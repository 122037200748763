import React from 'react';
import css from './ActualFrameworkCommon.module.scss';
import { Text } from '@components/common';
import { addHttps } from 'common/utils/Formaters';
import {
  FRAMEWORKS_LOGOS_DICTIONARY,
  ilustrationsFolder,
} from '../../IntegrationsDetails.constants';

function ActualFrameworkCommon({ currentProject }) {
  return (
    <div className={css.container}>
      {' '}
      <Text type="detail">Alojamiento actual:</Text>
      <div className={css.content}>
        <a href={addHttps(currentProject.actual_link)} target="_blank" rel="noreferrer">
          {currentProject.actual_link}
        </a>
      </div>
      <Text type="detail">Frameworks utilizados:</Text>
      <div className={css.frameworks}>
        {currentProject?.actual_framework?.map((frame, index) => {
          return (
            <div className={css.item} key={`frame-${index}`}>
              {FRAMEWORKS_LOGOS_DICTIONARY[frame] ? (
                <img src={ilustrationsFolder + FRAMEWORKS_LOGOS_DICTIONARY[frame]} />
              ) : (
                <div className={css.empty}>
                  {' '}
                  <img src={ilustrationsFolder + 'dreamer_e856445593.svg'} />
                  <Text type="smallDetail">{frame}</Text>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <hr />
    </div>
  );
}

export default ActualFrameworkCommon;

// : ilustrationsFolder + 'dreamer_e856445593.svg'
