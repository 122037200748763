export const BLUE_BACKGROUND_PROFILE_DRAWER =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/SideWindow/ViewProfileSideWindow.png';

export const GREEN_BACKGROUND_PROFILE_DRAWER =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/SideWindow/ServiceViewProfileSideWindow.png';

export const GREEN_BACKGROUND_CONTACT_DRAWER =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/SideWindow/ContactSideWindow.png';

export const BLUE_BACKGROUND_REQUEST_DRAWER =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/SideWindow/RequestSideWindow.png';

export const BLUE_BACKGROUND_PROJECT_DRAWER =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/SideWindow/ProjectSideWindow.png';

export const BLUE_BACKGROUND_PAYMENT_DRAWER =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/SideWindow/PaymentSideWindow.svg';

export const VERIFIED_ACCOUNT_ICON_URL =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/icons/Twitter_Verified_Badge.svg';
export const ICONO_BOLETA =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/icons/IconoBoleta.svg';
export const ICONO_FACTURA =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/icons/IconoFactura.svg';

export const ICONO_WEBPAY =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/icons/IconoWebpay.png';
export const ICONO_TENECESITO =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/icons/IconoTeNecesito.svg';

export const ICONO_PAGO_POR_ETAPAS =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/icons/IconoPagoPorEtapas.svg';
export const ICONO_PAGO_TOTAL =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/icons/IconoPagoTotal.svg';

export const BLUE_BACKGROUND_MYPROFILE =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/SideWindow/MyProfileBlueBackground.png';

export const STAR_COLOR = '#fdcc0d';
