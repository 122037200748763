/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import css from './Suggestions.module.scss';
import { Title } from '@components/common';
import SuggestionCard from './SuggestionCard';
import UserSuggestionCard from './UserSuggestionCard';

function Suggestions({ suggestionsData, handleRedirectSuggestion, type }) {
  return (
    <div className={css.container}>
      {suggestionsData?.length >= 1 ? (
        <Fragment>
          <div className={type == 'project' ? css.headerMain : css.headerSecondary}>
            <Title type="secondary" color={type == 'project' ? 'primary' : 'secondary'}>
              Te puede interesar
            </Title>
          </div>

          <div className={css.content}>
            {suggestionsData?.map((item, index) => {
              return type == 'project' ? (
                <SuggestionCard
                  key={index}
                  handleRedirectSuggestion={handleRedirectSuggestion}
                  item={item}
                  type={type}
                />
              ) : type == 'user' ? (
                <UserSuggestionCard
                  key={index}
                  handleRedirectSuggestion={handleRedirectSuggestion}
                  item={item}
                />
              ) : null;
            })}
          </div>
        </Fragment>
      ) : null}
    </div>
  );
}
export default Suggestions;
