import React from 'react';
import { ManagementCardLayout, SkillsBeans, Text, Title } from '@components/common';
import css from './MainCard.module.scss';
import { DropdownMenuItem } from 'components/common';

function MainCard({ currentService, handleDeleteService, handleGoToUpdateService }) {
  const withAction = [
    {
      label: 'Editar',
      callback: () => handleGoToUpdateService({ id: currentService.id }),
    },
    {
      label: 'Eliminar',
      callback: () => handleDeleteService({ id: currentService.id }),
    },
  ];
  return (
    <ManagementCardLayout>
      <div className={css.greenHeader}>
        <div className="publication-card__edit">
          <DropdownMenuItem hoverButton="" options={withAction} />
        </div>
      </div>
      <ManagementCardLayout.RightSide>
        {' '}
        <div className={css.container}>
          {' '}
          <div className={css.header}>
            <Text type="detail">Servicio: </Text>
          </div>
          <div className={css.content}>
            <Title type="secondary-bold" color="secondary">
              {currentService?.title}
            </Title>
            <div className={css.categories}>
              {currentService?.categories_names.map((category, index) => {
                return (
                  <Text key={index}>
                    {currentService?.categories_names.length == index + 1
                      ? category
                      : `${category} /`}
                  </Text>
                );
              })}
            </div>
            <SkillsBeans skills={currentService?.skills_names} />
          </div>
        </div>
      </ManagementCardLayout.RightSide>
    </ManagementCardLayout>
  );
}

export default MainCard;
