/* eslint-disable no-unused-vars */
import { Card } from 'antd';
import { DeleteOutline, EditOutlined } from '@material-ui/icons';
import { Text, Title } from 'components/common';
import css from './PreviousProjects.module.scss';
import React from 'react';

const PrevProjectCard = ({
  project,
  showCreateEditModal,
  showInfoModal,
  showDeleteModal,
  isOwner,
  ...rest
}) => {
  const projectDesc =
    project?.description?.length > 15
      ? project?.description?.slice(0, 15) + '...'
      : project?.description;

  const projectTitle =
    project?.title?.length > 15 ? project?.title?.slice(0, 18) + '...' : project?.title;
  return (
    <>
      <Card
        className={css.card}
        cover={
          <img
            alt="project-img"
            onClick={showInfoModal}
            src={project?.front_image?.upload}
            style={{ maxWidth: 200 }}
          />
        }
        actions={
          isOwner && [
            <EditOutlined key="edit" onClick={showCreateEditModal} />,
            <DeleteOutline key="setting" onClick={showDeleteModal} />,
          ]
        }
        {...rest}>
        <Card.Meta
          className={css.cardbody}
          title={
            <Title onClick={showInfoModal} color="primary">
              {projectTitle}
            </Title>
          }
          description={<Text>{projectDesc}</Text>}
        />
        <div></div>
      </Card>
    </>
  );
};

export default PrevProjectCard;
