import axiosClient from '@config/axios';
import { tokenConfig } from './auth';
import { createMessage, returnErrors } from './messages';
import {
  GET_PROJECTS,
  GET_USER_PROJECTS,
  PROJECTS_PAGINATION,
  MY_PROJECTS_PAGINATION,
  GET_PROJECT,
  DELETE_PROJECT,
  ADD_PROJECT,
  GET_MY_ACTIVE_PROJECTS,
  GET_MY_ACTIVE_PROJECTS_COLLAB,
  MY_ACTIVE_PROJECTS_PAGINATION,
  MY_ACTIVE_PROJECTS_PAGINATION_COLLAB,
  EDIT_PROJECT,
  UPDATE_CURRENT_PROJECT,
  MAX_RANGE_PROJECTS,
  UPDATE_MYACTIVEPROJECT,
  GET_PROJECT_LOADING,
  GET_PROJECTS_LOADING,
} from './types';

// GET PROJECTS
export const getProjects = (params) => {
  return axiosClient.get(`/projects`, { params });
};
export const getProjectsUrlParams = (URLparams) => {
  return axiosClient.get(`/projects?${URLparams}`);
};

export const getPublishedPaymentProjects = (userId) => {
  return axiosClient.get(`/projects/?owner=${userId}&&status=published&&status=payment`);
};

export const getInProgressCompletedHiringProjects = (userId) => {
  return axiosClient.get(`/projects/?owner=${userId}&&status=in_progress&&status=completed`);
};

export const getInProgressCompletedWorkingProjects = (userId) => {
  return axiosClient.get(`/projects/?collaborator=${userId}&&status=in_progress&&status=completed`);
};

// GET SEARCH FILTERED PROJECTS
export const getSearchFilteredProjects =
  (searchdata, page, callBack) => async (dispatch, getState) => {
    var aux_page = 1;
    if (page) {
      aux_page = page;
    }
    await axiosClient
      .get(
        `/projects/?status=published&page=${aux_page}&search=${searchdata}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_PROJECTS,
          payload: res.data.objects,
        });
        dispatch({
          type: PROJECTS_PAGINATION,
          payload: res.data.total_objects,
        });
        dispatch({
          type: MAX_RANGE_PROJECTS,
          payload: res.data.max_range_price,
        });
      })
      .catch((err) => console.log(err));
    callBack ? callBack() : null;
  };

export const getUserProjects = (userid, page) => async (dispatch, getState) => {
  var aux_page = 1;
  if (page) {
    aux_page = page;
  }
  await axiosClient
    .get(`/projects/?owner=${userid}&status=published&page=${aux_page}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_USER_PROJECTS,
        payload: res.data.objects,
      });
      dispatch({
        type: MY_PROJECTS_PAGINATION,
        payload: res.data.total_objects,
      });
    })
    .catch((err) => console.log(err));
};

export const getUserCompleted = (userid, page) => async (dispatch, getState) => {
  var aux_page = 1;
  if (page) {
    aux_page = page;
  }
  await axiosClient
    .get(`/projects/?user_completed=${userid}&page=${aux_page}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_MY_ACTIVE_PROJECTS,
        payload: res.data.objects.filter((obj) => obj.client_rating && obj.collaborator_rating),
      });
      dispatch({
        type: MY_ACTIVE_PROJECTS_PAGINATION,
        payload: res.data.total_objects,
      });
    })
    .catch((err) => console.log(err));
};

export const getUserActiveOwn = (userid, filters, page) => async (dispatch, getState) => {
  var aux_page = 1;
  if (page) {
    aux_page = page;
  }
  await axiosClient
    .get(
      `/projects/?my_projects=${userid}&owner=${userid}&page=${aux_page}&${filters}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: GET_MY_ACTIVE_PROJECTS,
        payload: res.data.objects,
      });
      dispatch({
        type: MY_ACTIVE_PROJECTS_PAGINATION,
        payload: res.data.total_objects,
      });
    })
    .catch((err) => console.log(err));
};

export const getUserActiveCollaborator = (userid, filters, page) => async (dispatch, getState) => {
  var aux_page = 1;
  if (page) {
    aux_page = page;
  }
  await axiosClient
    .get(
      `/projects/?my_projects=${userid}&collaborator=${userid}&page=${aux_page}&${filters}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: GET_MY_ACTIVE_PROJECTS_COLLAB,
        payload: res.data.objects,
      });
      dispatch({
        type: MY_ACTIVE_PROJECTS_PAGINATION_COLLAB,
        payload: res.data.total_objects,
      });
    })
    .catch((err) => console.log(err));
};

export const getFilteredUserActive = (userid, searchdata, page) => async (dispatch, getState) => {
  var aux_page = 1;
  if (page) {
    aux_page = page;
  }
  await axiosClient
    .get(
      `/projects/?my_projects=${userid}&page=${aux_page}&search=${searchdata}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: GET_MY_ACTIVE_PROJECTS,
        payload: res.data.objects,
      });
      dispatch({
        type: MY_ACTIVE_PROJECTS_PAGINATION,
        payload: res.data.total_objects,
      });
    })
    .catch((err) => console.log(err));
};
// GET PROJECT
export const getProject = (id) => (dispatch) => {
  dispatch({ type: GET_PROJECT_LOADING });
  return axiosClient
    .get(`/projects/${id}/`)
    .then((res) => {
      dispatch({
        type: GET_PROJECT,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_PROJECT,
        payload: { status: 'Unauthorized' },
      });
      dispatch(returnErrors(err.response.data, err.response.status));
    });
};

export const getProjectId = (projectId) => {
  return axiosClient.get(`/projects/${projectId}`);
};

export const getPrivateProject = (projectId) => {
  return axiosClient.get(`/my_project/?project=${projectId}`);
};

// DELETE PROJECT
export const deleteProject = (id) => async (dispatch, getState) => {
  await axiosClient
    .delete(`/projects/${id}/`, tokenConfig(getState))
    .then(() => {
      dispatch(createMessage({ deleteProject: 'Project Deleted' }));
      dispatch({
        type: DELETE_PROJECT,
        payload: id,
      });
    })
    .catch((err) => console.log(err));
};
export const deleteProject_v2 = ({ projectId }) => axiosClient.delete(`/projects/${projectId}/`);
// ADD PROJECT
export const registerProject = (project) => async (dispatch, getState) => {
  const result = await axiosClient
    .post(`/registerProject/`, project, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ registerProject: res.data }));
      dispatch({
        type: ADD_PROJECT,
        payload: res.data['project'],
      });
      return res;
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      return err.response;
    });
  return result;
};

export const registerProject_v2 = (project) => {
  return axiosClient.post(`/registerProject/`, project);
};

// ADDPROJECTFOR
export const registerProjectFor = (project, user) => async (dispatch, getState) => {
  var varproject = null;
  const result = await axiosClient
    .post(`/registerProject/`, project, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ registerProject: res.data }));
      dispatch({
        type: ADD_PROJECT,
        payload: res.data['project'],
      });
      varproject = res.data['project'];
      return res;
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      return err.response;
    });
  if (varproject) {
    await axiosClient
      .post(`/inviteService/`, { project: varproject.id, owner: user.id }, tokenConfig(getState))
      .then((res) => {
        dispatch(createMessage({ inviteService: res.data }));
      })
      .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
  }
  return result;
};
//GET FILTERED PROJECTS
export const getFilteredProjects = (filters, page) => (dispatch) => {
  dispatch({ type: GET_PROJECTS_LOADING });
  var aux_page = 1;
  if (page) {
    aux_page = page;
  }

  axiosClient
    .get(`/projects/?status=published&page=${aux_page}&${filters}&active=True`)
    .then((res) => {
      dispatch(createMessage({ getClientsProjects: 'Projects gotten' }));
      dispatch({
        type: GET_PROJECTS,
        payload: res.data.objects,
      });
      dispatch({
        type: PROJECTS_PAGINATION,
        payload: res.data.total_objects,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const dispatchProject = (project) => (dispatch) => {
  dispatch({
    type: GET_PROJECT,
    payload: project,
  });
};

export const editProject = (id, project) => async (dispatch, getState) => {
  const result = await axiosClient
    .patch(`/projects/${id}/`, project, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ editProject: res.data }));
      dispatch({
        type: EDIT_PROJECT,
        payload: res.data,
      });
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return result;
};

export const editProject_v2 = (id, body) => axiosClient.patch(`/projects/${id}/`, body);

export const getProjectQuestions = (projectId) =>
  axiosClient.get(`questions/?project=${projectId}`);

export const replyQuestion = (id, answerText) =>
  axiosClient.patch(`/questions/${id}/`, { answer_text: answerText });

export const createQuestion = (question) => axiosClient.post(`/questions`, question);

export const deleteQuestion = (id) => axiosClient.delete(`/questions/${id}/`);

export const uploadFile = (formData) => {
  return axiosClient.post('/files', formData);
};

export const deleteFile = (id) => axiosClient.delete(`/files/${id}`);

export const filesUpload = (data) => {
  return axiosClient.post('/files', data);
};

export const getFiles = (chatID) => {
  return axiosClient.get(`/files/?chat=${chatID}`);
};

export const acceptStage = (id) => async (dispatch, getState) => {
  const result = await axiosClient
    .patch(`/projectStages/${id}`, { approved: true }, tokenConfig(getState))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return result;
};
export const getStages = ({ projectId }) => axiosClient.get(`/projectStages/?project=${projectId}`);
export const feedbackStage = ({ comment, owner, project_stage, file_id }) =>
  axiosClient.post('/stage_feedbacks/', { comment, owner, project_stage, file_id });

export const updateProject = (status) => async (dispatch) => {
  dispatch({
    type: UPDATE_CURRENT_PROJECT,
    payload: status,
  });
  return;
};

export const finishUncompletedProject = (data) => async (dispatch, getState) => {
  const result = await axiosClient
    .post(`/finishUncompletedProject/`, data, tokenConfig(getState))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return result;
};

export const requestMediation = (data) => async (dispatch, getState) => {
  const result = await axiosClient
    .post(`/requestMediation/`, data, tokenConfig(getState))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return result;
};

export const discountCodeValidation = (code) => async (dispatch, getState) => {
  const result = await axiosClient
    .post(`/discountCodeValidation/`, code, tokenConfig(getState))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return result;
};

export const useDiscountCode = (data) => {
  const result = axiosClient
    .post(`/validate_discount_code/`, data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return result;
};

export const updateMyActiveProject = (id, state) => async (dispatch, getState) => {
  const result = await axiosClient
    .patch(`/projects/${id}/`, state, tokenConfig(getState))
    .then((res) => {
      const data = { id, state };
      dispatch({
        type: UPDATE_MYACTIVEPROJECT,
        payload: data,
      });
      return res;
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      return err.response;
    });
  return result;
};

export const approveStage = (stageId) => {
  return axiosClient.patch(`/projectStages/${stageId}`, { approved: true });
};

export const createBriefcase = ({
  title,
  url,
  description,
  front_image,
  gallery_images,
  skills,
  owner,
}) =>
  axiosClient.post('/briefcases/', {
    title,
    url,
    description,
    front_image,
    gallery_images,
    skills,
    owner,
  });

export const getBriefcases = ({ userId }) => axiosClient.get(`/briefcases?user=${userId}`);
export const getBriefcase = (id) => axiosClient.get(`/briefcases/${id}/`);
export const deleteBriefcase = ({ id }) => axiosClient.delete(`/briefcases/${id}/`);
export const updateBriefcase = ({ id, data }) =>
  axiosClient.patch(`/briefcases/${id}/`, { id, ...data });
export const relatedProjects = (projectId) =>
  axiosClient.get(`/related_projects/?project=${projectId}`);

export const uploadCMSProject = (data) => {
  return axiosClient.post('/register_cms_project', data);
};
export const uploadIntegrationProject = (data) => {
  return axiosClient.post('/register_integration_project', data);
};

export const getPublicProjects = (page, filterText, orderBy) => {
  return axiosClient.get(`/public_projects?page=${page}&order=${orderBy}${filterText}`);
};

export const sendAlreadyPaid = ({ project }) => {
  return axiosClient.post('/already_paid', { project });
};

export const sendMediation = ({ owner, project, reason, percentage }) => {
  return axiosClient.post('/mediation', { owner, project, reason, percentage });
};

export const sendProjectPromptClient = ({ project, prompt }) => {
  return axiosClient.post('/prompt_open_ai', { project, prompt });
};
export const sendProjectPromptFreelance = ({ project, prompt }) => {
  return axiosClient.post('/prompt_open_ai_collaborator', { project, prompt });
};
