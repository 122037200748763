/* eslint-disable no-unused-vars */
import React from 'react';
import { useEffect } from 'react';
import {
  createBriefcase,
  deleteBriefcase,
  getBriefcases,
  updateBriefcase,
  uploadFile,
} from 'actions/projects';
import { useDispatch } from 'react-redux';
import { loadUser } from 'actions/auth';
const usePrevProjects = ({ user }) => {
  const dispatch = useDispatch();
  const [modal, setModal] = React.useState({
    delete: false,
    edit: false,
    info: false,
  });
  const [projects, setProjects] = React.useState([]);
  const [selectedProject, setSelectedProject] = React.useState(null);
  const [refetch, setRefetch] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const showModal = (project, key) => {
    setSelectedProject(project);
    setModal({ ...modal, [key]: true });
  };
  const hiddeModal = () => {
    setModal({ delete: false, edit: false, info: false, create: false });
    setSelectedProject(null);
  };
  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await deleteBriefcase({ id: selectedProject.id });
      setIsLoading(false);
      setRefetch(!refetch);
      hiddeModal();
      dispatch(loadUser());
    } catch (error) {
      setIsLoading(false);
      setRefetch(!refetch);
      hiddeModal();
    }
  };
  const uploadPortada = async (portada) => {
    const formData = new FormData();
    formData.append('file', portada);
    formData.append('filename', portada.name);
    formData.append('type', 'briefcase');
    const { data: res } = await uploadFile(formData);
    return res.data.id;
  };
  const uploadGalerias = async (galerias, arr) => {
    const formData = new FormData();
    if (galerias.length > 0) {
      for (const img of galerias) {
        formData.append('file', img);
        formData.append('filename', img.name);
        formData.append('type', 'briefcase');
        const { data: galerias } = await uploadFile(formData);
        arr.push(galerias.data.id);
      }
    }
  };
  const handleEdit = async (values) => {
    setIsLoading(true);
    try {
      const { description, title, url, galerias, portada, skills, oldGalery, oldPortada } = values;

      let skillsIds = skills.map((skill) => skill.value);

      let portadaId;

      if (!oldPortada) {
        portadaId = await uploadPortada(portada);
      } else {
        portadaId = oldPortada.id;
      }

      let galeriasIds = [...oldGalery];

      await uploadGalerias(galerias, galeriasIds);

      await updateBriefcase({
        id: selectedProject.id,
        data: {
          description,
          title,
          url,
          front_image: portadaId,
          gallery_images: galeriasIds,
          skills: skillsIds,
        },
      });

      setRefetch(!refetch);
      hiddeModal();
      setIsLoading(false);
    } catch (error) {
      setRefetch(!refetch);
      hiddeModal();
      setIsLoading(false);
    }
  };

  const handleCreate = async (values) => {
    setIsLoading(true);
    try {
      const { description, title, url, galerias, portada, skills } = values;

      let skillsIds = skills.map((skill) => skill.value);

      const portadaId = await uploadPortada(portada);

      let galeriasIds = [];

      await uploadGalerias(galerias, galeriasIds);

      await createBriefcase({
        description,
        title,
        url,
        front_image: portadaId,
        gallery_images: galeriasIds,
        skills: skillsIds,
      });

      setRefetch(!refetch);
      hiddeModal();
      setIsLoading(false);
      dispatch(loadUser());
    } catch (error) {
      setRefetch(!refetch);
      hiddeModal();
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const getMyProjects = async () => {
      const { data } = await getBriefcases({ userId: user.id });
      setProjects(data);
    };
    getMyProjects();
  }, [refetch, user]);

  return {
    showModal,
    hiddeModal,
    modal,
    handleEdit,
    handleCreate,
    selectedProject,
    handleDelete,
    projects,
    isLoading,
  };
};

export default usePrevProjects;
