import css from './FileIcon.module.scss';
import React, { useEffect, useState } from 'react';

export function FileIcon({ userImage, fileData, fromChat }) {
  const [file, setFile] = useState('far fa-file-archive');
  const [name, setName] = useState(null);
  const [extension, setExtension] = useState(null);
  const icon = React.createRef();

  useEffect(() => {
    if (!fileData?.isUploading) {
      const file = fileData?.original_name?.split('.');
      const extension = file[file?.length - 1];
      setExtension(extension);
      setName(file[file?.length - 2]);
      if (extension === 'png' || extension === 'jpg' || extension === 'jpeg') {
        setFile('far fa-file-image');
      } else if (
        extension === 'csv' ||
        extension === 'xlsx' ||
        extension === 'xlsm' ||
        extension === 'xlsb' ||
        extension === 'xltx' ||
        extension === 'xltm' ||
        extension === 'xls' ||
        extension === 'xlt' ||
        extension === 'xls'
      ) {
        setFile('far fa-file-excel');
        icon.current.style.color = 'green';
      } else if (extension === 'ppt' || extension === 'pptx' || extension === 'pptm') {
        setFile('far fa-file-powerpoint');
        icon.current.style.color = 'orange';
      } else if (
        extension === 'doc' ||
        extension === 'docm' ||
        extension === 'docx' ||
        extension === 'dot'
      ) {
        setFile('far fa-file-word');
        icon.current.style.color = 'blue';
      } else if (extension === 'pdf') {
        setFile('far fa-file-pdf');
        icon.current.style.color = 'red';
      } else if (extension === 'rar' || extension === 'zip' || extension === 'iso') {
        setFile('far fa-file-archive');
      } else if (extension === 'mp3' || extension === 'wma' || extension === 'raw') {
        setFile('far fa-file-audio');
      } else if (
        extension === 'avi' ||
        extension === 'gif' ||
        extension === 'mkv' ||
        extension === 'mp4'
      ) {
        setFile('far fa-file-video');
      } else {
        icon.current.style.color = 'black';
      }
    }
  }, [fileData]);
  if (fromChat) {
    return (
      <a href={fileData?.upload} target="_blank" rel="noreferrer" style={{ textAlign: 'center' }}>
        <i
          className={file}
          ref={icon}
          style={{ width: 60, height: 50, fontSize: 40, padding: 5 }}></i>
        <div className={css.infoContainer}>{name?.substr(0, 30) + '.' + extension}</div>
      </a>
    );
  }
  return (
    <a className={css.container} href={fileData.upload} target="_blank" rel="noreferrer">
      <div className={css.imageContainer}>
        <i className={file} ref={icon}></i>
        {userImage && <img src={userImage}></img>}
      </div>
      <div className={css.infoContainer}>{name?.substr(0, 30) + '.' + extension}</div>
    </a>
  );
}
