import React from 'react';
import { Modal } from 'antd';
import { getPrivateProject, approveStage } from '@actions/projects.js';
import { useParams } from 'react-router-dom';
import { ManagmentViewModel } from '../../../ManagmentViewModel';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getRequest } from '@actions/requests';
import { getRequestProposals } from '@actions/proposals';
import chatService from '@services/chatService';
import { getProjectQuestions, replyQuestion } from 'actions/projects';
import { scrollToBottom } from 'common/utils/WindowFocus';
const { confirm } = Modal;

export const ClientProjectManagmentViewModel = () => {
  const { id } = useParams();
  const {
    authUser,
    userDrawer,
    setUserDrawer,
    viewRequestDrawer,
    setViewRequestDrawer,
    projectDrawer,
    setProjectDrawer,
  } = ManagmentViewModel();

  const [ratingModal, setRatingModal] = React.useState(false);

  const [isLoadingProject, setIsLoadingProject] = React.useState(true);
  const [currentProject, setCurrentProject] = React.useState();

  const [currentRequest, setCurrentRequest] = React.useState();
  const [isLoadingRequest, setIsLoadingRequest] = React.useState(false);

  const [currentProposal, setCurrentProposal] = React.useState();
  const [isLoadingProposal, setIsLoadingProposal] = React.useState(false);

  const [currentChatId, setCurrentChatId] = React.useState(null);

  const [files, setFiles] = React.useState([]);
  const [isLoadingFiles, setIsLoadingFiles] = React.useState(false);

  const [updateProjectStatus, setUpdateProjectStatus] = React.useState(0);

  const [questionsList, setQuestionsList] = React.useState([]);
  const [isLoadingQuestions, setIsLoadingQuestions] = React.useState(false);

  const [refetchProject, setRefetchProject] = React.useState(false);

  const useGetProject = (projectId) => getPrivateProject(projectId);

  const handleGetFiles = (chatId) => {
    setIsLoadingFiles(true);
    chatService
      .getFilesHandler(chatId)
      .then((res) => {
        setFiles(res.data);
        setIsLoadingFiles(false);
      })
      .catch(() => setIsLoadingFiles(false));
  };

  const handleGetRequest = (userId, projectId) => {
    setIsLoadingRequest(true);
    getRequest({ emitter: userId, project: projectId })
      .then((res) => {
        setCurrentRequest(res.data.objects[0]);
        setIsLoadingRequest(false);
      })
      .catch(() => {
        setCurrentRequest(null);
        setIsLoadingRequest(false);
      });
  };

  const useImageUploadHandler = (event) => {
    setIsLoadingFiles(true);
    chatService
      .uploadHandler(event, currentChatId)
      .then(() => {
        handleGetFiles(currentChatId);
      })
      .catch(() => setIsLoadingFiles(false));
  };
  const handleReplyQuestion = (id, answerText) => {
    setIsLoadingQuestions(true);
    replyQuestion(id, answerText)
      .then((res) => {
        setQuestionsList([...questionsList.filter((q) => q.id != id), res.data.request]);
        setIsLoadingQuestions(false);
        scrollToBottom(`publication-questions-${res.data.request.id}`);
      })
      .catch(() => {
        setIsLoadingQuestions(false);
      });
  };
  React.useEffect(() => {
    setIsLoadingProject(true);
    setIsLoadingFiles(true);
    useGetProject(id).then((res) => {
      setCurrentProject(res.data);
      setCurrentChatId(res.data.chat_id);
      handleGetFiles(res.data.chat_id);
      setIsLoadingProject(false);
    });
  }, [id, updateProjectStatus, refetchProject]);

  React.useEffect(() => {
    setIsLoadingQuestions(true);
    if (currentProject) {
      getProjectQuestions(currentProject.id)
        .then((res) => {
          setQuestionsList(res.data.objects);
          setIsLoadingQuestions(false);
        })
        .catch(() => {
          setQuestionsList([]);
          setIsLoadingQuestions(false);
        });
    }
  }, [currentProject]);

  React.useEffect(() => {
    if (currentProject) {
      handleGetRequest(currentProject.collaborator_id, id);
    }
  }, [currentProject]);

  React.useEffect(() => {
    if (currentRequest) {
      setIsLoadingProposal(true);
      getRequestProposals({ request: currentRequest?.id })
        .then((res) => {
          setCurrentProposal(res.data[res.data.length - 1]);
          setIsLoadingProposal(false);
        })
        .catch(() => setIsLoadingProposal(false));
    }
  }, [currentRequest]);

  const handleAproveStage = (stageId) => {
    confirm({
      title: `¿Estas seguro que quieres aceptar la etapa?`,
      icon: <ExclamationCircleOutlined />,
      content:
        'Una vez aceptada, la etapa quedará considerada como finalizada por completo y se liberará el valor de esta al freelancer.',
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: async () => {
        approveStage(stageId).then(() => {
          setUpdateProjectStatus(updateProjectStatus + 1);
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  return {
    authUser,
    files,
    isLoadingFiles,
    isLoadingProject,
    isLoadingRequest: isLoadingRequest || isLoadingProposal,
    currentProject,
    currentRequest,
    currentProposal,
    currentChatId,
    userDrawer,
    setUserDrawer,
    viewRequestDrawer,
    setViewRequestDrawer,
    projectDrawer,
    setProjectDrawer,
    handleAproveStage,
    ratingModal,
    setRatingModal,
    setIsLoadingFiles,
    useImageUploadHandler,
    handleReplyQuestion,
    isLoadingQuestions,
    questionsList,
    handleGetFiles,
    setRefetchProject,
  };
};
