/* eslint-disable no-unused-vars */
import { Input } from 'antd';
import { Text } from 'components/common';
import React from 'react';

const myLinks = [
  { name: 'github_link', icon: 'fab fa-github' },
  { name: 'briefcase_link', icon: 'fa fa-briefcase' },
  { name: 'stackoverflow_link', icon: 'fab fa-stack-overflow' },
];

const MyLinksProposal = ({ user, useEditUser, hasLink }) => {
  const [isEditing, setIsEditing] = React.useState('');
  const [userForm, setUserForm] = React.useState({
    github_link: user?.github_link || 'https://github.com/',
    briefcase_link: user?.briefcase_link || 'https://www.mi-portafolio.com/',
    stackoverflow_link: user?.stackoverflow_link || 'https://stackoverflow.com/',
  });
  const handleChange = (e) => {
    setUserForm({ ...userForm, [e.target.name]: e.target.value });
  };
  return (
    <div style={{ margin: '1em 0' }}>
      {!hasLink && <Text style={{ color: 'red' }}>Debes ingresar al menos un link</Text>}
      <Text style={{ marginBottom: '1em' }}>Cuentas y portafolio</Text>
      {myLinks.map((link, idx) => (
        <MyLinksInput
          key={idx}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          value={userForm[link.name]}
          handleChange={handleChange}
          name={link.name}
          icon={link.icon}
          useEditUser={useEditUser}
        />
      ))}
    </div>
  );
};
const MyLinksInput = ({
  isEditing,
  setIsEditing,
  value,
  handleChange,
  name,
  icon,
  useEditUser,
}) => {
  const onBlur = () => {
    useEditUser({ [name]: value });
    setIsEditing('');
  };
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '1em' }}>
      <i
        className={icon}
        style={{ fontSize: 28, maxWidth: 20, color: isEditing === name ? '#3383ff' : '#cccc' }}
      />
      <Input
        style={{
          width: '100%',
          borderRadius: '1em',
          margin: '0.5em 0',
          backgroundColor: isEditing === name ? '' : '#cccc',
        }}
        onFocus={() => setIsEditing(name)}
        onBlur={onBlur}
        name={name}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};
export default MyLinksProposal;
