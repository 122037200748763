import React from 'react';
import classNames from 'classnames';
import './Title.scss';

/*
  type: primary, secondary, tertiary, quaternary and subtitle
  color: main, primary, secondary, primaryText and secondaryText
*/
const Title = ({
  type = 'secondary',
  color = 'primaryText',
  size,
  children,
  centered = false,
  className,

  ...rest
}) => {
  const titleClass = classNames(
    'tn-title',
    type !== 'subtitle' && `${color}Color`,
    type,
    centered && 'centered',
    className
  );
  const TitleTag =
    type === 'primary' || type === 'xl'
      ? 'h1'
      : type === 'secondary'
      ? 'h2'
      : type === 'tertiary' || type === 'subtitle'
      ? 'h3'
      : 'h4';

  return (
    <TitleTag style={{ fontSize: `${size}px` }} className={titleClass} {...rest}>
      {children}
    </TitleTag>
  );
};

export default Title;
