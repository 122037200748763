import React, { Fragment } from 'react';
import css from '../../PostCard.module.scss';
import ReactMarkdown from 'react-markdown';

function PrimaryCardContent({ postAttr }) {
  return (
    <Fragment>
      <title className={css.primaryTitle}>
        <h3 className="tn-title primary">{postAttr?.title}</h3>
      </title>
      <div className={css.category}>
        <p className="tn-text tn-text--big primaryColor">{postAttr?.category}</p>
      </div>
      <div className={css.text}>
        {' '}
        <ReactMarkdown>{postAttr?.abstract}</ReactMarkdown>
      </div>
    </Fragment>
  );
}

export default PrimaryCardContent;
