import React from 'react';
import { DeleteOutline } from '@material-ui/icons';
import { useEffect } from 'react';
import css from '../PreviousProjects.module.scss';
import { useState } from 'react';

const PreviewImage = ({
  file,
  myPreview,
  onDelete = () => null,
  width = 170,
  height = 150,
  withDelete = true,
  ...rest
}) => {
  const [preview, setPreview] = useState(undefined);
  useEffect(() => {
    if (!file) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file, myPreview]);

  const handleDelete = () => {
    onDelete();
    setPreview(undefined);
  };

  return (
    <>
      {(preview || myPreview) && (
        <div className={css.previewImageContainer} style={{ width, height }} {...rest}>
          <img src={preview || myPreview} style={{ width, height }} />
          {withDelete && <DeleteOutline onClick={handleDelete} className={css.deletePreview} />}
        </div>
      )}
    </>
  );
};

export default PreviewImage;
