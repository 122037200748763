import React from 'react';
import css from './SideBarFilters.module.scss';
import { Title, SwitchTypeSearch } from '@components/common';
import Categories from './Categories';

function SideBarFilters({
  switchStatus,
  handleSwitch,
  mainCategories,
  loadingMainCategories,
  selectCategory,
  currentFilters,
}) {
  return (
    <div className={css.container}>
      <div className={css.section}>
        <Title type="secondary" color="primary">
          NECESITO
        </Title>
        <SwitchTypeSearch
          textWork="Trabajar"
          textHire="Contratar"
          checkedWork={switchStatus == 'Trabajar'}
          checkedHire={switchStatus == 'Contratar'}
          handleSwitch={handleSwitch}
        />
      </div>

      <Title type="secondary" color="primary">
        CATEGORÍAS
      </Title>
      <Categories
        mainCategories={mainCategories}
        loadingMainCategories={loadingMainCategories}
        selectCategory={selectCategory}
        currentFilters={currentFilters}
      />
    </div>
  );
}

export default SideBarFilters;
