import React from 'react';
import { LoadingDots, Text } from 'components/common';
import { Radio, Space } from 'antd';
import { FormItemCard } from '../../FormItemCard';
import { titleSubcategory } from 'common/constants/publicationForm.constants';

const SubCategory = ({
  values,
  handleSelectSubCategory,
  subCategories,
  isLoadingSubCategories,
  currentFocus,
  branchStatus,
  handleGoBackQuestion,
  handleGoForwardQuestion,
  displayDistinctQuestion,
}) => {
  return (
    values['category'] && (
      <FormItemCard
        name="sub_category"
        title={titleSubcategory[branchStatus]}
        subTitle={'Elige una opción'}
        isCurrent={currentFocus == 'sub_category'}
        id={'sub_category-container'}
        handleGoForwardQuestion={handleGoForwardQuestion}
        handleGoBackQuestion={handleGoBackQuestion}
        displayDistinctQuestion={displayDistinctQuestion}>
        {isLoadingSubCategories ? (
          <LoadingDots />
        ) : (
          <Radio.Group
            value={values['sub_category']?.name}
            disabled={currentFocus == 'sub_category' ? false : true}>
            <Space direction="vertical">
              {subCategories?.map((item) => {
                return (
                  <Radio
                    onChange={() => handleSelectSubCategory(item)}
                    value={item.name}
                    key={`mult-opt-${item.id}`}>
                    <Text>{item.name}</Text>
                  </Radio>
                );
              })}
            </Space>
          </Radio.Group>
        )}
      </FormItemCard>
    )
  );
};

export default SubCategory;
