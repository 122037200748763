import React, { Fragment } from 'react';
import classNames from 'classnames';
import { CornerDownRight, CreditCard, MessageSquare, Settings } from 'react-feather';
import {
  CLEAR_ACCOUNTS_ROUTE,
  MORECONFIGURATIONS,
  FREELANCER_REQUESTS_ROUTE,
  CLIENT_PUBLICATIONS_ROUTE,
  CLIENT_PROJECTS_ROUTE,
  //FREELANCER_PUBLICATIONS_ROUTE,
  FREELANCER_PROJECTS_ROUTE,
  MYPROFILE,
} from '@common/constants/routes.constants';
import { useHistory, useLocation } from 'react-router';
import { User, Briefcase, FileText, Mail } from 'react-feather';
import { logout } from '@actions/auth';
import { useDispatch } from 'react-redux';
import { selectAuthUserInfo } from '@reducers/auth';
import { useSelector } from 'react-redux';
import './DrawerItems.scss';
import { switchMoreConfigStatus } from '@actions/myprofile';
import { Text, Title, CustomDrawer } from '@components/common';
import { NOTIFICATIONS } from 'common/constants/routes.constants';
import ProfilePicture from 'components/common/ProfilePicture/ProfilePicture';

const DrawerSectionsTop = [
  {
    name: 'Mi perfil',
    route: MYPROFILE,
    icon: <User />,
  },
  {
    name: 'Publicaciones',
    route: CLIENT_PUBLICATIONS_ROUTE,
    icon: <FileText />,
  },
  {
    name: 'Proyectos activos',
    route: CLIENT_PROJECTS_ROUTE,
    icon: <Briefcase />,
  },
  {
    name: 'Postulaciones',
    route: FREELANCER_REQUESTS_ROUTE,
    icon: <Mail />,
  },
  //{
  //  name: 'Tus Servicios',
  //  route: FREELANCER_PUBLICATIONS_ROUTE,
  //  icon: <Code />,
  //},
  {
    name: 'Proyectos activos',
    route: FREELANCER_PROJECTS_ROUTE,
    icon: <Briefcase />,
  },
  {
    name: 'Cuentas claras',
    route: CLEAR_ACCOUNTS_ROUTE,
    icon: <CreditCard />,
  },
  {
    name: 'Notificaciones',
    route: NOTIFICATIONS,
    icon: <MessageSquare />,
  },
  {
    name: 'Configuración de la cuenta',
    route: MORECONFIGURATIONS,
    icon: <Settings />,
  },
];

export const DrawerItems = ({ admin, handleClose, visible }) => {
  const history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const currentAuthUser = useSelector(selectAuthUserInfo);

  const useLogout = () => dispatch(logout());

  const handleItemClick = (route, name) => {
    if (route === 'logout') {
      useLogout();
      handleClose && handleClose();
      return;
    } else if (name == 'Notificaciones') {
      dispatch(switchMoreConfigStatus('NOTIFICATIONS'));
    } else if (name == 'Configuración de la cuenta') {
      dispatch(switchMoreConfigStatus('PERSONAL DATA'));
    }
    history.push(route);
    handleClose && handleClose();
  };
  const DrawerItem = ({ children, selected, route, icon, name, isLogOut }) => {
    const containerClass = classNames(
      'menu-item-dropdown',
      'menu-item-drawer',
      selected && 'menu-item-selected',
      isLogOut && 'menu-item-log-out'
    );

    return (
      <div className={containerClass} onClick={() => handleItemClick(route, name)}>
        {icon}
        <div
          className={isLogOut ? 'menu-item-log-out' : 'menu-item-text'}
          style={{ marginLeft: '10px' }}>
          <Text type={isLogOut ? 'logOut' : 'detail'}>{children}</Text>
        </div>
      </div>
    );
  };

  const checkSelected = (route) => {
    return location.pathname === route;
  };

  return (
    <CustomDrawer onClose={handleClose} visible={visible} type="profile">
      <div className="menu__container">
        <div className="menu__header" style={{ marginTop: '1em' }}>
          <ProfilePicture
            user={currentAuthUser}
            width={80}
            withEditButton={false}
            withName={true}
          />
        </div>
        <div className="menu__content">
          {' '}
          <div className="tn-drawer-items">
            <div className="drawer-items__container">
              {' '}
              {DrawerSectionsTop.map((section, index) => (
                <Fragment key={`drawer-${index}`}>
                  {index == 1 ? (
                    <Title size="16" type="primary" color="primary">
                      Cliente
                    </Title>
                  ) : index == 3 ? (
                    <Title size="16" type="primary" color="secondary">
                      Freelancer
                    </Title>
                  ) : null}

                  <DrawerItem
                    key={index}
                    route={section.route}
                    icon={section.icon}
                    name={section.name}
                    selected={checkSelected(section.route)}>
                    {section.name}
                  </DrawerItem>
                  {index == 0 || index == 2 || index == 4 ? <hr /> : null}
                </Fragment>
              ))}
            </div>
            {admin && (
              <div className={`menu-item-dropdown`} onClick={() => handleItemClick('/admin')}>
                <span className="icon-button-dropdown-left">
                  <i className="fas fa-address-card" />
                </span>
                <div className="menu-item-text">
                  <p>Administrador</p>
                </div>
              </div>
            )}
            <span>
              <DrawerItem route="logout" isLogOut icon={<CornerDownRight />}>
                Cerrar sesión
              </DrawerItem>
            </span>
          </div>
        </div>
      </div>
    </CustomDrawer>
  );
};
