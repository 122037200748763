/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react';
import {
  BaseLayout,
  FormVerifyIdentity,
  PopUpWindow,
  Button,
  ProjectDetails,
  Text,
  Suggestions,
} from '@components/common';
import { PublicProjectMainCard } from '@components/project/ProjectMainCard/PublicProjectMainCard/PublicProjectMainCard';
import { ViewProfileDrawer } from '@components/profile/ViewProfileDrawer/ViewProfileDrawer';
import PublicProjectViewModel from './PublicProjectViewModel';
import { QuestionContainer } from '@components/common/QuestionContainer';
import { LoadingDots } from '@components/common/LoadingDots';
import './PublicProject.scss';
import { RequestModal } from 'components/requests/EditRequestDrawer/RequestModal';
import YourRequest from 'components/requests/EditRequestDrawer/RequestStepper/YourRequest';
import { Monitor } from 'react-feather';
import CustomTabs from 'components/common/CustomTabs';
import { Card } from 'antd';
import { Title } from 'components/common';

const PUBLIC_PROJECT_TABS = {
  PROYECT: 'Detalles del proyecto',
  SUGGESTION: 'Nuestras sugerencias',
};

const PublicProject = ({ fromModal = false }) => {
  const {
    isLoading,
    currentProject,
    own,
    currentRequest,
    currentProposal,
    currentUser,
    handleCreateQuestion,
    userDrawer,
    setUserDrawer,
    setRequestDrawer,
    requestDrawer,
    authUser,
    openVerifyIdentity,
    setOpenVerifyIdentity,
    doneVerification,
    questionsList,
    handleProposal,
    projectsSuggestions,
    handleRedirectSuggestion,
  } = PublicProjectViewModel();

  const publicProjectTabs = [
    {
      name: PUBLIC_PROJECT_TABS.PROYECT,
      icon: <Monitor />,
      component: (
        <div style={{ borderRadius: '0em 1em 1em 1em', padding: 0, backgroundColor: 'white' }}>
          <ProjectDetails currentProject={currentProject} />,
        </div>
      ),
    },
    // {
    //   name: PUBLIC_PROJECT_TABS.SUGGESTION,
    //   icon: <Monitor />,
    //   badge: (
    //     <span
    //       style={{
    //         top: '-0.8em',
    //         right: '-1em',
    //         position: 'absolute',
    //         backgroundColor: '#3383ff',
    //         color: 'white',
    //         padding: '0 0.7em',
    //         borderRadius: '1em',
    //         fontSize: 12,
    //       }}>
    //       BETA
    //     </span>
    //   ),
    //   component: (
    //     <Card style={{ borderRadius: '0em 1em 1em 1em' }}>
    //       <Title color="primary">Sugerencias de nuestra I.A</Title>
    //       <Text type="detail">
    //         Por favor, tenga en cuenta que las sugerencias proporcionadas son generadas por una
    //         inteligencia artificial y se proporcionan solo como una ayuda para la toma de
    //         decisiones. La precisión y la calidad de las sugerencias pueden variar y no se garantiza
    //         que sean exactas o adecuadas para sus necesidades específicas.
    //       </Text>
    //       <div style={{ marginTop: 24 }}>
    //         {currentProject?.project_ia?.collaborator_ia?.split('\n')?.map((line, idx) => (
    //           <Text key={idx}>{line}</Text>
    //         ))}
    //       </div>
    //     </Card>
    //   ),
    // },
  ];
  return (
    <BaseLayout>
      {projectsSuggestions.length >= 1 && !fromModal ? (
        <Fragment>
          <BaseLayout.LeftSide>
            <Suggestions
              suggestionsData={projectsSuggestions}
              handleRedirectSuggestion={handleRedirectSuggestion}
              type="project"
            />
          </BaseLayout.LeftSide>
        </Fragment>
      ) : null}
      <BaseLayout.Content>
        {!isLoading ? (
          <Fragment>
            <PopUpWindow open={openVerifyIdentity} onClose={() => setOpenVerifyIdentity(false)}>
              <FormVerifyIdentity
                doneVerification={doneVerification}
                userData={authUser.user}
                mustVerify
              />
            </PopUpWindow>
            <ViewProfileDrawer
              currentUser={currentUser}
              type="project"
              userDrawer={userDrawer}
              setUserDrawer={setUserDrawer}
            />
            <RequestModal
              isModalOpen={requestDrawer}
              authUser={authUser}
              setRequestDrawer={setRequestDrawer}
              lastStep={{
                title: 'Realizar Propuesta',
                component: (
                  <YourRequest
                    currentProject={currentProject}
                    authUser={authUser}
                    currentRequest={currentRequest}
                    currentProposal={currentProposal}
                    isLoading={isLoading}
                  />
                ),
              }}
            />
            {!fromModal &&
              (authUser.isAuthenticated ? (
                <PublicProjectMainCard
                  authUser={authUser}
                  currentProject={currentProject}
                  currentProposal={currentProposal}
                  currentUser={currentUser}
                  setUserDrawer={setUserDrawer}
                  setRequestDrawer={setRequestDrawer}
                  setOpenVerifyIdentity={setOpenVerifyIdentity}
                  own={own}
                  currentRequest={currentRequest}
                  handleProposal={handleProposal}
                />
              ) : (
                <PublicProjectMainCard
                  currentProject={currentProject}
                  currentProposal={currentProposal}
                  currentUser={currentUser}
                  setUserDrawer={setUserDrawer}
                  handleProposal={handleProposal}
                  own={own}
                />
              ))}
            <CustomTabs tabs={publicProjectTabs} />
            {/* <ProjectDetails currentProject={currentProject} /> */}
            {!fromModal && authUser.isAuthenticated && currentRequest?.status != 'rejected' && (
              <Fragment>
                <div className="public-project__propousal-button">
                  <Button
                    text={currentProposal ? 'Editar postulación' : 'Hacer Propuesta'}
                    variant="primary"
                    color={'secondary'}
                    onClick={() => setRequestDrawer(true)}
                    disabled={own}
                  />
                </div>
              </Fragment>
            )}
            <div className="public-project__question-container">
              <Text type="detail">Preguntas:</Text>
              <Text type="smallDetail">
                Aquí puedes hacer tus consultas sobre cosas que no te hayan quedado claras de la
                descripción.
              </Text>

              <QuestionContainer
                type="project"
                user={currentUser}
                handleCreateQuestion={handleCreateQuestion}
                authUser={authUser}
                isLoading={isLoading}
                questionsList={questionsList}
                isAnswerable={!fromModal}
              />
            </div>
          </Fragment>
        ) : (
          <LoadingDots />
        )}
      </BaseLayout.Content>
    </BaseLayout>
  );
};

export default PublicProject;
