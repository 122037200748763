import React from 'react';
import css from './UserSuggestionCard.module.scss';
import { Text } from '@components/common';

const UserSuggestionCard = ({ handleRedirectSuggestion, item }) => {
  return (
    <div className={css.container} onClick={() => handleRedirectSuggestion(item.user_id)}>
      <div className={css.header}>
        <div className={css.left}>
          <img className={css.image} src={item.profile_image} />
        </div>
        <div className={css.right}>
          <Text type="normalBoldDetail"> {`${item.owner_name} ofrece`}</Text>
        </div>
      </div>
      <div className={css.body}>
        {item.services.map((service) => {
          return <Text key={`service-${service.id}`}>{service.service_title}</Text>;
        })}

        <hr style={{ marginBottom: 0 }} />
      </div>
    </div>
  );
};

export default UserSuggestionCard;
