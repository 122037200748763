import React from 'react';
import './CompletedProjectsCard.scss';
import { CompletedProjects } from '@components/profile/CompletedProjects/CompletedProjects';

const CompletedProjectsCard = ({ userData }) => {
  return (
    <div className="completed-projects-card__container">
      <CompletedProjects currentUser={userData} type="project" />
    </div>
  );
};

export default CompletedProjectsCard;
