import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const CONFIRM_ACCEPT_PROPOSAL_MODAL = {
  title: '',
  icon: <ExclamationCircleOutlined />,
  content: 'Una vez que aceptes se rechazarán todas las otras postulaciones.',
  okText: 'Aceptar',
  cancelText: 'Cancelar',
};

export const CONFIRM_EDIT_PROJECT_MODAL = {
  title: '¿Estas seguro/a que quieres editar esta publicación?',
  icon: <ExclamationCircleOutlined />,
  content:
    'Si confirmas, volverás al formulario de publicación y los/as postulantes serán notificados/as',
  okText: 'Aceptar',
  cancelText: 'Cancelar',
};

export const CONFIRM_DELETE_PROJECT_MODAL = {
  title: '¿Estas seguro/a que quieres eliminar esta publicación?',
  icon: <ExclamationCircleOutlined />,
  content: 'Si confirmas, la publicación será eliminada y las propuestas serán rechazadas.',
  okText: 'Aceptar',
  cancelText: 'Cancelar',
};
