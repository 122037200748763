import React from 'react';
import css from './MultipleChoiceQuestion.module.scss';
import { MultipleChoiceRadio, Text } from 'components/common';

const MultipleChoiceQuestion = ({ optionsList, isFocused, currentSelected, handleSelect }) => {
  const isSelected = (option) => {
    if (currentSelected?.includes(option.name)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={`${css.container} ${!isFocused && css.disabled}`}>
      {optionsList.map((option, index) => {
        return (
          <div className={css.itemContainer} key={`funcionality-${index}`}>
            {' '}
            <div
              className={isSelected(option) ? css.itemSelected : css.item}
              onClick={() => handleSelect(option)}>
              {option.icon}
              <Text>{option.name}</Text>
              <div className={css.radioContainer}>
                {' '}
                <MultipleChoiceRadio isSelected={isSelected(option)} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default MultipleChoiceQuestion;
