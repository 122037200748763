import React from 'react';
import { FormItemCard } from '../../FormItemCard';
import { Spin, Select } from 'antd';

const { Option } = Select;

const Skills = ({
  values,
  fetchSkills,
  skillsOptions,
  fetchingSkills,
  handleSelectSkills,
  currentFocus,
  handleGoBackQuestion,
  handleGoForwardQuestion,
  displayDistinctQuestion,
}) => {
  return (
    <FormItemCard
      name="skills"
      title={'Selecciona las habilidades necesarias para completar el proyecto'}
      subTitle={'Elige entre 1 y 5 habilidades'}
      isCurrent={currentFocus == 'skills'}
      handleGoForwardQuestion={handleGoForwardQuestion}
      handleGoBackQuestion={handleGoBackQuestion}
      displayDistinctQuestion={displayDistinctQuestion}
      id={'skills-container'}>
      <div>
        {' '}
        <Select
          mode="multiple"
          labelInValue
          value={values['skills']}
          placeholder=""
          notFoundContent={fetchingSkills ? <Spin size="small" /> : null}
          filterOption={false}
          onSearch={fetchSkills}
          onChange={handleSelectSkills}
          style={{ width: '100%' }}
          disabled={currentFocus != 'skills'}>
          {skillsOptions?.map((skill) => (
            <Option value={skill.id} key={skill.id}>
              {skill.name}
            </Option>
          ))}
        </Select>
      </div>
    </FormItemCard>
  );
};

export default Skills;
