import React from 'react';
import './ProfileSection.scss';
import { SocialMediaLinks } from 'components/common';
import ClampLines from 'react-clamp-lines';
import { SkillsBeans, Title, Ratings } from '@components/common';
import PreviousProjects from 'pages/Managment/MyProfile/PreviousProjects';
import ProfilePicture from 'components/common/ProfilePicture/ProfilePicture';

const ProfileSection = ({ currentRequester, type }) => {
  return (
    <div className="profile-section__container">
      <ProfilePicture
        user={currentRequester}
        withEditButton={false}
        withMedals
        withName
        width={100}
      />
      <SocialMediaLinks currentUser={currentRequester} />
      <hr />
      <div className="profile-section__text">
        <Title type="secondary" color="primary">
          Acerca de mi
        </Title>
        {currentRequester.about_me ? (
          <ClampLines
            text={currentRequester.about_me}
            id="about-me-text"
            lines={5}
            ellipsis="..."
            moreText="Ver más"
            lessText="Ver menos"
            className={`tn-text tn-text--regular`}
            innerElement="p"
          />
        ) : (
          ''
        )}
      </div>
      <div className="profile-section__text">
        <Title type="secondary" color="primary">
          Experiencia
        </Title>
        {currentRequester.experience && (
          <ClampLines
            text={currentRequester.experience}
            id="about-me-text"
            lines={5}
            ellipsis="..."
            moreText="Ver más"
            lessText="Ver menos"
            className={`tn-text tn-text--regular`}
            innerElement="p"
          />
        )}
      </div>
      <div className="profile-section__text">
        <Title type="secondary" color="primary">
          Habilidades
        </Title>
        <SkillsBeans skills={currentRequester.skills_names} />
      </div>
      <div>
        <PreviousProjects user={currentRequester} carousel />
      </div>
      <div className="view-profile__text-section">
        {type == 'client' ? (
          <Ratings type="client" userId={currentRequester?.id} />
        ) : (
          <Ratings type="collaborator" userId={currentRequester?.id} />
        )}
      </div>
    </div>
  );
};

export default ProfileSection;
