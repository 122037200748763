import React from 'react';
import { BankAccountCard } from './BankAccountCard';
import './BankAccounts.scss';
import { NO_SERVICE_PUBLICATION_ILUSTRATION } from '@common/constants/informativeIlustrations.constants';
import { LoadingDots, Title } from '@components/common';

const BankAccounts = ({
  bankAccountsList,
  setBankAccountDrawer,
  handleClickBankAccountCard,
  setCurrentBankAccount,
  handleSelectBankAccount,
  handleDeleteBankAccount,
  isLoadingBankAccounts,
}) => {
  const sortBankAccounts = (list) => {
    return list.sort((a, b) => (a.id > b.id ? 1 : -1));
  };
  return !isLoadingBankAccounts ? (
    <div className="bank-accounts__container">
      <div className="personal-data__header">
        {' '}
        <Title type="tertiary" color="primary">
          Cuentas Bancarias:{' '}
        </Title>
        <button
          className="link-element__button"
          onClick={() => {
            setBankAccountDrawer(true);
            setCurrentBankAccount(null);
          }}>
          <i className="fa fa-plus" aria-hidden="true"></i>
        </button>
      </div>

      <hr />
      <div className="bank-accounts__content">
        {bankAccountsList.length == 0 ? (
          <img src={NO_SERVICE_PUBLICATION_ILUSTRATION} />
        ) : (
          sortBankAccounts(bankAccountsList).map((account) => {
            return (
              <BankAccountCard
                account={account}
                handleClickBankAccountCard={handleClickBankAccountCard}
                key={account.id}
                setBankAccountDrawer={setBankAccountDrawer}
                setCurrentBankAccount={setCurrentBankAccount}
                handleSelectBankAccount={handleSelectBankAccount}
                handleDeleteBankAccount={handleDeleteBankAccount}
              />
            );
          })
        )}
      </div>
    </div>
  ) : (
    <LoadingDots />
  );
};

export default BankAccounts;
