import { getUserChats } from 'actions/chat';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthUserInfo } from 'reducers/auth';

function sortChatsByLastMessageDate(array) {
  array.sort(function (a, b) {
    var dateA = new Date(a.last_message.created_at);
    var dateB = new Date(b.last_message.created_at);
    return dateB - dateA;
  });
  return array;
}

const useMyChats = () => {
  const user = useSelector(selectAuthUserInfo);

  const [openedChat, setOpenedChat] = useState();

  const [searchValue, setSearchValue] = useState('');

  const [chats, setChats] = useState([]);

  const [openedChats, setOpenedChats] = useState([]);
  useEffect(() => {
    if (user?.id) {
      getUserChats(user?.id).then((res) => {
        const sorted = sortChatsByLastMessageDate(res?.data);
        setChats(sorted);
        setOpenedChat(sorted?.[0]?.id);
      });
    }
  }, [user]);

  const handleOpenChat = (id) => {
    setOpenedChat(id);
    setOpenedChats((prev) => [...prev, id]);
  };

  const filteredChats = chats?.filter((chat) => {
    return (
      chat?.title?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      chat?.user_name?.toLowerCase().includes(searchValue?.toLowerCase())
    );
  });

  return {
    chats,
    handleOpenChat,
    filteredChats,
    searchValue,
    setSearchValue,
    openedChat,
    openedChats,
  };
};

export default useMyChats;
