import React from 'react';
import { BaseLayout, Button, Title, GraphicContainer } from '@components/common';
import { LANDING_BOTTOM_BACKGROUND } from 'pages/Landing/landing.constants';
import {
  HOWITWORKS_INITIAL_IMAGE1,
  HOWITWORKS_INITIAL_IMAGE2,
  HOWITWORKS_INITIAL_IMAGE3,
} from './HowItWorks.constants';
import { useDispatch } from 'react-redux';
import ListItem from './components/ListItem/ListItem';
import { SwitchTypeSearch } from '@components/common/SwitchTypeSearch';
import { HowItWorksData } from './HowItWorks.constants';
import TimelineObserver from 'react-timeline-animation';
import Timeline from './components/Timeline/Timeline';
import { useHistory } from 'react-router-dom';
import { UPDATE_HOME_SWITCH } from 'actions/types';
import {
  CREATE_PROJECT,
  FAQ_URL,
  HOME_ROUTE,
  HOWITWORKS_HIRE,
  HOWITWORKS_WORK,
} from 'common/constants/routes.constants';
import IconsSection from './components/IconsSection/IconsSection';
import './HowItWorks.scss';
import FaqProject from './components/FAQ/FaqProject/FaqProject';
import FaqService from './components/FAQ/FaqService/FaqService';
import { useParams } from 'react-router-dom';
import AnimateOnView from 'components/AnimateOnView';

const HowItWorksPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { type } = useParams();

  const useUpdateHome = (value) => dispatch({ type: UPDATE_HOME_SWITCH, payload: value });

  const isHiring = type == 'Contratar';
  const bannerBackgroundColor = isHiring ? '#00D5A1' : '#3383FF';
  const handleSwitch = (switchType) => {
    history.push(switchType == 'Contratar' ? HOWITWORKS_HIRE : HOWITWORKS_WORK);
  };

  const handleOnClickProjects = () => {
    useUpdateHome(true);
    history.push(HOME_ROUTE);
  };

  const handleOnClickServices = () => {
    history.push(CREATE_PROJECT);
  };

  const handleOnClickFaq = () => {
    history.push(FAQ_URL);
  };

  const windowClass = !isHiring ? 'primary' : 'secondary';

  return (
    <BaseLayout.Content className="howitworks">
      <div
        style={{
          backgroundImage: `url(${LANDING_BOTTOM_BACKGROUND})`,
        }}
        className="howitworks__container">
        <div className="howitworks__header">
          <AnimateOnView className="howitworks__left">
            <Title className="title__header" type="landing" color="white">
              ¿Cómo funciona TeNecesito?
            </Title>
            <Title className="mobile__header" type="primary" size="40" color="white">
              ¿Cómo funciona TeNecesito?
            </Title>
            <Title type="xl" color="white">
              ¡Encuentra el Freelancer que necesitas!
            </Title>
            <div className="howitworks__buttons-top">
              <SwitchTypeSearch
                textWork="Trabajar"
                textHire="Contratar"
                checkedWork={!isHiring}
                checkedHire={isHiring}
                handleSwitch={handleSwitch}
              />
            </div>
          </AnimateOnView>
          <AnimateOnView className="howitworks__right">
            <GraphicContainer src={HOWITWORKS_INITIAL_IMAGE1} maxHeight="400px" />
          </AnimateOnView>
          <div className="howitworks__buttons">
            <SwitchTypeSearch
              textWork="Trabajar"
              textHire="Contratar"
              checkedWork={!isHiring}
              checkedHire={isHiring}
              handleSwitch={handleSwitch}
            />
          </div>
        </div>
      </div>
      <div className="howitworks__main">
        <div className="howitworks__main__last">
          <Title className="howitworks__data-title" type="xl" color={windowClass}>
            {HowItWorksData[isHiring].title}
          </Title>
          {isHiring && (
            <div className="background__wrapper__hire">
              <TimelineObserver
                initialColor="#e5e5e5"
                fillColor={bannerBackgroundColor}
                handleObserve={(setObserver) => <Timeline setObserver={setObserver} />}
              />
            </div>
          )}
          {!isHiring && (
            <div className="background__wrapper">
              <TimelineObserver
                initialColor="#e5e5e5"
                fillColor={bannerBackgroundColor}
                handleObserve={(setObserver) => <Timeline setObserver={setObserver} />}
              />
            </div>
          )}
          {HowItWorksData[isHiring].list.map((item, index) => (
            <ListItem
              text={item.text}
              image={item.image}
              key={`list-${index}`}
              subtitle1={item.subtitle1}
              subtitle2={item.subtitle2}
              subtitle3={item.subtitle3}
              detail1={item.detail1}
              detail2={item.detail2}
              detail3={item.detail3}
              titleClass="list-item"
              reverse={item.reverse}
            />
          ))}
        </div>
      </div>
      <div style={{ backgroundColor: `${bannerBackgroundColor}` }} className="project__photos">
        <div className="project__photos-container">
          <AnimateOnView className="project__photos-left">
            {isHiring ? (
              <Title type="landing" color="white">
                ¿Ya estás listo/a para publicar tu proyecto?
              </Title>
            ) : (
              <Title size="45" type="landing" color="white">
                ¿Estas listo para empezar a trabajar?
              </Title>
            )}
            <Button
              style={{ color: `${bannerBackgroundColor}` }}
              className="photos__button"
              type="landing-btn"
              variant="primary"
              text={isHiring ? 'Publicar Ahora' : 'Revisar ofertas para mi'}
              onClick={isHiring ? handleOnClickServices : handleOnClickProjects}
            />
          </AnimateOnView>
          <div className="project__photos-right">
            <div className="project__photo-first">
              <AnimateOnView>
                <GraphicContainer src={HOWITWORKS_INITIAL_IMAGE2} maxHeight="200px" />
              </AnimateOnView>
            </div>
            <div className="project__photo-second">
              <AnimateOnView>
                <GraphicContainer src={HOWITWORKS_INITIAL_IMAGE3} maxHeight="300px" />
              </AnimateOnView>
            </div>
          </div>
        </div>
      </div>
      <div className="icons__content">
        <div style={{ color: `${bannerBackgroundColor}` }} className="icons__container">
          {HowItWorksData[isHiring].images.map((item, index) => (
            <AnimateOnView key={`item-${index}-icon`}>
              <IconsSection text={item.text} image={item.image} />
            </AnimateOnView>
          ))}
        </div>
      </div>
      <div className="faq__section">
        <div style={{ border: `1px solid ${bannerBackgroundColor}` }} className="faq__container">
          <AnimateOnView>
            <Title type="landing" size="50" style={{ color: `${bannerBackgroundColor}` }}>
              ¿Todavía tienes dudas?
            </Title>
            <div className="faq__content">{isHiring ? <FaqService /> : <FaqProject />}</div>
            <div className="button__faq">
              {isHiring ? (
                <Button
                  text="Preguntas Frecuentes"
                  type="landing-btn"
                  variant="secondary"
                  onClick={handleOnClickFaq}
                />
              ) : (
                <Button
                  text="Preguntas Frecuentes"
                  variant="primary"
                  color="primary"
                  onClick={handleOnClickFaq}
                />
              )}
            </div>
          </AnimateOnView>
        </div>
      </div>
    </BaseLayout.Content>
  );
};

export default HowItWorksPage;
