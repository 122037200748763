/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react';
import css from './Suggestions.module.scss';
import ServiceSuggestionCard from './ServiceSuggestionCard';
import { ArrowLeft, ArrowRight } from 'react-feather';
import ReactSimplyCarousel from 'react-simply-carousel';

function SuggestionInvite({ suggestionsData, type, currentProject }) {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const nextSlide = () => {
    if (activeSlideIndex < suggestionsData.length - 1) {
      setActiveSlideIndex(activeSlideIndex + 1);
    } else {
      setActiveSlideIndex(0);
    }
  };
  const prevSlide = () => {
    if (activeSlideIndex > 0) {
      setActiveSlideIndex(activeSlideIndex - 1);
    } else {
      setActiveSlideIndex(suggestionsData.length - 1);
    }
  };
  return (
    <div>
      {suggestionsData.length > 0 ? (
        <ReactSimplyCarousel
          activeSlideIndex={activeSlideIndex}
          onRequestChange={setActiveSlideIndex}
          itemsToShow={1}
          itemsToScroll={1}
          speed={200}
          easing="linear"
          responsiveProps={[
            {
              itemsToShow: 2,
              itemsToScroll: 2,
              minWidth: 900,
            },
          ]}
          forwardBtnProps={{
            //here you can also pass className, or any other button element attributes
            style: {
              alignSelf: 'center',
              background: '#3383ff',
              border: 'none',
              borderRadius: '50%',
              color: 'white',
              cursor: 'pointer',
              height: 35,
              lineHeight: 1,
              textAlign: 'center',
              width: 35,
              marginLeft: 10,
            },
            children: <ArrowRight onClick={nextSlide} />,
          }}
          dotsNav={{
            show: true,

            itemBtnProps: {
              style: {
                height: 16,
                width: 16,
                borderRadius: '50%',
                border: 0,
                background: '#ccc',
                margin: '10px 5px',
              },
            },
            activeItemBtnProps: {
              style: {
                height: 16,
                width: 16,
                borderRadius: '50%',
                border: 0,
                background: '#3383ff',
                margin: '10px 5px',
              },
            },
          }}
          backwardBtnProps={{
            //here you can also pass className, or any other button element attributes
            style: {
              alignSelf: 'center',
              background: '#3383ff',
              border: 'none',
              borderRadius: '50%',
              color: 'white',
              cursor: 'pointer',
              height: 35,
              lineHeight: 1,
              textAlign: 'center',
              width: 35,
              marginRight: 10,
            },
            children: <ArrowLeft onClick={prevSlide} />,
          }}>
          {suggestionsData.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  width: 300,
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '0 0.5em',
                }}>
                <ServiceSuggestionCard
                  item={item}
                  type={type}
                  currentProject={currentProject}
                  isInvite
                />
              </div>
            );
          })}
        </ReactSimplyCarousel>
      ) : null}
    </div>
  );
}
export default SuggestionInvite;
