import React, { Fragment } from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import css from './PublicationModal.module.scss';
import { Title } from '@components/common';
import Publication from './Publication';

const PublicationModal = ({ closeModal, showModal }) => {
  return (
    <Fragment>
      <Modal
        centered={true}
        visible={showModal}
        onCancel={closeModal}
        footer={null}
        destroyOnClose={true}
        width={920}>
        <Title type="primary" color="main" size="25" className={css.title}>
          {'¿Qué necesitas?'}
        </Title>
        <Publication closeModal={closeModal} />
      </Modal>
    </Fragment>
  );
};

PublicationModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
};

export default PublicationModal;
