import React from 'react';
import { FormItemCard } from '../../FormItemCard';

import { Input } from 'antd';

const { TextArea } = Input;

const ProyectTitle = ({
  values,
  handleOnChangeTitle,
  currentFocus,
  handleGoBackQuestion,
  handleGoForwardQuestion,
  displayDistinctQuestion,
}) => {
  return (
    <FormItemCard
      name="title"
      title="Elige un título llamativo para tu proyecto"
      subTitle={'Escribe un mínimo de 7 caracteres'}
      isCurrent={currentFocus == 'title'}
      id={'title-container'}
      handleGoForwardQuestion={handleGoForwardQuestion}
      handleGoBackQuestion={handleGoBackQuestion}
      displayDistinctQuestion={displayDistinctQuestion}>
      <TextArea
        placeholder={'¡Buena suerte!'}
        autoSize={{ minRows: 2, maxRows: 2 }}
        maxLength={100}
        showCount
        onChange={(event) => handleOnChangeTitle(event.target.value)}
        name="title"
        value={values['title']}
        required={true}
        disabled={currentFocus != 'title'}
      />
    </FormItemCard>
  );
};

export default ProyectTitle;
