import React from 'react';
import './Footer.scss';
import { CONTACT_PHONE, CONTACT_PHONE_TEXT } from '@common/constants/main.constants';
import { Text, Title } from '@components/common';
import {
  FACEBOOK_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  YOUTUBE_URL,
} from 'common/constants/main.constants';
import { SPONSOR1, SPONSOR2, SPONSOR3, SPONSOR4 } from 'pages/Landing/landing.constants';
import { CMS_FORM, INTEGRATIONS_FORM } from 'common/constants/routes.constants';
import { useLocation } from 'react-router-dom';

export const Footer = () => {
  const location = useLocation();
  const HIDDE_FOOTER_ROUTES = [CMS_FORM, INTEGRATIONS_FORM];
  if (HIDDE_FOOTER_ROUTES.includes(location.pathname)) {
    return null;
  }
  return (
    <div className="footer-container">
      <footer className="new_footer_area bg_color">
        <div className="new_footer_top">
          <div className="custom-container">
            <div className="info-container">
              <div className="first-column-container">
                <Title type="footer">¿Tienes dudas?</Title>
                <Text type="big">
                  Contáctanos por Whatsapp{' '}
                  <span className="f_social_icon">
                    <a
                      href={`https://wa.me/${CONTACT_PHONE}`}
                      className="fab fa-whatsapp"
                      target="_blank"
                      rel="noreferrer"></a>
                  </span>
                </Text>
                <Text type="big" className="contact-desktop">
                  <br />ó llamando al{' '}
                  <a className="contact-desktop">
                    <strong>{CONTACT_PHONE_TEXT}</strong>
                  </a>
                </Text>
                <Text type="big" className="contact-mobile">
                  ó llamando{' '}
                  <span className="f_social_icon">
                    <a href={`tel:+${CONTACT_PHONE}`} className="fa fa-phone"></a>
                  </span>
                </Text>
              </div>
              <div className="how-to-guide-column-container">
                <Title type="footer">¿Cómo funciona?</Title>
                <ul className="list-unstyled f_list">
                  <li>
                    <a href="#/howItWorks/Contratar" rel="noreferrer">
                      Manual de uso para Contratar
                    </a>
                  </li>
                  <li>
                    <a href="#/howItWorks/Trabajar" rel="noreferrer">
                      Manual de uso para Trabajar
                    </a>
                  </li>
                </ul>
              </div>
              <div className="second-column-container">
                <Title type="footer">Ayuda</Title>
                <ul className="list-unstyled f_list">
                  <li>
                    <a href="#/FrequentQuestions">Preguntas Frecuentes</a>
                  </li>
                  <li>
                    <a href="#/Terms&Conditions">Términos y Condiciones </a>
                  </li>
                  <li>
                    <a href="#/Behaviour&Sanctions">Normas y Sanciones</a>
                  </li>
                  <li>
                    <a href="#/Mediation">Mediación</a>
                  </li>
                  <li>
                    <a href="#/PrivacyPolicy">Política de Privacidad</a>
                  </li>
                  <li>
                    <a href="#/DataManagement">Manejo de Datos</a>
                  </li>
                </ul>
              </div>
              <div className="third-column-container">
                <Title type="footer">Redes Sociales</Title>
                <div className="f_social_icon">
                  <a target="blank" href={FACEBOOK_URL} className="fab fa-facebook"></a>
                  <a target="blank" href={INSTAGRAM_URL} className="fab fa-instagram"></a>
                  <a target="blank" href={YOUTUBE_URL} className="fab fa-youtube"></a>
                  <a target="blank" href={LINKEDIN_URL} className="fab fa-linkedin"></a>
                </div>
                <Title type="footer" style={{ marginTop: '2em' }}>
                  Con apoyo de
                </Title>
                <div className="footer_sponsors">
                  <img src={SPONSOR2} alt="sponsor-image2" width="120" />
                  <img src={SPONSOR1} alt="sponsor-image1" width="150" />
                  <img src={SPONSOR3} alt="sponsor-image3" width="150" />
                  <img src={SPONSOR4} alt="sponsor-image4" width="150" />
                </div>
              </div>
            </div>
          </div>
          <div className="footer_bg">
            <div className="footer_bg_one"></div>
            <div className="footer_bg_two"></div>
          </div>
        </div>
        <div className="footer_bottom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-7">
                <Text type="bigDetail" className="mb-0 f_400">
                  © Te Necesito SPA. 2020 All rights reserved.
                </Text>
              </div>
              <div className="col-lg-6 col-sm-5 text-right"></div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};
