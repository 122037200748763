import React, { useEffect, useRef } from 'react';
import './Timeline.scss';

const Timeline = ({ setObserver }) => {
  const timeline0 = useRef(null);
  const timeline1 = useRef(null);
  const timeline2 = useRef(null);
  const timeline3 = useRef(null);
  const timeline4 = useRef(null);
  const timeline5 = useRef(null);
  const circle1 = useRef(null);
  const circle2 = useRef(null);
  const circle3 = useRef(null);
  const circle4 = useRef(null);
  const circle5 = useRef(null);

  useEffect(() => {
    setObserver(timeline0.current);
    setObserver(timeline1.current);
    setObserver(timeline2.current);
    setObserver(timeline3.current);
    setObserver(timeline4.current);
    setObserver(timeline5.current);
    setObserver(circle1.current);
    setObserver(circle2.current);
    setObserver(circle3.current);
    setObserver(circle4.current);
    setObserver(circle5.current);
  }, []);
  return (
    <div className="wrapper">
      <div id="timeline0" ref={timeline0} className="timeline0" />
      <div id="timeline1" ref={timeline1} className="timeline1" />
      <div className="circleWrapper">
        <div id="circle1" ref={circle1} className="circle">
          01
        </div>
      </div>
      <div id="timeline2" ref={timeline2} className="timeline2" />
      <div className="circleWrapper">
        <div id="circle2" ref={circle2} className="circle">
          02
        </div>
      </div>
      <div id="timeline3" ref={timeline3} className="timeline3" />
      <div className="circleWrapper">
        <div id="circle3" ref={circle3} className="circle">
          03
        </div>
      </div>
      <div id="timeline4" ref={timeline4} className="timeline4" />
      <div className="circleWrapper">
        <div id="circle4" ref={circle4} className="circle">
          04
        </div>
      </div>
      <div id="timeline5" ref={timeline5} className="timeline5" />
      <div className="circleWrapper">
        <div id="circle5" ref={circle5} className="circle">
          05
        </div>
      </div>
    </div>
  );
};

export default Timeline;
