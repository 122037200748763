import { ChatWidgetContext } from 'context/ChatWidgetContext';
import { useContext } from 'react';

const useChatWidget = () => {
  const context = useContext(ChatWidgetContext);

  return context;
};

export default useChatWidget;
