import React from 'react';
import { SummaryRequest } from './SummaryRequest/SummaryRequest';
import './OtherRequests.scss';

export const OtherRequests = ({ requestsList, currentRequest, handleSwitchRequest, isLoading }) => {
  const [currentRequestId, setCurrentRequestId] = React.useState(currentRequest?.id);

  const isCurrent = (requestId) => currentRequestId === requestId;

  React.useEffect(() => {
    setCurrentRequestId(currentRequest?.id);
  }, [currentRequest]);

  return (
    <div className="other-requests__container">
      <section className="other-requests__scroll-content">
        {requestsList?.map((request, index) => (
          <SummaryRequest
            request={request}
            key={index}
            handleSwitchRequest={(requestId) => handleSwitchRequest(requestId)}
            isCurrent={(requestId) => isCurrent(requestId)}
            isLoading={isLoading}
          />
        ))}
      </section>
    </div>
  );
};
