import React from 'react';
import { Form, Input, Select } from 'antd';

const { Option } = Select;

import { COUNTRY_PREFIX_LISTS } from 'common/constants/registercountries.constants';

import css from './PhoneNumberInput.module.scss';

function PhoneNumberInput({
  prefix,
  setPrefix,
  setCurrentPhoneNumber,
  currentPhoneNumber,
  noPrefixCountry,
  required = true,
}) {
  const handleChangePrefix = (value) => {
    setPrefix(value);
  };

  const handleChangeNumber = (e) => {
    setCurrentPhoneNumber(e.target.value);
  };

  return (
    <div className={css.form}>
      <Form.Item
        className={css.prefixSection}
        name="phone_prefix"
        rules={[
          {
            required: required,
            message: 'Por favor selecciona el prefijo.',
          },
        ]}>
        <Select placeholder="Prefijo" value={prefix} onChange={handleChangePrefix}>
          {COUNTRY_PREFIX_LISTS.map((country, key) => (
            <Option value={country.prefix} key={`opt-${key}`}>
              {noPrefixCountry ? country.prefix : country.name + ' ' + country.prefix}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        className={css.numberSection}
        name="phone_number"
        rules={[
          {
            required: required,
            message: 'Por favor ingresa tu número de teléfono.',
          },
        ]}>
        <Input
          placeholder="Número de teléfono"
          type="number"
          onChange={handleChangeNumber}
          value={currentPhoneNumber}
        />
      </Form.Item>
    </div>
  );
}

export default PhoneNumberInput;
