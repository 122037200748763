import React from 'react';
import classnames from 'classnames';
import './ListCardLayout.scss';
import { LoadingDots } from '@components/common/';

const ListCardLayout = ({ children, isLoading, className = '', ...rest }) => {
  return (
    <div className={classnames('project-layout', className)} {...rest}>
      {!isLoading ? children : <LoadingDots />}
    </div>
  );
};

const ListCardLayoutLeftSide = ({ children, className, ...rest }) => {
  const classes = classnames('project-layout__left', className);

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

const ListCardLayoutRightSide = ({ children, className, ...rest }) => {
  const classes = classnames('project-layout__right', className);

  return (
    <div className={classes} {...rest}>
      <hr />
      {children}
    </div>
  );
};

const ProjectCardLayoutButtons = ({ children, className, ...rest }) => {
  const classes = classnames('project-layout__buttons', className);

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

const ProjectCardLayoutBottom = ({ children, className, ...rest }) => {
  const classes = classnames('project-layout__bottom', className);

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

ListCardLayout.LeftSide = ListCardLayoutLeftSide;
ListCardLayout.RightSide = ListCardLayoutRightSide;
ListCardLayout.Buttons = ProjectCardLayoutButtons;
ListCardLayout.Bottom = ProjectCardLayoutBottom;

export default ListCardLayout;
