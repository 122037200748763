/* eslint-disable no-unused-vars */
import { useMediaQuery } from '@material-ui/core';
import { Card } from 'antd';
import { FileContainer, ProjectDetails, PublicationQuestions } from 'components/common';
import CustomTabs from 'components/common/CustomTabs';
import NewChat from 'components/common/NewChat';

import React from 'react';
import { BookOpen, MessageSquare, Monitor, User } from 'react-feather';

import { Request } from 'components/requests/EditRequestDrawer/Request';
import { ProfileSection } from 'components/project/ApplicantModal/ProfileSection';
import useQueryParams from 'hooks/useQueryParams';
import { QuestionAnswer } from '@material-ui/icons';

const FREELANCERREQUEST_TABS = {
  PROYECT: 'Detalles del proyecto',
  QUESTIONS: 'Preguntas',
  PROPOSAL: 'Propuesta',
  MESSAGES: 'Mensajes',
  PROFILE: 'Perfil',
};

const FreelancerRequestTabs = ({
  authUser,
  currentProject,
  currentRequest,
  currentProposal,
  isLoading,
  currentChat,
  handleGetFiles,
  isLoadingFiles,
  files,
  useImageUploadHandler,
}) => {
  //get query params
  const params = useQueryParams();
  const tab = params.get('tab') || FREELANCERREQUEST_TABS.PROYECT;

  const Mobile = useMediaQuery('(max-width:596px)');
  const freelancerRequestTabs = [
    {
      name: FREELANCERREQUEST_TABS.PROYECT,
      icon: <Monitor />,
      component: (
        <Card style={{ borderRadius: '0em 1em 1em 1em' }}>
          <ProjectDetails currentProject={currentProject} noTitle />
        </Card>
      ),
    },
    {
      name: FREELANCERREQUEST_TABS.PROPOSAL,
      icon: <BookOpen />,
      component: (
        <Card style={{ borderRadius: '0em 1em 1em 1em', padding: '0 10px' }}>
          <Request
            editable={currentProject?.status === 'published'}
            withHeader={false}
            withProjectTitle={false}
            currentProject={currentProject}
            authUser={authUser}
            currentRequest={currentRequest}
            currentProposal={currentProposal}
            isLoading={isLoading}
          />
        </Card>
      ),
    },
    {
      name: FREELANCERREQUEST_TABS.PROFILE,
      icon: <User />,
      component: (
        <Card style={{ borderRadius: '0em 1em 1em 1em', padding: '0 10px' }}>
          <ProfileSection currentRequester={currentRequest?.user_data} type="freeelancer" />
        </Card>
      ),
    },
    {
      name: FREELANCERREQUEST_TABS.MESSAGES,
      icon: <MessageSquare />,
      //   notifications: currentRequester?.new_messages > 0 && currentRequester?.new_messages,
      //   onClick: null,
      component: (
        <Card style={{ borderRadius: '0em 1em 1em 1em', padding: '10px' }}>
          <NewChat
            chatID={currentChat?.id}
            participants={currentChat?.participants_id}
            currentChat={currentChat}
            handleGetFiles={handleGetFiles}
            chatHeight={405}
            currentProject={currentProject}
            archivos={
              <FileContainer
                userId={authUser?.user.id}
                ownerImage={authUser?.user.profile_image}
                freelanceImage={currentRequest.user_data.profile_image}
                isLoadingFiles={isLoadingFiles}
                files={files}
                onUpload={useImageUploadHandler}
                withButton={false}
              />
            }
          />
        </Card>
      ),
    },
  ];
  return <CustomTabs tabs={freelancerRequestTabs} toggleIcons={Mobile} selected={tab} />;
};

export default FreelancerRequestTabs;
