import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLaptop,
  faClipboardUser,
  faPeopleGroup,
  faFileCircleQuestion,
  faChartPie,
  faBullhorn,
  faCalendarDays,
  faHeadset,
  faComments,
  faLocationDot,
  faGlobe,
  faPhotoFilm,
  faUser,
  faMoneyCheckDollar,
  faListCheck,
  faStar,
  faBell,
  faThumbsUp,
  faShareNodes,
  faShield,
  faCloudArrowDown,
  faBan,
  faPersonCircleQuestion,
  faTableList,
  faCodeCompare,
  faFileCode,
  faChartLine,
  faPlus,
  faLink,
  faList,
} from '@fortawesome/free-solid-svg-icons';
import {
  PERSON_2_ILUSTRATION,
  PERSON_3_ILUSTRATION,
  PERSON_4_ILUSTRATION,
  PERSON_5_ILUSTRATION,
  PERSON_6_ILUSTRATION,
  PERSON_7_ILUSTRATION,
  PERSON_8_ILUSTRATION,
} from 'common/constants/informativeIlustrations.constants';

export const websiteFunctionalitiesOptions = [
  { id: 1, name: 'Página de inicio.', icon: <FontAwesomeIcon icon={faLaptop} size="2x" /> },
  {
    id: 2,
    name: 'Formulario de contacto.',
    icon: <FontAwesomeIcon icon={faClipboardUser} size="2x" />,
  },
  {
    id: 3,
    name: 'Acerca de nosotros.',
    icon: <FontAwesomeIcon icon={faPeopleGroup} size="2x" />,
  },
  {
    id: 4,
    name: 'Preguntas frecuentes',
    icon: <FontAwesomeIcon icon={faFileCircleQuestion} size="2x" />,
  },
  {
    id: 5,
    name: 'Dashboards y analytics',
    icon: <FontAwesomeIcon icon={faChartPie} size="2x" />,
  },
  { id: 6, name: 'Blog', icon: <FontAwesomeIcon icon={faBullhorn} size="2x" /> },
  { id: 7, name: 'Calendario', icon: <FontAwesomeIcon icon={faCalendarDays} size="2x" /> },
  { id: 8, name: 'Reservaciones', icon: <FontAwesomeIcon icon={faHeadset} size="2x" /> },
  { id: 9, name: 'Chat en vivo', icon: <FontAwesomeIcon icon={faComments} size="2x" /> },
  { id: 10, name: 'Mapas', icon: <FontAwesomeIcon icon={faLocationDot} size="2x" /> },
  { id: 11, name: 'Georeferenciación', icon: <FontAwesomeIcon icon={faGlobe} size="2x" /> },
  {
    id: 12,
    name: 'Galería y multimedia',
    icon: <FontAwesomeIcon icon={faPhotoFilm} size="2x" />,
  },
  {
    id: 13,
    name: 'Cuentas de usuarios',
    icon: <FontAwesomeIcon icon={faUser} size="2x" />,
  },
  {
    id: 14,
    name: 'Pagos por la plataforma',
    icon: <FontAwesomeIcon icon={faMoneyCheckDollar} size="2x" />,
  },
  {
    id: 15,
    name: 'Integración con RRSS',
    icon: <FontAwesomeIcon icon={faShareNodes} size="2x" />,
  },
  {
    id: 16,
    name: 'Formulario de subscripción',
    icon: <FontAwesomeIcon icon={faListCheck} size="2x" />,
  },
  {
    id: 17,
    name: 'Términos y condiciones',
    icon: <FontAwesomeIcon icon={faShield} size="2x" />,
  },
  {
    id: 18,
    name: 'Valoraciones y ratings',
    icon: <FontAwesomeIcon icon={faStar} size="2x" />,
  },
  { id: 19, name: 'Notificaciones', icon: <FontAwesomeIcon icon={faBell} size="2x" /> },
  { id: 20, name: 'Testimonios', icon: <FontAwesomeIcon icon={faThumbsUp} size="2x" /> },
  { id: 21, name: 'Listado de productos', icon: <FontAwesomeIcon icon={faList} size="2x" /> },
  {
    id: 22,
    name: 'Otras funcionalidades',
    icon: <FontAwesomeIcon icon={faPersonCircleQuestion} size="2x" />,
  },
];

export const actualStateOptions = [
  { id: 1, name: 'Tengo una idea aproximada de lo que necesito' },
  { id: 2, name: 'Tengo una idea clara, pero no tengo especificaciones escritas o esquemas' },
  { id: 3, name: 'Tengo especificaciones, diseño y/o flujo definido' },
  { id: 4, name: 'Necesito modificar o integrar algo a un proyecto existente' },
  { id: 5, name: 'Otro estado' },
  { id: 6, name: 'No lo sé o no estoy seguro' },
];

export const compatibilityOptions = [
  { id: 1, name: 'Android' },
  { id: 2, name: 'IOS' },
  { id: 3, name: 'Ambos, Android y IOS' },
  { id: 4, name: 'Necesito migrar mi app de un sistema operativo a otro' },
  { id: 5, name: 'Otro' },
  { id: 6, name: 'No lo sé o no estoy seguro' },
];

export const websiteTypeOptions = [
  { id: 1, name: 'Tienda Online' },
  { id: 2, name: 'Portafolio' },
  { id: 3, name: 'Blog' },
  { id: 4, name: 'Marketplace' },
  { id: 5, name: 'Red social' },
  { id: 6, name: 'Plataforma Saas' },
  { id: 7, name: 'Control de gestión' },
  { id: 8, name: 'Sitio web corporativo' },
  { id: 9, name: 'Otro tipo' },
  { id: 10, name: 'No lo sé o no estoy seguro' },
];

export const apiIntegrationsOptions = [
  {
    id: 1,
    name: 'Redes sociales (Facebook, Twitter, etc)',
    icon: <FontAwesomeIcon icon={faShareNodes} size="2x" />,
  },
  {
    id: 2,
    name: 'Pasarelas de pago (Webpay, Paypal, Khipu, etc)',
    icon: <FontAwesomeIcon icon={faMoneyCheckDollar} size="2x" />,
  },
  {
    id: 3,
    name: 'Cloud Storage (Dropbox, Google Drive, etc)',
    icon: <FontAwesomeIcon icon={faCloudArrowDown} size="2x" />,
  },
  { id: 4, name: 'Otras APIs', icon: <FontAwesomeIcon icon={faLink} size="2x" /> },
  {
    id: 5,
    name: 'No necesito ninguna integración',
    icon: <FontAwesomeIcon icon={faBan} size="2x" />,
  },
  {
    id: 6,
    name: 'No lo sé o no estoy seguro',
    icon: <FontAwesomeIcon icon={faPersonCircleQuestion} size="2x" />,
  },
];

export const timeRangeOptions = [
  { id: 1, name: 'No tengo apuro (más de 3 meses)' },
  { id: 2, name: 'Entre 1 y 3 meses' },
  { id: 3, name: 'Entre 15 y 30 días' },
  { id: 4, name: 'Menos de 15 días' },
  { id: 5, name: 'No lo sé o no estoy seguro' },
];

export const budgetRangeOptions = [
  { id: 1, name: 'Menos de $500.000' },
  { id: 2, name: 'Entre $500.000 y $1.000.000' },
  { id: 3, name: 'Entre $1.000.000 y $2.000.000' },
  { id: 4, name: 'Más de $2.000.000' },
  { id: 5, name: 'No lo sé o no estoy seguro' },
];

export const actualDBStatusOptions = [
  { id: 1, name: 'No tengo datos' },
  { id: 2, name: 'Planillas excel' },
  { id: 3, name: 'Base de datos consolidadas' },
  { id: 4, name: 'Data Warehouse' },
  { id: 5, name: 'Otro' },
  { id: 6, name: 'No lo sé o no estoy seguro' },
];

export const modelGoalOptions = [
  { id: 1, name: 'Consolidar datos', icon: <FontAwesomeIcon icon={faTableList} size="2x" /> },
  {
    id: 2,
    name: 'Visualizar información.',
    icon: <FontAwesomeIcon icon={faChartPie} size="2x" />,
  },
  {
    id: 3,
    name: 'Entender algún fenomeno en base a los datos',
    icon: <FontAwesomeIcon icon={faCodeCompare} size="2x" />,
  },
  {
    id: 4,
    name: 'Recaudar información de las web',
    icon: <FontAwesomeIcon icon={faFileCode} size="2x" />,
  },
  { id: 5, name: 'Modelo predictivo', icon: <FontAwesomeIcon icon={faChartLine} size="2x" /> },
  { id: 6, name: 'Otro objetivo', icon: <FontAwesomeIcon icon={faPlus} size="2x" /> },
  {
    id: 7,
    name: 'No lo sé o no estoy seguro',
    icon: <FontAwesomeIcon icon={faPersonCircleQuestion} size="2x" />,
  },
];
export const questionTitle = {
  actual_state: 'Estado actual del proyecto',
  category: 'Categoría del proyecto',
  sub_category: 'Subcategoría del proyecto',
  website_type: 'Tipo de página',
  reference_urls: 'Sitios de referencia',
  website_functionalities: 'Funcionalidades',
  compatibility: 'Compatibilidad',
  actual_db_status: 'Estado actual de la base de datos',
  model_goal: 'Objetivos del proyecto',
  api_integration: 'APIs',
  time_range: 'Urgencia',
  budget_range: 'Presupuesto',
  description: 'Descripción',
  skills: 'Habilidades',
  images: 'Imágenes',
  title: 'Título del proyecto',
  finalPreview: 'Vista previa',
};

export const adviceText = {
  category: {
    title: 'En TeNecesito puedes encontrar un programador freelancer para lo que necesites.',
    paragraphs: [
      'Puedes desarrollar con ellos desde un sitio web o tienda más estándar, ¡hasta todo lo que se te ocurra en tecnología!',
      'Si lo que necesitas es desarrollar una web o una solución digital desde cero, adaptada específicamente para satisfacer las necesidades de tu negocio o para un público objetivo, te recomendamos seleccionar “Una aplicación web o de escritorio a medida”.',
      'Ahora te pediremos contestar una breve serie de preguntas para ayudarte a explicar el proyecto y simplificar tu comunicación con los programadores.',
      '¡No te preocupes si sientes que te falta incluir más información! Un poco más abajo podrás escribir y explicar todo lo que necesitas.',
    ],
    image: PERSON_2_ILUSTRATION,
  },
  actual_state: {
    title: 'Siempre es bueno para nuestros freelancers conocer bien el alcance de un proyecto ',
    paragraphs: [
      'De esta forma, pueden realizar mejores propuestas y evitar malos entendidos.',
      'En general, mientras más especificaciones tienes del proyecto, más fácil será recibir propuestas y compararlas.',
      'Pero, si solo tienes una idea, ¡no te preocupes! Los distintos freelancers tendrán la oportunidad de darte su visión experimentada sobre lo que necesitas y podrás conversar directamente con ellos.',
    ],
    image: PERSON_3_ILUSTRATION,
  },

  subCategory1: {
    title: '¿Qué CMS utilizar?',
    paragraphs: [
      'Para desarrollar sitios webs que son más estándar, se recomienda el uso de los Sistemas de gestión de contenidos o CMS (del inglés Content Management System). Son sistemas online que permiten poner en marcha una página web de forma sencilla y rápida.',
      'Si necesitas un sitio web corporativo, los más populares son Wordpress, Joomla, Drupal o Wix.',
      'Si necesitas un blog, los más populares son Blogger, Wordpress, Joomla o Drupal.',
      'Si necesitas un e-commerce, los más populares son Shopify, Woocommerce, Prestashop o Wordpress.',
      'Si no sabes cuál podría ser indicado para tu proyecto ¡no te preocupes! Los freelancers te asesorarán en sus propuestas y podrás tomar la mejor decisión.',
    ],
    image: PERSON_4_ILUSTRATION,
  },
  subCategory2: {
    title: '¿Para qué sirven los frameworks?',
    paragraphs: [
      'Un framework es una herramienta de desarrollo que, por lo general, se define como una aplicación o conjunto de módulos que permiten el desarrollo ágil de aplicaciones mediante la aportación de librerías y/o funcionalidades ya creadas. ',
      'Los framework hacen que el desarrollador no esté continuamente “reinventado la rueda”, y se centre en el problema que quiere resolver y no en la implementación de funcionalidades, que normalmente son de uso común y que ya están resueltas por otros.',
      'Si no sabes cuál podría ser indicado para tu proyecto no te preocupes! Los freelancers te asesorarán en sus propuestas y podrás tomar la mejor decisión.',
    ],
    image: PERSON_4_ILUSTRATION,
  },
  subCategory3: {
    title: 'Queremos saber un poco más de tu proyecto',
    paragraphs: [
      'Como te habíamos contado, en TeNecesito puedes encontrar tu solución tecnológica ¡en lo que se te ocurra!',
      'Para poder guiar a los freelancer interesados en tu proyecto, por favor selecciona las opciones que más se aproxime a lo que necesitas.',
    ],
    image: PERSON_4_ILUSTRATION,
  },
  website_type: {
    title: 'Cuéntanos de tu objetivo',
    paragraphs: [
      'Para poder guiar a los freelancer interesados en tu proyecto, por favor selecciona las opciones que más se aproxime a la aplicación que buscas desarrollar.',
      'Conocer el tipo de aplicación permite a los programadores "imaginarse" el problema que necesitan solucionar de mejor forma. Además, aumentará tus propuestas de parte de freelancers especializados en el tema.',
    ],
    image: PERSON_5_ILUSTRATION,
  },
  reference_urls: {
    title: 'Ingresa tus sitios preferidos.',
    paragraphs: [
      'Las referencias ayudan a que el freelancer pueda imaginarse mejor lo que se necesita.',
      'Además, ayudan a entender de mejor manera tus preferencias en cuanto a diseño y estructura',
    ],
    image: PERSON_6_ILUSTRATION,
  },
  website_functionalities: {
    title: '¡Esto se puso entretenido! Armemos tu plataforma',
    paragraphs: [
      'En este menú tienes las funcionalidades más comunes que pueden tener los sitios web.',
      'Selecciona todas las que consideres necesarias para tu proyecto, nosotros ya empezamos por ti con:',
      '- Página de inicio',
      '- Formulario de contacto',
      'Si necesitas algo que no esté en esta pregunta, ¡no te preocupes! Podrás describirlo más adelante.',
    ],
    image: PERSON_7_ILUSTRATION,
  },
  compatibility: {
    title: '¿Cuál es la diferencia?',
    paragraphs: [
      'Cuando hablamos sobre los sistemas operativos, hay dos jugadores que dominan el mercado: Android (Google) y iOS (Apple).',
      'Android es usado por marcas como Samsung, LG, Xiaomi, Huawei, entre otros. Por otro lado, iOS se utiliza en iPhones y iPads.',
      'El proceso de desarrollo de aplicaciones móviles variará según el sistema operativo seleccionado, desde los lenguajes y entornos de programación, hasta temas relacionados con el diseño, usabilidad e interacciones que deben tenerse en cuenta al crear una aplicación.',
    ],
    image: PERSON_8_ILUSTRATION,
  },
  actual_db_status: {
    title: 'Estado de tus datos',
    paragraphs: [
      'Cuéntale a los freelancers si tu proyecto considera datos que ya tienes recolectados o si la idea es empezar a trabajarlos con la aplicación.',
    ],
    image: PERSON_8_ILUSTRATION,
  },
  model_goal: {
    title: 'Cuéntanos de tu objetivo',
    paragraphs: [
      'Para poder guiar a los freelancer interesados en tu proyecto, por favor selecciona las opciones que más se aproxime a lo que necesitas.',
    ],
    image: PERSON_2_ILUSTRATION,
  },
  api_integration: {
    title: '¿Qué es una API y para qué sirven?',
    paragraphs: [
      'Se puede definir como una interfaz que favorece la comunicación entre dos sistemas o plataformas diferentes, permitiendo agregar diversas funciones a sitios web y aplicaciones.',
      'Para entender el funcionamiento de una API hay que entender que no es un software que funciona independientemente sino que es una extensión, sino que requiere de un sistema, una aplicación o una plataforma en la cual agruparse. Las APIs deben integrarse a un sistema para funcionar.',
      'Se debe tener en cuenta que el uso de algunas APIs podría incurrir en costos extras o comisiones de terceros, como es el caso de las pasarelas de pago.',
    ],
    image: PERSON_3_ILUSTRATION,
  },
  time_range: {
    title: '¡Ya casi terminamos!',
    paragraphs: [
      'Esta información ayudará a filtrar los freelancers según su disponibilidad, y así tener tu proyecto justo para cuando lo necesites!',
    ],
    image: PERSON_4_ILUSTRATION,
  },
  budget_range: {
    title: '¡Ya casi terminamos!',
    paragraphs: [
      'Definir tu presupuesto no siempre es fácil, ¡pero no te preocupes! Recibirás varias propuestas y podrás comparar los precios de los distintos freelancers.',
      'Y si aún tienes dudas, el equipo de TeNecesito está siempre disponible para que nos cuentes tu idea y te podemos orientar de cuánto podría costarte según nuestra experiencia.',
      'Ten en cuenta que algunos desarrollos podrían necesitar algunos gastos extras como servidor y/o dominio. Estos recursos no son entregados por TeNecesito, los freelancers son expertos y pueden ayudarte a encontrar la mejor opcion para tí.',
    ],
    image: PERSON_5_ILUSTRATION,
  },
  description: {
    title: '¡Se lo más detallado posible!',
    paragraphs: [
      'Entre menos detalles queden al aire mejores presupuestos te llegarán.',
      'De todas formas, más adelante podrás chatear con los freelancers y aclarar cualquier duda que tengan',
    ],
    image: PERSON_6_ILUSTRATION,
  },
  skills: {
    title: 'Elige de nuestras opciones para ti',
    paragraphs: [
      '¡Ahorra tiempo en tu selección y evita malos entendidos!',
      'Los freelancers utilizarán las habilidades para encontrar los proyectos en que están más interesados y tienen experiencia',
      'Puedes elegir un máximo de 5 habilidades.',
    ],
    image: PERSON_3_ILUSTRATION,
  },
  images: {
    title: 'Una imagen vale más que mil palabras',
    paragraphs: [
      'Adjunta cualquier imagen que creas le pueda servir al freelancer para entender mejor el proyecto.',
      'Puede ser algún diseño o flujo de la plataforma.',
      '¡Puede ser hasta un bosquejo dibujado con lapiz y papel!',
    ],
    image: PERSON_3_ILUSTRATION,
  },
  title: {
    title: 'Elige un título llamativo',
    paragraphs: [
      'Un título llamativo ayuda a conseguir más y mejores propuestas.',
      'Busca uno que resuma brevemente lo que necesitas, trata de utilizar palabras clave del proyecto para que sea fácil de encontrar',
    ],
    image: PERSON_3_ILUSTRATION,
  },
  finalPreview: {
    title: '¡Buena suerte!',
    paragraphs: [
      'Te deseamos mucho éxito en la busqueda de freelancer y en el desarrollo del proyecto.',
      'Recuerda que siempre puedes escribirnos a nuestro Whatsapp a través del botón que se encuntra en la esquina inferior derecha.',
      'Si tienes cualquier duda sobre tu proyecto, te ofrecemos acesorías gratis sin compromiso.',
    ],
    image: PERSON_3_ILUSTRATION,
  },
};

export const titleSubcategory = {
  'cms-from-scratch': '¿Tienes alguna plataforma de preferencia?',
  'cms-integration': '¿En qué plataforma está desarrollada tu página?',
  'webapp-from-scratch': '¿Tienes algún lenguaje o framework de preferencia?',
  'webapp-integration': '¿En qué lenguaje o framework está desarrollada tu aplicación?',
  'mobileapp-from-scratch': '¿Tienes algún lenguaje o framework de preferencia?',
  'mobileapp-integration': '¿En qué lenguaje o framework está desarrollada tu aplicación?',
  'data-model-from-scratch': '¿Cuáles de estas opciones se acercan más a lo que necesitas?',
  'data-model-integration': '¿Cuáles de estas opciones se acercan más a lo que necesitas?',
  'other-from-scratch': '¿Cuáles de estas opciones se acercan más a lo que necesitas?',
  'other-integration': '¿Cuáles de estas opciones se acercan más a lo que necesitas?',
};

export const titleWebsiteType = {
  'cms-from-scratch': '¿Qué tipo de página necesitas?',
  'cms-integration': '¿Qué tipo de página tienes?',
  'webapp-from-scratch': '¿Qué tipo de página necesitas?',
  'webapp-integration': '¿Qué tipo de página tienes?',
  'mobileapp-from-scratch': '¿Qué tipo de página necesitas?',
  'mobileapp-integration': '¿Qué tipo de página tienes?',
  'data-model-from-scratch': '¿Qué tipo de página necesitas?',
  'data-model-integration': '¿Qué tipo de página tienes?',
  'other-from-scratch': '¿Qué tipo de página necesitas?',
  'other-integration': '¿Qué tipo de página tienes?',
};

export const variableQuestionsOrder = {
  'cms-from-scratch': ['website_type', 'reference_urls', 'website_functionalities'],
  'cms-integration': ['website_type', 'reference_urls'],
  'webapp-from-scratch': ['website_type', 'reference_urls', 'website_functionalities'],
  'webapp-integration': ['website_type', 'reference_urls'],
  'mobileapp-from-scratch': [
    'website_type',
    'reference_urls',
    'website_functionalities',
    'compatibility',
  ],
  'mobileapp-integration': ['website_type', 'reference_urls'],
  'data-model-from-scratch': ['actual_db_status', 'model_goal'],
  'data-model-integration': ['actual_db_status', 'model_goal'],
  'other-from-scratch': [],
  'other-integration': [],
};

export const categoryText = {
  //1: 'Una página web, una tienda online (e-commerce) o un blog',
  12: 'Una aplicación web o de escritorio a medida',
  22: 'Una App móvil (Android, iOS y otros)',
  31: 'Trabajar con datos y modelos (Data Science)',
  40: 'Otros (desarrollo de videojuegos, VR, ciberseguridad, etc)',
};
