/* eslint-disable no-unused-vars */
import {
  getAllPalettes,
  getIntegrationsForm,
  sendAskToFormTracker,
  uploadPaletteAndTemplates,
} from 'actions/forms';
import {
  uploadCMSProject,
  editProject_v2,
  uploadFile,
  uploadIntegrationProject,
  sendProjectPrompt,
  sendProjectPromptFreelance,
  sendProjectPromptClient,
} from 'actions/projects';
import { CLIENT_PUBLICATIONS_ROUTE } from 'common/constants/routes.constants';
import useAuthButtons from 'hooks/useAuthButtons';
import useNewForms from 'pages/CMSForm/hooks/useNewForms';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const IntegrationsFormViewModel = () => {
  const history = useHistory();
  const [allQuestions, setAllQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);

  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);

  const { setShowAuthModal } = useAuthButtons();

  const [loadingText, setLoadingText] = useState('Publicando proyecto...');

  const {
    handleSetAnswer,
    handleNext,
    handleBack,
    canGoNext,
    isOptionSelected,
    hasOptionSelected,
    update,
    index,
    answers,
    isEdit,
    formTrackerId,
    user,
    formAdapterFromApiToUi,
    isLoadingAnswers,
    currentQuestionAnswer,
    answersReturns,
    parseAnswersToPromptForFreelancers,
    id,
  } = useNewForms({
    questions,
    isLoadingQuestions,
    allQuestions,
    setQuestions,
    categoriesKey: 'integration_categories',
    type: 'integration',
  });

  useEffect(() => {
    setIsLoadingQuestions(true);
    getIntegrationsForm()
      .then((res) => {
        setAllQuestions(formAdapterFromApiToUi(res));
        setQuestions(formAdapterFromApiToUi(res));
      })
      .finally(() => {
        setIsLoadingQuestions(false);
      });
  }, []);

  const formAdapterFromUiToApi = async (answers) => {
    let request = {};
    for (const answer of answers) {
      request[answer?.key] = answersReturns(answer);
    }

    return { ...request, category: request?.actual_framework };
  };

  const handleSendForm = async () => {
    setIsPublishing(true);

    const request = await formAdapterFromUiToApi(answers);
    if (isEdit) {
      const images = answers.find((a) => a?.type === 'images');
      if (images && !images?.isEdit) {
        await uploadImages(id);
      }

      await editProject_v2(id, { ...request });
      history.push(`${CLIENT_PUBLICATIONS_ROUTE}/${id}`);
    } else {
      if (user) {
        const ask = {
          form: formTrackerId,
          question: questions[index]?.title,
          description: questions[index]?.description,
          options: [currentQuestionAnswer?.answers?.[0]],
        };
        try {
          await sendAskToFormTracker(ask);
        } catch (err) {
          console.log(err);
        }
        try {
          const request = await formAdapterFromUiToApi(answers);
          const res = await uploadIntegrationProject(request);
          const projectId = res?.data?.project?.id;
          await uploadImages(projectId);

          // try {
          //   setLoadingText('Analizando respuestas...');
          //   const prompt = parseAnswersToPromptForFreelancers();
          //   const prompt1 = sendProjectPromptClient({ project: projectId, prompt });
          //   const prompt2 = sendProjectPromptFreelance({ project: projectId, prompt });
          //   await Promise.all([prompt1, prompt2]);
          // } catch (error) {
          //   console.log(error);
          // }

          history.push(`${CLIENT_PUBLICATIONS_ROUTE}/${projectId}?invites=true`);
        } catch (error) {
          console.log(error);
        }
      } else {
        setShowAuthModal(true);
      }
    }

    setIsPublishing(false);
  };

  const uploadImages = async (projectId) => {
    const images = answers.filter((a) => a?.type === 'images');
    if (images?.length > 0) {
      const files = images?.[0].answers?.[0];

      for (const key in files) {
        let formData = new FormData();
        formData.append('file', files[key]);
        formData.append('filename', files[key]?.name);
        formData.append('project', projectId);
        formData.append('publication', true);
        formData.append('type', 'project');
        await uploadFile(formData);
      }
    }
  };

  return {
    answers,
    index,
    handleBack,
    hasOptionSelected,
    handleNext,
    update,
    handleSetAnswer,
    isOptionSelected,
    questions,
    handleSendForm,
    isLoadingQuestions,
    isPublishing,
    canGoNext,
    isLoadingAnswers,
    isEdit,
    user,
    currentQuestionAnswer,
    loadingText,
  };
};

export default IntegrationsFormViewModel;
