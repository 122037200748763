import React, { Fragment } from 'react';
import './ProposalSection.scss';
import { LoadingDots, Button, Text, Title } from 'components/common';
import { ViewRequestBreakdown } from 'components/requests/ViewRequestDrawer/ViewRequestBreakdown/ViewRequestBreakdown';

const ProposalSection = ({
  currentRequest,
  currentProposal,
  isLoadingProposal,
  handleRejectRequest,
  handleAcceptProposal,
}) => {
  const published = currentRequest?.status == 'created';
  return (
    <div className="proposal-section__container">
      {isLoadingProposal ? (
        <LoadingDots />
      ) : (
        <Fragment>
          <div className="proposal-section__item">
            <Title type="secondary" color="primary">
              {`Por qué ${currentRequest?.emitter_data.first_name} es la persona indicada para tu proyecto: `}
            </Title>
            <Text>{currentRequest?.why_you}</Text>
          </div>
          <div className="proposal-section__item">
            {' '}
            <Title type="secondary" color="primary">
              Descripción de la propuesta
            </Title>
            <Text>{currentProposal?.text}</Text>
          </div>
          <div className="proposal-section__item">
            <Title type="secondary" color="primary">
              Desglose de la propuesta
            </Title>
            <div className="proposal-section__request-breakdown">
              <ViewRequestBreakdown currentProposal={currentProposal} />
            </div>

            {published && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button
                  text="Rechazar propuesta"
                  variant="secondary"
                  borderColor="error"
                  size="sm"
                  onClick={handleRejectRequest}
                />
                <Button
                  text="Aceptar propuesta"
                  variant="primary"
                  size="sm"
                  onClick={handleAcceptProposal}
                />
              </div>
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default ProposalSection;
