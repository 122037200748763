import React from 'react';
import { FormItemCard } from '../../FormItemCard';
import { compatibilityOptions } from 'common/constants/publicationForm.constants';
import { Radio, Space } from 'antd';
import { Text } from '@components/common';

const Compatibility = ({
  values,
  handleSelectCompatibility,
  currentFocus,
  handleGoBackQuestion,
  handleGoForwardQuestion,
  displayDistinctQuestion,
}) => {
  return (
    <FormItemCard
      name="compatibility"
      title={'¿Tu aplicación necesita compatiblidad con alguna de estas opciones?'}
      subTitle={'Elige una opción'}
      isCurrent={currentFocus == 'compatibility'}
      id={'compatibility-container'}
      handleGoForwardQuestion={handleGoForwardQuestion}
      handleGoBackQuestion={handleGoBackQuestion}
      displayDistinctQuestion={displayDistinctQuestion}>
      <Radio.Group
        values={values['compatibility']?.name}
        disabled={currentFocus == 'compatibility' ? false : true}>
        <Space direction="vertical">
          {compatibilityOptions?.map((item) => {
            return (
              <Radio
                onChange={() => handleSelectCompatibility(item)}
                value={item.name}
                key={`mult-opt-${item.id}`}>
                <Text>{item.name}</Text>
              </Radio>
            );
          })}
        </Space>
      </Radio.Group>
    </FormItemCard>
  );
};

export default Compatibility;
