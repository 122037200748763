import React from 'react';
import './Settings.scss';
import { PersonalData } from './PersonalData';
import { ChangePassword } from './ChangePassword';

const Settings = ({
  userData,
  editingPersonalData,
  setEditingPersonalData,
  handleInputChange,
  handleSelectChange,
  handleonChangeBirth,
  handleEditUser,
  handleChangePassword,
}) => {
  return (
    <div className="settings__container">
      <div className="settings_personal-data">
        {' '}
        <PersonalData
          userData={userData}
          editingPersonalData={editingPersonalData}
          setEditingPersonalData={setEditingPersonalData}
          handleInputChange={handleInputChange}
          handleSelectChange={handleSelectChange}
          handleonChangeBirth={handleonChangeBirth}
          handleEditUser={handleEditUser}
        />
        <ChangePassword handleChangePassword={handleChangePassword} />
      </div>
    </div>
  );
};

export default Settings;
