import axiosClient from '@config/axios';

export const createRating = (rating) => async () => {
  const result = await axiosClient
    .post(`/ratings`, rating)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err.response.data;
    });
  return result;
};

export const getRatings = (type, userId) =>
  axiosClient.get(`/ratings/?rating_type=${type}&user_id=${userId}`);
