import React from 'react';
import { Button } from '@components/common';
import TextArea from 'antd/lib/input/TextArea';
import css from './Comments.module.scss';
import SocialShare from 'components/common/SocialShare';

function Comments({ handleSendComment, commentsList, authUser }) {
  const [currentComment, setCurrentComment] = React.useState('');

  const onChange = (e) => {
    setCurrentComment(e.target.value);
  };

  const onSubmit = () => {
    handleSendComment(currentComment);
    setCurrentComment('');
  };
  return (
    <div className={css.container}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap-reverse',
          gap: '1em',
        }}>
        <h3 className="tn-title secondary">
          {authUser.isAuthenticated
            ? 'Deja tu comentario👇'
            : 'Inicia sesión para dejar un comentario👋'}
        </h3>
        <h3
          className="tn-title secondary"
          style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
          Compartir en
          <SocialShare quote="¡Te invito a leer este artículo!" hashtags={['tenecesito']} />
        </h3>
      </div>
      <hr />
      <div className={css.commentsContainer}>
        {commentsList.map((comment, index) => {
          return (
            <div className={css.commentContainer} key={`comment-${index}`}>
              <div className={css.image}>
                <img src={comment.user.profile_image} />
              </div>
              <div className={css.name}>
                <p className="tn-text tn-text-regular primaryColor">{comment.user.first_name} </p>
                <p className="tn-text tn-text--detail">{comment.comment_text} </p>
              </div>
            </div>
          );
        })}
        {authUser.isAuthenticated && (
          <div className={css.inputContainer}>
            <TextArea
              rows="3"
              maxLength={500}
              showCount
              placeholder="Escribe tu comentario"
              name="comment-create-input"
              id="comment-create-input"
              onChange={onChange}
              value={currentComment}
            />
            <Button text="Enviar" variant="secondary" borderColor="primary" onClick={onSubmit} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Comments;
