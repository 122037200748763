import React from 'react';
import './EmptyListCard.scss';
import {
  NO_HIRING_PROJECTS_ILUSTRATION,
  NO_PROJECT_PUBLICATION_ILUSTRATION,
  NO_REQUESTS_ILUSTRATION,
  NO_SERVICE_PUBLICATION_ILUSTRATION,
  NO_WORKING_PROJECTS_ILUSTRATION,
  NO_INFORMATION_ILUSTRATION,
} from '@common/constants/informativeIlustrations.constants';
import { Button, Text } from '@components/common';
import { useHistory } from 'react-router';
import { HOME_ROUTE, CREATE_PROJECT, CREATE_SERVICE } from '@common/constants/routes.constants';

export const EmptyListCard = ({ type }) => {
  const history = useHistory();
  const [currentIlustration, setCurrentIlustration] = React.useState();
  const [boldTextContent, setBoldTextContent] = React.useState();
  const [detailTextContent, setDetailTextContent] = React.useState();
  const [buttonText, setButtonText] = React.useState();

  React.useEffect(() => {
    if (type == 'no-hiring-projects') {
      setCurrentIlustration(NO_HIRING_PROJECTS_ILUSTRATION);
      setBoldTextContent('No tienes ningún proyecto con estas características.');
      setDetailTextContent(
        '¿Buscas a un experto en programación? ¡Publica tu proyecto y te ayudamos a encontrar a la persona perfecta!'
      );
      setButtonText('Publicar');
    } else if (type == 'no-project-publication') {
      setCurrentIlustration(NO_PROJECT_PUBLICATION_ILUSTRATION);
      setBoldTextContent('Todavía no tienes proyecto publicados con estas características.');
      setDetailTextContent('Puedes publicar un proyecto y recibir propuestas de los freelancers');
      setButtonText('Publicar proyecto');
    } else if (type == 'no-request') {
      setCurrentIlustration(NO_REQUESTS_ILUSTRATION);
      setBoldTextContent('Aún no has postulado a ningún proyecto con estas características.');
      setDetailTextContent(
        '¡Anímate y postula a uno de los cientos de proyectos que tenemos para ti!'
      );
      setButtonText('Ver proyectos');
    } else if (type == 'no-service-publication') {
      setCurrentIlustration(NO_SERVICE_PUBLICATION_ILUSTRATION);
      setBoldTextContent('Todavía no ofreces tus servicios');
      setDetailTextContent('Puedes publicar un servicio para que te inviten a proyectos nuevos.');
      setButtonText('Publicar servicio');
    } else if (type == 'no-working-projects') {
      setCurrentIlustration(NO_WORKING_PROJECTS_ILUSTRATION);
      setBoldTextContent('No estás trabajando en ningún proyecto con estas características aún.');
      setDetailTextContent('¿Necesitas trabajar? ¡Mira todos los proyectos que tenemos para ti!');
      setButtonText('Ver proyectos');
    } else if (type == 'welcome-no-projects') {
      setCurrentIlustration(NO_INFORMATION_ILUSTRATION);
      setBoldTextContent('No hay proyectos que cumplan con los parámetros de tu búsqueda.');
      setDetailTextContent('Intenta buscando otra selección.');
    } else if (type == 'welcome-no-services') {
      setCurrentIlustration(NO_INFORMATION_ILUSTRATION);
      setBoldTextContent('No hay servicios que cumplan con los parámetros de tu búsqueda.');
      setDetailTextContent(
        'Puedes publicar tu proyecto de todas formas e te ayudaremos a encontrar a tu freelancer ideal.'
      );
    }
  }, []);

  const handleRedirection = () => {
    if (type == 'no-working-projects' || type == 'no-request') {
      history.push(HOME_ROUTE);
    } else if (type == 'no-hiring-projects' || type == 'no-project-publication') {
      history.push(CREATE_PROJECT);
    } else if (type == 'no-service-publication') {
      history.push(CREATE_SERVICE);
    }
  };

  return (
    <div className="empty-list-card__container">
      <div className="empty-list-card__ilustration">
        {' '}
        <img src={currentIlustration} />
      </div>
      <div className="empty-list-card__content">
        <Text type="strong">{boldTextContent}</Text>
        <Text type="detail">{detailTextContent}</Text>
        {buttonText ? (
          <Button
            text={buttonText}
            variant="secondary"
            color="primary"
            onClick={handleRedirection}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
