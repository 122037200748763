import React, { useEffect, useState } from 'react';
import css from '../Forms.module.scss';
import { Input } from '@material-ui/core';
import other from '../assets/other.svg';
import { Text } from 'components/common';

const OtherField = ({ handleSetAnswer, isOptionSelected, type, answer, isVertical }) => {
  const [isWriting, setIsWriting] = useState(false);

  const [option, setOption] = useState({
    label: 'Otro',
    value: '',
    img: 'https://www.flaticon.com/svg/static/icons/svg/149/149071.svg',
  });

  const handleChange = (e) => {
    if (e?.target?.value?.length > 50) return;
    setIsWriting(true);
    const val = e?.target?.value;
    const newOpt = { ...option, value: val };
    handleSetAnswer(newOpt, type, 'replace');
    setOption(newOpt);
  };

  useEffect(() => {
    if (!isWriting) {
      if (answer?.answers?.[0]?.label === 'Otro') {
        setOption(answer?.answers[0]);
      }
      if (answer?.answers?.find((a) => a.label === 'Otro')) {
        setOption(answer.answers.find((a) => a.label === 'Otro'));
      }
    }
  }, [answer]);

  return (
    <>
      {isVertical ? (
        <div style={{ maxWidth: 480, width: '100%', margin: '0 auto' }}>
          <div
            className={css.optionVertical}
            style={isOptionSelected(option) ? { border: '2px solid #3383ff' } : {}}
            onClick={() => handleSetAnswer(option, type)}>
            {isOptionSelected(option, 'otro') ? (
              <div style={{ display: 'flex', gap: '1em', alignItems: 'center' }}>
                <Input
                  placeholder="Escribe tu respuesta aquí..."
                  variant="standard"
                  autoFocus
                  value={option?.value}
                  onChange={handleChange}
                  style={{ width: '100%', border: 0 }}
                />
                <div style={{ textAlign: 'right', color: 'gray' }}>
                  <span>{option.value?.length}/50</span>
                </div>
              </div>
            ) : (
              <Text>Otro</Text>
            )}
          </div>
        </div>
      ) : (
        <div className={css.option}>
          <img
            src={other}
            alt="option"
            style={isOptionSelected(option) ? { border: '2px solid #3383ff', opacity: 1 } : {}}
            onClick={() => handleSetAnswer(option, type)}
          />
          <Text>{option.label}</Text>
          {isOptionSelected(option) && (
            <>
              <Input
                placeholder="Escribe tu respuesta aquí..."
                variant="standard"
                autoFocus
                value={option.value}
                onChange={handleChange}
              />
              <div style={{ textAlign: 'center', color: 'gray' }}>
                <span>{option.value?.length}/50</span>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default OtherField;
