/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import './ClientProjectManagment.scss';
import { BaseLayout, CustomBreadcrumb, Text } from '@components/common';
import { ClientProjectManagmentViewModel } from './ClientProjectManagmentViewModel';
import RatingWindow from '@components/viewProject/RatingWindow';
import CalendarStagesContainer from '../../../ProjectsCommon/CalendarStagesContainer';
import ClientProjectTabs from './ClientProjectTabs';
import {
  COMPLETED_PROJECTS_LABEL,
  FEEDBACK_PROJECT_LABEL,
} from 'common/constants/project.constants';
import { Redirect } from 'react-router-dom';

const ClientProjectManagment = () => {
  const {
    authUser,
    files,
    isLoadingFiles,
    isLoadingProject,
    isLoadingRequest,
    currentProject,
    currentRequest,
    currentProposal,
    ratingModal,
    setRatingModal,
    useImageUploadHandler,
    questionsList,
    handleReplyQuestion,
    isLoadingQuestions,
    handleGetFiles,
    setRefetchProject,
  } = ClientProjectManagmentViewModel();
  const isFinished =
    currentProject?.status === FEEDBACK_PROJECT_LABEL ||
    currentProject?.status === COMPLETED_PROJECTS_LABEL;

  if (authUser?.user?.id === currentProject?.freellance_data?.id) {
    return <Redirect to={`/management/freelancer/projects/${currentProject?.id}`} />;
  }

  return (
    <div>
      <CustomBreadcrumb type="client-project" title={currentProject?.title} />
      <BaseLayout>
        <BaseLayout.LeftSide>
          {!isLoadingProject && (
            <CalendarStagesContainer
              currentProject={currentProject}
              setRefetchProject={setRefetchProject}
              type="client"
            />
          )}
        </BaseLayout.LeftSide>
        <BaseLayout.Content>
          <Fragment>
            {!isLoadingProject && (
              <RatingWindow
                project={currentProject}
                userRole={'owner'}
                onClose={() => setRatingModal(false)}
                show={ratingModal}
                isLoading={isLoadingProject}
              />
            )}

            <ClientProjectTabs
              currentProject={currentProject}
              handleGetFiles={handleGetFiles}
              authUser={authUser}
              isLoadingFiles={isLoadingFiles}
              files={files}
              questionsList={questionsList}
              handleReplyQuestion={handleReplyQuestion}
              isLoadingQuestions={isLoadingQuestions}
              currentProposal={currentProposal}
              currentRequest={currentRequest}
              isLoadingProject={isLoadingProject}
              isLoadingRequest={isLoadingRequest}
              setRatingModal={setRatingModal}
              isFinished={isFinished}
              setRefetchProject={setRefetchProject}
            />
          </Fragment>
        </BaseLayout.Content>
      </BaseLayout>
    </div>
  );
};

export default ClientProjectManagment;
