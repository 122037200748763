/* eslint-disable no-unused-vars */
import React, { createContext, useEffect, useState } from 'react';
import { useMediaQuery } from '@material-ui/core';
import { getUserChats } from 'actions/chat';
import { useSelector } from 'react-redux';
import { selectAuthUserInfo } from 'reducers/auth';
import { useHistory } from 'react-router-dom';

function sortChatsByLastMessageDate(array) {
  array.sort(function (a, b) {
    var dateA = new Date(a.last_message.created_at);
    var dateB = new Date(b.last_message.created_at);
    return dateB - dateA;
  });
  return array;
}

export const ChatWidgetContext = createContext({});

export const ChatWidgetProvider = ({ children }) => {
  const history = useHistory();

  const user = useSelector(selectAuthUserInfo);

  const [openedChats, setOpenedChats] = useState([]);

  const [openedChatsHistory, setOpenedChatsHistory] = useState([]);

  const [searchValue, setSearchValue] = useState('');

  const [openChat, setOpenChat] = useState(false);

  const [chats, setChats] = useState([]);

  const isLargeDesk = useMediaQuery('(min-width: 1480px)');

  const isSmallDesk = useMediaQuery('(min-width: 1080px)');

  const isTablet = useMediaQuery('(min-width: 720px)');

  const isSmall = useMediaQuery('(max-width: 700px)');

  const [unreadedMessages, setUnreadedMessages] = useState(0);

  useEffect(() => {
    handleGetUserChats();

    const intervalId = setInterval(() => {
      handleGetUserChats();
    }, 30000);

    return () => clearInterval(intervalId);
  }, [user]);

  const handleGetUserChats = () => {
    if (user?.id) {
      getUserChats(user?.id).then((res) => {
        setChats(sortChatsByLastMessageDate(res?.data));
        const unreadeds = res?.data?.reduce((acc, chat) => chat?.not_readed_messages + acc, 0);
        setUnreadedMessages(unreadeds);
      });
    }
  };

  const handleOpenChat = (id) => {
    const findChat = chats.find((chat) => chat?.id === id);
    setUnreadedMessages((prev) => prev - findChat?.not_readed_messages);
    setOpenedChatsHistory((prev) => [...prev, id]);

    const MAX_CHATS = isLargeDesk ? 3 : isSmallDesk ? 2 : isTablet ? 1 : 1;

    if (openedChats.includes(id)) {
      return;
    } else {
      if (openedChats.length >= MAX_CHATS) {
        setOpenedChats([...openedChats.slice(1), id]);
      } else {
        setOpenedChats([...openedChats, id]);
      }
    }
  };

  const handleRemoveChat = (id) => {
    setOpenedChats((prev) => prev.filter((p) => p !== id));
  };

  const filteredChats = chats?.filter((chat) => {
    return (
      chat?.title?.toLowerCase().includes(searchValue?.toLowerCase()) ||
      chat?.user_name?.toLowerCase().includes(searchValue?.toLowerCase())
    );
  });

  const handleChat = () => {
    if (isSmall) {
      setOpenedChats([chats?.[0]?.id]);
      history.push('/my-chats');

      setOpenChat(true);
    } else {
      setOpenChat(true);
    }
  };

  return (
    <ChatWidgetContext.Provider
      value={{
        openChat,
        openedChats,
        chats,
        setOpenChat,
        handleOpenChat,
        handleRemoveChat,
        filteredChats,
        searchValue,
        setSearchValue,
        openedChatsHistory,
        isSmall,
        handleChat,
        unreadedMessages,
      }}>
      {children}
    </ChatWidgetContext.Provider>
  );
};
