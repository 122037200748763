import { BaseLayout } from 'components/common';
import React, { Fragment } from 'react';
import { PostsList } from './components/PostsList';
//import { PostDetail } from './components/PostDetail';
import { BlogViewModel } from './BlogViewModel';
//import { Categories } from './components/Categories';
//import { RecentPosts } from './components/RecentPosts';
import { LANDING_BOTTOM_BACKGROUND } from 'pages/Landing/landing.constants';
import { BlogHeader } from './components/BlogHeader';
import css from './Blog.module.scss';
import { PostDetail } from './components/PostDetail';

function Blog() {
  const {
    postsList,
    primaryBlog,
    isLoadingPosts,
    secondaryBlog,
    tertiaryBlog,
    handlePagination,
    totalObjects,
    blogCategories,
    categoryFilter,
    onChangeCategoryFilter,
    handleClickBlog,
    id,
    currentPost,
    handleSendComment,
    commentsList,
    authUser,
    handleBackToBlog,
    recentBlogs,
  } = BlogViewModel();

  return (
    <BaseLayout.Content>
      {id ? (
        <PostDetail
          handleClickBlog={handleClickBlog}
          recentBlogs={recentBlogs}
          currentPost={currentPost}
          handleSendComment={handleSendComment}
          commentsList={commentsList}
          authUser={authUser}
          handleBackToBlog={handleBackToBlog}
        />
      ) : (
        <Fragment>
          <div
            className={css.container}
            style={{
              backgroundImage: `url(${LANDING_BOTTOM_BACKGROUND})`,
            }}>
            <BlogHeader
              primaryBlog={primaryBlog}
              secondaryBlog={secondaryBlog}
              tertiaryBlog={tertiaryBlog}
              blogCategories={blogCategories}
              categoryFilter={categoryFilter}
              onChangeCategoryFilter={onChangeCategoryFilter}
              handleClickBlog={handleClickBlog}
            />
          </div>
          <div className={css.postsList}>
            <PostsList
              recentBlogs={recentBlogs}
              postsList={postsList}
              isLoadingPosts={isLoadingPosts}
              handleClickBlog={handleClickBlog}
              handlePagination={handlePagination}
              totalObjects={totalObjects}
            />
          </div>
        </Fragment>
      )}
    </BaseLayout.Content>
  );
}

export default Blog;
