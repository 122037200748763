import React, { Fragment } from 'react';
import GeneralInformation from './GeneralInformation/GeneralInformation';
import AboutMe from './AboutMe/AboutMe';
import MyLinks from './MyLinks/MyLinks';
import './MyProfile.scss';
import UploadProfileImageModal from './UploadImage/UploadProfileImageModal';
import { Modal } from 'antd';
import { MyProfileViewModel } from './MyProfileViewModel';
import CompletedProjectsCard from './CompletedProjectsCard/CompletedProjectsCard';
import { SocialMediaInput } from '@components/profile/SocialMediaInput/SocialMediaInput';
import { PopUpWindow, FormVerifyIdentity, PublicationQuestions } from '@components/common';
import { ViewProfileDrawer } from '@components/profile/ViewProfileDrawer/ViewProfileDrawer';
import PreviousProjects from './PreviousProjects';
import MyServices from './MyServices';

const MyProfile = () => {
  const {
    authUser,
    profileImageModal,
    setProfileImageModal,
    editGenaralInformation,
    setEditGenaralInformation,
    onSubmitGeneralInformation,
    onSubmitSocialMedia,
    socialMediaModal,
    setSocialMediaModal,
    socialMediaType,
    //setSocialMediaType,
    handleEditSocialMedia,
    aboutMeEditing,
    experienceEditing,
    skillsEditing,
    setAboutMeEditing,
    setExperienceEditing,
    setSkillsEditing,
    handleSave,
    skillsSelected,
    skillsData,
    fetching,
    fetchSkills,
    handleChangeSkills,
    verifyIdentityModal,
    setVerifyIdentityModal,
    userDrawer,
    setUserDrawer,
    handleReplyQuestion,
    questionsList,
  } = MyProfileViewModel();

  const doneVerification = () => {
    setVerifyIdentityModal(false);
  };

  return (
    <Fragment>
      {' '}
      <ViewProfileDrawer
        currentUser={authUser.user}
        type="project"
        userDrawer={userDrawer}
        setUserDrawer={setUserDrawer}
      />
      <PopUpWindow open={socialMediaModal} onClose={() => setSocialMediaModal(false)}>
        <SocialMediaInput
          type={socialMediaType}
          user={authUser.user}
          onSubmitSocialMedia={onSubmitSocialMedia}
        />
      </PopUpWindow>
      <PopUpWindow open={verifyIdentityModal} onClose={() => setVerifyIdentityModal(false)}>
        <FormVerifyIdentity doneVerification={doneVerification} userData={authUser.user} />
      </PopUpWindow>
      <div className="my-profile__container">
        <Modal
          centered
          footer={null}
          visible={profileImageModal}
          onOk={() => {}}
          onCancel={() => setProfileImageModal(false)}>
          <UploadProfileImageModal
            user={authUser.user}
            auth={authUser}
            setProfileImageModal={setProfileImageModal}
          />
        </Modal>

        <div className="my-profile__column">
          <div className="my-profile__row">
            <GeneralInformation
              user={authUser.user}
              setProfileImageModal={setProfileImageModal}
              editGenaralInformation={editGenaralInformation}
              setEditGenaralInformation={setEditGenaralInformation}
              onSubmitGeneralInformation={onSubmitGeneralInformation}
              setVerifyIdentityModal={setVerifyIdentityModal}
              setUserDrawer={setUserDrawer}
            />
          </div>
          <div className="my-profile__row">
            <MyServices user={authUser?.user} isPopup />
          </div>
          <div className="my-profile__row">
            <PreviousProjects user={authUser?.user} fromProfile />
          </div>
        </div>
        <div className="my-profile__column">
          <MyLinks
            user={authUser.user}
            socialMediaModal={socialMediaModal}
            handleEditSocialMedia={handleEditSocialMedia}
          />
          <AboutMe
            user={authUser.user}
            aboutMeEditing={aboutMeEditing}
            experienceEditing={experienceEditing}
            skillsEditing={skillsEditing}
            handleSave={handleSave}
            skillsSelected={skillsSelected}
            skillsData={skillsData}
            fetching={fetching}
            fetchSkills={fetchSkills}
            handleChangeSkills={handleChangeSkills}
            setAboutMeEditing={setAboutMeEditing}
            setExperienceEditing={setExperienceEditing}
            setSkillsEditing={setSkillsEditing}
          />
          <CompletedProjectsCard userData={authUser.user} />
          <PublicationQuestions
            type="service"
            questionsList={questionsList}
            handleReplyQuestion={handleReplyQuestion}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default MyProfile;
