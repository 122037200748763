import React from 'react';
import './BankAccountDrawer.scss';
import { FormAccount } from '../FormAccount';
import { Text, CustomDrawer } from '@components/common';

const BankAccountDrawer = ({
  account,
  handleSubmitAccount,
  bankAccountDrawer,
  setBankAccountDrawer,
}) => {
  return (
    <CustomDrawer
      type="project"
      open={bankAccountDrawer}
      onClose={() => setBankAccountDrawer(false)}
      visible={bankAccountDrawer}>
      <div className="bank-account-drawer__container">
        <div className="bank-account-drawer__header">
          <Text type="boldDetail">
            {account ? 'Editar cuenta bancaria' : 'Añadir cuenta bancaria'}
          </Text>
          <hr />
        </div>
        <div className="bank-account-drawer__content">
          <FormAccount
            window={account ? 'edit' : 'create'}
            handleSubmitAccount={handleSubmitAccount}
            account={account ? account : null}
          />
        </div>
      </div>
    </CustomDrawer>
  );
};

export default BankAccountDrawer;
