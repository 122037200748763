import React from 'react';
import css from './ServiceSuggestionsCard.module.scss';
import { Text, Button } from '@components/common';
import useSuggestions from './useSuggestions';
import { Rate } from 'antd';

const ServiceSuggestionCard = ({
  handleRedirectSuggestion = () => null,
  item,
  type,
  isInvite,
  currentProject,
}) => {
  const { isInviting, handleInvite, isAlreadyInvited } = useSuggestions({
    currentProject,
  });

  return (
    <div
      className={
        isInvite ? css.detailInvite : type === 'project' ? css.detailProject : css.detailFreelancer
      }
      onClick={() => handleRedirectSuggestion(item.id)}>
      <div className={css.header}>
        <div className={css.top}>
          <img className={css.image} src={item.profile_image} />
          <Rate allowHalf disabled defaultValue={item?.owner_rating} />
        </div>
        <div className={css.bottom}>
          <Text type="normalBoldDetail">
            {item.owner_name} {type == 'project' ? 'necesita' : 'ofrece'}
          </Text>
        </div>
      </div>
      <div className={css.body}>
        <Text>{item.service_title}</Text>

        <hr style={{ marginBottom: 0 }} />
      </div>
      {isInvite && (
        <div className={css.inviteBtn}>
          <Button
            text={isInviting ? 'Invitando...' : isAlreadyInvited(item) ? 'Invitado' : 'Invitar'}
            disabled={isInviting || isAlreadyInvited(item)}
            variant="secondary"
            borderColor="secondary"
            size="sm"
            onClick={() => handleInvite(item)}
          />
        </div>
      )}
    </div>
  );
};

export default ServiceSuggestionCard;
