import React from 'react';
import { LoadingDots, ProjectDetails, PublicationQuestions } from 'components/common';

const ProjectDetailsAndQuestions = ({
  currentProject,
  questionsList,
  handleReplyQuestion,
  isLoadingQuestions,
  type,
}) => {
  return (
    <>
      {currentProject ? (
        <div>
          <div style={{ marginBottom: '2em' }}>
            <ProjectDetails currentProject={currentProject} />
          </div>

          <div>
            {isLoadingQuestions ? (
              <LoadingDots />
            ) : (
              <PublicationQuestions
                type={type}
                answerable={false}
                questionsList={questionsList}
                handleReplyQuestion={handleReplyQuestion}
              />
            )}
          </div>
        </div>
      ) : (
        <LoadingDots />
      )}
    </>
  );
};
export default ProjectDetailsAndQuestions;
