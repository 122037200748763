import React from 'react';
import { Redirect } from 'react-router-dom';
import { Button } from '@components/common/Button';
import { Form, Input } from 'antd';
import { PasswordInput } from 'antd-password-input-strength';
import GoogleOAuthBtn from '../components/GoogleOAuthBtn';
import { Title, PhoneNumberInput, Text } from '@components/common';
import './Register.scss';
import '../Login/Login.scss';
import useAuthButtons from 'hooks/useAuthButtons';

export const Register = (props) => {
  const { currentAuthUser, prefix, setPrefix, useRegister, registerBlocked } = props;

  const { setShowRegisterWindow, setShowLoginWindow } = useAuthButtons();

  const [currentPhoneNumber, setCurrentPhoneNumber] = React.useState();

  React.useEffect(() => {
    if (currentAuthUser?.isAuthenticated) {
      return <Redirect to="/home" />;
    }
  }, []);

  const onSubmit = (e) => {
    useRegister(e.first_name, e.last_name, e.email, e.password, prefix, currentPhoneNumber);
  };

  return (
    <div className="login-container">
      <div className="card-login-container">
        <div className="card-login__header">
          <Title color="main">¡Unete a nuestra comunidad!</Title>
        </div>
        <GoogleOAuthBtn
          setShowRegisterWindow={setShowRegisterWindow}
          setShowLoginWindow={setShowLoginWindow}
        />
        <div className="register-login-option-container">
          <hr className="register-login-option-border-left" />
          <div className="option-text">O regístrate aquí</div>
          <hr className="register-login-option-border-right" />
        </div>
        <Form onFinish={onSubmit}>
          <div className="form-group-login">
            <label className="label-form-text">Nombre</label>
            <Form.Item
              name="first_name"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa tu nombre.',
                },
              ]}>
              <Input maxLength={30} placeholder="Nombre" />
            </Form.Item>
          </div>
          <div className="form-group-login">
            <label className="label-form-text">Apellido</label>
            <Form.Item
              name="last_name"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa tu apellido.',
                },
              ]}>
              <Input maxLength={30} placeholder="Apellido" />
            </Form.Item>
          </div>
          <div className="form-group-login">
            <label className="label-form-text">Email</label>
            <Form.Item
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'El texto ingresado no es un email válido.',
                },
                {
                  required: true,
                  message: 'Por favor ingresa tu email.',
                },
              ]}>
              <Input placeholder="Email" />
            </Form.Item>
          </div>
          <div className="form-group-login">
            <label className="label-form-text">Contraseña</label>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa tu contraseña.',
                },
              ]}
              hasFeedback>
              <PasswordInput placeholder="Contraseña" />
            </Form.Item>
          </div>
          <div className="form-group-login">
            <label className="label-form-text">Confirmar contraseña</label>
            <Form.Item
              name="confirm"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Por favor confirma tu contraseña.',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject('Las dos contraseñas que ingresaste no coinciden.');
                  },
                }),
              ]}>
              <PasswordInput placeholder="Confirmar contraseña" />
            </Form.Item>
          </div>
          <div className="form-group-login">
            <label className="label-form-text">Número de Teléfono</label>
            <PhoneNumberInput
              prefix={prefix}
              setPrefix={setPrefix}
              setCurrentPhoneNumber={setCurrentPhoneNumber}
              currentPhoneNumber={currentPhoneNumber}
            />
            <Text type="smallBlue" justify>
              *Recordamos que el número de teléfono se usará únicamente para la comunicación directa
              con TeNecesito y no se entregará a otros terceros.
            </Text>
          </div>
          <br />
          <div className="warning-container">
            Al hacer click en Registrarse aceptas los{' '}
            <a href="#/Terms&Conditions"> Términos y Condiciones </a>y además declaras estar al
            tanto de las <a href="#/PrivacyPolicy">Políticas de Privacidad</a> y de
            <a href="#/DataManagement"> Manejo de Datos</a>.
          </div>
          <div className="form-group">
            <Form.Item>
              <div className="button-login-container">
                <Button disabled={registerBlocked} text="Registrarse" variant="primary" />
              </div>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};
