import React from 'react';
import './AnimatedInput.scss';

export function AnimatedInput({
  name,
  type,
  onChange,
  defaultValue,
  animation,
  label,
  value,
  onBlur,
}) {
  return (
    <div className="animated-input__container">
      {defaultValue ? (
        <input
          name={name}
          type={type}
          autoComplete="off"
          onChange={onChange}
          defaultValue={defaultValue}
          value={value}
          onBlur={onBlur}
          maxLength={50}
          required></input>
      ) : (
        <input
          name={name}
          type={type}
          autoComplete="off"
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          maxLength={50}
          required></input>
      )}

      <label className={`animated-input__${animation}`}>
        <span>{label}</span>
      </label>
    </div>
  );
}
