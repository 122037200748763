import React from 'react';
import css from './Suggestions.module.scss';
import { Text } from '@components/common';

const SuggestionCard = ({ handleRedirectSuggestion = () => null, item, type, isInvite }) => {
  return (
    <div
      className={
        isInvite ? css.detailInvite : type === 'project' ? css.detailProject : css.detailFreelancer
      }
      onClick={() => handleRedirectSuggestion(item.id)}>
      <div className={css.header}>
        <div className={css.left}>
          <img className={css.image} src={item.profile_image} />
        </div>
        <div className={css.right}>
          <Text type="normalBoldDetail">
            {item.owner_name} {type == 'project' ? 'necesita' : 'ofrece'}
          </Text>
        </div>
      </div>
      <div className={css.body}>
        <Text>{item.project_title}</Text>

        <hr style={{ marginBottom: 0 }} />
      </div>
    </div>
  );
};

export default SuggestionCard;
