import config from '@config';

const BUCKETASSETS = config.assetsBucket;

export const ABOUT_US_HEADER_BACKGROUND = BUCKETASSETS + 'landing/porque+contratar+en+tn.png';

export const ABOUTUS_CIRCLE_HANDS = BUCKETASSETS + 'HowItWorks/freelancer_nosotros.png';

export const SOCIAL_ICON =
  BUCKETASSETS + 'HowItWorks/tenecesito_programacion_tecnologia_freelancer.png';
export const SOCIAL_ICON1 =
  BUCKETASSETS + 'HowItWorks/instagram_programacion_tecnologia_freelancer.png';
export const SOCIAL_ICON2 =
  BUCKETASSETS + 'HowItWorks/whatsapp_programacion_tecnologia_freelancer.png';
export const SOCIAL_ICON3 =
  BUCKETASSETS + 'HowItWorks/linkedin_programacion_tecnologia_freelancer.png';
export const SOCIAL_ICON4 =
  BUCKETASSETS + 'HowItWorks/youtube_programacion_tecnologia_freelancer.png';
export const SOCIAL_ICON5 =
  BUCKETASSETS + 'HowItWorks/facebook_programacion_tecnologia_freelancer.png';

export const ABOUTUS_TEXT = [
  {
    text: 'El desarrollo y la tecnología bien aplicada nos ayuda a los negocios, a aprender cosas nuevas, a acortar la distancia con nuestras amistades o hasta a la forma de tomarnos un café en la mañana ☕​.',
  },
  {
    text: 'Todo esto se traduce en que existe una alta demanda de profesionales de programación y tecnología, con una escasez de especialistas y altos costos de contratación.',
  },
  {
    text: 'Lo bueno 🙂, es que la cantidad de personas que eligen ser freelancers va en alza, y el área de la tecnología lleva la delantera a nivel mundial.',
  },
  {
    text: 'TeNecesito nace para hacerse cargo de esta nueva realidad, siendo un espacio profesional, confiable y cercano donde se les puede contratar.',
  },
];

export const ABOUTUS_TITLE = [
  {
    title1: 'El mundo',
    title2: 'necesita gente',
    title3: 'que ame lo que hace',
    subtitle1:
      'Tu trabajo ocupará gran parte de tu vida, y la única forma de estar bien contigo mismo es hacer lo que tú consideres un excelente trabajo.',
    subtitle2: 'Y la única forma de hacer un excelente trabajo es amar lo que haces.',
  },
];
