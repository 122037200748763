import React from 'react';
import { Steps } from 'antd';
import { Text } from 'components/common';
import VerifyIdentity from './VerifyIdentity';
import YourProfile from './YourProfile/YourProfile';
import { useMediaQuery } from '@material-ui/core';
import YourWorks from './YourWorks';

const RequestStepper = ({ current, authUser, lastStepTitle }) => {
  const isMobile = useMediaQuery('(max-width: 600px)');

  const StepsItem = [
    { title: 'Verifica tu identidad', component: <VerifyIdentity /> },
    { title: '¡Completa tu perfil!', component: <YourProfile /> },
    { title: 'Proyectos previos', component: <YourWorks authUser={authUser} /> },
    { title: lastStepTitle, component: <></> },
  ];
  return (
    <>
      <div style={{ padding: '1em 2em', marginBottom: '2em' }}>
        <Steps current={current} size="small">
          {StepsItem.map((item, index) => (
            <Steps.Step key={index} title={isMobile ? '' : item.title} />
          ))}
        </Steps>
      </div>
      <div>
        <Text size={24} centered style={{ marginBottom: '0.5em', color: '#3383ff' }}>
          {StepsItem[current].title}
        </Text>
        {StepsItem[current].component}
      </div>
    </>
  );
};

export default RequestStepper;
