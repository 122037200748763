import React from 'react';
import './StagesDefinition.scss';
import { RequestBreakdown } from '../RequestBreakdown/RequestBreakdown';
import { Text, Title } from '@components/common';

export function StagesDefinition({
  nStages,
  setNStages,
  stagesValues,
  handleStageValueChange,
  totalPrice,
  currentProject,
  showDiscountWindow,
  setShowDiscountWindow,
  totalDays,
  currentProposal,
  commission,
  setCommission,
  discountCode,
}) {
  return (
    <div className="stages-definition__container">
      <Title type="tertiary" color="primary">
        Desglosa tu presupuesto
      </Title>
      <Text type="detail">
        En esta sección debes ingresar las etapas que definiste en la descripción de la propuesta,
        además debes definir el tiempo y precio de cada una de estas. Cuando el cliente apruebe cada
        etapa se te pagará el precio correspondiente.
      </Text>

      <div className="stages-definition__select-nstages">
        {['1', '2', '3', '4', '5'].map((id) => (
          <div className="stages-definition-item" key={id}>
            <label>
              <input
                type="radio"
                value={id}
                checked={nStages == id ? true : false}
                onChange={(e) => setNStages(e.target.value)}
                name={`stage-${id}`}
              />
              <span id="stages-definition-label">{id}</span>
            </label>
          </div>
        ))}
      </div>
      <div className="stages-definition__request-breakdown">
        <RequestBreakdown
          nStages={nStages}
          stagesValues={stagesValues}
          handleStageValueChange={handleStageValueChange}
          totalPrice={totalPrice}
          currentProject={currentProject}
          showDiscountWindow={showDiscountWindow}
          setShowDiscountWindow={setShowDiscountWindow}
          totalDays={totalDays}
          currentProposal={currentProposal}
          commission={commission}
          setCommission={setCommission}
          discountCode={discountCode}
        />
      </div>
    </div>
  );
}
