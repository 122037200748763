import React from 'react';
import { getUserNotifications } from '@actions/notifications';
import { useDispatch } from 'react-redux';
import { ManagmentViewModel } from '../ManagmentViewModel';
import { useHistory } from 'react-router';
import {
  FREELANCER_REQUESTS_ROUTE,
  CLIENT_PUBLICATIONS_ROUTE,
} from '@common/constants/routes.constants';

export const NotificationsViewModel = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { authUser } = ManagmentViewModel();

  const currentNotifications = authUser.notifications;

  const [isLoadingNotifications, setIsLoadingNotifications] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      setIsLoadingNotifications(true);
      await dispatch(getUserNotifications(authUser.user.id));
      setIsLoadingNotifications(false);
    }
    fetchData();
  }, []);

  const handleNotificationRedirection = (notification) => {
    if (notification.redirection_class_type == 'publication') {
      history.push(CLIENT_PUBLICATIONS_ROUTE + `/${notification.redirection_class_id}`);
    } else if (notification.redirection_class_type == 'public-project') {
      history.push(`/project/${notification.redirection_class_id}/view`);
    } else if (notification.redirection_class_type == 'request') {
      history.push(FREELANCER_REQUESTS_ROUTE + `/${notification.redirection_class_id}`);
    }
  };

  const handlePagination = (page) => {
    setIsLoadingNotifications(true);
    dispatch(getUserNotifications(authUser.user.id, page));
    setIsLoadingNotifications(false);
  };

  return {
    authUser,
    currentNotifications,
    isLoadingNotifications,
    handleNotificationRedirection,
    handlePagination,
  };
};
