/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import PrevProjectCard from './PrevProjectCard';
import css from './PreviousProjects.module.scss';
import ReactSimplyCarousel from 'react-simply-carousel';
import { ArrowLeft, ArrowRight, SkipBack } from 'react-feather';
import { SkipNext } from '@material-ui/icons';
import { useState } from 'react';
import { useMediaQuery } from '@material-ui/core';

const PreviousProjectsCarousel = ({ projects, isOwner, showModal }) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);
  const disableArrows = useMediaQuery('(max-width: 1170px)');
  const nextSlide = () => {
    if (activeSlideIndex < projects.length - 1) {
      setActiveSlideIndex(activeSlideIndex + 1);
    } else {
      setActiveSlideIndex(0);
    }
  };
  const prevSlide = () => {
    if (activeSlideIndex > 0) {
      setActiveSlideIndex(activeSlideIndex - 1);
    } else {
      setActiveSlideIndex(projects.length - 1);
    }
  };
  return (
    <div className={css.container}>
      {projects.length > 0 ? (
        <Fragment>
          {projects?.map((project, index) => (
            <PrevProjectCard
              isOwner={isOwner}
              project={project}
              showCreateEditModal={() => showModal(project, 'edit')}
              showDeleteModal={() => showModal(project, 'delete')}
              showInfoModal={() => showModal(project, 'info')}
              key={index}
            />
          ))}
        </Fragment>
      ) : null}
    </div>
  );
};
export default PreviousProjectsCarousel;
