import React, { Fragment } from 'react';
import './OrdersTable.scss';
import { MoreHorizontal } from 'react-feather';
import { MoneyFormatter } from '@common/utils/Formaters';
import { Text, Title } from '@components/common';

export const PaymentOrdersTable = ({ paymentOrdersList, handleMore }) => {
  return (
    <div className="orders-table__container">
      <div className="orders-table__header">
        <Title className="tn-title tertiary">Fecha</Title>
        <Title className="tn-title tertiary" id="proyect">
          Proyecto
        </Title>
        <Title className="tn-title tertiary" id="stage">
          Etapa
        </Title>
        <Title className="tn-title tertiary">Monto</Title>
        <Title className="tn-title tertiary">Estado</Title>
        <Title className="tn-title tertiary">Más</Title>
      </div>
      <hr />
      <div className="orders-table__content">
        {paymentOrdersList.map((order, index) => {
          return (
            <Fragment key={index}>
              <div className="orders-table__row">
                <Text type="detail" className="tn-text tn-text--detail tn-text--small">
                  {order.created_at}
                </Text>
                <Text type="detail" className="tn-text tn-text--detail tn-text--small" id="proyect">
                  {order.project_name}
                </Text>
                <Text type="detail" className="tn-text tn-text--detail tn-text--small" id="stage">
                  {order.stage_name}
                </Text>
                <Text type="detail" className="tn-text tn-text--detail tn-text--small">
                  {MoneyFormatter(order.total)}
                </Text>
                <Text
                  className={`tn-text tn-text--detail tn-text--small status--${order.status}`}
                  id="status">
                  {order.status == 'created' ? 'creada' : 'pagada'}
                </Text>
                <Text type="detail" className="tn-text tn-text--detail tn-text--small">
                  <MoreHorizontal
                    onClick={() => {
                      handleMore(order);
                    }}
                  />
                </Text>
              </div>
              <hr />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
