/* eslint-disable no-unused-vars */
import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { Input, Select } from 'antd';
import { Text } from '../Text';
import * as Yup from 'yup';
import { CircularProgress } from '@material-ui/core';

const VideoModalContent = ({ handleSubmit }) => {
  return (
    <>
      <Formik
        initialValues={{ topic: '', start_time: '', duration: '' }}
        validationSchema={Yup.object({
          topic: Yup.string().required('El campo es obligatorio'),
          // description: Yup.string().required('El campo es obligatorio'),
          start_time: Yup.date().required('El campo es obligatorio'),
          duration: Yup.number().required('El campo es obligatorio'),
        })}
        onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm)}>
        {({ isSubmitting, values, setValues }) => (
          <Form style={{ display: 'flex', flexDirection: 'column', gap: '1em' }}>
            <div>
              <Text>Título de la reunión</Text>
              <Field name="topic" as={Input} />
              <span style={{ color: 'red' }}>
                <ErrorMessage name="topic" />
              </span>
            </div>
            {/* <div>
              <Text>Descripción</Text>
              <Field name="description" as={Input.TextArea} rows={4} />
              <span style={{ color: 'red' }}>
                <ErrorMessage name="description" />
              </span>
            </div> */}
            <div>
              <Text>Fecha de inicio</Text>
              <Field name="start_time" as={Input} type="datetime-local" />
              <span style={{ color: 'red' }}>
                <ErrorMessage name="start_time" />
              </span>
            </div>
            <div>
              <Text>Duración</Text>
              <Field
                name="duration"
                as={Select}
                style={{ width: '100%' }}
                onChange={(e) => setValues({ ...values, duration: e })}
                options={[
                  { value: 15, label: '15 minutos' },
                  { value: 30, label: '30 minutos' },
                  { value: 45, label: '45 minutos' },
                  { value: 60, label: '1 hora' },
                  { value: 90, label: '1 hora 30 minutos' },
                  { value: 120, label: '2 horas' },
                ]}
              />
              <span style={{ color: 'red' }}>
                <ErrorMessage name="duration" />
              </span>
            </div>
            <div style={{ marginTop: '2em', display: 'flex', justifyContent: 'center' }}>
              <button
                type="submit"
                className="tn-btn --primary"
                disabled={isSubmitting}
                style={{
                  maxWidth: 300,
                  height: 45,
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  cursor: isSubmitting ? 'default' : 'pointer',
                }}>
                {isSubmitting ? (
                  <CircularProgress size={25} style={{ color: '#00d5a1' }} />
                ) : (
                  'Agendar videollamada'
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default VideoModalContent;
