import React from 'react';
import css from './PublicProjectCard.module.scss';
import ProfilePicture from 'components/common/ProfilePicture/ProfilePicture';
import { RenderTimestamp, Text, Title, ProjectTags } from '@components/common';
import { capitalizeFirst } from '@common/utils/Formaters';
import { categoriesNames } from '@common/utils/CardsUtils';
import { useHistory } from 'react-router-dom';

function PublicProjectCard({ project }) {
  const history = useHistory();

  const user = {
    id: project.owner,
    profile_image: project.profile_image,
    overall_rating: project.owner_rating,
  };

  const categories = project.all_categories
    ? 'Me gustaría que los freelancers me hagan una recomendación de CMS en su propuesta.'
    : categoriesNames(project.project_subcategories_names);

  return (
    <div className={css.container} onClick={() => history.push(`/project/${project.id}/view`)}>
      <div className={css.left}>
        <ProfilePicture
          user={user}
          width={100}
          withEditButton={false}
          medals={project.user_medal}
          withRatings
        />
      </div>
      <div className={css.right}>
        {' '}
        <Text className="project-owner-text" type="detail" strong>
          {project.owner_name} necesita
        </Text>
        <Title color="primary" style={{ wordBreak: 'break-all' }}>
          {capitalizeFirst(project?.title)}
        </Title>
        <Text type="primary" strong>
          {project.project_category_name}
        </Text>
        <Text type="primary" strong>
          {categories}
        </Text>
        <Text className={css.description}>{project.description}</Text>
        <div className={css.tags}>
          <ProjectTags
            projectType={project?.project_type}
            neededType={project?.needed_type}
            websiteType={project?.website_type}
          />
        </div>
        <div className={css.timeStamp}>
          <Text type="detail" strong>
            {RenderTimestamp(project.created_at).toLowerCase()}
          </Text>
        </div>
      </div>
    </div>
  );
}

export default PublicProjectCard;
