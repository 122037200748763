import React from 'react';
import { FormItemCard } from '../../FormItemCard';
import { actualDBStatusOptions } from 'common/constants/publicationForm.constants';
import { Radio, Space } from 'antd';
import { Text } from '@components/common';

const ActualDBStatus = ({
  values,
  handleSelectActualDBStatus,
  currentFocus,
  handleGoBackQuestion,
  handleGoForwardQuestion,
  displayDistinctQuestion,
}) => {
  return (
    <FormItemCard
      name="actual_db_status"
      title="¿Cuál es el estado actual de tu base de datos?"
      subTitle={'Elige una opción'}
      isCurrent={currentFocus == 'actual_db_status'}
      id={'actual_db_status-container'}
      handleGoForwardQuestion={handleGoForwardQuestion}
      handleGoBackQuestion={handleGoBackQuestion}
      displayDistinctQuestion={displayDistinctQuestion}>
      <Radio.Group
        value={values['actual_db_status']?.name}
        disabled={currentFocus == 'actual_db_status' ? false : true}>
        <Space direction="vertical">
          {actualDBStatusOptions?.map((item) => {
            return (
              <Radio
                onChange={() => handleSelectActualDBStatus(item)}
                value={item.name}
                key={`mult-opt-${item.id}`}>
                <Text>{item.name}</Text>
              </Radio>
            );
          })}
        </Space>
      </Radio.Group>
    </FormItemCard>
  );
};

export default ActualDBStatus;
