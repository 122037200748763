import React from 'react';
import './OrderBy.scss';

const OrderBy = ({ orderBy, orderByOptions, handleOrderBy }) => {
  return (
    <div className="orderBy-button">
      Ordenar por
      <select className="orderBy-select" onChange={handleOrderBy} value={orderBy}>
        {orderByOptions.map((option, index) => (
          <option key={`op-${index}`} value={option.value}>
            {option.text}
          </option>
        ))}
      </select>
    </div>
  );
};

export default OrderBy;
