import React from 'react';
import { Pagination } from 'antd';
import PublicFreelancerCard from './PublicFreelancerCard';
import { LoadingDots, EmptyListCard } from 'components/common';
import css from './FreelancersFeed.module.scss';
import { getUsersWithServices } from 'actions/users';
import { handleCategoriesSubmit } from 'common/utils/FiltersUtils';

function FreelancersFeed({ currentFilters, orderBy, currentPage, handlePagination }) {
  const [publicFreelancers, setPublicFreelancers] = React.useState([]);
  const [totalObjects, setTotalObjects] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setIsLoading(true);
    const filterText = handleCategoriesSubmit(currentFilters);
    getUsersWithServices(currentPage, filterText, orderBy)
      .then((res) => {
        setPublicFreelancers(res.data.objects);
        setTotalObjects(res.data.total_objects);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [currentPage, currentFilters, orderBy]);

  return (
    <div className={css.container}>
      {!isLoading ? (
        publicFreelancers?.length > 0 ? (
          publicFreelancers?.map((freelancer, index) => {
            return <PublicFreelancerCard key={`freelancer-${index}`} freelancer={freelancer} />;
          })
        ) : (
          <EmptyListCard type="welcome-no-projects" />
        )
      ) : (
        <LoadingDots />
      )}
      <div className={css.pagination}>
        {' '}
        <Pagination
          onChange={handlePagination}
          current={currentPage}
          defaultPageSize={10}
          total={totalObjects}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
}

export default FreelancersFeed;
