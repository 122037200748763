export const TENECESITO_TEAM_LIST = [
  {
    url: 'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/aboutUs/fotoFernandoSalinas.png',
    name: 'Fernando Salinas',
    position: 'CTO',
    description:
      'Ingeniero Civil ​Industrial TI de la Pontificia Universidad Católica. ​Ha trabajado como analista de inteligencia de negocios en Ripley y Scotiabank. ​Programó la plataforma TeNecesito desde el primer día y lo continúa haciendo.',
  },
  {
    url: 'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/aboutUs/fotoJuanPabloHidalgo.png',
    name: 'Juan Pablo Hidalgo',
    position: 'CEO',
    description:
      'Ingeniero Civil ​en Diseño y Construcción de Obras de la Pontificia Universidad Católica. ​Ha desarrollado su carrera en la administración, planificación y control de proyectos, con un foco en la ingeniería de procesos en busca de la mejora continua.',
  },
  {
    url: 'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/aboutUs/fotoFranciscoHerrera.png',
    name: 'Francisco Herrera',
    position: 'CFO',
    description:
      'Ingeniero Civil Industrial, mención en Transporte y Logística de la Pontificia Universidad Católica. ​Con experiencia en contabilidad, tributaria y finanzas. También es Jefe de Control de Gestión Financiera en el holding Leñadura SA. ',
  },

  {
    url: 'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/aboutUs/fotoTomasFuentes.png',
    name: 'Tomas Fuentes',
    position: 'Desarrollo Frontend',
    description:
      'Ingeniero Civil de la Pontificia Universidad Católica mención TI. Trabajó en Cornershop como Engineering manager coordinando a equipos de desarrollo, asegurándome de la calidad de estos. Experiencia en gestión de equipos TI, además de desarrollo Frontend y Fullstack.',
  },
  {
    url: 'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/aboutUs/fotoFranciscoMiranda.png',
    name: 'Francisco Miranda',
    position: 'Desarrollo Backend',
    description:
      'Ingeniero Industrial con mención en TI de la Pontificia Universidad Católica. Con experiencia en proyectos de desarrollo de software, junto con incursiones en la optimización de procesos y la evaluación financiera de proyectos.',
  },
  {
    url: 'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/aboutUs/fotoSebastianRamirez.png',
    name: 'Sebastian Ramirez',
    position: 'Desarrollo Backend',
    description:
      'Ingeniero Civil en Informática de la Pontificia Universidad Católica de Valparaíso. Experiencia en gestión de proyectos y desarrollo web. Especialista en el Framework Django y lenguaje de programación Python.',
  },
  {
    url: 'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/aboutUs/fotoMaximilianoCarrasco.png',
    name: 'Maximiliano Carrasco',
    position: 'Desarrollo Frontend',
    description:
      'Desarrollador autodidacta en aprendizaje continuo. Cuenta con experiencia en el desarrollo de distintas aplicaciones web y móvil, es especialista en React.',
  },
  {
    url: 'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/aboutUs/fotoAuroraCoddou.png',
    name: 'Aurora Coddou',
    position: 'Periodista',
    description:
      'Periodista y Comunicadora Social de la Pontificia Universidad Católica de Chile. Con experiencia en marketing, redacción de contenido estratégico y lenguaje UX.',
  },
];
export const TENECESITO_ADVISORS_LIST = [
  {
    url: 'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/aboutUs/Tomas_Vera.png',
    name: 'Tomás Vera',
    position: 'Director Zenta Group',
    description:
      'PhD(c) en Ciencias, mención Computación de la Universidad de Chile, Asesor del Congreso Futuro, Docente Universidad de Chile, Docente de la Universidad Mayor y Docente de la Academia Politécnica Militar.',
  },
  {
    url: 'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/aboutUs/Daniel_Perovich.png',
    name: 'Daniel Perovich',
    position: 'Profesor U. de Chile',
    description:
      'Doctor en Ciencias, mención Computación de la Universidad de Chile, Magíster en Informática de la Universidad de la República de Uruguay e Ingeniero en Computación de la Universidad de la República de Uruguay.',
  },
];
