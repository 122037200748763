/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import { BaseLayout, LoadingDots, CustomBreadcrumb, InformativeAdvice } from '@components/common';
import { ClientPublicationManagmentViewModel } from './ClientPublicationManagmentViewModel';
import { PublicationManagmentMainCard } from '@components/project/ProjectMainCard/PublicationManagmentMainCard/PublicationManagmentMainCard';
import { PaymentDrawer } from '@components/payment/PaymentDrawer/PaymentDrawer';
import { OtherRequesters } from '@components/requests/OtherRequesters/OtherRequesters';

import {
  PERSON_7_ILUSTRATION,
  PERSON_6_ILUSTRATION,
} from '@common/constants/informativeIlustrations.constants';
import './ClientPublicationManagment.scss';
import ApplicantTabs from 'components/project/ApplicantTabs';
import { PAYMENT_PROJECTS_LABEL } from 'common/constants/project.constants';
import StandardModal from 'components/common/StandardModal';
import SuggestionInvite from 'components/common/Suggestions/SuggestionsInvite';
import { Button, Text } from 'components/common';
import { Users } from 'react-feather';
import { Badge } from 'antd';
import RenderIf from 'components/common/RenderIf';
import { INVITES_MODAL_STEP } from './ClientPublicationManagment.constants';
import PhoneNumberModalContent from './PhoneNumberModalContent/PhoneNumberModalContent';

const ClientPublicationManagment = () => {
  const {
    isLoadingProject,
    isLoadingProposal,
    isLoadingChat,
    isLoadingRequestersList,
    isLoadingFiles,
    files,
    currentProject,
    requestersList,
    currentRequester,
    currentRequest,
    currentProposal,
    currentChat,
    authUser,
    handleAcceptProposal,
    handleEditProject,
    handleDeleteProject,
    useImageUploadHandler,
    handleCompleteProfile,
    handleCreateNewPublication,
    showApplicantModal,
    setShowApplicantModal,
    handleOnClickApplicant,
    intellectualProp,
    setIntellectualProp,
    paymentDrawer,
    setPaymentDrawer,
    setProjectStatus,
    handleOpenPaymentDrawer,
    handleReplyQuestion,
    questionsList,
    isLoadingQuestions,
    handleRejectRequest,
    handleGetFiles,
    unansweredQuestions,
    resetRequesterMessages,
    selectedTab,
    showRejectModal,
    setShowRejectModal,
    isLoadingAcceptProposal,
    servicesSuggestions,
    handleLeftWindow,
    prefix,
    setPrefix,
    currentPhoneNumber,
    setCurrentPhoneNumber,
    handleConfirmPhoneNumber,
    showModal,
    setShowModal,
    isValidatingPayment,
    step,
  } = ClientPublicationManagmentViewModel();

  return (
    <Fragment>
      {(servicesSuggestions?.length > 0 || step === INVITES_MODAL_STEP.PHONE) && (
        <StandardModal
          visible={showModal}
          onCancel={() => setShowModal(false)}
          width={768}
          title={
            step === INVITES_MODAL_STEP.PHONE
              ? 'Confirma tu número de teléfono'
              : 'Freelancers recomendados para tu proyecto'
          }>
          <RenderIf isTrue={step === INVITES_MODAL_STEP.PHONE}>
            <div style={{ maxWidth: 560, margin: '0 auto' }}>
              <PhoneNumberModalContent
                prefix={prefix}
                setPrefix={setPrefix}
                currentPhoneNumber={currentPhoneNumber}
                setCurrentPhoneNumber={setCurrentPhoneNumber}
                handleConfirmPhoneNumber={handleConfirmPhoneNumber}
              />
            </div>
          </RenderIf>
          <RenderIf isTrue={step === INVITES_MODAL_STEP.SUGGESTIONS}>
            <div>
              <Text centered style={{ marginBottom: 20 }}>
                Invítalos a hacer una propuesta
              </Text>
              <SuggestionInvite
                suggestionsData={servicesSuggestions}
                type="client"
                currentProject={currentProject}
              />
            </div>
          </RenderIf>
        </StandardModal>
      )}

      <span className="postulantes_custom_button" onClick={handleLeftWindow}>
        <Badge count={requestersList?.length} />
        <Users />
      </span>
      <CustomBreadcrumb type="client-publication" title={currentProject?.title} />
      <BaseLayout>
        <BaseLayout.LeftSide>
          {requestersList &&
            (requestersList?.length > 0 ? (
              !isLoadingRequestersList ? (
                <div className="layoutContainer">
                  <div className="tn-layout__left-section">
                    <OtherRequesters
                      onlySelected={isValidatingPayment}
                      requestersList={requestersList}
                      handleOnClickApplicant={handleOnClickApplicant}
                      currentRequester={currentRequester}
                    />
                  </div>
                </div>
              ) : (
                <LoadingDots />
              )
            ) : currentProject.active ? (
              <Fragment>
                <div className="layoutContainer">
                  <div className="tn-layout__left-section">
                    <InformativeAdvice
                      image={PERSON_7_ILUSTRATION}
                      message="Aún no tienes postulantes, recuerda que es importante que entregues la mayor cantidad de información en tu publicación. Aún puedes editar tu proyecto."
                      buttonText="Editar publicación"
                      buttonOnClick={handleEditProject}
                    />
                  </div>
                  <div className="tn-layout__left-section">
                    <InformativeAdvice
                      image={PERSON_6_ILUSTRATION}
                      message="Recuerda completar tu perfil para aumentar las posibilidades de tener propuestas."
                      buttonText="Completar perfil"
                      buttonOnClick={handleCompleteProfile}
                    />
                  </div>
                </div>
              </Fragment>
            ) : (
              <div className="tn-layout__left-section">
                <InformativeAdvice
                  image={PERSON_7_ILUSTRATION}
                  message="Tu proyecto fué desactivado por no tener actividad en las últimas semanas. Si deseas retomar el proyecto escríbenos un Whatsapp y te ayudaremos en lo que necesites. O bien puedes publicar un nuevo proyecto."
                  buttonText="Publica otro proyecto"
                  buttonOnClick={handleCreateNewPublication}
                />
              </div>
            ))}
        </BaseLayout.LeftSide>
        <BaseLayout.Content>
          {currentProject && currentProposal && (
            <PaymentDrawer
              currentProject={currentProject}
              currentProposal={currentProposal}
              setProjectStatus={setProjectStatus}
              paymentDrawer={paymentDrawer}
              setPaymentDrawer={setPaymentDrawer}
            />
          )}
          {!isLoadingProject && currentProject ? (
            <Fragment>
              <PublicationManagmentMainCard
                currentProject={currentProject}
                handleEditProject={handleEditProject}
                handleDeleteProject={handleDeleteProject}
                handleOpenPaymentDrawer={handleOpenPaymentDrawer}
              />

              {PAYMENT_PROJECTS_LABEL.includes(currentProject.status) && (
                <div style={{ textAlign: 'center', margin: '8px 0' }}>
                  <Button
                    text="Realizar pago"
                    variant="primary"
                    color="secondary"
                    style={{ padding: '6px 20px' }}
                    onClick={() => handleOpenPaymentDrawer()}
                  />
                </div>
              )}

              <ApplicantTabs
                currentRequester={currentRequester}
                currentRequest={currentRequest}
                currentProposal={currentProposal}
                showApplicantModal={showApplicantModal}
                setShowApplicantModal={setShowApplicantModal}
                isLoadingProposal={isLoadingProposal}
                currentChat={currentChat}
                isLoadingChat={isLoadingChat}
                authUser={authUser}
                isLoadingFiles={isLoadingFiles}
                files={files}
                useImageUploadHandler={useImageUploadHandler}
                handleAcceptProposal={handleAcceptProposal}
                intellectualProp={intellectualProp}
                setIntellectualProp={setIntellectualProp}
                handleRejectRequest={handleRejectRequest}
                handleGetFiles={handleGetFiles}
                currentProject={currentProject}
                isLoadingQuestions={isLoadingQuestions}
                questionsList={questionsList}
                handleReplyQuestion={handleReplyQuestion}
                unansweredQuestions={unansweredQuestions}
                resetRequesterMessages={resetRequesterMessages}
                selectedTab={selectedTab}
                showRejectModal={showRejectModal}
                setShowRejectModal={setShowRejectModal}
                isLoadingAcceptProposal={isLoadingAcceptProposal}
              />
            </Fragment>
          ) : (
            <LoadingDots />
          )}
        </BaseLayout.Content>
      </BaseLayout>
    </Fragment>
  );
};
export default ClientPublicationManagment;
