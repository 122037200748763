import React from 'react';
import { LoadingDots, Text } from 'components/common';
import { Radio, Space } from 'antd';
import { FormItemCard } from '../../FormItemCard';
import { categoryText } from 'common/constants/publicationForm.constants';

const Category = ({
  values,
  handleSelectCategory,
  mainCategories,
  isLoadingMainCategories,
  currentFocus,
  displayDistinctQuestion,
  handleGoForwardQuestion,
}) => {
  return (
    <FormItemCard
      name="category"
      title="¿Con qué se relaciona lo que necesitas hacer?"
      subTitle={'Elige una opción'}
      isCurrent={currentFocus == 'category'}
      id={'category-container'}
      handleGoForwardQuestion={handleGoForwardQuestion}
      displayDistinctQuestion={displayDistinctQuestion}>
      {isLoadingMainCategories ? (
        <LoadingDots />
      ) : (
        <Radio.Group
          value={values['category']?.name}
          disabled={currentFocus == 'category' ? false : true}>
          <Space direction="vertical">
            {mainCategories?.map((item) => {
              return (
                categoryText[item.id] && (
                  <Radio
                    onChange={() => handleSelectCategory(item)}
                    value={item.name}
                    key={`mult-opt-${item.id}`}>
                    <Text>{categoryText[item.id]}</Text>
                  </Radio>
                )
              );
            })}
          </Space>
        </Radio.Group>
      )}
    </FormItemCard>
  );
};

export default Category;
