import axiosClient from '@config/axios';
import { tokenConfig } from './auth';
import { createMessage, returnErrors } from './messages';
import { GET_CATEGORIES, DELETE_CATEGORY, ADD_CATEGORY, GET_SUB_CATEGORIES } from './types';

// GET Categories
export const getCategories = (all) => async (dispatch, getState) => {
  if (all) {
    await axiosClient
      .get(`/categories`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_CATEGORIES,
          payload: res.data,
        });
        return res;
      })
      .catch((err) => console.log(err));
  } else {
    await axiosClient
      .get(`/categories/?main=true`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_CATEGORIES,
          payload: res.data,
        });
        return res;
      })
      .catch((err) => console.log(err));
  }
};
export const getMainCategories_v2 = () => axiosClient.get(`/categories?main=true`);
export const getMainCategories = () => {
  return axiosClient.get(`/categories/?main=true`);
};

export const getSubCategories_v2 = (categoryid) =>
  axiosClient.get(`/categories/?sub-category=${categoryid}`);

export const getCategory = (categoryid) => axiosClient.get(`/categories/${categoryid}`);

export const getSubCategories = (categoryid) => async (dispatch, getState) => {
  await axiosClient
    .get(`/categories/?sub-category=${categoryid}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SUB_CATEGORIES,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

// DELETE Category
export const deleteCategory = (id) => (dispatch, getState) => {
  axiosClient
    .delete(`/categories/${id}/`, tokenConfig(getState))
    .then(() => {
      dispatch(createMessage({ deleteCategory: 'Category Deleted' }));
      dispatch({
        type: DELETE_CATEGORY,
        payload: id,
      });
    })
    .catch((err) => console.log(err));
};

// ADD Category
export const addCategory = (category) => (dispatch, getState) => {
  category = {
    name: category.name,
  };
  axiosClient
    .post(`/categories/`, category, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addCategory: 'Category Added' }));
      dispatch({
        type: ADD_CATEGORY,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};
