import { Text } from 'components/common';
import PreviousProjects from 'pages/Managment/MyProfile/PreviousProjects';
import React from 'react';

const YourWorks = ({ authUser }) => {
  return (
    <div style={{ padding: '0 2em' }}>
      <Text centered>Agrega proyectos que hayas realizado anteriormente.</Text>
      <Text centered style={{ marginBottom: '3em' }}>
        Estos quedarán visibles en tu perfil, y tus clientes podrán verlos al momento de hacerles
        una propuesta.
      </Text>
      <PreviousProjects user={authUser?.user} withAdd />
    </div>
  );
};

export default YourWorks;
