import {
  UPDATE_ORDER_BY,
  UPDATE_FILTERS,
  UPDATE_SEARCHBAR,
  UPDATE_SKILLS,
  UPDATE_PROJECT_PAGE,
  UPDATE_SERVICE_PAGE,
  UPDATE_HOME_SWITCH,
} from '../actions/types.js';
import { updateFilters } from '../common/utils/FiltersUtils.js';

const initialState = {
  orderBy: 'most_recent',
  categories: [],
  searchbar: '',
  skills: [],
  projectPage: 1,
  servicePage: 1,
  filteringProjects: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_ORDER_BY:
      return {
        ...state,
        orderBy: action.payload,
      };
    case UPDATE_FILTERS:
      return {
        ...state,
        categories: updateFilters(action.payload, state.categories),
      };
    case UPDATE_SEARCHBAR:
      return {
        ...state,
        searchbar: action.payload,
      };
    case UPDATE_SKILLS:
      return {
        ...state,
        skills: action.payload,
      };
    case UPDATE_PROJECT_PAGE:
      return {
        ...state,
        projectPage: action.payload,
      };
    case UPDATE_SERVICE_PAGE:
      return {
        ...state,
        servicePage: action.payload,
      };
    case UPDATE_HOME_SWITCH:
      return {
        ...state,
        filteringProjects: action.payload,
      };
    default:
      return state;
  }
}
