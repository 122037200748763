export function centerElementById(id) {
  if (document.getElementById(id)) {
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
    document.getElementById(id).focus();
  }
}

export function scrollToBottom(id) {
  if (document.getElementById(id)) {
    document.getElementById(id).scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
    document.getElementById(id).focus();
  }
}
