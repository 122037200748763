import React from 'react';
import css from './ErrorType.module.scss';
import { Text } from '@components/common';

function ErrorType({ currentProject }) {
  return (
    <div className={css.container}>
      <Text type="detail">Errores: </Text>
      <div className={css.content}>
        <div className={css.errors}>
          {currentProject?.problem_type?.map((error, index) => {
            return (
              <li key={`error-${index}`}>
                <Text type="detail">{`● ${error}`} </Text>
              </li>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ErrorType;
