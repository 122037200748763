import React from 'react';
import { Button, Title } from '@components/common';
import { YoutubeEmbed } from '@components/common/YoutubeEmbed';
import { HOWITWORKS_WORK } from 'common/constants/routes.constants';
import { useHistory } from 'react-router-dom';
import './Section3.scss';
import AnimateOnView from 'components/AnimateOnView';

const Section3 = () => {
  const history = useHistory();
  const handleGoToHowItWorks = () => {
    history.push(HOWITWORKS_WORK);
  };
  return (
    <div className="section3__container">
      <div className="section3__content">
        <AnimateOnView className="section3__left">
          <YoutubeEmbed embedId="-suFAg_pbD0" />
        </AnimateOnView>
        <AnimateOnView className="section3__right">
          <Title type="xl" color="black">
            Esto es TeNecesito,
          </Title>
          <Title className="section3__subtitle" type="primary" color="lightgray">
            Plataforma exclusiva para programadores freelance
          </Title>
          <Title className="landing__primary-title" type="xl" color="black">
            ¿Quieres saber más?
          </Title>
          <Button
            onClick={handleGoToHowItWorks}
            text="Cómo funciona"
            variant="secondary"
            type="landing-btn"
          />
        </AnimateOnView>
      </div>
    </div>
  );
};

export default Section3;
