import React from 'react';
import css from './MigrationReason.module.scss';
import { Text } from '@components/common';
import {
  FRAMEWORKS_LOGOS_DICTIONARY,
  ilustrationsFolder,
} from '../../IntegrationsDetails.constants';

function MigrationReason({ currentProject }) {
  return (
    <div className={css.container}>
      <Text type="detail">Razones de la migración: </Text>
      <div className={css.content}>
        <div className={css.reasons}>
          {currentProject?.why_migrate?.map((reason, index) => {
            return (
              <li key={`reason-${index}`}>
                <Text type="smallDetail">{`● ${reason}`} </Text>
              </li>
            );
          })}
        </div>
      </div>
      <Text type="detail">Cambios de diseño: </Text>
      <div className={css.content}>
        <div className={css.reasons}>
          {currentProject?.design_changes?.map((change, index) => {
            return (
              <li key={`change-${index}`}>
                <Text type="smallDetail">{`● ${change}`} </Text>
              </li>
            );
          })}
        </div>
      </div>
      <Text type="detail">Nuevo framework: </Text>
      <div className={css.content}>
        <div className={css.frameworks}>
          {currentProject?.future_framework?.map((frame, index) => {
            return (
              <div className={css.item} key={`frame-${index}`}>
                {FRAMEWORKS_LOGOS_DICTIONARY[frame] ? (
                  <img src={ilustrationsFolder + FRAMEWORKS_LOGOS_DICTIONARY[frame]} />
                ) : (
                  <div className={css.empty}>
                    {' '}
                    <img src={ilustrationsFolder + 'dreamer_e856445593.svg'} />
                    <Text type="smallDetail">{frame}</Text>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MigrationReason;
