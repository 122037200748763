import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import GoogleOAuthBtn from '../components/GoogleOAuthBtn';
import { Form, Input, Checkbox } from 'antd';
import { Button } from '@components/common/Button';
import { Title } from '@components/common';
import './Login.scss';
import useAuthButtons from 'hooks/useAuthButtons';

export const Login = ({ currentAuthUser, useLogin }) => {
  const { setShowLoginWindow, setShowRegisterWindow } = useAuthButtons();
  const onSubmit = async (e) => {
    try {
      useLogin(e.email, e.password);
      setShowLoginWindow(false);
    } catch (error) {
      console.log(error);
    }
  };
  const handleRegisterWindow = () => {
    setShowRegisterWindow(true);
    setShowLoginWindow(false);
  };
  React.useEffect(() => {
    if (currentAuthUser?.isAuthenticated) {
      return <Redirect to="/home" />;
    }
  }, []);

  return (
    <div className="login__container">
      <Fragment>
        <div className="card-login__container">
          <div className="card-login__header">
            <Title color="gray">Hola,</Title>
            <Title color="main">¡Bienvenid@!</Title>
          </div>
          <GoogleOAuthBtn
            setShowRegisterWindow={setShowRegisterWindow}
            setShowLoginWindow={setShowLoginWindow}
          />
          <div className="register-login-option-container">
            <hr className="register-login-option-border-left" />
            <div className="option-text">O usa tu email</div>
            <hr className="register-login-option-border-right" />
          </div>
          <Form
            onFinish={onSubmit}
            name="login"
            initialValues={{
              remember: true,
            }}>
            <div className="form-group-login">
              <label className="label-form-text">Email</label>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingresa tu email.',
                  },
                ]}>
                <Input placeholder="Email" />
              </Form.Item>
            </div>
            <div className="form-group-login">
              <label className="label-form-text">Contraseña</label>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Por favor ingresa tu contraseña.',
                  },
                ]}>
                <Input type="password" placeholder="Contraseña" />
              </Form.Item>
              <Form.Item>
                <div className="remember-forgot-container">
                  <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox style={{ color: '#3383ff' }}>Recuérdame</Checkbox>
                  </Form.Item>
                </div>
              </Form.Item>
              <Form.Item>
                <div className="button-login-container">
                  <Button text="Iniciar sesión" variant="primary" color="primary" />
                  <a
                    onClick={() => setShowLoginWindow(false)}
                    className="tn-text primaryColor tn-text--regular"
                    href="#/forgotPassword">
                    Olvidé mi contraseña
                  </a>
                  <a onClick={handleRegisterWindow} className="tn-text tn-text--regular">
                    ¿No tienes cuenta aún?. Regístrate aca.
                  </a>
                </div>
              </Form.Item>
            </div>
            <br />
          </Form>
        </div>
      </Fragment>
    </div>
  );
};
