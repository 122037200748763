import React from 'react';
import classNames from 'classnames';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './LoadingSpin.scss';

const LoadingSpin = ({ size, tip, fullWidth, className }) => {
  const antLoadingIcon = <LoadingOutlined style={{ fontSize: size + 'px' }} spin />;

  const containerClasses = classNames('loading-spin-container', className);

  if (!fullWidth) {
    return (
      <div className={containerClasses}>
        <Spin tip={tip} indicator={antLoadingIcon} className />
      </div>
    );
  }
  return (
    <div className="loading-spin-container-fullwidth">
      <Spin tip={tip} indicator={antLoadingIcon} />
    </div>
  );
};

export default LoadingSpin;
