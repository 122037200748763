/* eslint-disable no-unused-vars */

import React, { useEffect } from 'react';
import css from './ChatWidget.module.scss';
import { ChevronDown, ChevronUp, Search } from 'react-feather';
import SingleChat from './SingleChat';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'reducers/auth';
import { getTimeAgo, subStr } from 'common/utils/Formaters';
import useChatWidget from 'hooks/useChatWidget';

const ChatWidget = () => {
  const auth = useSelector(selectAuthUser);

  const {
    openChat,
    openedChats,
    chats,
    setOpenChat,
    handleOpenChat,
    handleRemoveChat,
    searchValue,
    setSearchValue,
    filteredChats,
    openedChatsHistory,
    isSmall,
    handleChat,
    unreadedMessages,
  } = useChatWidget();

  const location = useLocation();

  const currentRoute = location.pathname;

  const getLastMessage = (chat) => {
    return chat?.last_message?.content;
  };
  const isOwnerOfLastMessage = (chat) => {
    return chat?.last_message?.owner === auth?.user?.id;
  };

  const HIDDEN_ROUTES = [
    '/chats',
    '/integrations-form',
    '/cms-form',
    '/forms',
    '/createProject/new',
  ];

  if (!auth.isAuthenticated || HIDDEN_ROUTES.includes(currentRoute)) return null;

  return (
    <div className={css.widget_container}>
      {!isSmall &&
        openedChats?.map((chat) => (
          <SingleChat
            key={chat}
            chat={chats?.find((p) => p?.id === chat)}
            opened
            handleRemoveChat={() => handleRemoveChat(chat)}
          />
        ))}
      {openChat ? (
        <>
          <div>
            <div className={css.chat_header} onClick={() => setOpenChat(false)}>
              <div className={css.chat_header_title}>
                <div className={css.white_circle} />
                <div>Chat</div>
                {unreadedMessages > 0 && <span className={css.unreaded}>{unreadedMessages}</span>}
              </div>
              <ChevronDown style={{ width: 28, height: 28 }} />
            </div>
            <div className={css.chat_content_main}>
              <div className={css.chat_items_list}>
                {filteredChats?.map((chat) => (
                  <div
                    key={`${chat?.id} ${chat?.no_readed_messages}`}
                    className={css.chat_item}
                    onClick={() => handleOpenChat(chat?.id)}>
                    <div>
                      <img src={chat?.profile_pic} alt="profile_pic" />
                    </div>
                    <div style={{ width: '100%', position: 'relative' }}>
                      <span>{subStr(chat?.user_name, 20)}</span>
                      <span style={{ display: 'block', fontSize: 12 }}>
                        {subStr(chat?.title, 35)}
                      </span>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          fontSize: 10,
                          color: 'gray',
                        }}>
                        <span>
                          {isOwnerOfLastMessage(chat) ? 'Tú : ' : 'Él : '}
                          {subStr(getLastMessage(chat), 20)}
                        </span>
                        <span>
                          {getTimeAgo(new Date(), new Date(chat?.last_message?.created_at))}
                        </span>
                      </div>
                      {chat?.not_readed_messages > 0 && !openedChatsHistory.includes(chat?.id) && (
                        <span className={css.unreaded_msgs}>{chat?.not_readed_messages}</span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={css.chat_search}>
              <input
                type="text"
                placeholder="Buscar..."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <Search style={{ color: '#3383ff' }} />
            </div>
          </div>
        </>
      ) : (
        <div className={css.chat_header} onClick={handleChat}>
          <div className={css.chat_header_title}>
            <div className={css.white_circle} />
            <div>Chat</div>
            {unreadedMessages > 0 && <span className={css.unreaded}>{unreadedMessages}</span>}
          </div>
          <ChevronUp style={{ width: 28, height: 28 }} />
        </div>
      )}
    </div>
  );
};

export default ChatWidget;
