import useChatWidget from 'hooks/useChatWidget';
import React from 'react';
import OpenedChat from './OpenedChat';
import css from './Chats.module.scss';

const ChatContainer = ({ chatId }) => {
  const { chats } = useChatWidget();
  return (
    <div>
      <div className={css.header}></div>

      <div
        style={{
          borderRadius: '0px 0px 10px 10px',
          height: '420px',
          backgroundColor: 'white',
        }}>
        <div className={css.opened_chat_container}>
          {chatId && <OpenedChat chat={chats?.find((chat) => chat?.id === chatId)} />}
        </div>
      </div>
    </div>
  );
};

export default ChatContainer;
