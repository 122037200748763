import React, { createContext, useState } from 'react';

export const AuthButtonsContext = createContext({});

export const AuthButtonsProvider = ({ children }) => {
  const [showRegisterWindow, setShowRegisterWindow] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [showLoginWindow, setShowLoginWindow] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);

  return (
    <AuthButtonsContext.Provider
      value={{
        showRegisterWindow,
        setShowRegisterWindow,
        confirmationModal,
        setConfirmationModal,
        showLoginWindow,
        setShowLoginWindow,
        showAuthModal,
        setShowAuthModal,
      }}>
      {children}
    </AuthButtonsContext.Provider>
  );
};
