import React from 'react';
import './StatusTag.scss';
import {
  COMPLETED_PROJECTS,
  PUBLISHED_PROJECTS,
  INPROGRESS_PROJECTS_LABEL,
  PAYMENT_PROJECTS_LABEL,
  FEEDBACK_PROJECT_LABEL,
} from '@common/constants/project.constants';
import {
  ACTIVE_REQUEST,
  REJECTED_REQUEST,
  PAYMENT_REQUEST,
} from '@common/constants/request.constants';
import {
  PUBLISHED_PROJECT_COLOR,
  PAYMENT_PROJECT_COLOR,
  INPROGRESS_PROJECT_COLOR,
  FEEDBACK_PROJECT_COLOR,
  COMPLETED_PROJECT_COLOR,
  ACTIVE_REQUEST_COLOR,
  PAYMENT_REQUEST_COLOR,
  REJECTED_REQUEST_COLOR,
  MEDIATION_PROJECT_COLOR,
} from 'common/constants/colors.constants';
import { MEDIATION_PROJECTS_LABEL } from 'common/constants/project.constants';

export function StatusTag({ project, request }) {
  const [statusText, setStatusText] = React.useState();
  const [backgroundColor, setBackgroundColor] = React.useState();

  React.useEffect(() => {
    if (project) {
      if (project.status == PUBLISHED_PROJECTS) {
        setStatusText('Publicado');
        setBackgroundColor(PUBLISHED_PROJECT_COLOR);
      } else if (PAYMENT_PROJECTS_LABEL.includes(project.status)) {
        setStatusText('Validando pago');
        setBackgroundColor(PAYMENT_PROJECT_COLOR);
      } else if (project.status == INPROGRESS_PROJECTS_LABEL) {
        setStatusText('En progreso');
        setBackgroundColor(INPROGRESS_PROJECT_COLOR);
      } else if (project.status == FEEDBACK_PROJECT_LABEL) {
        setStatusText('Feedback');
        setBackgroundColor(FEEDBACK_PROJECT_COLOR);
      } else if (project.status == COMPLETED_PROJECTS) {
        setStatusText('Completado');
        setBackgroundColor(COMPLETED_PROJECT_COLOR);
      } else if (project.status == MEDIATION_PROJECTS_LABEL) {
        setStatusText('En mediación');
        setBackgroundColor(MEDIATION_PROJECT_COLOR);
      }
    } else if (request) {
      if (request.status == ACTIVE_REQUEST) {
        setStatusText('Activa');
        setBackgroundColor(ACTIVE_REQUEST_COLOR);
      } else if (request.status == PAYMENT_REQUEST) {
        setStatusText('Validando pago');
        setBackgroundColor(PAYMENT_REQUEST_COLOR);
      } else if (request.status == REJECTED_REQUEST) {
        setStatusText('Rechazada');
        setBackgroundColor(REJECTED_REQUEST_COLOR);
      }
    }
  }, []);

  return (
    <div className="status-tag__container" style={{ backgroundColor: backgroundColor }}>
      <p
        className="status-tag__text tn-text tn-text--regular tn-text--small tn-text--strong"
        style={{ color: '#FFFFFF' }}>
        {statusText}
      </p>
    </div>
  );
}
