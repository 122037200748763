export const COUNTRY_PREFIX_LISTS = [
  {
    id: '1',
    name: 'Chile',
    prefix: '+56',
  },
  {
    id: '2',
    name: 'Bolivia',
    prefix: '+591',
  },
  {
    id: '3',
    name: 'Brasil',
    prefix: '+55',
  },
  {
    id: '4',
    name: 'Argentina',
    prefix: '+54',
  },
  {
    id: '5',
    name: 'Colombia',
    prefix: '+57',
  },
  {
    id: '6',
    name: 'Costa Rica',
    prefix: '+506',
  },
  {
    id: '7',
    name: 'Cuba',
    prefix: '+53',
  },
  {
    id: '8',
    name: 'República Dominicana',
    prefix: '+1-809',
  },
  {
    id: '9',
    name: 'Ecuador',
    prefix: '+593',
  },
  {
    id: '10',
    name: 'El Salvador',
    prefix: '+503',
  },
  {
    id: '11',
    name: 'Guatemala',
    prefix: '+502',
  },
  {
    id: '12',
    name: 'Haití',
    prefix: '+509',
  },
  {
    id: '13',
    name: 'Honduras',
    prefix: '+504',
  },
  {
    id: '14',
    name: 'México',
    prefix: '+52',
  },
  {
    id: '15',
    name: 'Nicaragua',
    prefix: '+505',
  },
  {
    id: '16',
    name: 'Panamá',
    prefix: '+507',
  },
  {
    id: '17',
    name: 'Paraguay',
    prefix: '+595',
  },
  {
    id: '18',
    name: 'Perú',
    prefix: '+51',
  },
  {
    id: '19',
    name: 'Puerto Rico',
    prefix: '+1-787',
  },
  {
    id: '20',
    name: 'Uruguay',
    prefix: '+598',
  },
  {
    id: '21',
    name: 'Venezuela',
    prefix: '+58',
  },
  {
    id: '22',
    name: 'España',
    prefix: '+34',
  },
];
