import React from 'react';
import './PaymentMethodOption.scss';
import { Button } from '@components/common';
import {
  ICONO_WEBPAY,
  ICONO_TENECESITO,
  ICONO_PAGO_POR_ETAPAS,
  ICONO_PAGO_TOTAL,
} from '@common/constants/assets.constants';
import { Tooltip } from 'antd';

export const PaymentMethodOption = ({ type, selected, handlePaymentOption, disabled, tooltip }) => {
  const [iconURL, setIconURL] = React.useState(ICONO_WEBPAY);

  React.useEffect(() => {
    if (type == 'webpay') {
      setIconURL(ICONO_WEBPAY);
    } else if (type == 'bankTransfer') {
      setIconURL(ICONO_TENECESITO);
    } else if (type == 'totalPayment') {
      setIconURL(ICONO_PAGO_TOTAL);
    } else if (type == 'stagesPayment') {
      setIconURL(ICONO_PAGO_POR_ETAPAS);
    }
  }, []);
  const styles1 = type == 'totalPayment' || type == 'stagesPayment' ? { width: 80 } : {};
  const styles2 = disabled ? { filter: 'grayscale(1)' } : {};
  return (
    <div className={`payment-method-option__container ${selected ? 'selected' : ''}`}>
      <Tooltip title={tooltip}>
        <img
          src={iconURL}
          className="payment-method-option__icon"
          alt="Icono Boleta"
          style={{ ...styles1, ...styles2 }}
        />
      </Tooltip>
      <Button
        text={
          type == 'totalPayment'
            ? 'Pago total'
            : type == 'stagesPayment'
            ? 'Pago por etapas'
            : type == 'webpay'
            ? 'Webpay'
            : type == 'bankTransfer'
            ? 'Transferencia'
            : ''
        }
        disabled={disabled}
        variant="secondary"
        color="primary"
        size="sm"
        borderColor={selected ? 'primary' : ''}
        onClick={handlePaymentOption}
      />
    </div>
  );
};
