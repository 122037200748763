import React, { Fragment } from 'react';
import { Title } from '@components/common';
import { LoadingOutlined } from '@ant-design/icons';
import ChatCard from '../ChatCard';
import { Spin } from 'antd';

import './SidePanel.scss';

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

const SidePanel = ({ currentUserChats, isLoading, currentChat }) => {
  return (
    <div className="chat__sidepanel">
      {isLoading ? (
        <div className="loadingChatSpin">
          <Spin tip="Cargando..." indicator={antIcon} />
        </div>
      ) : (
        <Fragment>
          <Title color="main" style={{ marginBottom: '30px' }}>
            Mensajes
          </Title>
          <div className="tn-chat-contacts">
            <ul className="contacts-list">
              {currentUserChats.map((chat) => (
                <ChatCard
                  key={chat.id}
                  data={chat}
                  isSelected={parseInt(chat.id) === parseInt(currentChat?.id)}
                />
              ))}
            </ul>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default SidePanel;
