import React from 'react';
import { RadioButton, Text } from '@components/common';
import './FilterTypes.scss';

const FilterTypes = ({ filter_types, onSelect, selectedState }) => {
  return (
    <div className="filtertypes-container">
      <div className="filtertypes-filters">
        <Text>Filtrar: </Text>
        {filter_types.map((filterType) => {
          return (
            <RadioButton
              idSelected={selectedState}
              itemId={filterType.id}
              onSelect={onSelect}
              key={`rad-${filterType.tag}`}>
              {filterType.name}
            </RadioButton>
          );
        })}
      </div>
    </div>
  );
};

export default FilterTypes;
