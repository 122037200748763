import React from 'react';
import { Title, Text } from '@components/common';
import './TeamMemberCard.scss';

const TeamMemberCard = ({ url, name, position, feed, description }) => {
  return (
    <div className="team-member-card">
      <div className="team-member__hover">
        <img src={url} className="team-member-card__image" />
        <p className="team-member__background">{description}</p>
      </div>
      <div className="team-member-card__feed">
        <Title size="18" type="subtitle">
          {name}
        </Title>
        <Text type="detail">{position}</Text>
        <Text type="primary">{feed}</Text>
      </div>
    </div>
  );
};

export default TeamMemberCard;
