import React from 'react';
import { getPaymentOrders, getClientOrders } from '@actions/myprofile';
import { ManagmentViewModel } from '../ManagmentViewModel';

export const ClearAccountsViewModel = () => {
  const { authUser } = ManagmentViewModel();

  const [moreDrawer, setMoreDrawer] = React.useState(false);
  const [currentPaymentOrder, setCurrentPaymentOrder] = React.useState();
  const [currentClientOrder, setCurrentClientOrder] = React.useState();

  const [paymentOrdersList, setPaymentOrdersList] = React.useState([]);
  const [isLoadingPaymentOrdersList, setIsLoadingPaymentOrdersList] = React.useState(false);

  const [clientOrdersList, setClientOrdersList] = React.useState([]);
  const [isLoadingClientOrdersList, setIsLoadingClientOrdersList] = React.useState(false);

  const [projectSelected, setProjectSelected] = React.useState(true);
  const [serviceSelected, setServiceSelected] = React.useState(false);

  React.useEffect(() => {
    async function fetchData() {
      setIsLoadingPaymentOrdersList(true);
      await getPaymentOrders(authUser.user.id).then((res) => {
        setPaymentOrdersList(res.data);
      });
      setIsLoadingPaymentOrdersList(true);
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    async function fetchData() {
      setIsLoadingClientOrdersList(true);
      await getClientOrders(authUser.user.id).then((res) => {
        setClientOrdersList(res.data);
      });
      setIsLoadingClientOrdersList(true);
    }
    fetchData();
  }, []);

  const handleSwitch = () => {
    if (projectSelected) {
      setProjectSelected(false);
    } else {
      setProjectSelected(true);
    }
    if (serviceSelected) {
      setServiceSelected(false);
    } else {
      setServiceSelected(true);
    }
  };

  const handleMore = (order) => {
    if (projectSelected) {
      setCurrentPaymentOrder(order);
    } else {
      setCurrentClientOrder(order);
    }

    setMoreDrawer(true);
  };

  return {
    isLoadingPaymentOrdersList,
    isLoadingClientOrdersList,
    authUser,
    paymentOrdersList,
    clientOrdersList,
    projectSelected,
    serviceSelected,
    handleSwitch,
    moreDrawer,
    setMoreDrawer,
    currentPaymentOrder,
    currentClientOrder,
    handleMore,
  };
};
