/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import './FreelancerProjectManagment.scss';
import { BaseLayout, CustomBreadcrumb, FileContainer, Text } from '@components/common';
import { FreelancerProjectManagmentViewModel } from './FreelancerProjectManagmentViewModel';

import RatingWindow from '@components/viewProject/RatingWindow';

import CalendarStagesContainer from 'pages/Managment/ProjectsCommon/CalendarStagesContainer';

import FreelancerProjectTabs from './FreelancerProjectTabs';
import {
  COMPLETED_PROJECTS_LABEL,
  FEEDBACK_PROJECT_LABEL,
} from 'common/constants/project.constants';
import { Redirect } from 'react-router-dom';

const FreelancerProjectManagment = () => {
  const {
    authUser,
    files,
    isLoadingFiles,
    isLoadingChat,
    isLoadingProject,
    currentProject,
    currentRequest,
    currentProposal,
    currentChat,
    ratingModal,
    setRatingModal,
    useImageUploadHandler,
    questionsList,
    handleReplyQuestion,
    isLoadingQuestions,
    handleGetFiles,
    setRefetchProject,
  } = FreelancerProjectManagmentViewModel();
  const isFinished =
    currentProject?.status === FEEDBACK_PROJECT_LABEL ||
    currentProject?.status === COMPLETED_PROJECTS_LABEL;

  if (authUser?.user?.id === currentProject?.owner_data?.id) {
    return <Redirect to={`/management/client/projects/${currentProject?.id}`} />;
  }
  return (
    <div>
      <CustomBreadcrumb type="freelancer-project" title={currentProject?.title} />
      <BaseLayout>
        <BaseLayout.LeftSide>
          {!isLoadingProject && currentChat && (
            <CalendarStagesContainer
              currentProject={currentProject}
              type="freelancer"
              setRefetchProject={setRefetchProject}
            />
          )}
        </BaseLayout.LeftSide>
        <BaseLayout.Content>
          <Fragment>
            {!isLoadingProject && (
              <RatingWindow
                project={currentProject}
                userRole={'freelancer'}
                onClose={() => setRatingModal(false)}
                show={ratingModal}
                isLoading={isLoadingProject}
              />
            )}

            <FreelancerProjectTabs
              currentProject={currentProject}
              handleGetFiles={handleGetFiles}
              authUser={authUser}
              isLoadingFiles={isLoadingFiles}
              files={files}
              questionsList={questionsList}
              handleReplyQuestion={handleReplyQuestion}
              isLoadingQuestions={isLoadingQuestions}
              currentProposal={currentProposal}
              currentRequest={currentRequest}
              isLoadingProject={isLoadingProject}
              setRatingModal={setRatingModal}
              isFinished={isFinished}
              setRefetchProject={setRefetchProject}
            />
          </Fragment>
        </BaseLayout.Content>
      </BaseLayout>
    </div>
  );
};

export default FreelancerProjectManagment;
