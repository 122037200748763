/* eslint-disable no-unused-vars */
import React from 'react';
import css from '../Forms.module.scss';
import FadeIn from 'react-fade-in';
import { Button, LoadingDots, Title } from 'components/common';
import RenderIf from 'components/common/RenderIf';
import QuestionPage from '../QuestionPage';
import { Progress } from 'antd';
import CMSFormViewModel from './CMSFormViewModel';

const CMSFormBlue = () => {
  const {
    answers,
    index,
    handleBack,
    handleNext,
    update,
    handleSetAnswer,
    isOptionSelected,
    questions,
    templates,
    setTemplates,
    palettes,
    handleSendForm,
    isLoadingQuestions,
    canGoNext,
    isPublishing,
    setSelectedCategory,
    isLoadingAnswers,
    isEdit,
    currentQuestionAnswer,
    loadingText,
  } = CMSFormViewModel();

  return (
    <section className={css.bg}>
      <div className={css.bg_color}>
        {isLoadingQuestions || isPublishing || isLoadingAnswers ? (
          <>
            {isPublishing && (
              <div style={{ textAlign: 'center', marginTop: 128 }}>
                <LoadingDots isSecondary height={100} />

                <Title color="white" size={24}>
                  {loadingText}
                </Title>
              </div>
            )}
          </>
        ) : (
          <>
            <div className={css.form}>
              <div className={css.container}>
                <div style={{ padding: '0em 2em 3em 2em', width: '100%' }}>
                  <Progress
                    percent={((index + 1) * 100) / questions?.length}
                    status="active"
                    showInfo={false}
                  />
                </div>
                <FadeIn key={update}>
                  <RenderIf isTrue={!isLoadingQuestions}>
                    <QuestionPage
                      question={questions[index]}
                      handleSetAnswer={handleSetAnswer}
                      isOptionSelected={isOptionSelected}
                      answer={currentQuestionAnswer}
                      templates={templates}
                      setTemplates={setTemplates}
                      palettes={palettes}
                      setSelectedCategory={setSelectedCategory}
                    />
                  </RenderIf>
                </FadeIn>
              </div>
            </div>
            <div style={{ maxWidth: 480, margin: '0 auto' }}>
              <div className={css.arrows}>
                <div>
                  {index > 0 && (
                    <Button
                      text="Volver atrás"
                      variant="secondary"
                      onClick={index > 0 ? handleBack : null}
                      className={index > 0 ? css.enabled : css.disabled}
                    />
                  )}
                </div>
                <Button
                  text={
                    index + 1 === questions?.length
                      ? isEdit
                        ? 'Editar proyecto'
                        : 'Publicar proyecto'
                      : 'Continuar'
                  }
                  color="primary"
                  onClick={
                    index + 1 === questions?.length
                      ? handleSendForm
                      : canGoNext()
                      ? handleNext
                      : null
                  }
                  className={canGoNext() ? css.enabled : css.disabled}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default CMSFormBlue;
