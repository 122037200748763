import React from 'react';
import './DropdownMenu.scss';
import { LoadingDots, Title } from '@components/common';
import { NO_HIRING_PROJECTS_ILUSTRATION } from '@common/constants/informativeIlustrations.constants';
import { DropdownItem } from '../DropdownItem';
import { useHistory } from 'react-router';
import { NOTIFICATIONS } from 'common/constants/routes.constants';

const DropdownMenu = ({
  notifications,
  isLoadingNotifications,
  handleNotificationRedirection,
  setOpenNotificationDropdown,
}) => {
  const history = useHistory();
  const handleGoToNotificationsConfigurations = () => {
    history.push(NOTIFICATIONS);
    setOpenNotificationDropdown(false);
  };
  return (
    <div className="dropdown-menu__container">
      <Title type="tertiary" color="primary">
        Notificaciones
      </Title>
      <hr />
      {!isLoadingNotifications ? (
        <div className="dropdown-menu__content">
          {notifications.length > 0 ? (
            notifications.map((notification, index) => {
              return (
                <DropdownItem
                  key={index}
                  notification={notification}
                  handleNotificationRedirection={handleNotificationRedirection}
                />
              );
            })
          ) : (
            <img src={NO_HIRING_PROJECTS_ILUSTRATION} />
          )}
        </div>
      ) : (
        <LoadingDots />
      )}
      <div className="dropdown-menu__show-more">
        <button onClick={() => handleGoToNotificationsConfigurations()}>Ver Más</button>
      </div>
    </div>
  );
};

export default DropdownMenu;
