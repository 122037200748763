import React, { useState, useEffect } from 'react';
import css from './RadioButton.module.scss';
export const RadioButton = (props) => {
  /*
    RADIO BUTTON ÚNICO SELECCIONADO
    Parámetros
        idSelected: id del radio button seleccionado
        itemId: id del item que representa el radio btn
    */
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (props.idSelected !== props.itemId) {
      setSelected(false);
    } else {
      setSelected(true);
    }
  }, [props.idSelected]);

  return (
    <div onClick={() => props.onSelect(props.itemId)} className="subcategory-container">
      <div className="category-status-container-shade" id="category-status-shade">
        <div className="category-status-container">
          <div className={`category-status-${selected}`} id={`category-status-${selected}`}></div>
        </div>
      </div>
      {props.children ? (
        <div className={css.container}>
          <div className={css.radio}>{selected && <div className={css.selected}></div>}</div>
          {props.children}
        </div>
      ) : null}
    </div>
  );
};
