import React, { useEffect, useState } from 'react';
import { Text } from 'components/common';
import css from '../Forms.module.scss';
import AddImage from 'components/common/AddImage';
import PreviewImage from 'pages/Managment/MyProfile/PreviousProjects/NewImage/PreviewImage';

const PaletteType = ({ handleSetAnswer, isOptionSelected, palettes, answer }) => {
  const [image, setImage] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const handleUpload = (e) => {
    setImage(e.target.files[0]);
    setShowPreview(true);
    handleSetAnswer({ value: 'custom', img: e.target.files[0] }, 'paletteCustom');
  };
  const handleDeletePreview = () => {
    setShowPreview(false);
    setImage(null);
    handleSetAnswer(null, 'paletteCustom', 'remove');
  };
  const handleSetCommonAnswer = (t) => {
    handleSetAnswer({ ...t, value: t.img }, 'palette');
    setImage(null);
    setShowPreview(false);
  };
  useEffect(() => {
    if (answer?.answers[0]?.value === 'custom') {
      setImage(answer?.answers[0]?.img);
      setShowPreview(true);
      setIsEdit(answer?.answers[0]?.edit);
    }
  }, [answer]);

  return (
    <>
      <div className={css.palettes_container}>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <div style={{ width: 240, height: 200 }}>
            {showPreview ? (
              <>
                {isEdit ? (
                  <PreviewImage
                    myPreview={image}
                    width={240}
                    height={200}
                    onDelete={handleDeletePreview}
                  />
                ) : (
                  <PreviewImage
                    file={image}
                    width={240}
                    height={200}
                    onDelete={handleDeletePreview}
                  />
                )}
              </>
            ) : (
              <label htmlFor="cms-templateimg" style={{ width: '100%', height: '100%' }}>
                <input
                  type="file"
                  accept="image/*"
                  id="cms-templateimg"
                  style={{ display: 'none' }}
                  onChange={handleUpload}
                />
                <AddImage
                  text="Agregar mi propia paleta de colores"
                  style={{ width: 240, height: '100%', borderRadius: 0 }}
                  iconSize={50}
                />
              </label>
            )}
          </div>
        </div>
        {palettes?.map((t) => (
          <div key={t.img} className={css.palette} onClick={() => handleSetCommonAnswer(t)}>
            <img
              src={t.img}
              alt="palette"
              style={
                isOptionSelected({ ...t, value: t.img })
                  ? { border: '2px solid #3383ff', opacity: 1 }
                  : {}
              }
            />
            <Text style={{ marginTop: 10 }}>{t?.name}</Text>
          </div>
        ))}
      </div>
    </>
  );
};

export default PaletteType;
