/* eslint-disable no-unused-vars */
import React from 'react';
import css from './Forms.module.scss';
import FadeIn from 'react-fade-in';

import { Progress } from 'antd';
import Publication from 'components/layouts/PublicationModal/Publication';
import { Text, Title } from 'components/common';
import { WhatsApp } from '@material-ui/icons';
import { CONTACT_PHONE } from 'common/constants/main.constants';

const SelectYourForm = () => {
  return (
    <section className={css.bg}>
      <div className={css.bg_color}>
        <div className={css.form}>
          <div className={css.container}>
            <div style={{ padding: '0em 2em 3em 2em', width: '100%' }}>
              <Progress percent={0} status="active" showInfo={false} />
            </div>
            <FadeIn className="w-100">
              <Title size={36} type="strongBold" color="primary">
                ¿Qué necesitas?
              </Title>
              <div style={{ maxWidth: 768, margin: '0 auto' }}>
                <Text size={14}>Selecciona la opción que más se acerque a lo que buscas</Text>
              </div>

              <Publication />
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Title type="primary" color="secondary">
                  ¿ No tienes claro lo que necesitas ?
                </Title>
                <div style={{ display: 'flex', gap: '0.2em' }}>
                  <Text>Te asesoramos</Text>
                  <Text type="strong">gratis</Text>
                </div>
                <div className="whatsapp__section">
                  <a
                    className="button__whatsapp"
                    href={`https://wa.me/${CONTACT_PHONE}`}
                    target="_blank"
                    rel="noreferrer">
                    <WhatsApp />
                    Escríbenos al WhatsApp
                  </a>
                </div>
              </div>
            </FadeIn>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SelectYourForm;
