import React, { Fragment } from 'react';
import { FileIcon } from '@components/common';
import { LoadingDots } from '@components/common';
import './ProjectFiles.scss';
import { NO_BANK_ACCOUNT_ILUSTRATION } from '@common/constants/informativeIlustrations.constants';

export function ProjectFiles({ userId, isLoadingFiles, ownerImage, freelanceImage, files }) {
  if (isLoadingFiles) {
    return (
      <Fragment>
        <div className="project-files">
          <LoadingDots files />
        </div>
      </Fragment>
    );
  } else {
    return (
      <div className="project-files">
        {files?.length > 0 ? (
          files.map((file, index) => {
            return (
              <FileIcon
                key={`file-${index}`}
                userImage={
                  userId ? (userId === file.owner ? ownerImage : freelanceImage) : file.owner
                }
                fileData={file}
              />
            );
          })
        ) : (
          <img className="project-files__no-files-img" src={NO_BANK_ACCOUNT_ILUSTRATION} />
        )}
      </div>
    );
  }
}
