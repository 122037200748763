import React, { Fragment } from 'react';
import { Bell } from 'react-feather';
import { DropdownMenu } from './DropdownMenu';
import { getUserNotifications, getNotificationsStatus } from '@actions/notifications';
import { useDispatch } from 'react-redux';
import css from './NotificationDropdown.module.scss';
import { useHistory } from 'react-router';
import {
  FREELANCER_REQUESTS_ROUTE,
  CLIENT_PUBLICATIONS_ROUTE,
  CLIENT_PROJECTS_ROUTE,
  FREELANCER_PROJECTS_ROUTE,
  FREELANCER_PUBLICATIONS_ROUTE,
  MYPROFILE,
} from '@common/constants/routes.constants';
import { Badge } from 'antd';

const NotificationDropdown = ({ auth }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [openNotificationDropdown, setOpenNotificationDropdown] = React.useState(false);
  const [unreadNotifications, setUnreadNotifications] = React.useState(0);
  const [isLoadingNotifications, setIsLoadingNotifications] = React.useState(false);

  const handleNotificationRedirection = (notification) => {
    setOpenNotificationDropdown(false);
    if (notification.redirection_class_type == 'publication') {
      history.push(CLIENT_PUBLICATIONS_ROUTE + `/${notification.redirection_class_id}`);
    } else if (notification.redirection_class_type == 'public-project') {
      if (notification.get_notification_text?.includes('hizo una pregunta en tu proyecto')) {
        history.push(
          CLIENT_PUBLICATIONS_ROUTE + `/${notification.redirection_class_id}?tab=Preguntas`
        );
      } else {
        history.push(`/project/${notification.redirection_class_id}/view`);
      }
    } else if (notification.redirection_class_type == 'request') {
      history.push(FREELANCER_REQUESTS_ROUTE + `/${notification.redirection_class_id}`);
    } else if (notification.redirection_class_type == 'project-client') {
      history.replace(CLIENT_PROJECTS_ROUTE + `/${notification.redirection_class_id}`);
    } else if (notification.redirection_class_type == 'project-freelancer') {
      history.replace(FREELANCER_PROJECTS_ROUTE + `/${notification.redirection_class_id}`);
    } else if (notification.redirection_class_type == 'service') {
      history.replace(FREELANCER_PUBLICATIONS_ROUTE + `/${notification.redirection_class_id}`);
    } else if (notification.redirection_class_type == 'user') {
      history.replace(MYPROFILE);
    } else if (notification.redirection_class_type == 'public-user') {
      history.push(`/freelance-profile/${notification.redirection_class_id}/view`);
    }
  };

  React.useEffect(() => {
    setIsLoadingNotifications(true);

    getNotificationsStatus()
      .then((res) => {
        setUnreadNotifications(res.data.n_notifications);
        setIsLoadingNotifications(false);
      })
      .catch(() => setIsLoadingNotifications(false));
  }, [openNotificationDropdown]);

  React.useEffect(() => {
    if (openNotificationDropdown) {
      dispatch(getUserNotifications(auth.user.id));
    }
  }, [openNotificationDropdown]);

  return (
    <Fragment>
      <div className={css.button}>
        <div onClick={() => setOpenNotificationDropdown(true)}>
          <Bell />
          {unreadNotifications > 0 && (
            <Badge
              count={unreadNotifications}
              style={{ position: 'absolute', right: -13, top: -5 }}
            />
          )}
        </div>
      </div>
      {openNotificationDropdown && (
        <div className={css.dropDown}>
          <div className={css.background} onClick={() => setOpenNotificationDropdown(false)}></div>{' '}
          <DropdownMenu
            notifications={auth.notifications}
            isLoadingNotifications={isLoadingNotifications}
            handleNotificationRedirection={handleNotificationRedirection}
            setOpenNotificationDropdown={setOpenNotificationDropdown}
          />
        </div>
      )}
    </Fragment>
  );
};

export default NotificationDropdown;
