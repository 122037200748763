import React from 'react';
import { getHomeSlider, getLatestBlog } from 'actions/blog';
import { getMainCategories } from 'actions/categories';
import { useLocalStorage } from 'hooks/useLocalStorage';

const WelcomeViewModel = () => {
  const [homeSlider, setHomeSlider] = React.useState([]);
  const [latestBlog, setLatestBlog] = React.useState(null);
  const [isLoadingSlider, setIsLoadingSlider] = React.useState(true);

  const [switchStatus, setSwitchStatus] = useLocalStorage(
    'switchStatus',
    window.localStorage.getItem('switchStatus')
      ? window.localStorage.getItem('switchStatus')
      : 'Trabajar'
  );
  const [mainCategories, setMainCategories] = useLocalStorage('mainCategories', []);
  const [loadingMainCategories, setLoadingMainCategories] = React.useState(true);

  const [currentFilters, setCurrentFilters] = useLocalStorage('currentFilters', []);

  const [orderBy, setOrderBy] = React.useState('most_recent');

  const [currentPage, setCurrentPage] = useLocalStorage('currentPage', 1);
  const [showPublicationModal, setShowPublicationModal] = React.useState(false);

  const handlePagination = (event) => {
    setCurrentPage(event);
    window.scrollTo(0, 0);
  };

  const orderByOptions = [
    { value: 'most_recent', text: 'Más recientes' },
    { value: 'least_recent', text: 'Menos recientes' },
    { value: 'featured', text: 'Destacados' },
  ];

  const handleOrderBy = (event) => {
    setOrderBy(event.target.value);
    setCurrentPage(1);
  };

  const handleSwitch = (value) => {
    setSwitchStatus(value);
    setCurrentFilters([]);
    window.scrollTo(0, 0);
    setCurrentPage(1);
  };

  const selectCategory = (category) => {
    const tempFilters = currentFilters.filter((e) => e.category !== category.id);

    if (tempFilters.find((e) => e.name === category.name)) {
      setCurrentFilters(tempFilters.filter((e) => e.name !== category.name));
    } else {
      setCurrentFilters([...tempFilters, category]);
    }
    setCurrentPage(1);
  };

  React.useEffect(() => {
    getHomeSlider()
      .then(({ data }) => {
        getLatestBlog()
          .then((res) => {
            setHomeSlider(data?.data);
            setLatestBlog(res?.data?.data[0]);
            setIsLoadingSlider(false);
          })
          .catch(() => {
            setIsLoadingSlider(false);
          });
      })
      .catch(() => {
        setIsLoadingSlider(false);
      });
  }, []);

  React.useEffect(() => {
    setLoadingMainCategories(true);
    getMainCategories()
      .then((res) => {
        setMainCategories(res.data);
        setLoadingMainCategories(false);
      })
      .catch(() => {
        setLoadingMainCategories(false);
      });
  }, []);

  return {
    homeSlider,
    latestBlog,
    isLoadingSlider,
    switchStatus,
    handleSwitch,
    mainCategories,
    loadingMainCategories,
    selectCategory,
    currentFilters,
    orderBy,
    orderByOptions,
    handleOrderBy,
    currentPage,
    handlePagination,
    showPublicationModal,
    setShowPublicationModal,
  };
};

export default WelcomeViewModel;
