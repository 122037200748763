export const PRIMARY_TEXT_COLOR = 'var(--primary-text-color)';

//status tag colors

export const PUBLISHED_PROJECT_COLOR = '#00D5A1';
export const PAYMENT_PROJECT_COLOR = '#33b1e5';
export const INPROGRESS_PROJECT_COLOR = '#3383FF';
export const FEEDBACK_PROJECT_COLOR = '#f8cd46';
export const COMPLETED_PROJECT_COLOR = '#3a9c35';
export const MEDIATION_PROJECT_COLOR = '#F88F22';
export const ACTIVE_REQUEST_COLOR = '#00D5A1';
export const PAYMENT_REQUEST_COLOR = '#33b1e5';
export const REJECTED_REQUEST_COLOR = '#D50613';
