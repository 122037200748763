import React from 'react';
import './ViewProjectDrawer.scss';
import { SkillsBeans, ProjectDetails, Text, Title } from '@components/common';
import { NO_INFORMATION_ILUSTRATION } from '@common/constants/informativeIlustrations.constants';
const ViewProject = ({ currentProject, withHeader = true }) => {
  return (
    <div className="view-project-drawer__container">
      {withHeader && <div className="view-project-drawer__blue-background"></div>}
      <div className="view-project-drawer__header">
        <Text type="boldDetail">Proyecto: </Text>
        <Title type="secondary-bold" color="primary">
          {currentProject?.title}
        </Title>
        <div className="view-project-drawer__categories">
          <Text type="strong">{currentProject?.project_category_name}</Text>
          <div className="view-project-drawer__subcategories">
            {currentProject?.project_subcategories_names.map((subcategory, index) => {
              return <Text key={index}>{`${subcategory}    `}</Text>;
            })}
          </div>
        </div>

        <SkillsBeans skills={currentProject?.skills_names} />
        <hr />
      </div>
      <div className="view-project-drawer__content">
        {' '}
        <div className="view-project-drawer__scroll">
          <ProjectDetails currentProject={currentProject} />

          <div className="view-project-drawer__questions">
            <Title type="tertiary" color="primary">
              {`Preguntas (${currentProject?.questions ? currentProject?.questions.length : 0}):`}{' '}
            </Title>
            {currentProject?.questions?.length == 0 ? (
              <img src={NO_INFORMATION_ILUSTRATION} />
            ) : (
              currentProject?.questions?.map((question, index) => {
                return (
                  <div className="view-project-drawer__question" key={index}>
                    <div className="view-project-drawer__question-header">
                      <img src={question.user_profile_picture} alt={'AltPhoto'} />
                      <Text type="strong">{question.question_text}</Text>
                    </div>
                    {question.answer_text && (
                      <div className="view-project-drawer__question-answer">
                        <img src={question.answer_profile_picture} alt={'AltPhoto'} />
                        <Text>{question.answer_text}</Text>
                      </div>
                    )}
                  </div>
                );
              })
            )}
            {}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProject;
