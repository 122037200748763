import React from 'react';
import { Upload } from 'react-feather';
import './FileUpload.scss';

function FileUpload({
  onUpload,
  isDisabled,
  fileTypes = '.png, .jpg, .jpeg, .doc, .xls, .pdf, .docx, .xlsx, .PNG',
}) {
  return (
    <div className="file__button-container">
      <div className="file__card">
        <div className="file__inputs">
          <input disabled={isDisabled} type="file" accept={fileTypes} onChange={onUpload} />
          <button>
            <i>
              <Upload />
            </i>
            Adjuntar Nuevo
          </button>
        </div>
      </div>
    </div>
  );
}

export default FileUpload;
