import React, { Component, Fragment } from 'react';
import Modal from 'react-modal';
import { Rate } from 'antd';
import { getUser } from '@actions/users';
import { createRating } from '@actions/ratings';
import { updateMyActiveProject } from '@actions/projects';
import { connect } from 'react-redux';
import { Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import '../../assets/styles/viewProject/RateWindow.scss';
import { Button } from '@components/common/Button';
import { Title } from '@components/common';

Modal.setAppElement('#root');

export class RatingWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      toRateUserData: null,
      communication: 0,
      clarity: 0,
      agreed_terms: 0,
      quality: 0,
      punctuality: 0,
    };
  }

  handleComunication = (value) => {
    this.setState({ communication: value });
  };
  handleClarity = (value) => {
    this.setState({ clarity: value });
  };
  handleAgreedTerms = (value) => {
    this.setState({ agreed_terms: value });
  };
  handleQuality = (value) => {
    this.setState({ quality: value });
  };
  handlePunctuality = (value) => {
    this.setState({ punctuality: value });
  };
  async componentDidMount() {
    if (!this.props.isLoading) {
      if (this.props.userRole == 'owner') {
        const user = await this.props.getUser(this.props.project?.collaborator_id);
        this.setState({ loading: false, toRateUserData: user });
      } else {
        const user = await this.props.getUser(this.props.project?.owner);
        this.setState({ loading: false, toRateUserData: user });
      }
    }
  }
  onSubmit = async () => {
    const comment = document.getElementById('window-rate-comment').value;
    var result;
    if (this.props.userRole == 'owner') {
      if (
        this.state.communication > 0 &&
        this.state.quality > 0 &&
        this.state.punctuality > 0 &&
        comment !== ''
      ) {
        //envio los rating de colaborador
        result = await this.props.createRating({
          rating_communication: this.state.communication,
          rating_quality: this.state.quality,
          comment: comment,
          rating_punctuality: this.state.punctuality,
          rating_type: 'client',
          project: this.props.project?.id,
        });
      } else {
        message.error('Debes completar todos los campos para enviar tu evaluación.');
      }
    } else {
      if (
        this.state.communication > 0 &&
        this.state.agreed_terms > 0 &&
        this.state.clarity > 0 &&
        comment !== ''
      ) {
        // envio rating de owner
        result = await this.props.createRating({
          rating_communication: this.state.communication,
          rating_agreed_terms: this.state.agreed_terms,
          comment: comment,
          rating_clarity: this.state.clarity,
          rating_type: 'collaborator',
          project: this.props.project?.id,
        });
      } else {
        message.error('Debes completar todos los campos para enviar tu evaluación.');
      }
    }

    if (result?.status_code) {
      if (result.status_code === 201) {
        message.success('Tu evaluación se creó correctamente.');
        //this.props.updateMyActiveProject(this.props.project?.id, this.state);
        this.props.onClose();
        window.location.reload();
      } else {
        message.error('Hubo un problema con tu evaluación.');
      }
    } else {
      message.error('Hubo un problema con tu evaluación.');
    }
  };

  render() {
    const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;
    return (
      <Modal
        isOpen={this.props.show}
        onRequestClose={this.props.onClose}
        shouldCloseOnOverlayClick={false}
        style={{
          overlay: {
            display: 'flex',
            justifyContent: 'center',
            opacity: 1,
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.5)',
          },
          content: {
            backgroundColor: '#fff',
            borderRadius: '0.8rem',
            margin: '48px 0 80px',
            height: '650px',
            maxWidth: '600px',
            minWidth: '300px',
            outline: 'none',
            padding: '20px',
            position: 'absolute',
            left: '50%',
            transform: 'translateX(-50%)',
          },
        }}>
        {!this.state.loading ? (
          <Fragment>
            {(this.props.userRole == 'freelancer' && !this.props.project?.collaborator_rating) ||
            (this.props.userRole == 'owner' && !this.props.project?.client_rating) ? (
              <div className="window-container">
                <div className={'close-btn-ctn'}>
                  <button className="close-btn" onClick={this.props.onClose}>
                    X
                  </button>
                </div>
                <div className="window-rate-user">
                  <div className="window-rate-user-description">
                    <Title size="32" type="secondary" color="primary">
                      {' '}
                      Evalúa a {this.state.toRateUserData?.first_name}{' '}
                    </Title>
                  </div>
                </div>
                <Title type="primary-nobold"> Su calidad de comunicación. </Title>
                <div className="rate-container">
                  <Rate id="window-rate-communication" onChange={this.handleComunication} />
                </div>
                {this.props.userRole == 'freelancer' ? (
                  <div className="window-rates-client">
                    <Title type="primary-nobold">
                      {' '}
                      Claridad de sus instrucciones e información.{' '}
                    </Title>
                    <div className="rate-container">
                      <Rate id="window-rate-clarity" onChange={this.handleClarity} />
                    </div>
                    <Title type="primary-nobold">
                      {' '}
                      Respeto de los términos acordados en un comienzo.{' '}
                    </Title>
                    <div className="rate-container">
                      <Rate id="window-rate-agreed-tems" onChange={this.handleAgreedTerms} />
                    </div>
                  </div>
                ) : (
                  <div className="window-rates-collab">
                    <Title type="primary-nobold"> Calidad del trabajo. </Title>
                    <div className="rate-container">
                      <Rate id="window-rate-quality" onChange={this.handleQuality} />
                    </div>
                    <Title type="primary-nobold">
                      {' '}
                      Puntualidad y disponibilidad para responder.{' '}
                    </Title>
                    <div className="rate-container">
                      <Rate id="window-rate-punctuality" onChange={this.handlePunctuality} />
                    </div>
                  </div>
                )}
                <Title type="primary-nobold">
                  {' '}
                  Deja un comentario general de su{' '}
                  {this.props.userRole == 'owner'
                    ? 'trabajo.'
                    : 'rol como dueño del proyecto.'}{' '}
                </Title>
                <textarea
                  cols="5"
                  rows="6"
                  className="basic-input"
                  id="window-rate-comment"
                  maxLength={200}></textarea>
                <div className="window-rate-button-container">
                  <Button text="Enviar evaluación" variant="primary" onClick={this.onSubmit} />
                </div>
              </div>
            ) : (
              <div className="window-container">
                <div className="window-rate-user">
                  <div className={'close-btn-ctn'}>
                    <button className="close-btn" onClick={this.props.onClose}>
                      X
                    </button>
                  </div>
                  <div className="window-rate-user-description-ready">
                    <Title> Gracias por tu evaluación </Title>
                  </div>
                </div>
              </div>
            )}
          </Fragment>
        ) : (
          <div className="loading-spin">
            <Spin tip="Cargando..." indicator={antIcon} />
          </div>
        )}
      </Modal>
    );
  }
}
const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  getUser,
  createRating,
  updateMyActiveProject,
})(RatingWindow);
