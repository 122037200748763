export function editProjectFromList(list, id, stateToUpdate) {
  const index = list.find((project) => project.id === id);
  const newList = list;
  newList[index] = { ...newList[index], ...stateToUpdate };
  return newList;
}

import validator from 'validator';

export function projectCurrentStep(status) {
  if (status === 'published' || status === 'created') {
    return 1;
  } else if (status === 'payment' || status === 'payment_validation' || status === 'accepted') {
    return 2;
  } else if (status === 'in_progress') {
    return 3;
  }
}

export function projectCurrentStage(stages) {
  let currentStageId = null;
  let currentOrder = 0;
  stages?.map((stage) => {
    if (stage.approved == false && currentStageId == null) {
      currentStageId = stage.id;
      currentOrder = stage.order;
    }
  });
  if (!currentStageId) {
    currentOrder = stages?.length + 1;
  }

  return { currentOrder, currentStageId };
}

export function validatePhoneNumber(number) {
  if (validator.isMobilePhone(number, 'any', { strictMode: true })) {
    return true;
  } else {
    return false;
  }
}

export function getProjectRoute({ projectId, requestId, status, isOwner }) {
  const clientRoutes = {
    published: `/management/client/publications/${projectId}`,
    payment: `/management/client/publications/${projectId}`,
    payment_validation: `/management/client/publications/${projectId}`,
    in_progress: `/management/client/projects/${projectId}`,
    mediation: `/management/client/projects/${projectId}`,
    feedback: `/management/client/projects/${projectId}`,
    completed: `/management/client/projects/${projectId}`,
  };

  const freelancerRoutes = {
    published: `/management/freelancer/requests/${requestId}`,
    payment: `/management/freelancer/requests/${requestId}`,
    payment_validation: `/management/freelancer/requests/${requestId}`,
    in_progress: `/management/freelancer/projects/${projectId}`,
    mediation: `/management/freelancer/projects/${projectId}`,
    feedback: `/management/freelancer/projects/${projectId}`,
    completed: `/management/freelancer/projects/${projectId}`,
  };

  if (isOwner) {
    return clientRoutes[status];
  }
  return freelancerRoutes[status];
}
