import axiosClient from '@config/axios';
import { tokenConfig } from './auth';
import { createMessage, returnErrors } from './messages';
import { GET_SKILLS, DELETE_SKILL, ADD_SKILL } from './types';

// GET Skills
export const getSkills = () => async (dispatch, getState) => {
  await axiosClient
    .get(`/skills/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SKILLS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};
// SEARCH SKILLS
export const searchSkills = (variable, fetchId, lastFetchId) => async (dispatch, getState) => {
  await axiosClient
    .get(`/skills/?search=${variable}`, tokenConfig(getState))
    .then((res) => {
      if (fetchId !== lastFetchId) {
        // for fetch callback order
        return;
      }
      dispatch({
        type: GET_SKILLS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};
export const getSkill = (skillId) => axiosClient.get(`/skills/${skillId}`);

export const searchSkills_v2 = ({ variable, categoryId }) => {
  if (categoryId && variable) {
    return axiosClient.get(`/skills/?category=${categoryId}&search=${variable}`);
  } else if (variable) {
    return axiosClient.get(`/skills/?search=${variable}`);
  } else if (categoryId) {
    return axiosClient.get(`/skills/?category=${categoryId}`);
  } else {
    return axiosClient.get(`/skills`);
  }
};

// DELETE Skill
export const deleteSkill = (id) => (dispatch, getState) => {
  axiosClient
    .delete(`/skills/${id}/`, tokenConfig(getState))
    .then(() => {
      dispatch(createMessage({ deleteSkill: 'Skill Deleted' }));
      dispatch({
        type: DELETE_SKILL,
        payload: id,
      });
    })
    .catch((err) => console.log(err));
};

// ADD Skill
export const addSkill = (skill) => (dispatch, getState) => {
  skill = {
    name: skill.name,
    category: skill.category,
    category_name: skill.category_name,
  };
  axiosClient
    .post(`/skills/`, skill, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ addSkill: 'Skill Added' }));
      dispatch({
        type: ADD_SKILL,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};
