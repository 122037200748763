import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated, isLoading } = useSelector((state) => state.auth);
  if (isLoading) {
    return '';
  } else if (!isAuthenticated) {
    return <Redirect to="/home" />;
  } else {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  }
};

export default PrivateRoute;
