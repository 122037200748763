import React from 'react';
import { Button } from '@components/common/Button';
import { UserMessage } from '@components/common';

export function formatter(value) {
  return `${value}%`;
}

export function redirectPost(url, data) {
  var form = document.createElement('form');
  document.body.appendChild(form);
  form.method = 'post';
  form.action = url;
  for (var name in data) {
    var input = document.createElement('input');
    input.type = 'hidden';
    input.name = name;
    input.value = data[name];
    form.appendChild(input);
  }
  form.submit();
}

export function calculate_progress(stages) {
  var completed = 0;
  stages.map((stage) => {
    return () => {
      if (stage.completed) {
        completed += 1;
      }
    };
  });
  return (completed / stages.length) * 100;
}

export function ReplyChat(props) {
  return (
    <form onSubmit={props.onFileUpload} className="chat-send-form">
      <textarea
        cols="40"
        required
        rows="2"
        type="text"
        name="content"
        className="basic-input chat-input-text"
        placeholder="Escribe y envía tus archivos aqui...."
        id="chat-release-input"></textarea>
      <div className="chat-actions-buttons">
        <Button text="Enviar" variant="secondary" type="submit" />
        <label htmlFor="chat-file-upload" className="chat-file-button">
          <i className="fas fa-paperclip chat-button-icon file-upload-icon" />
        </label>
      </div>
    </form>
  );
}

export function ReleaseMsgs(props) {
  /* Parametros
      - releaseMsgs: mensajes a ser mostrados
      - ownProject: indica si es tu propio proyecto
      - projectOwner: dueño del proyecto
      - projectColab: colaborador del proyecto
    */
  return (
    <div className="basic-container project-progress-ctn">
      <div className="chat-container">
        <div className="chat-messages-container">
          {props.releaseMsgs ? (
            props.releaseMsgs.length > 0 ? (
              props.releaseMsgs.map((msg, index) => {
                if (msg.owner === props.projectOwner.id) {
                  return (
                    <UserMessage
                      user={props.projectOwner}
                      content={msg.content}
                      createdAt={msg.created_at}
                      filesData={msg.files}
                      isOwner={props.ownProject}
                      key={`Rm-${index}`}
                    />
                  );
                } else {
                  return (
                    <UserMessage
                      user={props.projectColab}
                      content={msg.content}
                      createdAt={msg.created_at}
                      filesData={msg.files}
                      isOwner={!props.ownProject}
                      key={`Rm-${index}`}
                    />
                  );
                }
              })
            ) : (
              <p> No tienes ninguna entrega aún... </p>
            )
          ) : null}
        </div>
      </div>
    </div>
  );
}
