import React, { Fragment } from 'react';
import { Text } from '@components/common';

const DisplayList = ({ list }) => {
  const getText = (item) => {
    if (typeof item === 'string') {
      return item;
    } else if (item['label']) {
      return item['label'];
    } else if (item['name']) {
      return item['name'];
    }
  };
  return (
    <Fragment>
      {' '}
      {list?.map((item, index) => {
        return (
          <div key={`item-${index}`}>
            <Text type="detail">{`•${getText(item)}`}</Text>
          </div>
        );
      })}
    </Fragment>
  );
};

export default DisplayList;
