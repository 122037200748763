import React, { Fragment } from 'react';
import { MoneyFormatter, comisionOneDecimal } from '@common/utils/Formaters';
import './ViewRequestBreakdown.scss';
import { Text } from '@components/common';
import { Tooltip } from 'antd';
import { HelpCircle } from 'react-feather';

const TEXT_TOOLTIP =
  'Esta retención es obligatoria del Servicio de Impuestos Internos de Chile a las Boletas de Honorarios. En caso de requerir una factura, por favor contáctate con nosotros una vez aceptado tu presupuesto por el cliente.';

export function ViewRequestBreakdown({ currentProposal, isFreelance }) {
  const breakdown = isFreelance ? 'collaborator_breakdown' : 'client_breakdown';
  return (
    <Fragment>
      <div className="view-request-breakdown__header">
        {' '}
        <Text type="smallBoldBlue">Nombre</Text>
        <Text type="smallBoldBlue">Precio</Text>
        <Text type="smallBoldBlue">Duración</Text>
      </div>
      {currentProposal?.stages?.map((stage, index) => {
        return (
          <div className="view-request-breakdown__stage" key={index}>
            <Text type="smallDetail">{stage.name}</Text>
            <Text type="smallDetail">{MoneyFormatter(stage.price)}</Text>
            <Text type="smallDetail">{`${stage.stage_duration} días`}</Text>
          </div>
        );
      })}
      <hr />
      <div className="view-request-breakdown__row-show">
        <div className="view-request-breakdown__title-show">
          <Text type="smallDetail">Total presupuestado :</Text>
        </div>
        <div className="view-request-breakdown__price-show">
          <Text type="smallDetail">{MoneyFormatter(currentProposal?.total_price)} </Text>
        </div>
      </div>
      <div className="view-request-breakdown__row-show">
        <div className="view-request-breakdown__title-show">
          <Text type="smallDetail">
            {`Cargo por servicio (${comisionOneDecimal(
              currentProposal?.[breakdown]?.comission
            )}%) : `}{' '}
          </Text>
        </div>
        <div className="view-request-breakdown__price-show">
          <Text type="smallDetail">
            {MoneyFormatter(currentProposal?.[breakdown]?.comission_total)}
          </Text>
        </div>
      </div>
      <div className="view-request-breakdown__row-show">
        <div className="view-request-breakdown__title-show">
          {isFreelance && (
            <Tooltip title={TEXT_TOOLTIP} color={'#3383ff'}>
              <HelpCircle />
            </Tooltip>
          )}

          <Text type="smallDetail">
            {isFreelance
              ? `Retención Boleta Honorarios :  (${comisionOneDecimal(
                  currentProposal?.[breakdown]?.retention
                )}%): `
              : `IVA :  (${comisionOneDecimal(currentProposal?.[breakdown]?.iva)}%): `}
          </Text>
        </div>
        <div className="view-request-breakdown__price-show">
          <Text type="smallDetail">
            {isFreelance
              ? MoneyFormatter(currentProposal?.[breakdown]?.retention_total)
              : MoneyFormatter(currentProposal?.[breakdown]?.iva_total)}
          </Text>
        </div>
      </div>
      <div className="view-request-breakdown__row-show">
        <div className="view-request-breakdown__title-show">
          <Text type="smallDetail">{isFreelance ? `Vas a recibir :` : `Total a pagar`}</Text>
        </div>
        <div className="view-request-breakdown__price-show">
          <Text type="smallDetail">
            {MoneyFormatter(currentProposal?.[breakdown]?.total_payment)}
          </Text>
        </div>
      </div>
      <div className="view-request-breakdown__row-show">
        <div className="view-request-breakdown__title-show">
          <Text type="smallDetail">Duración total del proyecto: </Text>
        </div>
        <div className="view-request-breakdown__price-show">
          <Text type="smallDetail">{`${currentProposal?.total_duration} días`}</Text>
        </div>
      </div>
    </Fragment>
  );
}
