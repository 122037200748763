/* eslint-disable no-unused-vars */
import { Button, Text } from 'components/common';
import { Field, Formik, Form, ErrorMessage } from 'formik';
import { Input, Select, Spin } from 'antd';
import * as Yup from 'yup';
import css from '../PreviousProjects.module.scss';
import NewImage from '../NewImage/NewImage';
import React from 'react';
import StandardModal from 'components/common/StandardModal';
import useNewPrevProject from '../hooks/useNewPrevProject';
import { useEffect } from 'react';
import PreviewImage from '../NewImage/PreviewImage';

const NewPrevProjectModal = ({
  onCancel,
  visible,
  project,
  handleEdit,
  handleCreate,
  isLoading,
}) => {
  const {
    initialized,
    mySkills,
    handleSubmit,
    myPortada,
    myGalery,
    uploadGalery,
    skillsData,
    fetching,
    fetchSkills,
    setClearImages,
    onDeleteGalery,
    onDeletePortada,
    imagesError,
  } = useNewPrevProject({ project, handleEdit, handleCreate });

  if (!initialized) return <p>loading...</p>;

  const initialValues = {
    title: project?.title || '',
    description: project?.description || '',
    skills: project ? mySkills : [],
    portada: '',
    galerias: [],
    url: project?.url || '',
  };
  const validationSchema = Yup.object({
    title: Yup.string().required('El titulo es requerido'),
    description: Yup.string().required('La descripción es requerida'),
    skills: Yup.array()
      .required('Campo Requerido')
      .min(1, 'Debes seleccionar al menos una habilidad')
      .max(5, 'Debes seleccionar máximo 5 habilidades'),
  });
  const formFields = [
    { label: 'Título del proyecto', name: 'title', as: Input, placeholder: 'Título del proyecto' },
    { label: 'Descripción', name: 'description', as: Input.TextArea, placeholder: 'Descripción' },
    { label: 'Link del proyecto', name: 'url', as: Input, placeholder: 'Link del proyecto' },
  ];
  const editText = isLoading ? <Spin /> : 'Editar proyecto';
  const createText = isLoading ? <Spin /> : 'Agregar proyecto';
  return (
    <StandardModal
      width={800}
      title={project ? 'Editar proyecto' : 'Agregar proyecto'}
      onCancel={onCancel}
      visible={visible}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => handleSubmit(values, resetForm, setClearImages)}>
        {({ setFieldValue, values, handleSubmit }) => (
          <Form className={css.form}>
            {formFields.map((field, index) => (
              <div key={index}>
                <Text>{field.label}</Text>
                <Field
                  name={field.name}
                  placeholder={field.placeholder}
                  as={field.as}
                  style={{ borderRadius: '1em' }}
                />
                <span style={{ color: 'red' }}>
                  <ErrorMessage name={field.name} />
                </span>
              </div>
            ))}
            <div>
              <Text>Habilidades utilizadas</Text>
              <Field
                name="skills"
                as={Select}
                mode="multiple"
                labelInValue
                placeholder=""
                notFoundContent={fetching ? <Spin size="small" /> : null}
                filterOption={false}
                onFocus={fetchSkills}
                onSearch={fetchSkills}
                onChange={(e) => setFieldValue('skills', e)}
                style={{ width: '100%' }}>
                {skillsData?.map((skill) => (
                  <Select.Option value={skill.id} key={skill.id}>
                    {skill.name}
                  </Select.Option>
                ))}
              </Field>
              <span style={{ color: 'red' }}>
                <ErrorMessage name="skills" />
              </span>
            </div>
            {imagesError && (
              <span style={{ color: 'red' }}>
                Debes escoger al menos una imagen de portada y de galería.
              </span>
            )}
            <div className={css.formImages}>
              {myPortada ? (
                <PreviewImage
                  myPreview={myPortada.upload}
                  onDelete={() => onDeletePortada(setFieldValue)}
                />
              ) : values.portada ? (
                <PreviewImage
                  file={values.portada}
                  onDelete={() => onDeletePortada(setFieldValue)}
                />
              ) : (
                <NewImage
                  id="portada_file_create"
                  text="Imagen de portada"
                  onUpload={(e) => setFieldValue('portada', e.target.files[0])}
                />
              )}

              {myGalery?.map((image, i) => (
                <PreviewImage
                  key={i}
                  myPreview={image.upload}
                  onDelete={() => onDeleteGalery(i, setFieldValue, values, 'myGalery')}
                />
              ))}
              {values?.galerias?.map((image, i) => (
                <PreviewImage
                  key={i}
                  file={image}
                  onDelete={() => onDeleteGalery(i, setFieldValue, values)}
                />
              ))}
              {values?.galerias?.length + myGalery?.length < 5 && (
                <NewImage
                  id="galeria_file_create"
                  text="Imagen de galería"
                  onUpload={(e) => uploadGalery(e, setFieldValue, values)}
                />
              )}
            </div>
            <div style={{ textAlign: 'center' }}>
              <Button text={project ? editText : createText} disabled={isLoading} />
            </div>
          </Form>
        )}
      </Formik>
    </StandardModal>
  );
};

export default NewPrevProjectModal;
