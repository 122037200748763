import React, { Component } from 'react';
import { Form, Input, message } from 'antd';
import { changePasswordRecovery, resetPassword } from '@actions/auth';
import { connect } from 'react-redux';
import { validateEmail } from '../RegisterConfirmation/RegisterConfirmation';
import queryString from 'query-string';
import { BaseFormLayout, Button, Text, Title } from '@components/common';
import './RecoverPassword.scss';

export class RecoverPassword extends Component {
  onSubmit = async (e) => {
    console.log(e);
    const newUser = {
      password_token: e.token,
      new_password: e.password,
    };
    await this.props.changePasswordRecovery(newUser);
  };

  reSendToken = () => {
    const value = document.getElementById('recover-password-resend').value;
    if (validateEmail(value)) {
      this.props.resetPassword(value);
    } else {
      message.error('Ingresa un email válido!');
    }
  };

  render() {
    return (
      <BaseFormLayout>
        <Form
          onFinish={this.onSubmit}
          initialValues={{ token: queryString.parse(this.props.location.search).token }}>
          <Title size="22.9" type="accountTitle" color="primary">
            Recuperar contraseña
          </Title>
          <Text type="bigStrong">
            Para confirmar tu cuenta ingresa el código adjuntado en el email.
          </Text>

          <div className="form-group-login">
            <Form.Item name="token">
              <Input id="recover-password-token-input" />
            </Form.Item>
          </div>
          <Text type="bigStrong">Ingresa tu nueva contraseña</Text>
          <div className="form-group-login">
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa tu contraseña!',
                },
              ]}
              hasFeedback>
              <Input.Password />
            </Form.Item>
          </div>
          <Text type="bigStrong">Confirma la contraseña</Text>
          <div className="form-group-login">
            <Form.Item
              name="confirm"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Por favor confirma tu contraseña!',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject('Las dos contraseñas que ingresaste no coinciden!');
                  },
                }),
              ]}>
              <Input.Password />
            </Form.Item>
          </div>
          <div className="button-confirm">
            <Form.Item>
              <Button text="Confirmar" variant="secondary" color="primary" type="submit" />
            </Form.Item>
          </div>
        </Form>
        <Text type="bigDetail">
          Si no lo recibes puedes re enviarlo ingresando el correo en el siguiente campo
        </Text>
        <div className="registration-resend-container">
          <input
            className="basic-input registration-resend-input"
            id={'recover-password-resend'}
            placeholder="¡Ingresa tu correo!"
          />
        </div>
        <div className="button-confirm">
          <Button text="Enviar" variant="secondary" color="primary" />
        </div>
      </BaseFormLayout>
    );
  }
}

export default connect(() => {}, { changePasswordRecovery, resetPassword })(RecoverPassword);
