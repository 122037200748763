import { combineReducers } from 'redux';
import projects from './projects';
import errors from './errors';
import messages from './messages';
import auth from './auth';
import users from './users';
import skills from './skills';
import navigation from './navigation';
import userAttributes from './userAttributes';
import services from './services';
import requests from './requests';
import chat from './chat';
import admin from './admin';
import filters from './filters';
import sideWindows from './sideWindows';

export default combineReducers({
  projects,
  services,
  errors,
  messages,
  auth,
  users,
  skills,
  navigation,
  userAttributes,
  requests,
  chat,
  admin,
  filters,
  sideWindows,
});
