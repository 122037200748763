import { AuthButtonsContext } from 'context/AuthButtonsContext';
import { useContext } from 'react';

const useAuthButtons = () => {
  const context = useContext(AuthButtonsContext);
  if (!context) {
    throw new Error('useAuthButtons must be used within a AuthButtonsProvider');
  }

  return context;
};

export default useAuthButtons;
