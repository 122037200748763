import React from 'react';
import { Form, Input, Select } from 'antd';
import { createMessage } from 'actions/messages';
import { useDispatch, useSelector } from 'react-redux';
import StandardModal from 'components/common/StandardModal';
import { Button, Text } from 'components/common';
import { sendMediation } from 'actions/projects';
import { selectAuthUserInfo } from 'reducers/auth';
import { MoneyFormatter } from 'common/utils/Formaters';

const percentagesArray = Array.from({ length: 21 }, (_, i) => i * 5);

const MediationModal = ({
  owner,
  project,
  toggle,
  setToggle,
  remainingAmount,
  setRefetchProject,
}) => {
  const user = useSelector(selectAuthUserInfo);
  const [mediationText, setMediationText] = React.useState('');
  const [percentage, setPercentage] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();

  const isOwner = user?.id === project?.owner;

  const handleEnviarMediacion = () => {
    setIsLoading(true);
    sendMediation({ owner, project: project?.id, reason: mediationText, percentage })
      .then(() => {
        setMediationText('');
        setToggle(false);
        dispatch(
          createMessage({
            successMessage:
              'La mediación fué enviada correctamente, nos pondremos en contacto contigo cuanto antes.',
          })
        );
        setRefetchProject((prev) => !prev);
      })
      .catch(() => {
        dispatch(
          createMessage({
            errorMessage: 'Hubo un error al enviar la mediación, por favor, vuelve a intentarlo.',
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <StandardModal
      visible={toggle}
      onCancel={() => setToggle(false)}
      title="Solicitar mediación"
      button={
        <Button
          text="Enviar"
          style={{ marginTop: 20, width: 300 }}
          onClick={handleEnviarMediacion}
          isLoading={isLoading}
        />
      }>
      <Input.TextArea
        placeholder="Escribe aquí las razones para la mediación..."
        rows={10}
        value={mediationText}
        maxLength={500}
        showCount
        onChange={(e) => setMediationText(e.target.value)}
      />
      <Form.Item>
        <Text>
          {isOwner
            ? '¿Qué porcentaje del saldo no liberado al programador, crees que debe recibir?'
            : '¿Qué porcentaje del saldo no liberado, crees que debes recibir?'}
        </Text>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Text type="detail">Monto no liberado: {MoneyFormatter(remainingAmount)}</Text>
          <Text type="detail">
            Monto a liberar: {MoneyFormatter((remainingAmount * percentage) / 100)}
          </Text>
        </div>

        <Select value={percentage} onChange={(e) => setPercentage(e)}>
          {percentagesArray?.map((percentage) => (
            <Select.Option value={percentage} key={percentage}>
              {percentage}%
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </StandardModal>
  );
};

export default MediationModal;
