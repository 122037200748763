import React from 'react';
import { BaseLayout, Button } from '@components/common';
import { useParams } from 'react-router-dom';
import css from './NotAllowed.module.css';

import {
  ERROR_401_ILUSTRATION,
  ERROR_403_ILUSTRATION,
  ERROR_404_ILUSTRATION,
} from '@common/constants/errorIlustrations.constants';

const NotAllowedPage = () => {
  const { error } = useParams();

  const errors = {
    401: ERROR_401_ILUSTRATION,
    403: ERROR_403_ILUSTRATION,
    404: ERROR_404_ILUSTRATION,
  };

  return (
    <BaseLayout>
      <BaseLayout.Content>
        <div className={css.innerContainer}>
          <img src={errors[error]} className={css.image} />

          <Button text="Volver al Home" variant="primary" href="#/home" className={css.button} />
        </div>
      </BaseLayout.Content>
    </BaseLayout>
  );
};

export default NotAllowedPage;
