import React from 'react';
import css from './TimeLine.module.scss';
import { Timeline } from 'antd';
import { questionTitle } from 'common/constants/publicationForm.constants';
import { Text, Title } from '@components/common';

const TimeLine = ({ values, handleCurrentFocus, questionOrder, displayedQuestions }) => {
  const getColor = (name) => {
    if (displayedQuestions.includes(name)) {
      if (!values[name]) {
        return ['primaryColor', 'blue'];
      } else {
        return ['secondaryColor', 'rgb(0, 213, 161)'];
      }
    } else {
      return ['', 'grey'];
    }
  };

  const onClick = (name) => {
    if (displayedQuestions.includes(name)) {
      handleCurrentFocus(name);
    }
  };
  return (
    <div className={css.container}>
      <div className={css.header}>
        <Title type="secondary" color="primary">
          Publica tu proyecto
        </Title>
        <hr />
      </div>
      <div className={css.content}>
        {' '}
        <Timeline>
          {questionOrder?.map((name, index) => {
            return (
              <Timeline.Item
                className={css.item}
                key={`question-${index}`}
                onClick={() => onClick(name)}
                color={getColor(name)[1]}>
                <Text className={`tn-text tn-text--regular ${getColor(name)[0]}`}>
                  {questionTitle[name]}
                </Text>
              </Timeline.Item>
            );
          })}
        </Timeline>
      </div>
    </div>
  );
};

export default TimeLine;
