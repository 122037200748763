import React, { Component } from 'react';
import queryString from 'query-string';
import { registerConfirmation, registerSendConfirmation } from '@actions/auth';
import { message } from 'antd';
import { connect } from 'react-redux';
import { BaseFormLayout, Text, Title } from '@components/common';
import { MYPROFILE } from '@common/constants/routes.constants';
import './RegisterConfirmation.scss';

export function validateEmail(email) {
  /* eslint-disable */
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
  /* eslint-enable */
}

export class RegisterConfirmation extends Component {
  componentDidMount() {
    const values = queryString.parse(this.props.location?.search);
    if (values.token) {
      this.props.registerConfirmation({ register_token: values.token });
    }
  }

  handleConfirm = () => {
    var inputText = document.getElementById('register-confirmation-input');
    this.props.registerConfirmation({ register_token: inputText.value });
    if (this.props.onConfirm) {
      this.props.onConfirm();
    } else {
      this.props.history.push(MYPROFILE);
    }
  };

  handleResend = () => {
    var inputText = document.getElementById('register-confirmation-resend').value;
    if (validateEmail(inputText)) {
      this.props.registerSendConfirmation(inputText);
    } else {
      message.error('Por favor ingresa un email válido');
    }
  };

  render() {
    return (
      <>
        {this.props.disableLayout ? (
          <>
            <Title size="23" type="accountTitle" color="primary">
              Confirma tu registro
            </Title>
            <Text type="big" justify>
              Te enviamos un email al correo que pusiste en tu registro, deberías recibirlo pronto.
            </Text>
            <Text type="big">
              Para confirmar tu cuenta ingresa el código adjuntado en el email.
            </Text>
            <div className="registration-confirmation-input-container">
              <input
                className="basic-input registration-confirmation-input"
                placeholder="Ingresa el código"
                id="register-confirmation-input"></input>
              <button className="button-login-form" onClick={this.handleConfirm}>
                Confirmar
              </button>
            </div>
            <Text type="bigDetail" justify>
              Si no lo recibes, puedes reenviarlo ingresando tu correo en el siguiente campo.
            </Text>
            <div className="registration-resend-container">
              <input
                className="basic-input registration-resend-input"
                id="register-confirmation-resend"
                placeholder="Ingresa tu correo"
              />
              <button className="registration-resend-button" onClick={this.handleResend}>
                Reenviar
              </button>
            </div>
          </>
        ) : (
          <BaseFormLayout>
            <Title size="23" type="accountTitle" color="primary">
              Confirma tu registro
            </Title>
            <Text type="big" justify>
              Te enviamos un email al correo que pusiste en tu registro, deberías recibirlo pronto.
            </Text>
            <Text type="big">
              Para confirmar tu cuenta ingresa el código adjuntado en el email.
            </Text>
            <div className="registration-confirmation-input-container">
              <input
                className="basic-input registration-confirmation-input"
                placeholder="Ingresa el código"
                id="register-confirmation-input"></input>
              <button className="button-login-form" onClick={this.handleConfirm}>
                Confirmar
              </button>
            </div>
            <Text type="bigDetail" justify>
              Si no lo recibes, puedes reenviarlo ingresando tu correo en el siguiente campo.
            </Text>
            <div className="registration-resend-container">
              <input
                className="basic-input registration-resend-input"
                id="register-confirmation-resend"
                placeholder="Ingresa tu correo"
              />
              <button className="registration-resend-button" onClick={this.handleResend}>
                Reenviar
              </button>
            </div>
          </BaseFormLayout>
        )}
      </>
    );
  }
}

export default connect(() => {}, { registerConfirmation, registerSendConfirmation })(
  RegisterConfirmation
);
