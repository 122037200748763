import React from 'react';
import css from './ModificationType.module.scss';
import { Text } from '@components/common';
import {
  modificationsIlustrationsDictionary,
  ilustrationsFolder,
} from '../../IntegrationsDetails.constants';

function ModificationType({ currentProject }) {
  return (
    <div className={css.container}>
      <Text type="detail">Modificaciones o mejoras: </Text>
      <div className={css.content}>
        {' '}
        <div className={css.modificationType}>
          <div className={css.bean}>
            {' '}
            <Text type="strong" strong>
              {currentProject?.modification_type}
            </Text>
          </div>
        </div>
        <div className={css.modifications}>
          {currentProject?.modifications?.map((mod, index) => {
            return (
              <div className={css.item} key={`mod-${index}`}>
                <img
                  src={
                    modificationsIlustrationsDictionary[mod]
                      ? ilustrationsFolder + modificationsIlustrationsDictionary[mod]
                      : ilustrationsFolder + 'dreamer_e856445593.svg'
                  }
                />
                <Text type="smallDetail">{mod} </Text>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ModificationType;
