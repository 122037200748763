export function updateFilters(filter, currentFilters) {
  const found = currentFilters.find((element) => element.id === filter.id);
  if (found) {
    let updatedFilters = currentFilters.filter((element) => element.id !== filter.id);
    if (filter.main_category) {
      updatedFilters = updatedFilters.filter((element) => element.category !== filter.id);
    }
    return updatedFilters;
  }
  return [...currentFilters, filter];
}

export function handleCategoriesSubmit(categorieslist) {
  let consulta = '';
  if (!categorieslist) {
    return consulta;
  }
  categorieslist.map((category) => {
    if (category.main_category) {
      consulta += `&category=${category.id}`;
    } else {
      consulta += `&sub-category=${category.id}`;
    }
  });
  return consulta;
}

export function handleSkillsFilters(filterslist) {
  let consulta = '';
  if (filterslist.length === 0) {
    return consulta;
  }
  filterslist.map((skill) => {
    {
      consulta += `&skill=${skill.value}`;
    }
  });
  return consulta;
}
