import { getUser_v2 } from 'actions/users';
import React from 'react';

const usePublicationQuestions = ({ type }) => {
  const getColor = () => {
    if (type == 'project') {
      return 'primary';
    } else if (type == 'service') {
      return 'secondary';
    } else {
      return '';
    }
  };
  const [currentSelectedUser, setCurrentSelectedUser] = React.useState(null);
  const [viewProfileDrawer, setViewProfileDrawer] = React.useState(false);
  const handleDrawer = (user) => {
    getUser_v2(user).then((res) => {
      setCurrentSelectedUser(res.data);
      setViewProfileDrawer(true);
    });
  };

  return { getColor, currentSelectedUser, viewProfileDrawer, setViewProfileDrawer, handleDrawer };
};

export default usePublicationQuestions;
