/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';

const useRequest = ({ authUser }) => {
  const TOTAL_STEP = 3;
  const [current, setCurrent] = useState(1);
  const nextStep = () => {
    if (current < TOTAL_STEP) {
      setCurrent((prev) => prev + 1);
    }
  };
  const isVerified = authUser?.user?.is_identity_verified;

  const hasGithub =
    authUser?.user?.github_link && authUser?.user?.github_link !== 'https://github.com/';
  const hasBriefcase =
    authUser?.user?.briefcase_link &&
    authUser?.user?.briefcase_link !== 'https://www.mi-portafolio.com/';
  const hasStack =
    authUser?.user?.stackoverflow_link &&
    authUser?.user?.stackoverflow_link !== 'https://stackoverflow.com/';
  const hasLink = hasGithub || hasBriefcase || hasStack;
  const hasDescriptions = authUser?.user?.about_me && authUser?.user?.experience;
  const hasSkills = authUser?.user?.skills.length > 0;
  const isProfileComplete = hasLink && hasDescriptions && hasSkills;
  const hasPreviousProject = authUser?.user?.n_briefcases > 0;
  const canGoNext = [isVerified, isProfileComplete, hasPreviousProject];

  useEffect(() => {
    if (isVerified && isProfileComplete && hasPreviousProject) {
      setCurrent(3);
    } else if (isVerified && isProfileComplete) {
      setCurrent(2);
    } else if (isVerified) {
      setCurrent(1);
    } else {
      setCurrent(0);
    }
  }, []);

  return {
    current,
    setCurrent,
    nextStep,
    canGoNext,
    hasDescriptions,
    hasLink,
    hasSkills,
    TOTAL_STEP,
  };
};

export default useRequest;
