import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthUser } from '@reducers/auth';
import { editUser_v2, editUser, getUserQuestions } from '@actions/users';
import { EDIT_USER } from '@actions/types';
import { searchSkills_v2 } from '@actions/skills';
import { createMessage } from 'actions/messages';
import {
  REGEX_SOCIAL_MEDIA,
  REGEX_URL,
  SUCCESS_MESSAGE_EDIT_SOCIAL_MEDIA,
  ERROR_API_EDIT_SOCIAL_MEDIA,
  SUCCESS_MESSAGE_DELETE_SOCIAL_MEDIA,
} from './MyProfile.contants';
import { replyQuestion } from 'actions/projects';

export const MyProfileViewModel = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(selectAuthUser);

  const [profileImageModal, setProfileImageModal] = React.useState(false);
  const [socialMediaModal, setSocialMediaModal] = React.useState(false);
  const [socialMediaType, setSocialMediaType] = React.useState('github');

  const [editGenaralInformation, setEditGenaralInformation] = React.useState(false);

  const [userDrawer, setUserDrawer] = React.useState(false);

  const [aboutMeEditing, setAboutMeEditing] = React.useState(false);
  const [experienceEditing, setExperienceEditing] = React.useState(false);
  const [skillsEditing, setSkillsEditing] = React.useState(false);

  const [skillsData, setSkillsData] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);
  const [lastFetchId, setLastFetchId] = React.useState(0);

  const [questionsList, setQuestionsList] = React.useState([]);

  const [verifyIdentityModal, setVerifyIdentityModal] = React.useState(false);

  const useEditUser = (data) => dispatch(editUser(authUser.user.id, data));

  React.useEffect(() => {
    if (authUser) {
      getUserQuestions(authUser.user.id)
        .then((res) => {
          setQuestionsList(res.data.objects);
        })
        .catch(() => {
          setQuestionsList([]);
        });
    }
  }, []);

  const onSubmitGeneralInformation = async (values) => {
    editUser_v2(authUser.user.id, values).then((res) => {
      dispatch({
        type: EDIT_USER,
        payload: res.data,
      });
      setEditGenaralInformation(false);
    });
  };

  const onSubmitSocialMedia = async (values) => {
    const key = Object.keys(values)[0];
    const text = Object.values(values)[0];
    const regex = REGEX_SOCIAL_MEDIA[key]['regex'];
    const successMessage =
      text == '' ? SUCCESS_MESSAGE_DELETE_SOCIAL_MEDIA : SUCCESS_MESSAGE_EDIT_SOCIAL_MEDIA;

    if (text == '' || (text.match(regex) && text.match(REGEX_URL))) {
      editUser_v2(authUser.user.id, values)
        .then((res) => {
          dispatch({
            type: EDIT_USER,
            payload: res.data,
          });
          setSocialMediaModal(false);
          dispatch(createMessage({ successMessage: successMessage }));
        })
        .catch(() => dispatch(createMessage({ errorMessage: ERROR_API_EDIT_SOCIAL_MEDIA })));
    } else {
      dispatch(createMessage({ errorMessage: REGEX_SOCIAL_MEDIA[key]['error'] }));
    }
  };

  const handleReplyQuestion = (id, answerText) => {
    replyQuestion(id, answerText)
      .then((res) => {
        setQuestionsList([...questionsList.filter((q) => q.id != id), res.data.request]);
      })
      .catch(() => {});
  };

  const handleEditSocialMedia = (type) => {
    setSocialMediaModal(true);
    setSocialMediaType(type);
  };

  const loadSkillsProfile = (listid, listname) => {
    const result = [];
    listid?.map((id, index) => {
      return result.push({ value: id, label: listname[index], key: id });
    });
    return result;
  };
  const [skillsSelected, setSkillsSelected] = React.useState(
    loadSkillsProfile(authUser?.user?.skills, authUser?.user?.skills_names)
  );
  const handleChangeSkills = (value) => {
    setSkillsSelected(value);
    setSkillsData([]);
    setFetching(false);
  };

  const handleSave = (section) => {
    if (section === 'experience') {
      const value = document.getElementById('experiencia__input').value;
      useEditUser({
        experience: value,
      });
      setExperienceEditing(false);
    } else if (section === 'skills') {
      const skillsupdate = [];
      skillsSelected.map((skill) => {
        skillsupdate.push(skill.value);
      });
      useEditUser({
        skills: skillsupdate,
      });

      setSkillsEditing(false);
    } else if (section === 'aboutMe') {
      const value = document.getElementById('about-me__input').value;
      useEditUser({
        about_me: value,
      });
      setAboutMeEditing(false);
    }
  };

  const fetchSkills = async (value) => {
    setLastFetchId(lastFetchId + 1);
    const fetchId = lastFetchId;
    setSkillsData([]);
    setFetching(true);
    await searchSkills_v2({ variable: value }).then((res) => {
      if (fetchId !== lastFetchId) {
        // for fetch callback order
        return;
      }
      setSkillsData(res.data);
      setFetching(false);
    });
  };

  return {
    authUser,
    profileImageModal,
    setProfileImageModal,
    editGenaralInformation,
    setEditGenaralInformation,
    onSubmitGeneralInformation,
    onSubmitSocialMedia,
    socialMediaModal,
    setSocialMediaModal,
    socialMediaType,
    setSocialMediaType,
    handleEditSocialMedia,
    aboutMeEditing,
    experienceEditing,
    skillsEditing,
    setAboutMeEditing,
    setExperienceEditing,
    setSkillsEditing,
    handleSave,
    skillsSelected,
    skillsData,
    fetching,
    fetchSkills,
    handleChangeSkills,
    verifyIdentityModal,
    setVerifyIdentityModal,
    userDrawer,
    setUserDrawer,
    useEditUser,
    handleReplyQuestion,
    questionsList,
  };
};
