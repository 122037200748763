/* eslint-disable no-unused-vars */
import {
  getAllPalettes,
  getCMSForm,
  sendAskToFormTracker,
  uploadPaletteAndTemplates,
} from 'actions/forms';
import {
  uploadCMSProject,
  editProject_v2,
  sendProjectPromptClient,
  sendProjectPromptFreelance,
} from 'actions/projects';
import { CLIENT_PUBLICATIONS_ROUTE } from 'common/constants/routes.constants';
import useAuthButtons from 'hooks/useAuthButtons';
import useNewForms from 'pages/CMSForm/hooks/useNewForms';
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const CMSFormViewModel = () => {
  const history = useHistory();
  const [allQuestions, setAllQuestions] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [palettes, setPalettes] = useState([]);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);

  const { setShowAuthModal } = useAuthButtons();
  const [loadingText, setLoadingText] = useState('Publicando proyecto...');

  const {
    handleSetAnswer,
    handleNext,
    handleBack,
    canGoNext,
    isOptionSelected,
    hasOptionSelected,
    update,
    index,
    answers,
    isEdit,
    formTrackerId,
    user,
    selectedCategory,
    setSelectedCategory,
    id,
    palletesAdapterFromApiToUi,
    formAdapterFromApiToUi,
    isLoadingAnswers,
    currentQuestionAnswer,
    answersReturns,
    parseAnswersToPromptForFreelancers,
  } = useNewForms({
    questions,
    isLoadingQuestions,
    allQuestions,
    setQuestions,
    type: 'cms',
  });

  useEffect(() => {
    setQuestions(
      allQuestions.map((q) => ({
        ...q,
        options: q.options.filter((o) => o.categories?.includes(selectedCategory)),
      }))
    );
  }, [selectedCategory]);

  useEffect(() => {
    setIsLoadingQuestions(true);
    getCMSForm()
      .then((res) => {
        setAllQuestions(formAdapterFromApiToUi(res));
        setQuestions(formAdapterFromApiToUi(res));
      })
      .then(() => {
        getAllPalettes().then((res) => {
          setPalettes(palletesAdapterFromApiToUi(res));
        });
      })
      .finally(() => {
        setIsLoadingQuestions(false);
      });
  }, []);

  const formAdapterFromUiToApi = async (answers) => {
    let request = [];
    let is_uploaded_template = false;
    let is_uploaded_pallette = false;
    for (const answer of answers) {
      if (answer?.type === 'templateCustom' || answer?.type === 'paletteCustom') {
        if (!answer?.answers?.[0]?.edit) {
          const url = await getUrlFromCustomImages(answer);
          answer.answers[0].img = url;
        }
        if (answer?.type === 'templateCustom') {
          is_uploaded_template = true;
        }
        if (answer?.type === 'paletteCustom') {
          is_uploaded_pallette = true;
        }
      }
      request[answer?.key] = answersReturns(answer);
    }

    return { ...request, is_uploaded_template, is_uploaded_pallette };
  };
  const getUrlFromCustomImages = async (answer) => {
    const type = answer?.type === 'templateCustom' ? 'template_file' : 'pallette_file';
    const { data } = await uploadPaletteAndTemplates({
      file: answer?.answers?.[0]?.img,
      type,
      owner: user?.id,
    });
    return data?.data?.upload;
  };
  const handleSendForm = async () => {
    setIsPublishing(true);
    if (isEdit) {
      const request = await formAdapterFromUiToApi(answers);
      await editProject_v2(id, { ...request });
      history.push(`${CLIENT_PUBLICATIONS_ROUTE}/${id}`);
    } else {
      if (user) {
        const request = await formAdapterFromUiToApi(answers);
        const ask = {
          form: formTrackerId,
          question: questions[index]?.title,
          description: questions[index]?.description,
          options: [currentQuestionAnswer?.answers?.[0]],
        };
        try {
          await sendAskToFormTracker(ask);
        } catch (err) {
          console.log(err);
        }
        try {
          const { data } = await uploadCMSProject({ ...request });
          // try {
          //   setLoadingText('Analizando respuestas...');
          //   const prompt = parseAnswersToPromptForFreelancers();
          //   const prompt1 = sendProjectPromptClient({ project: data?.project?.id, prompt });
          //   const prompt2 = sendProjectPromptFreelance({ project: data?.project?.id, prompt });
          //   await Promise.all([prompt1, prompt2]);
          // } catch (error) {
          //   console.log(error);
          // }
          history.push(`${CLIENT_PUBLICATIONS_ROUTE}/${data?.project?.id}?invites=true`);
        } catch (error) {
          console.log(error);
        }
      } else {
        setShowAuthModal(true);
      }
    }
    setIsPublishing(false);
  };

  return {
    answers,
    index,
    handleBack,
    hasOptionSelected,
    handleNext,
    update,
    handleSetAnswer,
    isOptionSelected,
    questions,
    templates,
    setTemplates,
    palettes,
    handleSendForm,
    isLoadingQuestions,
    isPublishing,
    canGoNext,
    setSelectedCategory,
    isLoadingAnswers,
    isEdit,
    user,
    currentQuestionAnswer,
    loadingText,
  };
};

export default CMSFormViewModel;
