import React from 'react';
import { FormItemCard } from '../../FormItemCard';
import { apiIntegrationsOptions } from 'common/constants/publicationForm.constants';
import { MultipleChoiceQuestion } from 'components/project/NewProjectForm/MultipleChoiceQuestion';

const APIIntegration = ({
  values,
  handleSelectAPIIntegratios,
  currentFocus,
  handleGoBackQuestion,
  handleGoForwardQuestion,
  displayDistinctQuestion,
}) => {
  return (
    <FormItemCard
      name="api_integration"
      title="¿Necesitas integrarte con alguna API?"
      subTitle={'Elige una o más opciones'}
      isCurrent={currentFocus == 'api_integration'}
      id={'api_integration-container'}
      handleGoForwardQuestion={handleGoForwardQuestion}
      handleGoBackQuestion={handleGoBackQuestion}
      displayDistinctQuestion={displayDistinctQuestion}>
      <MultipleChoiceQuestion
        optionsList={apiIntegrationsOptions}
        isFocused={currentFocus == 'api_integration'}
        currentSelected={values['api_integration']}
        handleSelect={handleSelectAPIIntegratios}
      />
    </FormItemCard>
  );
};

export default APIIntegration;
