import { OPEN_LEFT_WINDOW, CLOSE_LEFT_WINDOW } from './types';

export const openLeftWindow = () => (dispatch) => {
  dispatch({
    type: OPEN_LEFT_WINDOW,
  });
};
export const closeLeftWindow = () => (dispatch) => {
  dispatch({
    type: CLOSE_LEFT_WINDOW,
  });
};
