import { uploadFile, getFiles } from '@actions/projects';

const fetchMessages = (email, chatID, page = 1) => {
  return JSON.stringify({
    command: 'fetch_messages',
    username: email,
    chatId: chatID,
    page,
  });
};

const newChatMessage = ({ from, content, chatId, token, fileId = null }) => {
  const helper = JSON.stringify({
    command: 'new_message',
    from: from,
    message: content,
    chatId: chatId,
    token: token,
    file_id: fileId,
  });
  return helper;
};

const uploadHandler = async (event, chatID) => {
  const file = event?.target.files[0];
  const formData = new FormData();
  formData.append('filename', file.name);
  formData.append('file', file);
  formData.append('chat', chatID);
  formData.append('type', 'chat');

  return uploadFile(formData);
};

const getFilesHandler = (chatID) => getFiles(chatID);

const chatService = { fetchMessages, newChatMessage, uploadHandler, getFilesHandler };

export default chatService;
