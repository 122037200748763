import React from 'react';
import { getPrivateProject } from '@actions/projects.js';
import { useParams } from 'react-router-dom';
import { ManagmentViewModel } from '../../../ManagmentViewModel';
import { getChatId } from '@actions/chat';
import { getRequest } from '@actions/requests';
import { getRequestProposals } from '@actions/proposals';
import chatService from '@services/chatService';
import { getProjectQuestions, replyQuestion } from 'actions/projects';
import { scrollToBottom } from 'common/utils/WindowFocus';

export const FreelancerProjectManagmentViewModel = () => {
  const { id } = useParams();
  const {
    authUser,
    userDrawer,
    setUserDrawer,
    viewRequestDrawer,
    setViewRequestDrawer,
    projectDrawer,
    setProjectDrawer,
  } = ManagmentViewModel();

  const [ratingModal, setRatingModal] = React.useState(false);

  const [isLoadingProject, setIsLoadingProject] = React.useState(true);
  const [currentProject, setCurrentProject] = React.useState();

  const [currentRequest, setCurrentRequest] = React.useState();
  const [isLoadingRequest, setIsLoadingRequest] = React.useState(false);

  const [currentProposal, setCurrentProposal] = React.useState();
  const [isLoadingProposal, setIsLoadingProposal] = React.useState(false);

  const [currentChat, setCurrentChat] = React.useState(null);
  const [isLoadingChat, setIsLoadingChat] = React.useState(false);

  const [files, setFiles] = React.useState([]);
  const [isLoadingFiles, setIsLoadingFiles] = React.useState(false);

  const [questionsList, setQuestionsList] = React.useState([]);
  const [isLoadingQuestions, setIsLoadingQuestions] = React.useState(false);

  const [refetchProject, setRefetchProject] = React.useState(false);

  const handleGetFiles = (chatId) => {
    setIsLoadingFiles(true);
    chatService
      .getFilesHandler(chatId)
      .then((res) => {
        setFiles(res.data);
        setIsLoadingFiles(false);
      })
      .catch(() => setIsLoadingFiles(false));
  };

  const handleGetRequest = (userId, projectId) => {
    setIsLoadingRequest(true);
    getRequest({ emitter: userId, project: projectId })
      .then((res) => {
        setCurrentRequest(res.data.objects[0]);
        setIsLoadingRequest(false);
      })
      .catch(() => {
        setCurrentRequest(null);
        setIsLoadingRequest(false);
      });
  };
  const handleReplyQuestion = (id, answerText) => {
    setIsLoadingQuestions(true);
    replyQuestion(id, answerText)
      .then((res) => {
        setQuestionsList([...questionsList.filter((q) => q.id != id), res.data.request]);
        setIsLoadingQuestions(false);
        scrollToBottom(`publication-questions-${res.data.request.id}`);
      })
      .catch(() => {
        setIsLoadingQuestions(false);
      });
  };
  React.useEffect(() => {
    setIsLoadingProject(true);
    getPrivateProject(id)
      .then((res) => {
        setCurrentProject(res.data);
        setIsLoadingProject(false);
      })
      .catch(() => setIsLoadingProject(false));
  }, [id, refetchProject]);
  React.useEffect(() => {
    setIsLoadingQuestions(true);
    if (currentProject) {
      getProjectQuestions(currentProject.id)
        .then((res) => {
          setQuestionsList(res.data.objects);
          setIsLoadingQuestions(false);
        })
        .catch(() => {
          setQuestionsList([]);
          setIsLoadingQuestions(false);
        });
    }
  }, [currentProject]);
  React.useEffect(() => {
    setIsLoadingChat(true);
    getChatId(authUser.user.id, id, authUser.token)
      .then((res) => {
        setCurrentChat(res.data[0]);
        setIsLoadingChat(false);
      })
      .catch(() => setIsLoadingChat(false));
  }, [currentProject]);

  React.useEffect(() => {
    if (currentChat && !isLoadingFiles) {
      handleGetFiles(currentChat.id);
    }
  }, [currentChat]);

  React.useEffect(() => {
    if (currentProject) {
      handleGetRequest(currentProject.collaborator_id, id);
    }
  }, [currentProject]);

  React.useEffect(() => {
    setIsLoadingProposal(true);
    if (currentRequest) {
      getRequestProposals({ request: currentRequest?.id })
        .then((res) => {
          setCurrentProposal(res.data[res.data.length - 1]);
          setIsLoadingProposal(false);
        })
        .catch(() => setIsLoadingProposal(false));
    }
  }, [currentRequest]);

  const useImageUploadHandler = (event) => {
    setIsLoadingFiles(true);
    chatService
      .uploadHandler(event, currentChat.id)
      .then(() => {
        setIsLoadingFiles(false);
        handleGetFiles(currentChat.id);
      })
      .catch(() => setIsLoadingChat(false));
  };

  return {
    authUser,
    files,
    isLoadingFiles,
    isLoadingChat,
    isLoadingProject,
    isLoadingRequest: isLoadingRequest || isLoadingProposal,
    currentProject,
    currentRequest,
    currentProposal,
    currentChat,
    userDrawer,
    setUserDrawer,
    viewRequestDrawer,
    setViewRequestDrawer,
    projectDrawer,
    setProjectDrawer,
    ratingModal,
    setRatingModal,
    useImageUploadHandler,
    questionsList,
    isLoadingQuestions,
    handleReplyQuestion,
    handleGetFiles,
    setRefetchProject,
  };
};
