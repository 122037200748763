import React from 'react';
/* eslint-disable no-unused-vars */
import { Fragment } from 'react';
import './ClientProjectManagment.scss';
import { Text } from '@components/common';
import { LoadingDots, FileContainer } from '@components/common/';
import NewChat from 'components/common/NewChat';
import { MyProjectsMainCard } from 'components/project/ProjectMainCard/MyProjectsMainCard/MyProjectsMainCard';
import ClientPublicationDetailsAndQuestions from '../../../ProjectsCommon/ProjectDetailsAndQuestions';
import ClientPublicationProposal from '../../../ProjectsCommon/ProjectProposal';
import CustomTabs from 'components/common/CustomTabs';
import ClientProjectFreelancerProfile from '../../../ProjectsCommon/ProfileContent';
import { TABS } from 'pages/Managment/ProjectsCommon/ProjectsTabs.constants';
import { BookOpen, File, Monitor, Star, User } from 'react-feather';
import BoardDashboard from 'components/board/BoardDashboard/BoardDashboard';
import { useMediaQuery } from '@material-ui/core';
import ProjectFeedback from 'pages/Managment/ProjectsCommon/ProjectFeedback';

const ClientProjectTabs = ({
  currentProject,
  handleGetFiles,
  authUser,
  isLoadingFiles,
  files,
  questionsList,
  handleReplyQuestion,
  isLoadingQuestions,
  currentProposal,
  currentRequest,
  isLoadingProject,
  isLoadingRequest,
  setRatingModal,
  isFinished,
  setRefetchProject,
}) => {
  const ratingUser = currentProject?.ratings?.find(
    (rating) => rating.rating_type === 'collaborator'
  );
  const isCompleted = currentProject?.status === 'completed';
  const clientTabs = [
    {
      name: TABS.FEEDBACK,
      icon: <Star />,
      component: <ProjectFeedback ratingUser={ratingUser} />,
    },
    {
      name: TABS.PROYECT,
      icon: <Monitor />,
      component: (
        <>
          <NewChat
            chatID={currentProject?.chat_id}
            participants={[currentProject?.owner_data?.id, currentProject?.freellance_data?.id]}
            handleGetFiles={handleGetFiles}
            currentProject={currentProject}
            style={{ padding: '1em 0' }}
            disabled={isFinished}
            archivos={
              <FileContainer
                userId={authUser?.user.id}
                ownerImage={currentProject?.owner_data?.profile_image}
                freelanceImage={currentProject?.freellance_data?.profile_image}
                isLoadingFiles={isLoadingFiles}
                files={files}
                withButton={false}
                style={{ maxHeight: '350px', overflowY: 'auto' }}
              />
            }
          />
          <Text type="detail">Usa el tablero para ordenar tus tareas e informar a tu cliente.</Text>
          <BoardDashboard projectId={currentProject?.id} disabled={isFinished} />
        </>
      ),
    },
    {
      name: TABS.PUBLICATION,
      icon: <BookOpen />,
      component: (
        <ClientPublicationDetailsAndQuestions
          type="project"
          currentProject={currentProject}
          questionsList={questionsList}
          handleReplyQuestion={handleReplyQuestion}
          isLoadingQuestions={isLoadingQuestions}
        />
      ),
    },
    {
      name: TABS.PROPOSAL,
      icon: <File />,
      component: (
        <ClientPublicationProposal
          currentProposal={currentProposal}
          currentRequest={currentRequest}
        />
      ),
    },
    {
      name: TABS.PROFILE.FREELANCER,
      icon: <User />,
      component: (
        <ClientProjectFreelancerProfile
          currentUser={currentProject?.freellance_data}
          type="client"
        />
      ),
    },
  ];
  const Mobile = useMediaQuery('(max-width:770px)');
  const DeskResponsiveEnd = useMediaQuery('(min-width:1017px)');
  const DeskResponsiveStart = useMediaQuery('(max-width:1160px)');
  const toggleIcons = (DeskResponsiveStart && DeskResponsiveEnd) || Mobile;
  return (
    <>
      {!isLoadingProject && !isLoadingRequest ? (
        <Fragment>
          <CustomTabs
            tabs={isCompleted ? clientTabs : clientTabs.slice(1)}
            toggleIcons={toggleIcons}
            commonLayout={
              <MyProjectsMainCard
                style={{ borderTopLeftRadius: 0 }}
                type="detail"
                currentProject={currentProject}
                currentFreelancer={currentProject?.freellance_data}
                setRatingModal={!currentProject?.client_rating ? setRatingModal : null}
                setRefetchProject={setRefetchProject}
                withActions
              />
            }
          />
        </Fragment>
      ) : (
        <LoadingDots />
      )}
    </>
  );
};

export default ClientProjectTabs;
