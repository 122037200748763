/* eslint-disable no-unused-vars */
import { useMediaQuery } from '@material-ui/core';
import { BaseLayout, LoadingDots, Suggestions, Text } from 'components/common';
import { ContactDrawer } from 'components/service/ContactDrawer/ContactDrawer';
import ProfileContent from 'pages/Managment/ProjectsCommon/ProfileContent';
import { PublicServiceViewModel } from 'pages/Service/PublicService/PublicServiceViewModel';
import React, { Fragment } from 'react';
import FreelanceProfileViewModel from '../FreelanceProfileViewModel';
import PublicFreelanceCard from '../PublicFreelanceCard/PublicFreelanceCard';
import { QuestionContainer } from '@components/common/QuestionContainer';

const FREELANCER_TABS = {
  SERVICES: 'Servicios',
  PROPOSAL: 'Propuesta',
  MESSAGES: 'Mensajes',
  PROFILE: 'Perfil',
};
const PublicFreelance = () => {
  const {
    currentFreelance,
    isLoadingFreelance,
    usersSuggestions,
    questionsList,
    handleCreateQuestion,
  } = FreelanceProfileViewModel();

  const {
    redirectToCreateProject,
    authUserProjects,
    handleConfirmInviteProject,
    setProjectSelected,
    projectSelected,
    contactDrawer,
    setContactDrawer,
    authUser,
    isLoadingProjects,
    handleRedirectSuggestion,
    isLoading,
  } = PublicServiceViewModel();

  const Mobile = useMediaQuery('(max-width:596px)');

  return (
    <BaseLayout>
      <Fragment>
        <BaseLayout.LeftSide>
          <Suggestions
            suggestionsData={usersSuggestions}
            handleRedirectSuggestion={handleRedirectSuggestion}
            type="user"
          />
        </BaseLayout.LeftSide>
      </Fragment>

      <BaseLayout.Content>
        {!isLoadingFreelance && (
          <PublicFreelanceCard
            freelance={currentFreelance}
            authUser={authUser}
            setContactDrawer={setContactDrawer}
          />
        )}

        {!isLoadingProjects && !isLoadingFreelance && (
          <ContactDrawer
            currentUser={currentFreelance}
            redirectToCreateProject={redirectToCreateProject}
            authUserProjects={authUserProjects}
            handleConfirmInviteProject={handleConfirmInviteProject}
            setProjectSelected={setProjectSelected}
            projectSelected={projectSelected}
            contactDrawer={contactDrawer}
            setContactDrawer={setContactDrawer}
          />
        )}

        {isLoadingFreelance ? (
          <LoadingDots />
        ) : (
          <Fragment>
            <ProfileContent
              currentUser={currentFreelance}
              style={{ borderRadius: '1em' }}
              type="client"
            />
          </Fragment>
        )}
        <div>
          <Text type="detail">Preguntas:</Text>
          <Text type="smallDetail">
            Aquí puedes hacer tus consultas sobre cosas que no te hayan quedado claras de la
            descripción.
          </Text>
          <QuestionContainer
            type="service"
            user={currentFreelance}
            handleCreateQuestion={handleCreateQuestion}
            authUser={authUser}
            isLoading={isLoading}
            questionsList={questionsList}
          />
        </div>
      </BaseLayout.Content>
    </BaseLayout>
  );
};

export default PublicFreelance;
