import React from 'react';
import './AboutMe.scss';
import ClampLines from 'react-clamp-lines';
import { Input, Spin, Select } from 'antd';
import { Button, SkillsBeans, Title } from '@components/common';

const { TextArea } = Input;
const { Option } = Select;

const AboutMe = ({
  user,
  aboutMeEditing,
  experienceEditing,
  skillsEditing,
  handleSave,
  skillsSelected,
  skillsData,
  fetching,
  fetchSkills,
  handleChangeSkills,
  setAboutMeEditing,
  setExperienceEditing,
  setSkillsEditing,
}) => {
  return (
    <div className="about-me__container">
      <div className="about-me__section">
        <div className="about-me__header">
          <Title size="14" tipe="tertiary" color="primary">
            Acerca de mi{' '}
          </Title>
          {aboutMeEditing ? (
            <Button
              onClick={() => handleSave('aboutMe')}
              text="Guardar"
              variant="secondary"
              borderColor="primary"
              size="sm"
            />
          ) : (
            <Button
              text={<i className="fas fa-pencil-alt"></i>}
              variant="edit"
              type="button"
              onClick={() => setAboutMeEditing(true)}>
              {' '}
            </Button>
          )}
        </div>
        <div className="about-me__content">
          {aboutMeEditing ? (
            <TextArea
              id="about-me__input"
              defaultValue={user.about_me}
              autoSize
              showCount
              maxLength={1500}
              allowClear={true}
              placeholder="Cuéntale al mundo sobre ti"
            />
          ) : (
            <ClampLines
              text={user.about_me ? user.about_me : ''}
              id="about-me__text"
              lines={5}
              ellipsis="..."
              moreText="Ver más"
              lessText="Ver menos"
              className="tn-text tn-text--regular"
              innerElement="div"
            />
          )}
        </div>
      </div>
      <div className="about-me__section">
        <div className="about-me__header">
          <Title size="14" tipe="tertiary" color="primary">
            Experiencia
          </Title>
          {experienceEditing ? (
            <Button
              onClick={() => handleSave('experience')}
              text="Guardar"
              variant="secondary"
              borderColor="primary"
              size="sm"
            />
          ) : (
            <Button
              text={<i className="fas fa-pencil-alt"></i>}
              variant="edit"
              type="button"
              onClick={() => setExperienceEditing(true)}>
              {' '}
            </Button>
          )}
        </div>
        <div className="about-me__content">
          {experienceEditing ? (
            <TextArea
              id="experiencia__input"
              defaultValue={user.experience}
              autoSize
              showCount
              maxLength={1500}
              allowClear={true}
              placeholder="Cuéntale al mundo tu experiencia"
            />
          ) : (
            <ClampLines
              text={user.experience ? user.experience : ''}
              id="experiencia__text"
              lines={5}
              ellipsis="..."
              moreText="Ver más"
              lessText="Ver menos"
              className="tn-text tn-text--regular"
              innerElement="div"
            />
          )}
        </div>
      </div>
      <div className="about-me__section">
        <div className="about-me__header">
          <Title size="14" tipe="tertiary" color="primary">
            Habilidades{' '}
          </Title>
          {skillsEditing ? (
            <Button
              onClick={() => handleSave('skills')}
              text="Guardar"
              variant="secondary"
              borderColor="primary"
              size="sm"
            />
          ) : (
            <Button
              text={<i className="fas fa-pencil-alt"></i>}
              variant="edit"
              type="button"
              onClick={() => setSkillsEditing(true)}>
              {' '}
            </Button>
          )}
        </div>
        <div className="about-me__content">
          {skillsEditing ? (
            <Select
              mode="multiple"
              labelInValue
              value={skillsSelected}
              placeholder=""
              notFoundContent={fetching ? <Spin size="small" /> : null}
              filterOption={false}
              onSearch={fetchSkills}
              onChange={handleChangeSkills}
              style={{ width: '100%' }}>
              {skillsData?.map((skill) => (
                <Option value={skill.id} key={skill.id}>
                  {skill.name}
                </Option>
              ))}
            </Select>
          ) : (
            <SkillsBeans skills={user.skills_names} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
