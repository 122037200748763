import React from 'react';
import { ManagmentViewModel } from '../../../ManagmentViewModel';
import { getUserServices } from '@actions/services';

export const FreelancerPublicationsListViewModel = () => {
  const { authUser } = ManagmentViewModel();

  const [isLoadingServices, setIsLoadingServices] = React.useState(false);

  const [servicesList, setServicesList] = React.useState([]);

  const useGetUserServices = (userId) => getUserServices(userId);

  React.useEffect(() => {
    async function fetchData() {
      setIsLoadingServices(true);
      if (authUser) {
        await useGetUserServices(authUser.user.id).then((res) => {
          setServicesList(res.data.objects);
        });
        setIsLoadingServices(false);
      }
    }
    fetchData();
  }, []);

  return {
    isLoading: isLoadingServices,
    authUser,
    servicesList,
  };
};
