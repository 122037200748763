import React from 'react';
import './CustomDrawer.scss';
import { Drawer } from 'antd';
import {
  WINDOW_DESKTOP_MEDIUM,
  WINDOW_MOBILE_LARGE,
  WINDOW_MOBILE_MEDIUM,
  DRAWER_EXTRA_LARGE,
  DRAWER_LARGE,
  DRAWER_MEDIUM,
  DRAWER_SMALL,
} from '@common/constants/windowsizewidth.constants';

import { useWindowSize } from '../../../hooks/UseWindowSize';

const CustomDrawer = ({ children, type, open, onClose, visible, isMain = true }) => {
  const windowSize = useWindowSize();
  const [drawerWidth, setDrawerWidth] = React.useState();
  React.useEffect(() => {
    if (windowSize.width > WINDOW_DESKTOP_MEDIUM && type != 'profile' && type != 'serviceProfile') {
      setDrawerWidth(DRAWER_EXTRA_LARGE);
    } else if (
      windowSize.width > WINDOW_MOBILE_LARGE &&
      type != 'profile' &&
      type != 'serviceProfile'
    ) {
      setDrawerWidth(DRAWER_LARGE);
    } else if (windowSize.width > WINDOW_MOBILE_MEDIUM) {
      setDrawerWidth(DRAWER_MEDIUM);
    } else {
      setDrawerWidth(DRAWER_SMALL);
    }
  }, [windowSize]);
  return (
    <Drawer
      width={drawerWidth}
      open={open}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ padding: 0 }}
      contentWrapperStyle={{ overflow: visible }}
      className={`custom-drawer__container-${isMain ? 'main' : 'secondary'}`}>
      {children}
    </Drawer>
  );
};

export default CustomDrawer;
