import axiosClient from '@config/axios';
import { tokenConfig } from './auth';

// ADD PROPOSAL
export const addProposal = (proposal) => {
  const result = axiosClient.post(`/proposals/`, proposal);

  return result;
};

// ACCEPT PROPOSAL
export const acceptProposal = (id, proposal) => {
  const result = axiosClient.patch(`/proposals/${id}/`, proposal);

  return result;
};
//GET USER REQUESTS (REQUESTS MADE FROM A SPECIFITY USER)
export const getRequestProposals = (params) => axiosClient.get('/proposals', { params });

export const addEndProjectProposal = (data) => async (dispatch, getState) => {
  const query = await axiosClient
    .post(`/finishProjectProposals/`, data, tokenConfig(getState))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return query;
};

export const acceptEndProjectProposal = (id) => async (dispatch, getState) => {
  const query = await axiosClient
    .patch(`/finishProjectProposals/${id}`, { accepted: true }, tokenConfig(getState))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return query;
};

export const getEndProjectProposals = (idrequest) => async (dispatch, getState) => {
  const query = await axiosClient
    .get(`/finishProjectProposals/?request=${idrequest}`, tokenConfig(getState))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return query;
};
