import { getProjectsUrlParams } from '@actions/projects';
import { uploadFile } from '@actions/projects';
import { registerProject_v2, editProject_v2 } from 'actions/projects';

import {
  actualStateOptions,
  timeRangeOptions,
  budgetRangeOptions,
  websiteTypeOptions,
  actualDBStatusOptions,
  compatibilityOptions,
} from 'common/constants/publicationForm.constants';
import { getPrivateProject } from 'actions/projects';
import { getCategory } from 'actions/categories';

const getUserProjects = (userId, status, userType, page) => {
  if (status.constructor.name === 'String') {
    const URLparams = new URLSearchParams([
      [userType, userId],
      ['status', status],
      ['page', page],
    ]).toString();
    return getProjectsUrlParams(URLparams);
  } else if (status.length === 2) {
    const URLparams = new URLSearchParams([
      [userType, userId],
      ['status', status[0]],
      ['status', status[1]],
      ['page', page],
    ]).toString();
    return getProjectsUrlParams(URLparams);
  } else if (status.length === 3) {
    const URLparams = new URLSearchParams([
      [userType, userId],
      ['status', status[0]],
      ['status', status[1]],
      ['status', status[2]],
      ['page', page],
    ]).toString();
    return getProjectsUrlParams(URLparams);
  } else {
    const URLparams = new URLSearchParams([
      [userType, userId],
      ['status', status[0]],
      ['status', status[1]],
      ['status', status[2]],
      ['status', status[3]],
      ['page', page],
    ]).toString();
    return getProjectsUrlParams(URLparams);
  }
};

const getUserCollaboratorProjects = (userId, status) => {
  const URLparams = new URLSearchParams([
    ['collaborator', userId],
    ['status', status],
  ]).toString();
  return getProjectsUrlParams(URLparams);
};

const getOwnerProjectsStatus = (userId, status) => {
  const URLparams = new URLSearchParams([
    ['owner', userId],
    ['status', status],
  ]).toString();
  return getProjectsUrlParams(URLparams);
};

const getCollabProjectsStatus = (userId, status) => {
  const URLparams = new URLSearchParams([
    ['collaborator', userId],
    ['status', status],
  ]).toString();
  return getProjectsUrlParams(URLparams);
};

const uploadProjectImages = (images, currentProject) => {
  return new Promise((resolve, reject) => {
    if (images.length == 0) {
      return resolve('Done');
    }
    images.forEach(function (image) {
      image.isUploading = true;
      //Upload file
      const formData = new FormData();
      formData.append('filename', image.name);
      formData.append('file', image.originFileObj);
      formData.append('project', currentProject);
      formData.append('publication', true);
      //formData.append('chat', chatID);
      formData.append('type', 'project');
      uploadFile(formData)
        .then(() => {
          //Success on upload
          image.isUploading = false;
          if (images.filter((image) => image.isUploading).length === 0) {
            return resolve('Done');
          }
        })
        .catch(() => {
          return reject('Error');
        });
    });
  });
};

const parseProjectForm = (values) => {
  const project = {
    actual_db_status: values['actual_db_status'] ? values['actual_db_status']['name'] : null,
    actual_state: values['actual_state'] ? values['actual_state']['name'] : null,
    api_integration: values['api_integration'],
    budget_range: values['budget_range'] ? values['budget_range']['name'] : null,
    categories: [values['category'].id, values['sub_category'].id],
    compatibility: values['compatibility'] ? values['compatibility']['name'] : null,
    description: values['description'],
    model_goal:
      values['model_goal'] && values['model_goal'].length > 0 ? values['model_goal'] : null,
    skills_required: values['skills'].map((s) => s.value),
    time_range: values['time_range'] ? values['time_range']['name'] : null,
    title: values['title'],
    website_functionalities: values['website_functionalities'],
    website_type: values['website_type'] ? values['website_type']['name'] : null,
    reference_urls: values['reference_urls']?.length > 0 ? values['reference_urls'] : null,
  };
  return project;
};

const handleOnSubmitForm = (type, id, values) => {
  return new Promise((resolve, reject) => {
    const project = parseProjectForm(values);
    if (type == 'EDIT') {
      editProject_v2(id, project)
        .then(() => {
          if (values['images']) {
            uploadProjectImages(
              values['images'].filter((file) => file.size),
              id
            )
              .then(() => {
                return resolve({
                  message: 'El proyecto se ha editado correctamente.',
                  id: id,
                });
              })
              .catch(() => {
                return reject({
                  message: 'El proyecto no se pudo editar correctamente.',
                  id: id,
                });
              });
          } else {
            return resolve({ message: 'El proyecto se ha editado correctamente.', id: id });
          }
        })
        .catch(() => {
          return reject({
            message: 'El proyecto no se pudo editar correctamente.',
            id: id,
          });
        });
    } else {
      registerProject_v2(project)
        .then((res) => {
          const projectData = res.data.project;
          if (values['images']) {
            uploadProjectImages(
              values['images'].filter((file) => file.size),
              projectData.id
            )
              .then(() => {
                return resolve({
                  message: 'El proyecto se ha creado correctamente.',
                  id: projectData.id,
                });
              })
              .catch(() => {
                return reject({
                  message: 'El proyecto no se pudo crear correctamente.',
                  id: projectData.id,
                });
              });
          } else {
            return resolve({
              message: 'El proyecto se ha creado correctamente.',
              id: projectData.id,
            });
          }
        })
        .catch(() => {
          return reject({
            message: 'El proyecto no se pudo crear correctamente.',
            id: null,
          });
        });
    }
  });
};

const loadProject = async (id) => {
  let values = {};
  await getPrivateProject(id).then(async function (res) {
    const category = await getCategory(res.data['categories'][0]);
    const subCategory = await getCategory(res.data['categories'][1]);
    values = {
      actual_state: actualStateOptions.filter((as) => as.name == res.data['actual_state'])[0],
      category: category.data,
      sub_category: subCategory.data,
      website_type: websiteTypeOptions.filter((as) => as.name == res.data['website_type'])[0],
      reference_urls: res.data['reference_urls'],
      website_functionalities: res.data['website_functionalities'],
      compatibility: compatibilityOptions.filter((as) => as.name == res.data['compatibility'])[0],
      actual_db_status: actualDBStatusOptions.filter(
        (as) => as.name == res.data['actual_db_status']
      )[0],
      model_goal: res.data['model_goal'],
      api_integration: res.data['api_integration'],
      time_range: timeRangeOptions.filter((as) => as.name == res.data['time_range'])[0],
      budget_range: budgetRangeOptions.filter((as) => as.name == res.data['budget_range'])[0],
      description: res.data['description'],
      skills: res.data['skills'].map((skill) => {
        return { value: skill.id, label: skill.name, key: skill.id };
      }),
      images: res.data['project_files'].map((file) => {
        return { name: file.original_name, id: file.id };
      }),
      title: res.data['title'],
    };
  });
  return values;
};

const loadBrachStatus = (categoryId, actualStateId) => {
  const categoryValues = { 1: 'cms', 12: 'webapp', 22: 'mobileapp', 31: 'data-model', 40: 'other' };
  const actualState = {
    1: 'from-scratch',
    2: 'from-scratch',
    3: 'from-scratch',
    4: 'integration',
    5: 'from-scratch',
    6: 'from-scratch',
  };

  return categoryValues[categoryId] + '-' + actualState[actualStateId];
};

const projectService = {
  uploadProjectImages,
  getUserProjects,
  getUserCollaboratorProjects,
  getOwnerProjectsStatus,
  getCollabProjectsStatus,
  parseProjectForm,
  handleOnSubmitForm,
  loadProject,
  loadBrachStatus,
};

export default projectService;
