import React from 'react';
import { FormItemCard } from '../../FormItemCard';
import { Input } from 'antd';

const { TextArea } = Input;

const ProyectDescription = ({
  values,
  handleOnChangeDescription,
  currentFocus,
  handleGoBackQuestion,
  handleGoForwardQuestion,
  displayDistinctQuestion,
}) => {
  return (
    <FormItemCard
      name="description"
      title="Describe en tus palabras los detalles del proyecto"
      subTitle="Escribe un mínimo de 100 caracteres"
      isCurrent={currentFocus == 'description'}
      id={'description-container'}
      handleGoForwardQuestion={handleGoForwardQuestion}
      handleGoBackQuestion={handleGoBackQuestion}
      displayDistinctQuestion={displayDistinctQuestion}>
      <TextArea
        placeholder={'¡Buena suerte!'}
        autoSize={{ minRows: 5, maxRows: 15 }}
        maxLength={4000}
        showCount
        onChange={(event) => handleOnChangeDescription(event.target.value)}
        name="description"
        value={values['description']}
        required={true}
        disabled={currentFocus != 'description'}
      />
    </FormItemCard>
  );
};

export default ProyectDescription;
