import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { notification } from 'antd';
import { withRouter } from 'react-router-dom';
import {
  ALERT_ERROR_ILUSTRATION,
  ALERT_SUCCESS_ILUSTRATION,
} from '@common/constants/informativeIlustrations.constants';
import './Alerts.scss';

export class Alerts extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    const { error, alert, message } = this.props;
    if (error !== prevProps.error) {
      if (error.status === 500) {
        notification.open({
          message: '¡Algo salió mal!',
          description: 'Ha ocurrido un error en el servidor, intentalo luego.',
          icon: <img src={ALERT_ERROR_ILUSTRATION} />,
          duration: 10,
          placement: 'bottomRight',
        });
      } else if (error.msg?.status_text) {
        notification.open({
          message: '¡Algo salió mal!',
          description: error.msg.status_text,
          icon: <img src={ALERT_ERROR_ILUSTRATION} />,
          duration: 10,
          placement: 'bottomRight',
        });
        if (
          error.status === 403 &&
          error.msg.status_text === 'El usuario no ha completado su validación de email'
        ) {
          this.handleRedirectTo(`?popup=registerConfirmation`);
        }
      } else if (error.msg?.detail) {
        notification.open({
          message: '¡Algo salió mal!',
          description: error.msg.detail,
          icon: <img src={ALERT_ERROR_ILUSTRATION} />,
          duration: 10,
          placement: 'bottomRight',
        });
      }
    }
    if (message !== prevProps.message) {
      if (message.deleteProject) {
        notification.open({
          message: '¡Tu Publicación ha sido borrada Correctamente!',
          description: '¡Esperamos que vuelvas!',
          icon: <img src={ALERT_SUCCESS_ILUSTRATION} />,
          duration: 10,
          placement: 'bottomRight',
        });
      } else if (message.successMessage) {
        notification.open({
          message: '!Felicitaciones!',
          description: message.successMessage,
          icon: <img src={ALERT_SUCCESS_ILUSTRATION} />,
          duration: 10,
          placement: 'bottomRight',
        });
      } else if (message.errorMessage) {
        notification.open({
          message: '!Algo salió mal!',
          description: message.errorMessage,
          icon: <img src={ALERT_ERROR_ILUSTRATION} />,
          duration: 10,
          placement: 'bottomRight',
        });
      } else if (message.registerUser) {
        notification.open({
          message: '!Felicitaciones!',
          description: '¡Tu cuenta se ha creado exitosamente!. Ahora debes confirmar tu Email.',
          icon: <img src={ALERT_SUCCESS_ILUSTRATION} />,
          duration: 10,
          placement: 'bottomRight',
        });
      } else if (message.registerConfirmation) {
        notification.open({
          message: '!Felicitaciones!',
          description:
            'Tu cuenta se ha creado exitosamente, ahora tienes que verificar tu identidad en la seccion Mi Perfil!',
          icon: <img src={ALERT_SUCCESS_ILUSTRATION} />,
          duration: 10,
          placement: 'bottomRight',
        });
      } else if (message.registerProject) {
        notification.open({
          message: '!Felicitaciones!',
          description: 'Tu Publicación ha sido creada correctamente.',
          icon: <img src={ALERT_SUCCESS_ILUSTRATION} />,
          duration: 10,
          placement: 'bottomRight',
        });
        this.handleRedirectTo(`/project/${message.registerProject.project.id}/view`);
      } else if (message.editProject) {
        notification.open({
          message: '!Felicitaciones!',
          description: 'Tu Publicación ha sido editada correctamente.',
          icon: <img src={ALERT_SUCCESS_ILUSTRATION} />,
          duration: 10,
          placement: 'bottomRight',
        });
        this.handleRedirectTo(`/project/${message.editProject.id}/view`);
      } else if (message.inviteService) {
        notification.open({
          message: 'Excelente!',
          description: 'Acabas de generar una invitación especial para el colaborador',
          icon: <img src={ALERT_SUCCESS_ILUSTRATION} />,
          duration: 10,
          placement: 'bottomRight',
        });
      } else if (message.registerService) {
        notification.open({
          message: 'Felicitaciones!',
          description: 'Tu Publicación ha sido creada correctamente.',
          icon: <img src={ALERT_SUCCESS_ILUSTRATION} />,
          duration: 10,
          placement: 'bottomRight',
        });
        this.handleRedirectTo(`/service/${message.registerService.service.id}/view`);
      } else if (message.addRequest) {
        notification.open({
          message: 'Felicitaciones!',
          description: 'Tu Postulación se realizó correctamente!',
          icon: <img src={ALERT_SUCCESS_ILUSTRATION} />,
          duration: 10,
          placement: 'bottomRight',
        });
        this.handleRedirectTo(`/project/${message.addRequest.request.project}`);
      } else if (message.addProposal) {
        notification.open({
          message: 'Felicitaciones!',
          description: 'Tu propuesta se envió correctamente!',
          icon: <img src={ALERT_SUCCESS_ILUSTRATION} />,
          duration: 10,
          placement: 'bottomRight',
        });
      } else if (message.updateService) {
        notification.open({
          message: 'Felicitaciones!',
          description: 'Tu Servicio se actualizó correctamente!',
          icon: <img src={ALERT_SUCCESS_ILUSTRATION} />,
          duration: 10,
          placement: 'bottomRight',
        });
      } else if (message.acceptRequest) {
        notification.open({
          message: 'Felicitaciones!',
          description: message.acceptRequest,
          icon: <img src={ALERT_SUCCESS_ILUSTRATION} />,
          duration: 10,
          placement: 'bottomRight',
        });
      } else if (message.resetPassword) {
        notification.open({
          message: 'Felicitaciones!',
          description: 'Se creó correctamente la solicitud. El correo llegará en breves instantes.',
          icon: <img src={ALERT_SUCCESS_ILUSTRATION} />,
          duration: 10,
          placement: 'bottomRight',
        });
        this.handleRedirectTo(`/recoverPassword`);
      } else if (message.successMessageRedirect) {
        notification.open({
          message: 'Felicitaciones!',
          description: message.successMessageRedirect,
          icon: <img src={ALERT_SUCCESS_ILUSTRATION} />,
          duration: 10,
          placement: 'bottomRight',
        });
        this.handleRedirectTo(message.redirectUri);
      } else if (message.passwordNotMatch) alert.error(message.passwordNotMatch);
    }
  }

  handleRedirectTo = (path) => {
    this.props.history.push(path);
  };

  render() {
    return <Fragment />;
  }
}

const mapStateToProps = (state) => ({
  error: state.errors,
  message: state.messages,
});

export default withRouter(connect(mapStateToProps)(Alerts));
