import React from 'react';
import css from './Functionalities.module.scss';
import { Text } from '@components/common';
import {
  functionalitiesIlustrationsDictionary,
  ilustrationsFolder,
  APILogosDictionary,
} from '../CMSDetails.constants';

function Functionalities({ currentProject }) {
  return (
    <div className={css.container}>
      <div className={css.functionalities}>
        <Text type="detail">Funcionalidades: </Text>
        <div className={css.content}>
          {' '}
          {currentProject?.website_functionalities?.map((func, index) => {
            return (
              <div className={css.item} key={`func-${index}`}>
                <img
                  src={
                    functionalitiesIlustrationsDictionary[func]
                      ? ilustrationsFolder + functionalitiesIlustrationsDictionary[func]
                      : ilustrationsFolder + 'dreamer.svg'
                  }
                />
                <Text type="smallDetail">{func} </Text>
              </div>
            );
          })}
        </div>
      </div>
      <div className={css.integrations}>
        {' '}
        <Text type="detail">Integraciones: </Text>
        <div className={css.content}>
          {' '}
          {currentProject?.api_integration?.map((api, index) => {
            return (
              <div className={css.item} key={`api-${index}`}>
                <img
                  src={
                    APILogosDictionary[api]
                      ? ilustrationsFolder + APILogosDictionary[api]
                      : ilustrationsFolder + 'dreamer.svg'
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Functionalities;
