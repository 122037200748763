import React from 'react';
import { Text, Title, CustomDrawer } from '@components/common';
import { SkillsBeans, Ratings } from '@components/common';
import ClampLines from 'react-clamp-lines';
import './ViewProfileDrawer.scss';
import PreviousProjects from 'pages/Managment/MyProfile/PreviousProjects';
import ProfilePicture from 'components/common/ProfilePicture/ProfilePicture';

export function ViewProfileDrawer({ currentUser, type, userDrawer, setUserDrawer }) {
  return (
    <CustomDrawer
      type={'project'}
      open={userDrawer}
      onClose={() => setUserDrawer(false)}
      visible={userDrawer}>
      <div className="view-profile__container">
        {/* {type == 'project' ? (
          <div className="view-profile__blue-background"></div>
        ) : (
          <div className="view-profile__green-background"></div>
        )} */}

        <div className="view-profile__header">
          <ProfilePicture
            user={currentUser}
            withEditButton={false}
            withMedals
            withName
            width={100}
          />
        </div>
        <div className="view-profile__social-media">
          {currentUser?.stackoverflow_link && (
            <div className="view-profile__link-container">
              {' '}
              <a
                id="view-profile__linked"
                href={currentUser?.stackoverflow_link}
                target="_blank"
                rel="noreferrer">
                <i className="fab fa-stack-overflow"></i>
                <Text type="strong">Stackoverflow</Text>
              </a>
            </div>
          )}
          {currentUser?.github_link && (
            <div className="view-profile__link-container">
              <a
                id="view-profile__linked"
                href={currentUser?.github_link}
                target="_blank"
                rel="noreferrer">
                <i className="fab fa-github"></i>
                <Text type="strong">Github</Text>
              </a>
            </div>
          )}
          {currentUser?.briefcase_link && (
            <div className="view-profile__link-container">
              <a
                id="view-profile__linked-briefcase"
                href={currentUser?.briefcase_link}
                target="_blank"
                rel="noreferrer">
                <i className="fa fa-briefcase"></i>
                <Text type="strong">Portafolio</Text>
              </a>
            </div>
          )}
        </div>
        <hr />
        <div className="view-profile__content">
          {' '}
          <div className="view-profile__scroll-container">
            <div className="view-profile__text-section">
              <Title type="tertiary" color="primary">
                Acerca de mi
              </Title>
              {currentUser?.about_me ? (
                <ClampLines
                  text={currentUser?.about_me}
                  id="about-me-text"
                  lines={5}
                  ellipsis="..."
                  moreText="Ver más"
                  lessText="Ver menos"
                  className={`tn-text tn-text--regular ${
                    type == 'project' ? 'primary-view-more' : 'secondary-view-more'
                  }`}
                  innerElement="p"
                />
              ) : (
                ''
              )}
            </div>
            <div className="view-profile__text-section">
              <Title type="tertiary" color="primary">
                Experiencia
              </Title>
              {currentUser?.experience && (
                <ClampLines
                  text={currentUser?.experience}
                  id="about-me-text"
                  lines={5}
                  ellipsis="..."
                  moreText="Ver más"
                  lessText="Ver menos"
                  className={`tn-text tn-text--regular ${
                    type == 'project' ? 'primary-view-more' : 'secondary-view-more'
                  }`}
                  innerElement="p"
                />
              )}
            </div>
            <div className="view-profile__text-section">
              <Title type="tertiary" color="primary">
                Habilidades
              </Title>
              <SkillsBeans skills={currentUser?.skills_names} />
            </div>
            <div className="view-profile__text-section">
              <PreviousProjects user={currentUser} carousel />
            </div>
            <div className="view-profile__text-section">
              <Ratings type="collaborator" userId={currentUser?.id} />
            </div>
          </div>
        </div>
      </div>
    </CustomDrawer>
  );
}
