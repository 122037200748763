export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_PROJECTS_LOADING = 'GET_PROJECTS_LOADING';
export const GET_PROJECT = 'GET_PROJECT';
export const GET_PROJECT_LOADING = 'GET_PROJECT_LOADING';
export const GET_SERVICE_LOADING = 'GET_SERVICE_LOADING';
export const GET_USER_PROJECTS = 'GET_USER_PROJECTS';
export const DELETE_PROJECT = 'DELETE_PROJECT';
export const ADD_PROJECT = 'ADD_PROJECT';
export const CLEAR_PROJECTS = 'CLEAR_PROJECTS';
export const PROJECTS_PAGINATION = 'PROJECTS_PAGINATION';
export const MY_PROJECTS_PAGINATION = 'MY_PROJECTS_PAGINATION';
export const GET_MY_ACTIVE_PROJECTS = 'GET_MY_ACTIVE_PROJECTS';
export const GET_MY_ACTIVE_PROJECTS_COLLAB = 'GET_MY_ACTIVE_PROJECTS_COLLAB';
export const MY_ACTIVE_PROJECTS_PAGINATION = 'MY_ACTIVE_PROJECTS_PAGINATION';
export const MY_ACTIVE_PROJECTS_PAGINATION_COLLAB = 'MY_ACTIVE_PROJECTS_PAGINATION_COLLAB';
export const EDIT_PROJECT = 'EDIT_PROJECT';
export const ADD_QUESTION = 'ADD_QUESTION';
export const DELETE_QUESTION = 'DELETE_QUESTION';
export const GET_KANBAN = 'GET_KANBAN';
export const UPDATE_CURRENT_PROJECT = 'UPDATE_CURRENT_PROJECT';
export const GET_SERVICES = 'GET_SERVICES';
export const GET_SERVICES_LOADING = 'GET_SERVICES_LOADING';
export const GET_USER_SERVICES = 'GET_USER_SERVICES';
export const GET_SERVICE = 'GET_SERVICE';
export const GET_MY_SERVICE = 'GET_MY_SERVICE';
export const DELETE_SERVICE = 'DELETE_SERVICE';
export const ADD_SERVICE = 'ADD_SERVICE';
export const UPDATE_SERVICE = 'UPDATE_SERVICE';
export const CLEAR_SERVICES = 'CLEAR_SERVICES';
export const SERVICES_PAGINATION = 'SERVICES_PAGINATION';
export const MY_SERVICES_PAGINATION = 'MY_SERVICES_PAGINATION';
export const MAX_RANGE_PROJECTS = 'MAX_RANGE_PROJECTS';
export const MAX_RANGE_SERVICES = 'MAX_RANGE_SERVICES';
export const UPDATE_MYACTIVEPROJECT = 'UPDATE_MYACTIVEPROJECT';
export const UPDATE_MYACTIVEPROJECT_COLLAB = 'UPDATE_MYACTIVEPROJECT_COLLAB';
export const UPDATE_PROJECT_PAGE = 'UPDATE_PROJECT_PAGE';
export const UPDATE_SERVICE_PAGE = 'UPDATE_SERVICE_PAGE';
export const UPDATE_HOME_SWITCH = 'UPDATE_HOME_SWITCH';

export const GET_ERRORS = 'GET_ERRORS';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';

export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const SET_LOADING_AUTH = 'SET_LOADING_AUTH';

export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const GET_USER_LOADING = 'GET_USER_LOADING';
export const DELETE_USER = 'DELETE_USER';
export const GET_USER_NOTIFICATIONS = 'GET_USER_NOTIFICATIONS';
export const NOTIFICATIONS_PAGINATION = 'NOTIFICATIONS_PAGINATION';

export const GET_SKILLS = 'GET_SKILLS';
export const DELETE_SKILL = 'DELETE_SKILL';
export const ADD_SKILL = 'ADD_SKILL';

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_SUB_CATEGORIES = 'GET_SUB_CATEGORIES';
export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const ADD_CATEGORY = 'ADD_CATEGORY';

export const EDIT_USER = 'EDIT_USER';

export const GET_USER_REQUESTS = 'GET_USER_REQUESTS';
export const GET_PROJECT_REQUESTS = 'GET_PROJECT_REQUESTS';
export const DELETE_REQUEST = 'DELETE_REQUEST';
export const ADD_REQUEST = 'ADD_REQUEST';
export const GET_REQUEST = 'GET_REQUEST';
export const ACCEPT_REQUEST = 'ACCEPT_REQUEST';

export const GET_REQUEST_PROPOSALS = 'GET_REQUEST_PROPOSALS';
export const ADD_PROPOSAL = 'ADD_PROPOSAL';
export const ACCEPT_PROPOSAL = 'ACCEPT_PROPOSAL';

//MYPROFILE
export const MY_PUBLICATIONS_PAGE_STATUS = 'MY_PUBLICATIONS_PAGE_STATUS';
export const HOME_PAGE_STATUS = 'HOME_PAGE_STATUS';
export const MORE_CONFIG_STATUS = 'MORE_CONFIG_STATUS';
export const VALIDATE_IDENTITY = 'VALIDATE_IDENTITY';
export const SELECT_CHAT = 'SELECT_CHAT';
export const UPDATE_CHATS = 'UPDATE_CHATS';
//
export const ADMIN_WINDOW_STATE = 'ADMIN_WINDOW_STATE';

// Order By
export const UPDATE_ORDER_BY = 'HANDLE_ORDER_BY';
export const UPDATE_SEARCHBAR = 'UPDATE_SEARCHBAR';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const UPDATE_SKILLS = 'UPDATE_SKILLS';

export const OPEN_LEFT_WINDOW = 'OPEN_LEFT_WINDOW';
export const CLOSE_LEFT_WINDOW = 'CLOSE_LEFT_WINDOW';
export const OPEN_RIGHT_WINDOW = 'OPEN_RIGHT_WINDOW';
export const CLOSE_RIGHT_WINDOW = 'CLOSE_RIGHT_WINDOW';
