import React, { Fragment } from 'react';
import { Collapse } from 'antd';
const { Panel } = Collapse;

const FaqProject = () => {
  return (
    <Fragment>
      <Collapse>
        <Panel header="¿Cómo creo o edito mi perfil?" key="1">
          <p>
            Tanto como para crear o postular a un proyecto, lo primero que debes hacer es crear tu
            perfil y verificar tus datos personales. ¡Como freelancer tu perfil puede ser muy
            importante a la hora de postular a un proyecto! Te damos los siguientes tips:
          </p>
          <p>
            <ul>
              <li>
                ¡Trata de que tu perfil sea lo más completo posible! Es tu carta de presentación y
                debes tratar de llamar la atención de tu cliente para que confíe en tu experiencia y
                habilidades.
              </li>
              <li>
                Presenta la información de forma sencilla y clara, de esta forma, lograrás que quién
                visite tu perfil sepa lo que realmente quieres que sepa acerca de ti.
              </li>
              <li>
                Agrega tus habilidades para que encuentres los proyectos más relevantes para ti.
              </li>
              <li>No olvides mencionar tu experiencia y tus fortalezas.</li>
              <li>Agrega el link a tus cuentas de Github, Stackoverflow o tu portafolio</li>
            </ul>
          </p>
        </Panel>
        <Panel
          header="¿Necesito estar habilitado en el SII para poder trabajar como colaborador?"
          key="2">
          <p>
            Es recomendable que hayas iniciado actividades en el SII, ya que nosotros retendremos el
            pago de tu cliente y luego emitiremos una boleta de honorarios para pagar el servicio
            que hayas prestado (con la retención correspondiente que te será devuelta con la
            devolución anual de impuestos).
          </p>
          <p>
            Por lo tanto, si iniciaste actividades, podrás recibir tu retención correspondiente
            (12.25%) en la próxima devolución de impuestos. En caso contrario, que no hayas iniciado
            actividades, este porcentaje te será retenido hasta que inicies actividades.
          </p>
          <p>
            Por esto, te recomendamos haber iniciado actividades en el
            <a href="https://homer.sii.cl/" rel="noreferrer" target="_blank">
              {' '}
              SII{' '}
            </a>
            con anterioridad para poder trabajar como colaborador en un proyecto.
          </p>
        </Panel>
        <Panel header="¿Cómo busco un proyecto?" key="3">
          <p>
            Para optimizar la búsqueda de ofertas de trabajo que puedan interesarte, puedes utilizar
            los filtros disponibles a la izquierda de las publicaciones.
          </p>
        </Panel>
        <Panel header="¿Qué datos puedo poner en una postulación?" key="4">
          <p>
            Una vez que ingreses al detalle la oferta, puedes ver el perfil del dueño del proyecto
            (para que así puedas saber más sobre la persona que necesita de tus servicios). Esto, es
            un método para garantizar tu tranquilidad al momento de postular a un trabajo y que
            puedas conocer a tu futuro empleador. Además, cada proyecto dispone de un foro de
            preguntas, en donde podrás hacer todo tipo de preguntas respecto al proyecto, para así
            tener una mayor información sobre el alcance que busca la persona dueña del proyecto, de
            los plazos, etc. (Recuerda que está prohibido enviar números o información de contacto a
            través de
            <strong className="te-necesito"> TE NECESITO</strong>, para más información, leer
            <a href="#/terms&conditions"> Términos y Condiciones</a>.
          </p>
          <p>
            En caso de que quieras postular a trabajar en ese proyecto, debes seleccionar la opción
            <strong> Postular</strong>, lo cual desplegará un formulario en donde deberás llenar la
            información de postulación señalando por qué eres el indicado para el proyecto, tus
            condiciones, entre otras cosas.
          </p>
          <p>
            Es importante que cuando postules a un proyecto seas específico en detallar el alcance y
            los precios de tu propuesta. Además, debes seleccionar si deseas un pago único o pago
            por avances. Para mayor detalle, leer en preguntas generales:
            <strong> ¿Cuál es la diferencia entre un pago único o pago por etapas?</strong>.
          </p>
        </Panel>
        <Panel header="¿Puedo retirarme de una postulación?" key="5">
          <p>
            Si no has cerrado una negociación con tu cliente puedes retirarte de la postulación en
            el momento que tú lo desees. Es importante siempre dejarle claro al cliente las razones
            por la que declinarás la oferta, nos importa que tanto tú como él tengan una buena
            experiencia y comunicación en todo ámbito, y te servirá para tus futuras postulaciones
            quizás con él. ¡Quién sabe!
          </p>
        </Panel>
      </Collapse>
    </Fragment>
  );
};

export default FaqProject;
