import css from './PopUpWindow.module.scss';
import React from 'react';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

export const PopUpWindow = ({ open, onClose, children }) => {
  return (
    <Modal
      centered={true}
      visible={open}
      onCancel={onClose}
      footer={null}
      bodyStyle={{ padding: 0, borderRadius: '20px' }}
      style={{ borderRadius: '20px' }}
      closeIcon={<CloseOutlined style={{ color: '#FFFFFF' }} />}>
      <div className={css.blueBackground}></div>
      <div className={css.content}>{children}</div>
    </Modal>
  );
};
