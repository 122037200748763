import React from 'react';
import { BaseLayout } from '@components/common';
import { Breadcrumb } from 'antd';
import {
  FREELANCER_REQUESTS_ROUTE,
  FREELANCER_PROJECTS_ROUTE,
  CLIENT_PUBLICATIONS_ROUTE,
  CLIENT_PROJECTS_ROUTE,
  FREELANCER_PUBLICATIONS_ROUTE,
  BLOG_URL,
} from '@common/constants/routes.constants';

import { User, Briefcase, FileText, Code, Mail, FileMinus } from 'react-feather';

const breadcrumbTypes = {
  'client-project': {
    view: 'Cliente',
    name: 'Proyectos activos',
    icon: <Briefcase />,
    href: CLIENT_PROJECTS_ROUTE,
  },
  'client-publication': {
    view: 'Cliente',
    name: 'Publicaciones',
    icon: <FileText />,
    href: CLIENT_PUBLICATIONS_ROUTE,
  },
  'freelancer-project': {
    view: 'Freelancer',
    name: 'Proyectos activos',
    icon: <Briefcase />,
    href: FREELANCER_PROJECTS_ROUTE,
  },
  'freelancer-publication': {
    view: 'Freelancer',
    name: 'Servicios',
    icon: <Code />,
    href: FREELANCER_PUBLICATIONS_ROUTE,
  },
  'freelancer-request': {
    view: 'Freelancer',
    name: 'Postulaciones',
    icon: <Mail />,
    href: FREELANCER_REQUESTS_ROUTE,
  },
  blog: {
    name: 'Blog',
    icon: <FileMinus />,
    href: BLOG_URL,
  },
};

const CustomBreadcrumb = ({ type, title }) => {
  return (
    <BaseLayout.Margins>
      <Breadcrumb>
        {breadcrumbTypes[type]['view'] && (
          <Breadcrumb.Item>
            <User />
            {breadcrumbTypes[type]['view']}
          </Breadcrumb.Item>
        )}
        <Breadcrumb.Item>
          <a href={`#${breadcrumbTypes[type]['href']}`}>
            {breadcrumbTypes[type]['icon']}
            {breadcrumbTypes[type]['name']}
          </a>
        </Breadcrumb.Item>
        {title && <Breadcrumb.Item>{title}</Breadcrumb.Item>}
      </Breadcrumb>
    </BaseLayout.Margins>
  );
};

export default CustomBreadcrumb;
