import React from 'react';
import CommonGeneral from '../components/CommonGeneral';
import ActualFrameworkCommon from '../components/ActualFrameworkCommon';
import ImagesCommon from '../components/ImagesCommon';
import ErrorType from './ErrorType';
import css from './Error.module.scss';

function Error({ currentProject }) {
  return (
    <div className={css.container}>
      <div className={css.left}>
        <div className={css.general}>
          <CommonGeneral currentProject={currentProject} />
          <hr />
        </div>
        <div className={css.functionalities}>
          <ErrorType currentProject={currentProject} />
        </div>
      </div>
      <div className={css.right}>
        <div className={css.design}>
          <ActualFrameworkCommon currentProject={currentProject} />
        </div>
        <div className={css.tecnology}>
          <ImagesCommon currentProject={currentProject} />
        </div>
      </div>
    </div>
  );
}

export default Error;
