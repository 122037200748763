import React from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import css from './SocialShare.module.scss';
import { FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon } from 'react-share';
const SocialShare = ({ size = 30, quote, hashtags, ...rest }) => {
  return (
    <div style={{ display: 'flex', gap: '0.3em' }} {...rest}>
      <FacebookShareButton url={window.location.href} quote={quote} hashtag={hashtags[0]}>
        <FacebookIcon size={size} className={css.social} />
      </FacebookShareButton>
      <LinkedinShareButton url={window.location.href} title={quote}>
        <LinkedinIcon size={size} className={css.social} />
      </LinkedinShareButton>
      <TwitterShareButton url={window.location.href} title={quote} hashtags={hashtags}>
        <TwitterIcon size={size} className={css.social} />
      </TwitterShareButton>
      <WhatsappShareButton url={window.location.href}>
        <WhatsappIcon size={size} className={css.social} />
      </WhatsappShareButton>
    </div>
  );
};

export default SocialShare;
