import React, { Fragment } from 'react';
import {
  BaseLayout,
  EmptyListCard,
  InformativeAdvice,
  CustomBreadcrumb,
  Title,
} from '@components/common';
import { FreelancerPublicationsListViewModel } from './FreelancerPublicationsListViewModel';
import { LoadingDots } from '@components/common/LoadingDots';
import { ServicePublicationsListMainCard } from '@components/service/ServicePublicationsListMainCard/ServicePublicationsListMainCard';
import { PERSON_4_ILUSTRATION } from '@common/constants/informativeIlustrations.constants';
import css from './FreelancerPublications.module.scss';

const FreelancerPublicationsList = () => {
  const { isLoading, authUser, servicesList } = FreelancerPublicationsListViewModel();

  return (
    <Fragment>
      <CustomBreadcrumb type="freelancer-publication" />
      <BaseLayout>
        <BaseLayout.LeftSide>
          <div className={css.layoutContainer}>
            <div className="tn-layout__left-section">
              {' '}
              <Title type="secondary-bold" color="secondary">
                Tus Servicios publicados:{' '}
              </Title>
              <hr />
              <InformativeAdvice
                image={PERSON_4_ILUSTRATION}
                message="Acá puedes encontrar el listado de tus servicios publicados."
              />
            </div>
          </div>
        </BaseLayout.LeftSide>
        <BaseLayout.Content>
          {!isLoading ? (
            servicesList.length == 0 ? (
              <EmptyListCard type="no-service-publication" />
            ) : (
              servicesList.map((service, index) => {
                return (
                  <ServicePublicationsListMainCard
                    currentService={service}
                    authUser={authUser}
                    key={index}
                  />
                );
              })
            )
          ) : (
            <LoadingDots />
          )}
        </BaseLayout.Content>
      </BaseLayout>
    </Fragment>
  );
};
export default FreelancerPublicationsList;
