import config from '@config';

const BUCKETASSETS = config.assetsBucket;

export const HOWITWORKS_TOP_BACKGROUND = BUCKETASSETS + 'landing/Por+qu%C3%A9+contratar....png';
export const HOWITWORKS_LEFT_BANNER = BUCKETASSETS + 'HowItWorks/Service/Recurso+21-8.png';
export const HOWITWORKS_RIGHT_BANNER = BUCKETASSETS + 'HowItWorks/Service/Recurso+22-8.png';
export const HOWITWORKS_NUMBER5 = BUCKETASSETS + 'HowItWorks/Service/Recurso+28-8.png';
export const HOWITWORKS_NUMBER4 = BUCKETASSETS + 'HowItWorks/Service/Recurso+29-8.png';
export const HOWITWORKS_NUMBER3 = BUCKETASSETS + 'HowItWorks/Service/Recurso+30-8.png';
export const HOWITWORKS_NUMBER2 = BUCKETASSETS + 'HowItWorks/Service/Recurso+31-8.png';
export const HOWITWORKS_NUMBER1 = BUCKETASSETS + 'HowItWorks/Service/Recurso+32-8.png';

export const PROJECT_LEFT = BUCKETASSETS + 'HowItWorks/Project/Recurso+59-8.png';
export const PROJECT_RIGHT = BUCKETASSETS + 'HowItWorks/Project/Recurso+60-8.png';
export const SERVICE_LEFT = BUCKETASSETS + 'HowItWorks/Service/Recurso+33-8.png';
export const SERVICE_RIGHT = BUCKETASSETS + 'HowItWorks/Service/Recurso+34-8.png';
export const HOWITWORKS_LEFT_BANNER_BLUE = BUCKETASSETS + 'HowItWorks/Project/Recurso+68-8.png';
export const HOWITWORKS_RIGHT_BANNER_BLUE = BUCKETASSETS + 'HowItWorks/Project/Recurso+69-8.png';
export const HOWITWORKS_STEP1_PROJECT =
  BUCKETASSETS + 'HowItWorks/Project/como_funciona_trabajar_step_1.png';
export const HOWITWORKS_STEP2_PROJECT =
  BUCKETASSETS + 'HowItWorks/Project/como_funciona_trabajar_step_2.png';
export const HOWITWORKS_STEP3_PROJECT =
  BUCKETASSETS + 'HowItWorks/Project/como_funciona_trabajar_step_3.png';
export const HOWITWORKS_STEP4_PROJECT =
  BUCKETASSETS + 'HowItWorks/Project/como_funciona_trabajar_step_4.png';
export const HOWITWORKS_STEP5_PROJECT =
  BUCKETASSETS + 'HowItWorks/Project/como_funciona_trabajar_step_5.png';
export const HOWITWORKS_STEP1_SERVICE =
  BUCKETASSETS + 'HowItWorks/Service/como_funciona_contratar_step_1.png';
export const HOWITWORKS_STEP2_SERVICE =
  BUCKETASSETS + 'HowItWorks/Service/como_funciona_contratar_step_2.png';
export const HOWITWORKS_STEP3_SERVICE =
  BUCKETASSETS + 'HowItWorks/Service/como_funciona_contratar_step_3.png';
export const HOWITWORKS_STEP4_SERVICE =
  BUCKETASSETS + 'HowItWorks/Service/como_funciona_contratar_step_4.png';
export const HOWITWORKS_STEP5_SERVICE =
  BUCKETASSETS + 'HowItWorks/Service/como_funciona_contratar_step_5.png';

/* todos estos assets deberíamos meterlos en el bucket,
 no es óptimo que estén locales, de ahí debemos hacer algo al respecto */
export const HOWITWORKS_INITIAL_IMAGE1 =
  BUCKETASSETS + 'HowItWorks/como_funciona_tenecesito_main.png';
export const HOWITWORKS_INITIAL_IMAGE2 =
  BUCKETASSETS + 'HowItWorks/como_funciona_tenecesito_secondary_2.png';
export const HOWITWORKS_INITIAL_IMAGE3 =
  BUCKETASSETS + 'HowItWorks/como_funciona_tenecesito_secondary_1.png';

const projectList = [
  {
    image: HOWITWORKS_STEP1_PROJECT,
    text: '¡Empecemos!',
    subtitle1: '- Completa tu perfil',
    detail1: 'Cuéntanos de ti, sube una foto, describe tus habilidades y agrega tu portafolio.',
    subtitle2: '- Busca proyectos ideales para ti',
    detail2:
      'Revisa las ofertas de trabajo según tus habilidades y experiencia. Día a día hay nuevos proyectos que se pueden ajustar a tus expectativas y tarifas. Puedes navegar completamente gratuito.',
    reverse: false,
    numberImage: HOWITWORKS_NUMBER1,
    animationBanner: 'fade-right',
    animationContent: 'fade-left',
    placement: 'bottom-bottom',
  },
  {
    image: HOWITWORKS_STEP2_PROJECT,
    text: 'Postula y envía tu propuesta',
    subtitle1: '- Interactúa directo con tus clientes',
    detail1:
      'Lee atentamente los proyectos y haz las preguntas que consideres necesarias para la mejor propuesta posible. Hazle saber al cliente por qué eres la persona indicada para el trabajo.',
    subtitle2: '- Negocia tus tiempos y el precio',
    detail2:
      'Coordina con tu cliente en cuántas etapas desarrollarás el proyecto, a qué precio y los plazos de entrega. Trata de ser lo más detallado posible y de estimar bien los tiempos para poder cumplir. 👍',
    reverse: true,
    numberImage: HOWITWORKS_NUMBER2,
    animationBanner: 'fade-left',
    animationContent: 'fade-right',
    placement: 'bottom-bottom',
  },
  {
    image: HOWITWORKS_STEP3_PROJECT,
    text: 'Trabaja seguro, libre y conectado',
    subtitle1: '- Trabaja conectado desde donde quieras',
    detail1:
      'Comienza a trabajar remoto desde cualquier parte del mundo, en TeNecesito tienes un espacio de trabajo online para chatear con tu cliente, enviar archivos y organizar las entregas en un tablero Kanban.',
    subtitle2: '- Maneja tus horarios',
    detail2:
      'Organiza tus tiempos como más te acomode, hazte tus propios horarios y trabaja en un entorno privado y seguro. No olvides mantener una comunicación fluida con tu cliente y todo saldrá bien. 😉',
    reverse: false,
    numberImage: HOWITWORKS_NUMBER3,
    animationBanner: 'fade-right',
    animationContent: 'fade-left',
    placement: 'bottom-bottom',
  },
  {
    image: HOWITWORKS_STEP4_PROJECT,
    text: 'Entrega tu trabajo según lo acordado',
    subtitle1: '- Finaliza el proyecto',
    detail1:
      'Realiza las últimas entregas a través de TeNecesito y ¡listo!. Puedes trabajar en cuántos proyectos quieras al mismo tiempo. 😃',
    subtitle2: '- Evalúa a tu cliente',
    detail2:
      'Porque en TeNecesito nos interesa el bienestar de nuestros freelancers, evalúa tus clientes para ir mejorando la comunidad entre todos y nos entregas una idea de cómo fue el resultado del proyecto.',
    reverse: true,
    numberImage: HOWITWORKS_NUMBER4,
    animationBanner: 'fade-left',
    animationContent: 'fade-right',
    placement: 'bottom-bottom',
  },
  {
    image: HOWITWORKS_STEP5_PROJECT,
    text: '¡Recibe tu pago a tiempo!',
    subtitle1: '- Nosotros nos encargamos',
    detail1:
      'Ya no te tienes que preocupar de cobrar a tus clientes. TeNecesito protege tu trabajo y el cliente ya depositó el dinero en nuestra cuenta cuando cerraron el presupuesto. Terminas tu trabajo y recibes tu pago en máximo 72 horas.',
    reverse: false,
    numberImage: HOWITWORKS_NUMBER5,
    animationBanner: 'fade-right',
    animationContent: 'fade-left',
    placement: 'bottom-bottom',
  },
];
const serviceList = [
  {
    image: HOWITWORKS_STEP1_SERVICE,
    text: '¡Empecemos con tu proyecto!',
    subtitle1: '- Publica lo que necesitas gratis',
    detail1:
      'Describe tu proyecto en sencillos pasos y comienza a recibir propuestas. Puedes hacer tu página web, programar una aplicación, consolidar tus bases de datos y muchas cosas más. Al mismo tiempo, puedes revisar los freelancers disponibles e invitarlos a conocer tu publicación.',
    subtitle2: '- Completa tu perfil',
    detail2:
      'Cuéntanos de ti, sube una foto y entrégale a los freelancers una idea de quien eres. 🙂',
    reverse: false,
    numberImage: HOWITWORKS_NUMBER1,
    animationBanner: 'fade-right',
    animationContent: 'fade-left',
    placement: 'bottom-bottom',
  },
  {
    image: HOWITWORKS_STEP2_SERVICE,
    text: 'Recibe propuestas, compara y elige al freelancer ideal',
    subtitle1: '- Revisa los perfiles',
    detail1:
      'Analiza los distintos postulantes, quiénes son, sus portafolios y calificaciones de trabajos anteriores. Cada uno de ellos tiene su identidad verificada en TeNecesito.',
    subtitle2: '- Compara y chatea en tiempo real',
    detail2:
      'Tu decides con quién quieres trabajar. Puedes conversar con los candidatos, comparar sus propuestas y decidir cuánto quieres pagar. 😉',
    reverse: true,
    numberImage: HOWITWORKS_NUMBER2,
    animationBanner: 'fade-left',
    animationContent: 'fade-right',
    placement: 'bottom-bottom',
  },
  {
    image: HOWITWORKS_STEP3_SERVICE,
    text: 'Paga seguro con TeNecesito',
    subtitle1: '- Seguridad y confianza',
    detail1:
      'Nosotros resguardamos tu dinero y lo mantenemos seguro. No te arriesgas pagando anticipos, pagas por resultados. 👍',
    subtitle2: '- Libera los fondos cuándo estés satisfecho',
    detail2:
      'Tú eres quien decide cuando pagarle al freelancer. Puede ser por etapas o cuando el trabajo esté completamente terminado. Tú tienes el control.',
    reverse: false,
    numberImage: HOWITWORKS_NUMBER3,
    animationBanner: 'fade-right',
    animationContent: 'fade-left',
    placement: 'bottom-bottom',
  },
  {
    image: HOWITWORKS_STEP4_SERVICE,
    text: '¡Relajate! Tu proyecto ya está en camino',
    subtitle1: '- El freelancer ya comenzó a trabajar',
    detail1: 'De forma remota, según los tiempos y presupuesto que acordaron.',
    subtitle2: '- Mantente en contacto y sigue su proceso',
    detail2:
      'A través de TeNecesito podrás estar en control de tu proyecto. Chatea con tu freelancer, comparte archivos y monitorea los avances con un tablero Kanban.',
    subtitle3: '- Estamos siempre disponibles',
    detail3:
      'Recuerda que nosotros te acompañamos durante todo el proceso, desde antes de la publicación hasta la entrega de tu trabajo terminado. Contáctanos a través de cualquiera de nuestros canales, estamos para ti. 🙂',
    reverse: true,
    numberImage: HOWITWORKS_NUMBER4,
    animationBanner: 'fade-left',
    animationContent: 'fade-right',
    placement: 'bottom-bottom',
  },
  {
    image: HOWITWORKS_STEP5_SERVICE,
    text: 'Recibe tu proyecto terminado, sin trámites y a tiempo',
    subtitle1: '- Verifica tu trabajo y libera el pago',
    detail1:
      'Revisa y confirma que el proyecto fue terminado según lo acordado. Una vez satisfecho, da el OK y libera el pago al freelancer.',
    subtitle2: '- Evalúa y califica',
    detail2:
      'Deja una breve evaluación del freelancer en relación a tu experiencia con él. Así vamos mejorando la comunidad entre todos.',
    reverse: false,
    numberImage: HOWITWORKS_NUMBER5,
    animationBanner: 'fade-right',
    animationContent: 'fade-left',
    placement: 'bottom-bottom',
  },
];

const iconsHireList = [
  {
    image: 'faBusinessTime',
    text: 'Ahorra tiempo y costos de contratación',
  },
  {
    image: 'faAddressCard',
    text: '¡Tú decides! Compara y elige con quién quieres trabajar',
  },
  {
    image: 'faVault',
    text: 'No te arriesgues, libera el pago cuando estés satisfecho',
  },
];
const iconsServiceList = [
  {
    image: 'faListCheck',
    text: 'Trabaja en distintos proyectos y sin trámites',
  },
  {
    image: 'faHourglassHalf',
    text: 'Manjea tus propios horarios y reduce costos',
  },
  {
    image: 'faPiggyBank',
    text: 'Asegura el pago justo y al instante',
  },
];

export const HowItWorksData = {
  false: {
    list: projectList,
    title: '¿CÓMO TRABAJAR?',
    images: iconsServiceList,
  },
  true: {
    list: serviceList,
    title: '¿CÓMO CONTRATAR?',
    images: iconsHireList,
  },
};
