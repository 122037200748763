import {
  OPEN_LEFT_WINDOW,
  CLOSE_LEFT_WINDOW,
  OPEN_RIGHT_WINDOW,
  CLOSE_RIGHT_WINDOW,
} from '../actions/types.js';

const initialState = {
  leftWindowOpened: false,
  rightWindowOpened: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case OPEN_LEFT_WINDOW:
      return {
        ...state,
        leftWindowOpened: true,
      };
    case CLOSE_LEFT_WINDOW:
      return {
        ...state,
        leftWindowOpened: false,
      };
    case OPEN_RIGHT_WINDOW:
      return {
        ...state,
        rightWindowOpened: true,
      };
    case CLOSE_RIGHT_WINDOW:
      return {
        ...state,
        rightWindowOpened: false,
      };
    default:
      return state;
  }
}
