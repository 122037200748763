import React from 'react';
import { Steps } from 'antd';
import { projectCurrentStep, projectCurrentStage } from '@common/utils/ProjectUtils';
import './CustomSteps.scss';
const { Step } = Steps;

const CustomSteps = ({ status, type = 'postulacion', stages, direction = 'vertical' }) => {
  const [currentStep, setCurrentStep] = React.useState(projectCurrentStep(status));
  const [stepList, setStepList] = React.useState([]);

  React.useEffect(() => {
    if (type == 'postulacion') {
      setStepList(['Postulación proyecto', 'Negociación', 'Validando pago', 'Inicio del Proyecto']);
    } else if (type == 'publicacion') {
      setStepList(['Publicación proyecto', 'Negociación', 'Validando pago', 'Inicio del Proyecto']);
    } else if (type == 'misProyectos') {
      const stage_sorted = stages?.sort((a, b) => (a.order > b.order ? 1 : -1));
      const stage_list = ['Inicio'];
      stage_sorted?.map((stage) => {
        stage_list.push(stage.name);
      });
      stage_list.push('Fin');

      const { currentOrder } = projectCurrentStage(stage_sorted);
      setCurrentStep(currentOrder + 1);
      setStepList(stage_list);
    }
  }, []);

  return (
    <div className="custom-steps__container">
      {' '}
      <Steps
        progressDot
        current={currentStep}
        direction={direction}
        size="small"
        labelPlacement="vertical">
        {stepList.map((step, index) => {
          return <Step title={step} key={index} />;
        })}
      </Steps>
    </div>
  );
};

export default CustomSteps;
