/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import css from './ChatWidget.module.scss';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Close, Videocam } from '@material-ui/icons';
import { FileIcon } from '../FileIcon/FileIcon';
import RenderIf from '../RenderIf';
import UploadClip from '../NewChat/UploadClip';
import VideoCallModal from '../NewChat/VideoCallModal';
import useSingleChat from './hooks/useSingleChat';

import { subStr } from 'common/utils/Formaters';
import { CircularProgress } from '@material-ui/core';

const SingleChat = ({ chat, opened = true, handleRemoveChat }) => {
  const {
    messageHistory,
    onSend,
    isOwnerOfMessage,
    stringMessageToArray,
    onUpload,
    videoModal,
    setVideoModal,
    handleSubmitVideoCall,
    onChatScroll,
    message,
    setMessage,
    handleRedirectToProject,
    isLoading,
  } = useSingleChat({ chat, containerId: `widget-chat-${chat?.id}` });

  const [openChat, setOpenChat] = useState(opened);

  return (
    <div>
      <VideoCallModal
        toggle={videoModal}
        setToggle={setVideoModal}
        handleSubmitVideoCall={handleSubmitVideoCall}
      />
      {openChat ? (
        <div>
          <div className={css.chat_header}>
            <div className={css.chat_header_title}>
              <img src={chat?.profile_pic} alt="profile" />
              <div>
                <span>{subStr(chat?.user_name, 20)}</span>
                <span
                  className={css.underline_hover}
                  style={{ display: 'block', fontSize: 12 }}
                  onClick={handleRedirectToProject}>
                  {subStr(chat?.title, 25)}
                </span>
              </div>
            </div>
            <div>
              <ChevronDown onClick={() => setOpenChat(false)} style={{ width: 28, height: 28 }} />
              <Close onClick={handleRemoveChat} />
            </div>
          </div>
          <div
            style={{ height: 320 }}
            className={css.chat_content}
            id={`widget-chat-${chat?.id}`}
            onScroll={onChatScroll}>
            <div>
              {isLoading ? (
                <div style={{ textAlign: 'center' }}>
                  <CircularProgress style={{ color: '#3383ff' }} />
                </div>
              ) : (
                <>
                  {messageHistory?.map((msg, idx) => (
                    <div
                      key={idx}
                      className={
                        isOwnerOfMessage(msg)
                          ? css.message_sent_container
                          : css.message_received_container
                      }>
                      <RenderIf isTrue={msg?.file_id}>
                        <FileIcon fileData={msg?.attached_file} fromChat />
                      </RenderIf>
                      <RenderIf isTrue={!msg?.file_id}>
                        {stringMessageToArray(msg?.content).map((message, idx) => (
                          <span
                            className={
                              isOwnerOfMessage(msg) ? css.message_sent : css.message_received
                            }
                            key={idx}>
                            {message}
                          </span>
                        ))}
                      </RenderIf>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          <div className={css.chat_search}>
            <input
              type="text"
              placeholder="Escribe un mensaje..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && e.shiftKey) {
                  setMessage((prev) => `${prev}\n`);
                }
                if (e.key === 'Enter') {
                  onSend(message);
                  setMessage('');
                }
              }}
            />
            <div className={css.chat_search_items}>
              <UploadClip
                style={{ height: 20, cursor: 'pointer' }}
                onUpload={onUpload}
                id="chat-file"
              />
              <Videocam style={{ cursor: 'pointer' }} onClick={() => setVideoModal(true)} />
            </div>
          </div>
        </div>
      ) : (
        <div className={css.chat_header} onClick={() => setOpenChat(true)}>
          <div className={css.chat_header_title}>
            <img src={chat?.profile_pic} alt="profile" />
            <div>
              <span>{chat?.user_name}</span>
              <span style={{ display: 'block', fontSize: 12, maxWidth: 320, overflow: 'hidden' }}>
                {subStr(chat?.title, 30)}
              </span>
            </div>
          </div>
          <ChevronUp style={{ width: 28, height: 28 }} />
        </div>
      )}
    </div>
  );
};

export default SingleChat;
