import React from 'react';
import classNames from 'classnames';
import './Text.scss';

/*
This is a Text component to handle the different types of text inside the app

type: primary, regular or detail
*/

const Text = ({
  type = 'regular',
  strong = false,
  spaced = false,
  italic = false,
  justify = false,
  centered = false,
  color = false,
  className,
  children,
  size,
  style,
  ...rest
}) => {
  const textClass = classNames(
    'tn-text',
    `tn-text--${type}`,
    strong && 'tn-text--strong',
    italic && 'tn-text--italic',
    spaced && 'tn-text--spaced',
    centered && 'tn-text--centered',
    justify && 'tn-text--justify',
    className
  );
  return (
    <p
      style={{ fontSize: `${size}px`, color: `${color}`, ...style }}
      className={textClass}
      {...rest}>
      {children}
    </p>
  );
};

export default Text;
