import { getPaymentData } from 'actions/requests';
import React from 'react';

const usePaymentData = () => {
  const [commission, setCommission] = React.useState(0);

  React.useEffect(() => {
    getPaymentData().then(({ data }) => setCommission(data?.COLLABORATOR_COMISSION));
  }, []);

  return { commission, setCommission };
};

export default usePaymentData;
