/* eslint-disable no-unused-vars */
import React from 'react';
import css from './BlogHeader.module.scss';
import { PostCard } from '../PostCard';
import { BLOG_IMAGE_BLUE_URL } from '../../Blog.constants';
import { SwitchFilter } from '../SwitchFilter';

function BlogHeader({
  primaryBlog,
  secondaryBlog,
  tertiaryBlog,
  handleClickBlog,
  blogCategories,
  categoryFilter,
  onChangeCategoryFilter,
}) {
  return (
    <div className={css.container}>
      <SwitchFilter
        blogCategories={blogCategories}
        categoryFilter={categoryFilter}
        onChangeCategoryFilter={onChangeCategoryFilter}
      />
      <div className={css.mainPosts}>
        <div className={css.primaryPost}>
          {primaryBlog && (
            <PostCard post={primaryBlog} handleClickBlog={handleClickBlog} primary={true} />
          )}
        </div>
        <div className={css.secondaryPosts}>
          {secondaryBlog && <PostCard post={secondaryBlog} handleClickBlog={handleClickBlog} />}
          {tertiaryBlog && <PostCard post={tertiaryBlog} handleClickBlog={handleClickBlog} />}
        </div>
      </div>
    </div>
  );
}

export default BlogHeader;
