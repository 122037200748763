/* eslint-disable no-unused-vars */
import { generateFormTracker, sendAskToFormTracker } from 'actions/forms';
import { getProjectId } from 'actions/projects';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthUserInfo } from 'reducers/auth';
import useQueryParams from '../../../hooks/useQueryParams';

const useNewForms = ({
  questions,
  isLoadingQuestions,
  allQuestions,
  setQuestions,
  categoriesKey,
  type,
}) => {
  const user = useSelector(selectAuthUserInfo);
  const [update, setUpdate] = useState(false);
  const [index, setIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [formTrackerId, setFormTrackerId] = useState(null);
  const [isLoadingAnswers, setIsLoadingAnswers] = useState(false);
  const [currentFlow, setCurrentFlow] = useState([]);
  const [currentOptionsCategories, setCurrentOptionsCategories] = useState([]);

  const query = useQueryParams();
  const action = query.get('action');
  const id = query.get('id');
  const isEdit = action === 'edit' && id;

  const currentQuestionAnswer = answers?.find((a) => a.id === questions[index].id);

  useEffect(() => {
    if (!isEdit && user) {
      generateFormTracker(type).then((res) => setFormTrackerId(res?.data?.form_tracker.id));
    }
  }, [user]);

  useEffect(() => {
    if (isEdit && !isLoadingQuestions) {
      setIsLoadingAnswers(true);
      getProjectId(id).then((res) => projectAdapterFromApiToUi(res?.data));
    }
  }, [id, isLoadingQuestions]);

  useEffect(() => {
    if (!isEdit) {
      const newAnswers = answers.filter((a, i) => i <= index);
      setAnswers(newAnswers);
    }

    const currentFlowQuestions = getCurrentFlowQuestions();
    if (currentOptionsCategories.length > 0) {
      setQuestions(() => {
        return getFilteredCategoriesOptions(currentFlowQuestions);
      });
    } else {
      setQuestions(currentFlowQuestions);
    }

    const names = currentFlow?.map((f) => f.name);
    if (names?.includes('Integraciones-01') || names?.includes('Integraciones-04')) {
      setCurrentOptionsCategories((prev) => [
        ...prev,
        'Redes Sociales',
        'Pasarelas de pago',
        'Despachos y envíos',
        'Marketing y SEO',
        'Marketplace',
        'Otros',
      ]);
    }
  }, [currentFlow]);

  useEffect(() => {
    const currentFlowQuestions = getCurrentFlowQuestions();
    setQuestions(() => {
      return getFilteredCategoriesOptions(currentFlowQuestions);
    });
  }, [currentOptionsCategories]);

  const getCurrentFlowQuestions = () => {
    if (currentFlow.length === 0) return allQuestions;
    const flowsNames = currentFlow.map((f) => f.name);
    return allQuestions.filter((q) => {
      if (q?.flows?.length === 0) {
        return true;
      }
      const questionFlowWithCurrentFlow = q?.flows?.filter((f) => flowsNames.includes(f));
      return questionFlowWithCurrentFlow.length === flowsNames.length;
    });
  };
  const getFilteredCategoriesOptions = (questions) => {
    return questions.map((q) => ({
      ...q,
      options: q.options.filter((o) => {
        if (q?.allCategories) return true;
        if (o?.[categoriesKey]?.length === 0) return true;

        const optionIncludesCategory = o?.[categoriesKey]?.filter((c) => {
          return currentOptionsCategories.includes(c);
        });
        return optionIncludesCategory.length > 0;
      }),
    }));
  };
  const palletesAdapterFromApiToUi = (res) => {
    return res.data.data.map((p) => ({
      id: p?.id,
      name: p?.attributes?.name,
      img: p?.attributes?.img?.data?.attributes?.url,
    }));
  };
  const formAdapterFromApiToUi = (res) => {
    return res.data.data.attributes.questions.data
      .map((q) => ({
        id: q.id,
        ...q.attributes,
        flows: q?.attributes?.flows?.data?.map((f) => f?.attributes?.name),
        options: q?.attributes?.options?.data
          ?.map((o) => ({
            id: o.id,
            ...o.attributes,
            flowName: o?.attributes?.flow_value.data?.attributes?.name,
            img: o?.attributes?.img?.data?.attributes?.url,
            categories: o?.attributes?.categories?.data?.map((c) => c?.attributes?.name),
            integration_categories: o?.attributes?.integration_categories?.data?.map(
              (c) => c?.attributes?.name
            ),
          }))
          .sort((a, b) => a?.order - b?.order),
        question_type: q.attributes.question_type.data.attributes.name,
        selects: q?.attributes?.selects?.split('\n'),
      }))
      .sort((a, b) => a?.order - b?.order);
  };
  const projectAdapterFromApiToUi = (project) => {
    const returns = {
      select: setFromProjectDataSelect,
      templateCategory: setFromProjectDataTemplateCategory,
      unique: setFromProjectDataUnique,
      textfield: setFromProjectDataTextField,
      multiple: setFromProjectDataMultiple,
      template: setFromProjectDataTemplate,
      palette: setFromProjectDataPalette,
      url: setFromProjectDataURL,
      images: setFromProjectDataImages,
      uniqueUrl: setFromProjectDataUniqueUrl,
      flow: setFromProjectDataFlow,
      multipleOptionCategory: setFromProjectDataMultipleOptionCategory,
      uniqueOptionCategory: setFromProjectDataUniqueOptionCategory,
    };

    for (const question of questions) {
      returns[question?.question_type](project, question);
    }
    setIsLoadingAnswers(false);
  };
  const setFromProjectDataFlow = (project, question) => {
    const option = question.options.find((o) => o.value === project[question.key]);
    const questionId = question.id;
    const flow = { questionId, name: option?.flowName };
    setCurrentFlow((prev) => [...prev, flow]);
    handleSetSimple(question, [option]);
  };
  const setFromProjectDataMultipleOptionCategory = (project, question) => {
    const option = question.options.find((o) => o.value === project[question.key]);
    setCurrentOptionsCategories((prev) => {
      if (prev.includes(option.value)) {
        return prev.filter((o) => o !== option.value);
      } else {
        return [...prev, option.value];
      }
    });
    setFromProjectDataMultiple(project, question);
  };
  const setFromProjectDataUniqueOptionCategory = (project, question) => {
    const option = question.options.find((o) => o.value === project[question.key]);
    setCurrentOptionsCategories((prev) => [...prev, option?.value]);
    setFromProjectDataUnique(project, question);
  };
  const setFromProjectDataUniqueUrl = (project, question) => {
    const value = project[question.key];
    handleSetSimple(question, [{ value, label: value }]);
  };
  const setFromProjectDataSelect = (project, question) => {
    let option = { value: project[question.key], label: project[question.key] };
    handleSetSimple(question, [option]);
  };
  const setFromProjectDataTemplateCategory = (project, question) => {
    setSelectedCategory(project[question.key]);
    let option = { value: project[question.key], label: project[question.key] };
    handleSetSimple(question, [option]);
  };
  const setFromProjectDataUnique = (project, question) => {
    console.log({ project, question });
    const findOption = question.options.find((o) => o.value === project[question.key]);

    if (findOption) {
      let option = { value: project[question.key], label: project[question.key] };
      handleSetSimple(question, [option]);
    } else {
      let option = { value: project[question.key], label: 'Otro' };
      handleSetSimple(question, [option]);
    }
  };
  const setFromProjectDataTextField = (project, question) => {
    let option = project[question.key];
    handleSetSimple(question, [option]);
  };
  const setFromProjectDataTemplate = (project, question) => {
    if (project.is_uploaded_template) {
      let option = { value: 'custom', img: project.template_url, edit: true };
      handleSetSimple({ ...question, question_type: 'templateCustom' }, [option]);
    } else {
      let option = { value: project.template_url, img: project.template_url };
      handleSetSimple(question, [option]);
    }
  };
  const setFromProjectDataPalette = (project, question) => {
    if (project.is_uploaded_pallette) {
      let option = { value: 'custom', img: project.pallette_url, edit: true };
      handleSetSimple({ ...question, question_type: 'paletteCustom' }, [option]);
    } else {
      let option = { value: project.pallette_url, img: project.pallette_url };
      handleSetSimple(question, [option]);
    }
  };
  const setFromProjectDataImages = (project, question) => {
    const imagesUrls = project?.project_files?.map((file) => file?.upload);
    setAnswers((prev) => [
      ...prev,
      {
        id: question.id,
        type: question.question_type,
        key: question.key,
        isEdit: true,
        answers: [imagesUrls],
      },
    ]);
  };
  const setFromProjectDataURL = (project, question) => {
    let urls = {};
    project?.reference_urls?.forEach((url, index) => {
      urls[`url${index + 1}`] = url;
    });
    handleSetSimple(question, [urls]);
  };
  const setFromProjectDataMultiple = (project, question) => {
    if (question.key === 'category') {
      let options = project.categories_names?.map((o) => ({ value: o, label: o }));
      handleSetSimple(question, options);
    } else {
      let options = project[question.key]?.map((o) => {
        const findOption = question.options.find((x) => x.value === o);
        if (findOption) {
          return { label: o, value: o };
        } else {
          return { label: 'Otro', value: o };
        }
      });
      handleSetSimple(question, options);
    }
  };
  const handleSetSimple = (question, option) => {
    setAnswers((prev) => [
      ...prev,
      {
        id: question.id,
        type: question.question_type,
        key: question.key,
        answers: option,
      },
    ]);
  };
  const handleNext = async () => {
    if (index + 1 === questions.length) return;
    if (!isEdit) {
      const returns = {
        textfield: [currentQuestionAnswer?.answers?.[0]],
        multiple: currentQuestionAnswer?.answers?.map((a) => a?.value),
        palette: [currentQuestionAnswer?.answers?.[0]?.img],
        template: [currentQuestionAnswer?.answers?.[0]?.img],
        templateCustom: ['custom template'],
        paletteCustom: ['custom image'],
        url: [
          currentQuestionAnswer?.answers?.[0]?.url1,
          currentQuestionAnswer?.answers?.[0]?.url2,
          currentQuestionAnswer?.answers?.[0]?.url3,
        ].filter((u) => u),
      };
      if (user && formTrackerId) {
        const ask = {
          form: formTrackerId,
          question: questions[index]?.title,
          description: questions[index]?.description,
          options: returns[currentQuestionAnswer?.type] || [
            currentQuestionAnswer?.answers?.[0]?.value,
          ],
        };
        try {
          await sendAskToFormTracker(ask);
        } catch (err) {
          console.log(err);
        }
      }
    }

    if (!answers[index]) {
      answers[index] = questions[index];
    }

    setIndex((prev) => prev + 1);
    setUpdate(!update);
    window.scrollTo(0, 0);
  };
  const handleBack = () => {
    if (index === 0) return;
    setIndex((prev) => prev - 1);
    setUpdate(!update);
    window.scrollTo(0, 0);
  };
  const handleSetTextField = (option, type) => {
    const questionId = questions[index]?.id;
    if (findCurrentAnswer()) {
      setAnswers((prev) =>
        prev.map((answer) => {
          if (answer.id === questionId) {
            return { id: questionId, type, key: questions[index]?.key, answers: [option] };
          } else {
            return answer;
          }
        })
      );
    } else {
      setAnswers((prev) => [
        ...prev,
        { id: questionId, type, key: questions[index]?.key, answers: [option] },
      ]);
    }
  };
  const handleSetMultipleOptions = (option, type, action) => {
    const questionId = questions[index]?.id;

    if (type === 'multipleOptionCategory') {
      setCurrentOptionsCategories((prev) => {
        if (prev.includes(option.value)) {
          return prev.filter((o) => o !== option.value);
        } else {
          return [...prev, option.value];
        }
      });
    }

    if (findCurrentAnswer()) {
      setAnswers((prev) =>
        prev.map((answer) => {
          if (answer.id === questionId) {
            if (
              answer?.answers?.find((opt) => opt.value === option.value) ||
              answer?.answers?.find((opt) => opt.label === option.label)
            ) {
              if (action === 'replace') {
                return {
                  id: questionId,
                  type,
                  key: questions[index]?.key,
                  answers: answer.answers.map((opt) => (opt.label === option.label ? option : opt)),
                };
              } else {
                return {
                  id: questionId,
                  type,
                  key: questions[index]?.key,
                  answers: answer.answers.filter((opt) => opt.value !== option.value),
                };
              }
            } else {
              return {
                id: questionId,
                type,
                key: questions[index]?.key,
                answers: [...(answer?.answers || []), option],
              };
            }
          } else {
            return answer;
          }
        })
      );
    } else {
      setAnswers((prev) => [
        ...prev,
        { id: questionId, type, key: questions[index]?.key, answers: [option] },
      ]);
    }
  };
  const handleSetUniqueOption = (option, type, action) => {
    const questionId = questions[index]?.id;

    if (action === 'remove') {
      setAnswers((prev) => prev.filter((answer) => answer.id !== questionId));
      return;
    }

    if (type === 'flow') {
      const flow = { questionId, name: option?.flowName };
      setCurrentFlow((prev) => {
        const find = prev.find((f) => f.questionId === questionId);
        if (find) {
          return prev.map((f) => (f.questionId === questionId ? flow : f));
        }
        return [...prev, flow];
      });
    }

    if (type === 'uniqueOptionCategory') {
      const currentQuestionOptionsCategoriesNames = questions[index]?.options?.map((o) => o?.value);
      const alreadyHasCategoryOfThisQuestion = currentQuestionOptionsCategoriesNames.includes(
        option.value
      );
      const currentOptionIsSelectedCategory = currentOptionsCategories.includes(option.value);

      if (alreadyHasCategoryOfThisQuestion && !currentOptionIsSelectedCategory) {
        const categoryOfThisQuestion = currentOptionsCategories.find((c) =>
          currentQuestionOptionsCategoriesNames.includes(c)
        );
        setCurrentOptionsCategories((prev) => [
          ...prev.filter((c) => c !== categoryOfThisQuestion),
          option.value,
        ]);
      }
    }

    if (findCurrentAnswer()) {
      setAnswers((prev) =>
        prev.map((answer) =>
          answer.id === questionId
            ? { id: questionId, type, key: questions[index]?.key, answers: [option] }
            : answer
        )
      );
    } else {
      setAnswers((prev) => [
        ...prev,
        { id: questionId, type, key: questions[index]?.key, answers: [option] },
      ]);
    }
  };
  const findCurrentAnswer = () => {
    const questionId = questions[index]?.id;
    return answers.find((answer) => answer.id === questionId);
  };
  const isOptionSelected = (opt) => {
    return findCurrentAnswer()?.answers?.find((o) => o?.value === opt?.value);
  };
  const hasOptionSelected = () => {
    return findCurrentAnswer()?.answers?.length > 0;
  };
  const handleSetAnswer = (option, type, action) => {
    const returns = {
      multiple: handleSetMultipleOptions,
      textfield: handleSetTextField,
      unique: handleSetUniqueOption,
      url: handleSetUniqueOption,
      select: handleSetUniqueOption,
      template: handleSetUniqueOption,
      templateCategory: handleSetUniqueOption,
      templateCustom: handleSetUniqueOption,
      palette: handleSetUniqueOption,
      paletteCustom: handleSetUniqueOption,
      flow: handleSetUniqueOption,
      multipleOptionCategory: handleSetMultipleOptions,
      uniqueOptionCategory: handleSetUniqueOption,
      images: handleSetUniqueOption,
      uniqueUrl: handleSetUniqueOption,
    };

    returns[type](option, type, action);
  };
  const canGoNext = () => {
    return hasOptionSelected() || questions[index]?.isOptional;
  };

  const answersReturns = (answer) => {
    const types = {
      palette: answer?.answers?.[0]?.img,
      template: answer?.answers?.[0]?.img,
      templateCustom: answer?.answers?.[0]?.img,
      paletteCustom: answer?.answers?.[0]?.img,
      textfield: answer?.answers?.[0],
      multiple: answer?.answers?.map((a) => a?.value),
      multipleOptionCategory: answer?.answers?.map((a) => a?.value),
      url: [
        answer?.answers?.[0]?.url1,
        answer?.answers?.[0]?.url2,
        answer?.answers?.[0]?.url3,
      ].filter((u) => u),
    };

    return types[answer?.type] || answer?.answers?.[0]?.value;
  };

  const parseAnswersToPromptForFreelancers = () => {
    const questionWithStringAnswers = answers.map((answer) => {
      const questionId = answer?.id;
      const question = questions.find((q) => q?.id === questionId);

      let answersString = answersReturns(answer);

      if (Array.isArray(answersString)) {
        answersString = answersString.join(', ');
      }

      return {
        title: question?.title,
        answer: answersString,
      };
    });

    let prompt = '\n';

    questionWithStringAnswers.forEach((question) => {
      prompt += `Pregunta: [${question.title}]\n Respuesta:[${question.answer}]\n`;
    });

    return prompt;
  };

  return {
    handleSetAnswer,
    handleNext,
    handleBack,
    canGoNext,
    isOptionSelected,
    hasOptionSelected,
    update,
    index,
    questions,
    answers,
    setAnswers,
    isEdit,
    formTrackerId,
    user,
    selectedCategory,
    setSelectedCategory,
    id,
    palletesAdapterFromApiToUi,
    formAdapterFromApiToUi,
    isLoadingAnswers,
    currentFlow,
    setCurrentFlow,
    currentOptionsCategories,
    setCurrentOptionsCategories,
    getCurrentFlowQuestions,
    currentQuestionAnswer,
    answersReturns,
    parseAnswersToPromptForFreelancers,
  };
};

export default useNewForms;
