import React, { Fragment } from 'react';
import './BaseFormLayout.scss';

export function BaseFormLayout(props) {
  return (
    <Fragment>
      <div className="custom-container baseform-layout-container">
        <div className="svg-screen-cover-left"></div>
        <div className="basic-container baseform-layout-content baseform-body">
          {props.children}
        </div>
        <div className="svg-screen-cover-right"></div>
      </div>
    </Fragment>
  );
}
