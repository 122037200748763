/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import './EditRequestDrawer.scss';
import { PopUpWindow, Text, Title } from '@components/common';

import DiscountPanel from '@components/discountCodes/DiscountPanel/DiscountPanel';
import { COLLABORATOR_COMISSION } from '@common/constants/payment.constants';

import { RequestForm } from './RequestForm/RequestForm';
import usePaymentData from 'hooks/usePaymentData';
import { ViewRequestBreakdown } from '../ViewRequestDrawer/ViewRequestBreakdown/ViewRequestBreakdown';

export function Request({
  currentProject,
  authUser,
  currentRequest,
  currentProposal,
  isLoading,
  withHeader = true,
  withProjectTitle = true,
  editable = true,
}) {
  const [showDiscountWindow, setShowDiscountWindow] = React.useState(false);
  // const [commission, setCommission] = React.useState(COLLABORATOR_COMISSION);
  const { commission, setCommission } = usePaymentData();
  const [discountCode, setDiscountCode] = React.useState(false);

  return (
    <Fragment>
      <PopUpWindow open={showDiscountWindow} onClose={() => setShowDiscountWindow(false)}>
        <DiscountPanel
          setShowDiscountWindow={setShowDiscountWindow}
          setCommission={setCommission}
          currentProject={currentProject}
          setDiscountCode={setDiscountCode}
        />
      </PopUpWindow>
      {editable ? (
        <div className="request__container">
          {withHeader && <div className="request__blue-background"></div>}
          {withProjectTitle && (
            <div className="request__header">
              <Text type="boldDetail">Propuesta: </Text>
              <Title type="secondary-bold" color="primary">
                {currentProject?.title}
              </Title>
              <hr />
            </div>
          )}

          <div className="requ est__content">
            <div className="request__form-scroll">
              {' '}
              {!isLoading ? (
                <RequestForm
                  currentProject={currentProject}
                  currentRequest={currentRequest}
                  currentProposal={currentProposal}
                  authUser={authUser}
                  showDiscountWindow={showDiscountWindow}
                  setShowDiscountWindow={setShowDiscountWindow}
                  commission={commission}
                  setCommission={setCommission}
                  isLoading={isLoading}
                  discountCode={discountCode}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="view-request-drawer__container">
          <div className="view-request-drawer__content">
            <Text type="boldDetail">Propuesta: </Text>
            <Title type="tertiary" color="primary">
              ¿Por qué eres la persona indicada para este trabajo?
            </Title>
            <Text>{currentRequest?.why_you}</Text>
            <Title type="tertiary" color="primary">
              Descripción de la propuesta
            </Title>
            <Text>{currentProposal?.text}</Text>
            <Title type="tertiary" color="primary">
              Desglose de la propuesta
            </Title>
            <div className="view-request-drawer__request-breakdown">
              <ViewRequestBreakdown currentProposal={currentProposal} isFreelance />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
}
