import React from 'react';
import { Card } from 'antd';
import ClampLines from 'react-clamp-lines';
import { Ratings, Title } from 'components/common';
import MyServices from 'pages/Managment/MyProfile/MyServices';
import 'components/requests/ViewRequestDrawer/ViewRequestDrawer.scss';
import PreviousProjects from '../MyProfile/PreviousProjects';

const ProfileContent = ({ currentUser, type, ...rest }) => {
  return (
    <>
      {currentUser && (
        <Card style={{ borderRadius: '1em' }} {...rest}>
          <div className="view-profile__text-section">
            <Title type="tertiary" color="secondary">
              Acerca de mi
            </Title>
            {currentUser.about_me ? (
              <ClampLines
                text={currentUser.about_me}
                id="about-me-text"
                lines={5}
                ellipsis="..."
                moreText="Ver más"
                lessText="Ver menos"
                className={`tn-text tn-text--regular ${
                  type == 'client' ? 'primary-view-more' : 'secondary-view-more'
                }`}
                innerElement="p"
              />
            ) : (
              ''
            )}
          </div>
          <div className="view-profile__text-section">
            <Title type="tertiary" color="secondary">
              Experiencia
            </Title>
            {currentUser.experience && (
              <ClampLines
                text={currentUser.experience}
                id="about-me-text"
                lines={5}
                ellipsis="..."
                moreText="Ver más"
                lessText="Ver menos"
                className={`tn-text tn-text--regular ${
                  type == 'client' ? 'primary-view-more' : 'secondary-view-more'
                }`}
                innerElement="p"
              />
            )}
          </div>
          <div className="view-profile__text-section">
            {' '}
            <Title type="tertiary" color="secondary">
              Servicios
            </Title>
            <MyServices user={currentUser} listOnly />
          </div>

          <div className="view-profile__text-section">
            <PreviousProjects user={currentUser} carousel type={type} />
          </div>
          <div className="view-profile__text-section">
            <Ratings type={type} userId={currentUser?.id} />
          </div>
        </Card>
      )}
    </>
  );
};

export default ProfileContent;
