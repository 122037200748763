const AccountNewUser = (first_name, last_name, email, password, prefix, phone_number) => {
  const newUser = JSON.stringify({
    username: first_name + ' ' + last_name,
    first_name: first_name,
    last_name: last_name,
    email: email,
    password: password,
    phone_number: phone_number,
    phone_prefix: prefix,
  });
  return newUser;
};

const accountService = { AccountNewUser };

export default accountService;
