/* eslint-disable no-unused-vars */
import React from 'react';
import useCustomCalendar from './useCustomCalendar';
import css from './CustomCalendar.module.scss';
import { Card } from 'antd';
import CalendarDay from './CalendarDay';
import RenderIf from '../RenderIf';

const CustomCalendar = ({ datesWithStatus }) => {
  const {
    calendario,
    year,
    increaseMonth,
    decreaseMonth,
    monthName,
    getDayString,
    fillBackDaysArray,
  } = useCustomCalendar({ datesWithStatus });
  return (
    <Card className={css.card_calendar}>
      <div className={css.calendar}>
        <div className={css.month}>
          <ul>
            <span>{year}</span>

            <li className={css.arrow} onClick={decreaseMonth}>
              &#10094;
            </li>
            <li className={css.current_month}>{monthName}</li>

            <li className={css.arrow} onClick={increaseMonth}>
              &#10095;
            </li>
          </ul>
        </div>
        <ul className={css.weekdays}>
          <li>Lu</li>
          <li>Ma</li>
          <li>Mi</li>
          <li>Ju</li>
          <li>Vi</li>
          <li>Sa</li>
          <li>Do</li>
        </ul>
        <ul className={css.days}>
          {calendario &&
            calendario.length > 0 &&
            fillBackDaysArray(calendario[0]?.fecha).map((_, i) => <li key={i}></li>)}
          {calendario &&
            calendario.length > 0 &&
            calendario.map((day) => (
              <li key={day.fecha}>
                <CalendarDay day={day}>{getDayString(day.fecha)}</CalendarDay>
              </li>
            ))}
        </ul>
      </div>
    </Card>
  );
};

export default CustomCalendar;
