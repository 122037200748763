/* eslint-disable no-unused-vars */
import React from 'react';
import { Button, Title, Text } from '@components/common';
import { message, Form, Input } from 'antd';
import { updateProject } from '@actions/projects';
import { useDispatch } from 'react-redux';
import { useDiscountCode } from '@actions/projects';
import css from './DiscountPanel.module.scss';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
const DiscountPanel = ({
  setShowDiscountWindow,
  setCommission,
  currentProject,
  setDiscountCode,
  own,
}) => {
  const dispatch = useDispatch();
  const useUpdateProject = (value) => dispatch(updateProject(value));
  const handleSendCode = async (values, setFieldError) => {
    const data = {
      discount_code: values.discount_code,
      project: currentProject?.id,
    };
    const queryResult = await useDiscountCode(data);
    if (queryResult?.data?.valid) {
      message.success('Tu descuento se aplicó correctamente');
      setCommission(queryResult.data.discount / 100);
      setShowDiscountWindow(false);
      useUpdateProject({ discount_code: true });
      setDiscountCode(true);
    } else {
      setFieldError('discount_code', 'El código ingresado no es valido.');
    }
  };
  return (
    <div className={css.container} style={{ padding: '2em' }}>
      <Title color="main">Aplicar descuento</Title>
      {own ? (
        <Text>
          Los códigos pueden ser utilizados una cantidad determinada de veces, y una vez validado
          será contado como utilizado, asi que recuerda completar el pago para no perder tu
          beneficio. No hacemos descuentos sobre el valor del descuento, únicamente sobre nuestra
          comisión.
        </Text>
      ) : (
        <Text>
          Los códigos pueden ser utilizados una cantidad determinada de veces, y una vez validado
          será contado como utilizado. No hacemos descuentos sobre el valor del descuento,
          únicamente sobre nuestra comisión.
        </Text>
      )}
      <Formik
        initialValues={{ discount_code: '' }}
        validationSchema={Yup.object({
          discount_code: Yup.string().required('Ingresa un código'),
        })}
        onSubmit={(values, { setFieldError }) => handleSendCode(values, setFieldError)}>
        {({ errors }) => (
          <FormikForm>
            <Field
              as={Input}
              name="discount_code"
              placeholder="Ingresa tu código de descuento"
              style={
                errors.discount_code
                  ? {
                      borderColor: 'red',
                      margin: '1em 0 0.3em 0',
                      boxShadow: '0 0 0 2px rgb(255 0 0 / 20%)',
                    }
                  : { margin: '1em 0 0.3em 0' }
              }
            />
            <span style={{ color: 'red', padding: '0.8em' }}>
              <ErrorMessage name="discount_code" />
            </span>

            <div style={{ textAlign: 'center', marginTop: '2em' }}>
              <Button text="Enviar código" variant="primary" />
            </div>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
};

export default DiscountPanel;
