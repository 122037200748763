import React from 'react';
import css from './ServiceDetails.module.scss';
import { Text, Title } from '@components/common';

const SERVICE_ATTRIBUTES = [
  { name: 'description', label: 'Descripción: ' },
  { name: 'availability', label: 'Disponibilidad: ' },
  { name: 'experience', label: 'Experiencia: ' },
];

const HEADER = 'Detalles del Servicio';

function ServiceDetails({ currentService }) {
  return (
    <div className={css.container}>
      <div className={css.header}>
        <Text type="normalDetail">{HEADER}</Text>
      </div>
      <div className={css.content}>
        {' '}
        {SERVICE_ATTRIBUTES.map((attr, index) => {
          return (
            <div key={`attr-${index}`} className={css.item}>
              <Title type="tertiary" color="secondary">
                {attr.label}
              </Title>
              <Text type="normalDetail">{currentService[attr.name]}</Text>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default ServiceDetails;
