import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuthUser } from '@reducers/auth';
import { logInUser, registerUser } from '@actions/auth';
import { LOGIN_SUCCESS, LOGIN_FAIL, REGISTER_SUCCESS, REGISTER_FAIL } from '@actions/types';
import { returnErrors, createMessage } from '@actions/messages';
import accountService from 'services/accountsServices';

const AccountsManagementViewModel = ({ onRegister }) => {
  const dispatch = useDispatch();
  const [prefix, setPrefix] = React.useState(null);
  const currentAuthUser = useSelector(selectAuthUser);

  const [registerBlocked, setRegisterBlocked] = React.useState(false);

  async function useLogin(email, password) {
    await logInUser(email, password)
      .then((res) => {
        dispatch({ type: LOGIN_SUCCESS, payload: res.data });
      })
      .catch((err) => {
        dispatch({ type: LOGIN_FAIL, payload: err.data });
        dispatch(returnErrors(err.response.data, err.response.status));
      });
  }

  const useRegister = (first_name, last_name, email, password, prefix, phone_number) => {
    if (!registerBlocked) {
      setRegisterBlocked(true);
      registerUser(
        accountService.AccountNewUser(first_name, last_name, email, password, prefix, phone_number)
      )
        .then((res) => {
          dispatch({ type: REGISTER_SUCCESS, payload: res?.data });
          dispatch(createMessage({ registerUser: 'User Created' }));
          setRegisterBlocked(false);
          onRegister();
        })
        .catch((err) => {
          dispatch({ type: REGISTER_FAIL, payload: err?.data });
          dispatch(returnErrors(err?.response?.data, err?.response?.status));
          setRegisterBlocked(false);
        });
    }
  };

  return {
    useRegister,
    useLogin,
    prefix,
    setPrefix,
    currentAuthUser,
    registerBlocked,
  };
};

export default AccountsManagementViewModel;
