export const ilustrationsFolder =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/formIlustrations/';

export const functionalitiesIlustrationsDictionary = {
  'Página de inicio': 'landing_page.svg',
  'Formulario de contacto': 'forms.svg',
  'Acerca de nosotros': 'about_us.svg',
  'Preguntas frecuentes': 'questions.svg',
  'Dashboards y analytics': 'data_trends.svg',
  Blog: 'blogging.svg',
  Calendario: 'calendar.svg',
  Reservas: 'booking.svg',
  'Chat en vivo': 'chatting.svg',
  Mapas: 'map_dark.svg',
  Georeferenciación: 'map.svg',
  'Galería y multimedia': 'video_files.svg',
  'Cuentas de usuarios': 'account.svg',
  'Pagos por la plataforma': 'mobile_pay.vg.svg',
  'Integración con Redes Sociales': 'social_thinking.svg',
  'Formulario de subscripción': 'subscriptions.svg',
  'Términos y condiciones': 'terms.svg',
  'Valoraciones y ratings': 'review.svg',
  Notificaciones: 'notifications.svg',
  Testimonios: 'feedback.svg',
  'Listado de productos': 'note_list.svg',
};

export const TechIlustrationsDictionary = {
  Drupal: 'Drupal_logo.png',
  Joomla: 'Joomla!-Logo.png',
  Magento: 'Magento-logo.webp',
  Shopify: 'Shopify_logo.png',
  Wix: 'Wix_Logo.png',
  Woocommerce: 'Woocommerce-logo.png',
  WordPress: 'WordPress_logo.png',
  Prestashop: 'prestashop-logo.png',
  Bsale: 'bsale.jpeg',
  'CMS Hub': 'cms_hub.png',
  Behance: 'behance.png',
  Blogger: 'blogger.png',
  Otros: 'dreamer.svg',
  SquareSpace: 'squarespace.jpeg',
};

export const APILogosDictionary = {
  PagoFacil: 'pago_facil.png',
  WebPay: 'webpay.svg',
  Khipu: 'khipu.svg',
  PayPal: 'paypal.png',
  Stripe: 'stripe.png',
  ChileExpress: 'chile_express.webp',
  GoogleMaps: 'google_map.png',
  CorreosChile: 'correos_chile.png',
  BlueExpress: 'blue_express.svg',
  Flow: 'flow.jpg',
  'Mercado Pago': 'mercado_pago.svg',
  Starken: 'starken.png',
  WhatsApp: 'whatsapp.svg',
};
