export function categoriesNames(categories_list) {
  if (categories_list.length === 1) {
    return categories_list[0];
  } else {
    let list = [];
    for (let i = categories_list.length - 1; i >= 0; i--) {
      list.push(categories_list[i]);
    }
    return categories_list.join(' / ');
  }
}
