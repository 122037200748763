import React, { Fragment } from 'react';
import {
  TimeRange,
  BudgetRange,
  ProyectDescription,
  Skills,
  ProyectTitle,
  Images,
  APIIntegration,
} from 'components/project/NewProjectForm/FormItems';
import { FinalPreview } from '../FinalPreview';
import css from './CommonEndingQuestions.module.scss';

const CommonEndingQuestions = ({
  values,
  handleSelectAPIIntegration,
  handleSelectTimeRange,
  handleSelectBudgetRange,
  handleOnChangeDescription,
  currentFocus,
  fetchSkills,
  skillsOptions,
  fetchingSkills,
  handleSelectSkills,
  handleOnChangeTitle,
  handleChangeImages,
  displayDistinctQuestion,
  questionOrder,
  displayedQuestions,
  handleGoBackQuestion,
  handleGoForwardQuestion,
  type,
  id,
  setShowVerifyIdentity,
  authUser,
}) => {
  return (
    <Fragment>
      {questionOrder.includes('api_integration') &&
        displayedQuestions.includes('api_integration') && (
          <APIIntegration
            values={values}
            handleSelectAPIIntegratios={handleSelectAPIIntegration}
            currentFocus={currentFocus}
            handleGoBackQuestion={handleGoBackQuestion}
            handleGoForwardQuestion={handleGoForwardQuestion}
            displayDistinctQuestion={displayDistinctQuestion}
          />
        )}
      {displayedQuestions.includes('time_range') && (
        <TimeRange
          values={values}
          handleSelectTimeRange={handleSelectTimeRange}
          currentFocus={currentFocus}
          handleGoBackQuestion={handleGoBackQuestion}
          handleGoForwardQuestion={handleGoForwardQuestion}
          displayDistinctQuestion={displayDistinctQuestion}
        />
      )}
      {displayedQuestions.includes('budget_range') && (
        <BudgetRange
          values={values}
          handleSelectTimeRange={handleSelectBudgetRange}
          currentFocus={currentFocus}
          handleGoBackQuestion={handleGoBackQuestion}
          handleGoForwardQuestion={handleGoForwardQuestion}
          displayDistinctQuestion={displayDistinctQuestion}
        />
      )}
      {displayedQuestions.includes('description') && (
        <ProyectDescription
          values={values}
          handleOnChangeDescription={handleOnChangeDescription}
          currentFocus={currentFocus}
          handleGoBackQuestion={handleGoBackQuestion}
          handleGoForwardQuestion={handleGoForwardQuestion}
          displayDistinctQuestion={displayDistinctQuestion}
        />
      )}
      {displayedQuestions.includes('skills') && (
        <Skills
          values={values}
          fetchSkills={fetchSkills}
          skillsOptions={skillsOptions}
          fetchingSkills={fetchingSkills}
          handleSelectSkills={handleSelectSkills}
          currentFocus={currentFocus}
          handleGoBackQuestion={handleGoBackQuestion}
          handleGoForwardQuestion={handleGoForwardQuestion}
          displayDistinctQuestion={displayDistinctQuestion}
        />
      )}
      {displayedQuestions.includes('images') && (
        <Images
          values={values}
          currentFocus={currentFocus}
          handleChangeImages={handleChangeImages}
          displayDistinctQuestion={displayDistinctQuestion}
          handleGoBackQuestion={handleGoBackQuestion}
          handleGoForwardQuestion={handleGoForwardQuestion}
        />
      )}
      {displayedQuestions.includes('title') && (
        <ProyectTitle
          values={values}
          handleOnChangeTitle={handleOnChangeTitle}
          currentFocus={currentFocus}
          handleGoBackQuestion={handleGoBackQuestion}
          handleGoForwardQuestion={handleGoForwardQuestion}
          displayDistinctQuestion={displayDistinctQuestion}
        />
      )}
      {displayedQuestions.includes('finalPreview') && (
        <FinalPreview
          values={values}
          currentFocus={currentFocus}
          questionOrder={questionOrder}
          handleGoBackQuestion={handleGoBackQuestion}
          displayDistinctQuestion={displayDistinctQuestion}
          type={type}
          id={id}
          setShowVerifyIdentity={setShowVerifyIdentity}
          authUser={authUser}
        />
      )}
      <div className={css.bottom}></div>
    </Fragment>
  );
};

export default CommonEndingQuestions;
