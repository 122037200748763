import React, { Fragment } from 'react';
import { Button, Text } from 'components/common';
import { Input } from 'antd';
import './QuestionBox.scss';
import { Loader } from 'react-feather';

const { TextArea } = Input;

const QuestionBox = ({
  question,
  handleReplyQuestion,
  getColor,
  isReplying = false,
  answerable,
}) => {
  const [showAnswerInput, setShowAnswerInput] = React.useState(false);
  const [currentAnswer, setCurrentAnswer] = React.useState('');
  const closeInput = () => {
    setShowAnswerInput(false);
    setCurrentAnswer('');
  };
  const handleReply = async () => {
    handleReplyQuestion(question.id, currentAnswer, closeInput);
  };
  return (
    <div className="question-box__container">
      {question.answer_text ? (
        <div className="question-box__with-answer">
          <Text type="detail">{question.answer_text}</Text>{' '}
          <img src={question.answer_profile_picture}></img>
        </div>
      ) : (
        <div className="question-box__without-answer">
          {showAnswerInput ? (
            <Fragment>
              <TextArea
                rows="3"
                maxLength={5000}
                showCount
                placeholder="Responde el comentario"
                name="question-reply-input"
                id={`question-reply-input-${question.id}`}
                onChange={(event) => setCurrentAnswer(event.target.value)}
              />
              <div className="question-box__button-container">
                <Button
                  text="Cancelar"
                  variant="secondary"
                  disabled={isReplying}
                  size="sm"
                  onClick={() => setShowAnswerInput(false)}
                />
                <Button
                  text={isReplying ? <Loader style={{ height: 18 }} /> : 'Enviar respuesta'}
                  variant="secondary"
                  disabled={isReplying}
                  borderColor={getColor()}
                  size="sm"
                  onClick={handleReply}
                />
              </div>
            </Fragment>
          ) : (
            <div className="question-box__button-container">
              {answerable && (
                <Button
                  text="Responder"
                  variant="secondary"
                  borderColor={getColor()}
                  size="sm"
                  onClick={() => setShowAnswerInput(true)}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default QuestionBox;
