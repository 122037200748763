import React from 'react';
import { Input } from 'antd';
import './BillingInformation.scss';
import { Text } from '@components/common';

export const BillingInformation = ({
  billingRut,
  billingAddress,
  billingActivity,
  billingSocial,
  onChangeRut,
  setBillingAddress,
  setBillingActivity,
  setBillingSocial,
}) => {
  const handleBillingSocial = (e) => {
    setBillingSocial(e.target.value);
  };
  const handleBillingActivity = (e) => {
    setBillingActivity(e.target.value);
  };
  const handleBillingAddress = (e) => {
    setBillingAddress(e.target.value);
  };
  return (
    <div className="billing-information__container">
      <div className="billing-information__rut">
        <Text type="blue">Rut: </Text>
        <Input placeholder="ej: 12345678-9" value={billingRut} onChange={onChangeRut} />
      </div>
      <div className="billing-information__direction">
        <Text type="blue">Dirección: </Text>
        <Input value={billingAddress} onChange={handleBillingAddress} />
      </div>
      <div className="billing-information__activity">
        <Text type="blue">Actividad Comercial: </Text>
        <Input value={billingActivity} onChange={handleBillingActivity} />
      </div>
      <div className="billing-information__activity">
        <Text type="blue">Razón Social: </Text>
        <Input value={billingSocial} onChange={handleBillingSocial} />
      </div>
      <hr />
    </div>
  );
};
