import React from 'react';
import { Menu, Dropdown } from 'antd';
import { MoreHorizontal } from 'react-feather';
import './DropdownMenuItem.scss';

export const DropdownMenuItem = ({ options, hoverButton, color = 'white' }) => {
  const menu = (
    <Menu>
      {options?.map((option, index) => {
        return (
          <Menu.Item disabled={option?.disabled} key={`menuopt-${index}`}>
            <button
              className="dropdown-menu-item-button"
              onClick={option?.disabled ? null : option?.callback}>
              {option?.label}
            </button>
            {option?.children}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  return (
    <Dropdown overlay={menu}>
      <a className="bank-account-card__button">
        <MoreHorizontal
          onClick={(e) => e.preventDefault()}
          style={{ fontSize: 50 }}
          color={color}
        />
        {hoverButton}
      </a>
    </Dropdown>
  );
};
