/* eslint-disable no-unused-vars */
import React from 'react';
import { CMS_FORM } from '@common/constants/routes.constants';
import { useHistory } from 'react-router';
import css from './PublicationModal.module.scss';
import { Text } from '@components/common';

import { INTEGRATIONS_FORM } from 'common/constants/routes.constants';

const Publication = ({ closeModal, containerStyles }) => {
  const history = useHistory();

  const handleClickProjects = () => {
    history.push('/createProject/new');
    if (closeModal) {
      closeModal();
    }
  };

  const handleClickCMS = () => {
    history.push(CMS_FORM);
    if (closeModal) {
      closeModal();
    }
  };

  const handleClickIntegrations = () => {
    history.push(INTEGRATIONS_FORM);
    if (closeModal) {
      closeModal();
    }
  };
  return (
    <div className={containerStyles ? '' : css.container} style={containerStyles}>
      <div className={css.integrations}>
        <button onClick={handleClickIntegrations}>
          <div className={css.image}></div>
        </button>
        <Text type="detail" centered style={{ marginTop: 10 }}>
          Modificar, mejorar, migrar o integrar algo a mi sitio web
        </Text>
      </div>
      <div className={css.cms}>
        <button onClick={handleClickCMS}>
          <div className={css.image}></div>
        </button>
        <Text type="detail" centered style={{ marginTop: 10 }}>
          Una página web, tienda online, blog o foro
        </Text>
      </div>

      <div className={css.other}>
        <button onClick={handleClickProjects}>
          <div className={css.image}></div>
        </button>
        <Text type="detail" centered style={{ marginTop: 10 }}>
          Un desarrollo a medida, app móvil, datos y modelos u otros en programación.
        </Text>
      </div>
    </div>
  );
};

export default Publication;
