import React from 'react';
import { Pagination } from 'antd';
import PublicProjectCard from './PublicProjectCard';
import { LoadingDots, EmptyListCard } from 'components/common';
import css from './ProjectsFeed.module.scss';
import { getPublicProjects } from 'actions/projects';
import { handleCategoriesSubmit } from 'common/utils/FiltersUtils';

function ProjectsFeed({ currentFilters, orderBy, currentPage, handlePagination }) {
  const [publicProjects, setPublicProjects] = React.useState([]);

  const [totalObjects, setTotalObjects] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    setIsLoading(true);
    const filterText = handleCategoriesSubmit(currentFilters);
    getPublicProjects(currentPage, filterText, orderBy)
      .then((res) => {
        setPublicProjects(res.data.objects);
        setTotalObjects(res.data.total_objects);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [currentPage, currentFilters, orderBy]);
  return (
    <div className={css.container}>
      {!isLoading ? (
        publicProjects?.length > 0 ? (
          publicProjects?.map((project, index) => {
            return <PublicProjectCard key={`project-${index}`} project={project} />;
          })
        ) : (
          <EmptyListCard type="welcome-no-projects" />
        )
      ) : (
        <LoadingDots />
      )}
      <div className={css.pagination}>
        {' '}
        <Pagination
          onChange={handlePagination}
          current={currentPage}
          defaultPageSize={10}
          total={totalObjects}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
}

export default ProjectsFeed;
