import React from 'react';
import { Tabs } from 'antd';
import { Request } from '../Request';
import PublicProject from 'pages/Project/PublicProject';
import ViewProject from 'components/project/ViewProjectDrawer/ViewProject';

const YourRequest = ({ currentProject, authUser, currentRequest, currentProposal, isLoading }) => {
  return (
    <>
      <Tabs type="card">
        <Tabs.TabPane tab="Propuesta" key="item-1">
          <Request
            withHeader={false}
            currentProject={currentProject}
            authUser={authUser}
            currentRequest={currentRequest}
            currentProposal={currentProposal}
            isLoading={isLoading}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Detalles del proyecto" key="item-2">
          {currentProject?.already_requested ? (
            <ViewProject currentProject={currentProject} withHeader={false} />
          ) : (
            <PublicProject fromModal />
          )}
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default YourRequest;
