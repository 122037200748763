import { searchSkills_v2 } from 'actions/skills';
import React from 'react';

const useSearchSkills = () => {
  const [skillsData, setSkillsData] = React.useState([]);
  const [fetching, setFetching] = React.useState(false);
  const [lastFetchId, setLastFetchId] = React.useState(0);

  const fetchSkills = async (value) => {
    setLastFetchId(lastFetchId + 1);
    const fetchId = lastFetchId;
    setSkillsData([]);
    setFetching(true);
    await searchSkills_v2({ variable: value }).then((res) => {
      if (fetchId !== lastFetchId) {
        // for fetch callback order
        return;
      }
      setSkillsData(res.data);
      setFetching(false);
    });
  };

  return { fetchSkills, skillsData, fetching };
};

export default useSearchSkills;
