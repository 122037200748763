/* eslint-disable no-unused-vars */
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { X } from 'react-feather';
import { Text } from '../Text';
import UploadClip from '../NewChat/UploadClip';
import RenderIf from '../RenderIf';
import { Button } from '../Button';

const ProjectSubmitStageModal = ({
  onUploadFeedbackFile,
  handleChangeFeedback,
  feedback,
  file,
  handleClearFile,
  currentStage,
}) => {
  return (
    <>
      {currentStage?.stage_feedbacks?.length > 0 ? (
        <>
          <Text>Espera a que el dueño del proyecto confirme tu feedback</Text>
        </>
      ) : (
        <>
          <Text>Deja un comentario:</Text>
          <TextArea
            style={{ height: 120, resize: 'none', marginTop: '1em' }}
            value={feedback}
            onChange={handleChangeFeedback}
          />
          <Text style={{ marginTop: '1em' }}>
            Adjunta un archivo(opcional):
            <UploadClip
              onUpload={onUploadFeedbackFile}
              style={{ cursor: 'pointer' }}
              id="feedback-file"
            />
          </Text>
          <Text>
            <RenderIf isTrue={file}>
              <span
                style={{
                  backgroundColor: '#e6e6e6',
                  padding: '0.2em 0.5em',
                  borderRadius: '0.5em',
                }}>
                {file?.name}
                <X
                  style={{ width: 15, marginLeft: 5, cursor: 'pointer' }}
                  onClick={handleClearFile}
                />
              </span>
            </RenderIf>
          </Text>
        </>
      )}
    </>
  );
};

export default ProjectSubmitStageModal;
