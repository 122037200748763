import React from 'react';
import FileUpload from 'components/common/FileContainer/FileUpload/FileUpload';
import { ProjectFiles } from 'components/common/ProjectFiles/ProjectFiles';
import { LoadingDots } from 'components/common';

import './FileContainer.scss';

const FileContainer = ({
  userId,
  ownerImage,
  freelanceImage,
  isLoadingFiles,
  files,
  onUpload,
  withButton = true,
  ...rest
}) => {
  return (
    <div className="file__container" {...rest}>
      {isLoadingFiles ? (
        <LoadingDots />
      ) : (
        <ProjectFiles
          userId={userId}
          isLoadingFiles={isLoadingFiles}
          ownerImage={ownerImage}
          freelanceImage={freelanceImage}
          files={files}
        />
      )}
      {withButton && (
        <>
          <hr />
          <FileUpload onUpload={onUpload} isDisabled={isLoadingFiles} />
        </>
      )}
    </div>
  );
};

export default FileContainer;
