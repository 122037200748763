/* eslint-disable no-unused-vars */
import { BaseLayout, Title } from 'components/common';
import React from 'react';
import useChatWidget from 'hooks/useChatWidget';
import ChatContainer from '../ChatContainer';

const ChatsMobilePage = () => {
  const { openedChats, chats } = useChatWidget();

  const currentChatId =
    openedChats?.length > 0 ? openedChats?.[openedChats.length - 1] : chats?.[0]?.id;

  return (
    <BaseLayout style={{ padding: 0 }}>
      <BaseLayout.Content>
        <ChatContainer chatId={currentChatId} />
      </BaseLayout.Content>
    </BaseLayout>
  );
};

export default ChatsMobilePage;
