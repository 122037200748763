import { Badge } from 'antd';
import React, { useEffect } from 'react';
import { useState } from 'react';
import RenderIf from '../RenderIf';
import { Text } from '../Text';
import './CustomTabs.scss';
const CustomTabs = ({ tabs, commonLayout, toggleIcons, selected }) => {
  const tabExists = (tab) => {
    return tabs.find((item) => item.name === tab);
  };

  const [currentTab, setCurrentTab] = useState(tabExists(selected) ? selected : tabs[0]?.name);
  useEffect(() => {
    if (tabExists(selected)) {
      setCurrentTab(selected);
    }
  }, [selected]);

  const handleOnClick = (tab) => {
    setCurrentTab(tab.name);
    if (tab.onClick) {
      tab.onClick();
    }
  };
  return (
    <>
      <div className="tabs_items_container">
        {tabs?.map((item, idx) => (
          <Text
            style={{
              margin: 0,
              padding: toggleIcons ? '0.3em 1.5em' : '0.3em 1em',
              position: item.badge ? 'relative' : 'unset',
            }}
            className={`${currentTab === item.name && 'active'} tab_item`}
            key={idx}
            onClick={() => handleOnClick(item)}>
            {toggleIcons ? item.icon : `${item.name} ${item.extra ? item.extra : ''}`}
            {item?.badge}
            {item.notifications && (
              <Badge
                count={item.notifications}
                style={{ top: '-2em', right: '-1.3em', position: 'absolute' }}
              />
            )}
          </Text>
        ))}
      </div>
      {commonLayout && commonLayout}
      {tabs?.map((item, idx) => (
        <RenderIf isTrue={item.name === currentTab} key={idx}>
          {item.component}
        </RenderIf>
      ))}
    </>
  );
};

export default CustomTabs;
