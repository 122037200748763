import React from 'react';
import { getUser } from '@actions/users';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser, selectLoadingUser } from '@reducers/navigation';
import { useParams } from 'react-router-dom';
import { getProjectId } from '@actions/projects';
import { selectCurrentProject } from 'reducers/navigation';

const ProjectViewModel = () => {
  const dispatch = useDispatch();
  const { projectId } = useParams();

  const loadingUser = useSelector(selectLoadingUser);

  const [currentProject, setCurrentProject] = React.useState(useSelector(selectCurrentProject));
  const [isLoadingProject, setIsLoadingProject] = React.useState(true);

  const currentUser = useSelector(selectCurrentUser);

  const useGetUser = (project) => dispatch(getUser(project));

  React.useEffect(() => {
    setCurrentProject(null);
    if (projectId) {
      setIsLoadingProject(true);

      getProjectId(projectId)
        .then((res) => {
          setCurrentProject(res.data);
          setIsLoadingProject(false);
        })
        .catch(() => {
          setCurrentProject(null);
          setIsLoadingProject(false);
        });
    }
  }, [projectId]);

  React.useEffect(() => {
    if (currentProject && currentUser?.id !== currentProject?.owner) {
      useGetUser(currentProject?.owner);
    }
  }, [currentProject]);

  const isLoading = loadingUser || isLoadingProject;
  return {
    isLoading,
    currentProject,
    currentUser,
  };
};

export default ProjectViewModel;
