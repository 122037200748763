import React from 'react';
import '../ProjectMainCard.scss';
import { ListCardLayout } from '@components/common';
import { StatusTag, SkillsBeans, RequestersCarousel, Text, Title } from '@components/common';
import { useHistory } from 'react-router';
import { CLIENT_PUBLICATIONS_ROUTE } from '@common/constants/routes.constants';
import { NO_REQUESTERS_ILUSTRATION } from '@common/constants/informativeIlustrations.constants';
import ProfilePicture from 'components/common/ProfilePicture/ProfilePicture';

export const PublicationsListMainCard = ({ currentProject }) => {
  const history = useHistory();
  const handleProjectManagment = (id) => {
    history.push(CLIENT_PUBLICATIONS_ROUTE + '/' + id);
  };
  return (
    <ListCardLayout onClick={() => handleProjectManagment(currentProject?.id)}>
      <ListCardLayout.LeftSide>
        {currentProject.freellance_data ? (
          <div>
            <div className="project-card__requester-header">
              <Text type="detail">Freelancer elegido: </Text>
            </div>{' '}
            <ProfilePicture
              user={currentProject.freellance_data}
              withEditButton={false}
              withMedals
              width={100}
            />
          </div>
        ) : (
          <div>
            <div className="project-card__requester-header">
              <Text type="detail">
                {' '}
                {`Postulantes (${currentProject?.requesting_users.length}):`}{' '}
              </Text>
            </div>
            {currentProject?.requesting_users.length == 0 ? (
              <div className="requesters-carousel__no-requesters">
                <img src={NO_REQUESTERS_ILUSTRATION} />
              </div>
            ) : (
              <RequestersCarousel requesters={currentProject?.requesting_users} />
            )}
          </div>
        )}
      </ListCardLayout.LeftSide>
      <ListCardLayout.RightSide>
        {' '}
        <div className="project-card__project">
          {' '}
          <div className="project-card__header">
            <Text type="detail">Proyecto: </Text>
            <StatusTag project={currentProject} />
          </div>
          <div className="project-card__content">
            <Title type="secondary-bold" color="primary">
              {currentProject?.title}
            </Title>
            <div className="project-card__categories">
              <Text type="strong">{currentProject?.project_category_name}</Text>
              <div className="project-card__subcategories">
                {currentProject?.project_subcategories_names.map((subcategory, index) => {
                  return (
                    <Text key={index}>
                      {currentProject?.project_subcategories_names.length == index + 1
                        ? subcategory
                        : `${subcategory} /`}
                    </Text>
                  );
                })}
              </div>
            </div>

            <SkillsBeans skills={currentProject?.skills_names} />
          </div>
        </div>
      </ListCardLayout.RightSide>
      <ListCardLayout.Buttons></ListCardLayout.Buttons>
      <ListCardLayout.Bottom></ListCardLayout.Bottom>
    </ListCardLayout>
  );
};
