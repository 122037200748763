import './UserCard.scss';
import React from 'react';

export const UserCard = ({ image, name, profession }) => {
  return (
    <div className="user-card-body">
      <div className="user-card-image-ctn">
        <img src={image} className="user-card-image" />
      </div>
      <div className="user-card-data">
        <h5>{name}</h5>
        <h6>{profession}</h6>
      </div>
    </div>
  );
};
