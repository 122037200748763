import React from 'react';
import classNames from 'classnames';
import './SwitchTypeSearch.scss';

const SwitchTypeSearch = ({
  textWork,
  textHire,
  handleSwitch,
  checkedWork,
  checkedHire,
  className,
}) => {
  const containerClasses = classNames('switch-container', className);
  return (
    <div className={containerClasses}>
      <label className="working">
        <input
          id="work"
          type="radio"
          defaultValue="work"
          onClick={() => handleSwitch(textWork)}
          checked={checkedWork}
          onChange={() => {}}
        />
        <span id="work-label">{textWork}</span>
      </label>

      <label className="hiring">
        {' '}
        <input
          id="hire"
          type="radio"
          defaultValue="hire"
          onClick={() => handleSwitch(textHire)}
          checked={checkedHire}
          onChange={() => {}}
        />
        <span id="hire-label">{textHire}</span>
      </label>
    </div>
  );
};

export default SwitchTypeSearch;
