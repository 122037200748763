import React from 'react';
import './PaymentOrderMoreDrawer.scss';
import { MoneyFormatter } from '@common/utils/Formaters';
import { Title, Text, CustomDrawer } from '@components/common';

export function PaymentOrderMoreDrawer({ currentPaymentOrder, moreDrawer, setMoreDrawer }) {
  return (
    <CustomDrawer
      type="profile"
      open={moreDrawer}
      onClose={() => setMoreDrawer(false)}
      visible={moreDrawer}>
      <div className="payment-order-more__container">
        <div className="payment-order-more__blue-background"></div>
        <div className="payment-order-more__content">
          <Title type="primary" color="main">
            Orden de pago
          </Title>
          <div className="payment-order-more__row">
            <Text type="strongBlue">Nombre proyecto: </Text>
            <Text>{currentPaymentOrder?.project_name}</Text>
          </div>
          <div className="payment-order-more__row">
            <Text type="strongBlue">Nombre etapa: </Text>
            <Text>{currentPaymentOrder?.stage_name}</Text>
          </div>
          <div className="payment-order-more__row">
            <Text type="strongBlue">Precio: </Text>
            <Text>{MoneyFormatter(currentPaymentOrder?.amount)}</Text>
          </div>
          <div className="payment-order-more__row">
            <Text type="strongBlue">Comisión: </Text>
            <Text>{MoneyFormatter(currentPaymentOrder?.comission)}</Text>
          </div>
          <div className="payment-order-more__row">
            <Text type="strongBlue">Retención SII: </Text>
            <Text>{MoneyFormatter(currentPaymentOrder?.retention)}</Text>
          </div>
          <div className="payment-order-more__row">
            <Text type="strongBlue">Total a recibir: </Text>
            <Text>{MoneyFormatter(currentPaymentOrder?.total)}</Text>
          </div>
          <div className="payment-order-more__row">
            <Text type="strongBlue">Estado: </Text>
            <Text
              className={`tn-text tn-text--detail tn-text--small status--${currentPaymentOrder?.status}`}
              id="status">
              {currentPaymentOrder?.status == 'created' ? 'creada' : 'pagada'}
            </Text>
          </div>
          <div className="payment-order-more__row">
            <Text type="strongBlue">Fecha creación : </Text>
            <Text>{currentPaymentOrder?.created_at}</Text>
          </div>
          <div className="payment-order-more__row">
            <Text type="strongBlue">Fecha pago: </Text>
            <Text>{currentPaymentOrder?.paid_at}</Text>
          </div>
        </div>
      </div>
    </CustomDrawer>
  );
}
