import React, { Component } from 'react';
import ReactCardCarousel from 'react-card-carousel';
import { GraphicContainer, Title, Text } from '@components/common';
import { Rate } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import './MyCarousel.scss';

class MyCarousel extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div className="mycarousel-container">
        <ReactCardCarousel autoplay autoplay_speed={4000}>
          <div className="mycarousel-card">
            <div className="mycarousel-image-container">
              <div className="mycarousel__image">
                <GraphicContainer
                  src="https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/landing/richard-modified.png"
                  maxHeight="140px"
                />
              </div>
              <div className="mycarousel__header">
                <Title className="mycarousel__title" size="20" type="xl" color="black">
                  Richard
                </Title>
                <CheckOutlined className="checked__user" />
              </div>
              <Rate disabled defaultValue={5} />
            </div>
            <div className="mycarousel__feed">
              <Title className="mycarousel__profesion" type="tertiary" color="primary">
                Desarrollo de Software a Medida
              </Title>
              <Text className="mycarousel-feed-experience" type="detail">
                {`" Desarrollo software de hace mas de 10 años, trabajo con varios lenguajes de programacion el cual definido de acuerdo a las necesidades del cliente y servicio que se requiere, por ejemplo si es un sitio e-commerce me especializo en programar en PHP... "`}
              </Text>
            </div>
          </div>
          <div className="mycarousel-card">
            <div className="mycarousel-image-container">
              <div className="mycarousel__image">
                <GraphicContainer
                  src="https://tenecesito-bucket.s3-us-west-1.amazonaws.com/profile-images/media/davidcastroalvarez%40gmail.com/davidcastroalvarez%40gmail.com_ProfilePicture0.png"
                  maxHeight="140px"
                />
              </div>
              <div className="mycarousel__header">
                <Title className="mycarousel__title" size="20" type="xl" color="black">
                  David
                </Title>
                <CheckOutlined className="checked__user" />
              </div>
              <Rate allowHalf disabled defaultValue={5} />
            </div>
            <div className="mycarousel__feed">
              <Title className="mycarousel__profesion" type="tertiary" color="primary">
                Desarrollo web e ecommerce
              </Title>
              <Text className="mycarousel-feed-experience" type="detail">
                {`" Tengo experiencia creando sitios web desde cero o configurando templates. Te puedo orientar si necesitas en sitio web, landing page o una web de comercio electrónico. Puedo integrar medios de pago, sistemas de envío, configurar correos, migrar entre servidores..."`}
              </Text>
            </div>
          </div>
          <div className="mycarousel-card">
            <div className="mycarousel-image-container">
              <div className="mycarousel__image">
                <GraphicContainer
                  src="https://tenecesito-bucket.s3-us-west-1.amazonaws.com/profile-images/media/gabo.agf%40gmail.com/gabo.agf%40gmail.com_ProfilePicture0.png"
                  maxHeight="140px"
                />
              </div>
              <div className="mycarousel__header">
                <Title className="mycarousel__title" size="20" type="xl" color="black">
                  Gabriel
                </Title>
                <CheckOutlined className="checked__user" />
              </div>
              <Rate allowHalf disabled defaultValue={5} />
            </div>
            <div className="mycarousel__feed">
              <Title className="mycarousel__profesion" type="tertiary" color="primary">
                Pagina web y tienda online CMS con medio de pago web pay
              </Title>
              <Text className="mycarousel-feed-experience" type="detail">
                {`" Creo tu pagina web (CMS) con tienda online hecha a tu medida, te integro Transbank para que cobres a tus clientes por medio de Webpay. Ten una base de datos de tus clientes y gestiona profesionalmente tus ventas. Cobra con tarjetas de crédito y debito y ..."`}
              </Text>
            </div>
          </div>
        </ReactCardCarousel>
      </div>
    );
  }
}

export default MyCarousel;
