import React from 'react';
import css from './SummaryRequester.module.scss';
import { Badge, Rate } from 'antd';
import { Text } from '@components/common';
import { MoneyFormatter } from 'common/utils/Formaters';

export const SummaryRequester = ({ requester, handleOnClickApplicant, ...rest }) => {
  const rejected = requester?.request_status == 'rejected';
  return (
    <div
      className={`${css.container} ${rejected && css.rejected}`}
      onClick={() => handleOnClickApplicant(requester)}
      {...rest}>
      <div className={css.imageContainer}>
        {!rejected && (
          <div className={css.activityContainer}>
            <Badge count={requester?.new_messages} />
          </div>
        )}
        <img src={requester?.profile_image} />
      </div>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        }}>
        <div style={{ position: 'relative' }}>
          <Text type="strongBlue" size={14} style={{ margin: 0 }}>
            {requester?.first_name}
          </Text>
          <Rate
            disabled
            allowHalf
            defaultValue={requester?.overall_rating}
            style={{ fontSize: 12, position: 'absolute', bottom: -15 }}
          />
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: 85 }}>
            <Text size={13} type="strong">
              $
            </Text>
            <Text type="strong" size={13}>
              {MoneyFormatter(requester?.proposal?.total_price).slice(1)}
            </Text>
          </div>
          <div style={{ width: 60, textAlign: 'right' }}>
            <Text size={13} style={{ color: 'gray' }}>
              {requester?.proposal?.total_duration} días
            </Text>
          </div>
        </div>
      </div>
      {/* {!rejected && (
        <div className={css.activityContainer}>
          <Badge count={requester?.new_messages} />
        </div>
      )} */}
    </div>
  );
};
export const SummaryRequester2 = ({ requester, handleOnClickApplicant, ...rest }) => {
  const rejected = requester?.request_status == 'rejected';
  return (
    <div
      className={`${css.container} ${rejected && css.rejected}`}
      onClick={() => handleOnClickApplicant(requester)}
      {...rest}>
      <div className={css.imageContainer}>
        <img src={requester?.profile_image} />
      </div>
      <div className={css.titleContainer}>
        <Text type="strongBlue">{requester?.first_name}</Text>
        <Rate
          disabled
          allowHalf
          defaultValue={requester?.overall_rating}
          style={{ fontSize: 12 }}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'space-between', marginTop: 20 }}>
        <Text>{requester?.proposal?.total_duration} días</Text>
        <Text type="strongBlue">{requester?.proposal?.total_price}</Text>
      </div>
      {!rejected && (
        <div className={css.activityContainer}>
          <Badge count={requester?.new_messages} />
        </div>
      )}
    </div>
  );
};
