import React, { Component } from 'react';
import { Collapse } from 'antd';
import '@assets/styles/layouts/LegalFiles.scss';
import { Text, Title } from '@components/common';
const { Panel } = Collapse;

export class Mediation extends Component {
  render() {
    return (
      <div className="custom-container">
        <div className="documents-ctn">
          <Title size="25" type="secondary" color="primary">
            Mediación de TE NECESITO en caso de controversia entre las partes
          </Title>
          <Text type="legalFiles">
            A efecto de facilitar una adecuada resolución de las controversias que pudieren
            suscitarse, <strong>TE NECESITO</strong> podrá actuar como mediador entre las partes que
            hagan uso de su Servicio, a fin de lograr un acuerdo en caso de algún tipo de
            desavenencia o desacuerdo respecto a los servicios pactados a través del Sitio Web{' '}
            <a href="http://www.tenecesito.cl/">www.tenecesito.cl</a>
          </Text>
          <Text type="legalFiles">
            En este caso,<strong> TE NECESITO</strong> actuará como{' '}
            <em>mediador o amigable componedor</em> para resolver la forma según la cual se
            ejecutará, pagará o finalizará el proyecto respecto del cual se susciten controversias,
            así como el porcentaje de pago que se liberará.
          </Text>
          <Collapse defaultActiveKey={['1']}>
            <Panel header="1. Resolución" key="1">
              <Text type="big">
                Para su resolución, <strong>TE NECESITO </strong>tendrá en consideración, exclusiva
                y únicamente,<strong> </strong>los antecedentes, comunicaciones, archivos y
                documentos que Usuario y Freelancer hubieren aportado y cargado en el desarrollo del
                proyecto a través del Sitio Web{' '}
                <a href="http://www.tenecesito.cl/">www.tenecesito.cl</a>. Por esta razón, cualquier
                información externa o que no hubiera sido intercambiada ni aportada a dicho Sitio no
                será considerada como antecedentes en este proceso de mediación.
              </Text>
            </Panel>
            <Panel header="2. Propósito" key="2">
              <Text type="big">
                El propósito de <strong>TE NECESITO</strong> es ayudar en el proceso entre las
                partes en calidad de facilitador. Por esta razón, ambas partes podrán expresar sus
                consideraciones y pretensiones en términos respetuosos, formales y en igualdad de
                condiciones. El plazo para llevar a cabo la Mediación no será inferior a 3 ni
                superior a 10 días hábiles, contados desde la aceptación expresa de{' '}
                <strong>TE NECESITO </strong>en orden a actuar como mediador.
              </Text>
            </Panel>
            <Panel header="3. Solicitud" key="3">
              <Text type="big">
                Las partes podrán solicitar la Mediación de <strong>TE NECESITO </strong>en los
                siguientes casos:
              </Text>
              <Text type="big">
                i. Cuando el creador del proyecto no quede conforme con los resultados entregados
                por el <em>FreeLancer</em>/colaborador;
              </Text>
              <Text type="big">
                ii. En caso de que se produzca un retraso injustificado en la entrega del proyecto,
                según las condiciones acordadas por ambas partes;
              </Text>
              <Text type="big">
                iii. Cuando el Trabajo efectuado por el <em>FreeLancer</em> no se desarrolle según
                lo acordado o se realice de manera deficiente;
              </Text>
              <Text type="big">
                iv. Cuando el <em>FreeLancer</em> no se informe adecuadamente de las
                especificaciones del proyecto y/o no cumpla con lo acordado en la negociación;
              </Text>
              <Text type="big">
                v. Cuando el <em>FreeLancer</em> no cumpla con las fechas de entrega acordadas;
              </Text>
              <Text type="big">
                vi. Cuando el dueño del proyecto, de manera injustificada, no acepte el proyecto;
              </Text>
              <Text type="big">
                vii. Cualquier otra desavenencia entre las partes, respecto de las cuales{' '}
                <strong>TE NECESITO</strong> pueda mediar.
              </Text>
            </Panel>
            <Panel header="4. Consideraciones" key="4">
              <Text type="big">
                Antes de solicitar la Mediación, es importante que tengas en consideración:
              </Text>
              <Text type="big">
                i. La Mediación tendrá un costo fijo para quien la solicite en primera instancia,
                monto que equivaldrá al 7% del precio pactado por el Proyecto. Luego, la contraparte
                tendrá la opción de aceptar la última negociación realizada o aceptar el proceso de
                mediación (para lo cual también deberá desembolsar un monto fijo que{' '}
                <strong>TE NECESITO </strong>informará para tal efecto);
              </Text>
              <Text type="big">
                ii. Al proponer las bases de resolución de la mediación, y siendo estas aceptadas
                por las partes, el monto pagado será restituido a la parte ganadora (o en partes
                proporcionales si la resolución así lo fuese. Con todo, las partes podrán acordar
                algo diverso en relación a esto, decisión que, en todo caso, deberá ser aprobada por
                el mediador de <strong>TE NECESITO</strong>).
              </Text>
            </Panel>
            <Panel header="5. Comisión de TE NECESITO" key="5">
              <Text type="big">
                La comisión pagada a <strong>TE NECESITO</strong> no será reembolsada en caso de
                Mediación.
              </Text>
            </Panel>
            <Panel header="6. Recurrencia" key="6">
              <Text type="big">
                El Usuario que recurra a la Mediación de <strong>TE NECESITO</strong> más de 3 veces
                en un año calendario, verá duplicado el costo de esta.
              </Text>
            </Panel>
            <Panel header="7. Sanciones" key="7">
              <Text type="big">
                En el evento de existir un caso de Mediación por el cual una de las partes actuó de
                mala fe, <strong>TE NECESITO</strong> se reserva el derecho de aplicar alguna de las
                sanciones establecidas en el documento de{' '}
                <a href="#/Behaviour&Sanctions">Normas y Sanciones</a>.
              </Text>
            </Panel>
          </Collapse>
        </div>
      </div>
    );
  }
}
