import React from 'react';
import css from './Advices.module.scss';
import { InformativeAdvice } from 'components/common';
import { adviceText } from 'common/constants/publicationForm.constants';

const Advices = ({ currentFocus, branchStatus }) => {
  const getSubCategoryAdvice = () => {
    if (!branchStatus) {
      return adviceText['subCategory1'];
    }
    if (['cms-from-scratch', 'cms-integration'].includes(branchStatus)) {
      return adviceText['subCategory1'];
    } else if (
      [
        'webapp-from-scratch',
        'webapp-integration',
        'mobileapp-from-scratch',
        'mobileapp-integration',
      ].includes(branchStatus)
    ) {
      return adviceText['subCategory2'];
    } else if (
      [
        'data-model-from-scratch',
        'other-from-scratch',
        'data-model-integration',
        'other-integration',
      ].includes(branchStatus)
    ) {
      return adviceText['subCategory3'];
    }
  };

  return (
    <div className={css.container}>
      <div className={css.item}>
        {currentFocus == 'sub_category' ? (
          <InformativeAdvice
            image={getSubCategoryAdvice()['image']}
            title={getSubCategoryAdvice()['title']}
            paragraphs={getSubCategoryAdvice()['paragraphs']}
          />
        ) : (
          currentFocus && (
            <InformativeAdvice
              image={adviceText[currentFocus]['image']}
              title={adviceText[currentFocus]['title']}
              paragraphs={adviceText[currentFocus]['paragraphs']}
            />
          )
        )}
      </div>
    </div>
  );
};

export default Advices;
