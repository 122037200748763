/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect } from 'react';
import './PaymentDrawer.scss';
import { PaymentOption } from '../PaymentOption/PaymentOption';
import { BillingInformation } from '../BillingInformation/BillingInformation';
import { PaymentMethodOption } from '../PaymentMethodOption/PaymentMethodOption';
import { ViewRequestBreakdown } from '@components/requests/ViewRequestDrawer/ViewRequestBreakdown/ViewRequestBreakdown';
//import { CalculateComissionIVAStage } from '@common/utils/Bussiness';
import { BankData } from '../BankData/BankData';
import { Button, Text, Title, CustomDrawer } from '@components/common';
import axiosClient from '@config/axios';
import { redirectPost } from '@components/viewProject/common.jsx';
import { message, Tooltip } from 'antd';
import { updateProject } from '@actions/projects';
import useRut from 'hooks/useRut';
import { sendAlreadyPaid } from 'actions/projects';
import { useDispatch } from 'react-redux';
import { createMessage } from 'actions/messages';

export const PaymentDrawer = ({
  currentProject,
  currentProposal,
  setProjectStatus,
  paymentDrawer,
  setPaymentDrawer,
}) => {
  const [receiptType, setReceiptType] = React.useState('bill');
  const [paymentMethod, setPaymentMethod] = React.useState('deposit');
  //const [paymentOption, setPaymentOption] = React.useState('totalPayment');
  const { currentRut, onChangeRut } = useRut();
  const [billingAddress, setBillingAddress] = React.useState();
  const [billingActivity, setBillingActivity] = React.useState();
  const [billingSocial, setBillingSocial] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch();

  const postTbk = async (data) => {
    await axiosClient
      .post(`/tbkInit/`, data)
      .then((res) => {
        redirectPost(res.data.url, { token_ws: res.data.token });
      })
      .catch(() => {
        message.error('Ha ocurrido un problema al procesar el pago, intenta nuevamente.');
      });
  };

  const handlePaymentMethod = async (data, paymentMethod) => {
    if (paymentMethod === 'webpay') {
      await postTbk(data);
    } else {
      /* bank transfer */
      const query = await axiosClient.post('/initBankTransaction/', data);
      if (query.status === 200) {
        updateProject({ status: 'payment_validation' });
      }
      setProjectStatus('payment');
    }
  };

  const handlePayment = async () => {
    const data = {
      order_number: currentProject.order[0].order_number,
      receipt_type: receiptType,
      payment_type: paymentMethod,
    };
    if (receiptType === 'invoice') {
      data['rut'] = currentRut;
      data['address'] = billingAddress;
      data['activity'] = billingActivity;
      data['bussines_name'] = billingSocial;
    }
    await handlePaymentMethod(data, paymentMethod);
  };

  const handleAlreadyPaid = () => {
    setIsLoading(true);
    sendAlreadyPaid({ project: currentProject.id })
      .then(() => {
        dispatch(
          createMessage({ successMessage: 'Se envió un aviso sobre la transferencia realizada.' })
        );
      })
      .catch(() => {
        dispatch(
          createMessage({
            errorMessage:
              'Hubo un error al intentar enviar el aviso de la transferencia, intenta nuevamente.',
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <CustomDrawer
      type="payment"
      open={paymentDrawer}
      onClose={() => setPaymentDrawer(false)}
      visible={paymentDrawer}>
      {' '}
      <div className="payment-drawer__container">
        <div className="payment-drawer__header">
          <Text type="boldDetail">Pago del proyecto: </Text>
          <Title type="secondary-bold" color="primary">
            {currentProject?.title}
          </Title>
          <hr />
        </div>

        <div className="payment-drawer__content">
          {currentProject?.bank_transfer ? (
            <Fragment>
              <Text type="detail" style={{ marginBottom: 16 }}>
                Estamos validando tu transferencia, esto puede tardar unas horas. Te notificaremos
                cuando esté listo y poder dar inicio al proyecto.
              </Text>
              <BankData
                totalPayment={currentProposal?.client_breakdown?.total_payment}
                confirmationCode={currentProject?.bank_transfer?.confirmation_code}
              />
              <div style={{ textAlign: 'center', marginTop: 16 }}>
                <Button
                  isLoading={isLoading}
                  text="Ya transferí"
                  onClick={handleAlreadyPaid}
                  style={{ width: 200 }}
                />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="payment-drawer__row">
                <Title type="tertiary" color="primary">
                  Pago:{' '}
                </Title>
                <div className="payment-drawer__options">
                  <PaymentOption
                    type="bill"
                    selected={receiptType == 'bill'}
                    setPaymentType={setReceiptType}
                  />
                  <PaymentOption
                    type="invoice"
                    selected={receiptType == 'invoice'}
                    setPaymentType={setReceiptType}
                  />
                </div>
              </div>
              <hr />
              <div className="payment-drawer__row">
                {receiptType == 'invoice' && (
                  <Fragment>
                    <Title type="tertiary" color="primary">
                      Información de Facturación:
                    </Title>
                    <BillingInformation
                      onChangeRut={onChangeRut}
                      setBillingAddress={setBillingAddress}
                      setBillingActivity={setBillingActivity}
                      setBillingSocial={setBillingSocial}
                      billingAddress={billingAddress}
                      billingActivity={billingActivity}
                      billingSocial={billingSocial}
                      billingRut={currentRut}
                    />
                  </Fragment>
                )}
              </div>

              <hr />
              <Title type="tertiary" color="primary">
                Detalle del Pago:{' '}
              </Title>
              <div className="payment-drawer__request-breakdown">
                <ViewRequestBreakdown currentProposal={currentProposal} />
              </div>
              <hr />
              <div className="payment-drawer__row">
                <Title type="tertiary" color="primary">
                  Método de Pago:{' '}
                </Title>
                <div className="payment-drawer__options">
                  <PaymentMethodOption
                    type="bankTransfer"
                    selected={paymentMethod == 'deposit'}
                    handlePaymentOption={() => setPaymentMethod('deposit')}
                  />
                  <PaymentMethodOption
                    disabled={receiptType == 'invoice'}
                    tooltip={
                      receiptType == 'invoice'
                        ? 'Este método de pago no está disponible con factura. Por favor, seleccione otro método de pago o cambie a Boleta'
                        : ''
                    }
                    type="webpay"
                    selected={paymentMethod == 'webpay'}
                    handlePaymentOption={() => setPaymentMethod('webpay')}
                  />
                </div>
              </div>

              <div className="payment-drawer__checkout">
                <Button
                  text={paymentMethod == 'deposit' ? 'Pagar por transferencia' : 'Pagar por webpay'}
                  variant="primary"
                  borderColor="primary"
                  onClick={handlePayment}
                />
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </CustomDrawer>
  );
};
