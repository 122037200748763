import React, { Fragment } from 'react';
import { BaseLayout, EmptyListCard, InformativeAdvice, CustomBreadcrumb } from '@components/common';
import { FreelancerProjectsListViewModel } from './FreelancerProjectsListViewModel';
import { MyProjectsMainCard } from '@components/project/ProjectMainCard/MyProjectsMainCard/MyProjectsMainCard';
import { FilterTypes, Title } from '@components/common';
import { LoadingDots } from '@components/common/LoadingDots';
import { PERSON_5_ILUSTRATION } from '@common/constants/informativeIlustrations.constants';
import { Pagination } from 'antd';
import css from './FreelancerProjectsList.module.scss';

const FreelancerProjectsList = () => {
  const {
    isLoadingWorkingProjectsList,
    workingProjectsList,
    project_types,
    handleOnClickCategory,
    currentFilter,
    handlePagination,
    totalWorkingProjects,
    currentPage,
  } = FreelancerProjectsListViewModel();

  return (
    <Fragment>
      <CustomBreadcrumb type="freelancer-project" />
      <BaseLayout>
        <BaseLayout.LeftSide>
          <div className={css.layoutContainer}>
            <div className="tn-layout__left-section">
              <Title type="secondary-bold" color="primary">
                Tus proyectos activos:{' '}
              </Title>
              <hr />
              <div className="my-projects-list__filters">
                <FilterTypes
                  filter_types={project_types}
                  onSelect={handleOnClickCategory}
                  selectedState={currentFilter}
                />
              </div>
            </div>
            <div className="tn-layout__left-section">
              <InformativeAdvice
                image={PERSON_5_ILUSTRATION}
                message="Acá puedes encontrar el listado de los proyectos activos en los que estás trabajando. Presiona alguno para ver más detalles."
              />
            </div>
          </div>
        </BaseLayout.LeftSide>
        <BaseLayout.Content>
          <div className={css.content}>
            {' '}
            {!isLoadingWorkingProjectsList ? (
              workingProjectsList.length == 0 ? (
                <EmptyListCard type="no-working-projects" />
              ) : (
                <Fragment>
                  {workingProjectsList.map((project, index) => {
                    return (
                      <MyProjectsMainCard
                        type="list"
                        currentProject={project}
                        currentOwner={project.owner_data}
                        key={index}
                      />
                    );
                  })}
                  <div className={css.paginationContainer}>
                    {' '}
                    <Pagination
                      onChange={handlePagination}
                      pageSize={10}
                      total={totalWorkingProjects}
                      current={currentPage}
                      showSizeChanger={false}
                    />
                  </div>
                </Fragment>
              )
            ) : (
              <LoadingDots />
            )}
          </div>
        </BaseLayout.Content>
      </BaseLayout>
    </Fragment>
  );
};

export default FreelancerProjectsList;
