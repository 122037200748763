import React from 'react';
import { NavLink } from 'react-router-dom';
import { UserCard } from '@components/common';

export const Contact = (props) => (
  <NavLink to={`/chat/${props.chatURL}`}>
    <li
      className={`contact ${props.currentChat === props.chat ? 'selected' : ''}`}
      onClick={() => props.dispatchChat(props.chat)}>
      <UserCard image={props.picURL} name={props.user_name} profession={props.name} />
    </li>
  </NavLink>
);
