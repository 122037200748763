import axiosClient from '@config/axios';
import { createMessage } from './messages';
import { GET_USER_NOTIFICATIONS, NOTIFICATIONS_PAGINATION } from './types';

export const getUserNotifications = (userid, page) => async (dispatch) => {
  var aux_page = 1;
  if (page) {
    aux_page = page;
  }
  await axiosClient
    .get(`/notifications/?owner=${userid}&page=${aux_page}`)
    .then((res) => {
      dispatch(createMessage({ getUserNotifications: 'User gotten' }));
      dispatch({
        type: GET_USER_NOTIFICATIONS,
        payload: res.data.objects,
      });
      dispatch({
        type: NOTIFICATIONS_PAGINATION,
        payload: res.data.total_objects,
      });
    })
    .catch((err) => console.log(err));
};

export const getNotificationsStatus = () => axiosClient.get(`/loadHeader`);
