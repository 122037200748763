/* eslint-disable no-unused-vars */
import React from 'react';
import css from './PostDetail.module.scss';
import { Comments } from '../Comments';
import moment from 'moment';
import 'moment/locale/es';
import { LoadingDots, Text } from 'components/common';
import ReactMarkdown from 'react-markdown';
import { PostCard } from '../PostCard';
import SocialShare from 'components/common/SocialShare';

function PostDetail({
  currentPost,
  handleSendComment,
  commentsList,
  authUser,
  recentBlogs,
  handleClickBlog,
}) {
  const postAttr = currentPost?.attributes;
  const date = moment(new Date(postAttr?.createdAt)).format('DD/MM/YYYY');

  return currentPost ? (
    <div className={css.whiteContainer}>
      <div className={css.container}>
        <div className={css.postContainer}>
          <title>
            <h3 className="tn-title xxl">{postAttr?.title}</h3>
          </title>
          <div className={css.tags}>
            <div className={css.item}>
              <p className="tn-text tn-text--big primaryColor">{postAttr?.category}</p>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '1em',
                alignItems: 'center',
              }}>
              <p className="tn-text tn-text--detail tn-text--big">{'Por: Aurora Coddou'}</p>
              <SocialShare quote="¡Te invito a leer este artículo!" hashtags={['tenecesito']} />
            </div>
          </div>

          <div className={css.image}>
            <img src={postAttr?.coverImage.data.attributes.url} alt={'AltPhoto'} />
          </div>
          <div className={css.content}>
            <div className={css.tags}>
              <div className={css.item}>
                <p className="tn-text tn-text--detail tn-text--big">{`Publicado el:  ${date}`}</p>
              </div>
            </div>
            <div className={css.abstract}>
              <ReactMarkdown>{postAttr?.abstract}</ReactMarkdown>
            </div>
            <div className={css.text}>
              <ReactMarkdown>{postAttr?.content}</ReactMarkdown>
            </div>
          </div>
        </div>
        <div className={css.commentsContainer}>
          <Comments
            handleSendComment={handleSendComment}
            commentsList={commentsList}
            authUser={authUser}
          />
        </div>
        <Text type="detail" size="16" className={css.text}>
          {authUser.isAuthenticated
            ? null
            : '¿Te gustaría estar al tanto de nuestro blog? 👀 ¡Solo te debes registrar en nuestra página y recibirás quincenalmente un newsletter con lo más destacado!'}
        </Text>
      </div>
      {recentBlogs && (
        <div className={css.recommendsPosts}>
          <h3 className="tn-title secondary">Continuar leyendo:</h3>
          <div className={css.postsContainer}>
            {recentBlogs?.map((post, index) => {
              return (
                <div className={css.postCard} key={`post-${index}`}>
                  <PostCard post={post} handleClickBlog={handleClickBlog} list={true} />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  ) : (
    <LoadingDots />
  );
}

export default PostDetail;
