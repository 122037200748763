import { FileIcon } from '..';
import { Text } from '../Text';
import React from 'react';
import RenderIf from '../RenderIf';
import { stepsMessages } from './ProjectSteps.constants';
const ProjectFeedbackStageModal = ({ stage, type, owner }) => {
  return (
    <>
      {stage?.stage_feedbacks?.map((feedback) => (
        <div key={feedback.id}>
          {stage?.approved ? (
            <Text>La etapa se aprobó y quedó el siguiente feedback:</Text>
          ) : (
            <Text>
              {type === 'client'
                ? stepsMessages.Modals.submitStage().client
                : stepsMessages.Modals.submitStage(owner).freelancer}
            </Text>
          )}

          <Text style={{ marginTop: 20, fontWeight: 700 }}>{feedback.comment}</Text>
          <RenderIf isTrue={feedback.file_id}>
            <Text style={{ marginTop: 20 }}>Archivos Adjuntos:</Text>
            <div style={{ maxWidth: 60 }}>
              <FileIcon fileData={feedback?.file} fromChat />
            </div>
          </RenderIf>
        </div>
      ))}
    </>
  );
};

export default ProjectFeedbackStageModal;
