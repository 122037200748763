import axiosClient from '../config/axios';
import config from '../config';

//const HTTP_BAD_REQUEST_CODE = 400;
const HTTP_UNAUTHORIZED_CODE = 401;
const HTTP_FORBIDDEN_CODE = 403;
const HTTP_NOT_FOUND_CODE = 404;
//const HTTP_INTERNAL_SERVER_ERROR = 500;

const NOT_ALLOWED_URL = '/notAllowed';

const setupInterceptors = (store) => {
  axiosClient.interceptors.request.use(
    async (config) => {
      const token = store?.getState().auth.token;
      if (token) {
        config.headers = {
          Authorization: `Token ${token}`,
          'Content-Type': 'application/json',
        };
      }
      return config;
    },
    (error) => {
      // eslint-disable-next-line no-undef
      Promise.reject(error);
    }
  );
  axiosClient.interceptors.response.use(undefined, (error) => {
    if (error.response.status === HTTP_UNAUTHORIZED_CODE) {
      window.location = config.homeURL + NOT_ALLOWED_URL + `/${HTTP_UNAUTHORIZED_CODE}`;
    } else if (error.response.status === HTTP_NOT_FOUND_CODE) {
      window.location = config.homeURL + NOT_ALLOWED_URL + `/${HTTP_NOT_FOUND_CODE}`;
    } else if (error.response.status === HTTP_FORBIDDEN_CODE) {
      window.location = config.homeURL + NOT_ALLOWED_URL + `/${HTTP_FORBIDDEN_CODE}`;
    }
    return Promise.reject(error);
  });
};

export default setupInterceptors;
