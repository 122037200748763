import React, { Fragment } from 'react';
import { MultipleChoiceRadio, LoadingSpin, Text } from 'components/common';
import css from './Categories.module.scss';
import SubCategories from '../SubCategories';

function Categories({ mainCategories, loadingMainCategories, selectCategory, currentFilters }) {
  const isSelected = (category) => {
    return currentFilters.find((e) => e.name === category.name);
  };

  return (
    <div className={css.container}>
      {!loadingMainCategories ? (
        <Fragment>
          {mainCategories.map((cat, index) => {
            return (
              <Fragment key={`main-cat-${index}`}>
                {' '}
                <div className={css.mainCategory} onClick={() => selectCategory(cat)}>
                  <MultipleChoiceRadio isSelected={isSelected(cat)} />
                  <Text>{cat.name}</Text>
                </div>
                {isSelected(cat) && (
                  <SubCategories
                    categoryId={cat.id}
                    selectCategory={selectCategory}
                    isSelected={isSelected}
                  />
                )}
              </Fragment>
            );
          })}
        </Fragment>
      ) : (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <LoadingSpin />
        </div>
      )}
    </div>
  );
}

export default Categories;
