import { getRequestUrlParams } from '@actions/requests';

const getUserRequest = (userId, status, page) => {
  if (status?.length === 2) {
    const URLparams = new URLSearchParams([
      ['emitter', userId],
      ['status', status[0]],
      ['status', status[1]],
      ['page', page],
    ]).toString();
    return getRequestUrlParams(URLparams);
  } else {
    const URLparams = new URLSearchParams([
      ['emitter', userId],
      ['status', status],
      ['page', page],
    ]).toString();
    return getRequestUrlParams(URLparams);
  }
};

const requestService = { getUserRequest };

export default requestService;
