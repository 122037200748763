/* eslint-disable no-unused-vars */
import { Tooltip } from 'antd';
import React from 'react';
import useMedals from './useMedals';

const UserMedals = ({ userId, show = 10, medals }) => {
  if (!userId) return null;

  const { getMedals } = useMedals({ userId, medals });

  const medallas = getMedals();

  const totalMedals = medals?.length;

  if (totalMedals === 0) return null;

  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: '0.5em',
        justifyContent: 'center',
      }}>
      {medallas?.slice(0, show)?.map((medal) => (
        <div key={medal.image}>
          <Tooltip title={medal.tooltip}>
            <img src={medal.image} alt="medal-img" width={30} />
          </Tooltip>
        </div>
      ))}
    </div>
  );
};

export default UserMedals;
