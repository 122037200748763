import axiosClient from '@config/axios';
import { tokenConfig } from './auth';
import { MY_PUBLICATIONS_PAGE_STATUS, HOME_PAGE_STATUS, MORE_CONFIG_STATUS } from './types';

export const switchPublicationsPageStatus = (pagestatus) => (dispatch) => {
  dispatch({
    type: MY_PUBLICATIONS_PAGE_STATUS,
    payload: pagestatus,
  });
};

export const switchHomePageStatus = (pagestatus) => (dispatch) => {
  dispatch({
    type: HOME_PAGE_STATUS,
    payload: pagestatus,
  });
};

export const switchMoreConfigStatus = (pagestatus) => (dispatch) => {
  dispatch({
    type: MORE_CONFIG_STATUS,
    payload: pagestatus,
  });
};

export const getBankAccountData = (id) => async (dispatch, getState) => {
  const result = await axiosClient
    .get(`/bankAccounts/?owner=${id}`, tokenConfig(getState))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return result;
};

export const getBankAccountData_v2 = (id) => axiosClient.get(`/bankAccounts/?owner=${id}`);

export const addBankAccount = (accData) => async (dispatch, getState) => {
  const result = await axiosClient
    .post(`/bankAccounts/`, accData, tokenConfig(getState))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return result;
};

export const addBankAccount_v2 = (accData) => axiosClient.post(`/bankAccounts/`, accData);

export const deleteBankAccount = (idAcc, ownerId) => async (dispatch, getState) => {
  const result = await axiosClient
    .delete(`/bankAccounts/${idAcc}?owner=${ownerId}`, tokenConfig(getState))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return result;
};

export const deleteBanckAccount_v2 = (idAcc, ownerId) =>
  axiosClient.delete(`/bankAccounts/${idAcc}?owner=${ownerId}`);

export const editBankAccount_v2 = (idAcc, ownerId, accData) =>
  axiosClient.patch(`/bankAccounts/${idAcc}?owner=${ownerId}`, accData);

export const verifyIdentity = (accData) => {
  return axiosClient.post(`/verifyIdentity`, accData);
};

export const getPaymentOrders = (ownerId) => {
  const result = axiosClient.get(`/paymentOrders/?owner=${ownerId}`);

  return result;
};

export const getClientOrders = (ownerId) => {
  const result = axiosClient.get(`/orders/?owner=${ownerId}`);

  return result;
};

export const getPaymentOrdersStats =
  (ownerId, startDate, endDate) => async (dispatch, getState) => {
    const result = await axiosClient
      .get(
        `/paymentOrders/?owner=${ownerId}&paymentStats=True&startDate=${startDate}&endDate=${endDate}`,
        tokenConfig(getState)
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err.response;
      });
    return result;
  };

export const getTransbankStats = (ownerId, startDate, endDate) => async (dispatch, getState) => {
  const result = await axiosClient
    .get(
      `/transbankLogs/?owner=${ownerId}&paymentStats=True&startDate=${startDate}&endDate=${endDate}`,
      tokenConfig(getState)
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
  return result;
};
