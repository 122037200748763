import React from 'react';
import { Button, PhoneNumberInput, Text } from 'components/common';

const PhoneNumberModalContent = ({
  prefix,
  setPrefix,
  currentPhoneNumber,
  setCurrentPhoneNumber,
  handleConfirmPhoneNumber,
}) => {
  return (
    <div>
      <Text centered style={{ marginBottom: 20 }}>
        ¡Felicidades! Tu proyecto fue publicado con éxito. Ahora comenzarás a conectar con
        programadores que te podrán enviar propuestas o realizar preguntas públicas en tu proyecto.
        Déjanos tu WhatsApp para contactarte cuando tengas tus primeras propuestas
      </Text>
      <PhoneNumberInput
        prefix={prefix}
        setPrefix={setPrefix}
        currentPhoneNumber={currentPhoneNumber}
        setCurrentPhoneNumber={setCurrentPhoneNumber}
      />
      <Text centered>
        Tu número no será compartido con ningún usuario de la plataforma, solo para contacto del
        equipo de tenecesito.cl
      </Text>
      <Button
        onClick={handleConfirmPhoneNumber}
        disabled={!currentPhoneNumber || !prefix}
        text="Confirmar"
        style={{ marginTop: 20, width: '100%' }}
      />
    </div>
  );
};

export default PhoneNumberModalContent;
