import React from 'react';
import { BaseLayout, Title, Text, BasicContainer } from '@components/common';

const HiddenPageForUs = () => {
  return (
    <BaseLayout>
      <BaseLayout.Content>
        <BasicContainer>
          <Title type="primary" color="main">
            This is an example text with title primary
          </Title>
          <Title type="primary" color="primary">
            This is an example text with title primary
          </Title>
          <Title type="primary" color="secondary">
            This is an example text with title primary
          </Title>
          <Title type="secondary" color="main">
            This is an example text with title secondary
          </Title>
          <Title type="secondary" color="primary">
            This is an example text with title secondary
          </Title>
          <Title type="secondary" color="secondary">
            This is an example text with title secondary
          </Title>
          <Title type="tertiary" color="main">
            This is an example text with title tertiary
          </Title>
          <Title type="tertiary" color="primary">
            This is an example text with title tertiary
          </Title>
          <Title type="tertiary" color="secondary">
            This is an example text with title tertiary
          </Title>
          <Title type="subtitle">This is an example text with title subtitle</Title>
          <Title type="quaternary">This is an example text with title quaternary</Title>
          <Text type="primary">This is an example text with text primary</Text>
          <Text>This is an example text with text regular</Text>
          <Text type="detail">This is an example text with text detail</Text>
          <Text strong>This is an example text with text regular strong</Text>
          <Text strong italic>
            This is an example text with text regular strong italic
          </Text>
          <Text spaced>This is an example text with text regular spaced</Text>
          We also have the Divider Component
        </BasicContainer>
      </BaseLayout.Content>
    </BaseLayout>
  );
};

export default HiddenPageForUs;
