import React, { Fragment } from 'react';
import './OrdersTable.scss';
import { MoreHorizontal, Check, X } from 'react-feather';
import { MoneyFormatter } from '@common/utils/Formaters';
import { Text, Title } from '@components/common';

export const ClientOrdersTable = ({ clientOrdersList, handleMore }) => {
  return (
    <div className="orders-table__container">
      <div className="orders-table__header">
        <Title className="tn-title tertiary">Fecha</Title>
        <Title className="tn-title tertiary" id="proyect">
          Proyecto
        </Title>

        <Title className="tn-title tertiary">Monto</Title>
        <Title className="tn-title tertiary">Tipo</Title>
        <Title className="tn-title tertiary">Método</Title>
        <Title className="tn-title tertiary">Pagada</Title>
        <Title className="tn-title tertiary">Más</Title>
      </div>
      <hr />
      <div className="orders-table__content">
        {clientOrdersList.map((order, index) => {
          return (
            <Fragment key={index}>
              <div className="orders-table__row">
                <Text type="detail" className="tn-text tn-text--detail tn-text--small">
                  {order.created_at}
                </Text>
                <Text type="detail" className="tn-text tn-text--detail tn-text--small" id="proyect">
                  {order.project_name}
                </Text>

                <Text type="detail" className="tn-text tn-text--detail tn-text--small">
                  {MoneyFormatter(order.total_payment)}
                </Text>
                <Text type="detail" className="tn-text tn-text--detail tn-text--small">
                  {order.receipt_type == 'bill' ? 'boleta' : 'factura'}
                </Text>
                <Text type="detail" className="tn-text tn-text--detail tn-text--small">
                  {order.payment_type == 'deposit' ? 'transferencia' : order.payment_type}
                </Text>
                <Text type="detail" className="tn-text tn-text--detail tn-text--small">
                  {order.paid ? <Check /> : <X />}
                </Text>

                <Text type="detail" className="tn-text tn-text--detail tn-text--small">
                  <MoreHorizontal
                    onClick={() => {
                      handleMore(order);
                    }}
                  />
                </Text>
              </div>
              <hr />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
