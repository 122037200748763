import React from 'react';
import { deleteService_v2, getPrivateService, getServiceQuestions } from 'actions/services';
import { replyQuestion } from 'actions/projects';
import { scrollToBottom } from 'common/utils/WindowFocus';

import { useHistory, useParams } from 'react-router-dom';
import { ModalConfirm } from 'components/common/ModalConfirm';
import { useDispatch } from 'react-redux';
import { createMessage } from 'actions/messages';
import { serviceMessages } from 'pages/ServicesForm/ServicesForm.constants';

export const FreelancerPublicationsManagementViewModel = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [currentService, setCurrentService] = React.useState();
  const [isLoadingService, setIsLoadingService] = React.useState(false);

  const [questionsList, setQuestionsList] = React.useState([]);
  const [isLoadingQuestions, setIsLoadingQuestions] = React.useState(false);

  React.useEffect(() => {
    setIsLoadingService(true);
    getPrivateService(id)
      .then((res) => {
        setCurrentService(res.data);
        setIsLoadingService(false);
      })
      .catch(() => setIsLoadingService(false));
  }, [id]);

  React.useEffect(() => {
    setIsLoadingQuestions(true);
    if (currentService) {
      getServiceQuestions(currentService.id)
        .then((res) => {
          setQuestionsList(res.data.objects);
          setIsLoadingQuestions(false);
        })
        .catch(() => {
          setQuestionsList([]);
          setIsLoadingQuestions(false);
        });
    }
  }, [currentService]);

  const handleReplyQuestion = (id, answerText) => {
    setIsLoadingQuestions(true);
    replyQuestion(id, answerText)
      .then((res) => {
        setQuestionsList([...questionsList.filter((q) => q.id != id), res.data.request]);
        setIsLoadingQuestions(false);
        scrollToBottom(`publication-questions-${res.data.request.id}`);
      })
      .catch(() => {
        setIsLoadingQuestions(false);
      });
  };
  const handleDeleteService = ({ id }) => {
    ModalConfirm({
      title: serviceMessages.deleteModalConfirm.title,
      content: serviceMessages.deleteModalConfirm.content,
      onOk: () => {
        deleteService_v2({ id })
          .then(() => {
            history.push('/management/freelancer/publications');
            dispatch(createMessage({ successMessage: serviceMessages.deleteService.success }));
          })
          .catch(() => {
            dispatch(
              createMessage({
                errorMessage: serviceMessages.deleteService.error,
              })
            );
          });
      },
      onCancel: () => null,
    });
  };
  const handleGoToUpdateService = ({ id }) => {
    history.push(`/updateService/${id}`);
  };

  return {
    currentService,
    isLoadingService,
    questionsList,
    isLoadingQuestions,
    handleReplyQuestion,
    handleDeleteService,
    handleGoToUpdateService,
  };
};
