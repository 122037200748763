import {
  GET_PROJECTS,
  DELETE_PROJECT,
  ADD_PROJECT,
  CLEAR_PROJECTS,
  GET_MY_ACTIVE_PROJECTS,
  GET_KANBAN,
  MAX_RANGE_PROJECTS,
  GET_MY_ACTIVE_PROJECTS_COLLAB,
  UPDATE_MYACTIVEPROJECT,
  GET_PROJECTS_LOADING,
} from '../actions/types.js';
import { editProjectFromList } from '@common/utils/ProjectUtils';

const initialState = {
  projects: [],
  myActiveProjects: [],
  myActiveProjectsCollab: [],
  kanban: [],
  maxRangePrice: 200000,
  loadingProjects: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
        loadingProjects: false,
      };
    case GET_PROJECTS_LOADING:
      return {
        ...state,
        loadingProjects: true,
      };
    case DELETE_PROJECT:
      return {
        ...state,
        projects: state.projects.filter((project) => project.id !== action.payload),
      };
    case ADD_PROJECT:
      return {
        ...state,
        projects: [...state.projects, action.payload],
      };
    case CLEAR_PROJECTS:
      return {
        ...state,
        projects: [],
      };
    case GET_MY_ACTIVE_PROJECTS:
      return {
        ...state,
        myActiveProjects: action.payload,
      };
    case GET_MY_ACTIVE_PROJECTS_COLLAB:
      return {
        ...state,
        myActiveProjectsCollab: action.payload,
      };
    case MAX_RANGE_PROJECTS:
      return {
        ...state,
        maxRangePrice: action.payload,
      };
    case GET_KANBAN:
      return {
        ...state,
        kanban: action.payload,
      };
    case UPDATE_MYACTIVEPROJECT: {
      return {
        ...state,
        myActiveProjects: editProjectFromList(
          state.myActiveProjects,
          action.payload.id,
          action.payload.state
        ),
      };
    }
    default:
      return state;
  }
}
