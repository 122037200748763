export const BANKS_LIST = [
  { name: 'Banco De Chile - Edwards', value: 1 },
  { name: 'Banco Bice', value: 2 },
  //{"name": "BANCO CONSORCIO", "value": 3},
  { name: 'Banco Del Estado De Chile', value: 4 },
  //{"name": "Banco DO BRASIL S.A.", "value": 5},
  { name: 'Banco Falabella', value: 6 },
  //{"name": "Banco INTERNACIONAL", "value": 7},
  //{"name": "Banco PARIS", "value": 8},
  //{"name": "Banco RIPLEY", "value": 9},
  { name: 'Banco Santander', value: 10 },
  { name: 'Banco Security', value: 11 },
  { name: 'BBVA (BCO BILBAO VIZCAYA ARG)', value: 12 },
  { name: 'BCI (Bco De Credito e inv)', value: 13 },
  //{"name": "COOPEUCH", "value": 14},
  //{"name": "HSBC BANK", "value": 15},
  { name: 'ITAU Chile', value: 16 },
  { name: 'ITAU-Corpbanca', value: 17 },
  //{"name": "PREPAGO LOS HEROES", "value": 18},
  { name: 'Scotiabank', value: 19 },
];

export const ACCOUNT_TYPES = [
  { name: 'Cuenta Vista' },
  { name: 'Cuenta Corriente' },
  { name: 'Ahorro' },
];
export const WINDOW_TYPE_EDIT = 'edit';
export const WINDOW_TYPE_CREATE = 'create';
export const TIME_POPUP = 6;

export const ACCOUNT_NAME = 'name';
export const ACCOUNT_RUT = 'rut';
export const ACCOUNT_EMAIL = 'email';
export const ACCOUNT_BANK = 'bank';
export const ACCOUNT_ACCOUNT_TYPE = 'account_type';
export const ACCOUNT_NUMBER = 'account_number';

export const ACCOUNT_CARD_LISTS = [
  {
    title: 'Nombre Titular:',
    variableName: ACCOUNT_NAME,
    side: 'left',
  },
  {
    title: 'RUT:',
    variableName: ACCOUNT_RUT,
    side: 'left',
  },
  {
    title: 'Correo:',
    variableName: ACCOUNT_EMAIL,
    side: 'left',
  },
  {
    title: 'Banco:',
    variableName: ACCOUNT_BANK,
    side: 'right',
  },
  {
    title: 'Tipo de Cuenta:',
    variableName: ACCOUNT_ACCOUNT_TYPE,
    side: 'right',
  },
  {
    title: 'Número de Cuenta:',
    variableName: ACCOUNT_NUMBER,
    side: 'right',
  },
];
