/* eslint-disable no-unused-vars */
import React from 'react';
import { Title } from '@components/common';

import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HOME_ROUTE } from '@common/constants/routes.constants';
import { UPDATE_HOME_SWITCH } from 'actions/types';
import { CONTACT_PHONE } from 'common/constants/main.constants';
import './Section8.scss';
import Publication from 'components/layouts/PublicationModal/Publication';
import { Text } from 'components/common';
import { WhatsApp } from '@material-ui/icons';
import { useMediaQuery } from '@material-ui/core';
import AnimateOnView from 'components/AnimateOnView';

const Section8 = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const useUpdateHome = (value) => dispatch({ type: UPDATE_HOME_SWITCH, payload: value });

  const handleOnClickProjects = () => {
    useUpdateHome(true);
    history.push(HOME_ROUTE);
  };

  const isDesktop = useMediaQuery('(min-width:990px)');
  return (
    <>
      <div className="section8__section">
        <AnimateOnView>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}>
            <Title type="primary" color="primary" size={32}>
              ¿ Qué necesitas ?
            </Title>
            <Publication
              containerStyles={{
                maxWidth: '1000px',
                width: '100%',
                margin: '50px auto',
                display: 'flex',
                gap: '1em',
                justifyContent: isDesktop ? 'space-between' : 'center',
                flexWrap: 'wrap',
                padding: '0 1em',
                marginTop: '1em',
              }}
            />

            <Title type="primary" color="secondary">
              ¿ No tienes claro lo que necesitas ?
            </Title>
            <div style={{ display: 'flex', gap: '0.2em' }}>
              <Text>Te asesoramos</Text>
              <Text type="strong">gratis</Text>
            </div>
            <div className="whatsapp__section">
              <a
                className="button__whatsapp"
                href={`https://wa.me/${CONTACT_PHONE}`}
                target="_blank"
                rel="noreferrer">
                <WhatsApp />
                Escríbenos al WhatsApp
              </a>
            </div>
          </div>
        </AnimateOnView>
      </div>
    </>
  );
};

export default Section8;
