import { useState, useEffect } from 'react';
import { monthsToName } from './CustomCalendar.constants';

const useCustomCalendar = ({ datesWithStatus }) => {
  const [calendario, setCalendario] = useState([]);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [monthName, setMonthName] = useState('');

  useEffect(() => {
    const daysInMonth = new Date(year, month, 0).getDate();
    setCalendario(
      new Array(daysInMonth).fill(undefined).map((_, i) => {
        const fecha = `${year}-${month < 10 ? '0' + month : month}-${
          i < 9 ? '0' + (i + 1) : i + 1
        }`;
        const currentDate = currentDateWithStatus(datesWithStatus, fecha);
        return {
          fecha,
          status: currentDate?.status,
          title: currentDate?.title,
        };
      })
    );
  }, [month, year]);

  useEffect(() => {
    setMonthName(monthsToName[month]);
  }, [month]);
  const fillBackDaysArray = (date) => {
    const firstDay = new Date(date).getDay();
    const daysToFill = firstDay === 0 ? 6 : firstDay;
    return new Array(daysToFill).fill(undefined);
  };
  const currentDateWithStatus = (dates, current) => {
    return dates.find((date) => date.date === current);
  };

  const increaseYear = () => {
    setYear((prev) => prev + 1);
  };
  const decreaseYear = () => {
    setYear((prev) => prev - 1);
  };
  const increaseMonth = () => {
    if (month + 1 <= 12) {
      setMonth((prev) => prev + 1);
    } else {
      setMonth(1);
      increaseYear();
    }
  };
  const decreaseMonth = () => {
    if (month - 1 === 0) {
      setMonth(12);
      decreaseYear();
    } else {
      setMonth((prev) => prev - 1);
    }
  };
  const getDayString = (fecha) => {
    return fecha.slice(8, 10);
  };
  return {
    calendario,
    increaseYear,
    decreaseYear,
    increaseMonth,
    decreaseMonth,
    year,
    month,
    monthName,
    getDayString,
    fillBackDaysArray,
  };
};

export default useCustomCalendar;
