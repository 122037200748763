import React from 'react';
import { connect } from 'react-redux';
import { getUser } from '@actions/users';

class Profile extends React.Component {
  state = {
    loading: true,
  };

  async handleUpdateUser() {
    this.setState({ loading: true });
    var posOtherUser = 0;
    if (this.props.user.id === this.props.selectedChat.participants_id[0]) {
      posOtherUser += 1;
    }
    const otherUserId = this.props.selectedChat.participants_id[posOtherUser];
    if (this.props.otherUser) {
      if (this.props.otherUser.id !== otherUserId) {
        await this.props.getUser(otherUserId);
      }
    } else {
      await this.props.getUser(otherUserId);
    }
    this.setState({ loading: false });
  }

  async componentDidMount() {
    if (this.props.selectedChat) {
      this.handleUpdateUser();
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.selectedChat !== this.props.selectedChat) {
      this.handleUpdateUser();
    }
  }

  render() {
    return (
      <div className="contact-profile">
        {this.props.otherUser !== null && this.props.selectedChat !== null ? (
          <>
            <img className="contact-profile-img" src={this.props.otherUser.profile_image} alt="" />
            <div className="contact-profile-mid-section">
              <div className="contact-profile-mid-left">
                <p className="contact-profile-header">
                  Trabajando en
                  <a
                    className="goToProjectBtn"
                    href={`#/project/${this.props.selectedChat.project}/view`}>
                    {' '}
                    {this.props.selectedChat.title}
                  </a>
                </p>
                <p className="contact-profile-name">{this.props.otherUser.first_name}</p>
              </div>
              <div className="contact-profile-mid-right"></div>
            </div>
          </>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    selectedChat: state.chat.selectedChat,
    otherUser: state.navigation.user,
  };
};

export default connect(mapStateToProps, { getUser })(Profile);
