import React, { Fragment } from 'react';
import css from './FreelancerPublicationsManagment.module.scss';
import { FreelancerPublicationsManagementViewModel } from './FreelancerPublicationsManagementViewModel';
import { BaseLayout, LoadingDots, PublicationQuestions, CustomBreadcrumb } from 'components/common';
import { MainCard } from './MainCard';
import { ServiceDetails } from 'components/service/ServiceDetails';

function FreelancerPublicationsManagment() {
  const {
    currentService,
    isLoadingService,
    questionsList,
    isLoadingQuestions,
    handleReplyQuestion,
    handleDeleteService,
    handleGoToUpdateService,
  } = FreelancerPublicationsManagementViewModel();

  return (
    <Fragment>
      <CustomBreadcrumb type="freelancer-publication" title={currentService?.title} />
      <BaseLayout>
        <BaseLayout.Content>
          {!isLoadingService && currentService && (
            <Fragment>
              <MainCard
                currentService={currentService}
                handleDeleteService={handleDeleteService}
                handleGoToUpdateService={handleGoToUpdateService}
              />
              <div className={css.content}>
                <div className={css.section}>
                  <ServiceDetails currentService={currentService} />
                </div>
                <div className={css.section}>
                  {isLoadingQuestions ? (
                    <LoadingDots />
                  ) : (
                    <PublicationQuestions
                      type="service"
                      questionsList={questionsList}
                      handleReplyQuestion={handleReplyQuestion}
                    />
                  )}
                </div>
              </div>
            </Fragment>
          )}
        </BaseLayout.Content>
      </BaseLayout>
    </Fragment>
  );
}

export default FreelancerPublicationsManagment;
