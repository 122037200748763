import React, { Fragment } from 'react';
import { getSubCategories_v2 } from 'actions/categories';
import css from './SubCategories.module.scss';
import { MultipleChoiceRadio, LoadingSpin, Text } from 'components/common';

function SubCategories({ categoryId, selectCategory, isSelected }) {
  const [isLoading, setIsLoading] = React.useState(true);
  const [subCategories, setSubCategories] = React.useState([]);

  React.useEffect(() => {
    setIsLoading(true);
    getSubCategories_v2(categoryId)
      .then((res) => {
        setSubCategories(res.data);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, []);

  return (
    <div className={css.container}>
      {!isLoading ? (
        <Fragment>
          {subCategories.map((sub, index) => {
            return (
              <div
                className={css.subCategory}
                key={`sub-cat-${index}`}
                onClick={() => selectCategory(sub)}>
                <MultipleChoiceRadio isSelected={isSelected(sub)} />
                <Text>{sub.name}</Text>
              </div>
            );
          })}
        </Fragment>
      ) : (
        <div style={{ textAlign: 'center', width: '100%' }}>
          <LoadingSpin />
        </div>
      )}
    </div>
  );
}

export default SubCategories;
