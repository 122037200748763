import React from 'react';
import { ManagmentViewModel } from '../../../ManagmentViewModel';
import projectService from '@services/projectService';
import {
  PAYMENT_PROJECTS_LABEL,
  PUBLISHED_PROJECTS,
  ALL_PUBLICATIONS,
} from '@common/constants/project.constants';

export const ClientPublicationsListViewModel = () => {
  const { authUser } = ManagmentViewModel();

  const [isLoadingPublications, setIsLoadingPublications] = React.useState(false);
  const [publicationsList, setPublicationsList] = React.useState([]);

  const [currentFilter, setCurrentFilter] = React.useState(ALL_PUBLICATIONS);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPublications, setTotalPublications] = React.useState(0);

  React.useEffect(() => {
    setIsLoadingPublications(true);
    if (authUser) {
      projectService
        .getUserProjects(authUser.user.id, currentFilter, 'owner', currentPage)
        .then((res) => {
          setPublicationsList(res.data.objects);
          setTotalPublications(res.data.total_objects);
          setIsLoadingPublications(false);
        })
        .catch(() => {
          setPublicationsList([]);
          setTotalPublications(0);
          setIsLoadingPublications(false);
        });
    }
  }, [currentFilter, currentPage]);

  const project_types = [
    { id: ALL_PUBLICATIONS, name: 'Todas', tag: 1 },
    { id: PUBLISHED_PROJECTS, name: 'Publicado', tag: 2 },
    { id: PAYMENT_PROJECTS_LABEL, name: 'En pago', tag: 3 },
  ];

  const handleOnClickCategory = async (categoryId) => {
    setCurrentFilter(categoryId);
  };

  const handlePagination = (event) => {
    setCurrentPage(event);
  };

  return {
    isLoadingPublications,
    authUser,
    publicationsList,
    project_types,
    handleOnClickCategory,
    currentFilter,
    handlePagination,
    totalPublications,
    currentPage,
  };
};
