export function RenderTimestamp(created_at) {
  let prefix = '';
  const timeDiff = Math.round((new Date().getTime() - new Date(created_at).getTime()) / 60000);
  if (timeDiff <= 1) {
    // less than one minute ago
    prefix = `Hace menos de un minuto`;
  } else if (timeDiff < 60 && timeDiff > 1) {
    prefix = `Hace ${timeDiff} minutos`;
  } else if (timeDiff < 24 * 60 && timeDiff >= 60) {
    if (timeDiff === 60) {
      prefix = `Hace 1 hora`;
    } else {
      prefix = `Hace ${Math.round(timeDiff / 60)} horas`;
    }
  } else if (timeDiff < 24 * 60 * 31 && timeDiff >= 60 * 24) {
    if (Math.round(timeDiff / (60 * 24)) === 1) {
      prefix = `Hace 1 día`;
    } else {
      prefix = `Hace ${Math.round(timeDiff / (60 * 24))} días`;
    }
  } else if (timeDiff < 24 * 60 * 31 * 12 && timeDiff >= 60 * 24 * 31) {
    let month = Math.round(timeDiff / (24 * 60 * 31));
    if (month === 1) {
      prefix = `Hace 1 mes`;
    } else if (month >= 2 && month < 12) {
      prefix = `Hace ${month} meses`;
    } else if (month === 12) {
      prefix = `Hace 1 año`;
    }
  } else {
    prefix = `Hace más de 1 año`;
  }
  return prefix;
}
