/* eslint-disable no-unused-vars */
import React from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import Header from 'components/layouts/Header';
import Alerts from './components/layouts/Alerts/Alerts.js';
import { Footer } from './components/layouts/Footer/Footer/Footer';
import { TermsAndConditions } from './components/layouts/Footer/TermsAndConditions/TermsAndConditions';
import { Mediation } from './components/layouts/Footer/Mediation/Mediation';
import { PrivacyPolicy } from './components/layouts/Footer/PrivacyPolicy/PrivacyPolicy';
import { BehaviourAndSanctions } from './components/layouts/Footer/BehaviourAndSanctions/BehaviourAndSanctions';
import { DataManagement } from './components/layouts/Footer/DataManagement/DataManagement';
import { FrequentQuestions } from './components/layouts/Footer/FrequentQuestions/FrequentQuestions';
import PrivateRoute from './components/common/PrivateRoute';
import Welcome from '@pages/Welcome';
import RecoverPassword from './components/accounts/RecoverPassword/RecoverPassword';
import ForgotPassword from './components/accounts/ForgotPassword/ForgotPassword';
import ChangePassword from './components/accounts/ChangePassword/ChangePassword';
import { Provider } from 'react-redux';
import store from './store';
import { loadUser, setLoading } from '@actions/auth';

import { NewProjectForm } from 'pages/NewProjectForm';
import { PaymentVoucherPage } from './pages/PaymentVoucher';
import { HowItWorksPage } from './pages/HowItWorks';
import { HiddenPage } from './pages/HiddenPage';
import { AboutUs } from './pages/AboutUs';
import ChatPage from './pages/ChatTest';
import setupInterceptors from './services/setupInterceptors';
import Managment from './pages/Managment/Managment';
import WhatsappButton from './components/common/WhatsappButton/WhatsappButton';
import NotAllowedPage from './pages/Miscellaneous/NotAllowed';
import { Blog } from './pages/Blog';

import { LiveChatDashboard } from './components/liveChat';

import config from './config';

import Landing from './pages/Landing';

import ScrollToTop from './components/common/ScrollToTop/ScrollToTop';

import '@assets/styles/App.scss';
import PublicProject from './pages/Project/PublicProject';
import { PublicService } from './pages/Service/PublicService/PublicService';
import { Notifications } from 'pages/Managment/Notifications/index.js';

import { hotjar } from 'react-hotjar';
import PublicFreelance from 'pages/FreelanceProfile/PublicFreelance/PublicFreelance.jsx';
import { refreshLastLogin } from 'actions/auth.js';
import { AuthButtonsProvider } from 'context/AuthButtonsContext.jsx';
import CMSFormBlue from 'pages/CMSForm/CMSForm/index.jsx';
import IntegrationsForm from 'pages/CMSForm/IntegrationsForm/index.jsx';
import SelectYourForm from 'pages/CMSForm/SelectYourForm.jsx';
import ChatWidget from 'components/common/ChatWidget/index.jsx';
import ChatsPage from 'pages/Chats/index.jsx';
import ChatsMobilePage from 'pages/Chats/Mobile/index.jsx';
import { ChatWidgetProvider } from 'context/ChatWidgetContext.jsx';

const tagManagerArgs = {
  gtmId: config.googleTagManagerId,
};

const App = () => {
  window.dataLayer.push({
    event: 'pageview',
  });

  React.useEffect(() => {
    if (localStorage.getItem('token')) {
      store.dispatch(loadUser());
      refreshLastLogin();
    } else {
      store.dispatch(setLoading(false));
    }
    TagManager.initialize(tagManagerArgs);
    hotjar.initialize(3288728, 6);
  }, []);

  const haveVisited = localStorage?.getItem('token');

  return (
    <Provider store={store}>
      <AuthButtonsProvider>
        <Router>
          <ScrollToTop>
            <div className="grey-background">
              <ChatWidgetProvider>
                <WhatsappButton />
                <ChatWidget />
                <Header />
                <Alerts />
                <Switch>
                  <Route exact path="/">
                    {haveVisited ? <Redirect to="/home" /> : <Redirect to="/landing" />}
                  </Route>
                  <Route exact path="/home" component={Welcome} />
                  <Route exact path="/landing" component={Landing} />
                  <Route exact path="/changePassword" component={ChangePassword} />
                  <Route exact path="/recoverPassword" component={RecoverPassword} />
                  <Route exact path="/forgotPassword" component={ForgotPassword} />
                  <PrivateRoute exact path="/management/:type/:view/:id" component={Managment} />
                  <PrivateRoute exact path="/management/:type/:view" component={Managment} />
                  <PrivateRoute exact path="/management/notifications" component={Notifications} />
                  <Route exact path="/project/:projectId/view" component={PublicProject} />
                  <Route exact path="/service/:serviceId/view" component={PublicService} />
                  <Route exact path="/freelance-profile/:userId/view" component={PublicFreelance} />

                  <Route exact path="/paymentVoucher" component={PaymentVoucherPage} />
                  <Route exact path="/Behaviour&Sanctions" component={BehaviourAndSanctions} />
                  <Route exact path="/Mediation" component={Mediation} />
                  <Route exact path="/Terms&Conditions" component={TermsAndConditions} />
                  <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
                  <Route exact path="/DataManagement" component={DataManagement} />
                  <Route exact path="/FrequentQuestions" component={FrequentQuestions} />
                  <Route exact path="/HowItWorks/:type" component={HowItWorksPage} />
                  <Route exact path="/AboutUs" component={AboutUs} />
                  <Route exact path="/blog/" component={Blog} />
                  <Route exact path="/blog/:id/:title" component={Blog} />
                  <Route exact path="/blog/:id" component={Blog} />
                  <Route exact path="/hiddenPageForUs" component={HiddenPage} />
                  <Route exact path="/notAllowed/:error" component={NotAllowedPage} />
                  <PrivateRoute exact path="/chats" component={ChatsPage} />
                  <PrivateRoute exact path="/my-chats" component={ChatsMobilePage} />

                  <Route exact path="/forms" component={SelectYourForm} />
                  <Route exact path="/cms-form" component={CMSFormBlue} />
                  <Route exact path="/integrations-form" component={IntegrationsForm} />
                  <Route exact path="/createProject/:type" component={NewProjectForm} />
                  <PrivateRoute exact path="/createProject/:type/:id" component={NewProjectForm} />

                  <Redirect exact from="/project/:projectId" to="/project/:projectId/view" />
                </Switch>
                <Footer />
              </ChatWidgetProvider>
            </div>
          </ScrollToTop>
        </Router>
      </AuthButtonsProvider>
    </Provider>
  );
};

setupInterceptors(store);

export default App;
