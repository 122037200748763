import React, { Fragment } from 'react';
import {
  BaseLayout,
  EmptyListCard,
  CustomBreadcrumb,
  LoadingDots,
  Title,
} from '@components/common';
import { ClientProjectsListViewModel } from './ClientProjectsListViewModel';
import { MyProjectsMainCard } from '@components/project/ProjectMainCard/MyProjectsMainCard/MyProjectsMainCard';
import { FilterTypes, InformativeAdvice } from '@components/common';
import { PERSON_2_ILUSTRATION } from '@common/constants/informativeIlustrations.constants';
import css from './ClientProjectList.module.scss';
import { Pagination } from 'antd';

const ClientProjectsList = () => {
  const {
    isLoadingHiringProjectsList,
    hiringProjectsList,
    project_types,
    handleOnClickCategory,
    currentFilter,
    handlePagination,
    totalProjects,
    currentPage,
  } = ClientProjectsListViewModel();

  return (
    <Fragment>
      <CustomBreadcrumb type="client-project" />
      <BaseLayout>
        <BaseLayout.LeftSide>
          <div className={css.layoutContainer}>
            <div className="tn-layout__left-section">
              <div className="my-projects-list__filters">
                <Title type="secondary-bold" color="primary">
                  Tus proyectos activos:{' '}
                </Title>
                <hr />
                <FilterTypes
                  filter_types={project_types}
                  onSelect={handleOnClickCategory}
                  selectedState={currentFilter}
                />
              </div>
            </div>

            <div className="tn-layout__left-section">
              {' '}
              <InformativeAdvice
                image={PERSON_2_ILUSTRATION}
                message="Acá puedes encontrar el listado de los proyectos activos en los que estás contratando. Presiona alguno para ver más detalles."
              />
            </div>
          </div>
        </BaseLayout.LeftSide>
        <BaseLayout.Content>
          <div className={css.content}>
            {' '}
            {!isLoadingHiringProjectsList && hiringProjectsList ? (
              hiringProjectsList.length == 0 ? (
                <EmptyListCard type="no-project-publication" />
              ) : (
                <Fragment>
                  {hiringProjectsList.map((project, index) => {
                    return (
                      <MyProjectsMainCard
                        type="list"
                        currentProject={project}
                        currentFreelancer={project.freellance_data}
                        key={index}
                      />
                    );
                  })}
                  <div className={css.paginationContainer}>
                    {' '}
                    <Pagination
                      onChange={handlePagination}
                      pageSize={10}
                      total={totalProjects}
                      current={currentPage}
                      showSizeChanger={false}
                    />
                  </div>
                </Fragment>
              )
            ) : (
              <LoadingDots />
            )}
          </div>
        </BaseLayout.Content>
      </BaseLayout>
    </Fragment>
  );
};

export default ClientProjectsList;
