import React, { Fragment } from 'react';
import './PersonalData.scss';
import { Input, Select, DatePicker, Space } from 'antd';
import { Button, Text, Title } from '@components/common';
import moment from 'moment';
import { COUNTRY_PREFIX_LISTS } from 'common/constants/registercountries.constants';

const { Option } = Select;

const PersonalData = ({
  userData,
  editingPersonalData,
  setEditingPersonalData,
  handleInputChange,
  handleSelectChange,
  handleonChangeBirth,
  handleEditUser,
}) => {
  return (
    <div className="personal-data__container">
      <form onSubmit={handleEditUser}>
        <div className="personal-data__header">
          <Title type="tertiary" color="primary">
            Datos Personales:
          </Title>
          {editingPersonalData ? (
            <Button
              text="Guardar"
              type="submit"
              variant="secondary"
              borderColor="primary"
              size="sm"
            />
          ) : (
            <Fragment>
              <Button
                text={<i className="fas fa-pencil-alt"></i>}
                variant="edit"
                type="button"
                onClick={() => setEditingPersonalData(true)}></Button>
            </Fragment>
          )}
        </div>
        <hr />
        <div className="personal-data__row">
          <Text type="strongBlue">Nombre: </Text>
          {editingPersonalData ? (
            <Input
              maxLength={20}
              defaultValue={userData.first_name}
              name="first_name"
              onChange={handleInputChange}
            />
          ) : (
            <Text>{userData.first_name}</Text>
          )}
        </div>
        <div className="personal-data__row">
          <Text type="strongBlue">Apellidos: </Text>
          {editingPersonalData ? (
            <Input
              maxLength={20}
              defaultValue={userData.last_name}
              name="last_name"
              onChange={handleInputChange}
            />
          ) : (
            <Text>{userData.last_name}</Text>
          )}
        </div>
        <div className="personal-data__row">
          <Text type="strongBlue">Genero: </Text>

          <Select
            id="gender"
            className="select-gender"
            name="gender"
            onChange={handleSelectChange}
            defaultValue={userData.gender}
            disabled={!editingPersonalData}>
            <Option value="male">Masculino</Option>
            <Option value="female">Femenino</Option>
            <Option value="other">Sin Especificar</Option>
          </Select>
        </div>
        <div className="personal-data__row">
          <Text type="strongBlue">Fecha de Nacimiento: </Text>
          <Space id="birth-date-input" direction="vertical">
            <DatePicker
              format={'DD-MM-YYYY'}
              name="date_of_birth"
              onChange={handleonChangeBirth}
              defaultValue={userData.date_of_birth && moment(userData.date_of_birth, 'DD-MM-YYYY')}
              disabled={!editingPersonalData}
            />
          </Space>
        </div>
        <div className="personal-data__row">
          <Text type="strongBlue">Email: </Text>
          <Text>{userData.email}</Text>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Text type="strongBlue">Número contacto: </Text>
          {editingPersonalData ? (
            <div style={{ display: 'flex', gap: 8, width: '100%', maxWidth: 410 }}>
              <Select
                placeholder="Prefijo"
                name="phone_prefix"
                defaultValue={userData?.phone_prefix}
                onChange={(e) => handleInputChange({ target: { value: e, name: 'phone_prefix' } })}>
                {COUNTRY_PREFIX_LISTS.map((country, key) => (
                  <Option value={country.prefix} key={`opt-${key}`}>
                    {country.name + ' ' + country.prefix}
                  </Option>
                ))}
              </Select>
              <Input
                maxLength={20}
                defaultValue={userData.phone_number}
                name="phone_number"
                onChange={handleInputChange}
              />
            </div>
          ) : (
            <Text style={{ maxWidth: 410, width: '100%' }}>
              {userData?.phone_prefix} {userData?.phone_number}
            </Text>
          )}
        </div>
      </form>
      <Text type="smallDetail">
        {' '}
        * Los datos mostrados son confidenciales y TeNecesito no se hace cargo por la exposicion,
        divulgacion o mal uso de estos. Te recordamos que TeNecesito nunca te pedira ninguna clave,
        ni por correo ni por nuestra pagina.
      </Text>
    </div>
  );
};

export default PersonalData;
