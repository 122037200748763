/* eslint-disable no-unused-vars */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import debounce from 'lodash/debounce';
import DropWrapper from '../DropWrapper/DropWrapper';
import Col from '../Col/Col';
import Item from '../Item/Item';
import { getKanban, modifyBoard, debouncedmodifyBoard } from '@actions/kanban';
import { getPrivateProject } from '@actions/projects.js';

import { PlusOutlined } from '@ant-design/icons';
import './BoardDashboard.scss';
import { Title } from '@components/common';

const statusIcons = [
  {
    status: 'Tareas Pendientes',
    icon: '⭕️',
  },
  {
    status: 'En Proceso',
    icon: '🔆️',
  },
  {
    status: 'En Revisión',
    icon: '📝',
  },
  {
    status: 'Listo',
    icon: '✅',
  },
];

export class BoardDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      dragEl: null,
      loading: true,
    };
    this.lastFetchId = 0;
    this.updatePositions = debounce(this.updatePositions, 800);
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const kanbanboard = await this.props.getKanban(this.props.projectId);
    this.setState({ items: kanbanboard, loading: false });
    if (kanbanboard.length === 0 && !this.props.disabled) {
      const { data: project } = await getPrivateProject(this.props.projectId);
      project.stages.forEach((stage, idx) => {
        if (idx === 0) {
          this.onAddItem('En Proceso', stage.name);
        } else {
          this.onAddItem('Tareas Pendientes', stage.name);
        }
      });
    }
  }

  updatePositions = async (el) => {
    this.lastFetchId += 1;
    const fetchId = this.lastFetchId;
    const positions = [];
    var position = 0;
    this.state.items
      .filter((item) => item.status === el.status)
      .map((item) => {
        const itemdict = { id: item.id, position: position };
        positions.push(itemdict);
        position += 1;
      });
    const request = { UPDATE: positions };
    await this.props.debouncedmodifyBoard(request, fetchId, this.lastFetchId);
  };

  onDrop = async (item, status) => {
    if (item.status == status) {
      return;
    }
    const mapping = statusIcons.find((si) => si.status === status);
    const position = this.state.items.filter((i) => i.status === status).length + 1;
    this.updatePositions(item);
    const newItems = this.state.items
      .filter((i) => i.id !== item.id)
      .concat({ ...item, status, icon: mapping.icon, position: position });
    this.setState({ items: newItems });
    await this.props.modifyBoard({
      UPDATE: [{ id: item.id, status: status, position: position }],
    });
  };

  moveItem = (el) => {
    const itemIndex = this.state.items.findIndex((i) => i.id === this.state.dragEl.id);
    const hoverIndex = this.state.items.findIndex((i) => i.id === el.id);
    const newState = this.state.items;
    newState.splice(itemIndex, 1);
    newState.splice(hoverIndex, 0, this.state.dragEl);
    this.setState({ items: newState });
    this.updatePositions(this.state.dragEl);
  };

  handleDelete = async (item) => {
    const newItems = this.state.items.filter((i) => i.id !== item.id);
    this.setState({ items: newItems });
    await this.props.modifyBoard({ DELETE: [{ id: item.id }] });
  };

  handleUpdate = async (item) => {
    const itemIndex = this.state.items.findIndex((i) => i.id === item.id);
    const newItem = this.state.items[itemIndex];
    newItem['title'] = document.getElementById('board-item-title').value;
    newItem['content'] = document.getElementById('board-item-description').value;
    newItem['estimate'] = document.getElementById('board-item-estimate').value;
    newItem['priority'] = document.getElementById('board-item-priority').value;
    const newItems = this.state.items;
    newItems[itemIndex] = newItem;
    this.setState({ items: newItems });
    await this.props.modifyBoard({
      UPDATE: [
        {
          id: item.id,
          title: newItem['title'],
          content: newItem['content'],
          priority: newItem['priority'],
          estimate: newItem['estimate'],
        },
      ],
    });
  };

  setDragElement = (el) => {
    this.setState({ dragEl: el });
  };

  onAddItem = async (col, title) => {
    const status = statusIcons.find((si) => si.status === col);
    const position = this.state.items.filter((i) => i.status === status).length + 1;
    const new_item = await this.props.modifyBoard({
      CREATE: [
        {
          status: status.status,
          project: this.props.projectId,
          title: title || 'Nueva Tarjeta',
          content: 'agrega más detalles!',
          issueType: 'gg-bookmark',
          priority: 'Alta',
          estimate: 0,
          position: position,
        },
      ],
    });
    const final_list = this.state.items.concat(new_item);
    this.setState({ items: final_list });
  };

  render() {
    return this.state.loading ? (
      'Cargando'
    ) : (
      <div
        className="kanban-container"
        style={this.props.disabled ? { backgroundColor: 'rgba(255,255,255,0.8)' } : {}}>
        {[
          { id: 'Tareas Pendientes', label: 'Tareas Pendientes' },
          { id: 'En Proceso', label: 'En Proceso' },
          { id: 'En Revisión', label: 'En Revisión' },
          { id: 'Listo', label: 'Listo' },
        ].map((status) => {
          return (
            <div className="kanban-ctn" key={status.id}>
              <div className={'col-wrapper'}>
                <div className={'col-group'}>
                  <Title className="center" color="primary">
                    {status.label}
                  </Title>
                </div>
                <DropWrapper onDrop={this.onDrop} status={status.id}>
                  <Col>
                    {!this.props.disabled && (
                      <button
                        onClick={() => this.onAddItem(status.id)}
                        className="kanban-add-card-button">
                        <PlusOutlined className="plus-icon" />
                      </button>
                    )}
                    {this.state.items
                      .filter((i) => i.status === status.id)
                      .map((i) => (
                        <Item
                          key={i.id}
                          disabled={this.props.disabled}
                          item={i}
                          moveItem={this.moveItem}
                          setDragElement={this.setDragElement}
                          handleDelete={this.handleDelete}
                          handleUpdate={this.handleUpdate}
                        />
                      ))}
                  </Col>
                </DropWrapper>
              </div>
              <hr className="border-divider-kanban" />
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  modifyBoard,
  getKanban,
  debouncedmodifyBoard,
})(BoardDashboard);
