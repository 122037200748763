import { Modal } from 'antd';
import React from 'react';
import { Title } from '../Title';
import css from './StandardModal.module.scss';
const StandardModal = ({ visible, onOk, onCancel, children, button, title, ...rest }) => {
  return (
    <Modal
      className={css.container}
      visible={visible}
      centered
      onOk={onOk}
      onCancel={onCancel}
      footer={null}
      {...rest}>
      <div className={css.footer}>
        <Title
          type="tertiary"
          color="primary"
          style={{ marginBottom: '1em', fontSize: 18, textAlign: 'center', padding: '0 1em' }}>
          {title}
        </Title>
        {children}
        <div className={css.buttonContainer}>{button}</div>
      </div>
    </Modal>
  );
};

export default StandardModal;
