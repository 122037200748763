export const stepsMessages = {
  Modals: {
    apoveStage: {
      title: '¿Estas seguro que quieres aceptar la etapa?',
      content:
        'Una vez aceptada, la etapa quedará considerada como finalizada por completo y se liberará el valor de esta al freelancer.',
    },
    submitStage: (name) => ({
      client: 'El freelancer ha solicitado aprobar la etapa y ha dejado el siguiente comentario:',
      freelancer: `Espera a que ${name} revise y acepte tu entrega. Una vez aprobada la etapa se liberará el pago correspondiente`,
    }),
  },
};
