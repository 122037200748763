import React from 'react';
import { PostCard } from '../PostCard';
import { Pagination } from 'antd';
import { LoadingDots } from 'components/common';
import css from './PostsList.module.scss';

function PostsList({
  postsList,
  isLoadingPosts,
  handleClickBlog,
  handlePagination,
  totalObjects,
  recentBlogs,
}) {
  return isLoadingPosts ? (
    <LoadingDots />
  ) : (
    <div className={css.container}>
      {recentBlogs && (
        <>
          <h3 className="tn-title secondary">{'Los mas vistos del mes'}</h3>
          <hr />
          <div className={css.postsContainer}>
            {recentBlogs?.map((post, index) => {
              return (
                <div className={css.postCard} key={`post-${index}`}>
                  <PostCard post={post} handleClickBlog={handleClickBlog} list={true} />
                </div>
              );
            })}
          </div>
        </>
      )}

      <h3 className="tn-title secondary">{'Todas las publicaciones 👍'}</h3>

      <hr />
      <div className={css.postsContainer}>
        {postsList
          .map((post, index) => {
            return (
              <div className={css.postCard} key={`post-${index}`}>
                <PostCard post={post} handleClickBlog={handleClickBlog} list={true} />
              </div>
            );
          })
          .slice(3)}
      </div>

      <div className="welcome-pagination-container">
        <Pagination
          onChange={handlePagination}
          defaultPageSize={25}
          total={totalObjects}
          showSizeChanger={false}
        />
      </div>
    </div>
  );
}

export default PostsList;
