import React from 'react';
import { Button } from '@components/common';
import { MyCarousel } from '@components/common/CardCarousel';
import { useHistory } from 'react-router-dom';
import { HOME_ROUTE } from 'common/constants/routes.constants';
import './Section4.scss';
import AnimateOnView from 'components/AnimateOnView';

const Section4 = () => {
  const history = useHistory();
  const handleGoToHowItWorks = () => {
    history.push(HOME_ROUTE);
    window.localStorage.setItem('switchStatus', 'Contratar');
  };
  return (
    <div className="section4__container">
      <AnimateOnView>
        <div className="section4__content">
          <MyCarousel />
          <div style={{ width: 340, margin: '0 auto', paddingTop: 32 }}>
            <Button
              onClick={handleGoToHowItWorks}
              text="Visita nuestros freelancers"
              type="landing-btn"
              variant="freelancer"
            />
          </div>
        </div>
      </AnimateOnView>
    </div>
  );
};

export default Section4;
