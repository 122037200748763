/* eslint-disable no-unused-vars */
import React from 'react';
import '../ProjectMainCard.scss';
import {
  StatusTag,
  SkillsBeans,
  ManagementCardLayout,
  DropdownMenuItem,
  Button,
  CustomSteps,
  Text,
  Title,
} from 'components/common';
import './PublicationManagmentMainCard.scss';
import { PAYMENT_PROJECTS_LABEL } from '@common/constants/project.constants';
import ProfilePicture from 'components/common/ProfilePicture/ProfilePicture';
import { useSelector } from 'react-redux';
import { selectAuthUserInfo } from 'reducers/auth';

export const PublicationManagmentMainCard = ({
  currentProject,
  handleEditProject,
  handleDeleteProject,
  handleOpenPaymentDrawer,
}) => {
  const user = useSelector(selectAuthUserInfo);
  const withAction = [
    {
      label: 'Editar',
      callback: () => handleEditProject(),
    },
    {
      label: 'Eliminar',
      callback: () => handleDeleteProject(),
    },
  ];

  const isValidatingPayment = PAYMENT_PROJECTS_LABEL.includes(currentProject?.status);
  return (
    currentProject && (
      <ManagementCardLayout
        className={!currentProject?.active && 'proyect-card__deactivate'}
        style={{ paddingBottom: 16 }}>
        <div className="publication-card__blue-header">
          <div className="publication-card__edit">
            <DropdownMenuItem
              hoverButton=""
              options={isValidatingPayment ? [withAction[1]] : withAction}
            />
          </div>
        </div>

        <ManagementCardLayout.LeftSide>
          <CustomSteps status={currentProject?.status} type="publicacion" />
          <ProfilePicture user={user} width={100} withEditButton={false} withName={true} />
        </ManagementCardLayout.LeftSide>
        <ManagementCardLayout.RightSide>
          {' '}
          <div className="project-card__project">
            {' '}
            <div className="project-card__header">
              <Text type="detail">Proyecto: </Text>
              <StatusTag project={currentProject} />
            </div>
            <div className="project-card__content">
              <Title type="secondary-bold" color="primary">
                {currentProject?.title}
              </Title>
              <div className="project-card__categories">
                <Text type="strong">{currentProject?.project_category_name}</Text>
                <div className="project-card__subcategories">
                  {currentProject?.all_categories ? (
                    <Text>
                      Me gustaría que los freelancers me hagan una recomendación en su propuesta
                    </Text>
                  ) : (
                    <>
                      {currentProject?.project_subcategories_names.map((subcategory, index) => {
                        return (
                          <Text key={index}>
                            {currentProject?.project_subcategories_names.length == index + 1
                              ? subcategory
                              : `${subcategory} /`}
                          </Text>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>

              <SkillsBeans skills={currentProject?.skills_names} />
            </div>
          </div>
        </ManagementCardLayout.RightSide>
        {/* <ManagementCardLayout.Buttons>
          <div className="project-card__buttons">
            {PAYMENT_PROJECTS_LABEL.includes(currentProject.status) && (
              <Button
                text="Pagar"
                variant="primary"
                color="secondary"
                size="xs"
                style={{ padding: '6px 20px' }}
                onClick={() => handleOpenPaymentDrawer()}
              />
            )}
          </div>
        </ManagementCardLayout.Buttons> */}
      </ManagementCardLayout>
    )
  );
};
