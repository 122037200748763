import React, { Component } from 'react';
import { Collapse } from 'antd';
import '@assets/styles/layouts/LegalFiles.scss';
import { Text, Title } from '@components/common';
const { Panel } = Collapse;

export class TermsAndConditions extends Component {
  render() {
    return (
      <div className="custom-container">
        <div className="documents-ctn">
          <Title size="25" type="secondary" color="primary">
            Términos y Condiciones
          </Title>
          <Text type="legalFiles">
            Antes de acceder a los servicios, o utilizarlos, lea atentamente estos términos y
            condiciones, los cuales tienen un carácter obligatorio y vinculante. Cualquier persona
            que no los acepte, deberá abstenerse de utilizar el sitio
          </Text>
          <Collapse defaultActiveKey={['1']}>
            <Panel header="1.	Antecedentes generales del Sitio Web" key="1">
              <Text type="big">
                Los usuarios que ingresen o accedan al Sitio Web{' '}
                <a href="http://www.tenecesito.cl/">www.tenecesito.cl</a> (en adelante “Sitio Web”),
                deberán aceptar expresa y totalmente estos Términos y Condiciones (en adelante
                “Términos”), los cuales serán íntegramente aplicables a todos los usuarios que
                ingresen y decidan utilizar de cualquier manera el Sitio Web.
              </Text>
              <Text type="big">
                Se recomienda a los usuarios de <strong>TE NECESITO</strong> revisar periódicamente
                estos Términos y Condiciones, ya que estos pueden ser actualizados o modificados sin
                previo aviso y sin ulterior responsabilidad para <strong>TE NECESITO</strong>.
              </Text>
              <Text type="big">
                En caso de que el usuario no esté de acuerdo o decida no aceptar los Términos aquí
                establecidos, deberá abstenerse de registrarse y hacer uso de los servicios del
                Sitio Web.
              </Text>
            </Panel>
            <Panel header="2.	Funcionamiento general del Sitio Web" key="2">
              <Text type="big">
                <strong>TE NECESITO</strong> es una plataforma digital que conecta a emprendedores
                con trabajadores para tareas o servicios esporádicos. Para lo anterior, el Sitio Web
                www.tenecesito.cl constituye un mercado informático en donde usuarios y freelancers
                pueden identificarse, ofertar, solicitar y gestionar servicios en línea ante
                terceros.
              </Text>
              <Text type="big">
                Los Servicios solo se encuentran disponibles para personas con capacidad legal para
                contratar. No podrán hacer uso de tales servicios las personas que no tengan dicha
                capacidad, los menores de edad o bien los usuarios de <strong>TE NECESITO</strong>{' '}
                que hayan sido suspendidos temporalmente o inhabilitados definitivamente por el
                mismo Sitio Web.
              </Text>
              <Text type="big">
                Si tu Usuario es una Empresa, debes tener la capacidad legal para contratar a nombre
                de ella y de obligar a la misma según los Términos de este Acuerdo.
              </Text>
            </Panel>
            <Panel header="3.	Definiciones" key="3">
              <Text type="big">
                Para efectos de estos Términos y Condiciones, se entenderá por:
              </Text>
              <Text type="big">
                a. Sitio Web: página de internet de{' '}
                <strong>
                  <u>TE NECESITO</u>
                </strong>{' '}
                alojada en el dominio <a href="http://www.tenecesito.cl/">www.tenecesito.cl</a>;
              </Text>
              <Text type="big">
                b. Proyecto: servicio, asesoría, consultoría o trabajo esporádico concertado entre
                un Usuario y FreeLancer a través del Sitio Web de{' '}
                <strong>
                  <u>TE NECESITO;</u>
                </strong>
              </Text>
              <Text type="big">
                c. Usuario o Cliente: persona natural o jurídica que requiere y está en búsqueda de
                los servicios de un tercero para un proyecto, y que para dicho fin utiliza los
                servicios provistos por{' '}
                <strong>
                  <u>TE NECESITO </u>
                </strong>
                <u>
                  en<strong> </strong>su<strong> </strong>
                </u>
                Sitio Web;
              </Text>
              <Text type="big">
                d. FreeLancer: persona natural o jurídica<em> </em>que ofrece y provee servicios a
                través del Sitio Web de <strong>TE NECESITO </strong>que se asimilen a los
                requeridos por un Usuario, a fin de construir un proyecto o proveer un servicio; y,
              </Text>
              <Text type="big">
                e. Precio Convenido: Corresponde al precio acordado entre las partes (Usuario y
                FreeLancer), luego de un proceso de negociación realizado por ellas a través del
                sistema que para dicho objeto provee el Sitio Web de <strong>TE NECESITO.</strong>
              </Text>
              <Text type="big">
                <strong>
                  <br />
                </strong>
              </Text>
            </Panel>
            <Panel header="4.	Inscripción de Usuario y responsabilidad respecto a sus datos" key="4">
              <Text type="big">
                Para la utilización de los servicios de <strong>TE NECESITO </strong>los{' '}
                <strong> </strong>Usuarios deben entregar datos válidos. De tal modo, quien desee
                ser Usuario de <strong>TE NECESITO</strong>, debe verificar y asegurar que la
                información personal que proporcione al registrarse en el Sitio Web sea exacta y
                fidedigna. Los Usuarios deberán, además, actualizar los datos ingresados en caso de
                que ello resulte necesario. Al aceptar estos Términos y Condiciones, el Usuario
                consiente expresamente en que <strong>TE NECESITO</strong> utilice diversos medios
                para identificar sus datos personales, asumiendo el Usuario la obligación de
                revisarlos y actualizarlos. Por lo anterior,
                <strong> TE NECESITO</strong> no se responsabiliza por la exactitud o verosimilitud
                de los datos personales que hubieran sido proporcionado por los Usuarios, por cuanto
                estos son los responsables de la fidelidad, exactitud y vigencia de los entregados,
                así como de la actualización periódica de los mismos.
              </Text>
              <Text type="big">
                Sin perjuicio de lo anterior,<strong> TE NECESITO</strong> podrá solicitar al
                Usuario documentos, certificados, comprobantes y/o cualquier tipo información
                adicional a fin de corroborar los datos proporcionados. En caso de que la
                información requerida no pueda ser corroborada, <strong>TE NECESITO</strong> se
                reserva el derecho de suspender o cancelar, temporal o definitivamente, las cuentas
                de aquellos Usuarios que no confirmen o no puedan acreditar la información que les
                sea requerida. En caso de inhabilitación, se dará de baja al Usuario de que se
                trate, sin que ello le otorgue algún derecho de resarcimiento o de cualquier otra
                naturaleza contra <strong>TE NECESITO </strong>por este concepto.
              </Text>
              <Text type="big">
                Los Usuarios podrán acceder a sus Cuentas en el Sitio Web de{' '}
                <strong>TE NECESITO </strong>mediante su correo electrónico o nombre de usuario y la
                contraseña de seguridad que deberán crear para tal efecto. Cada Usuario será el
                único responsable de resguardar el acceso personal a su cuenta en el Sitio Web, como
                así también de la seguridad y protección respecto a su correo electrónico, nombre de
                usuario y la contraseña de seguridad proporcionada. Así, cada Usuario es responsable
                de la seguridad e integridad de estos datos, ante cualquier evento y por cualquier
                modo, incluso respecto a hurtos o robos de los mismos.
              </Text>
              <Text type="big">
                El Usuario entiende y acepta expresamente que la pérdida o cualquier mal uso de su
                correo electrónico o nombre de usuario y la contraseña de seguridad, o bien su
                entrega por cualquier causa o medio a terceros, constituye una infracción a estos
                Términos, leyes y reglamentos aplicables.
              </Text>
              <Text type="big">
                El Usuario será responsable de todas las operaciones efectuadas en su Cuenta, ya que
                el acceso a ella está restringido al ingreso y uso de su clave, de conocimiento
                exclusivo del Usuario. En caso de que el Usuario advierta un uso no autorizado o
                anómalo de su Cuenta, o el ingreso a la misma por terceros no autorizados, deberá
                comunicar esta situación de forma inmediata a <strong>TE NECESITO</strong>, por un
                medio idóneo y fehaciente. Se entenderá que se ha configurado una pérdida, mal uso,
                atentado o infracción en los términos señalados cuando: i) El Usuario entregue o
                permita que un tercero utilice su nombre, contraseña o cuenta con el fin de que ese
                tercero acceda, directa o indirectamente al Sitio Web y a cualquier documento,
                información, gestión o declaración efectuada por el Usuario; ii) Que un tercero lo
                haga por él o a su nombre, con o sin consentimiento del Usuario; iii) Cuando un
                Usuario de <strong>TE NECESITO</strong> ingrese o acceda al Sitio Web con el fin de
                copiar, reproducir, redistribuir, retransmitir, publicar, descargar, cargar y/o
                exhibir por cualquier medio o de cualquier manera, cualquier contenido, material o
                documento, con el fin de usarlo para competir, directa o indirectamente, con el giro
                de negocios de <strong>TE NECESITO</strong>.
              </Text>
              <Text type="big">
                Cada Usuario se obliga mediante la aceptación de estos Términos a mantener la
                confidencialidad de su correo electrónico y contraseña o clave de acceso al Sitio,
                así como a usarlos exclusiva e indelegablemente por sí mismo. Las Cuentas de cada
                Usuario son de carácter personal, única e intransferible, por lo que está prohibido
                que un mismo Usuario inscriba o posea más de una cuenta. En este caso, el Usuario
                que incurra en esta prohibición podrá ser suspendido.
              </Text>
              <Text type="big">
                <strong>TE NECESITO </strong>
                prohíbe expresamente la venta, cesión o transferencia bajo cualquier título,
                gratuito u oneroso, de las Cuentas de ingreso al Sitio Web.
              </Text>
              <Text type="big">
                <strong>TE NECESITO</strong>
                se reserva el derecho a rechazar cualquier solicitud de inscripción o de suspender
                y/o cancelar una inscripción previamente aceptada, sin que por ello esté obligado a
                comunicar o exponer las razones de su decisión, y sin que ello genere algún derecho
                a indemnización o resarcimiento, por cualquier título, por parte de{' '}
                <strong>TE NECESITO </strong>en favor de Usuarios o terceros.
              </Text>
            </Panel>
            <Panel header="5.	Sobre el pago " key="5">
              <Text type="big">
                Los pagos se efectuarán en pesos chilenos, moneda de curso legal, mediante sistemas
                de pagos en línea a través de Internet. Una vez confirmado el pago a{' '}
                <strong>TE NECESITO</strong>, el Usuario recibirá el respectivo comprobante a la
                dirección de correo electrónico que haya registrado en el Sitio Web. En caso de
                rechazarse o no efectuarse totalmente el pago correspondiente,{' '}
                <strong>TE NECESITO</strong> podrá suspender o cancelar la compra y la entrega del
                producto de que se trate.
              </Text>
              <Text type="big">
                <strong>TE NECESITO</strong> no será responsable, en ningún caso, por cualquier
                falla, cesación, demora, interrupción o caída del sistema de pago en línea a través
                de Internet. Por tanto, el Usuario será el único responsable de pagar todas aquellas
                sumas que se deban, tanto en relación con <em>Freelancers</em> como respecto a la
                adquisición de los productos o servicios que preste <strong>TE NECESITO</strong>, de
                conformidad a los precios, cargos, tarifas u otros que se encuentren vigentes al
                momento en que se incurra en los mismos. Cualquier impuesto aplicable a la
                transacción de que se trate, será de cargo exclusivo del Usuario.
              </Text>
            </Panel>
            <Panel header="6.	Privacidad de la información (Política de Privacidad)" key="6">
              <Text type="big">
                <strong>TE NECESITO</strong> podrá recopilar, almacenar y tratar la información
                proporcionada por el Usuario cuando este utilice los servicios y Sitio Web de{' '}
                <strong>TE NECESITO</strong>. Dicha información será utilizada con el objeto de
                mejorar y desarrollar los servicios ofrecidos. Toda la información proporcionada por
                el Usuario será utilizada de acuerdo a la Política de Privacidad de{' '}
                <strong>TE NECESITO</strong>.
              </Text>
              <Text type="big">
                Para utilizar el Sitio <a href="http://www.tenecesito.cl/">www.tenecesito.cl</a>{' '}
                cada Usuario deberá leer y aceptar estos Términos y Condiciones, así como la
                Política de Privacidad que se encuentra en el siguiente enlace:{' '}
                <a href="#/PrivacyPolicy">Política de Privacidad</a>
              </Text>
            </Panel>
            <Panel header="7.	Modificación de estos Términos y Condiciones" key="7">
              <Text type="big">
                <strong>TE NECESITO</strong> podrá modificar los Términos y Condiciones Generales en
                cualquier momento, haciendo públicos en el Sitio los términos modificados, siendo
                responsabilidad del Usuario revisar la vigencia de los mismos. Todos los términos
                modificados entrarán en vigor luego de 10 (diez) días, contados desde su
                publicación. Dichas modificaciones serán comunicadas por{' '}
                <strong>TE NECESITO</strong> a los Usuarios que en la Configuración de su Cuenta de{' '}
                <strong>TE NECESITO</strong> hayan indicado que desean recibir notificaciones de los
                cambios en estos Términos y Condiciones. Cualquier Usuario que no esté de acuerdo
                con las modificaciones efectuadas por <strong>TE NECESITO</strong> podrá solicitar
                la baja de la cuenta. El uso del Sitio y/o de sus servicios implica la aceptación de
                estos Términos y Condiciones de uso de <strong>TE NECESITO</strong>.
              </Text>
            </Panel>
            <Panel
              header="8.	Obligaciones de todos los Usuarios de TE NECESITO y sanción por infracción de normas"
              key="8">
              <Text type="big">
                <strong></strong>
              </Text>
              <Text type="big">
                Los Usuarios del Sitio Web deberán cumplir en todo momento con estos Términos y
                Condiciones y con todas las leyes y regulaciones aplicables en el territorio de la
                República de Chile (esto, en relación con el punto 11 de estos Términos). Los
                usuarios de <strong>TE NECESITO</strong> se comprometen a cumplir con las normas que{' '}
                <strong>TE NECESITO</strong> ha establecido para el funcionamiento del Sitio y el
                otorgamiento de sus servicios. La infracción de alguna de ellas podrá causar la
                aplicación de sanciones por parte de <strong>TE NECESITO</strong>. Las normas y
                sanciones pueden ser conocidas{' '}
                <a href="#/Behaviour&Sanctions">
                  <u>aquí</u>
                </a>
                . En caso de que un Usuario no cumpla con estas normas, o infrinja cualquiera de las
                disposiciones contenidas en estos Términos y Condiciones y/o en la legislación
                vigente, <strong>TE NECESITO</strong> podrá suspender, o eliminar la cuenta del
                Usuario sin derecho a compensación de ninguna especie, sin perjuicio de las demás
                acciones legales a que <strong>TE NECESITO</strong> tenga derecho.
              </Text>
            </Panel>
            <Panel header="9.	Prohibición de violar sistema " key="9">
              <Text type="big">
                No está permitida ninguna acción o uso de dispositivo, software, u otro medio
                tendiente a interferir, dañar, vulnerar, o afectar de cualquier forma, por cualquier
                tiempo, y con cualquiera intención, tanto las actividades, servicios y operación del
                Sitio Web de <strong>TE NECESITO</strong>. Lo anterior, ya sea en las ofertas,
                descripciones, programación, arquitectura digital, cuentas o bases de datos, así
                como cualquier otro elemento, sistema, infraestructura o elemento, físico, digital o
                intelectual que sirva al objeto y giro de <strong>TE NECESITO</strong>. Cualquier
                intromisión, tentativa o actividad violatoria o contraria a las leyes sobre
                ciberseguridad, protección de datos, derecho de propiedad intelectual y/o a las
                prohibiciones estipuladas en estos Términos y Condiciones o cualquier contrato que
                proteja al Servicio harán susceptible a su responsable de las acciones legales
                pertinentes y a las sanciones previstas por estos Términos, así como también dará
                lugar a las indemnizaciones que correspondan por los daños ocasionados, cualquiera
                sea su naturaleza.
              </Text>
            </Panel>
            <Panel header="10.	Responsabilidad de TE NECESITO" key="10">
              <Text type="big">
                <strong>TE NECESITO</strong> no se hace responsable de las posibles fallas,
                cesaciones, interrupciones o demoras de conexión, incompatibilidad del Sitio Web con
                cualquier tipo de hardware o de software, caídas del Sitio Web o fallas, cesaciones,
                interrupciones o demoras en los equipos computacionales o software utilizados para
                conectarse al Sitio Web.
              </Text>
              <Text type="big">
                (Aquí se pueden agregar detalles de navegador requerido o preferido para la
                navegación y uso del sitio, y su configuración según el medio de acceso al Sitio
                Web. Por ejemplo: Safari, Chrome, cookies activadas, etc.)
              </Text>
              <Text type="big">
                En algunas ocasiones, el Sitio Web puede sufrir fallas o retrasos en su
                funcionamiento debido a la instalación de mejoras, actualizaciones y funciones
                adicionales, u otros procedimientos destinados a mejorar la experiencia de los
                Usuarios y programadores de <strong>TE NECESITO</strong>. En estos casos,{' '}
                <strong>TE NECESITO</strong> se compromete a solucionar estos problemas a la
                brevedad posible, sin ulterior responsabilidad para <strong>TE NECESITO</strong>.
              </Text>
              <Text type="big">
                En el caso de que el Sitio Web contenga links, banners o URLs de terceros ajenos a{' '}
                <strong>TE NECESITO, </strong>esta última no se hace responsable del contenido,
                veracidad, seguridad y/o funcionalidad de dichos enlaces, direcciones o sitios. Si
                el Usuario accede a ellos se entenderá que lo hace bajo su exclusiva
                responsabilidad.
              </Text>
              <Text type="big">
                Los Usuarios y <em>Freelancers </em>no tendrán, bajo ningún respecto, la calidad de
                dependiente de <strong>TE NECESITO, </strong>por tanto <strong> </strong>esta última
                no es responsable, en modo alguno, de obligaciones o circunstancias que configuran
                un contrato de trabajo, ni le será tampoco aplicables disposiciones laborales
                respecto de aquellos. Por tanto, en aceptación expresa de los Términos y Condiciones
                aquí establecidos, los Usuarios y <em>Freelancers </em>dejan expresamente
                establecido que no los une relación laboral alguna con <strong>TE NECESITO.</strong>
              </Text>
            </Panel>
            <Panel header="11.	Propiedad Intelectual" key="11">
              <Text type="big">
                El Sitio Web y los Documentos en él contenidos son de propiedad de{' '}
                <strong>TE NECESITO</strong> y operados por este último. Cualquier derecho, título o
                interés en el Sitio Web, sus aplicaciones y contratos, incluyendo, pero no limitado,
                a: información, documentos, logos, gráfica, diseño, sonidos e imágenes (en
                adelante&quot; Materiales &quot;) son de propiedad ya sea de{' '}
                <strong>TE NECESITO</strong> o de sus terceros proveedores.
              </Text>
              <Text type="big">
                El uso que el Usuario le dé al Sitio Web estará sujeto al cumplimiento de las leyes
                aplicables en materia de propiedad intelectual. Al respecto, el Usuario acepta
                expresamente que no subirá, exhibirá, ejecutará, transmitirá o distribuirá cualquier
                información o contenido de <strong>TE NECESITO</strong> con infracción a estos
                Términos o cualquier ley de propiedad intelectual. El Usuario será responsable
                exclusivo de cualquier incumplimiento, infracción o violación producto de cualquier
                contenido que provea, entregue o transmita, usando o no para ello su cuenta de{' '}
                <strong>TE NECESITO</strong>.
              </Text>
              <Text type="big">
                Ninguno de los Materiales pueden ser copiados, reproducidos, publicados,
                descargados, cargados, exhibidos, transmitidos o distribuidos de ninguna manera, con
                excepción de lo que <strong>TE NECESITO</strong> autorice o determine expresamente.
                Nada de lo que esté contenido en el Sitio Web, en sus aplicaciones o en los
                documentos se entenderá que confiere al usuario o a terceros una licencia de
                derechos de propiedad intelectual o uso por parte de <strong>TE NECESITO</strong>.
              </Text>
              <Text type="big">
                Ningún aviso de propiedad intelectual o de marcas comerciales o logos contenidos en
                los Materiales podrá ser alterado, eliminado o removido de ninguna forma. Los
                Materiales no podrán ser utilizados ni vendidos o de otra manera comercializados por
                ningún medio ni en ningún otro sitio web sin la autorización expresa de{' '}
                <strong>TE NECESITO.</strong>
              </Text>
              <Text type="big">
                Cualquier uso no autorizado de cualquier Material contenido en el Sitio Web puede
                constituir un incumplimiento de leyes sobre propiedad intelectual, sin perjuicio de
                que pueda constituir también una infracción a otras leyes aplicables o a lo señalado
                en estos Términos y Condiciones.
              </Text>
              <Text type="big">
                <strong>TE NECESITO</strong>, el Sitio Web{' '}
                <a href="http://www.tenecesito.cl/">www.tenecesito.cl</a>, su logo, y todos los
                documentos, gráficas, imágenes, contenidos, íconos y textos asociados a los
                anteriores, son marcas y propiedad intelectual de <strong>TE NECESITO</strong>.
              </Text>
              <Text type="big">
                El Sitio Web puede contener enlaces a sitios webs controlados y de responsabilidad
                de terceros. Por ello, <strong>TE NECESITO</strong> no será responsable respecto de
                la disponibilidad, contenido, producto, servicio, privacidad, seguridad o uso de un
                sitio web de un tercero, o de cualquier modificación o actualización de esos sitios.{' '}
              </Text>
              <Text type="big">
                <strong>TE NECESITO</strong> tampoco garantiza el contenido o la calidad de los
                productos o servicios provistos por dichos sitios ni se responsabiliza por ninguna
                transmisión recibida desde sitios de terceros. El Usuario acepta expresamente asumir
                todos los riesgos asociados con el acceso y el uso del contenido de un sitio web de
                un tercero. Asimismo, el Usuario acepta expresamente que{' '}
                <strong>TE NECESITO</strong> no será responsable por ninguna pérdida o daño de
                ningún tipo en que el Usuario pudiera incurrir o sufrir en relación con lo anterior.
              </Text>
              <Text type="big">
                <strong>
                  Los usuarios aceptan que una vez recibido el pago y conforme se vayan llevando a
                  cabo las etapas de cada proyecto , los documentos, esquemas, diseños, códigos, y
                  en general cualquier documento que se genere producto de los proyectos realizados
                  a través de Te Necesito SPA, son propiedad única y exclusiva del contratante
                  (dueño del proyecto), sin perjuicio de la propiedad intelectual que sobre ellos
                  mantienen sus autores.
                </strong>
              </Text>
            </Panel>
            <Panel header="12.	Servicio y Mediación en caso de controversias " key="12">
              <Text type="big">
                Los Usuarios podrán solicitar un servicio utilizando la plataforma de{' '}
                <strong>TE NECESITO</strong> de dos maneras:
              </Text>
              <Text type="big">
                (1) buscando un <em>FreeLancer</em> que ofrezca servicios que se asimilen al
                requerido por el Usuario, a fin de construir un proyecto o proveer un servicio, o;
              </Text>
              <Text type="big">
                (2) Mediante la publicación de una oferta de proyecto, que deberá contener las
                características del servicio que se busca. En estos casos, posteriormente, se
                procederá a la selección de él o los postulantes que prestarán el servicio. Una vez
                terminada la negociación entre Usuario y <em>FreeLancer</em>, el Usuario deberá
                realizar el pago del total del precio convenido, siendo este retenido por{' '}
                <strong>TE NECESITO</strong> hasta el cumplimiento del servicio por parte del{' '}
                <em>FreeLancer</em>. Adicionalmente, <strong>TE NECESITO</strong> podrá incluir
                otros servicios por aquellos precios, tarifas o cargos que{' '}
                <strong>TE NECESITO</strong> establezca. <strong>TE NECESITO</strong> se reserva el
                derecho de modificar las tarifas, cargos, métodos o modalidades de cobro por los
                Servicios que preste en cualquier momento, a su entera discreción.
              </Text>
              <Text type="big">
                En caso de controversia entre usuario y FreeLancer, esta debe ser alertada (en la
                respectiva sección que para dicho efecto dispondrá el Sitio Web);{' '}
                <strong>TE NECESITO</strong> se pondrá en contacto con ambas partes, y actuará como
                mediador en la resolución de la controversia, en razón de los antecedentes ya
                existentes que las mismas partes hubieran aportado, previamente, al Sitio Web, y que
                para estos efectos servirá como única la prueba documental para la resolución del
                conflicto. Con esto, se hace énfasis en la importancia de esclarecer los objetivos
                de cada trabajo y de traspasar toda la documentación necesaria para su realización a
                través del Sitio Web www.tenecesito.cl. En caso de haber alguna disputa o
                controversia, <strong>TE NECESITO</strong> arbitrará sólo en base a las pruebas
                presentes en el desarrollo del proyecto, es decir, solo se considerarán los
                documentos, archivos y enlaces enviados a través de nuestro Sitio Web.
              </Text>
              <Text type="big">
                <strong>TE NECESITO</strong> no efectuará reembolsos.
              </Text>
            </Panel>
            <Panel header="13.	Legislación aplicable" key="13">
              <Text type="big">
                Este acuerdo y Términos estarán regidos en todos sus puntos por las leyes vigentes
                en la República de Chile. Cualquier controversia derivada de estos, su existencia,
                validez, interpretación, alcance o cumplimiento, será sometida a las leyes
                aplicables y a los Tribunales competentes en Santiago de Chile.
              </Text>
            </Panel>
            <Panel header="14.	Divisibilidad y Ejercicio de Derechos y Recursos" key="14">
              <Text type="big">
                En caso que cualquier término, disposición, acuerdo o restricción de estos Términos
                y Condiciones sea declarado inválido o nulo, dicho término, disposición, acuerdo o
                restricción deberá interpretarse sin afectar la validez o exigibilidad de las demás
                disposiciones de estos Términos y Condiciones, las que permanecerán en pleno vigor y
                vigencia.
              </Text>
            </Panel>
            <Panel header="15.	 Anexos" key="15">
              <Text type="big">
                <a href="#/PrivacyPolicy">Política de Privacidad</a>
              </Text>
              <Text type="big">
                <a href="#/Behaviour&Sanctions">Normas y Sanciones</a>
              </Text>
            </Panel>
            <Panel header="16.	Contacto" key="16">
              <Text type="big">
                En caso de tener consultas o comentarios, escríbenos a{' '}
                <a href="mailto:contacto@tenecesito.cl">contacto@tenecesito.cl</a>
              </Text>
            </Panel>
          </Collapse>
        </div>
      </div>
    );
  }
}
