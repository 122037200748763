export const NO_REQUESTERS_ILUSTRATION =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/InformativeIlustrations/NoRequesters.png';

export const NO_HIRING_PROJECTS_ILUSTRATION =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/InformativeIlustrations/NoHiringProjects.svg';

export const NO_PROJECT_PUBLICATION_ILUSTRATION =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/InformativeIlustrations/NoProjectPublication.svg';

export const NO_REQUESTS_ILUSTRATION =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/InformativeIlustrations/NoRequests.svg';

export const NO_SERVICE_PUBLICATION_ILUSTRATION =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/InformativeIlustrations/NoServicePublication.svg';

export const NO_WORKING_PROJECTS_ILUSTRATION =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/InformativeIlustrations/NoWorkingProjects.svg';

export const NO_INFORMATION_ILUSTRATION =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/InformativeIlustrations/NoInformation.svg';

export const NO_BANK_ACCOUNT_ILUSTRATION =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/InformativeIlustrations/NoBankAccounts.png';

export const ALERT_ERROR_ILUSTRATION =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/InformativeIlustrations/AlertError.png';

export const ALERT_SUCCESS_ILUSTRATION =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/InformativeIlustrations/AlertSuccess.jpeg';
export const PERSON_1_ILUSTRATION =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/informativePersons/Person1.png';

export const PERSON_2_ILUSTRATION =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/informativePersons/Person2.png';
export const PERSON_3_ILUSTRATION =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/informativePersons/Person3.png';
export const PERSON_4_ILUSTRATION =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/informativePersons/Person4.png';
export const PERSON_5_ILUSTRATION =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/informativePersons/Person5.png';
export const PERSON_6_ILUSTRATION =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/informativePersons/Person6.png';
export const PERSON_7_ILUSTRATION =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/informativePersons/Person7.png';
export const PERSON_8_ILUSTRATION =
  'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/informativePersons/Person8.png';
