/* eslint-disable no-unused-vars */
import React from 'react';
import { ManagmentViewModel } from '../../../ManagmentViewModel';
import { getPrivateRequest } from '@actions/requests';
import { getRequestProposals } from '@actions/proposals';
import { getProjectId } from '@actions/projects';
import { getChatId } from '@actions/chat';
import {
  FREELANCER_REQUESTS_ROUTE,
  FREELANCER_PROJECTS_ROUTE,
} from '@common/constants/routes.constants';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import chatService from '@services/chatService';
import { ALL_PROJECTS } from '@common/constants/project.constants';
import { addBankAccount_v2, getBankAccountData_v2 } from 'actions/myprofile';
import { message } from 'antd';
import { getProjectQuestions, replyQuestion } from 'actions/projects';

export const FreelancerRequestManagmentViewModel = () => {
  const { id } = useParams();
  const history = useHistory();
  const {
    isLoading,
    requestsList,
    authUser,
    userDrawer,
    setUserDrawer,
    requestDrawer,
    setRequestDrawer,
    projectDrawer,
    setProjectDrawer,
    viewRequestDrawer,
    setViewRequestDrawer,
  } = ManagmentViewModel();

  const [currentRequest, setCurrentRequest] = React.useState(null);
  const [isLoadingRequest, setIsLoadingRequest] = React.useState(false);

  const [currentProposal, setCurrentProposal] = React.useState(null);
  const [isLoadingProposal, setIsLoadingProposal] = React.useState(false);

  const [currentProject, setCurrentProject] = React.useState(null);
  const [isLoadingProject, setIsLoadingProject] = React.useState(false);

  const [currentChat, setCurrentChat] = React.useState(null);
  const [isLoadingChat, setIsLoadingChat] = React.useState(false);

  const [files, setFiles] = React.useState([]);
  const [isLoadingFiles, setIsLoadingFiles] = React.useState(false);

  const [freelancerBankAccounts, setFreelancerBankAccounts] = React.useState([]);
  const [openBankAccountModal, setOpenBankAccountModal] = React.useState(false);

  const [questionsList, setQuestionsList] = React.useState([]);
  const [isLoadingQuestions, setIsLoadingQuestions] = React.useState(false);

  const handleSwitchRequest = (requestId) => {
    history.push(FREELANCER_REQUESTS_ROUTE + '/' + requestId);
  };

  React.useEffect(() => {
    getBankAccountData_v2(authUser?.user?.id).then((res) => {
      setFreelancerBankAccounts(res.data);
      if (res?.data?.length === 0) {
        setOpenBankAccountModal(true);
      }
    });
  }, [id]);

  const handleSubmitBankAccount = async (event) => {
    const bankAccount = {
      name: event.name,
      rut: event.rut,
      bank: event.bank,
      account_type: event.account_type,
      account_number: event.account_number,
      address: event.address,
    };
    await addBankAccount_v2(bankAccount)
      .then((res) => {
        setFreelancerBankAccounts([...freelancerBankAccounts, res.data]);
        message.success('Cuenta añadida exitosamente');
        setOpenBankAccountModal(false);
      })
      .catch(() => message.error('Ha ocurrido un error al añadir la cuenta, intenta nuevamente'));
  };

  const handleGetFiles = (chatId) => {
    chatService
      .getFilesHandler(chatId)
      .then((res) => {
        setFiles(res.data);
        setIsLoadingFiles(false);
      })
      .catch(() => setIsLoadingFiles(false));
  };

  const handleReplyQuestion = (id, answerText) => {
    setIsLoadingQuestions(true);
    replyQuestion(id, answerText)
      .then((res) => {
        setQuestionsList([...questionsList.filter((q) => q.id != id), res.data.request]);
        setIsLoadingQuestions(false);
      })
      .catch(() => {
        setIsLoadingQuestions(false);
      });
  };

  React.useEffect(() => {
    setIsLoadingRequest(true);
    getPrivateRequest(id)
      .then((res) => {
        setCurrentRequest(res.data);
        setIsLoadingRequest(false);
      })
      .catch(() => {
        setCurrentRequest(null);
        setIsLoadingRequest(false);
      });
  }, [id]);

  React.useEffect(() => {
    setIsLoadingQuestions(true);
    if (currentProject) {
      getProjectQuestions(currentProject.id)
        .then((res) => {
          setQuestionsList(res.data.objects);
          setIsLoadingQuestions(false);
        })
        .catch(() => {
          setQuestionsList([]);
          setIsLoadingQuestions(false);
        });
    }
  }, [currentProject]);

  React.useEffect(() => {
    setIsLoadingRequest(true);
    getPrivateRequest(id)
      .then((res) => {
        setCurrentRequest(res.data);
        setIsLoadingRequest(false);
      })
      .catch(() => {
        setCurrentRequest(null);
        setIsLoadingRequest(false);
      });
  }, [id]);

  React.useEffect(() => {
    setIsLoadingProposal(true);
    if (currentRequest) {
      getRequestProposals({ request: currentRequest?.id })
        .then((res) => {
          setCurrentProposal(res.data[res.data.length - 1]);
          setIsLoadingProposal(false);
        })
        .catch(() => {
          setCurrentProposal(null);
          setIsLoadingProposal(false);
        });
    }
  }, [currentRequest]);

  React.useEffect(() => {
    if (currentRequest && currentRequest.status != 'rejected') {
      setIsLoadingProject(true);
      getProjectId(currentRequest?.project)
        .then((res) => {
          if (ALL_PROJECTS.includes(res.data.status)) {
            history.push(`${FREELANCER_PROJECTS_ROUTE}/${res.data.id}`);
          }
          setCurrentProject(res.data);
          setIsLoadingProject(false);
        })
        .catch(() => {
          setCurrentProject(null);
          setIsLoadingProject(false);
        });
    }
  }, [currentRequest]);

  React.useEffect(() => {
    setIsLoadingChat(true);
    setIsLoadingFiles(true);
    if (currentRequest && authUser) {
      getChatId(authUser.user.id, currentRequest.project, authUser.token)
        .then((res) => {
          if (res.data.length > 0) {
            setCurrentChat(res.data[0]);
            handleGetFiles(res.data[0].id);
          }
          setIsLoadingChat(false);
        })
        .catch(() => setIsLoadingChat(false));
    }
  }, [currentRequest]);

  const useImageUploadHandler = (event) => {
    setIsLoadingFiles(true);
    chatService
      .uploadHandler(event, currentChat.id)
      .then(() => {
        handleGetFiles(currentChat.id);
      })
      .catch(() => setIsLoadingChat(false));
  };

  return {
    isLoading,
    isLoadingRequest: isLoadingRequest || isLoadingProposal,
    isLoadingChat,
    isLoadingProject,
    isLoadingFiles,
    files,
    authUser,
    currentRequest,
    currentProposal,
    currentProject,
    currentChat,
    requestsList,
    userDrawer,
    setUserDrawer,
    requestDrawer,
    setRequestDrawer,
    projectDrawer,
    setProjectDrawer,
    handleSwitchRequest,
    useImageUploadHandler,
    handleGetFiles,
    viewRequestDrawer,
    setViewRequestDrawer,
    freelancerBankAccounts,
    openBankAccountModal,
    setOpenBankAccountModal,
    handleSubmitBankAccount,
    handleReplyQuestion,
    questionsList,
  };
};
