import React from 'react';
import OldDetails from './OldDetails';
import CMSDetails from './CMSDetails';
import IntegrationDetails from './IntegrationDetails';

const getType = (currentProject, noTitle) => {
  if (currentProject?.project_type == 'cms') {
    return <CMSDetails currentProject={currentProject} />;
  } else if (currentProject?.project_type == 'integration') {
    return <IntegrationDetails currentProject={currentProject} />;
  } else {
    return <OldDetails currentProject={currentProject} noTitle={noTitle} />;
  }
};

const ProjectDetails = ({ currentProject, noTitle = false }) => {
  return <div>{getType(currentProject, noTitle)}</div>;
};

export default ProjectDetails;
