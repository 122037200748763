import React from 'react';
import './ClientOrderMoreDrawer.scss';
import { MoneyFormatter } from '@common/utils/Formaters';
import { Title, Text, CustomDrawer } from '@components/common';

export function ClientOrderMoreDrawer({ currentClientOrder, moreDrawer, setMoreDrawer }) {
  return (
    <CustomDrawer
      type="profile"
      open={moreDrawer}
      onClose={() => setMoreDrawer(false)}
      visible={moreDrawer}>
      <div className="client-order-more__container">
        <div className="client-order-more__blue-background"></div>
        <div className="client-order-more__content">
          <Title type="primary" color="main">
            Orden de pago
          </Title>
          <div className="client-order-more__row">
            <Text type="strongBlue">Nombre proyecto: </Text>
            <Text>{currentClientOrder?.project_name}</Text>
          </div>
          <div className="client-order-more__row">
            <Text type="strongBlue">Precio: </Text>
            <Text>{MoneyFormatter(currentClientOrder?.amount)}</Text>
          </div>
          <div className="client-order-more__row">
            <Text type="strongBlue">Comisión: </Text>
            <Text>{MoneyFormatter(currentClientOrder?.comission)}</Text>
          </div>
          <div className="client-order-more__row">
            <Text type="strongBlue">Iva: </Text>
            <Text>{MoneyFormatter(currentClientOrder?.iva)}</Text>
          </div>
          <div className="client-order-more__row">
            <Text type="strongBlue">
              {currentClientOrder?.paid ? 'Total pagado:' : 'Total por pagar: '}{' '}
            </Text>
            <Text>{MoneyFormatter(currentClientOrder?.total_payment)}</Text>
          </div>
        </div>
      </div>
    </CustomDrawer>
  );
}
