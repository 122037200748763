import React from 'react';
import './SkillsBeans.scss';

export function SkillsBeans({ skills }) {
  // Initialize state with undefined width/height so server and client renders match

  return (
    <div className="skills-beans__container">
      {skills
        ? skills.map((skill, index) => {
            return (
              <div className="skills-beans__element" key={index}>
                {skill}
              </div>
            );
          })
        : ''}
    </div>
  );
}
