import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuthUser } from '@reducers/auth';
import { selectMoreConfigStatus } from '@reducers/navigation';
import { editUser_v2 } from '@actions/users';
import { message, Modal } from 'antd';
import { EDIT_USER } from '@actions/types';
import { useHistory } from 'react-router';
import {
  getBankAccountData_v2,
  addBankAccount_v2,
  editBankAccount_v2,
  deleteBanckAccount_v2,
} from '@actions/myprofile';
import { ManagmentViewModel } from '../ManagmentViewModel';
import { TIME_POPUP } from '@common/constants/bankAccounts.constants';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { switchMoreConfigStatus } from '@actions/myprofile';

const { confirm } = Modal;

export const MoreConfigurationsViewModel = () => {
  const authUser = useSelector(selectAuthUser);
  const dispatch = useDispatch();
  const history = useHistory();

  const userData = authUser.user;

  const [editingPersonalData, setEditingPersonalData] = React.useState(false);

  const [isLoadingBankAccounts, setIsLoadingBankAccounts] = React.useState(false);
  const [bankAccountsList, setBankAccountsList] = React.useState([]);

  const { bankAccountDrawer, setBankAccountDrawer } = ManagmentViewModel();
  const [currentBankAccount, setCurrentBankAccount] = React.useState(null);

  const [renderView, setRenderView] = React.useState(0);

  const [currentMoreConfigPage, setCurrentMoreConfigPage] = React.useState(
    useSelector(selectMoreConfigStatus)
  );

  React.useEffect(() => {
    setIsLoadingBankAccounts(true);
    async function fetchAccounts() {
      await getBankAccountData_v2(userData.id).then((res) => {
        setBankAccountsList(res.data);
      });
      setIsLoadingBankAccounts(false);
    }
    fetchAccounts();
  }, [renderView]);

  const [formData, setFormData] = React.useState({
    first_name: userData?.first_name,
    last_name: userData?.last_name,
    gender: userData?.gender,
    date_of_birth: userData?.date_of_birth,
    email: userData?.email,
    phone_number: userData?.phone_number,
    phone_prefix: userData?.phone_prefix,
  });

  const handleInputChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSelectChange = (event) => {
    setFormData({
      ...formData,
      gender: event,
    });
  };
  const handleonChangeBirth = (value) => {
    setFormData({
      ...formData,
      date_of_birth: value.format('YYYY-MM-DD'),
    });
  };

  const handleEditUser = async (event) => {
    event.preventDefault();

    await editUser_v2(userData.id, formData).then((res) => {
      dispatch({
        type: EDIT_USER,
        payload: res.data,
      });
      message.success('Se ha actualizado correctamente tu usuario.');
      setEditingPersonalData(false);
      setRenderView(renderView + 1);
    });
  };

  const handleChangePassword = () => {
    history.push('/changePassword');
  };

  const handleSubmitAccount = async (event) => {
    const bankAccount = {
      name: event.name,
      rut: event.rut,
      bank: event.bank,
      account_type: event.account_type,
      account_number: event.account_number,
      address: event.address,
    };
    if (!currentBankAccount) {
      await addBankAccount_v2(bankAccount)
        .then((res) => {
          setCurrentBankAccount(res.data);
          setRenderView(renderView + 1);
          setBankAccountDrawer(false);
          message.success('Cuenta añadida exitosamente', TIME_POPUP);
        })
        .catch(() =>
          message.error('Ha ocurrido un error al añadir la cuenta, intenta nuevamente', TIME_POPUP)
        );
    } else {
      await editBankAccount_v2(currentBankAccount.id, currentBankAccount.owner, bankAccount)
        .then((res) => {
          setCurrentBankAccount(res.data);
          setRenderView(renderView + 1);
          setBankAccountDrawer(false);
          message.success('¡La cuenta se ha editado con éxito!', TIME_POPUP);
        })
        .catch(() =>
          message.error('Ha ocurrido un error al editar la cuenta, intenta nuevamente', TIME_POPUP)
        );
    }
  };

  const handleClickBankAccountCard = (account) => {
    setCurrentBankAccount(account);
    setBankAccountDrawer(true);
  };

  const handleDeleteBankAccount = async (account) => {
    confirm({
      title: `¿Estás seguro que quieres eliminar esta cuenta?`,
      icon: <ExclamationCircleOutlined />,
      content: 'La cuenta se eliminará permanentemente.',
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: async () => {
        await deleteBanckAccount_v2(account.id, account.owner)
          .then(() => {
            setBankAccountDrawer(false);
            setCurrentBankAccount(null);
            message.success('¡La cuenta se ha eliminado con éxito!', TIME_POPUP);
            setRenderView(renderView + 1);
          })
          .catch(() => message.error('Ha ocurrido un problema al eliminar tu cuenta.', TIME_POPUP));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleSelectBankAccount = async (account) => {
    confirm({
      title: `¿Quieres seleccionar esta cuenta como tu cuenta activa?`,
      icon: <ExclamationCircleOutlined />,
      content: 'A esta cuenta se te tranferirán los pagos.',
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: async () => {
        await editBankAccount_v2(account.id, account.owner, { active: true }).then(() => {
          setCurrentBankAccount(null);
          setBankAccountDrawer(false);
          setRenderView(renderView + 1);
          message.success('¡La cuenta se ha seleccionado con éxito!', TIME_POPUP);
        });
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };
  const switchPageStatus = (pagestatus) => {
    dispatch(switchMoreConfigStatus(pagestatus));
    setRenderView(renderView + 1);
  };

  return {
    userData,
    bankAccountsList,
    isLoadingBankAccounts,
    editingPersonalData,
    setEditingPersonalData,
    formData,
    setFormData,
    handleInputChange,
    handleSelectChange,
    handleonChangeBirth,
    handleEditUser,
    handleChangePassword,
    bankAccountDrawer,
    setBankAccountDrawer,
    handleSubmitAccount,
    currentBankAccount,
    setCurrentBankAccount,
    handleClickBankAccountCard,
    handleDeleteBankAccount,
    handleSelectBankAccount,
    currentMoreConfigPage,
    setCurrentMoreConfigPage,
    switchPageStatus,
  };
};
