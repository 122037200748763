import React, { Fragment } from 'react';
import {
  BaseLayout,
  EmptyListCard,
  InformativeAdvice,
  CustomBreadcrumb,
  Title,
} from '@components/common';
import { FreelancerRequestsListViewModel } from './FreelancerRequestsListViewModel';
import { LoadingDots } from '@components/common/LoadingDots';
import { RequestMainCard } from '@components/requests/RequestMainCard/RequestMainCard';
import { FilterTypes } from '@components/common';
import { PERSON_6_ILUSTRATION } from '@common/constants/informativeIlustrations.constants';
import { Pagination } from 'antd';
import css from './FreelancerRequestsList.module.scss';

const FreelancerRequestsList = () => {
  const {
    isLoadingRequests,
    requestsList,
    request_types,
    handleOnClickCategory,
    currentFilter,
    handlePagination,
    totalRequests,
    currentPage,
  } = FreelancerRequestsListViewModel();

  return (
    <Fragment>
      <CustomBreadcrumb type="freelancer-request" />
      <BaseLayout>
        <BaseLayout.LeftSide>
          <div className={css.layoutContainer}>
            <div className="tn-layout__left-section">
              <Title type="secondary-bold" color="primary">
                Tus postulaciones:
              </Title>
              <hr />
              <FilterTypes
                filter_types={request_types}
                onSelect={handleOnClickCategory}
                selectedState={currentFilter}
              />
            </div>
            <div className="tn-layout__left-section">
              <InformativeAdvice
                image={PERSON_6_ILUSTRATION}
                message="Acá puedes encontrar un listado de tus postulaciones. Presiona alguna para ver más detalles."
              />
            </div>
          </div>
        </BaseLayout.LeftSide>
        <BaseLayout.Content>
          {!isLoadingRequests ? (
            <div className={css.content}>
              {requestsList.length > 0 ? (
                <Fragment>
                  {requestsList.map((request) => {
                    return (
                      <RequestMainCard
                        type="list"
                        request={request}
                        key={`request-${request.id}`}
                      />
                    );
                  })}
                  <div className={css.paginationContainer}>
                    {' '}
                    <Pagination
                      onChange={handlePagination}
                      pageSize={10}
                      total={totalRequests}
                      current={currentPage}
                      showSizeChanger={false}
                    />
                  </div>
                </Fragment>
              ) : (
                <EmptyListCard type="no-request" />
              )}
            </div>
          ) : (
            <LoadingDots />
          )}
        </BaseLayout.Content>
      </BaseLayout>
    </Fragment>
  );
};
export default FreelancerRequestsList;
