import React from 'react';
import { Title, Text } from '@components/common';

const ServicesFormItem = ({ title, children, id, detail }) => (
  <div className="form-item-container-flex">
    <div className="form-left-flex-item">
      <Title type="subtitle">{title}</Title>
      {detail && (
        <div className="description-guide-text">
          <Text type="detail">{detail}</Text>
        </div>
      )}
    </div>
    <div className="form-right-flex-item" id={id}>
      {children}
    </div>
  </div>
);

export default ServicesFormItem;
