/* eslint-disable no-unused-vars */
import React from 'react';
import './ProfilePicture.scss';
import { Rate } from 'antd';
import UserMedals from 'components/common/UserMedals/UserMedals';
import { Title } from 'components/common';
import { VERIFIED_ACCOUNT_ICON_URL } from 'common/constants/assets.constants';

const ProfilePicture = ({
  user,
  setProfileImageModal,
  width = 170,
  withRatings = true,
  withEditButton = true,
  withMedals = true,
  withName = false,
  medals,
}) => {
  return (
    <div className="profile-picture__container">
      <div className="profile-picture__image-container">
        <div className="profile-picture__image" style={{ width }}>
          <img src={`${user?.profile_image}?${Date.now()}`} alt="Foto" width={width} />
        </div>
        {withName && (
          <div className="user-stats__name-verified" style={{ marginTop: '1em' }}>
            <Title>{user?.first_name}</Title>
            <img
              className="user-stats__verficated-icon"
              src={VERIFIED_ACCOUNT_ICON_URL}
              alt="profile"
            />
          </div>
        )}
        {withEditButton && (
          <div className="profile-picture__edit-button">
            <button onClick={() => setProfileImageModal(true)}>
              <i className="fas fa-pencil-alt"></i>
            </button>
          </div>
        )}
      </div>
      {withRatings && (
        <div className="profile-picture__rate">
          <Rate allowHalf disabled defaultValue={user?.overall_rating} />
        </div>
      )}
      {withMedals && (
        <div style={{ marginTop: '0.5em' }}>
          <UserMedals userId={user?.id} show={10} medals={medals} />
        </div>
      )}
    </div>
  );
};

export default ProfilePicture;
