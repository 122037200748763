export const CLEAR_ACCOUNTS_ROUTE = '/management/common/clearAccounts';
export const MYPROFILE = '/management/common/profile';
export const CREATE_PROJECT = '/forms';
export const CREATE_SERVICE = '/createService/new';
export const HOME_ROUTE = '/home';
export const LANDING = '/landing';
export const VIEWSERVICE = '/service';
export const VIEWPROJECT = '/project';
export const MORECONFIGURATIONS = '/management/common/personal-data';
export const NOTIFICATIONS = '/management/common/notifications';
export const BANK_ACCOUNTS = '/management/common/bank-accounts';
export const REGISTER = '/register';
export const HOWITWORKS = '/howItWorks';
export const HOWITWORKS_HIRE = '/howItWorks/Contratar';
export const HOWITWORKS_WORK = '/howItWorks/Trabajar';
export const NOT_ALLOWED_URL = '/notAllowed';
export const BLOG_URL = '/blog';
export const FAQ_URL = '/FrequentQuestions';
export const ABOUT_US = '/AboutUs';
export const CMS_FORM = '/cms-form';
export const INTEGRATIONS_FORM = '/integrations-form';
export const CHATS_MOBILE = '/my-chats';

export const CLIENT_PUBLICATIONS_ROUTE = '/management/client/publications';
export const CLIENT_PROJECTS_ROUTE = '/management/client/projects';

export const FREELANCER_REQUESTS_ROUTE = '/management/freelancer/requests';
export const FREELANCER_PUBLICATIONS_ROUTE = '/management/freelancer/publications';
export const FREELANCER_PROJECTS_ROUTE = '/management/freelancer/projects';

export const ROUTES_WITH_FILTERS_MOBILE = [
  HOME_ROUTE,
  MORECONFIGURATIONS,
  CLIENT_PUBLICATIONS_ROUTE,
  CLIENT_PROJECTS_ROUTE,
  FREELANCER_REQUESTS_ROUTE,
  FREELANCER_PUBLICATIONS_ROUTE,
  FREELANCER_PROJECTS_ROUTE,
];

export const GO_TO_FREELANCER = '/service';

export const GO_TO_SERVICE = (id) => `/service/${id}/view`;
