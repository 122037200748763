import React, { Fragment } from 'react';
import css from './LinksContainer.module.scss';
import { Title } from 'components/common';
import { LINKS } from '../../Header.constants';

function LinksContainer({ currentLink }) {
  const isSelected = (name) => {
    if (name == currentLink) {
      return true;
    }
    return false;
  };
  return (
    <Fragment>
      {LINKS.map((link, index) => {
        return (
          <div
            className={`${css.link} ${isSelected(link.name) && css.selected}`}
            key={`link-${index}`}>
            <a href={link.href}>
              <Title style={{ marginBottom: '0' }} color="main" type="secondary">
                {link.label}
              </Title>
            </a>
          </div>
        );
      })}
    </Fragment>
  );
}

export default LinksContainer;
