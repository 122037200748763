import React from 'react';
import { getService } from '@actions/services';
import { getUser } from '@actions/users';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCurrentService,
  selectCurrentUser,
  selectLoadingService,
  selectLoadingUser,
} from '@reducers/navigation';
import { useParams } from 'react-router-dom';

const ServiceViewModel = () => {
  const dispatch = useDispatch();
  const { serviceId } = useParams();

  const loadingUser = useSelector(selectLoadingUser);
  const loadingService = useSelector(selectLoadingService);

  const currentService = useSelector(selectCurrentService);
  const currentUser = useSelector(selectCurrentUser);

  const useGetService = () => dispatch(getService(serviceId));
  const useGetUser = (service) => {
    dispatch(getUser(service));
  };

  React.useEffect(() => {
    if (serviceId !== currentService?.id) {
      useGetService(serviceId);
    }
  }, [serviceId]);

  React.useEffect(() => {
    if (currentService && currentUser?.id !== currentService?.owner) {
      useGetUser(currentService?.owner);
    }
  }, [currentService]);

  const isLoading = loadingUser || loadingService || !currentService || !currentUser;
  return { isLoading, currentService, currentUser };
};

export default ServiceViewModel;
