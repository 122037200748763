import React, { Fragment } from 'react';
import { ActualState, Category, SubCategory } from 'components/project/NewProjectForm/FormItems';
import css from './CommonBeginningQuestions.module.scss';

const CommonBeginningQuestions = ({
  values,
  handleSelectActualState,
  handleSelectCategory,
  mainCategories,
  isLoadingMainCategories,
  handleSelectSubCategory,
  subCategories,
  isLoadingSubCategories,
  currentFocus,
  branchStatus,
  displayedQuestions,
  handleGoBackQuestion,
  handleGoForwardQuestion,
  displayDistinctQuestion,
}) => {
  return (
    <Fragment>
      <div className={css.top}></div>
      <Category
        values={values}
        handleSelectCategory={handleSelectCategory}
        mainCategories={mainCategories}
        isLoadingMainCategories={isLoadingMainCategories}
        currentFocus={currentFocus}
        handleGoBackQuestion={handleGoBackQuestion}
        handleGoForwardQuestion={handleGoForwardQuestion}
        displayDistinctQuestion={displayDistinctQuestion}
      />

      {displayedQuestions.includes('actual_state') && (
        <ActualState
          values={values}
          handleSelectActualState={handleSelectActualState}
          currentFocus={currentFocus}
          handleGoBackQuestion={handleGoBackQuestion}
          handleGoForwardQuestion={handleGoForwardQuestion}
          displayDistinctQuestion={displayDistinctQuestion}
        />
      )}

      {displayedQuestions.includes('sub_category') && (
        <SubCategory
          values={values}
          handleSelectSubCategory={handleSelectSubCategory}
          subCategories={subCategories}
          isLoadingSubCategories={isLoadingSubCategories}
          currentFocus={currentFocus}
          branchStatus={branchStatus}
          handleGoBackQuestion={handleGoBackQuestion}
          handleGoForwardQuestion={handleGoForwardQuestion}
          displayDistinctQuestion={displayDistinctQuestion}
        />
      )}
    </Fragment>
  );
};

export default CommonBeginningQuestions;
