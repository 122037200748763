import React from 'react';
import css from './ImagesCommon.module.scss';
import { Text } from 'components/common';
import { Image } from 'antd';
import { ilustrationsFolder } from '../../IntegrationsDetails.constants';

function ImagesCommon({ currentProject }) {
  return (
    <div className={css.container}>
      <div className={css.header}>
        <Text type="detail">Imágenes: </Text>
      </div>
      {currentProject?.project_files?.length > 0 ? (
        <div className={css.images}>
          <Image.PreviewGroup>
            {currentProject?.project_files?.map((image) => (
              <Image key={image.id} height={150} width={150} src={image.upload} />
            ))}
          </Image.PreviewGroup>
        </div>
      ) : (
        <div className={css.empty}>
          <img src={ilustrationsFolder + 'dreamer_e856445593.svg'} />
          <Text type="smallDetail">Sin imágenes</Text>
        </div>
      )}
    </div>
  );
}

export default ImagesCommon;
