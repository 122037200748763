import React from 'react';
import { FormItemCard } from '../../FormItemCard';
import { websiteFunctionalitiesOptions } from 'common/constants/publicationForm.constants';

import { MultipleChoiceQuestion } from 'components/project/NewProjectForm/MultipleChoiceQuestion';

const WebsiteFunctionalities = ({
  values,
  handleSelectWebsiteFunctionalities,
  currentFocus,
  handleGoBackQuestion,
  handleGoForwardQuestion,
  displayDistinctQuestion,
}) => {
  return (
    <FormItemCard
      name="website_functionalities"
      title="¿Qué funcionalidades necesitas para tu plataforma?"
      subTitle={'Elige una o más opciones'}
      isCurrent={currentFocus == 'website_functionalities'}
      id={'website_functionalities-container'}
      handleGoForwardQuestion={handleGoForwardQuestion}
      handleGoBackQuestion={handleGoBackQuestion}
      displayDistinctQuestion={displayDistinctQuestion}>
      <MultipleChoiceQuestion
        optionsList={websiteFunctionalitiesOptions}
        isFocused={currentFocus == 'website_functionalities'}
        currentSelected={values['website_functionalities']}
        handleSelect={handleSelectWebsiteFunctionalities}
      />
    </FormItemCard>
  );
};

export default WebsiteFunctionalities;
