import { Divider } from 'antd';
import { Text } from 'components/common';
import CustomCalendar from 'components/common/CustomCalendar';
import ProjectSteps from 'components/common/ProjectSteps';
import useCurrentProjectStages from 'components/common/ProjectSteps/useCurrentProjectStages';
import React from 'react';
import '../Client/ClientProjects/ClientProjectManagment/ClientProjectManagment.scss';

const CalendarStagesContainer = ({ currentProject, type, setRefetchProject }) => {
  const { getDatesWithStatus, sortedStages } = useCurrentProjectStages({
    currentProject,
    setRefetchProject,
  });
  return (
    <div className="client-project__chat-wrapper">
      <Text type="detail">Panel de entregas</Text>
      <div className="client-project_calendar-container">
        <ProjectSteps
          currentProject={currentProject}
          type={type}
          setRefetchProject={setRefetchProject}
        />
        <Divider />
        {sortedStages.length > 0 && <CustomCalendar datesWithStatus={getDatesWithStatus()} />}
      </div>
    </div>
  );
};

export default CalendarStagesContainer;
