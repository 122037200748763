/* eslint-disable no-unused-vars */
import React from 'react';
import { Rate } from 'antd';
import './UserStats.scss';
import { VERIFIED_ACCOUNT_ICON_URL } from '@common/constants/assets.constants';
import { Title } from '@components/common';
import UserMedals from '../UserMedals/UserMedals';

export function UserStats({ user, profile }) {
  return (
    <div className="user-stats__container">
      <div className="user-stats__profile">
        <img
          src={user?.profile_image}
          className={profile ? 'user-stats__profile-image-drawer' : 'user-stats__profile-image'}
          alt="profileImg"
        />
      </div>
      <div className="user-stats__name-verified">
        <Title>{user?.first_name}</Title>
        <img
          className="user-stats__verficated-icon"
          src={VERIFIED_ACCOUNT_ICON_URL}
          alt="profile"
        />
      </div>
      <div className="user-stats__star-rate">
        <Rate allowHalf disabled defaultValue={user?.overall_rating} />
      </div>
    </div>
  );
}
