import React, { Component } from 'react';
import { Collapse } from 'antd';
import '@assets/styles/layouts/LegalFiles.scss';
import { Text, Title } from '@components/common';

const { Panel } = Collapse;

export class BehaviourAndSanctions extends Component {
  render() {
    return (
      <div className="custom-container">
        <div className="documents-ctn">
          <Title size="25" type="secondary" color="primary">
            Normas de comportamiento y sanciones aplicables
          </Title>
          <Text type="legalFiles">
            <strong>TE NECESITO</strong> busca brindar un servicio a sus Usuarios que les permita
            lograr sus objetivos. Para ello es fundamental que clientes y quienes ofrecen sus
            servicios (<em>Freelancers</em>) cumplan con las siguientes normas, las cuales son
            aceptadas por todos los usuarios que han aceptado los Términos y Condiciones de{' '}
            <strong>TE NECESITO</strong> (<a href="#/Terms&Conditions">Términos y Condiciones</a>).
            Sólo cumpliendo estas normas, <strong>TE NECESITO</strong> podrá ser de utilidad para la
            realización de los proyectos según las condiciones establecidas, o si la situación lo
            requiere, de arbitrar de justa manera ante cualquier desacuerdo entre las partes
            posterior a la finalización del trabajo.
          </Text>
          <Text type="legalFiles">
            En virtud de lo anterior, se han establecido ciertas normas que tienen por objeto velar
            por el adecuado cumplimiento de las condiciones propias de cada proyecto.
          </Text>
          <Collapse>
            <Panel header="1. Normas de comportamiento usuarios " key="1">
              <Text type="big">
                En caso de transgredir las normas que se indican a continuación,{' '}
                <strong>TE NECESITO</strong> podrá aplicar las sanciones indicadas en la siguiente
                enumeración.
              </Text>
              <Text type="big">
                i. Cada Usuario podrá abrir sólo una cuenta asociada a su RUT, ya sea de persona
                natural o jurídica.
              </Text>
              <Text type="big">ii. La cuenta será para el uso individual del Usuario.</Text>
              <Text type="big">
                iii. Ningún Usuario puede enviar ni solicitar datos de contacto externo a{' '}
                <strong>TE NECESITO</strong> antes que se realice el pago establecido en nuestras
                condiciones comerciales.
              </Text>
              <Text type="big">
                iv. Ningún Usuario puede solicitar ni enviar pagos por fuera del servicio o Términos
                de <strong>TE NECESITO</strong>; como así tampoco ningún cliente puede enviar un
                pago externo a un <em>FreeLancer</em>. En este sentido, <strong>TE NECESITO</strong>{' '}
                prohíbe que Usuarios y FreeLancers se conecten o discutan aspectos relativos a sus
                proyectos y/o servicios por fuera del Sitio Web provisto para tales fines por{' '}
                <strong>TE NECESITO</strong>.
              </Text>
              <Text type="big">
                v. Ningún <em>FreeLancer</em> podrá interferir en la puja entre distintos usuario .
              </Text>
              <Text type="big">
                vi. Ningún Usuario podrá dar a conocer los datos personales de otros Usuarios.
              </Text>
              <Text type="big">
                vii. Ningún Usuario puede enviar contenido ofensivo a otro Usuario o a un{' '}
                <em>FreeLancer</em>.
              </Text>
              <Text type="big">viii. Se prohíbe a los Usuarios autocontratar.</Text>
              <Text type="big">
                ix. El incumplimiento de lo acordado inicialmente respecto de un cliente podrá
                generar sanciones por parte de <strong>TE NECESITO</strong>, independiente de las
                consecuencias legales que adicionalmente pudieran corresponder, o bien, de las que
                las partes hayan acordado.
              </Text>
              <Text type="big">
                x. Se prohíbe utilizar el servicio o el Sitio para acordar o realizar trabajos no
                remunerados.
              </Text>
              <Text type="big">
                xi. Ningún usuario de <strong>TE NECESITO</strong> puede publicar o vender artículos
                prohibidos por los Términos y Condiciones Generales, las demás políticas de{' '}
                <strong>TE NECESITO</strong> o que prohíban las normas vigentes.
              </Text>
            </Panel>
            <Panel header="2. Incumplimiento de estas normas" key="2">
              <Text type="big">
                En caso de incumplir alguna de las disposiciones indicadas previamente por
                <strong> TE NECESITO,</strong> y aceptadas por todo Usuario,
                <strong> TE NECESITO</strong> podrá aplicar cualquiera de las sanciones que a
                continuación se enumeran, según la gravedad del incumplimiento o infracción de que
                se trate:
              </Text>
              <Text type="big">i. Advertencia(s);</Text>
              <Text type="big">
                ii. Sanción en puntaje <strong>TE NECESITO</strong>;
              </Text>
              <Text type="big">iii. Suspensión temporal de la cuenta;</Text>
              <Text type="big">iv. Cierre definitivo de la cuenta;.</Text>
              <Text type="big">
                (Estas sanciones se establecen sin perjuicio de las demás acciones o medidas que en
                derecho correspondan y que <strong>TE NECESITO </strong>
                pueda ejercer contra Usuarios, <em>FreeLancers</em> o terceros ).
              </Text>
            </Panel>
          </Collapse>
        </div>
      </div>
    );
  }
}
