import { Tooltip } from 'antd';
import React from 'react';
import { STATUS } from './CustomCalendar.constants';
import css from './CustomCalendar.module.scss';

const CalendarDay = ({ day, children }) => {
  const STATUS_CSS = {
    [STATUS.PROJECT_START]: css.project_start,
    [STATUS.STAGE_START]: css.stage_start,
    [STATUS.STAGE_COMPLETED]: css.stage_completed,
    [STATUS.PROJECT_COMPLETED]: css.project_completed,
  };
  if (day?.status) {
    return (
      <Tooltip title={day.title}>
        <span className={STATUS_CSS[day?.status]}>{children}</span>
      </Tooltip>
    );
  }
  return <span className={css.day}>{children}</span>;
};

export default CalendarDay;
