import { ProjectDetails } from 'components/common';
import React from 'react';

const ClientPublicationProject = ({ currentProject }) => {
  return (
    <div className={`${!currentProject.active && 'client-publication__deactivate'}`}>
      <div>
        <ProjectDetails currentProject={currentProject} noTitle />
      </div>
    </div>
  );
};

export default ClientPublicationProject;
