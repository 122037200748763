import React, { Fragment, useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { Edit2, Save } from 'react-feather';
import css from '../Forms.module.scss';

const UrlType = ({ handleSetAnswer, answer }) => {
  const [value, setValue] = useState({ url1: '', url2: '', url3: '' });
  const [isWriting, setIsWriting] = useState({ url1: true, url2: true, url3: true });
  const [errors, setErrors] = useState({ url1: false, url2: false, url3: false });

  const handleChange = (e) => {
    if (e?.target?.value?.length > 200) {
      return;
    }
    setValue({ ...value, [e.target.name]: e.target.value });
  };
  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSave(e.target.name);
    }
  };
  const onSave = (url) => {
    if (validateIsUrl(url)) {
      setIsWriting({ ...isWriting, [url]: false });
      setErrors({ ...errors, [url]: false });
      handleSetAnswer(value, 'url');
    } else {
      setErrors({ ...errors, [url]: true });
    }
  };

  const validateIsUrl = (url) => {
    //regex validation for ulrs
    const regex = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',

      'i'
    );

    return regex.test(value[url]);
  };
  const urls = ['url1', 'url2', 'url3'];

  useEffect(() => {
    if (answer?.answers?.length > 0) {
      const urls = answer?.answers?.[0];
      setValue({ url1: '', url2: '', url3: '', ...answer?.answers[0] });
      setIsWriting({ url1: urls.url1 === '', url2: urls.url2 === '', url3: urls.url3 === '' });
    }
  }, [answer]);

  return (
    <div className={css.url_container}>
      {urls.map((url) => (
        <Fragment key={url}>
          {isWriting[url] ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                maxWidth: 480,
                width: '100%',
                justifyContent: 'space-between',
                gap: '2em',
              }}>
              <div style={{ textAlign: 'left', width: '100%' }}>
                <TextField
                  variant="standard"
                  placeholder="example.com"
                  value={value[url]}
                  onKeyDown={onKeyDown}
                  name={url}
                  onChange={handleChange}
                  onBlur={() => onSave(url)}
                  style={{ width: '100%' }}
                />
                <div style={{ textAlign: 'right', width: '100%', maxWidth: 480, color: 'gray' }}>
                  <span>{value[url]?.length}/200</span>
                </div>
                {errors[url] && <span style={{ color: 'red' }}>Ingresa una url valida</span>}
              </div>
              <Save onClick={() => onSave(url)} style={{ cursor: 'pointer', color: '#3383ff' }} />
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                maxWidth: 480,
                width: '100%',
                justifyContent: 'space-between',
                wordBreak: 'break-all',
              }}>
              <a href={value[url]} target="_blank" rel="noreferrer" style={{ fontSize: 18 }}>
                {value[url]}
              </a>
              <Edit2
                onClick={() => setIsWriting({ ...isWriting, [url]: true })}
                style={{ cursor: 'pointer', color: '#3383ff' }}
              />
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default UrlType;
