import React from 'react';
import { QuestionBox, Button } from '@components/common';
import './QuestionContainer.scss';
import TextArea from 'antd/lib/input/TextArea';
import AccountsManagement from 'components/layouts/Header/components/Accounts/AccountsManagement';
import { Text } from '../Text';

const QuestionContainer = ({
  type,
  user,
  handleCreateQuestion,
  authUser,
  questionsList,
  isAnswerable = true,
}) => {
  const [questionText, setQuestionText] = React.useState('');

  const handleSubmitQuestion = () => {
    handleCreateQuestion(questionText);

    setQuestionText('');
  };
  if (!authUser?.isAuthenticated) {
    return (
      <div className="publication-questions-container">
        <Text type="detail" centered style={{ marginBottom: 10 }}>
          Debes iniciar sesión o registrar una cuenta para poder hacer una pregunta.
        </Text>
        <AccountsManagement />
      </div>
    );
  }
  return (
    <div className="publication-questions-container">
      {questionsList?.map((question) => {
        return <QuestionBox question={question} key={question.id} user={user} type={type} />;
      })}
      {authUser.isAuthenticated && isAnswerable ? (
        <div className="publication-create-question">
          <TextArea
            rows="3"
            maxLength={5000}
            showCount
            className="publication-create-question-input"
            placeholder="Escribe tu consulta"
            name="question-create-input"
            id="question-create-input"
            value={questionText}
            onChange={(e) => setQuestionText(e.target.value)}
          />

          <Button
            text="Enviar"
            variant="secondary"
            borderColor={type === 'project' ? 'primary' : 'secondary'}
            onClick={() => handleSubmitQuestion()}
          />
        </div>
      ) : null}
    </div>
  );
};

export default QuestionContainer;
