import React, { useEffect } from 'react';
import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import { googleLogin } from '@actions/auth';
import { useDispatch } from 'react-redux';

const CLIENT_ID = '1078402580071-v9njktqdntnv7nicvpgrjt5fq01n65lo.apps.googleusercontent.com';

function GoogleOAuthBtn({ setShowRegisterWindow, setShowLoginWindow }) {
  const dispatch = useDispatch();
  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: CLIENT_ID,
        scope: '',
      });
    }
    gapi.load('client:auth2', start);
  }, []);

  async function handleLogin(response) {
    console.log('response', response);
    if (response.tokenId) {
      dispatch(googleLogin(response.tokenId));
    }

    setShowRegisterWindow(false);
    setShowLoginWindow(false);
  }

  const handleLoginFailure = () => {
    alert(
      'El inicio de sesión ha tenido un problema, intenta nuevamente o prueba con otro tipo de inicio de sesión.'
    );
  };
  return (
    <div className="google-register-container">
      <GoogleLogin
        clientId={CLIENT_ID}
        scope="profile email"
        render={(renderProps) => (
          <button onClick={renderProps.onClick} className="google-button">
            Conectar con Google
          </button>
        )}
        onSuccess={handleLogin}
        onFailure={() => handleLoginFailure}
        cookiePolicy={'single_host_origin'}
        responseType="code,token"
      />
    </div>
  );
}

export default GoogleOAuthBtn;
