import React from 'react';
import { clearRutInput, rutFormater } from 'common/utils/Formaters';

const useRut = () => {
  const [currentRut, setCurrentRut] = React.useState('');

  const onChangeRut = (event) => {
    const rutInput = clearRutInput(event.target.value);
    const formatRut = rutFormater(rutInput);
    setCurrentRut(formatRut);
  };

  return { currentRut, onChangeRut };
};

export default useRut;
