import React, { Fragment } from 'react';
import './CompletedProjects.scss';
import { Rate } from 'antd';
import projectService from '../../../services/projectService';
import { LoadingDots, Text, Title } from 'components/common';

export function CompletedProjects({ currentUser, type }) {
  const [completedProjects, setCompletedProjects] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const isOwner = (project) => {
    if (currentUser.id == project?.owner) {
      return true;
    } else {
      return false;
    }
  };

  React.useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      if (currentUser) {
        projectService
          .getOwnerProjectsStatus(currentUser?.id, 'completed')
          .then((res) => {
            const ownerProjects = res.data.objects;
            projectService.getCollabProjectsStatus(currentUser?.id, 'completed').then((res2) => {
              const totalProjects = ownerProjects.concat(res2.data.objects);
              setCompletedProjects(totalProjects);
              setIsLoading(false);
            });
          })
          .finally(() => setIsLoading(false));
      }
    }
    fetchData();
  }, [currentUser]);

  return !isLoading ? (
    <div className="completed-projects__container">
      {completedProjects.length != 0 && (
        <Fragment>
          {' '}
          <Title type="tertiary" color={type == 'project' ? 'primary' : 'secondary'}>
            {`Proyectos Completados (${completedProjects.length})`}
          </Title>
          {completedProjects.map((project) => (
            <div className="completed-projects__project" key={`proj-${project?.id}`}>
              <div className="completed-projects__header">
                <Text type="smallBold">{project?.title}</Text>
                <div className="completed-projects__rate">
                  {isOwner(project) ? (
                    <Rate
                      disabled
                      allowHalf
                      defaultValue={project?.collaborator_rating_data?.rating_average}
                    />
                  ) : (
                    <Rate
                      disabled
                      allowHalf
                      defaultValue={project?.client_rating_data?.rating_average}
                    />
                  )}
                </div>
              </div>
              <div className="completed-project__comment">
                {' '}
                {isOwner(project) ? (
                  <Text type="smallDetail">{project?.collaborator_rating_data?.comment}</Text>
                ) : (
                  <Text type="smallDetail">{project?.client_rating_data?.comment}</Text>
                )}
              </div>
            </div>
          ))}
        </Fragment>
      )}
    </div>
  ) : (
    <LoadingDots />
  );
}
