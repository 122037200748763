import axiosClient from '@config/axios';
import { tokenConfig } from './auth';
import { returnErrors } from './messages';
import { SELECT_CHAT } from './types';

export const createChatMsg = (message) => async (dispatch, getState) => {
  const result = await axiosClient
    .post(`/chat/sendMessage/`, message, tokenConfig(getState))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      return err.response;
    });
  return result;
};

export const getChatMsgs = (queryParams) => async (dispatch, getState) => {
  const result = await axiosClient
    .get(`/chat/chats/?${queryParams}`, tokenConfig(getState))
    .then((res) => {
      return res;
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      return err.response;
    });
  return result;
};

export const selectChat = (chat) => (dispatch) => {
  dispatch({
    type: SELECT_CHAT,
    payload: chat,
  });
};

export const getUserChats = (id) => axiosClient.get(`/chat/chats/?user=${id}`);

export const getChatId = (userId, projectId, token) =>
  axiosClient.get(`/chat/chats/?user=${userId}&project=${projectId}`, {
    headers: { Authorization: `Token ${token}` },
  });

export const getChatLastMessages = () => axiosClient.get(`/chat/last_message/`);

export const viewChat = (id) => {
  return axiosClient.patch(`/chat/chats/${id}`, { viewed: true });
};

export const videoCallSchedule = (data) => axiosClient.post('/zoom_meeting', data);
