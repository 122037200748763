import {
  GET_USER,
  GET_PROJECT,
  GET_PROJECT_LOADING,
  GET_SERVICE,
  GET_SERVICE_LOADING,
  GET_USER_PROJECTS,
  GET_USER_SERVICES,
  MY_PUBLICATIONS_PAGE_STATUS,
  GET_REQUEST,
  SERVICES_PAGINATION,
  PROJECTS_PAGINATION,
  MY_PROJECTS_PAGINATION,
  UPDATE_SERVICE,
  ACCEPT_REQUEST,
  MY_ACTIVE_PROJECTS_PAGINATION,
  DELETE_PROJECT,
  DELETE_SERVICE,
  HOME_PAGE_STATUS,
  ADD_QUESTION,
  DELETE_QUESTION,
  MORE_CONFIG_STATUS,
  UPDATE_CURRENT_PROJECT,
  MY_ACTIVE_PROJECTS_PAGINATION_COLLAB,
  GET_USER_LOADING,
} from '../actions/types.js';

const initialState = {
  user: null,
  project: null,
  service: null,
  myservice: null,
  myservices: null,
  myprojects: null,
  mypublicationspagestatus: null,
  homepagestatus: 'NORMAL',
  moreconfigstatus: 'PERSONAL DATA',
  request: null,
  total_projects: 10,
  total_services: 10,
  my_total_projects: 10,
  myActiveProjects: 10,
  myActiveProjectsCollab: 10,
  loadingProject: false,
  loadingService: false,
  loadingUser: false,
  errorProject: null,
  errorUser: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER:
      return {
        ...state,
        loadingUser: false,
        user: action.payload,
      };
    case GET_USER_LOADING:
      return {
        ...state,
        loadingUser: true,
      };

    case GET_PROJECT:
      return {
        ...state,
        loadingProject: false,
        project: action.payload,
      };

    case GET_PROJECT_LOADING:
      return {
        ...state,
        loadingProject: true,
      };

    case GET_SERVICE:
      return {
        ...state,
        loadingService: false,
        service: action.payload,
      };

    case GET_SERVICE_LOADING:
      return {
        ...state,
        loadingService: true,
      };
    case MORE_CONFIG_STATUS:
      return {
        ...state,
        moreconfigstatus: action.payload,
      };

    case GET_USER_SERVICES:
      return {
        ...state,
        myservices: action.payload,
      };

    case GET_USER_PROJECTS:
      return {
        ...state,
        myprojects: action.payload,
      };
    case MY_PUBLICATIONS_PAGE_STATUS:
      return {
        ...state,
        mypublicationspagestatus: action.payload,
      };

    case HOME_PAGE_STATUS:
      return {
        ...state,
        homepagestatus: action.payload,
      };

    case GET_REQUEST:
      return {
        ...state,
        request: action.payload,
      };
    case PROJECTS_PAGINATION:
      return {
        ...state,
        total_projects: action.payload,
      };

    case MY_PROJECTS_PAGINATION:
      return {
        ...state,
        my_total_projects: action.payload,
      };
    case SERVICES_PAGINATION:
      return {
        ...state,
        total_services: action.payload,
      };

    case MY_ACTIVE_PROJECTS_PAGINATION:
      return {
        ...state,
        myActiveProjects: action.payload,
      };
    case MY_ACTIVE_PROJECTS_PAGINATION_COLLAB:
      return {
        ...state,
        myActiveProjectsCollab: action.payload,
      };
    case ACCEPT_REQUEST:
      return Object.assign({}, state, {
        request: Object.assign({}, state.request, { status: action.payload.status }),
      });
    case DELETE_PROJECT:
      return {
        ...state,
        myprojects: state.myprojects.filter((project) => project.id !== action.payload),
      };
    case DELETE_SERVICE:
      return {
        ...state,
        myservices: state.myservices.filter((service) => service.id !== action.payload),
      };
    case UPDATE_SERVICE:
      return Object.assign({}, state, {
        myservices: state.myservices.map((service) => {
          if (service.id === action.payload.id) {
            return Object.assign({}, service, {
              status: action.payload.status,
            });
          }
          return service;
        }),
      });
    case ADD_QUESTION:
      if (action.payload.type === 'project') {
        return Object.assign({}, state, {
          project: Object.assign({}, state.project, {
            questions: state.project.questions.concat(action.payload.data),
          }),
        });
      } else {
        return Object.assign({}, state, {
          service: Object.assign({}, state.service, {
            questions: state.service.questions.concat(action.payload.data),
          }),
        });
      }
    case DELETE_QUESTION:
      if (action.payload.type === 'project') {
        return Object.assign({}, state, {
          project: Object.assign({}, state.project, {
            questions: state.project.questions.filter(
              (question) => question.id !== action.payload.data
            ),
          }),
        });
      } else {
        return Object.assign({}, state, {
          service: Object.assign({}, state.service, {
            questions: state.service.questions.filter(
              (question) => question.id !== action.payload.data
            ),
          }),
        });
      }
    case UPDATE_CURRENT_PROJECT:
      return Object.assign({}, state, {
        project: Object.assign({}, state.project, action.payload),
      });
    default:
      return state;
  }
}

export const selectCurrentService = (state) => state.navigation.service;
export const selectCurrentProject = (state) => state.navigation.project;
export const selectCurrentUser = (state) => state.navigation.user;
export const selectCurrentRequest = (state) => state.navigation.request;
export const selectLoadingService = (state) => state.navigation.loadingService;
export const selectLoadingProject = (state) => state.navigation.loadingProject;
export const selectLoadingUser = (state) => state.navigation.loadingUser;
export const selectMoreConfigStatus = (state) => state.navigation.moreconfigstatus;
