import { Button } from 'components/common';
import React from 'react';
import StandardModal from 'components/common/StandardModal';
import { Spin } from 'antd';

const DeletePrevProjectModal = ({ visible, onCancel, handleDelete, isLoading }) => {
  return (
    <StandardModal
      visible={visible}
      onCancel={onCancel}
      title="¿Estas seguro/a que quieres eliminar este proyecto?"
      width={400}
      button={
        <Button
          text={isLoading ? <Spin /> : 'Eliminar proyecto'}
          disabled={isLoading}
          variant="secondary"
          borderColor="error"
          size="sm"
          onClick={handleDelete}
        />
      }></StandardModal>
  );
};

export default DeletePrevProjectModal;
