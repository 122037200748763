export const REGEX_URL = 'https?://(?:www.|(?!www))';

export const SUCCESS_MESSAGE_EDIT_SOCIAL_MEDIA = 'Su cuenta ha sido agregada con éxito';

export const SUCCESS_MESSAGE_DELETE_SOCIAL_MEDIA = 'Su cuenta ha sido eliminada con éxito';

export const ERROR_API_EDIT_SOCIAL_MEDIA =
  'Hubo un problema con el servidor, porfavor intente más tarde.';

export const REGEX_SOCIAL_MEDIA = {
  github_link: {
    regex: '^(https?://)?(www.)?github.com/[a-zA-Z0-9_]{1,25}',
    error: 'La cuenta de Github que ingresaste no es válida, porfavor intente nuevamente.',
  },
  stackoverflow_link: {
    regex:
      '^(https?://)?(www.)?stackoverflow.com/[a-zA-Z0-9_]{1,25}/[a-zA-Z0-9_]{1,25}/[a-zA-Z0-9_]{1,25}',
    error: 'La cuenta de StackOverflow que ingresaste no es válida, porfavor intente nuevamente.',
  },
  briefcase_link: {
    regex:
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
    error: 'La url que ingresaste no es válida, porfavor intente nuevamente.',
  },
};
