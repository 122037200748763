import React, { useState } from 'react';
import { Button, Title } from 'components/common';
import { Input, Form } from 'antd';
import {
  SOCIAL_ICON,
  SOCIAL_ICON1,
  SOCIAL_ICON2,
  SOCIAL_ICON3,
  SOCIAL_ICON4,
  SOCIAL_ICON5,
} from 'pages/AboutUs/aboutus.constants';
import './ContactUs.scss';
import {
  BLOG_URL,
  CONTACT_PHONE,
  FACEBOOK_URL,
  INSTAGRAM_URL,
  LINKEDIN_URL,
  YOUTUBE_URL,
} from 'common/constants/main.constants';
import { useDispatch } from 'react-redux';
import { createMessage } from 'actions/messages';
import { createContact } from 'actions/contact';

const { TextArea } = Input;

const ContactUs = () => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleCreateContact = () => {
    if (name && email && email.includes('@') && message) {
      createContact(name, email, message)
        .then(() => {
          dispatch(createMessage({ successMessage: '¡Se ha recibido tu mensaje exitosamente!' }));
        })
        .catch(() => {
          dispatch(
            createMessage({
              errorMessage:
                'Ha ocurrido un error inesperado, verifica que todos los campos esten completos y el email esté escrito correctamente',
            })
          );
        });
    } else {
      dispatch(
        createMessage({
          errorMessage:
            'Ha ocurrido un error inesperado, verifica que todos los campos esten completos y el email esté escrito correctamente',
        })
      );
    }
  };

  const handleSubmitContact = () => {
    handleCreateContact();
    setName('');
    setEmail('');
    setMessage('');
  };

  const handleChangeName = (e) => {
    setName(e.target.value);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleChangeMessage = (e) => {
    setMessage(e.target.value);
  };

  return (
    <div className="about__us-content">
      <div className="aboutus__left">
        <Title type="xl" color="white">
          ¿Necesitas contactarnos?
        </Title>
        <Title type="primary-nobold" color="white">
          Déjanos tu mensaje aquí y nuestro equipo te escribirá lo antes posible
        </Title>
        <Form className="contactus__form">
          <Form.Item>
            <TextArea
              placeholder="Nombre"
              name="anombre"
              value={name}
              onChange={handleChangeName}
              required={true}
              autoSize={{ minRows: 1 }}
            />
          </Form.Item>
          <Form.Item>
            <TextArea
              placeholder="Correo Electrónico"
              name="email"
              value={email}
              onChange={handleChangeEmail}
              required={true}
              autoSize={{ minRows: 1 }}
            />
          </Form.Item>
          <Form.Item>
            <TextArea
              rows={4}
              placeholder="Mensaje"
              name="message"
              maxLength={200}
              showCount
              onChange={handleChangeMessage}
              required={true}
              value={message}
            />
          </Form.Item>
          <Form.Item>
            <Button
              onClick={handleSubmitContact}
              text="Enviar"
              type="landing-btn"
              variant="secondary"
            />
          </Form.Item>
        </Form>
      </div>
      <div className="aboutus__right">
        <Title size="18" type="subtitle">
          ¡Síguenos en nuestras redes!
        </Title>
        <div className="social__media">
          <div className="social__upper">
            <a className="social__icon" target="blank" href={BLOG_URL}>
              <div className="upper__icon">
                <img src={SOCIAL_ICON} alt="freelancer_social" />
              </div>
              <div className="lower__icon">
                <Title type="subtitle">Blog</Title>
              </div>
            </a>
            <a className="social__icon" target="blank" href={INSTAGRAM_URL}>
              <div className="upper__icon">
                <img src={SOCIAL_ICON1} alt="freelancer_social" />
              </div>
              <div className="lower__icon">
                <Title type="subtitle">Instagram</Title>
              </div>
            </a>
            <a
              className="social__icon"
              href={`https://wa.me/${CONTACT_PHONE}`}
              target="_blank"
              rel="noreferrer">
              <div className="upper__icon">
                <img src={SOCIAL_ICON2} alt="freelancer_social" />
              </div>
              <div className="lower__icon">
                <Title type="subtitle">Whatsapp</Title>
              </div>
            </a>
          </div>
          <div className="social__low">
            <a className="social__icon" target="blank" href={LINKEDIN_URL}>
              <div className="upper__icon">
                <img src={SOCIAL_ICON3} alt="freelancer_social" />
              </div>
              <div className="lower__icon">
                <Title type="subtitle">LinkedIn</Title>
              </div>
            </a>
            <a className="social__icon" target="blank" href={YOUTUBE_URL}>
              <div className="upper__icon">
                <img src={SOCIAL_ICON4} alt="freelancer_social" />
              </div>
              <div className="lower__icon">
                <Title type="subtitle">Youtube</Title>
              </div>
            </a>
            <a className="social__icon" target="blank" href={FACEBOOK_URL}>
              <div className="upper__icon">
                <img src={SOCIAL_ICON5} alt="freelancer_social" />
              </div>
              <div className="lower__icon">
                <Title type="subtitle">Facebook</Title>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
