import React from 'react';
import './DropdownItem.scss';
import sanitizeHtml from 'sanitize-html';
import { RenderTimestamp, Text, Title } from 'components/common';

const DropdownItem = ({ notification, handleNotificationRedirection }) => {
  const sanitizedData = {
    __html: sanitizeHtml(notification.get_notification_text),
  };

  return (
    <div className="dropdown-item__container">
      <a
        className="dropdown-item__content"
        onClick={() => handleNotificationRedirection(notification)}>
        <div className="dropdown-item__image">
          <img src={notification.picture} />
        </div>
        <div className="dropdown-item__template">
          <p
            className="tn-text tn-text--regular tn-text--small"
            dangerouslySetInnerHTML={sanitizedData}></p>
          <Text type="smallBoldBlue">{RenderTimestamp(notification.created_at).toLowerCase()}</Text>
        </div>
        {!notification.viewed && (
          <Title size="30" type="primary" color="main">
            •
          </Title>
        )}
      </a>
    </div>
  );
};

export default DropdownItem;
