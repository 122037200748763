/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import { TextField } from '@material-ui/core';
import { Edit2, Save } from 'react-feather';
import css from '../Forms.module.scss';

const UniqueUrlType = ({ handleSetAnswer, answer }) => {
  const [value, setValue] = useState('');
  const [isWriting, setIsWriting] = useState(true);
  const [errors, setErrors] = useState(false);

  const handleChange = (e) => {
    if (e?.target?.value?.length > 200) {
      return;
    }
    setValue(e.target.value);
  };
  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSave();
    }
  };
  const onSave = () => {
    if (validateIsUrl()) {
      setIsWriting(false);
      setErrors(false);
      handleSetAnswer({ value, label: value }, 'uniqueUrl');
    } else {
      setErrors(true);
    }
  };
  useEffect(() => {
    if (answer?.answers?.length > 0) {
      const ans = answer?.answers?.[0];
      setIsWriting(false);
      setValue(ans?.value);
    }
  }, [answer]);

  const validateIsUrl = () => {
    //regex validation for ulrs
    const regex = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',

      'i'
    );

    return regex.test(value);
  };

  return (
    <div className={css.url_container}>
      {isWriting ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            maxWidth: 480,
            width: '100%',
            justifyContent: 'space-between',
            gap: '2em',
          }}>
          <div style={{ textAlign: 'left', width: '100%' }}>
            <TextField
              variant="standard"
              placeholder="example.com"
              value={value}
              onKeyDown={onKeyDown}
              name="url"
              onChange={handleChange}
              onBlur={() => onSave()}
              style={{ width: '100%' }}
            />
            <div style={{ textAlign: 'right', width: '100%', maxWidth: 480, color: 'gray' }}>
              <span>{value?.length}/200</span>
            </div>
            {errors && <span style={{ color: 'red' }}>Ingresa una url valida</span>}
          </div>
          <Save onClick={() => onSave()} style={{ cursor: 'pointer', color: '#3383ff' }} />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            maxWidth: 480,
            width: '100%',
            justifyContent: 'space-between',
            wordBreak: 'break-all',
          }}>
          <a href={value} target="_blank" rel="noreferrer" style={{ fontSize: 18 }}>
            {value}
          </a>
          <Edit2
            onClick={() => setIsWriting(true)}
            style={{ cursor: 'pointer', color: '#3383ff' }}
          />
        </div>
      )}
    </div>
  );
};

export default UniqueUrlType;
