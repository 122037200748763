import React, { Fragment } from 'react';
import { GraphicContainer, Title, Text } from '@components/common';
import {
  TENECESITO_ADVISORS_LIST,
  TENECESITO_TEAM_LIST,
} from '../../common/constants/aboutus.constants';
import { TeamMemberCard } from '../../pages/AboutUs/components/TeamMemberCard';
import {
  ABOUT_US_HEADER_BACKGROUND,
  ABOUTUS_CIRCLE_HANDS,
  ABOUTUS_TEXT,
  ABOUTUS_TITLE,
} from './aboutus.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagicWandSparkles, faUpDownLeftRight } from '@fortawesome/free-solid-svg-icons';
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import ContactUs from './components/ContactUs/ContactUs';
import './AboutUs.scss';

const AboutUs = () => {
  return (
    <div className="aboutus__layout">
      <div
        className="aboutus__header"
        style={{
          backgroundImage: `url(${ABOUT_US_HEADER_BACKGROUND})`,
        }}>
        <div className="header__container">
          <div className="header__content">
            <div className="header__title">
              <div className="header__sentence">
                {ABOUTUS_TITLE.map((item, index) => (
                  <Fragment key={`title-${index}`}>
                    <Title size="35" type="primary-nobold" color="white">
                      {item.title1}
                    </Title>{' '}
                    <Title size="35" type="xl" color="white">
                      {item.title2}
                    </Title>
                  </Fragment>
                ))}
              </div>
              {ABOUTUS_TITLE.map((item, index) => (
                <Title key={`title-${index}`} size="35" type="primary-nobold" color="white">
                  {item.title3}
                </Title>
              ))}
            </div>
            {ABOUTUS_TITLE.map((item, index) => (
              <Fragment key={`title-${index}`}>
                <Title type="primary-nobold" color="white">
                  {item.subtitle1}
                </Title>{' '}
                <Title type="primary-nobold" color="white">
                  {item.subtitle2}
                </Title>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
      <div className="aboutus__info">
        <div className="info__container">
          <div className="info__left">
            {ABOUTUS_TEXT.map((item, index) => (
              <Text key={`text-${index}`} size="14">
                {item.text}
              </Text>
            ))}
          </div>
          <div className="info__right">
            <GraphicContainer maxHeight="450px" src={ABOUTUS_CIRCLE_HANDS} />
          </div>
        </div>
      </div>
      <div className="icons__items">
        <div className="icons__container__aboutus">
          <div className="icon__item">
            <div className="icon__single">
              <FontAwesomeIcon icon={faThumbsUp} />
            </div>
            <Title size="22" type="primary-nobold" color="white">
              Confiable
            </Title>
          </div>
          <div className="icon__item">
            <div className="icon__single">
              <FontAwesomeIcon icon={faMagicWandSparkles} />
            </div>
            <Title size="22" type="primary-nobold" color="white">
              Facilitador
            </Title>
          </div>
          <div className="icon__item">
            <div className="icon__single">
              <FontAwesomeIcon icon={faUpDownLeftRight} />
            </div>
            <Title size="22" type="primary-nobold" color="white">
              Versátil
            </Title>
          </div>
        </div>
      </div>
      <div className="about-us__members">
        <div className="about-us__who-we-are">
          <Title size="28" type="subtitle">
            Nuestro Equipo ✨
          </Title>
          <div className="about-us__team-member-card">
            {TENECESITO_TEAM_LIST.map((person, index) => (
              <TeamMemberCard
                key={index}
                url={person.url}
                name={person.name}
                position={person.position}
                description={person.description}
              />
            ))}
          </div>
        </div>
        <div className="about-us__who-we-are">
          <Title size="28" type="subtitle">
            Advisors
          </Title>
          <div className="about-us__team-member-card">
            {TENECESITO_ADVISORS_LIST.map((person, index) => (
              <TeamMemberCard
                key={index}
                url={person.url}
                name={person.name}
                position={person.position}
                description={person.description}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="contact__us">
        <div className="contact_us-container">
          <ContactUs />
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
