export const CONTACT_PHONE = '56988825504';
export const CONTACT_PHONE_TEXT = '+569 8882 5504';
export const MOBILE_BREAKPOINT = 795;
export const DESKTOPXSMALL_BREAKPOINT = 897;

export const INSTAGRAM_URL = 'https://www.instagram.com/tenecesito.cl/';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/tenecesito/';
export const YOUTUBE_URL = 'https://www.youtube.com/channel/UC8TJaxql-JhAaph1d3yLwDQ';
export const FACEBOOK_URL = 'https://www.facebook.com/people/Te-Necesito/100074494880894/';
export const BLOG_URL = 'https://www.tenecesito.cl/blog';
