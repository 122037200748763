import React from 'react';
import CommonGeneral from '../components/CommonGeneral';
import ModificationType from './ModificationType';
import ActualFrameworkCommon from '../components/ActualFrameworkCommon';
import ImagesCommon from '../components/ImagesCommon';
import css from './Modification.module.scss';

function Modification({ currentProject }) {
  return (
    <div className={css.container}>
      <div className={css.left}>
        <div className={css.general}>
          <CommonGeneral currentProject={currentProject} />
          <hr />
        </div>
        <div className={css.functionalities}>
          <ModificationType currentProject={currentProject} />
        </div>
      </div>
      <div className={css.right}>
        <div className={css.design}>
          <ActualFrameworkCommon currentProject={currentProject} />
        </div>
        <div className={css.tecnology}>
          <ImagesCommon currentProject={currentProject} />
        </div>
      </div>
    </div>
  );
}

export default Modification;
