import React, { useState } from 'react';
import { Title } from 'components/common';
import css from './Forms.module.scss';
import OptionType from './QuestionTypes/OptionType';
import TextFieldType from './QuestionTypes/TextFieldType';
import UrlType from './QuestionTypes/UrlType';
import SelectType from './QuestionTypes/SelectType';
import OtherField from './QuestionTypes/OtherField';
import TemplateType from './QuestionTypes/TemplateType';
import PaletteType from './QuestionTypes/PaletteType';
import ReactMarkdown from 'react-markdown';
import { Select } from 'antd';
import ImagesType from './QuestionTypes/ImagesType';
import UniqueUrlType from './QuestionTypes/UniqueUrlType';
function getAllCategories(question) {
  let categories = [];

  // loop through all the options
  for (let option of question.options) {
    // loop through all the categories in the option
    for (let category of option.integration_categories) {
      // add the category to the array if it's not already there
      if (!categories.includes(category)) {
        categories.push(category);
      }
    }
  }

  return categories;
}
const QuestionPage = ({
  question,
  handleSetAnswer,
  isOptionSelected,
  answer,
  templates,
  setTemplates,
  palettes,
  setSelectedCategory,
}) => {
  const OptionType = (
    <OptionsList
      question={question}
      handleSetAnswer={handleSetAnswer}
      isOptionSelected={isOptionSelected}
      type={question?.question_type}
      withOther={question?.withOther}
      answer={answer}
      setTemplates={setTemplates}
      templates={templates}
      setSelectedCategory={setSelectedCategory}
      groupedOptions={question?.groupedOptions}
      questionCategories={question?.groupedOptions ? getAllCategories(question) : null}
    />
  );
  const types = {
    multiple: OptionType,
    unique: OptionType,
    templateCategory: OptionType,
    flow: OptionType,
    multipleOptionCategory: OptionType,
    uniqueOptionCategory: OptionType,
    template: (
      <TemplateType
        templates={templates}
        handleSetAnswer={handleSetAnswer}
        isOptionSelected={isOptionSelected}
        answer={answer}
      />
    ),
    palette: (
      <PaletteType
        palettes={palettes}
        handleSetAnswer={handleSetAnswer}
        isOptionSelected={isOptionSelected}
        answer={answer}
      />
    ),
    textfield: (
      <TextFieldType
        handleSetAnswer={handleSetAnswer}
        answer={answer}
        maxLength={question?.maxLength}
      />
    ),
    url: <UrlType handleSetAnswer={handleSetAnswer} answer={answer} />,
    select: (
      <SelectType selects={question?.selects} handleSetAnswer={handleSetAnswer} answer={answer} />
    ),

    images: <ImagesType handleSetAnswer={handleSetAnswer} answer={answer} />,
    uniqueUrl: <UniqueUrlType handleSetAnswer={handleSetAnswer} answer={answer} />,
  };
  return (
    <div style={{ maxWidth: 960, width: '100%' }}>
      <div className={css.description}>
        <Title size={32} type="strongBold">
          {question?.title}
        </Title>
        <ReactMarkdown>{question?.description}</ReactMarkdown>
      </div>
      <div
        style={{
          minHeight: '50vh',
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {types[question?.question_type]}
      </div>
    </div>
  );
};
const OptionsList = ({
  question,
  handleSetAnswer,
  isOptionSelected,
  type,
  withOther,
  answer,
  setTemplates,
  templates,
  setSelectedCategory,
  groupedOptions,
  questionCategories,
}) => {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const handleChange = (value) => {
    setSelectedCategories(value);
  };
  const filteredOptions = question?.options?.filter((option) => {
    for (let category of selectedCategories) {
      if (option.integration_categories.includes(category)) {
        return true;
      }
    }
    return false;
  });

  const options = selectedCategories.length > 0 ? filteredOptions : question?.options;

  return (
    <>
      {groupedOptions && (
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <Select
            mode="tags"
            style={{ minWidth: 300 }}
            placeholder="Categorías"
            onChange={handleChange}
            options={questionCategories?.map((category) => ({
              label: category,
              value: category,
            }))}
          />
        </div>
      )}
      <div className={question?.isVertical ? css.optionsVertical : css.options}>
        {options?.map((option, index) => (
          <OptionType
            key={index}
            option={option}
            handleSetAnswer={handleSetAnswer}
            isOptionSelected={isOptionSelected}
            type={type}
            templates={templates}
            setTemplates={setTemplates}
            setSelectedCategory={setSelectedCategory}
            isVertical={question?.isVertical}
          />
        ))}
        {withOther && (
          <OtherField
            handleSetAnswer={handleSetAnswer}
            isOptionSelected={isOptionSelected}
            type={type}
            answer={answer}
            isVertical={question?.isVertical}
          />
        )}
      </div>
    </>
  );
};
export default QuestionPage;
