import React from 'react';
import { Image } from 'antd';
import css from './OldDetails.module.scss';
import { questionTitle } from 'common/constants/publicationForm.constants';
import { DisplayList, LoadingDots, Text, Title } from 'components/common';

const IMAGES_HEADER = 'Imágenes adjuntas:';
const DETAILS_HEADER = 'Detalles del proyecto:';
const STRING_ATTRIBUTES_LIST = [
  'actual_state',
  'website_type',
  'compatibility',
  'actual_db_status',
  'time_range',
  'budget_range',
];

const LIST_ATTRIBUTES_LIST = [
  'website_functionalities',
  'model_goal',
  'api_integration',
  'reference_urls',
];

const OldDetails = ({ currentProject, noTitle = false }) => {
  const projectImages = currentProject?.project_files;
  return (
    <>
      {currentProject ? (
        <div className={css.container} id="project-details-container">
          {projectImages && projectImages?.length > 0 && (
            <div className={css.imagesContainer}>
              <div className={css.header}>
                <Text type="detail">{IMAGES_HEADER}</Text>
              </div>
              <div className={css.images}>
                {projectImages.length > 0 && (
                  <Image.PreviewGroup>
                    {projectImages.map((image) => (
                      <Image key={image.id} height={100} width={100} src={image.upload} />
                    ))}
                  </Image.PreviewGroup>
                )}
              </div>
            </div>
          )}
          <div className={css.detailsContainer}>
            {!noTitle && (
              <div className={css.header}>
                <Text type="detail">{DETAILS_HEADER}</Text>
              </div>
            )}

            <div className={css.details}>
              <Title type="tertiary" color="primary">
                Descripción
              </Title>
              <Text type="detail">{currentProject?.description}</Text>
              {STRING_ATTRIBUTES_LIST.map((name, index) => {
                return (
                  currentProject[name] && (
                    <div key={`str-${index}`} className={css.section}>
                      <Title type="tertiary" color="primary">
                        {questionTitle[name]}
                      </Title>
                      <Text type="detail">{currentProject[name]}</Text>
                    </div>
                  )
                );
              })}

              {LIST_ATTRIBUTES_LIST.map((name, index) => {
                return (
                  currentProject[name] &&
                  currentProject[name].length > 0 && (
                    <div key={`list-${index}`} className={css.section}>
                      <Title type="tertiary" color="primary">
                        {questionTitle[name]}
                      </Title>
                      <DisplayList list={currentProject[name]} />
                    </div>
                  )
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <LoadingDots />
      )}
    </>
  );
};

export default OldDetails;
