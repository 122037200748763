/* eslint-disable no-unused-vars */
import { BaseLayout, Title } from 'components/common';
import React from 'react';
import useMyChats from './hooks/useMyChats';
import OpenedChat from './OpenedChat';
import css from './Chats.module.scss';
import { Search } from 'react-feather';
import { useMediaQuery } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'reducers/auth';
import { getTimeAgo } from 'common/utils/Formaters';
import { useHistory } from 'react-router-dom';

const ChatsPage = () => {
  const auth = useSelector(selectAuthUser);

  const {
    chats,
    handleOpenChat,
    filteredChats,
    searchValue,
    setSearchValue,
    openedChat,
    openedChats,
  } = useMyChats();

  const isLeftSidehidden = useMediaQuery('(max-width: 1024px)');

  const getLastMessage = (chat) => {
    return chat?.last_message?.content;
  };
  const isOwnerOfLastMessage = (chat) => {
    return chat?.last_message?.owner === auth?.user?.id;
  };

  return (
    <BaseLayout style={{ padding: '16px' }}>
      <BaseLayout.LeftSide>
        {!isLeftSidehidden && <div className={css.header}></div>}
        {isLeftSidehidden && (
          <div className={css.chat_items_search}>
            <input
              type="text"
              placeholder="Buscar..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <Search style={{ color: '#3383ff' }} />
          </div>
        )}
        <div
          style={{
            height: '420px',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            borderRadius: '0px 0px 10px 10px',
          }}>
          <div className={css.chats_container}>
            <div
              className={css.chats_items_container}
              style={{ overflowY: 'auto', maxHeight: 350 }}>
              {filteredChats?.map((chat, index) => (
                <div key={index} onClick={() => handleOpenChat(chat?.id)} className={css.chat_item}>
                  <div style={{ position: 'relative' }}>
                    <img src={chat?.profile_pic} alt="profile_pic" />
                    {chat?.not_readed_messages > 0 && !openedChats.includes(chat?.id) && (
                      <span className={css.unreaded_msgs}>{chat?.not_readed_messages}</span>
                    )}
                  </div>
                  <div style={{ width: '100%' }}>
                    <span>{chat?.user_name}</span>
                    <span style={{ display: 'block', fontSize: 12 }}>
                      {chat?.title.length > 25 ? `${chat?.title.substring(0, 25)}...` : chat?.title}
                    </span>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        fontSize: 10,
                        color: 'gray',
                      }}>
                      <span>
                        {isOwnerOfLastMessage(chat) ? 'Tú : ' : 'Él : '}
                        {getLastMessage(chat)?.length > 13
                          ? `${getLastMessage(chat)?.substring(0, 13)}...`
                          : getLastMessage(chat)}
                      </span>
                      <span>
                        {getTimeAgo(new Date(), new Date(chat?.last_message?.created_at))}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {!isLeftSidehidden && (
            <div className={css.chat_items_search}>
              <input
                type="text"
                placeholder="Buscar..."
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
              />
              <Search style={{ color: '#3383ff' }} />
            </div>
          )}
        </div>
      </BaseLayout.LeftSide>

      <BaseLayout.Content>
        <div className={css.header}></div>

        <div
          style={{
            borderRadius: '0px 0px 10px 10px',
            height: '420px',
            backgroundColor: 'white',
          }}>
          <div className={css.opened_chat_container}>
            {openedChat && <OpenedChat chat={chats?.find((chat) => chat?.id === openedChat)} />}
          </div>
        </div>
      </BaseLayout.Content>
    </BaseLayout>
  );
};

export default ChatsPage;
