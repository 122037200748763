export const INVITES_MODAL_STEP = {
  PHONE: 'PHONE',
  SUGGESTIONS: 'SUGGESTIONS',
};
export const INVITES_MODAL_MESSAGES = {
  PHONE_SUCCESS: 'Tu número de teléfono ha sido guardado correctamente.',
  PHONE_ERROR:
    'Ha ocurrido un error al guardar tu número de teléfono, por favor intenta nuevamente.',
};

export const PROPOSAL_MESSAGES = {
  CONFIRM_SUCCESS:
    'La propuesta fué aceptada exitosamente. Sólo falta que pagues para dar inicio al proyecto.',
  CONFIRM_ERROR: 'Hubo problemas al aceptar la propuesta. Intente nuevamente más tarde.',
  REJECT_SUCCESS: 'La propuesta fué rechazada exitosamente.',
  REJECT_ERROR: 'Hubo problemas al rechazar la propuesta. Intente nuevamente más tarde.',
};

export const DELETE_PROJECT_MESSAGES = {
  SUCCESS: 'El proyecto fué eliminado exitosamente.',
  ERROR: 'Hubo problemas al eliminar el proyecto. Intente nuevamente más tarde.',
};
