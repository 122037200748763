import React from 'react';
import { connect } from 'react-redux';
import { getUserChats, selectChat } from '@actions/chat';
import { Contact } from './index';
import './liveChat.scss';
import { Button, Title } from '@components/common';
import { HOME_ROUTE } from '@common/constants/routes.constants';

class SidePanel extends React.Component {
  constructor() {
    super();
    this.state = {
      chats: [],
    };
    //this.dispatchChat = debounce(this.dispatchChat, 3000);
  }

  async componentDidMount() {
    if (this.props.user) {
      const query = await this.props.getUserChats(this.props.user.id);
      if (query.status === 200) {
        this.setState({ chats: query.data });
        this.props.handleChats(this.state.chats);
        if (query.data.length > 0 && this.props.selectedChat === null) {
          this.props.selectChat(query.data[0]);
        }
      } else {
        console.log('error obteniendo chats');
      }
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      const query = await this.props.getUserChats(this.props.user.id);
      if (query.status === 200) {
        this.setState({ chats: query.data });
        this.props.handleChats(this.state.chats);
        if (query.data.length > 0 && this.props.selectedChat === null) {
          this.props.selectChat(query.data[0]);
        }
      } else {
        console.log('error obteniendo chats');
      }
    }
  }

  dispatchChat = (chat) => {
    this.props.selectChat(chat);
  };

  handleGoToHome = () => {
    this.props.history.push(HOME_ROUTE);
  };

  render() {
    const activeChats = this.state.chats.map((chat) => {
      return (
        <Contact
          key={chat.id}
          chat={chat}
          name={`${chat.title}`}
          user_name={chat.user_name}
          picURL={chat.profile_pic}
          chatURL={`${chat.id}`}
          dispatchChat={this.dispatchChat}
          currentChat={this.props.selectedChat}
        />
      );
    });
    return (
      <div className="sidepanel">
        <Title color="main" style={{ marginBottom: '30px' }}>
          Mensajes
        </Title>
        <Button
          text="Volver al Home"
          variant="secondary"
          color="primary"
          onClick={this.handleGoToHome}
        />
        <div className="tn-chat-contacts">
          <ul className="contacts-list">{activeChats}</ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.isLoading,
  token: state.auth.token,
  user: state.auth.user,
  selectedChat: state.chat.selectedChat,
});

export default connect(mapStateToProps, { getUserChats, selectChat })(SidePanel);
