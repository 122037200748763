import React from 'react';
import './LoadingDots.scss';

const LoadingDots = ({ files, isSecondary, height = 300 }) => {
  return (
    <div className={files ? 'background__files' : 'background-loader'} style={{ height }}>
      <div className={isSecondary ? 'loaderSecondary' : 'loader'}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};
export default LoadingDots;
