export function MoneyFormatter(value) {
  if (isNaN(value)) {
    return '$ 0';
  } else {
    return `$ ${Math.round(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }
}

export function comisionOneDecimal(value) {
  if (isNaN(value)) {
    return 0;
  } else {
    return Math.round(value * 1000) / 10;
  }
}

export function Formatter(value) {
  `$ ${value}`.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
export function dateFixer(date) {
  if (date.toString().length === 1) {
    return '0' + date.toString();
  } else {
    return date;
  }
}
export function numberWithCommas(x) {
  if (typeof x === 'string') {
    x = x.replace('.', '');
  }
  return Number(x)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function addThousandDots(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export function removeNonNumeric(num) {
  if (num) {
    return num.toString().replace(/\D/g, '');
  }
  return '';
}
export function clearRutInput(input) {
  if (input) {
    if (input[input.length - 1].toLowerCase() === 'k') return input;
    return input.toString().replace(/\D/g, '');
  }
  return '';
}
export function rutFormater(rut) {
  var actual = rut.replace(/^0+/, '');
  if (actual != '' && actual.length > 1) {
    var sinPuntos = actual.replace(/\./g, '');
    var actualLimpio = sinPuntos.replace(/-/g, '');
    var inicio = actualLimpio.substring(0, actualLimpio.length - 1);
    var rutPuntos = '';
    var i = 0;
    var j = 1;
    for (i = inicio.length - 1; i >= 0; i--) {
      var letra = inicio.charAt(i);
      rutPuntos = letra + rutPuntos;
      if (j % 3 == 0 && j <= inicio.length - 1) {
        rutPuntos = '.' + rutPuntos;
      }
      j++;
    }
    var dv = actualLimpio.substring(actualLimpio.length - 1);
    rutPuntos = rutPuntos + '-' + dv;
    return rutPuntos;
  }
  return actual;
}
export function projectStatusFormatter(status) {
  if (status === 'in_progress') {
    return 'En Progreso';
  } else if (status === 'completed') {
    return 'Finalizado';
  } else if (status === 'agreement') {
    return 'Negociando finalización del Proyecto';
  } else if (status === 'payment') {
    return 'Esperando Pago';
  } else if (status === 'mediation') {
    return 'En Mediación';
  } else if (status === 'uncompleted') {
    return 'Finalizado tempranamente';
  }
}
export function capitalizeFirst(text) {
  if (!text) {
    return;
  }
  const lowerCasedText = text?.toLowerCase();
  return lowerCasedText.charAt(0).toUpperCase() + lowerCasedText.slice(1);
}

export function getTimeAgo(date1, date2) {
  // Get the difference between the two dates in milliseconds
  const diffInMilliseconds = Math.abs(date1 - date2);

  // Calculate the number of seconds in the difference
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);

  // Calculate the number of minutes in the difference
  const diffInMinutes = Math.floor(diffInSeconds / 60);

  // Calculate the number of hours in the difference
  const diffInHours = Math.floor(diffInMinutes / 60);

  // Calculate the number of days in the difference
  const diffInDays = Math.floor(diffInHours / 24);

  // Calculate the number of months in the difference
  const diffInMonths = Math.floor(diffInDays / 30);

  // Calculate the number of years in the difference
  const diffInYears = Math.floor(diffInMonths / 12);

  // Return the appropriate time ago string
  if (diffInYears > 0) {
    return `Hace ${diffInYears} ${diffInYears > 1 ? 'años' : 'año'}`;
  } else if (diffInMonths > 0) {
    return `Hace ${diffInMonths} ${diffInMonths > 1 ? 'meses' : 'mes'} `;
  } else if (diffInDays > 0) {
    return `Hace ${diffInDays} ${diffInDays > 1 ? 'días' : 'día'}`;
  } else if (diffInHours > 0) {
    return `Hace ${diffInHours} ${diffInHours > 1 ? 'horas' : 'hora'}`;
  } else if (diffInMinutes > 0) {
    return `Hace ${diffInMinutes} ${diffInMinutes > 1 ? 'minutos' : 'minuto'}`;
  } else if (diffInSeconds > 0) {
    return `Hace ${diffInSeconds} ${diffInSeconds > 1 ? 'segundos' : 'segundo'}`;
  } else {
    return 'Ahora mismo';
  }
}

export function parseToUrlFormat(str) {
  if (!str) return null;
  return str
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/\?/g, '')
    .replace(/¿/g, '')
    .replace(/!/g, '')
    .replace(/¡/g, '')
    .replace(/:/g, '');
}
export const addHttps = (link) => {
  if (!link) return null;
  if (link.includes('http')) {
    return link;
  } else {
    return `https://${link}`;
  }
};

export const subStr = (str, length) => {
  if (!str) return null;
  if (str.length > length) {
    return str.substring(0, length) + '...';
  }
  return str;
};
