import React, { Fragment } from 'react';

import { BaseLayout, FormVerifyIdentity, PopUpWindow } from '@components/common';
import { NewProjectFormViewModel } from './NewProjectFormViewModel';
import { TimeLine } from './TimeLine';

import { CommonBeginningQuestions } from './CommonBeginnigQuestions';
import { VariableMiddleQuestions } from './VariableMiddleQuestions';
import { CommonEndingQuestions } from './CommonEndingQuestions';

import { Advices } from './Advices';

const NewProjectForm = () => {
  const {
    authUser,
    values,
    branchStatus,
    mainCategories,
    isLoadingMainCategories,
    subCategories,
    isLoadingSubCategories,
    handleSelectActualState,
    handleSelectCategory,
    handleSelectSubCategory,
    handleSelectWebsiteType,
    handleSelectWebsiteFunctionalities,
    handleSelectCompatibility,
    handleSelectTimeRange,
    handleSelectBudgetRange,
    handleSelectActualDBStatus,
    handleSelectModelGoal,
    handleSelectAPIIntegration,
    handleOnChangeDescription,
    currentFocus,
    handleCurrentFocus,
    questionOrder,
    fetchSkills,
    skillsOptions,
    fetchingSkills,
    handleSelectSkills,
    handleOnChangeTitle,
    handleChangeImages,
    displayedQuestions,
    displayDistinctQuestion,
    handleGoBackQuestion,
    handleGoForwardQuestion,
    handleSelectReferenceUrls,
    type,
    id,
  } = NewProjectFormViewModel();

  const [showVerifyIdentity, setShowVerifyIdentity] = React.useState(false);
  const doneVerification = () => {
    setShowVerifyIdentity(false);
  };

  return (
    <BaseLayout>
      <PopUpWindow open={showVerifyIdentity} onClose={() => setShowVerifyIdentity(false)}>
        <FormVerifyIdentity
          doneVerification={doneVerification}
          userData={authUser.user}
          mustVerify
        />
      </PopUpWindow>
      <BaseLayout.LeftSide>
        <TimeLine
          values={values}
          handleCurrentFocus={handleCurrentFocus}
          questionOrder={questionOrder}
          displayedQuestions={displayedQuestions}
        />
      </BaseLayout.LeftSide>
      <BaseLayout.Content>
        {currentFocus && (
          <Fragment>
            <CommonBeginningQuestions
              values={values}
              handleSelectActualState={handleSelectActualState}
              handleSelectCategory={handleSelectCategory}
              mainCategories={mainCategories}
              isLoadingMainCategories={isLoadingMainCategories}
              handleSelectSubCategory={handleSelectSubCategory}
              subCategories={subCategories}
              isLoadingSubCategories={isLoadingSubCategories}
              currentFocus={currentFocus}
              branchStatus={branchStatus}
              displayedQuestions={displayedQuestions}
              handleGoBackQuestion={handleGoBackQuestion}
              handleGoForwardQuestion={handleGoForwardQuestion}
              displayDistinctQuestion={displayDistinctQuestion}
            />
            <VariableMiddleQuestions
              values={values}
              handleSelectWebsiteFunctionalities={handleSelectWebsiteFunctionalities}
              handleSelectCompatibility={handleSelectCompatibility}
              handleSelectActualDBStatus={handleSelectActualDBStatus}
              handleSelectModelGoal={handleSelectModelGoal}
              handleSelectWebsiteType={handleSelectWebsiteType}
              questionOrder={questionOrder}
              branchStatus={branchStatus}
              currentFocus={currentFocus}
              displayedQuestions={displayedQuestions}
              handleGoBackQuestion={handleGoBackQuestion}
              handleGoForwardQuestion={handleGoForwardQuestion}
              handleSelectReferenceUrls={handleSelectReferenceUrls}
              displayDistinctQuestion={displayDistinctQuestion}
            />
            <CommonEndingQuestions
              values={values}
              handleSelectAPIIntegration={handleSelectAPIIntegration}
              handleSelectTimeRange={handleSelectTimeRange}
              handleSelectBudgetRange={handleSelectBudgetRange}
              handleOnChangeDescription={handleOnChangeDescription}
              branchStatus={branchStatus}
              currentFocus={currentFocus}
              fetchSkills={fetchSkills}
              skillsOptions={skillsOptions}
              fetchingSkills={fetchingSkills}
              handleSelectSkills={handleSelectSkills}
              handleOnChangeTitle={handleOnChangeTitle}
              handleChangeImages={handleChangeImages}
              displayDistinctQuestion={displayDistinctQuestion}
              questionOrder={questionOrder}
              displayedQuestions={displayedQuestions}
              handleGoBackQuestion={handleGoBackQuestion}
              handleGoForwardQuestion={handleGoForwardQuestion}
              type={type}
              id={id}
              setShowVerifyIdentity={setShowVerifyIdentity}
              authUser={authUser}
            />
          </Fragment>
        )}
      </BaseLayout.Content>
      <BaseLayout.RightSide>
        <Advices currentFocus={currentFocus} branchStatus={branchStatus} />
      </BaseLayout.RightSide>
    </BaseLayout>
  );
};

export default NewProjectForm;
