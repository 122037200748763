import { TextareaAutosize } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import css from '../Forms.module.scss';
const TextFieldType = ({ handleSetAnswer, answer, maxLength = 100 }) => {
  const [value, setValue] = useState('');

  const handleChange = (e) => {
    setValue(e.target.value);
    handleSetAnswer(e.target.value, 'textfield');
  };

  useEffect(() => {
    if (answer) {
      setValue(answer?.answers[0]);
    }
  }, [answer]);

  return (
    <>
      <TextareaAutosize
        placeholder="Escribe tu respuesta aquí..."
        value={value}
        onChange={handleChange}
        className={css.textfield}
        minLength={5}
        maxLength={Number(maxLength)}
      />
      <div style={{ textAlign: 'right', width: '100%', maxWidth: 480, color: 'gray' }}>
        <span>
          {value?.length}/{maxLength}
        </span>
      </div>
    </>
  );
};

export default TextFieldType;
