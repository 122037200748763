import React from 'react';
import { CONTACT_PHONE } from '@common/constants/main.constants';
import { CMS_FORM } from '@common/constants/routes.constants';
import './WhatsappButton.scss';
import { useLocation } from 'react-router-dom';

const WhatsappButton = () => {
  const location = useLocation();
  const { pathname } = location;
  const HIDDEN_ROUTES = [CMS_FORM];

  return (
    <>
      {!HIDDEN_ROUTES.includes(pathname) && (
        <div className="whatsapp__button-container">
          <a
            href={`https://wa.me/${CONTACT_PHONE}`}
            className="whatsapp__icon"
            target="_blank"
            rel="noreferrer">
            <div className="whatsapp__content">
              <span className="whatsapp__right-content">
                <i className="fab fa-whatsapp"></i>
              </span>
            </div>
          </a>
        </div>
      )}
    </>
  );
};

export default WhatsappButton;
