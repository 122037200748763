import React from 'react';
import css from './Tecnology.module.scss';
import { Text } from '@components/common';
import { TechIlustrationsDictionary, ilustrationsFolder } from '../CMSDetails.constants';

function Tecnology({ currentProject }) {
  return (
    <div className={css.container}>
      <div className={css.cms}>
        {' '}
        <Text type="detail">CMS de preferencia: </Text>
        <div className={css.content}>
          {currentProject.all_categories ? (
            <div className={css.all_categories}>
              <Text type="detail">
                Me gustaría que los freelancers me hagan una recomendación en su propuesta
              </Text>
            </div>
          ) : (
            currentProject.project_subcategories_names.map((cat, index) => {
              return (
                <div className={css.item} key={`cat-${index}`}>
                  <img src={ilustrationsFolder + TechIlustrationsDictionary[cat]} />
                  {cat == 'Otros' && <Text type="detail">Otro</Text>}
                </div>
              );
            })
          )}
        </div>
      </div>
      <div className={css.hosting}>
        {' '}
        <Text type="detail">Hosting y dominio: </Text>
        <div>
          {currentProject.advisories.map((adv, index) => {
            return (
              <Text key={`advt-${index}`} type="detail">
                {`• ${adv}`}
              </Text>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Tecnology;
