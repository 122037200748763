import React, { Fragment } from 'react';
import './GeneralInformation.scss';
import ProfilePicture from '../../../../components/common/ProfilePicture/ProfilePicture';
import { Button, Text, Title } from '@components/common';
import { Form, Input, Select } from 'antd';
import { VERIFIED_ACCOUNT_ICON_URL } from '@common/constants/assets.constants';
import { COUNTRY_PREFIX_LISTS } from 'common/constants/registercountries.constants';

function GeneralInformation({
  user,
  setProfileImageModal,
  editGenaralInformation,
  setEditGenaralInformation,
  onSubmitGeneralInformation,
  setVerifyIdentityModal,
  setUserDrawer,
  withHeader = true,
}) {
  return (
    <div className="general-information__container">
      {withHeader && (
        <>
          <div className="general-information__preview">
            <Button
              text="Vista previa"
              variant="secondary"
              borderColor="primary"
              size="sm"
              onClick={() => setUserDrawer(true)}
            />
          </div>
          <div className="general-information__header"></div>
        </>
      )}

      <div className="general-information__content">
        {' '}
        <div className="general-information__edit-picture">
          <ProfilePicture user={user} setProfileImageModal={setProfileImageModal} />
        </div>
        <div className="general-information__info-form">
          <Form
            name="GeneralInformationForm"
            onFinish={onSubmitGeneralInformation}
            initialValues={{
              first_name: user.first_name,
              last_name: user.last_name,
              profession: user.profession,
              phone_number: user.phone_number,
              phone_prefix: user.phone_prefix,
            }}>
            <div className="general-information__button">
              {' '}
              {editGenaralInformation ? (
                <Button
                  text="Guardar"
                  type="submit"
                  variant="secondary"
                  borderColor="primary"
                  size="sm"
                />
              ) : (
                <Fragment>
                  <Button
                    text={<i className="fas fa-pencil-alt"></i>}
                    variant="edit"
                    type="button"
                    onClick={() => setEditGenaralInformation(true)}>
                    {' '}
                  </Button>
                </Fragment>
              )}
            </div>
            <div className="general-information__row">
              <Title type="tertiary" color="primary">
                Nombre:{' '}
              </Title>
              {editGenaralInformation ? (
                <Form.Item name="first_name" className="input-row">
                  <Input maxLength={20} />
                </Form.Item>
              ) : (
                <Text>{user.first_name}</Text>
              )}
            </div>
            <div className="general-information__row">
              <Title type="tertiary" color="primary">
                Apellidos:{' '}
              </Title>
              {editGenaralInformation ? (
                <Form.Item name="last_name" className="input-row">
                  <Input maxLength={20} />
                </Form.Item>
              ) : (
                <Text>{user.last_name}</Text>
              )}
            </div>
            <div className="general-information__row">
              <Title type="tertiary" color="primary">
                Profesión:{' '}
              </Title>
              {editGenaralInformation ? (
                <Form.Item name="profession" className="input-row">
                  <Input maxLength={50} />
                </Form.Item>
              ) : (
                <Text>{user.profession ? user.profession : 'No especificada'}</Text>
              )}
            </div>{' '}
            <div className="general-information__row">
              <Title type="tertiary" color="primary">
                Teléfono:{' '}
              </Title>
              {editGenaralInformation ? (
                <div className="input-row" style={{ display: 'flex', gap: 8 }}>
                  <Form.Item name="phone_prefix" className="prefixSection">
                    <Select placeholder="Prefijo">
                      {COUNTRY_PREFIX_LISTS.map((country, key) => (
                        <Select.Option value={country.prefix} key={`opt-${key}`}>
                          {country.prefix}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name="phone_number" className="numberSection">
                    <Input placeholder="Número de teléfono" type="number" />
                  </Form.Item>
                </div>
              ) : (
                <Text>
                  {user.phone_prefix && user.phone_prefix}{' '}
                  {user.phone_number ? user.phone_number : 'No especificado'}
                </Text>
              )}
            </div>
            <div className="general-information__row">
              <Title type="tertiary" color="primary">
                Verificado:
              </Title>
              {user.is_identity_verified ? (
                <div className="general-information__verify-identity">
                  <img src={VERIFIED_ACCOUNT_ICON_URL} alt="profile" />
                </div>
              ) : (
                <div className="general-information__verify-identity">
                  <Button
                    text="Verificar Identidad"
                    variant="secondary"
                    borderColor="error"
                    size="sm"
                    onClick={() => setVerifyIdentityModal(true)}
                  />
                </div>
              )}
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default GeneralInformation;
