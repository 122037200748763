import React from 'react';
import './ViewProjectDrawer.scss';
import { CustomDrawer } from '@components/common';
import ViewProject from './ViewProject';

export function ViewProjectDrawer({ currentProject, projectDrawer, setProjectDrawer }) {
  // Initialize state with undefined width/height so server and client renders match
  return (
    <CustomDrawer
      type="project"
      open={projectDrawer}
      onClose={() => setProjectDrawer(false)}
      visible={projectDrawer}>
      <ViewProject currentProject={currentProject} />
    </CustomDrawer>
  );
}
