/* eslint-disable no-unused-vars */
import { Input } from 'antd';
import { Text } from 'components/common';
import React from 'react';

const myInfo = [
  {
    name: 'about_me',
    label: 'Acerca de mi',
    placeholder: `Soy un desarrollador de software desde 20XX, comencé con... 
Mi experiencia laboral fue orientada a desarrollo de...
Busco proyectos que...`,
  },
  {
    name: 'experience',
    label: 'Experiencia',
    placeholder: `- 2022 Desarrollo de proyectos cumpliendo rol de Data Scientist
- 2021 Trabajo en plataforma en Frontend (ReactJS)
- ...`,
  },
];

const AboutMeProposal = ({ user, useEditUser, hasDescriptions }) => {
  const [isEditing, setIsEditing] = React.useState('');
  const [userForm, setUserForm] = React.useState({
    about_me: user?.about_me || '',
    experience: user?.experience || '',
  });
  const handleChange = (e) => {
    if (e.target.value.length > 1500) return;
    setUserForm({ ...userForm, [e.target.name]: e.target.value });
  };
  return (
    <div style={{ margin: '4em 0' }}>
      {!hasDescriptions && <Text style={{ color: 'red' }}>Debes rellenar tu información</Text>}
      {myInfo.map((info, idx) => (
        <div key={idx}>
          <Text style={{ marginBottom: '1em', color: isEditing === info.name ? '#3383ff' : '' }}>
            {info.label}
          </Text>
          <AboutMeInput
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            value={userForm[info.name]}
            handleChange={handleChange}
            name={info.name}
            useEditUser={useEditUser}
            placeholder={info.placeholder}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      ))}
    </div>
  );
};
const AboutMeInput = ({
  isEditing,
  setIsEditing,
  value,
  handleChange,
  name,
  useEditUser,
  placeholder,
}) => {
  const onBlur = () => {
    useEditUser({ [name]: value });
    setIsEditing('');
  };
  return (
    <div style={{ marginBottom: '1em' }}>
      <Input.TextArea
        style={{
          width: '100%',
          borderRadius: '1em',
          margin: '0.5em 0',
          backgroundColor: isEditing === name ? '' : '#cccc',
        }}
        rows={4}
        onFocus={() => setIsEditing(name)}
        onBlur={onBlur}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
      />
      <Text style={{ textAlign: 'right' }}>{value?.length}/1500</Text>
    </div>
  );
};
export default AboutMeProposal;
