import React from 'react';
import { GraphicContainer, Title } from '@components/common';
import { Carousel } from 'antd';
import {
  PC_LUN,
  PC_IMAGE,
  PC_IMAGE1,
  PC_IMAGE2,
  PC_IMAGE3,
  LANDING_NEWS_PC,
  PC_IMAGE4,
} from 'pages/Landing/landing.constants';
import './Section7.scss';
import AnimateOnView from 'components/AnimateOnView';

const Section7 = () => {
  return (
    <div className="section7__container">
      <div className="section7__content">
        <AnimateOnView className="content__left">
          <Title type="xl" color="white">
            Freelancer,
          </Title>
          <Title type="primary" color="white">
            la modalidad de trabajo del futuro
          </Title>
        </AnimateOnView>
        <AnimateOnView
          className="content__right"
          style={{
            backgroundImage: `url(${LANDING_NEWS_PC})`,
          }}>
          <Carousel autoplay>
            <GraphicContainer
              className="news_image1"
              src={PC_LUN}
              link={
                'https://www.lun.com/Pages/NewsDetail.aspx?dt=2022-10-24&NewsID=499564&BodyID=0&PaginaId=27'
              }
              newTab={'_blank'}
            />
            <GraphicContainer className="news_image1" src={PC_IMAGE} maxHeight="550px" />
            <GraphicContainer className="news_image1" src={PC_IMAGE1} maxHeight="550px" />
            <GraphicContainer className="news_image1" src={PC_IMAGE2} maxHeight="550px" />
            <GraphicContainer className="news_image1" src={PC_IMAGE3} maxHeight="550px" />
            <GraphicContainer
              className="news_image1"
              src={PC_IMAGE4}
              maxHeight="550px"
              link="https://www.linkedin.com/feed/update/urn:li:activity:7057327487055212546/"
            />
          </Carousel>
        </AnimateOnView>
      </div>
    </div>
  );
};

export default Section7;
