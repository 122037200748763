import axiosClient from '@config/axios';
import { tokenConfig } from './auth';

// GET KANBAN
export const getKanban = (projectid) => async (dispatch, getState) => {
  const result = await axiosClient
    .get(`/kanban/?project=${projectid}`, tokenConfig(getState))
    .then((res) => {
      return res.data;
    })
    .catch(() => {
      return null;
    });
  return result;
};
export const modifyBoard = (changes) => async (dispatch, getState) => {
  const result = await axiosClient
    .post(`/changeBoard`, changes, tokenConfig(getState))
    .then((res) => {
      return res.data.cards;
    })
    .catch((err) => {
      return err;
    });
  return result;
};

export const debouncedmodifyBoard =
  (changes, fetchId, lastFetchId) => async (dispatch, getState) => {
    const result = await axiosClient
      .post(`/changeBoard`, changes, tokenConfig(getState))
      .then((res) => {
        if (fetchId !== lastFetchId) {
          // for fetch callback order
          return 'debounced';
        }
        return res.data.cards;
      })
      .catch((err) => {
        return err.response;
      });
    return result;
  };

export const getKanban_v2 = ({ projectId }) => axiosClient.get(`/kanban/?project=${projectId}`);
