import React from 'react';
import { ManagmentViewModel } from 'pages/Managment/ManagmentViewModel';
import {
  COMPLETED_PROJECTS_LABEL,
  INPROGRESS_PROJECTS_LABEL,
  ALL_PROJECTS,
  FEEDBACK_PROJECT_LABEL,
} from '@common/constants/project.constants';
import projectService from '@services/projectService';
import { MEDIATION_PROJECTS_LABEL } from 'common/constants/project.constants';

export const ClientProjectsListViewModel = () => {
  const { authUser } = ManagmentViewModel();
  const [currentFilter, setCurrentFilter] = React.useState(ALL_PROJECTS);

  const [isLoadingHiringProjectsList, setIsLoadingHiringProjectsList] = React.useState(false);
  const [hiringProjectsList, setHiringProjectsList] = React.useState([]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalProjects, setTotalProjects] = React.useState(0);

  React.useEffect(() => {
    setIsLoadingHiringProjectsList(true);
    if (authUser) {
      projectService
        .getUserProjects(authUser.user.id, currentFilter, 'owner', currentPage)
        .then((res) => {
          setHiringProjectsList(res.data.objects);
          setTotalProjects(res.data.total_objects);
          setIsLoadingHiringProjectsList(false);
        })
        .catch(() => {
          setHiringProjectsList([]);
          setTotalProjects(0);
          setIsLoadingHiringProjectsList(false);
        });
    }
  }, [currentFilter, currentPage]);

  const project_types = [
    { id: ALL_PROJECTS, name: 'Todas', tag: 1 },
    { id: INPROGRESS_PROJECTS_LABEL, name: 'En progreso', tag: 2 },
    { id: FEEDBACK_PROJECT_LABEL, name: 'Feedback', tag: 3 },
    { id: COMPLETED_PROJECTS_LABEL, name: 'Completados', tag: 4 },
    { id: MEDIATION_PROJECTS_LABEL, name: 'En mediación', tag: 5 },
  ];

  const handleOnClickCategory = async (categoryId) => {
    setCurrentFilter(categoryId);
  };

  const handlePagination = (event) => {
    setCurrentPage(event);
  };

  return {
    isLoadingHiringProjectsList: isLoadingHiringProjectsList,
    hiringProjectsList,
    project_types,
    handleOnClickCategory,
    currentFilter,
    handlePagination,
    totalProjects,
    currentPage,
  };
};
