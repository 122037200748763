import React, { Fragment } from 'react';
import { Collapse } from 'antd';
const { Panel } = Collapse;

const FaqService = () => {
  return (
    <Fragment>
      <Collapse>
        <Panel header="¿Cómo creo o edito mi perfil?" key="1">
          <p>
            Es importante que tengas tu perfil lo más completo posible, así las personas podrán ver
            quién está solicitando el trabajo y entregarles a ellos una mayor confianza a la hora de
            trabajar.
          </p>
          <p>
            En primer lugar, para crear tu perfil, debes tener una cuenta registrada. Una vez que
            hayas registrado tu cuenta (Nombre, Correo, RUT, entre otros), completando esos datos,
            podrás realizar cualquier edición en tu perfil (Acerca de mí, Fotografía, Habilidades,
            etc.). Para esto, debes ir al ícono <strong>Mi Perfil</strong> (dando click en el ícono
            de tu tótem, en la parte superior derecha).
          </p>
          <p>
            <strong>
              Te sugerimos realizar la verificación de identidad, ya que esto es uno de los aspectos
              más relevantes que verán los usuarios cuando visiten tu perfil.
            </strong>
          </p>
          <p>
            Una vez que estés dentro de la pestaña <strong>Mi perfil</strong>, podrás editar los
            campos dando click en el símbolo <i className="fas fa-pencil-alt"></i> que se encuentra
            en la parte superior derecha de todos los campos.
          </p>
        </Panel>
        <Panel header="¿Cómo ofrecer un proyecto?" key="2">
          <p>Como cliente (creador de un proyecto), tienes 2 opciones de comenzar uno:</p>
          <p>
            <ul>
              <li>
                La primera es que realices una publicación que vaya dirigida a todo el público, en
                la cual pongas tu requerimiento, cuanto estás dispuesto a pagar y para cuando lo
                necesitas (ejemplo: necesito un desarrollador web que me haga un sitio para mi
                negocio, estoy dispuesto a pagar $100.000 por todo el trabajo y lo necesito para 15
                días más).
              </li>
              <li>
                La segunda opción, es que crees un proyecto para un colaborador en específico. Con
                esto, tu proyecto no será público, sino que será una oferta de trabajo que le
                llegará solamente a ese colaborador (Freelancer).
              </li>
            </ul>
          </p>
        </Panel>
        <Panel header="¿Qué tipo de proyectos puedo publicar?" key="3">
          <p>
            En <strong className="te-necesito"> TE NECESITO</strong>, tenemos diferentes categorías
            de proyectos (Página web estándar (CMS), Aplicación web a medida, Aplicación móvil,
            Datos y modelos, Otros en programación y tecnologías). Estas categorías están definidas
            para guiar a los usuarios en el contenido que pueden publicar, sin embargo, en
            <strong className="te-necesito"> TE NECESITO</strong> se puede solicitar cualquier
            requerimiento que tenga un cliente, siempre y cuando este cumpla con los Términos y
            Condiciones de nuestro sitio web (principalmente que el requerimiento no sea nada
            ilegal).
          </p>
          <p>
            Además del tipo de proyecto, o el ámbito laboral de la solicitud, en
            <strong className="te-necesito"> TE NECESITO</strong> puedes definir tus proyectos en
            base a las habilidades que estás buscando, el tipo de trabajo y el precio que estás
            dispuesto a pagar.
          </p>
        </Panel>
        <Panel header="¿Cómo se crea un proyecto?" key="4">
          <p>
            En todo momento, podrás encontrar en la esquina superior, a un lado de tu perfil, un
            botón de “Publicar”. Ahí encontrarás la opción para publicar tu proyecto y comenzar a
            recibir propuestas de los freelancers.
          </p>
          <p>
            Recuerda que en todo momento puedes contactarte con el equipo de TeNecesito para guiarte
            en tu postulación de forma gratuita. Mientras más detallada sea tu postulación, más
            posibilidades tienes de recibir más y mejores propuestas.
          </p>
        </Panel>
        <Panel header="¿Cómo elijo entre mis postulantes?" key="5">
          <p>A la hora de elegir un postulante, tienes dos etapas muy importantes:</p>
          <p>
            <ul>
              <li>
                En primer lugar, cuando recibas una postulación a un proyecto, te sugerimos revisar
                el perfil del postulante, en este, podrás ver en cuales otros trabajos ha
                participado, cuanta experiencia tiene trabajando como Freelancer en
                <strong className="te-necesito"> TE NECESITO</strong>, su puntuación por proyectos
                pasados y su portafolio.
              </li>
              <li>
                Luego, si te tinca alguno de tus postulantes, tendrás la oportunidad de negociar con
                ella/él a través de nuestra plataforma. En la misma publicación que hayas realizado,
                puedes ver a todos los postulantes y chatear con ellos, donde puedes establecer el
                precio, alcance, etapas del proyecto (cuantas entregas habrán), entre otras cosas.
              </li>
            </ul>
          </p>
          <p>
            Una vez que llegues a un acuerdo con el/la postulante, podrás aceptar su propuesta, y
            luego de aceptado el pago, ¡ella/él comenzará a trabajar en tu proyecto!
          </p>
          <p>
            Estas dos instancias son clave para que puedas elegir al postulante que más se acomode a
            tus necesidades y a tu presupuesto. Y en caso de que no te motive ninguno, puedes
            rechazar cada postulación.
          </p>
        </Panel>
      </Collapse>
    </Fragment>
  );
};

export default FaqService;
