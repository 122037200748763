/* eslint-disable no-unused-vars */
import useSingleChat from 'components/common/ChatWidget/hooks/useSingleChat';
import React from 'react';
import css from './Chats.module.scss';
import RenderIf from 'components/common/RenderIf';
import { FileContainer, FileIcon } from 'components/common';
import UploadClip from 'components/common/NewChat/UploadClip';
import { Videocam } from '@material-ui/icons';
import VideoCallModal from 'components/common/NewChat/VideoCallModal';
import { selectAuthUserInfo } from 'reducers/auth';
import { useSelector } from 'react-redux';
import { CircularProgress, useMediaQuery } from '@material-ui/core';
import { subStr } from 'common/utils/Formaters';

const OpenedChat = ({ chat }) => {
  const user = useSelector(selectAuthUserInfo);

  const {
    messageHistory,
    onSend,
    isOwnerOfMessage,
    stringMessageToArray,
    onUpload,
    videoModal,
    setVideoModal,
    handleSubmitVideoCall,
    onChatScroll,
    message,
    setMessage,
    files,
    isLoadingFiles,
    handleRedirectToProject,
    isLoading,
  } = useSingleChat({ chat, containerId: `chats-${chat?.id}` });

  const isSmall = useMediaQuery('(max-width: 480px)');

  return (
    <>
      <div className={css.opened_chat_title}>
        <img src={chat?.profile_pic} alt="profile" />
        <div>
          <span style={{ fontSize: 18 }}>{subStr(chat?.user_name, 20)}</span>
          <span
            style={{ display: 'block', fontSize: 12 }}
            className={css.underline_hover}
            onClick={handleRedirectToProject}>
            {subStr(chat?.title, 35)}
          </span>
        </div>
      </div>
      <VideoCallModal
        toggle={videoModal}
        setToggle={setVideoModal}
        handleSubmitVideoCall={handleSubmitVideoCall}
      />

      <div className={css.chat_container}>
        <div style={{ width: '100%', borderRight: isSmall ? '0px' : '1px solid #cecece' }}>
          <div
            style={{ height: 300 }}
            className={css.chat_content}
            id={`chats-${chat?.id}`}
            onScroll={onChatScroll}>
            <div>
              {isLoading ? (
                <div style={{ textAlign: 'center' }}>
                  <CircularProgress style={{ color: '#3383ff' }} />
                </div>
              ) : (
                <>
                  {messageHistory?.map((msg, idx) => (
                    <div
                      key={idx}
                      className={
                        isOwnerOfMessage(msg)
                          ? css.message_sent_container
                          : css.message_received_container
                      }>
                      <RenderIf isTrue={msg?.file_id}>
                        <FileIcon fileData={msg?.attached_file} fromChat />
                      </RenderIf>
                      <RenderIf isTrue={!msg?.file_id}>
                        {stringMessageToArray(msg?.content).map((message, idx) => (
                          <span
                            className={
                              isOwnerOfMessage(msg) ? css.message_sent : css.message_received
                            }
                            key={idx}>
                            {message}
                          </span>
                        ))}
                      </RenderIf>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          <div className={css.chat_search}>
            <input
              type="text"
              placeholder="Escribe un mensaje..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && e.shiftKey) {
                  setMessage((prev) => `${prev}\n`);
                }
                if (e.key === 'Enter') {
                  onSend(message);
                  setMessage('');
                }
              }}
            />
            <div className={css.chat_search_items}>
              <UploadClip
                style={{ height: 20, cursor: 'pointer' }}
                onUpload={onUpload}
                id="chat-file"
              />
              <Videocam style={{ cursor: 'pointer' }} onClick={() => setVideoModal(true)} />
            </div>
          </div>
        </div>
        {!isSmall && (
          <FileContainer
            userId={user?.id}
            ownerImage={user?.profile_image}
            freelanceImage={chat?.profile_pic}
            isLoadingFiles={isLoadingFiles}
            files={files}
            withButton={false}
            style={{ width: '350px', overflowY: 'auto', height: '340px', padding: '8px' }}
          />
        )}
      </div>
    </>
  );
};

export default OpenedChat;
