import { Checkbox } from 'antd';
import { NEGOTIATION_INTELLECTUAL_PROP_TEXT } from 'common/constants/negotiation.constants';
import { Button, Text, Title } from 'components/common';
import StandardModal from 'components/common/StandardModal';
import React from 'react';
import css from '../ApplicantModal/ApplicantModal.module.scss';

const AcceptProposalModal = ({
  showApplicantModal,
  setShowApplicantModal,
  intellectualProp,
  currentProposal,
  handleAcceptProposal,
  setIntellectualProp,
  isLoadingAcceptProposal,
}) => {
  return (
    <StandardModal
      visible={showApplicantModal}
      onOk={() => setShowApplicantModal(false)}
      onCancel={() => setShowApplicantModal(false)}
      title="¿Estas seguro que quieres aceptar la propuesta?"
      width={700}
      button={
        <Button
          text={isLoadingAcceptProposal ? 'Aceptando...' : 'Aceptar propuesta'}
          variant="primary"
          color="primary"
          disabled={!intellectualProp || isLoadingAcceptProposal}
          onClick={() => handleAcceptProposal(currentProposal, intellectualProp)}
        />
      }>
      <Text size={16} style={{ textAlign: 'center', marginBottom: '2em' }}>
        Una vez que aceptes se rechazarán todas las otras postulaciones y el proyecto quedará en
        proceso de pago.
      </Text>

      <div className={css.intellectualProp} style={{ marginBottom: '2em' }}>
        <Checkbox
          checked={intellectualProp}
          onChange={(event) => setIntellectualProp(event.target.checked)}></Checkbox>
        <div>
          <Title type="tertiary" color="primary" style={{ fontSize: 16, paddingLeft: '10px' }}>
            Condiciones de propiedad intelectual
          </Title>
          <Text size={12} style={{ textAlign: 'justify' }}>
            {NEGOTIATION_INTELLECTUAL_PROP_TEXT}
          </Text>
        </div>
      </div>
    </StandardModal>
  );
};

export default AcceptProposalModal;
