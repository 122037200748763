import React from 'react';
import { ListCardLayout, Title } from '@components/common';
import './RequestMainCard.scss';
import { SkillsBeans, StatusTag, Button, Text } from '@components/common';
import { useHistory } from 'react-router';
import { FREELANCER_REQUESTS_ROUTE } from '@common/constants/routes.constants';
import ProfilePicture from 'components/common/ProfilePicture/ProfilePicture';

export const RequestMainCard = ({
  type,
  request,
  setUserDrawer,
  setRequestDrawer,
  setProjectDrawer,
}) => {
  const history = useHistory();

  const handleRequestManagment = (id) => {
    if (type == 'list') {
      history.push(FREELANCER_REQUESTS_ROUTE + '/' + id);
    }
  };
  return (
    <ListCardLayout onClick={() => handleRequestManagment(request.id)}>
      <ListCardLayout.LeftSide>
        <div className="request-card__profile">
          <div className="request-card__header">
            <Text type="detail">Dueño: </Text>
          </div>
          <div className="request-card__content">
            <ProfilePicture
              user={request?.owner_data}
              withEditButton={false}
              withName
              withMedals
              width={100}
            />
          </div>
        </div>
      </ListCardLayout.LeftSide>
      <ListCardLayout.RightSide>
        <div className="request-card__project">
          {' '}
          <div className="request-card__header">
            <Text type="detail">Proyecto: </Text>
            <StatusTag request={request} />
          </div>
          <div className="request-card__content">
            <Title type="secondary-bold" color="primary">
              {request.project_name}
            </Title>
            <div className="request-card__categories">
              <Text type="strong">{request.project_category_name}</Text>
              <div className="request-card__subcategories">
                {request.project_subcategories_names.map((subcategory, index) => {
                  return <Text key={index}>{`${subcategory} `}</Text>;
                })}
              </div>
            </div>

            <SkillsBeans skills={request.project_skills_names} />
          </div>
        </div>
      </ListCardLayout.RightSide>
      <ListCardLayout.Buttons>
        <div className="request-card__buttons">
          {' '}
          {setUserDrawer && request?.status != 'rejected' ? (
            <Button
              text="Ver perfil"
              variant="secondary"
              borderColor="primary"
              onClick={() => setUserDrawer(true)}
            />
          ) : (
            ''
          )}
          {setRequestDrawer && request?.status != 'rejected' ? (
            <Button
              text="Editar postulación"
              variant="secondary"
              borderColor="primary"
              onClick={() => setRequestDrawer(true)}
              disabled={request.status == 'accepted' ? true : false}
            />
          ) : (
            ''
          )}
          {setProjectDrawer && request?.status != 'rejected' ? (
            <Button
              text="Detalles proyecto"
              variant="secondary"
              borderColor="primary"
              onClick={() => setProjectDrawer(true)}
            />
          ) : (
            ''
          )}
        </div>
      </ListCardLayout.Buttons>
      <ListCardLayout.Bottom></ListCardLayout.Bottom>
    </ListCardLayout>
  );
};
