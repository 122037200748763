import React from 'react';
import StandardModal from '../StandardModal';
import { Text } from '../Text';
import VideoModalContent from './VideoModalContent';

const VideoCallModal = ({ toggle, setToggle, handleSubmitVideoCall }) => {
  return (
    <StandardModal
      title="Agendar una videollamada"
      visible={toggle}
      onCancel={() => setToggle(false)}>
      <Text size={12} centered style={{ marginBottom: '2em' }}>
        Aca podrás agendar una videollamada a través de zoom, ambos participantes recibirán una
        invitación por correo electronico y la reunión comenzará en el horario que hayas
        seleccionado.
      </Text>
      <VideoModalContent handleSubmit={handleSubmitVideoCall} />
    </StandardModal>
  );
};

export default VideoCallModal;
