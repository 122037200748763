import React from 'react';
import { Carousel } from 'antd';
import './RequestersCarousel.scss';
import { UserStats } from '@components/common';

export const RequestersCarousel = ({ requesters }) => {
  return (
    <div className="requesters-carousel__container">
      {requesters.length > 0 ? (
        <div className="requesters-carousel__content">
          <Carousel autoplay>
            {requesters.map((user, index) => {
              return <UserStats user={user} key={index} />;
            })}
          </Carousel>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
