import React from 'react';
import { Login } from 'components/layouts/Header/components/Accounts/Login/Login';
import { PopUpWindow } from '../PopUpWindow/PopUpWindow';
import { Register } from 'components/layouts/Header/components/Accounts/Register/Register';
import { Text } from '../Text';
import RegisterConfirmation from 'components/accounts/RegisterConfirmation/RegisterConfirmation';
import StandardModal from '../StandardModal';
import AccountsManagementViewModel from 'components/layouts/Header/components/Accounts/AccountsManagementViewModel';
import { useHistory } from 'react-router-dom';
import AccountsManagement from 'components/layouts/Header/components/Accounts/AccountsManagement';
import useAuthButtons from 'hooks/useAuthButtons';

const AuthPopups = () => {
  const {
    showAuthModal,
    setShowAuthModal,
    showLoginWindow,
    setShowLoginWindow,
    showRegisterWindow,
    setShowRegisterWindow,
    confirmationModal,
    setConfirmationModal,
  } = useAuthButtons();
  const { currentAuthUser, prefix, setPrefix, useRegister, registerBlocked, useLogin } =
    AccountsManagementViewModel({
      onRegister: () => {
        setConfirmationModal(true);
        setShowRegisterWindow(false);
      },
    });
  const history = useHistory();

  return (
    <>
      <StandardModal
        visible={showAuthModal}
        onCancel={() => setShowAuthModal(false)}
        title="Inicia sesión para continuar">
        <Text centered style={{ marginBottom: '1em' }}>
          Debes registrarte o iniciar sesión para publicar un proyecto, selecciona una opción para
          continuar.
        </Text>
        <AccountsManagement />
      </StandardModal>
      <PopUpWindow open={showLoginWindow} onClose={() => setShowLoginWindow(false)}>
        <Login useLogin={useLogin} currentAuthUser={currentAuthUser} />
      </PopUpWindow>
      <PopUpWindow open={showRegisterWindow} onClose={() => setShowRegisterWindow(false)}>
        <Register
          useRegister={useRegister}
          prefix={prefix}
          setPrefix={setPrefix}
          currentAuthUser={currentAuthUser}
          registerBlocked={registerBlocked}
        />
      </PopUpWindow>
      <StandardModal
        visible={confirmationModal}
        onCancel={() => {
          setConfirmationModal(false);
          history.push('?');
        }}>
        <RegisterConfirmation
          disableLayout
          onConfirm={() => {
            setConfirmationModal(false);
            history.push('?');
          }}
        />
      </StandardModal>
    </>
  );
};

export default AuthPopups;
