import React from 'react';
import { BaseLayout, SwitchTypeSearch } from '@components/common';
import { ClearAccountsViewModel } from './ClearAccountsViewModel';
import { PaymentOrderMoreDrawer } from '@components/clearAccounts/PaymentOrderMoreDrawer/PaymentOrderMoreDrawer';
import { ClientOrderMoreDrawer } from '@components/clearAccounts/ClientOrderMoreDrawer/ClientOrderMoreDrawer';
import { PaymentOrdersTable } from '@components/clearAccounts/OrdersTable/PaymentOrdersTable';
import { ClientOrdersTable } from '@components/clearAccounts/OrdersTable/ClientOrdersTable';

const ClearAccounts = () => {
  const {
    paymentOrdersList,
    clientOrdersList,
    projectSelected,
    serviceSelected,
    handleSwitch,
    moreDrawer,
    setMoreDrawer,
    currentPaymentOrder,
    currentClientOrder,
    handleMore,
  } = ClearAccountsViewModel();

  return (
    <BaseLayout>
      {projectSelected && (
        <PaymentOrderMoreDrawer
          currentPaymentOrder={currentPaymentOrder}
          moreDrawer={moreDrawer}
          setMoreDrawer={setMoreDrawer}
        />
      )}
      {serviceSelected && (
        <ClientOrderMoreDrawer
          currentClientOrder={currentClientOrder}
          moreDrawer={moreDrawer}
          setMoreDrawer={setMoreDrawer}
        />
      )}

      <BaseLayout.LeftSide>
        <div className="tn-layout__left-section">
          <SwitchTypeSearch
            textWork="Ingresos"
            textHire="Pagos"
            checkedWork={projectSelected}
            checkedHire={serviceSelected}
            handleSwitch={handleSwitch}
          />
        </div>
      </BaseLayout.LeftSide>
      <BaseLayout.Content>
        {projectSelected ? (
          <PaymentOrdersTable paymentOrdersList={paymentOrdersList} handleMore={handleMore} />
        ) : (
          <ClientOrdersTable clientOrdersList={clientOrdersList} handleMore={handleMore} />
        )}
      </BaseLayout.Content>
    </BaseLayout>
  );
};

export default ClearAccounts;
