import { DELETE_REQUEST } from '../actions/types.js';

const initialState = {
  projects: [],
  requests: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DELETE_REQUEST:
      return {
        ...state,
        requests: state.requests.filter((request) => request.id !== action.payload),
      };

    default:
      return state;
  }
}
