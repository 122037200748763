import React from 'react';
import css from './MigrationType.module.scss';
import { Text } from '@components/common';
import {
  INTEGRATIONS_LOGOS_DICTIONARY,
  modificationsIlustrationsDictionary,
  ilustrationsFolder,
} from '../../IntegrationsDetails.constants';

function MigrationType({ currentProject }) {
  return (
    <div className={css.container}>
      <div className={css.nActiveUsers}>
        {' '}
        <Text type="detail">Número de usuarios promedio: </Text>
        <Text>{currentProject?.n_active_users}</Text>
      </div>
      <Text type="detail">Funcionalidades actuales: </Text>
      <div className={css.content}>
        <div className={css.functionalities}>
          {currentProject?.website_functionalities?.map((mod, index) => {
            return (
              <div className={css.item} key={`mod-${index}`}>
                <img
                  src={
                    modificationsIlustrationsDictionary[mod]
                      ? ilustrationsFolder + modificationsIlustrationsDictionary[mod]
                      : ilustrationsFolder + 'dreamer_e856445593.svg'
                  }
                />
                <Text type="smallDetail">{mod} </Text>
              </div>
            );
          })}
        </div>
      </div>
      <Text type="detail">Integraciones actuales: </Text>
      <div className={css.content}>
        <div className={css.integrations}>
          {currentProject?.current_integration?.map((int, index) => {
            return (
              <div className={css.integration} key={`int-${index}`}>
                <img
                  src={
                    INTEGRATIONS_LOGOS_DICTIONARY[int]
                      ? ilustrationsFolder + INTEGRATIONS_LOGOS_DICTIONARY[int]
                      : ilustrationsFolder + 'dreamer_e856445593.svg'
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MigrationType;
