import React from 'react';
import { FormItemCard } from '../../FormItemCard';
import css from './ReferenceUrls.module.scss';
import { Text } from '@components/common';

const ReferenceUrls = ({
  values,
  handleSelectReferenceUrls,
  currentFocus,
  handleGoBackQuestion,
  handleGoForwardQuestion,
  displayDistinctQuestion,
}) => {
  const [currentValue, setCurrentValue] = React.useState({
    url_1: '',
    url_2: '',
    url_3: '',
    url_4: '',
    url_5: '',
  });

  React.useEffect(() => {
    if (values['reference_urls']) {
      setCurrentValue({
        url_1: values['reference_urls'][0] ? values['reference_urls'][0] : '',
        url_2: values['reference_urls'][1] ? values['reference_urls'][1] : '',
        url_3: values['reference_urls'][2] ? values['reference_urls'][2] : '',
        url_4: values['reference_urls'][3] ? values['reference_urls'][3] : '',
        url_5: values['reference_urls'][4] ? values['reference_urls'][4] : '',
      });
    }
  }, []);

  const onChange = (event) => {
    setCurrentValue({ ...currentValue, [event.target.name]: event.target.value });
    handleSelectReferenceUrls(
      Object.values({ ...currentValue, [event.target.name]: event.target.value }).filter(
        (a) => a != ''
      )
    );
  };

  return (
    <FormItemCard
      name="reference_urls"
      title="¿Tienes sitios de referencia?"
      subTitle={'(Opcional) Ingresa hasta 5 urls'}
      isCurrent={currentFocus == 'reference_urls'}
      id={'reference_urls-container'}
      handleGoForwardQuestion={handleGoForwardQuestion}
      handleGoBackQuestion={handleGoBackQuestion}
      displayDistinctQuestion={displayDistinctQuestion}>
      <div className={`${css.conatainer} ${currentFocus != 'reference_urls' && css.disabled}`}>
        {Object.keys(currentValue).map((url, index) => {
          return (
            <div className={css.section} key={`url-${index + 1}`}>
              <Text>{`${index + 1})`}</Text>
              <input
                maxLength="200"
                type="text"
                name={url}
                value={currentValue[url]}
                onChange={onChange}></input>
            </div>
          );
        })}
      </div>
    </FormItemCard>
  );
};

export default ReferenceUrls;
