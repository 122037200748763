import React from 'react';
import './ChangePassword.scss';
import { Button, Text, Title } from '@components/common';

const ChangePassword = ({ handleChangePassword }) => {
  return (
    <div className="change-password__container">
      <div className="change-password__header">
        <Title type="tertiary" color="primary">
          Cambiar Contraseña:{' '}
        </Title>
        <hr />
      </div>
      <Text type="detail">
        Se recomienda usar una contraseña segura que no uses en ningún otro sitio.
      </Text>

      <Button
        variant="secondary"
        borderColor="primary"
        text="Editar"
        onClick={handleChangePassword}
      />
    </div>
  );
};

export default ChangePassword;
