import { Card } from 'antd';
import { Text, Title } from 'components/common';
import { ViewRequestBreakdown } from 'components/requests/ViewRequestDrawer/ViewRequestBreakdown/ViewRequestBreakdown';
import React from 'react';
import '../Client/ClientProjects/ClientProjectManagment/ClientProjectManagment.scss';

const ProyectProposal = ({ currentProposal, currentRequest, isFreelance }) => {
  return (
    <Card style={{ borderRadius: '1em' }}>
      <Text type="boldDetail">Propuesta: </Text>
      <Title type="tertiary" color="primary">
        ¿Por qué eres la persona indicada para este trabajo?
      </Title>
      <Text>{currentRequest.why_you}</Text>
      <Title type="tertiary" color="primary">
        Descripción de la propuesta
      </Title>
      <Text>{currentProposal?.text}</Text>
      <Title type="tertiary" color="primary">
        Desglose de la propuesta
      </Title>
      <div className="view-request-drawer__request-breakdown">
        <ViewRequestBreakdown currentProposal={currentProposal} isFreelance={isFreelance} />
      </div>
    </Card>
  );
};

export default ProyectProposal;
