import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { HelpCircle } from 'react-feather';
import classnames from 'classnames';
import './ToolTip.scss';

const ToolTip = ({ text, className = '' }) => {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(true);
  };

  const onLeave = () => {
    setHover(false);
  };
  return (
    <div className={classnames('tooltip-box', className)}>
      <button onMouseEnter={onHover} onMouseLeave={onLeave} className="tooltip-container">
        <HelpCircle />
      </button>
      {hover && <div className="tooltip-background-text">{text}</div>}
    </div>
  );
};

ToolTip.propTypes = {
  text: PropTypes.string,
};

export default ToolTip;
