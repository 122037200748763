import React from 'react';
import { useSelector } from 'react-redux';
import { selectAuthUser } from '@reducers/auth';
import { useParams } from 'react-router-dom';

export const ManagmentViewModel = () => {
  const authUser = useSelector(selectAuthUser);

  const { type, view, id } = useParams();

  const [userDrawer, setUserDrawer] = React.useState(false);
  const [requestDrawer, setRequestDrawer] = React.useState(false);
  const [projectDrawer, setProjectDrawer] = React.useState(false);
  const [viewRequestDrawer, setViewRequestDrawer] = React.useState(false);
  const [paymentDrawer, setPaymentDrawer] = React.useState(false);
  const [bankAccountDrawer, setBankAccountDrawer] = React.useState(false);

  return {
    type,
    view,
    id,
    authUser,
    userDrawer,
    setUserDrawer,
    requestDrawer,
    setRequestDrawer,
    projectDrawer,
    setProjectDrawer,
    viewRequestDrawer,
    setViewRequestDrawer,
    paymentDrawer,
    setPaymentDrawer,
    bankAccountDrawer,
    setBankAccountDrawer,
  };
};
