import React from 'react';
import { ListCardLayout, SkillsBeans, Button, Text, Title } from '@components/common';
import '../../project/ProjectMainCard/ProjectMainCard.scss';
import ProfilePicture from 'components/common/ProfilePicture/ProfilePicture';

export const PublicServiceMainCard = ({
  currentService,
  currentUser,
  setUserDrawer,
  setContactDrawer,
  authUser,
}) => {
  return (
    <ListCardLayout>
      <div className="project-card__green-header"></div>
      <ListCardLayout.LeftSide>
        {' '}
        <div className="project-card__requester-header">
          <Text type="detail">Freelancer: </Text>
        </div>
        <ProfilePicture user={currentUser} withEditButton={false} withMedals withName width={100} />
      </ListCardLayout.LeftSide>
      <ListCardLayout.RightSide>
        {' '}
        <div className="project-card__project">
          {' '}
          <div className="project-card__header">
            <Text type="detail">Servicio: </Text>
          </div>
          <div className="project-card__content">
            <Title type="secondary-bold" color="secondary">
              {currentService?.title}
            </Title>
            <div className="project-card__categories">
              <div className="project-card__subcategories">
                {currentService?.categories_names.map((category, index) => {
                  return (
                    <Text key={index}>
                      {currentService?.categories_names.length == index + 1
                        ? category
                        : `${category} / `}
                    </Text>
                  );
                })}
              </div>
            </div>

            <SkillsBeans skills={currentService?.skills_names} />
          </div>
        </div>
      </ListCardLayout.RightSide>
      <ListCardLayout.Buttons>
        {' '}
        <div className="project-card__buttons">
          {' '}
          {setUserDrawer ? (
            <Button
              text={'Ver perfil'}
              variant="secondary"
              borderColor="secondary"
              onClick={() => setUserDrawer(true)}
            />
          ) : (
            ''
          )}
          {setContactDrawer ? (
            <Button
              text={'Contactar'}
              variant="primary"
              color="secondary"
              onClick={() => setContactDrawer(true)}
              disabled={!authUser.isAuthenticated}
            />
          ) : (
            ''
          )}
        </div>
      </ListCardLayout.Buttons>
      <ListCardLayout.Bottom></ListCardLayout.Bottom>
    </ListCardLayout>
  );
};
