import React from 'react';
import MyServicesCard from './MyServicesCard';

const ServicesList = ({ services, isPopup, handleDeleteService, handleSelectService }) => {
  return (
    <>
      {services?.length > 0 && (
        <>
          <div>
            {services?.map((service) => (
              <MyServicesCard
                service={service}
                key={service.id}
                isPopup={isPopup}
                handleDeleteService={handleDeleteService}
                handleSelectService={handleSelectService}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default ServicesList;
