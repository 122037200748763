import React, { Fragment } from 'react';
import {
  WebsiteFunctionalities,
  ActualDBStatus,
  ModelGoal,
  Compatibility,
  WebsiteType,
  ReferenceUrls,
} from 'components/project/NewProjectForm/FormItems';

const VariableMiddleQuestions = ({
  values,
  handleSelectWebsiteFunctionalities,
  handleSelectCompatibility,
  handleSelectActualDBStatus,
  handleSelectModelGoal,
  handleSelectWebsiteType,
  questionOrder,
  currentFocus,
  branchStatus,
  displayedQuestions,
  handleGoBackQuestion,
  handleGoForwardQuestion,
  handleSelectReferenceUrls,
  displayDistinctQuestion,
}) => {
  return (
    <Fragment>
      {questionOrder.includes('website_type') && displayedQuestions.includes('website_type') && (
        <WebsiteType
          values={values}
          handleSelectWebsiteType={handleSelectWebsiteType}
          branchStatus={branchStatus}
          currentFocus={currentFocus}
          handleGoBackQuestion={handleGoBackQuestion}
          handleGoForwardQuestion={handleGoForwardQuestion}
          displayDistinctQuestion={displayDistinctQuestion}
        />
      )}
      {questionOrder.includes('reference_urls') &&
        displayedQuestions.includes('reference_urls') && (
          <ReferenceUrls
            values={values}
            handleSelectReferenceUrls={handleSelectReferenceUrls}
            currentFocus={currentFocus}
            handleGoBackQuestion={handleGoBackQuestion}
            handleGoForwardQuestion={handleGoForwardQuestion}
            displayDistinctQuestion={displayDistinctQuestion}
          />
        )}

      {questionOrder.includes('website_functionalities') &&
        displayedQuestions.includes('website_functionalities') && (
          <WebsiteFunctionalities
            values={values}
            handleSelectWebsiteFunctionalities={handleSelectWebsiteFunctionalities}
            currentFocus={currentFocus}
            handleGoBackQuestion={handleGoBackQuestion}
            handleGoForwardQuestion={handleGoForwardQuestion}
            displayDistinctQuestion={displayDistinctQuestion}
          />
        )}
      {questionOrder.includes('compatibility') && displayedQuestions.includes('compatibility') && (
        <Compatibility
          values={values}
          handleSelectCompatibility={handleSelectCompatibility}
          currentFocus={currentFocus}
          handleGoBackQuestion={handleGoBackQuestion}
          handleGoForwardQuestion={handleGoForwardQuestion}
          displayDistinctQuestion={displayDistinctQuestion}
        />
      )}
      {questionOrder.includes('actual_db_status') &&
        displayedQuestions.includes('actual_db_status') && (
          <ActualDBStatus
            values={values}
            handleSelectActualDBStatus={handleSelectActualDBStatus}
            currentFocus={currentFocus}
            handleGoBackQuestion={handleGoBackQuestion}
            handleGoForwardQuestion={handleGoForwardQuestion}
            displayDistinctQuestion={displayDistinctQuestion}
          />
        )}
      {questionOrder.includes('model_goal') && displayedQuestions.includes('model_goal') && (
        <ModelGoal
          values={values}
          handleSelectModelGoal={handleSelectModelGoal}
          currentFocus={currentFocus}
          handleGoBackQuestion={handleGoBackQuestion}
          handleGoForwardQuestion={handleGoForwardQuestion}
          displayDistinctQuestion={displayDistinctQuestion}
        />
      )}
    </Fragment>
  );
};

export default VariableMiddleQuestions;
