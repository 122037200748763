import React from 'react';
import css from './PostCard.module.scss';
import { Button } from 'components/common';
import moment from 'moment';
import 'moment/locale/es';
import { PrimaryCardContent } from './components/PrimaryCardContent';
import { SecondaryCardContent } from './components/SecondaryCardContent';

function PostCard({ post, handleClickBlog, list = false, primary = false }) {
  const postAttr = post?.attributes;
  const date = moment(new Date(postAttr?.createdAt)).format('DD/MM/YYYY');

  return (
    <div className={css.container}>
      <div className={css.image}>
        <img src={postAttr?.coverImage.data.attributes.url} alt={'AltPhoto'} />
      </div>
      <div className={css.content}>
        {primary && !list ? (
          <PrimaryCardContent postAttr={postAttr} date={date} />
        ) : (
          <SecondaryCardContent postAttr={postAttr} date={date} />
        )}
        <div className={css.button}>
          <div className={css.date}>
            <p className="tn-text tn-text--detail">{date}</p>
          </div>{' '}
          <Button
            text="Continuar leyendo"
            variant="secondary"
            borderColor="primary"
            onClick={() => handleClickBlog(post?.id, postAttr?.title)}
          />
        </div>
      </div>
    </div>
  );
}

export default PostCard;
