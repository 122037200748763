/* eslint-disable no-unused-vars */
import React from 'react';
import CustomTabs from 'components/common/CustomTabs';
import { BookOpen, MessageSquare, Monitor, User } from 'react-feather';
import { ProposalSection } from '../ApplicantModal/ProposalSection';
import { ProfileSection } from '../ApplicantModal/ProfileSection';
import NewChat from 'components/common/NewChat';
import { FileContainer, LoadingDots, PublicationQuestions, Text, Title } from 'components/common';
import { Card } from 'antd';
import { PUBLICATION_TABS } from 'pages/Managment/ProjectsCommon/ProjectsTabs.constants';
import { useMediaQuery } from '@material-ui/core';
import ClientPublicationProject from 'pages/Managment/Client/ClientPublications/ClientPublicationManagment/ClientPublicationProject';
import { QuestionAnswer } from '@material-ui/icons';
import RejectProposalModal from './RejectProposalModal';
import AcceptProposalModal from './AcceptProposalModal';
import useQueryParams from 'hooks/useQueryParams';

const ApplicantTabs = ({
  currentRequester,
  currentRequest,
  currentProposal,
  showApplicantModal,
  setShowApplicantModal,
  isLoadingProposal,
  currentChat,
  isLoadingChat,
  authUser,
  isLoadingFiles,
  files,
  useImageUploadHandler,
  handleAcceptProposal,
  intellectualProp,
  setIntellectualProp,
  handleRejectRequest,
  handleGetFiles,
  currentProject,
  isLoadingQuestions,
  questionsList,
  handleReplyQuestion,
  unansweredQuestions,
  resetRequesterMessages,
  selectedTab,
  showRejectModal,
  setShowRejectModal,
  isLoadingAcceptProposal,
}) => {
  const query = useQueryParams();
  const tab = query.get('tab') || selectedTab;

  const applicantTabs = [
    {
      name: PUBLICATION_TABS.PROYECT,
      icon: <Monitor />,
      component: (
        <div style={{ borderRadius: '0em 1em 1em 1em', padding: 0, backgroundColor: 'white' }}>
          <ClientPublicationProject currentProject={currentProject} />
        </div>
      ),
    },
    // {
    //   name: PUBLICATION_TABS.SUGGESTION,
    //   icon: <Monitor />,
    //   badge: (
    //     <span
    //       style={{
    //         top: '-0.8em',
    //         right: '-1em',
    //         position: 'absolute',
    //         backgroundColor: '#3383ff',
    //         color: 'white',
    //         padding: '0 0.7em',
    //         borderRadius: '1em',
    //         fontSize: 12,
    //       }}>
    //       BETA
    //     </span>
    //   ),
    //   component: (
    //     <Card style={{ borderRadius: '0em 1em 1em 1em' }}>
    //       <Title color="primary">Sugerencias de nuestra I.A</Title>

    //       <Text type="detail">
    //         Por favor, tenga en cuenta que las sugerencias proporcionadas son generadas por una
    //         inteligencia artificial y se proporcionan solo como una ayuda para la toma de
    //         decisiones. La precisión y la calidad de las sugerencias pueden variar y no se garantiza
    //         que sean exactas o adecuadas para sus necesidades específicas.
    //       </Text>
    //       <div style={{ marginTop: 24 }}>
    //         {currentProject?.project_ia?.client_ia?.split('\n')?.map((line, idx) => (
    //           <Text key={idx}>{line}</Text>
    //         ))}
    //       </div>
    //     </Card>
    //   ),
    // },
    {
      name: PUBLICATION_TABS.QUESTIONS,
      extra: `(${questionsList?.length})`,
      icon: <QuestionAnswer />,
      notifications: unansweredQuestions > 0 && unansweredQuestions,
      component: (
        <Card style={{ borderRadius: '0em 1em 1em 1em' }}>
          <>
            {isLoadingQuestions ? (
              <LoadingDots />
            ) : (
              <PublicationQuestions
                type="project"
                questionsList={questionsList}
                handleReplyQuestion={handleReplyQuestion}
                noTitle
                withScroll={false}
              />
            )}
          </>
        </Card>
      ),
    },
    {
      name: PUBLICATION_TABS.PROPOSAL,
      icon: <BookOpen />,
      component: (
        <Card style={{ borderRadius: '0em 1em 1em 1em', padding: '0 10px' }}>
          <ProposalSection
            currentRequest={currentRequest}
            currentProposal={currentProposal}
            isLoadingProposal={isLoadingProposal}
            handleRejectRequest={() => setShowRejectModal(true)}
            handleAcceptProposal={() => setShowApplicantModal(true)}
          />
        </Card>
      ),
    },
    {
      name: PUBLICATION_TABS.PROFILE,
      icon: <User />,
      component: (
        <Card style={{ borderRadius: '0em 1em 1em 1em', padding: '0 10px' }}>
          <ProfileSection currentRequester={currentRequester} type="client" />
        </Card>
      ),
    },
    {
      name: PUBLICATION_TABS.MESAGES,
      icon: <MessageSquare />,
      notifications: currentRequester?.new_messages > 0 && currentRequester?.new_messages,
      onClick: resetRequesterMessages,
      component: (
        <Card style={{ borderRadius: '0em 1em 1em 1em', padding: '10px' }}>
          {!isLoadingChat && (
            <NewChat
              chatID={currentChat?.id}
              participants={currentChat?.participants_id}
              handleGetFiles={handleGetFiles}
              currentProject={currentProject}
              chatHeight={350}
              archivos={
                <FileContainer
                  userId={authUser?.user.id}
                  ownerImage={authUser?.user.profile_image}
                  freelanceImage={currentRequester?.profile_image}
                  isLoadingFiles={isLoadingFiles}
                  files={files}
                  onUpload={useImageUploadHandler}
                  withButton={false}
                />
              }
            />
          )}
        </Card>
      ),
    },
  ];

  const Mobile = useMediaQuery('(max-width:596px)');
  return (
    <>
      <RejectProposalModal
        showRejectModal={showRejectModal}
        setShowRejectModal={setShowRejectModal}
        handleRejectRequest={handleRejectRequest}
        currentRequest={currentRequest}
      />
      <AcceptProposalModal
        showApplicantModal={showApplicantModal}
        setShowApplicantModal={setShowApplicantModal}
        intellectualProp={intellectualProp}
        currentProposal={currentProposal}
        handleAcceptProposal={handleAcceptProposal}
        setIntellectualProp={setIntellectualProp}
        isLoadingAcceptProposal={isLoadingAcceptProposal}
      />
      <CustomTabs
        tabs={currentRequester ? applicantTabs : [applicantTabs[0], applicantTabs[1]]}
        selected={tab}
        toggleIcons={Mobile}
      />
    </>
  );
};
export default ApplicantTabs;
