import React from 'react';
import { ManagementCardLayout, SkillsBeans, StatusTag, Text, Title } from 'components/common';
import css from './MainCard.module.scss';
import ProfilePicture from 'components/common/ProfilePicture/ProfilePicture';

function MainCard({ request }) {
  return (
    <ManagementCardLayout>
      <div className={css.blueHeader}></div>
      <ManagementCardLayout.LeftSide>
        <div className={css.leftSide}>
          <div className={css.header}>
            <Text type="detail">Dueño: </Text>
          </div>
          <div className={css.leftContent}>
            <ProfilePicture
              user={request?.user_data}
              withEditButton={false}
              withName
              withMedals
              width={100}
            />
          </div>
        </div>
      </ManagementCardLayout.LeftSide>

      <ManagementCardLayout.RightSide>
        <div className={css.rightSide}>
          {' '}
          <div className={css.header}>
            <Text type="detail">Proyecto: </Text>
            <StatusTag request={request} />
          </div>
          <div className={css.rightContent}>
            <Title type="secondary-bold" color="primary">
              {request.project_name}
            </Title>
            <div className={css.categories}>
              <Text type="strong">{request.project_category_name}</Text>
              <div className={css.subcategories}>
                {request.project_subcategories_names.map((subcategory, index) => {
                  return <Text key={index}>{`${subcategory} `}</Text>;
                })}
              </div>
            </div>

            <SkillsBeans skills={request.project_skills_names} />
          </div>
        </div>
      </ManagementCardLayout.RightSide>
      <ManagementCardLayout.Buttons>
        <div className={css.buttons}></div>
      </ManagementCardLayout.Buttons>
      <ManagementCardLayout.Bottom></ManagementCardLayout.Bottom>
    </ManagementCardLayout>
  );
}

export default MainCard;
