/* eslint-disable no-unused-vars */
import { createMessage } from 'actions/messages';
import {
  approveStage,
  feedbackStage,
  getPrivateProject,
  getStages,
  uploadFile,
} from 'actions/projects';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthUser } from 'reducers/auth';
import { STATUS } from '../CustomCalendar/CustomCalendar.constants';
import { ModalConfirm } from '../ModalConfirm';
import { stepsMessages } from './ProjectSteps.constants';

const useCurrentProjectStages = ({ currentProject, setRefetchProject }) => {
  const authUser = useSelector(selectAuthUser);
  const dispatch = useDispatch();
  const projectStart = currentProject?.started_at?.split('T')[0];
  const [sortedStages, setSortedStages] = useState([]);
  const [isSubmitStageModalOpen, setIsSubmitStageModalOpen] = useState(false);
  const [isFeedbackStageModalOpen, setIsFeedbackStageModalOpen] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [file, setFile] = useState(null);
  const [refetchStages, setRefetchStages] = useState(false);
  useEffect(() => {
    getStages({ projectId: currentProject.id }).then(({ data }) => {
      const stages = data.objects;
      const sorted = stages?.sort((a, b) => (a.order > b.order ? 1 : -1));
      setSortedStages(sorted);
    });
  }, [currentProject, refetchStages]);

  const getCurrentStage = () => {
    return sortedStages?.filter((stage) => stage.approved).length + 1;
  };
  const currentStage = sortedStages[getCurrentStage() - 1];
  const onUploadFeedbackFile = (e) => {
    setFile(e.target.files[0]);
  };
  const handleClearFile = () => {
    if (file) {
      document.getElementById('feedback-file').value = '';
    }
    setFile(null);
  };
  const handleChangeFeedback = (e) => {
    setFeedback(e.target.value);
  };
  const showSubmitStageModal = () => {
    setIsSubmitStageModalOpen(true);
  };
  const handleSendSubmitStageModal = async () => {
    if (currentStage?.stage_feedbacks?.length > 0) {
      setIsSubmitStageModalOpen(false);
      return;
    }
    let file_id = null;
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('type', 'project');
      formData.append('filename', file.name);
      formData.append('project', currentProject.id);
      const { data } = await uploadFile(formData);
      file_id = data.data.id;
    }

    feedbackStage({
      comment: feedback,
      owner: authUser.user.id,
      project_stage: currentStage.id,
      file_id,
    }).then(() => {
      dispatch(createMessage({ successMessage: 'Se envió el feedback al cliente correctamente!' }));
      setRefetchStages(!refetchStages);
    });
    setIsSubmitStageModalOpen(false);
  };
  const handleCancelSubmitStageModal = () => {
    if (currentStage?.stage_feedbacks?.length > 0) {
      setIsSubmitStageModalOpen(false);
      return;
    }
    document.getElementById('feedback-file').value = '';
    setIsSubmitStageModalOpen(false);
    setFile(null);
    setFeedback('');
  };
  const showFeedbackStageModal = () => {
    setIsFeedbackStageModalOpen(true);
  };
  const handleSendFeedbackStageModal = (clickedStage) => {
    if (currentStage?.id !== clickedStage?.id) {
      setIsFeedbackStageModalOpen(false);
      return;
    }
    setIsFeedbackStageModalOpen(false);
    handleAproveCurrentStage();
  };
  const handleCancelFeedbackStageModal = () => {
    setIsFeedbackStageModalOpen(false);
  };

  const toDateString = (time) => {
    if (time instanceof Date && !isNaN(time)) {
      return new Date(time).toISOString().split('T')[0];
    }
    return '9999-99-99';
  };
  const getDateOfStage = (stage) => {
    const previousStagesDuration = sortedStages
      ?.slice(0, stage)
      ?.reduce((acc, stage) => acc + stage.duration, stage);
    let start = new Date(projectStart);
    start.setDate(start.getDate() + previousStagesDuration + 1);
    let end = new Date(start);
    end.setDate(end.getDate() + sortedStages[stage]?.duration);
    start = toDateString(start);
    end = toDateString(end);
    return { start, end };
  };
  const getDateOfStageFormatedToDDMMYYYY = (stageDate) => {
    //Formatea las fechas 2022-01-30 -> 30/01/2022
    if (!stageDate) return;
    const year = stageDate.slice(0, 4);
    const month = stageDate.slice(5, 7);
    const day = stageDate.slice(8, 10);
    return `${day}/${month}/${year}`;
  };
  const getDescriptionOfStage = (stageDate) => {
    const formatedStart = getDateOfStageFormatedToDDMMYYYY(stageDate.start);
    const formatedEnd = getDateOfStageFormatedToDDMMYYYY(stageDate.end);
    return `${formatedStart} - ${formatedEnd}`;
  };
  const getEndProjectDate = () => {
    let totalDuration = 0;
    totalDuration = sortedStages?.reduce((acc, stage) => acc + stage.duration, 1);
    let end = new Date(projectStart);
    end.setDate(end.getDate() + totalDuration + sortedStages?.length);
    end = toDateString(end);
    return end;
  };
  const getDatesWithStatus = () => {
    const projectStartWithStatus = {
      date: projectStart,
      status: STATUS.PROJECT_START,
      title: 'Inicio del proyecto',
    };

    const projectEndWithStatus = {
      date: getEndProjectDate(),
      status: STATUS.PROJECT_COMPLETED,
      title: 'Fin del proyecto',
    };

    let stagesWithStatus = [projectStartWithStatus, projectEndWithStatus];

    sortedStages.forEach((stage, idx) => {
      const dateOfStage = getDateOfStage(idx);

      const stageStartWithStatus = {
        date: dateOfStage.start,
        status: STATUS.STAGE_START,
        title: `Inicio de ${stage.name}`,
      };
      const stageEndWithStatus = {
        date: dateOfStage.end,
        status: STATUS.STAGE_COMPLETED,
        title: `Fin de ${stage.name}`,
      };

      stagesWithStatus = [...stagesWithStatus, stageStartWithStatus, stageEndWithStatus];
    });

    return stagesWithStatus;
  };
  const calculateStageClass = (idx) => {
    const current = getCurrentStage();
    if (idx + 1 < current) return 'finished';
    if (idx + 1 > current) return 'not-started';
    return 'started';
  };
  const isLastStage = () => {
    const current = getCurrentStage();
    return current === sortedStages?.length;
  };
  const isCurrent = (idx) => idx + 1 === getCurrentStage();
  const isProjectFinished = () =>
    sortedStages?.filter((stage) => stage.approved).length === sortedStages?.length;
  const handleAproveCurrentStage = () => {
    const stageId = currentStage.id;
    ModalConfirm({
      title: stepsMessages.Modals.apoveStage.title,
      content: stepsMessages.Modals.apoveStage.content,
      onOk: async () => {
        approveStage(stageId).then(() => {
          setSortedStages((prev) =>
            prev.map((stage) => {
              return stage.id === stageId ? { ...stage, approved: true } : stage;
            })
          );
          if (isLastStage()) {
            setRefetchProject((prev) => !prev);
          }
        });
      },
      onCancel: () => null,
    });
  };
  const formatedStartDate = getDateOfStageFormatedToDDMMYYYY(projectStart);
  const formatedEndDate = getDateOfStageFormatedToDDMMYYYY(getEndProjectDate());
  const descriptionOfStage = (stage) => getDescriptionOfStage(getDateOfStage(stage));
  return {
    getCurrentStage,
    getDateOfStage,
    getDescriptionOfStage,
    getEndProjectDate,
    getDatesWithStatus,
    getDateOfStageFormatedToDDMMYYYY,
    formatedStartDate,
    formatedEndDate,
    descriptionOfStage,
    sortedStages,
    calculateStageClass,
    isCurrent,
    isProjectFinished,
    handleAproveCurrentStage,
    showSubmitStageModal,
    handleSendSubmitStageModal,
    handleCancelSubmitStageModal,
    isSubmitStageModalOpen,
    onUploadFeedbackFile,
    handleChangeFeedback,
    feedback,
    file,
    handleClearFile,
    currentStage,
    showFeedbackStageModal,
    handleSendFeedbackStageModal,
    handleCancelFeedbackStageModal,
    isFeedbackStageModalOpen,
  };
};

export default useCurrentProjectStages;
