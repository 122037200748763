import React from 'react';
import classnames from 'classnames';
import './Row.scss';

const Row = ({ children, className = '', ...rest }) => {
  return (
    <div className={classnames('tn-row', className)} {...rest}>
      {children}
    </div>
  );
};

const RowLeading = ({ children, className, ...rest }) => {
  const classes = classnames('tn-row__leading', className);
  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

const RowTrailing = ({ children, className, ...rest }) => {
  const classes = classnames('tn-row__trailing', className);
  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

Row.Leading = RowLeading;
Row.Trailing = RowTrailing;

export default Row;
