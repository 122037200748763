import React from 'react';
import { RenderTimestamp, Text } from '@components/common';
import './QuestionBox.scss';

export const QuestionBox = ({ question, user, type }) => {
  const getColor = () => {
    if (type == 'project') {
      return 'primaryColor';
    } else if (type == 'service') {
      return 'secondaryColor';
    } else {
      return '';
    }
  };
  return (
    <div className="question-box-container">
      <div className="question-box-content-question">
        <div className="question-box-icon-question">
          <img
            className="question-box-profile-image"
            src={question?.user_profile_picture}
            alt="Error"
          />
          <div className="question-box-icon-text">
            <Text type="smallDetail">
              {`${question?.emitter_name} ${RenderTimestamp(question.created_at).toLowerCase()}`}
            </Text>
            <Text type="detail" className={`tn-text tn-text--detail ${getColor()}`}>
              {question.question_text}
            </Text>
          </div>
        </div>
      </div>
      {question?.answer_text && (
        <div className="question-box-content-answer">
          <div className="question-box-icon-question">
            <img className="question-box-profile-image" src={user?.profile_image} alt="Error" />
            <Text type="detail">{question.answer_text}</Text>
          </div>
        </div>
      )}
    </div>
  );
};
