//Projects status
export const ACTIVE_PROJECT_STATUS = 'status=in_progress&status=agreement&status=mediation';
export const COMPLETED_PROJECT_STATUS = 'status=completed';
export const PAYMENT_PROJECT_STATUS = 'status=payment&status=payment_validation';

export const ACTIVE_PROJECTS = 'active';
export const COMPLETED_PROJECTS = 'completed';
export const FEEDBACK_PROJECTS = 'feedback';
export const PAYMENT_PROJECTS = 'payment';
export const PUBLISHED_PROJECTS = 'published';
export const MEDIATION_PROJECTS = 'mediation';
export const REJECTED_PROJECTS = 'rejected';

export const ALL_PUBLICATIONS = ['payment', 'published', 'payment_validation'];
export const ALL_PROJECTS = ['completed', 'in_progress', 'feedback', 'mediation'];

//Projects labels
export const INPROGRESS_PROJECTS_LABEL = 'in_progress';
export const AGREEMENT_PROJECTS_LABEL = 'agreement';
export const MEDIATION_PROJECTS_LABEL = 'mediation';
export const PAYMENT_PROJECTS_LABEL = ['payment', 'payment_validation'];
export const PAYMENTVALIDATION_PROJECTS_LABEL = 'payment_validation';
export const FEEDBACK_PROJECT_LABEL = 'feedback';
export const COMPLETED_PROJECTS_LABEL = 'completed';
export const UNCOMPLETED_PROJECTS_LABEL = 'uncompleted';
export const MY_OWN_PROJECTS = 'myownprojects';
export const MY_COLLAB_PROJECTS = 'mycollabprojects';

export const PROJECT_LABELS_TEXTS = {
  [INPROGRESS_PROJECTS_LABEL]: 'Activo',
  [AGREEMENT_PROJECTS_LABEL]: 'Negociación',
  [MEDIATION_PROJECTS_LABEL]: 'Mediación',
  [PAYMENT_PROJECTS_LABEL]: 'En pago',
  [PAYMENTVALIDATION_PROJECTS_LABEL]: 'En pago',
  [COMPLETED_PROJECTS_LABEL]: 'Terminado',
  [UNCOMPLETED_PROJECTS_LABEL]: 'Terminado',
};
