import React from 'react';
import css from './CMSDetails.module.scss';
import General from './General';
import Design from './Design';
import Functionalities from './Functionalities';
import Tecnology from './Tecnology';

function CMSDetails({ currentProject }) {
  return (
    <div className={css.container}>
      <div className={css.left}>
        <div className={css.general}>
          <General currentProject={currentProject} />
        </div>
        <div className={css.functionalities}>
          <Functionalities currentProject={currentProject} />
        </div>
      </div>
      <div className={css.right}>
        <div className={css.design}>
          <Design currentProject={currentProject} />
        </div>
        <div className={css.tecnology}>
          <Tecnology currentProject={currentProject} />
        </div>
      </div>
    </div>
  );
}

export default CMSDetails;
