import React from 'react';
import css from './CurrentFilters.module.scss';

const CurrentFilters = ({ currentFilters, handleOnClickCategory }) => {
  return (
    <div className={css.container}>
      {currentFilters.map((category) => {
        return (
          <div className={css.category} key={`cat-${category.id}`}>
            {category.name}
            <div
              className={css.button}
              onClick={() => {
                handleOnClickCategory(category);
              }}>
              x
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CurrentFilters;
