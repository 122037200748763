/* eslint-disable no-unused-vars */
import React from 'react';
import classNames from 'classnames';
import './Button.scss';
import { LoadingSpin } from '../LoadingSpin';
import { Loader } from 'react-feather';
import { LoadingDots } from '../LoadingDots';
import { CircularProgress } from '@material-ui/core';

const Button = ({
  type,
  text,
  onClick,
  variant,
  color,
  borderColor,
  size,
  active,
  disabled,
  href,
  className,
  isLoading,
  ...rest
}) => {
  const buttonClasses = classNames(
    type ? type : 'tn-btn',
    variant ? `--${variant}` : `--primary`,
    color && `--${color}Color`,
    borderColor && `--${borderColor}BorderColor`,
    size && `--${size}`,
    active && '--active',
    (disabled || isLoading) && '--disabled',
    className
  );
  const ButtonTag = href ? 'a' : 'button';
  return (
    <ButtonTag
      disabled={disabled}
      type={type}
      className={buttonClasses}
      onClick={onClick}
      href={href}
      {...rest}>
      <span>{isLoading ? <CircularProgress style={{ height: 18, width: 18 }} /> : text}</span>
    </ButtonTag>
  );
};

export default Button;
