import React, { Fragment } from 'react';
import { Title } from '@components/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBusinessTime,
  faAddressCard,
  faVault,
  faListCheck,
  faHourglassHalf,
  faPiggyBank,
} from '@fortawesome/free-solid-svg-icons';
import './IconsSection.scss';

const IconsSection = ({ text, image }) => {
  return (
    <Fragment>
      <div className="icons__advantages">
        <div className="icons">
          {image === 'faBusinessTime' ? <FontAwesomeIcon icon={faBusinessTime} /> : null}
          {image === 'faAddressCard' ? <FontAwesomeIcon icon={faAddressCard} /> : null}
          {image === 'faVault' ? <FontAwesomeIcon icon={faVault} /> : null}
          {image === 'faListCheck' ? <FontAwesomeIcon icon={faListCheck} /> : null}
          {image === 'faHourglassHalf' ? <FontAwesomeIcon icon={faHourglassHalf} /> : null}
          {image === 'faPiggyBank' ? <FontAwesomeIcon icon={faPiggyBank} /> : null}
        </div>
        <Title className="icons__subtitle" type="primary">
          {text}
        </Title>
      </div>
    </Fragment>
  );
};

export default IconsSection;
