import React from 'react';

const sizeHandler = (height, width) => {
  if (height && width) {
    return { height: `${height}`, width: `${width}` };
  } else if (height | width) {
    return height ? { height: height, width: 'auto' } : { height: 'auto', width: width };
  } else {
    return { height: '100%', width: 'auto' };
  }
};
const maxSizeHandler = (maxHeight, maxWidth) => {
  if (maxHeight && maxWidth) {
    return { maxHeight: `${maxHeight}`, maxWidth: 'auto' };
  } else if (maxHeight || maxWidth) {
    return maxHeight
      ? { maxHeight: maxHeight, maxWidth: 'auto' }
      : { maxHeight: 'auto', maxWidth: maxWidth };
  } else {
    return { maxHeight: '100%', maxWidth: 'auto' };
  }
};

const GraphicContainer = ({
  src,
  width,
  height,
  maxWidth,
  maxHeight,
  style,
  animation,
  placement,
  link,
  newTab,
}) => {
  const size = sizeHandler(height, width);
  const maxSize = maxSizeHandler(maxHeight, maxWidth);

  return (
    <a href={link} target={newTab}>
      <img
        data-aos={animation}
        data-aos-anchor-placement={placement}
        src={src}
        style={{ ...size, ...maxSize, ...style }}
      />
    </a>
  );
};

export default GraphicContainer;
