import React, { Fragment } from 'react';
import { Button, Text, Title, CustomDrawer } from '@components/common';
import './ContactDrawer.scss';
import { NO_REQUESTS_ILUSTRATION } from '@common/constants/informativeIlustrations.constants';
import { useHistory } from 'react-router';

export const ContactDrawer = ({
  currentUser,
  redirectToCreateProject,
  authUserProjects,
  handleConfirmInviteProject,
  projectSelected,
  setProjectSelected,
  contactDrawer,
  setContactDrawer,
}) => {
  const history = useHistory();

  const handleRedirection = () => {
    history.push('/forms');
  };
  return (
    <CustomDrawer
      isMain={false}
      type="contact"
      open={contactDrawer}
      onClose={() => setContactDrawer(false)}
      visible={contactDrawer}>
      {' '}
      <div className="contact-drawer__container">
        <div className="contact-drawer__content">
          {' '}
          <Title type="tertiary" color="secondary">
            Crear proyecto
          </Title>
          <Text>
            Puedes crear un proyecto nuevo para <strong>{currentUser?.first_name} </strong>y así
            adecuarlo a sus servicios.
          </Text>
          <div className="contact-drawer__button">
            {' '}
            <Button
              text="Crear proyecto"
              variant="secondary"
              color="secondary"
              onClick={redirectToCreateProject}
            />
          </div>
          <hr />
          <Title type="tertiary" color="secondary">
            Invitar a un proyecto
          </Title>
          <Text>O puedes invitarlo a uno de tus proyectos ya publicados.</Text>
          <Text type="strong">
            {authUserProjects.length == 0 ? '' : 'Elige uno de tus proyectos publicados:'}
          </Text>
          {authUserProjects.map((project, index) => {
            return (
              <div
                onClick={() => setProjectSelected(project)}
                className={`contact-drawer__project-item${
                  project.id == projectSelected.id ? '--selected' : ''
                }`}
                key={index}>
                {project.title}
              </div>
            );
          })}
          <div className="contact-drawer__invite-button">
            {authUserProjects.length == 0 ? (
              <Fragment>
                {' '}
                <img src={NO_REQUESTS_ILUSTRATION} />
                <Text type="strong">No tienes proyectos publicados</Text>
                <Button
                  text="Publicar proyecto"
                  variant="secondary"
                  color="primary"
                  onClick={handleRedirection}
                />
              </Fragment>
            ) : (
              <Button
                text={`Invitar a ${currentUser?.first_name}`}
                variant="secondary"
                color="secondary"
                onClick={handleConfirmInviteProject}
              />
            )}
          </div>
        </div>
      </div>
    </CustomDrawer>
  );
};
