import React from 'react';
import './BankAccountCard.scss';
import { DropdownMenuItem, Text } from '@components/common';

const BankAccountCard = ({
  account,
  handleClickBankAccountCard,
  handleSelectBankAccount,
  handleDeleteBankAccount,
}) => {
  const withAction = [
    {
      label: 'Editar',
      callback: () => handleClickBankAccountCard(account),
    },
    {
      label: 'Eliminar',
      callback: () => handleDeleteBankAccount(account),
    },
  ];
  return (
    <div className={`bank-account-card__container${account.active ? '--active' : ''}`}>
      <DropdownMenuItem hoverButton="" options={withAction} color={'#3383ff'} />
      <div
        className="bank-account-card__clickeable"
        onClick={() => handleSelectBankAccount(account)}>
        <Text>{account?.name}</Text>
        <Text>{account?.rut}</Text>
        <Text>{account?.bank}</Text>
        <Text>{account?.account_type}</Text>
        <Text>{account?.account_number}</Text>
      </div>
    </div>
  );
};

export default BankAccountCard;
