/* eslint-disable no-unused-vars */
import React from 'react';
import { StatusTag, SkillsBeans, Button, Text, Title } from '@components/common';
import { ListCardLayout } from '@components/common';
import '../ProjectMainCard.scss';
import {
  CLIENT_PROJECTS_ROUTE,
  FREELANCER_PROJECTS_ROUTE,
} from '@common/constants/routes.constants';
import { useHistory } from 'react-router';
import { FEEDBACK_PROJECTS } from '@common/constants/project.constants';
import ProfilePicture from 'components/common/ProfilePicture/ProfilePicture';
import { DropdownMenuItem } from 'components/common';
import css from './MyProjectsMainCard.module.scss';
import { useSelector } from 'react-redux';
import { selectAuthUserInfo } from 'reducers/auth';
import MediationModal from './MediationModal';
import { MEDIATION_PROJECTS_LABEL } from 'common/constants/project.constants';

export const MyProjectsMainCard = ({
  type,
  currentProject,
  currentFreelancer,
  currentOwner,
  setRatingModal,
  withActions = false,
  setRefetchProject,
  ...rest
}) => {
  const history = useHistory();

  const user = useSelector(selectAuthUserInfo);

  const [toggleMediacionModal, setToggleMediacionModal] = React.useState(false);

  const withAction = [
    {
      label: 'Solicitar mediación',
      callback: () => setToggleMediacionModal(true),
    },
  ];

  const handleProjectManagment = (id) => {
    if (type == 'list') {
      if (currentOwner) {
        history.push(FREELANCER_PROJECTS_ROUTE + `/${id}`);
      } else if (currentFreelancer) {
        history.push(CLIENT_PROJECTS_ROUTE + `/${id}`);
      }
    }
  };

  const isInMediation = currentProject?.status === MEDIATION_PROJECTS_LABEL;

  const remainingAmount = currentProject?.stages?.reduce((acc, stage) => {
    if (!stage?.paid) {
      return (acc += stage?.price);
    }
    return acc;
  }, 0);

  return (
    <ListCardLayout {...rest} onClick={() => handleProjectManagment(currentProject?.id)}>
      {withActions && !isInMediation && (
        <>
          <div className={css.publication_card__blue_header}>
            <div className={css.publication_card__edit}>
              <DropdownMenuItem options={withAction} color="#3383ff" />
            </div>
          </div>
          <MediationModal
            owner={user?.id}
            project={currentProject}
            toggle={toggleMediacionModal}
            setToggle={setToggleMediacionModal}
            remainingAmount={remainingAmount}
            setRefetchProject={setRefetchProject}
          />
        </>
      )}

      <ListCardLayout.LeftSide>
        <div className="project-card__requester-header">
          <Text type="detail">{currentFreelancer ? 'Freelancer:' : 'Dueño'} </Text>
        </div>
        {currentFreelancer ? (
          <ProfilePicture
            user={currentFreelancer}
            withEditButton={false}
            withMedals
            withName
            width={100}
          />
        ) : (
          <ProfilePicture
            user={currentOwner}
            withEditButton={false}
            withMedals
            withName
            width={100}
          />
        )}
      </ListCardLayout.LeftSide>
      <ListCardLayout.RightSide>
        {' '}
        <div className="project-card__project">
          {' '}
          <div className="project-card__header">
            <Text type="detail">Proyecto: </Text> <StatusTag project={currentProject} />
          </div>
          <div className="project-card__content">
            <Title type="secondary-bold" color="primary">
              {currentProject?.title}
            </Title>
            <div className="project-card__categories">
              <Text type="strong">{currentProject?.project_category_name}</Text>
              <div className="project-card__subcategories">
                {currentProject?.project_subcategories_names.map((subcategory, index) => {
                  return (
                    <Text key={index}>
                      {currentProject?.project_subcategories_names.length == index + 1
                        ? subcategory
                        : `${subcategory} /`}
                    </Text>
                  );
                })}
              </div>
            </div>

            <SkillsBeans skills={currentProject?.skills_names} />
          </div>
        </div>
      </ListCardLayout.RightSide>
      <ListCardLayout.Buttons>
        <div className="project-card__buttons">
          {setRatingModal && currentProject.status == FEEDBACK_PROJECTS && (
            <Button
              text="Evaluar"
              variant="primary"
              color="secondary"
              size="xs"
              onClick={() => setRatingModal(true)}
            />
          )}
        </div>
      </ListCardLayout.Buttons>
    </ListCardLayout>
  );
};
