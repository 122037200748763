import React from 'react';
import css from './MultipleChoiceRadio.module.scss';

const MultipleChoiceRadio = ({ isSelected }) => {
  return (
    <div className={css.shade}>
      <div className={css.radio}>{isSelected && <div className={css.selected}></div>}</div>
    </div>
  );
};

export default MultipleChoiceRadio;
