/* eslint-disable no-unused-vars */
import React from 'react';
import { ListCardLayout, StatusTag, SkillsBeans, Button, Text, Title } from '@components/common';
import '../ProjectMainCard.scss';
import SocialShare from 'components/common/SocialShare';
import ProfilePicture from 'components/common/ProfilePicture/ProfilePicture';
import { ProjectTags } from 'components/common';

export const PublicProjectMainCard = ({
  authUser,
  currentProject,
  currentUser,
  currentProposal,
  setUserDrawer,
  setRequestDrawer,
  own,
  currentRequest,
  handleProposal,
}) => {
  return (
    <ListCardLayout>
      <div className="project-card__blue-header"></div>
      <ListCardLayout.LeftSide>
        <div className="project-card__requester-header">
          <Text type="detail">Dueño: </Text>
        </div>
        <ProfilePicture user={currentUser} width={100} withEditButton={false} withName={true} />
      </ListCardLayout.LeftSide>
      <ListCardLayout.RightSide>
        {' '}
        <div className="project-card__project">
          {' '}
          <div className="project-card__header">
            <Text type="detail">Proyecto: </Text>
            <StatusTag project={currentProject} />
          </div>
          <div className="project-card__content">
            <Title type="secondary-bold" color="primary">
              {currentProject?.title}
            </Title>
            <div className="project-card__categories">
              <Text type="strong">{currentProject?.project_category_name}</Text>
              <div className="project-card__subcategories">
                {currentProject?.project_subcategories_names.map((subcategory, index) => {
                  return (
                    <Text key={index}>
                      {currentProject?.project_subcategories_names.length == index + 1
                        ? subcategory
                        : `${subcategory} /`}
                    </Text>
                  );
                })}
              </div>
            </div>
            <ProjectTags
              projectType={currentProject?.project_type}
              neededType={currentProject?.needed_type}
              websiteType={currentProject?.website_type}
            />
            <SkillsBeans skills={currentProject?.skills_names} />
          </div>
        </div>
      </ListCardLayout.RightSide>
      <ListCardLayout.Buttons>
        <div className="project-card__buttons">
          {setUserDrawer && (
            <Button
              text={own ? 'Ver tu perfil' : 'Ver perfil'}
              variant="secondary"
              borderColor="primary"
              onClick={() => setUserDrawer(true)}
            />
          )}
          {setRequestDrawer && currentRequest?.status != 'rejected' && (
            <Button
              text={currentProposal ? 'Editar postulación' : 'Postular'}
              onClick={handleProposal}
              disabled={own}
              variant="primary"
              color="secondary"
              size="xs"
            />
          )}
        </div>
      </ListCardLayout.Buttons>
      <ListCardLayout.Bottom>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '1em' }}>
          <SocialShare quote="¡Te invito a revisar este proyecto!" hashtags={['tenecesito']} />
        </div>
      </ListCardLayout.Bottom>
    </ListCardLayout>
  );
};
