import React, { Fragment } from 'react';
import css from './Ratings.module.scss';
import { getRatings } from 'actions/ratings';
import { Text, Title } from 'components/common';
import { Rate } from 'antd';

function Ratings({ type, userId }) {
  const [ratings, setRatings] = React.useState([]);

  React.useEffect(() => {
    getRatings(type, userId).then((res) => {
      setRatings(res.data);
    });
  }, []);

  return (
    <div className={css.container}>
      {ratings.length > 0 && (
        <Fragment>
          {' '}
          <div className={css.title}>
            {type == 'client' ? (
              <Title color="secondary">Calificaciones de Clientes</Title>
            ) : (
              <Title color="primary">Calificaciones de Freelancers</Title>
            )}
          </div>
          <div className={css.content}>
            {ratings?.map((rating, index) => {
              return (
                <div key={`rating-${index}`} className={css.rating}>
                  <hr />
                  <Text type="boldDetail">{rating.project_name}</Text>
                  <div className={css.owner}>
                    {' '}
                    <img src={rating.profile_image} />
                    <Text type="bold">{rating.rating_name}</Text>
                    <Rate allowHalf disabled defaultValue={rating.rating_average} />
                  </div>
                  <Text>{rating.comment}</Text>
                </div>
              );
            })}
          </div>
        </Fragment>
      )}
    </div>
  );
}

export default Ratings;
