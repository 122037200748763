import React from 'react';
import css from './FormItemCard.module.scss';
import { Button, Text, Title } from 'components/common';

const FormItemCard = ({
  name,
  title,
  subTitle,
  isCurrent,
  id,
  children,
  handleGoBackQuestion,
  handleGoForwardQuestion,
  displayDistinctQuestion,
}) => {
  const handleOnClick = () => {
    if (!isCurrent) {
      displayDistinctQuestion(name);
    }
  };
  return (
    <div className={css.container} id={id}>
      <div className={`${css.header} ${isCurrent && css.selected}`} onClick={() => handleOnClick()}>
        <Title type="tertiary" color="white">
          {title}
        </Title>
        {subTitle && <Text type="normalWhite">{subTitle}</Text>}
      </div>
      <div className={css.content} onClick={() => handleOnClick()}>
        {children}
      </div>
      {isCurrent && (
        <div className={css.footer}>
          {handleGoBackQuestion && (
            <Button
              text="← Anterior"
              variant="secondary"
              borderColor="primary"
              size="sm"
              onClick={() => handleGoBackQuestion(name)}
            />
          )}
          {handleGoForwardQuestion && (
            <Button
              text="Siguiente →"
              variant="secondary"
              borderColor="primary"
              size="sm"
              onClick={() => handleGoForwardQuestion(name)}
            />
          )}
        </div>
      )}
    </div>
  );
};
export default FormItemCard;
