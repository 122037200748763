import React from 'react';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';

const AnimateOnView = ({ children, animation = 'fadeIn', className, ...rest }) => {
  const [ref, inView] = useInView({
    threshold: 0.8,
    triggerOnce: true,
  });

  const animations = {
    fadeIn: {
      opacity: inView ? 1 : 0,
      transform: inView ? 'translateY(0)' : 'translateY(50px)',
    },
    fromSide: {
      from: {
        transform: 'translateX(-100%)',
        opacity: 0,
        tension: 200,
        friction: 15,
      },
      to: {
        transform: inView ? 'translateX(0)' : 'translateX(-100%)',
        opacity: inView ? 1 : 0,
      },
    },
    fromSideAnytime: {
      from: {
        transform: 'translateX(-100%)',
        opacity: 0,
        tension: 200,
        friction: 15,
      },
      to: {
        transform: 'translateX(0)',
        opacity: 1,
      },
    },
    fadeInAnytime: {
      from: {
        opacity: 0,
        transform: 'translateY(50px)',
      },
      to: {
        opacity: 1,
        transform: 'translateY(0)',
      },
    },
  };
  const props = useSpring(animations[animation]);
  return (
    <div ref={ref} className={className} {...rest}>
      <animated.div style={props}>{children}</animated.div>
    </div>
  );
};

export default AnimateOnView;
