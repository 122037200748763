import { useState, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useSelector } from 'react-redux';
import config from '@config';
import chatService from '../services/chatService';
import { useRef } from 'react';
import { uploadFile } from 'actions/projects';

const NO_ID_CHAT = {
  messageHistory: [],
  onSend: () => console.log('not passing id to chat'),
  connectionStatus: 'Closed',
  isActive: false,
  isEmpty: true,
};

const useChat = ({ chatId }) => {
  const mounted = useRef(false);
  if (!chatId) {
    return NO_ID_CHAT;
  }
  //Public API that will echo messages sent to it back to the client

  const CHATURL = `${config.chatURL}/${chatId}/`;
  const [messageHistory, setMessageHistory] = useState([]);
  const { user, token } = useSelector((state) => state.auth);
  const [isNewPage, setIsNewPage] = useState(false);
  const [isFullHistory, setIsFullHistory] = useState(false);
  const { sendMessage, lastJsonMessage, readyState } = useWebSocket(CHATURL, {
    onOpen: () => onLoad(),
    shouldReconnect: () => true,
    reconnectInterval: 5000,
  });

  const onLoad = () => {
    sendMessage(chatService.fetchMessages(user?.email, chatId));
  };
  const fetchNewPage = (page) => {
    sendMessage(chatService.fetchMessages(user?.email, chatId, page));
  };

  const onSend = (messageContent, fileData = null) => {
    if (messageContent.trim() === '') return;
    sendMessage(
      chatService.newChatMessage({
        from: user?.email,
        content: messageContent,
        chatId,
        token,
        fileId: fileData ? fileData.id : null,
      })
    );
  };

  useEffect(() => {
    if (lastJsonMessage !== null && mounted) {
      if (isNewPage) {
        setMessageHistory((prev) => [...lastJsonMessage.messages, ...prev]);
        setIsNewPage(false);
      } else {
        if (lastJsonMessage?.messages) {
          setMessageHistory(lastJsonMessage.messages);
        }
        if (lastJsonMessage?.message) {
          setMessageHistory([...messageHistory, lastJsonMessage?.message]);
        }
      }
      if (lastJsonMessage.actual_page === lastJsonMessage.total_pages) {
        setIsFullHistory(true);
      }
    }
  }, [lastJsonMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const isActive = connectionStatus === 'Open';

  const isEmpty = messageHistory ? true : false;

  const isOwnerOfMessage = (msg) => msg.owner === user?.id;

  const stringMessageToArray = (chatMessage) => {
    return chatMessage?.split('\n');
  };

  const onUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();

    formData.append('file', file);
    formData.append('chat', chatId);
    formData.append('filename', file.name);
    formData.append('type', 'chat');

    const { data } = await uploadFile(formData);

    onSend(file.name, data.data);

    document.getElementById('chat-file').value = '';
  };

  return {
    messageHistory,
    onSend,
    connectionStatus,
    isActive,
    isEmpty,
    fetchNewPage,
    setIsNewPage,
    isNewPage,
    isFullHistory,
    isOwnerOfMessage,
    stringMessageToArray,
    onUpload,
  };
};

export default useChat;
