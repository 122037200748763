import React, { Component } from 'react';
import { resetPassword } from '@actions/auth';
import { message } from 'antd';
import { connect } from 'react-redux';
import { validateEmail } from '../RegisterConfirmation/RegisterConfirmation';
import { BaseFormLayout, Button, Text, Title } from '@components/common';
import '@components/accounts/ForgotPassword/ForgotPassword.scss';

export class ForgotPassword extends Component {
  handleConfirm = () => {
    const value = document.getElementById('forgot-password-input').value;
    if (validateEmail(value)) {
      this.props.resetPassword(value);
    } else {
      message.error('¡Ingresa un email válido!');
    }
  };

  render() {
    return (
      <BaseFormLayout>
        <Title size="22.9" type="accountTitle" color="primary">
          Recuperar Contraseña
        </Title>
        <Text type="bigStrong">
          Realizaremos un restablecimiento a través del correo asociado a tu cuenta.
        </Text>
        <input
          className="basic-input registration-confirmation-input forgot-password-input"
          placeholder="¡Ingresa aquí tu correo!"
          id="forgot-password-input"></input>

        <Text type="bigStrong">
          Te enviaremos un email al correo que nos señalas con las intrucciones para recuperar tu
          contraseña
        </Text>
        <div className="button-confirm">
          <Button
            text="Confirmar"
            variant="secondary"
            color="primary"
            onClick={this.handleConfirm}
          />
        </div>
      </BaseFormLayout>
    );
  }
}

export default connect(() => {}, { resetPassword })(ForgotPassword);
