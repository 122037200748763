import React from 'react';
import css from './SwitchFilter.module.scss';

function SwitchFilter({ blogCategories, categoryFilter, onChangeCategoryFilter }) {
  return (
    <div className={css.container}>
      <label className={css.optionLabel}>
        <input
          type="radio"
          onClick={() => onChangeCategoryFilter('')}
          checked={'' == categoryFilter}
        />
        <span>Todos</span>
      </label>
      {blogCategories.map((cat, index) => {
        return (
          <label className={css.optionLabel} key={`cat-${index}`}>
            <input
              type="radio"
              onClick={() => onChangeCategoryFilter(cat.name)}
              checked={cat.name == categoryFilter}
            />
            <span>{cat.name}</span>
          </label>
        );
      })}
    </div>
  );
}

export default SwitchFilter;
