import React from 'react';
import Select from 'react-select';
import css from '../Forms.module.scss';
const SelectType = ({ handleSetAnswer, selects, answer }) => {
  const options = selects.map((select) => ({
    value: select,
    label: select,
  }));
  const handleChange = (e) => {
    handleSetAnswer(e, 'select');
  };

  return (
    <div style={{ margin: '2em auto', maxWidth: 480, width: '100%' }}>
      <Select
        options={options}
        placeholder="Selecciona una opción..."
        classNamePrefix="react-select"
        className={css.select}
        onChange={handleChange}
        defaultValue={answer?.answers[0]}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            height: 50,
            boxShadow: state.isFocused ? 0 : 0,
            borderTop: 'none',
            borderLeft: 'none',
            borderRight: 'none',
            borderRadius: 0,
            maxWidth: 480,
            fontSize: 18,
            width: '100%',
            margin: '0 auto',
          }),
        }}
      />
    </div>
  );
};

export default SelectType;
