/* eslint-disable no-unused-vars */
import { Modal } from 'antd';
import { Text } from 'components/common';
import { MyProfileViewModel } from 'pages/Managment/MyProfile/MyProfileViewModel';
import ProfilePicture from 'components/common/ProfilePicture/ProfilePicture';
import UploadProfileImageModal from 'pages/Managment/MyProfile/UploadImage/UploadProfileImageModal';
import React from 'react';
import AboutMeProposal from './AboutMeProposal';
import ChangeSkillsProposal from './ChangeSkillsProposal';
import MyInformationProposal from './MyInformationProposal';
import MyLinksProposal from './MyLinksProposal';
import useRequest from '../useRequest';

const YourProfile = () => {
  const {
    authUser,
    profileImageModal,
    setProfileImageModal,
    skillsSelected,
    skillsData,
    fetching,
    fetchSkills,
    handleChangeSkills,
    useEditUser,
  } = MyProfileViewModel();
  const { hasLink, hasDescriptions, hasSkills } = useRequest({ authUser });
  return (
    <>
      <Modal
        centered
        footer={null}
        visible={profileImageModal}
        onOk={() => {}}
        onCancel={() => setProfileImageModal(false)}>
        <UploadProfileImageModal
          user={authUser.user}
          auth={authUser}
          setProfileImageModal={setProfileImageModal}
        />
      </Modal>
      <Text centered style={{ color: '#3383ff', marginBottom: '1em' }}>
        Y destácate del resto de los freelancers
      </Text>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '4em', flexWrap: 'wrap' }}>
        <div style={{ maxWidth: 400, width: '100%' }}>
          <ProfilePicture
            user={authUser.user}
            setProfileImageModal={setProfileImageModal}
            withRatings={false}
          />

          <MyLinksProposal user={authUser.user} useEditUser={useEditUser} hasLink={hasLink} />
          <ChangeSkillsProposal
            hasSkills={hasSkills}
            value={skillsSelected}
            onSearch={fetchSkills}
            onChange={handleChangeSkills}
            skillsData={skillsData}
            fetching={fetching}
            useEditUser={useEditUser}
          />
        </div>
        <div style={{ width: '100%', maxWidth: 468 }}>
          <MyInformationProposal user={authUser.user} useEditUser={useEditUser} />

          <AboutMeProposal
            user={authUser.user}
            useEditUser={useEditUser}
            hasDescriptions={hasDescriptions}
          />
        </div>
      </div>
    </>
  );
};

export default YourProfile;
