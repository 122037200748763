/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Title, Button } from 'components/common';
import css from './MyServices.module.scss';
import ServicesList from './ServicesList';
import useMyServices from './useMyServices';
import StandardModal from 'components/common/StandardModal';
import { ServicesForm } from 'pages/ServicesForm';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'reducers/auth';
import { RequestModal } from 'components/requests/EditRequestDrawer/RequestModal';

const MyServices = ({ user, listOnly, isPopup }) => {
  const authUser = useSelector(selectAuthUser);

  const { services, handleDeleteService, setRefetch } = useMyServices({
    user,
  });

  const [showModal, setShowModal] = useState(false);

  const [selectedService, setSelectedService] = useState(null);

  const handleOpenCreateService = () => {
    setSelectedService(null);
    setShowModal(true);
  };
  const handleSelectService = (id) => {
    setSelectedService(id);
    setShowModal(true);
  };
  const afterSubmit = () => {
    setRefetch((prev) => !prev);
    setShowModal(false);
  };
  return (
    <>
      {listOnly ? (
        <ServicesList
          services={services}
          isPopup={isPopup}
          handleDeleteService={handleDeleteService}
          handleSelectService={handleSelectService}
        />
      ) : (
        <div className={css.container}>
          <div style={{ marginBottom: 20 }}>
            <Title color="primary">Tus Servicios ({services?.length})</Title>
            <div style={services?.length > 0 ? { textAlign: 'right' } : {}}>
              <Button
                text="Agregar servicio"
                variant="primary"
                color="secondary"
                onClick={handleOpenCreateService}
              />
              <RequestModal
                isModalOpen={showModal}
                authUser={authUser}
                setRequestDrawer={setShowModal}
                lastStep={{
                  title: 'Agregar servicio',
                  component: (
                    <div style={{ width: '100%', maxHeight: '70vh', overflow: 'auto' }}>
                      <ServicesForm afterSubmit={afterSubmit} serviceToUpdate={selectedService} />
                    </div>
                  ),
                }}
              />
            </div>
          </div>
          <ServicesList
            services={services}
            isPopup={isPopup}
            handleSelectService={handleSelectService}
            handleDeleteService={handleDeleteService}
          />
        </div>
      )}
    </>
  );
};

export default MyServices;
