import React from 'react';
import { Text, GraphicContainer } from '@components/common';
import './ListItem.scss';
import AnimateOnView from 'components/AnimateOnView';

const ItemList = ({
  text,
  subtitle1,
  detail1,
  subtitle2,
  detail2,
  subtitle3,
  detail3,
  image,
  titleClass,
  reverse,
}) => {
  return (
    <AnimateOnView>
      <span
        className="howitworks__steps"
        style={{ flexDirection: reverse ? 'row-reverse' : 'row' }}>
        <div style={{ marginLeft: reverse ? '150px' : '' }} className="howitworks__content">
          <div
            style={{ paddingRight: !reverse ? '70px' : '' }}
            className="howitworks__content-inner">
            <Text className={titleClass} style={{ marginBottom: '0px' }}>
              {text}
            </Text>
            <Text
              style={{ marginBottom: '0px', marginTop: '20px', fontWeight: 'bold' }}
              type="subtitle">
              {subtitle1}
            </Text>
            <Text type="detail">{detail1}</Text>
            <Text style={{ marginBottom: '0px', fontWeight: 'bold' }} type="subtitle">
              {subtitle2}
            </Text>
            <Text type="detail">{detail2}</Text>
            <Text style={{ marginBottom: '0px', fontWeight: 'bold' }} type="subtitle">
              {subtitle3}
            </Text>
            <Text type="detail">{detail3}</Text>
          </div>
        </div>
        <div className="howitworks__image">
          <GraphicContainer src={image} maxHeight="250px" className="howitworks__graphic" />
        </div>
      </span>
    </AnimateOnView>
  );
};

export default ItemList;
