import axios from 'axios';
import axiosClient from 'config/axios';
const strapiURL = 'https://warm-reaches-30307.herokuapp.com/api';

const CMSFORM_ID = 1;
const INTEGRATIONSFORM_ID = 2;

export const getCMSForm = () => {
  return axios.get(`${strapiURL}/forms/${CMSFORM_ID}?populate=deep`);
};

export const getAllTemplates = () => {
  return axios.get(
    `${strapiURL}/templates?populate=*&pagination?[page]=1&pagination[pageSize]=1000`
  );
};
export const getTemplatesByCategoryName = ({ categoryName }) => {
  return axios.get(
    `${strapiURL}/templates?filters[template_category][name][$eq]=${categoryName}&populate=*`
  );
};

export const getAllPalettes = () => {
  return axios.get(
    `${strapiURL}/palettes?populate=*&pagination?[page]=1&pagination[pageSize]=1000`
  );
};
export const uploadPaletteAndTemplates = ({ file, type, owner }) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('owner', owner);
  return axiosClient.post(`/${type}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const generateFormTracker = (type) => {
  return axiosClient.post('/form_tracker', { tracker_type: type });
};
export const sendAskToFormTracker = (data) => {
  return axiosClient.post('/form_asks', data);
};

export const getIntegrationsForm = () => {
  return axios.get(`${strapiURL}/forms/${INTEGRATIONSFORM_ID}?populate=deep`);
};
