import React, { Fragment } from 'react';
import { BaseLayout } from '@components/common';
import { ClientPublicationsList } from './Client/ClientPublications/ClientPublicationsList';
import { ClientPublicationManagment } from './Client/ClientPublications/ClientPublicationManagment';
import { ClientProjectsList } from './Client/ClientProjects/ClientProjectsList';
import { ClientProjectManagment } from './Client/ClientProjects/ClientProjectManagment';
import { FreelancerRequestsList } from './Freelancer/FreelancerRequests/FreelancerRequestsList';
import { FreelancerRequestManagment } from './Freelancer/FreelancerRequests/FreelancerRequestManagment';
import { FreelancerPublicationsList } from './Freelancer/FreelancerPublications/FreelancerPublicationsList';
import { FreelancerPublicationsManagment } from './Freelancer/FreelancerPublications/FreelancerPublicationsManagment';
import { FreelancerProjectsList } from './Freelancer/FreelancerProjects/FreelancerProjectsList';
import { FreelancerProjectManagment } from './Freelancer/FreelancerProjects/FreelancerProjectManagment';
import { ManagmentViewModel } from './ManagmentViewModel';
import { ClearAccounts } from './ClearAccounts';
import { MoreConfigurations } from './MoreConfigurations';
import { MyProfile } from './MyProfile';
import {
  BANK_ACCOUNTS,
  MORECONFIGURATIONS,
  NOTIFICATIONS,
} from 'common/constants/routes.constants';

const Managment = () => {
  const { type, view, id } = ManagmentViewModel();

  return (
    <Fragment>
      {type == 'client' && view == 'publications' && !id ? (
        <ClientPublicationsList />
      ) : type == 'client' && view == 'publications' && id ? (
        <ClientPublicationManagment />
      ) : type == 'client' && view == 'projects' && !id ? (
        <ClientProjectsList />
      ) : type == 'client' && view == 'projects' && id ? (
        <ClientProjectManagment />
      ) : type == 'freelancer' && view == 'requests' && !id ? (
        <FreelancerRequestsList />
      ) : type == 'freelancer' && view == 'requests' && id ? (
        <FreelancerRequestManagment />
      ) : type == 'freelancer' && view == 'publications' && !id ? (
        <FreelancerPublicationsList />
      ) : type == 'freelancer' && view == 'publications' && id ? (
        <FreelancerPublicationsManagment />
      ) : type == 'freelancer' && view == 'projects' && !id ? (
        <FreelancerProjectsList />
      ) : type == 'freelancer' && view == 'projects' && id ? (
        <FreelancerProjectManagment />
      ) : type == 'common' && view == 'clearAccounts' ? (
        <ClearAccounts />
      ) : type == 'common' && view == 'personal-data' ? (
        <MoreConfigurations route={MORECONFIGURATIONS} />
      ) : type == 'common' && view == 'notifications' ? (
        <MoreConfigurations route={NOTIFICATIONS} />
      ) : type == 'common' && view == 'bank-accounts' ? (
        <MoreConfigurations route={BANK_ACCOUNTS} />
      ) : type == 'common' && view == 'profile' ? (
        <BaseLayout.Margins>
          <MyProfile />
        </BaseLayout.Margins>
      ) : (
        ''
      )}
    </Fragment>
  );
};
export default Managment;
