import axiosClient from '@config/axios';
import { tokenConfig } from './auth';
import { createMessage, returnErrors } from './messages';
import { DELETE_REQUEST, GET_USER_REQUESTS, GET_REQUEST } from './types';

// ADD REQUEST
export const addRequest = (request) => axiosClient.post(`/requests`, request);

export const manageRequest = (id, status) => {
  const result = axiosClient.patch(`/requests/${id}/`, status);

  return result;
};

// DELETE REQUEST
export const deleteRequest = (id) => (dispatch, getState) => {
  axiosClient
    .delete(`/requests/${id}/`, tokenConfig(getState))
    .then(() => {
      dispatch(createMessage({ deleteRequest: 'Request Deleted' }));
      dispatch({
        type: DELETE_REQUEST,
        payload: id,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

//GET USER REQUESTS (REQUESTS MADE FROM A SPECIFIC USER)
export const getUserRequests = (params) => {
  return axiosClient.get(`/requests/`, { params });
};

export const getRequestUrlParams = (URLparams) => {
  return axiosClient.get(`/requests?${URLparams}`);
};

export const getUserRequestOwn = (userid, status, page) => async (dispatch, getState) => {
  var aux_page = 1;
  if (page) {
    aux_page = page;
  }
  await axiosClient
    .get(`/requests/?emitter=${userid}&page=${aux_page}&${status}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_USER_REQUESTS,
        payload: res.data.objects,
      });
    })
    .catch((err) => console.log(err));
};
export const getFilteredUserRequests = (userid, searchdata, page) => async (dispatch, getState) => {
  var aux_page = 1;
  if (page) {
    aux_page = page;
  }
  await axiosClient
    .get(
      `/requests/?emitter=${userid}&page=${aux_page}&search=${searchdata}`,
      tokenConfig(getState)
    )
    .then((res) => {
      dispatch({
        type: GET_USER_REQUESTS,
        payload: res.data.objects,
      });
    })
    .catch((err) => console.log(err));
};
//GET PROJECTS REQUESTS
export const getProjectRequests = (projectid) => {
  return axiosClient.get(`/requests/?project=${projectid}`);
};

export const getRequest = (params) => {
  return axiosClient.get('/requests', { params });
};

export const getRequestId = (requestId) => axiosClient.get(`/requests/${requestId}`);

export const getPrivateRequest = (requestId) => {
  return axiosClient.get(`/my_request/?request=${requestId}`);
};

export const dispatchRequest = (request) => (dispatch) => {
  dispatch({
    type: GET_REQUEST,
    payload: request,
  });
};

export const getPaymentData = () => {
  return axiosClient.get('/get_payment_data');
};
