import React from 'react';
import { FormItemCard } from '../../FormItemCard';
import { modelGoalOptions } from 'common/constants/publicationForm.constants';
import { MultipleChoiceQuestion } from 'components/project/NewProjectForm/MultipleChoiceQuestion';

const ModelGoal = ({
  values,
  handleSelectModelGoal,
  currentFocus,
  handleGoBackQuestion,
  handleGoForwardQuestion,
  displayDistinctQuestion,
}) => {
  return (
    <FormItemCard
      name="model_goal"
      title="¿Cuál es el objetivo de este proyecto?"
      subTitle={'Elige una o más opciones'}
      isCurrent={currentFocus == 'model_goal'}
      id={'model_goal-container'}
      handleGoForwardQuestion={handleGoForwardQuestion}
      handleGoBackQuestion={handleGoBackQuestion}
      displayDistinctQuestion={displayDistinctQuestion}>
      <MultipleChoiceQuestion
        optionsList={modelGoalOptions}
        isFocused={currentFocus == 'model_goal'}
        currentSelected={values['model_goal']}
        handleSelect={handleSelectModelGoal}
      />
    </FormItemCard>
  );
};

export default ModelGoal;
