/* eslint-disable no-unused-vars */
import { Select, Spin } from 'antd';
import { Text } from 'components/common';
import React from 'react';

const ChangeSkillsProposal = ({
  value,
  onSearch,
  onChange,
  fetching,
  skillsData,
  useEditUser,
  hasSkills,
}) => {
  const [isEditing, setIsEditing] = React.useState(false);
  const onBlur = () => {
    useEditUser({ skills: value.map((i) => i.value) });
    setIsEditing(false);
  };
  const onClear = (e) => {
    const filtered = value.filter((i) => i.value !== e.value).map((i) => i.value);
    useEditUser({ skills: filtered });
    setIsEditing(false);
  };

  return (
    <>
      {!hasSkills && <Text style={{ color: 'red' }}>Debes ingresar al menos una habilidad</Text>}
      <Text style={{ marginBottom: '1em', color: isEditing ? '#3383ff' : '' }}>
        Selecciona tus habilidades
      </Text>
      <Select
        mode="multiple"
        labelInValue
        value={value}
        placeholder=""
        onFocus={() => setIsEditing(true)}
        onBlur={onBlur}
        onDeselect={onClear}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        filterOption={false}
        onSearch={onSearch}
        onChange={onChange}
        style={{ width: '100%' }}>
        {skillsData
          ?.filter((skill) => !value.map((i) => i.value).includes(skill.id))
          .map((skill) => (
            <Select.Option value={skill.id} key={skill.id}>
              {skill.name}
            </Select.Option>
          ))}
      </Select>
    </>
  );
};

export default ChangeSkillsProposal;
