export const TABS = {
  FEEDBACK: 'Feedback',
  PROYECT: 'Seguimiento del Proyecto',
  PUBLICATION: 'Publicación',
  PROPOSAL: 'Propuesta',
  PROFILE: {
    FREELANCER: 'Perfil del Freelancer',
    OWNER: 'Perfil del Dueño',
  },
};

export const PUBLICATION_TABS = {
  PROYECT: 'Detalles del proyecto',
  SUGGESTION: 'Nuestras sugerencias',
  QUESTIONS: 'Preguntas',
  PROPOSAL: 'Propuesta',
  PROFILE: 'Perfil',
  MESAGES: 'Mensajes',
};
