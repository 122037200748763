/* eslint-disable no-unused-vars */
import { Carousel } from 'antd';
import { parseToUrlFormat } from 'common/utils/Formaters';
import { Button, Title } from 'components/common';
import React from 'react';
import { useHistory } from 'react-router-dom';
import css from './HomeSlider.module.scss';
const HomeSlider = ({ homeSlider, latestBlog }) => {
  const history = useHistory();

  return (
    <Carousel autoplay>
      {homeSlider?.map(({ attributes: item }, index) => (
        <div key={index}>
          <div
            className={css.container}
            style={{ backgroundImage: `url('${item?.image?.data?.attributes?.url}')` }}>
            <div className={css.text}>
              <div>
                <Title type="xl" color="white">
                  {item?.title}
                </Title>
                <Title type="secondary" color="white">
                  {item?.subtitle}
                </Title>
                <Title type="xl" color="white" style={{ marginBottom: 25 }}>
                  {item?.text}
                </Title>
              </div>
              {item?.buttonText && (
                <div>
                  <Button
                    text={item?.buttonText}
                    color={item?.buttonColor}
                    style={{ padding: '6px 20px' }}
                    href={item?.buttonLink}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
      <div>
        <div
          className={css.container}
          style={{
            backgroundImage: `url('${latestBlog?.attributes?.coverImage?.data?.attributes?.url}')`,
          }}>
          <div className={css.overlay}>
            <div className={css.text}>
              <div>
                <Title type="xl" color="white">
                  Visita nuestro blog
                </Title>
                <Title type="secondary" color="white">
                  Te invitamos a leer...
                </Title>
                <Title type="xl" color="white" style={{ marginBottom: 25 }}>
                  {latestBlog?.attributes?.title}
                </Title>
              </div>
              <div>
                <Button
                  text="Continuar leyendo"
                  color="primary"
                  style={{ padding: '6px 20px' }}
                  onClick={() =>
                    history.push(
                      `/blog/${latestBlog?.id}/${parseToUrlFormat(latestBlog?.attributes?.title)}`
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Carousel>
  );
};

export default HomeSlider;
