import React, { Component } from 'react';
import { Form, Input } from 'antd';
import { changePassword } from '@actions/auth';
import { connect } from 'react-redux';
import { BaseFormLayout, Text, Title } from '@components/common';
import { HOME_ROUTE } from '@common/constants/routes.constants';
import './ChangePassword.scss';

export class ChangePassword extends Component {
  onSubmit = async (e) => {
    const newUser = {
      new_password: e.password,
      password: e.oldpassword,
    };
    await this.props.changePassword(newUser);
    this.props.history.push(HOME_ROUTE);
  };
  render() {
    return (
      <BaseFormLayout>
        <Form onFinish={this.onSubmit}>
          <Title size="23" type="accountTitle" color="primary">
            Cambia Tu Contraseña
          </Title>
          <Text type="bigStrong">Ingresa tu antigua contraseña</Text>

          <div className="form-group-login">
            <Form.Item
              name="oldpassword"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa tu antigua contraseña.',
                },
              ]}>
              <Input.Password />
            </Form.Item>
          </div>
          <Text type="bigStrong">Ingresa tu nueva contraseña</Text>

          <div className="form-group-login">
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Por favor ingresa tu nueva contraseña.',
                },
              ]}
              hasFeedback>
              <Input.Password />
            </Form.Item>
          </div>

          <Text type="bigStrong">Confirma la nueva contraseña</Text>

          <div className="form-group-login">
            <Form.Item
              name="confirm"
              dependencies={['newpassword']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Por favor confirma tu nueva contraseña.',
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject('¡Las dos contraseñas que ingresaste no coinciden!');
                  },
                }),
              ]}>
              <Input.Password />
            </Form.Item>
          </div>
          <Form.Item>
            <button className="animated-button change-password-confirmation-button" type="submit">
              <span>Confirmar</span>
            </button>
          </Form.Item>
        </Form>
      </BaseFormLayout>
    );
  }
}

export default connect(() => {}, { changePassword })(ChangePassword);
