export const ilustrationsFolder = 'https://tenecesito-cms-bucket.s3.us-west-2.amazonaws.com/';

export const modificationsIlustrationsDictionary = {
  'Agregar imágenes o ilustraciones': 'landing_page_afc34c724b.svg',
  'Reorganizar elementos': 'undraw_organize_photos_re_ogcy_3c807ad462.svg',
  'Velocidad de carga': 'undraw_fast_loading_re_8oi3_1_3b46c2cc8c.svg',
  'Actualizar tipografía': 'undraw_font_re_efri_88a79bf8b5.svg',
  'Cambiar estilo de botones y enlaces': 'undraw_button_style_re_uctt_8fac0efca4.svg',
  'Cambiar esquema de colores': 'undraw_color_palette_re_dwy7_3898980c64.svg',
  'Mejorar vistas móviles': 'note_list_c57c258892.svg',
  'Añadir imágenes y videos': 'video_files_aa6cb8f297.svg',
  'Crear contenido nuevo para mantener actualizada la web': 'landing_page_afc34c724b.svg',
  'Revisar y actualizar el contenido existente': 'website_setup_461c1b55d4.svg',
  'Añadir contenido descargable': 'undraw_export_files_re_99ar_44de24a20d.svg',
  'Página de inicio': 'landing_page_afc34c724b.svg',
  'Formulario de contacto': 'forms_f4579a46d9.svg',
  'Blog o foro de discusión': 'blogging_953b600f39.svg',
  'Dashboards y analytics': 'data_trends_5832f5bf58.svg',
  'Preguntas frecuentes': 'questions_0188b4eaf5.svg',
  Calendario: 'calendar_9ae394234c.svg',
  Reservas: 'booking_c15b28b33f.svg',
  'Chat en vivo': 'chatting_fc428a6e30.svg',
  Mapas: 'map_dark_f5fab2aded.svg',
  Georeferenciación: 'map_055ecdeb04.svg',
  'Galería y multimedia': 'video_files_aa6cb8f297.svg',
  'Cuentas de usuarios': 'account_1666dd74e5.svg',
  'Pagos por la plataforma': 'mobile_pay_vg_07d74d9246.svg',
  'Integración con Redes Sociales': 'social_thinking_01a1c96d98.svg',
  'Formulario de subscripción': 'subscriptions_5b401fc1da.svg',
  'Términos y condiciones': 'terms_3359e4c955.svg',
  'Valoraciones y ratings': 'review_7b7b28b7e6.svg',
  Testimonios: 'feedback_2eb116e743.svg',
  'Listado de productos': 'note_list_c57c258892.svg',
  'Optimizar contenido con palabras clave': 'undraw_key_points_re_u903_4bbcbe140c.svg',
  'Mejorar estructura del sitio': 'undraw_bookmarks_re_mq1u_0abaac3d17.svg',
  'Añadir contenido relevante y de calidad': 'undraw_image_focus_re_qqxc_5dfebade20.svg',
  'Crear o mejorar enlaces de calidad': 'undraw_link_shortener_mvf6_9566143ee1.svg',
  'Optimizar las imágenes': 'undraw_optimize_image_re_3tb1_2cbd339ac0.svg',
  'Optimizar el código de la página': 'undraw_code_review_re_woeb_e9de0f4bff.svg',
  'Optimizar la configuración del servidor': 'undraw_server_down_s_4_lk_5c936b3a24.svg',
  'Cambiarme de servidor': 'undraw_maintenance_re_59vn_ba8928022c.svg',
  'Medidas de prevención de hackers, firewall o antimalware':
    'undraw_secure_server_re_8wsq_fae97e061c.svg',
  'Actualizar el software': 'undraw_os_upgrade_re_r0qa_a2c09be37f.svg',
  'Implementar un certificado SSL': 'undraw_sync_files_re_ws4c_fc51aeef95.svg',
  'Realizar copias de seguridad regularmente': 'undraw_sync_files_re_ws4c_fc51aeef95.svg',
};

export const FRAMEWORKS_LOGOS_DICTIONARY = {
  WordPress: 'Word_Press_logo_6d4d9faff1.png',
  Joomla: 'Joomla_Logo_c49431de55.png',
  Drupal: 'Drupal_logo_39097a1fd2.png',
  Wix: 'Wix_Logo_41dcda22de.png',
  Shopify: 'Shopify_logo_d5997cec52.png',
  Woocommerce: 'Woocommerce_logo_6a733f808d.png',
  PrestaShop: 'prestashop_logo_866c449274.png',
  Magento: 'Magento_logo_0fa7e57ee4.webp',
  Bsale: 'bsale_2e3395dccb.png',
  Blogger: 'blogger_0e10205e6d.png',
  'CMS Hub': 'cmshub_a1e6991402.png',
  Behance: 'behance_da7ecd09ef.png',
  SquareSpace: 'squarespace_31d89a4e7e.png',
  Javascript: 'Java_Script_logo_bd024b5093.png',
  Go: 'Go_Logo_Blue_415085ec4f.png',
  React: 'React_logo_wordmark_3958230449.png',
  'C++': 'ISO_C_Logo_svg_3a268570c4.png',
  Laravel: 'laravel_ccb72acbf6.png',
  NodeJs: '2560px_Node_js_logo_2015_svg_c19513d3ba.png',
  '.NET': 'aspnet_5c76e17ab3.svg',
  Spring: 'Spring_Framework_Logo_wine_3da1efef8d.png',
  TypeScript: 'Typescript_logo_2020_eb5dbbba6b.svg',
  Flask: 'Flask_logo_132da6c362.svg',
  'Vue.js': 'vuejs_wide_1235cd3246.png',
  'C#': 'c_9469759ec7.png',
  Ruby: 'ruby_40734353eb.png',
  Python: 'python_logo_da1506118b.png',
  Express: 'express_routing_logo_96572fb66f.webp',
  Angular: 'angular_3_0f749b0c25.svg',
  PHP: 'php_1_63dae5d5b5.svg',
  'Ruby on Rails': 'Ruby_On_Rails_Logo_svg_b68fbf0ec4.png',
  Java: 'Java_logo_8e7bac93db.png',
  Django: 'Django_Logo_e8518c0f37.png',
  'No necesito migrar de framework': 'dreamer_e856445593.svg',
};

export const INTEGRATIONS_LOGOS_DICTIONARY = {
  'Google Analytics': 'Logo_Google_Analytics_svg_0285cc3874.png',
  TikTok: 'Tik_Tok_icon_glyph_97d4c50cab.png',
  Shipit: 'BRANDMARK_shipit_margin2_c28941ae96.webp',
  Meta: 'Meta_Platforms_Inc_logo_svg_3f86b60393.png',
  Nomad: 'Nomad_Logo_Black_e8922af54f.png',
  Linkedin: 'Linked_In_Logo_svg_2da03bd05d.webp',
  Amplifica: 'amplifica_35a03de3ac.png',
  Twitter: 'twitter_d6520757a0.png',
  Youtube: 'You_Tube_Logo_2017_svg_d007726bdb.png',
  Instagram: 'Instagram_logo_2016_svg_d3af58ad66.webp',
  Facebook: 'facebook_99efb6cdf5.png',
  WebPay: '1_Webpay_FB_800_527c6fd915.svg',
  'Mercado Pago': 'Mercado_Pago_Horizontal_118018d412.svg',
  PayPal: '580b57fcd9996e24bc43c530_f9979a63dd.png',
  PagoFácil: '1_Logo_cuadrado_e57fd62209.png',
  Khipu: '08_Khipu_200x75_1_f324d764cf.svg',
  Flow: 'logo_flow_56c06a5682.jpg',
  Stripe: '2560px_Stripe_Logo_revised_2016_svg_656c4039ad.png',
  Starken: 'Starkenlogo_d00ecdd0a9.png',
  CorreosChile: 'Logo_correos_svg_789a687882.png',
  ChileExpress: 'Chilexpress_2012_cc2f2b997e.webp',
  BlueExpress: 'Logo_Blue_fondo_blanco_f5859e3045.svg',
  GoogleMaps: 'Google_Maps_Logo_17d40d56e8.png',
  WhatsApp: 'Whats_App_Logo_wine_ee3a5c8146.svg',
};
