import React from 'react';
import CommonGeneral from '../components/CommonGeneral';
import ActualFrameworkCommon from '../components/ActualFrameworkCommon';
import IntegrationType from './IntegrationType';
import css from './Integration.module.scss';

function Integration({ currentProject }) {
  return (
    <div className={css.container}>
      <div className={css.left}>
        <div className={css.general}>
          <CommonGeneral currentProject={currentProject} />
        </div>
      </div>
      <div className={css.right}>
        <div className={css.design}>
          <ActualFrameworkCommon currentProject={currentProject} />
        </div>
        <div className={css.tecnology}>
          <IntegrationType currentProject={currentProject} />
        </div>
      </div>
    </div>
  );
}

export default Integration;
