import React from 'react';
import '../../project/ProjectMainCard/ProjectMainCard.scss';
import { ListCardLayout, UserStats, SkillsBeans, Text, Title } from '@components/common';
import { FREELANCER_PUBLICATIONS_ROUTE } from '@common/constants/routes.constants';
import { useHistory } from 'react-router';

export const ServicePublicationsListMainCard = ({ currentService, authUser }) => {
  const history = useHistory();

  const handleServiceManagment = (id) => {
    history.push(FREELANCER_PUBLICATIONS_ROUTE + '/' + id);
  };
  return (
    <ListCardLayout onClick={() => handleServiceManagment(currentService?.id)}>
      <ListCardLayout.LeftSide>
        <div>
          <div className="project-card__requester-header">
            <Text type="detail">Freelancer: </Text>
          </div>{' '}
          <UserStats user={authUser.user} />
        </div>
      </ListCardLayout.LeftSide>
      <ListCardLayout.RightSide>
        {' '}
        <div className="project-card__project">
          {' '}
          <div className="project-card__header">
            <Text type="detail">Proyecto: </Text>
          </div>
          <div className="project-card__content">
            <Title type="secondary-bold" color="secondary">
              {currentService?.title}
            </Title>
            <div className="project-card__categories">
              <div className="project-card__subcategories">
                {currentService?.categories_names.map((subcategory, index) => {
                  return (
                    <Text key={index}>
                      {currentService?.categories_names.length == index + 1
                        ? subcategory
                        : `${subcategory} /`}
                    </Text>
                  );
                })}
              </div>
            </div>

            <SkillsBeans skills={currentService?.skills_names} />
          </div>
        </div>
      </ListCardLayout.RightSide>
      <ListCardLayout.Buttons></ListCardLayout.Buttons>
      <ListCardLayout.Bottom></ListCardLayout.Bottom>
    </ListCardLayout>
  );
};
