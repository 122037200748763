import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  replyQuestion,
  deleteQuestion,
  createQuestion,
  getProjectQuestions,
  relatedProjects,
} from '@actions/projects';
import { selectAuthUser } from '@reducers/auth';
import { getRequest } from '@actions/requests';
import { getRequestProposals } from '@actions/proposals';
import ProjectViewModel from '../ProjectViewModel';
import { useDispatch } from 'react-redux';
import { createMessage } from 'actions/messages';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { useHistory } from 'react-router';
import {
  NOT_ALLOWED_URL,
  CLIENT_PUBLICATIONS_ROUTE,
  FREELANCER_REQUESTS_ROUTE,
} from 'common/constants/routes.constants';

const { confirm } = Modal;

const PublicProjectViewModel = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const authUser = useSelector(selectAuthUser);

  const [userDrawer, setUserDrawer] = useState(false);
  const [requestDrawer, setRequestDrawer] = useState(false);

  const [currentRequest, setCurrentRequest] = useState(null);
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  const [currentProposal, setCurrentProposal] = useState(null);
  const [isLoadingProposal, setIsLoadingProposal] = useState(false);

  const { isLoading, currentProject, currentUser } = ProjectViewModel();

  const [openVerifyIdentity, setOpenVerifyIdentity] = useState(false);
  const [fileList, setFileList] = React.useState([]);

  const [questionsList, setQuestionsList] = React.useState([]);

  const [projectsSuggestions, setProjectsSuggestions] = React.useState([]);

  const useGetRequestProjectUser = (emitterId, projectId) =>
    getRequest({ project: projectId, emitter: emitterId });
  const useGetRequestProposals = (request) => getRequestProposals({ request: request });

  const filterVisibleQuestions = (questions) => {
    const questionsFiltered = questions.filter((question) => {
      return question.visible || authUser?.user?.id == question.emitter;
    });
    return questionsFiltered;
  };

  React.useEffect(() => {
    if (currentProject) {
      relatedProjects(currentProject.id)
        .then((res) => {
          setProjectsSuggestions(res.data.projects);
        })
        .catch(() => {
          setProjectsSuggestions([]);
        });
    }
  }, [currentProject]);

  React.useEffect(() => {
    if (currentProject && !isLoading) {
      if (currentProject.status !== 'published') {
        history.push(NOT_ALLOWED_URL + '/403');
      }
      if (currentProject?.owner == authUser?.user?.id) {
        history.push(`${CLIENT_PUBLICATIONS_ROUTE}/${currentProject.id}`);
      }

      setFileList(currentProject.project_files);
    }
    async function fetchData() {
      setCurrentRequest(null);
      if (!isLoading && authUser?.isAuthenticated) {
        setIsLoadingRequest(true);
        await useGetRequestProjectUser(authUser?.user.id, currentProject?.id)
          .then((res) => {
            setCurrentRequest(res.data.objects[0]);
            if (res.data.objects[0]) {
              history.push(`${FREELANCER_REQUESTS_ROUTE}/${res.data.objects[0].id}`);
            }
          })
          .catch(() => {
            setCurrentRequest(null);
            setCurrentProposal(null);
          });
        setIsLoadingRequest(false);
      }
    }
    fetchData();
  }, [isLoading, currentProject]);

  React.useEffect(() => {
    if (currentProject) {
      getProjectQuestions(currentProject.id)
        .then((res) => {
          setQuestionsList(filterVisibleQuestions(res.data.objects));
        })
        .catch(() => {
          setQuestionsList([]);
        });
    }
  }, [currentProject]);

  React.useEffect(() => {
    async function fetchData() {
      setCurrentProposal(null);
      if (!isLoadingRequest && authUser.isAuthenticated) {
        setIsLoadingProposal(true);
        if (currentRequest) {
          await useGetRequestProposals(currentRequest.id)
            .then((res) => {
              setCurrentProposal(res.data[res.data.length - 1]);
            })
            .catch(() => {
              setCurrentProposal(null);
            });
        }

        setIsLoadingProposal(false);
      }
    }
    fetchData();
  }, [isLoadingRequest, currentRequest]);

  const doneVerification = () => {
    setOpenVerifyIdentity(false);
    setRequestDrawer(true);
  };

  const own = authUser?.isAuthenticated
    ? currentUser?.id === authUser?.user.id
      ? true
      : false
    : false;

  const handleCreateQuestion = (questionText) => {
    var question = {};
    if (authUser?.isAuthenticated) {
      if (questionText != '') {
        question['question_text'] = questionText;
        question['project'] = currentProject.id;
        question['emitter'] = authUser?.user.id;
        createQuestion(question)
          .then((res) => {
            setQuestionsList([...questionsList, res.data.request]);
            dispatch(createMessage({ createQuestion: '¡Se ha creado correctamente tu pregunta!' }));
          })
          .catch(() => {
            setQuestionsList([]);
            dispatch(
              createMessage({
                createQuestion: 'No se ha podido crear tu pregunta, por favor intente más tarde.',
              })
            );
          });
      }
    }
  };

  const handledeleteQuestion = async (id) => {
    confirm({
      title: `¿Seguro que quieres eliminar esta pregunta?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: async () => {
        deleteQuestion(id)
          .then(() => {
            dispatch(
              createMessage({ deleteQuestion: 'Se ha eliminado correctamente el comentario' })
            );
          })
          .catch((err) => {
            console.log(err);
          });
      },
      onCancel() {},
    });
  };

  const handleProposal = () => {
    return setRequestDrawer(true);
  };

  const handleRedirectSuggestion = (id) => {
    history.push(`/project/${id}/view`);
  };
  return {
    isLoading: isLoading || isLoadingRequest || isLoadingProposal,
    currentProject,
    own,
    replyQuestion,
    handledeleteQuestion,
    currentRequest,
    currentProposal,
    currentUser,
    handleCreateQuestion,
    userDrawer,
    setUserDrawer,
    requestDrawer,
    setRequestDrawer,
    authUser,
    openVerifyIdentity,
    setOpenVerifyIdentity,
    doneVerification,
    fileList,
    questionsList,
    handleProposal,
    projectsSuggestions,
    handleRedirectSuggestion,
  };
};

export default PublicProjectViewModel;
