import axiosClient from '@config/axios';
import axios from 'axios';

export const getBlogCategories = () => {
  return axiosClient.get('/blogCategories');
};

export const getBlogs = (page, category) => {
  const categoryString = category ? `&filters[category][$eq]=${category}` : '';
  return axios.get(
    `https://warm-reaches-30307.herokuapp.com/api/blogs?populate=*&&sort=createdAt:desc&&pagination[page]=${page}${categoryString}`
  );
};

export const getBlogId = (id) => {
  return axios.get(`https://warm-reaches-30307.herokuapp.com/api/blogs/${id}?populate=*`);
};

export const newComment = (comment) => {
  return axiosClient.post(`/blogcomments/`, comment);
};

export const getComments = (blogId) => {
  return axiosClient.get(`/blogcomments?blog=${blogId}`);
};

export const getBlogInfo = (blogId) => {
  return axiosClient.get(`/info_of_blog?blog_id=${blogId}`);
};

export const getRecentBlogs = () => {
  return axiosClient.get('/recent_blogs');
};

export const getHomeSlider = () => {
  return axios.get(
    `https://warm-reaches-30307.herokuapp.com/api/home-sliders?populate=*&&sort=order:asc`
  );
};

export const getLatestBlog = () => {
  return axios.get(
    'https://warm-reaches-30307.herokuapp.com/api/blogs?populate=*&&sort=createdAt:desc&&pagination[page]=1&pagination[pageSize]=1'
  );
};
