import { getUserServices } from 'actions/services';
import { getUser_v2, relatedUsers, getUserQuestions } from 'actions/users';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectAuthUser } from '@reducers/auth';
import { MYPROFILE } from '@common/constants/routes.constants';
import { createMessage } from 'actions/messages';
import { createQuestion } from '@actions/projects';
import { useDispatch } from 'react-redux';

const FreelanceProfileViewModel = () => {
  const { userId } = useParams();
  const authUser = useSelector(selectAuthUser);
  const history = useHistory();
  const dispatch = useDispatch();

  const [currentFreelance, setCurrentFreelance] = useState(null);
  const [isLoadingFreelance, setIsLoadingFreelance] = useState(true);
  const [usersSuggestions, setUsersSuggestions] = useState([]);
  const [services, setServices] = useState([]);

  const [questionsList, setQuestionsList] = useState([]);

  const filterVisibleQuestions = (questions) => {
    const questionsFiltered = questions.filter((question) => {
      return question.visible || authUser?.user?.id == question.emitter;
    });
    return questionsFiltered;
  };

  useEffect(() => {
    if (authUser?.user?.id == userId) {
      history.push(MYPROFILE);
    }
    if (userId) {
      setIsLoadingFreelance(true);
      getUser_v2(userId)
        .then((res) => {
          setCurrentFreelance(res.data);
          setIsLoadingFreelance(false);
        })
        .catch(() => {
          setIsLoadingFreelance(false);
        });

      getUserServices(userId).then((res) => {
        setServices(res?.data?.objects);
      });
    }
  }, [userId, authUser]);

  useEffect(() => {
    if (userId) {
      relatedUsers(userId)
        .then((res) => {
          setUsersSuggestions(res.data.users);
        })

        .catch(() => {
          setUsersSuggestions([]);
        });
    }
  }, [userId]);

  useEffect(() => {
    if (authUser) {
      getUserQuestions(userId)
        .then((res) => {
          setQuestionsList(filterVisibleQuestions(res.data.objects));
        })
        .catch(() => {
          setQuestionsList([]);
        });
    }
  }, [userId, authUser]);

  const handleCreateQuestion = (questionText) => {
    var question = {};
    if (authUser?.isAuthenticated) {
      if (questionText != '') {
        question['question_text'] = questionText;
        question['owner'] = userId;
        question['emitter'] = authUser?.user.id;
        createQuestion(question)
          .then((res) => {
            setQuestionsList([...questionsList, res.data.request]);
            dispatch(createMessage({ createQuestion: '¡Se ha creado correctamente tu pregunta!' }));
          })
          .catch(() => {
            setQuestionsList([]);
            dispatch(
              createMessage({
                createQuestion: 'No se ha podido crear tu pregunta, por favor intente más tarde.',
              })
            );
          });
      }
    }
  };

  return {
    isLoadingFreelance,
    currentFreelance,
    services,
    usersSuggestions,
    questionsList,
    handleCreateQuestion,
  };
};

export default FreelanceProfileViewModel;
