import React from 'react';
import { Button, Text } from '@components/common';
import { Modal } from 'antd';
import './NewCardWindow.scss';
import { Input } from 'antd';
const { TextArea } = Input;

const NewCardWindow = ({ show, onClose, item, handleDelete }) => {
  return (
    <Modal centered={true} visible={show} onCancel={onClose} footer={null} destroyOnClose={true}>
      <div className="new-card-window__container ">
        <div className="new-card-window_section">
          <Text type="strongBlue">Título:</Text>
          <Input
            placeholder="Completa"
            name="board-item-title"
            id="board-item-title"
            defaultValue={item.title}></Input>
        </div>
        <div className="new-card-window_section">
          <Text type="strongBlue">Descripción:</Text>
          <TextArea
            autoSize={{ minRows: 5 }}
            placeholder="Agrega más detalles"
            name="board-item-description"
            id="board-item-description"
            defaultValue={item.content}
            maxLength={500}
          />
        </div>
        <div className="new-card-window_section">
          <Text type="strongBlue">Prioridad:</Text>
          <select name="board-item-priority" id="board-item-priority" defaultValue={item.priority}>
            <option value="Muy Alta">Muy Alta</option>
            <option value="Alta">Alta</option>
            <option value="Media">Media</option>
            <option value="Baja">Baja</option>
          </select>
        </div>
        <div className="new-card-window_section">
          <Text type="strongBlue">Duración Estimada:</Text>
          <div className="new-card-window_section-content">
            {' '}
            <Input
              placeholder="0"
              type="number"
              name="board-item-estimate"
              id="board-item-estimate"
              defaultValue={item.estimate}
              maxLength={10}></Input>
            <Text>Horas hombre (HH)</Text>
          </div>
        </div>
        <div className="item-delete-container">
          <Button variant="secondary" borderColor="primary" onClick={onClose} text="¡Listo!" />
          <Button
            variant="secondary"
            borderColor="error"
            onClick={() => handleDelete(item)}
            text="Borrar"
          />
        </div>
      </div>
    </Modal>
  );
};

export default NewCardWindow;
