import React, { Fragment } from 'react';
import { Form, message } from 'antd';
import { Button } from '@components/common';
import { DescriptiveQuestions } from '../DescriptiveQuestions/DescriptiveQuestions';
import { StagesDefinition } from '../StagesDefinition/StagesDefinition';
import { removeNonNumeric } from '@common/utils/Formaters';
import { addRequest } from '@actions/requests';
import { addProposal } from '@actions/proposals';
import { FREELANCER_REQUESTS_ROUTE } from '@common/constants/routes.constants';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { Modal } from 'antd';
const { confirm } = Modal;

export function RequestForm({
  currentProject,
  currentRequest,
  currentProposal,
  authUser,
  showDiscountWindow,
  setShowDiscountWindow,
  commission,
  setCommission,
  isLoading,
  discountCode,
}) {
  const history = useHistory();
  const [nStages, setNStages] = React.useState(1);
  const [stagesValues, setStagesValues] = React.useState(
    Array.from(Array(5), () => ({ name: '', price: '', stage_duration: '' }))
  );
  const [totalPrice, setTotalPrice] = React.useState(0);
  const [totalDays, setTotalDays] = React.useState(0);

  const handleStageValueChange = (e) => {
    const index = parseInt(e.target.name.split('-')[1]) - 1;
    const type = e.target.name.split('-')[2];
    let tempList = stagesValues;
    if (type == 'price') {
      tempList[index]['price'] = parseInt(removeNonNumeric(e.target.value));
    } else if (type == 'title') {
      tempList[index]['name'] = e.target.value;
    } else if (type == 'days') {
      tempList[index]['stage_duration'] = parseInt(e.target.value >= 0 ? e.target.value : 0);
    }
    setStagesValues(tempList);
    setTotalPrice(stagesValues.reduce((a, v) => (a = a + v.price), 0));
    setTotalDays(stagesValues.reduce((a, v) => (a = a + v.stage_duration), 0));
  };

  const onSubmitRequest = (values) => {
    if (totalPrice < 10000) {
      message.error('La propuesta no puede ser menor a $10.000');
      return;
    }
    confirm({
      title: `¿Estas seguro que quieres enviar tu Postulación?`,
      icon: <ExclamationCircleOutlined />,
      content:
        'Revisa que hayas añadido toda la información, así tendrás más oportunidades de ser seleccionado.',
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      onOk: async () => {
        const request = {
          emitter: authUser.user.id,
          project: currentProject.id,
          why_you: values.why_you,
          price: totalPrice,
        };
        let requestId = 0;
        if (currentRequest) {
          requestId = currentRequest.id;
        } else {
          const requestResponse = await addRequest(request);
          requestId = requestResponse.data.request.id;
        }
        const proposal = {
          stages: stagesValues.slice(0, nStages),
          emitter: authUser.user.id,
          text: values.description,
          request: requestId,
          request_why_you: values.why_you,
        };
        await addProposal(proposal)
          .then(() => {
            history.push(`${FREELANCER_REQUESTS_ROUTE}/${requestId}`);
            message.success('¡Tu propuesta ha sido creado exitosamente!');
          })
          .catch(() => {
            message.error('Tu propuesta no ha podido ser envidad correctamente');
          });
      },
    });
  };
  React.useEffect(() => {
    if (!isLoading && currentProposal) {
      const complementStages = Array.from(Array(5 - currentProposal.stages.length), () => ({
        name: '',
        price: '',
        stage_duration: '',
      }));
      setNStages(currentProposal.stages.length);
      setStagesValues([...currentProposal.stages, ...complementStages]);
      setTotalPrice(currentProposal.total_price);
      setTotalDays(currentProposal.total_duration);
    }
  }, [currentProposal]);
  return (
    <Fragment>
      <Form
        name="RequestForm"
        onFinish={onSubmitRequest}
        initialValues={{ why_you: currentRequest?.why_you, description: currentProposal?.text }}>
        <DescriptiveQuestions ownerName={currentProject.owner_name} />
        <StagesDefinition
          nStages={nStages}
          setNStages={setNStages}
          stagesValues={stagesValues}
          handleStageValueChange={handleStageValueChange}
          totalPrice={totalPrice}
          currentProject={currentProject}
          showDiscountWindow={showDiscountWindow}
          setShowDiscountWindow={setShowDiscountWindow}
          commission={commission}
          setCommission={setCommission}
          totalDays={totalDays}
          currentProposal={currentProposal}
          discountCode={discountCode}
        />
        <div className="request__submit-button">
          {' '}
          <Form.Item>
            {currentRequest && currentProposal ? (
              <Button text="Editar postulación" variant="primary" color="primary" />
            ) : (
              <Button text="Enviar postulación" variant="primary" color="primary" />
            )}
          </Form.Item>
        </div>
      </Form>
    </Fragment>
  );
}
