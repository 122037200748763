import React from 'react';
import css from './InformativeAdvice.module.scss';
import { Button, Text } from 'components/common';

const InformativeAdvice = ({ image, title, message, buttonText, buttonOnClick, paragraphs }) => {
  return (
    <div className={css.container}>
      <img src={image} />
      <div className={css.content}>
        {' '}
        <div className={css.title}>
          {' '}
          <Text type="strong">{title}</Text>
        </div>
        <div className={css.paragraphs}>
          {paragraphs &&
            paragraphs.map((pgph, index) => {
              return (
                <Text type="detail" key={`pgph-${index}`}>
                  {pgph}
                </Text>
              );
            })}
        </div>
        {message && (
          <Text type="detail" centered>
            {message}
          </Text>
        )}
        {buttonText && (
          <Button
            text={buttonText}
            variant="secondary"
            borderColor="primary"
            size="sm"
            onClick={() => buttonOnClick()}
          />
        )}
      </div>
    </div>
  );
};

export default InformativeAdvice;
