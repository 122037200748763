import React from 'react';
import Modification from './Modification';
import Integration from './Integration';
import Error from './Error';
import Migration from './Migration';
import css from './IntegrationDetails.module.css';

function IntegrationDetails({ currentProject }) {
  const getNeededComponent = (neededType) => {
    if (neededType === 'Necesito modificar o mejorar mi sitio web') {
      return <Modification currentProject={currentProject} />;
    } else if (neededType === 'Necesito una integración') {
      return <Integration currentProject={currentProject} />;
    } else if (neededType === 'Tengo un error o problema en mi sitio web') {
      return <Error currentProject={currentProject} />;
    } else if (neededType === 'Necesito migrar mi sitio') {
      return <Migration currentProject={currentProject} />;
    }
  };
  return <div className={css.container}>{getNeededComponent(currentProject.needed_type)}</div>;
}

export default IntegrationDetails;
