/* eslint-disable no-unused-vars */
import React from 'react';
import { ManagementCardLayout, SkillsBeans, Text, Title, Button } from 'components/common';
import css from '../FreelanceProfile.module.scss';
import ProfilePicture from 'components/common/ProfilePicture/ProfilePicture';

function PublicFreelanceCard({ freelance, setContactDrawer, authUser }) {
  return (
    <ManagementCardLayout>
      <div className={css.greenHeader}></div>
      <ManagementCardLayout.LeftSide>
        <div className={css.leftSide}>
          <div style={{ padding: '1em' }}>
            <ProfilePicture user={freelance} width={100} withEditButton={false} />
          </div>
        </div>
      </ManagementCardLayout.LeftSide>

      <ManagementCardLayout.RightSide>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            maxWidth: 350,
            padding: 20,
          }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <Title type="tertiary" color="secondary">
              {freelance?.first_name}
            </Title>
          </div>
          <Title type="tertiary">
            {freelance?.profession ? freelance?.profession : 'No especificada'}
          </Title>
          <SkillsBeans skills={freelance?.skills_names} />

          <div style={{ display: 'flex', marginTop: '1em' }}>
            {freelance?.github_link && (
              <div>
                <a
                  id="view-profile__linked"
                  href={freelance?.github_link}
                  target="_blank"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginRight: '1em',
                  }}
                  rel="noreferrer">
                  <i className="fab fa-github" style={{ fontSize: 22 }}></i>
                  <Text type="strong">Github</Text>
                </a>
              </div>
            )}
            {freelance?.briefcase_link && (
              <div>
                <a
                  id="view-profile__linked-briefcase"
                  href={freelance?.briefcase_link}
                  target="_blank"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginRight: '1em',
                  }}
                  rel="noreferrer">
                  <i className="fa fa-briefcase"></i>
                  <Text type="strong">Portafolio</Text>
                </a>
              </div>
            )}
            {freelance?.stackoverflow_link && (
              <div>
                <a
                  id="view-profile__linked"
                  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                  href={freelance?.stackoverflow_link}
                  target="_blank"
                  rel="noreferrer">
                  <i className="fab fa-stack-overflow" style={{ fontSize: 22 }}></i>
                  <Text type="strong">Stackoverflow</Text>
                </a>
              </div>
            )}
          </div>
          <Text type="detail" style={{ margin: 0 }}>
            Proyectos completados: {freelance?.completed_works_collaborator}
          </Text>
        </div>
      </ManagementCardLayout.RightSide>

      <ManagementCardLayout.Buttons>
        <div className={css.buttons}>
          {setContactDrawer && (
            <Button
              text={'Contactar'}
              variant="primary"
              color="secondary"
              onClick={() => setContactDrawer(true)}
              disabled={!authUser.isAuthenticated}
            />
          )}
        </div>
      </ManagementCardLayout.Buttons>
      <ManagementCardLayout.Bottom></ManagementCardLayout.Bottom>
    </ManagementCardLayout>
  );
}

export default PublicFreelanceCard;
