import { getUserMedals } from 'actions/users';
import { useEffect } from 'react';
import { useState } from 'react';

const MEDALS_URL = 'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/medals-files';

/* eslint-disable no-unused-vars */
const useMedals = ({ userId, medals }) => {
  const [userMedalsData, setUserMedalsData] = useState(null);
  useEffect(() => {
    if (medals && userId) {
      setUserMedalsData(medals);
    } else if (userId) {
      getUserMedals(userId)
        .then((res) => {
          setUserMedalsData(res?.data?.objects[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [userId, medals]);

  const get1To50 = (num) => {
    if (num >= 1 && num < 5) num = 1;
    if (num >= 5 && num < 10) num = 5;
    if (num >= 10 && num < 20) num = 10;
    if (num >= 20 && num < 50) num = 20;
    if (num >= 50) num = 50;

    return num;
  };
  const get10To50 = (num) => {
    if (num >= 10 && num < 50) num = 10;
    if (num >= 50) num = 50;

    return num;
  };
  const mmsGeneratedMedal = () => {
    let num = userMedalsData?.mm_generated_int;

    if (num === 0 || !num) return null;

    num = get1To50(num);

    return {
      tooltip: `${num} Millones Generados`,
      image: `${MEDALS_URL}/${num}+millon+freelance.png`,
    };
  };

  const completedProjectsMedal = () => {
    let num = userMedalsData?.n_completed_projects;

    if (num === 0 || !num) return null;

    num = get1To50(num);

    return {
      tooltip: `${num} Proyectos Completados`,
      image: `${MEDALS_URL}/${num}+proyecto+freelance.png`,
    };
  };

  const hiredProjectsMedal = () => {
    let num = userMedalsData?.n_hired_projects;

    if (num === 0 || !num) return null;

    num = get1To50(num);

    return {
      tooltip: `${num} Proyectos Contratados`,
      image: `${MEDALS_URL}/${num}+proyecto+cliente.png`,
    };
  };

  const perfectPunctualityMedal = () => {
    let num = userMedalsData?.perfect_punctuality;

    if (num < 10 || !num) return null;

    num = get10To50(num);

    return {
      tooltip: `${num} Calificaciones de 5 Estrellas a la Puntualidad`,
      image: `${MEDALS_URL}/${num}+puntualidad+freelance.png`,
    };
  };

  const perfectQualityMedal = () => {
    let num = userMedalsData?.perfect_quality;

    if (num < 10 || !num) return null;

    num = get10To50(num);

    return {
      tooltip: `${num} Calificaciones de 5 Estrellas a la Calidad`,
      image: `${MEDALS_URL}/${num}+calidad+freelance.png`,
    };
  };

  const perfectComunicationMedal = () => {
    let num = userMedalsData?.perfect_communication;

    if (num < 10 || !num) return null;

    num = get10To50(num);

    return {
      tooltip: `${num} Calificaciones de 5 Estrellas a la Comunicación`,
      image: `${MEDALS_URL}/${num}+comunicacion+freelance.png`,
    };
  };

  const CMSProjectsMedal = () => {
    let num = userMedalsData?.n_cms_projects;

    if (num < 10 || !num) return null;

    num = get10To50(num);

    return {
      tooltip: `${num} Proyectos CMS Completados`,
      image: `${MEDALS_URL}/${num}+cms+freelance.png`,
    };
  };

  const customDevelopmentMedal = () => {
    let num = userMedalsData?.n_custom_development;

    if (num < 10 || !num) return null;

    num = get10To50(num);

    return {
      tooltip: `${num} Proyectos a la Medida Completados`,
      image: `${MEDALS_URL}/${num}+a+medida+freelance.png`,
    };
  };

  const appMobileMedal = () => {
    let num = userMedalsData?.n_movil_app;

    if (num < 10 || !num) return null;

    num = get10To50(num);

    return {
      tooltip: `${num} Proyectos Mobile Completados`,
      image: `${MEDALS_URL}/${num}+app+movil+freelance.png`,
    };
  };

  const dataModelMedals = () => {
    let num = userMedalsData?.n_data_model;

    if (num < 10 || !num) return null;

    num = get10To50(num);

    return {
      tooltip: `${num} Proyectos de Datos y Modelos Completados`,
      image: `${MEDALS_URL}/${num}+datos+y+modelos+freelance.png`,
    };
  };

  const getMedals = () => {
    if (!userMedalsData) return null;

    const medals = [
      mmsGeneratedMedal(),
      completedProjectsMedal(),
      hiredProjectsMedal(),
      perfectPunctualityMedal(),
      perfectQualityMedal(),
      perfectComunicationMedal(),
      CMSProjectsMedal(),
      customDevelopmentMedal(),
      appMobileMedal(),
      dataModelMedals(),
    ];

    return medals.filter((medal) => medal !== null);
  };

  return {
    getMedals,
  };
};

export default useMedals;
