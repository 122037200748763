export const monthsToName = {
  ['1']: 'Ene.',
  ['2']: 'Feb.',
  ['3']: 'Mar.',
  ['4']: 'Abr.',
  ['5']: 'May.',
  ['6']: 'Jun.',
  ['7']: 'Jul.',
  ['8']: 'Ago.',
  ['9']: 'Sept.',
  ['10']: 'Oct.',
  ['11']: 'Nov.',
  ['12']: 'Dic.',
};
export const STATUS = {
  PROJECT_START: 1,
  STAGE_START: 2,
  STAGE_COMPLETED: 3,
  PROJECT_COMPLETED: 4,
};
export const datesWithStatus = [
  {
    date: '2022-10-03',
    status: 1,
    title: 'Inicio del proyecto',
  },
  {
    date: '2022-11-25',
    status: 4,
    title: 'Fin del proyecto',
  },
  {
    date: '2022-10-03',
    status: 2,
    title: 'Inicio de Diseno web',
  },
  {
    date: '2022-10-24',
    status: 3,
    title: 'Fin de Diseno web',
  },
  {
    date: '2022-10-24',
    status: 2,
    title: 'Inicio de Desarrollo',
  },
  {
    date: '2022-11-25',
    status: 3,
    title: 'Fin de Desarrollo',
  },
];
