import React, { Component, Fragment } from 'react';
import { image64toCanvasRef, dataURItoBlob } from './ImageFunctions';
import { Upload, message } from 'antd';
import ReactCrop from 'react-image-crop';
import './UploadProfileImageModal.scss';
import { MAIN_CATEGORIES, MAIN_CATEGORIES_ONE_WORD } from '@common/constants/categories.constants';
import { DEFAULT_IMAGES_URL } from '@common/constants/enviroment.constants';
import { Button, Title } from '@components/common';
import { editUser, updateProfileImage } from '@actions/users';
import { connect } from 'react-redux';

export class UploadProfileImageModal extends Component {
  constructor(props) {
    super(props);
    this.imagePreviewCanvasRef = React.createRef();
    this.state = {
      selectedFile: false,
      tempFile: null,
      width: 0,
      crop: {
        aspect: 1 / 1,
        unit: 'px',
        x: 0,
        y: 0,
        width: 472,
        height: 472,
      },
      selectAvatar: false,
      selectedAvatar: 1,
      selectImage: false,
      active_category: 'Administracion',
    };
  }

  handleImageLoaded = (image) => {
    this.imageRef = image;
  };

  handleOnCropChange = (crop) => {
    this.setState({ crop: crop });
  };

  handleOnCropComplete = (crop) => {
    const canvasRef = this.imagePreviewCanvasRef.current;
    image64toCanvasRef(canvasRef, this.state.tempFile, crop);
  };

  handleUpload = () => {
    const { selectAvatar, selectedAvatar } = this.state;

    if (selectAvatar) {
      this.props.editUser(this.props.user.id, {
        default_image: selectedAvatar,
        is_picture_default: true,
      });

      this.setState({ selectedFile: false, selectAvatar: false });
    } else {
      const canvasRef = this.imagePreviewCanvasRef.current;
      const blob = dataURItoBlob(canvasRef.toDataURL());
      let file = new File([blob], 'a.png', {
        lastModified: new Date(),
        type: 'image/png',
      });
      const formData = new FormData();
      formData.append('file', file);
      formData.append('owner', this.props.user.id);
      this.setState({ uploading: true });

      updateProfileImage(formData)
        .then(() => {
          this.setState({
            selectedFile: false,
            tempFile: URL.createObjectURL(file),
            uploading: false,
          });
          this.props.editUser(this.props.user.id, {
            is_picture_default: false,
          });

          message.success('Tu archivo se subió correctamente.');
        })
        .catch(() => {
          this.setState({ uploading: false });
          message.error('La carga de tu imagen falló.');
        });
    }
    this.props.setProfileImageModal(false);
  };

  selectAvatar = (index) => {
    this.setState({ selectedAvatar: index, selectedFile: true });
  };

  chooseAvatar = () => {
    const { selectedAvatar, active_category } = this.state;
    const list_colors = ['verde', 'amarillo', 'rojo', 'azul'];
    const list_sex = ['H', 'M'];

    return (
      <div>
        <div className="choose-category-label-text">Elige una categoría</div>
        <div className="categories-container">
          {[...Array(2).keys()].map((index) => (
            <div className="category-container" key={`cat-${index}`}>
              <Button
                text={MAIN_CATEGORIES[index]}
                variant="category"
                onClick={() =>
                  this.setState({
                    active_category: `${MAIN_CATEGORIES_ONE_WORD[index]}`,
                  })
                }
                active={this.state.active_category === MAIN_CATEGORIES_ONE_WORD[index]}
              />
            </div>
          ))}
        </div>
        <div>
          <div className="choose-category-label-text">Elige un avatar</div>
          {list_sex.map((sex, index) => (
            <Fragment key={`gender-${index}`}>
              {list_colors.map((color, index) => (
                <Fragment key={`color-${index}`}>
                  <button
                    className={`avatar-image-modal ${
                      selectedAvatar === `${active_category}_${color}_${sex}`
                        ? `avatar-selected-${color}`
                        : ''
                    }`}
                    onClick={() => this.selectAvatar(`${active_category}_${color}_${sex}`)}>
                    <img
                      src={`${DEFAULT_IMAGES_URL}/${active_category}_${color}_${sex}.png`}
                      alt={`${active_category}_${color}_${sex}`}
                      className="image-container"></img>
                  </button>
                </Fragment>
              ))}
            </Fragment>
          ))}
          <hr />
          <div className="upload-button-container">
            <Button text="Elegir" variant="primary" onClick={this.handleUpload} />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { uploading, selectedFile, tempFile, crop, selectAvatar } = this.state;
    const props = {
      accept: '.jpeg,.jpg,.png',
      onRemove: () => {
        this.setState(() => {
          return {
            selectedFile: false,
          };
        });
      },
      beforeUpload: (file) => {
        this.setState(() => ({
          selectedFile: true,
          tempFile: URL.createObjectURL(file),
        }));
        return false;
      },
      selectedFile,
    };

    return (
      <div className="prifile-image-container">
        <button
          onClick={() => this.setState({ selectedFile: false, selectAvatar: false })}
          className={`close-btn ${selectedFile ? '' : selectAvatar ? '' : 'canvas-hided'}`}>
          <i className="fas fa-angle-left"></i>
        </button>
        {selectAvatar ? (
          <div>{this.chooseAvatar()}</div>
        ) : selectedFile ? (
          <div>
            <div className="choose-category-label-text">Redimensiona la imagen si lo deseas</div>
            <ReactCrop
              src={tempFile}
              crop={crop}
              onImageLoaded={this.handleImageLoaded}
              onChange={this.handleOnCropChange}
              onComplete={this.handleOnCropComplete}
              className="crop-image-modal"
            />
            <canvas ref={this.imagePreviewCanvasRef} className="canvas-hided"></canvas>
            <hr />
            <div className="upload-button-container">
              <Button
                text={uploading ? 'Subiendo' : 'Subir'}
                variant="primary"
                onClick={this.handleUpload}
              />
            </div>
          </div>
        ) : (
          <div>
            <Title className="edit-picture-title" size="25" type="primary" color="main">
              Editar foto
            </Title>
            <img
              src={`${this.props.user.profile_image}?${Date.now()}`}
              alt="Foto"
              className="crop-image-modal"></img>
            <hr />
            <div className="upload-image-button-container">
              <div className="choose-avatar-button">
                <Button
                  text="Elige tu avatar"
                  variant="secondary"
                  onClick={() => this.setState({ selectImage: false, selectAvatar: true })}
                />
              </div>
              <div className="choose-avatar-button">
                <Upload {...props}>
                  <Button
                    text="Cargar imagen"
                    variant="secondary"
                    onClick={() => this.setState({ selectAvatar: false })}
                  />
                </Upload>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  editUser,
})(UploadProfileImageModal);
