/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useEffect } from 'react';
import useSearchSkills from './useSearchSkills';

const useNewPrevProject = ({ project, handleEdit, handleCreate }) => {
  const { skillsData, fetching, fetchSkills } = useSearchSkills();
  const [myPortada, setMyPortada] = useState(null);
  const [myGalery, setMyGalery] = useState([]);
  const [mySkills, setMySkills] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const [clearImages, setClearImages] = useState(false);
  const [imagesError, setImagesError] = useState(false);
  const uploadGalery = (e, setFieldValue, values) => {
    setFieldValue(`galerias`, [...values.galerias, e.target.files[0]]);
  };
  const onDeleteGalery = (i, setFieldValue, values, type) => {
    if (type === 'myGalery') {
      setMyGalery(myGalery.filter((img, index) => index !== i));
    } else {
      setFieldValue(
        `galerias`,
        values.galerias.filter((img, index) => index !== i)
      );
    }
  };
  const onDeletePortada = (setFieldValue) => {
    setFieldValue('portada', '');
    setMyPortada(null);
  };
  const handleSubmit = async (values, resetForm, setClearImages) => {
    if (
      (values.galerias.length === 0 && myGalery.length === 0) ||
      (values.portada === '' && myPortada === null)
    ) {
      setImagesError(true);
      return;
    }
    setImagesError(false);

    if (project) {
      let fullValues = {
        ...values,
        oldGalery: myGalery.map((item) => item.id),
        oldPortada: myPortada,
      };
      await handleEdit(fullValues);
    } else {
      await handleCreate(values);
    }
    resetForm();
    setClearImages((prev) => !prev);
  };

  const handleSetSkills = () => {
    if (project?.skills?.length > 0) {
      let skills = project?.skills?.map((id, index) => {
        return { value: id, label: project?.skills_names[index], key: id };
      });
      setMySkills(skills);
    }
  };
  const handleSetImages = () => {
    setMyPortada(project?.front_image);
    if (project?.gallery_images?.length > 0) {
      setMyGalery(project?.gallery_images);
    }
  };
  const initializeUpdateForm = () => {
    handleSetImages();
    handleSetSkills();
    setInitialized(true);
  };
  const initializeCreateForm = () => {
    setMyGalery([]);
    setMyPortada(null);
    setInitialized(true);
  };
  useEffect(() => {
    setInitialized(false);
    if (project) {
      initializeUpdateForm();
    } else {
      initializeCreateForm();
    }
  }, [project]);

  return {
    initialized,
    mySkills,
    handleSubmit,
    myPortada,
    setMyPortada,
    myGalery,
    uploadGalery,
    setMyGalery,
    skillsData,
    fetching,
    fetchSkills,
    setClearImages,
    clearImages,
    onDeleteGalery,
    onDeletePortada,
    imagesError,
  };
};

export default useNewPrevProject;
