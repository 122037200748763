import React from 'react';
import './BankData.scss';
import { MoneyFormatter } from '@common/utils/Formaters';
import { ToolTip } from '@components/common/ToolTip';
import { BUSINESS_ACCOUNT } from '@common/constants/business.constants';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ExternalLink } from 'react-feather';
import { message } from 'antd';
import { Text } from '@components/common';

export const BankData = ({ totalPayment, confirmationCode }) => {
  const clipValue = `${BUSINESS_ACCOUNT}\nTotal a pagar: $${totalPayment}\nCódigo de confirmación: ${confirmationCode}`;

  return (
    <div className="bank-data__container">
      <div className="bank-data__copy-container">
        <CopyToClipboard text={clipValue} onCopy={() => message.success('Datos Copiados')}>
          <button className="clipboard-button">
            <ExternalLink />
          </button>
        </CopyToClipboard>
      </div>

      <div className="bank-data__row">
        <Text type="smallBoldBlue">Total a pagar: </Text>
        <Text type="smallBoldDetail">{MoneyFormatter(totalPayment)}</Text>
      </div>
      <div className="bank-data__row">
        <Text type="smallBoldBlue">Nombre: </Text>
        <Text type="smallBoldDetail">Te Necesito SPA</Text>
      </div>
      <div className="bank-data__row">
        <Text type="smallBoldBlue">Banco: </Text>
        <Text type="smallBoldDetail">Santander</Text>
      </div>
      <div className="bank-data__row">
        <Text type="smallBoldBlue">Tipo de cuenta: </Text>
        <Text type="smallBoldDetail">Cuenta Corriente</Text>
      </div>
      <div className="bank-data__row">
        <Text type="smallBoldBlue">Número de cuenta: </Text>
        <Text type="smallBoldDetail">75840187</Text>
      </div>
      <div className="bank-data__row">
        <Text type="smallBoldBlue">RUT: </Text>
        <Text type="smallBoldDetail">77.146.172-7</Text>
      </div>
      <div className="bank-data__row">
        <Text type="smallBoldBlue">Email: </Text>
        <Text type="smallBoldDetail">pagos@tenecesito.cl</Text>
      </div>
      <div className="bank-data__row">
        <div className="bank-data__confirmation">
          {' '}
          <ToolTip
            className="payment-validation-tooltip"
            text="Añade este codigo al comentario de tu transferencia para que podamos confirmar tu pago!"
          />
          <Text type="smallBoldBlue">Código de confirmación: </Text>
          <Text type="smallBoldDetail">{confirmationCode}</Text>
        </div>
      </div>
    </div>
  );
};
