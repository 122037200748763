/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Card } from 'antd';
import { categoriesNames } from 'common/utils/CardsUtils';
import { DropdownMenuItem, SkillsBeans, Text, Title } from 'components/common';
import { selectAuthUserInfo } from 'reducers/auth';
import { useSelector } from 'react-redux';
import css from './MyServices.module.scss';
import StandardModal from 'components/common/StandardModal';

const MyServicesCard = ({
  service,
  isPopup,
  handleDeleteService,
  handleSelectService,
  ...rest
}) => {
  const user = useSelector(selectAuthUserInfo);

  const [showPopup, setShowPopup] = React.useState(false);

  const [showMoreDescription, setShowMoreDescription] = React.useState(true);
  const [showMoreExperience, setShowMoreExperience] = React.useState(true);

  const cardActions = [
    {
      label: 'Editar',
      callback: () => handleSelectService(service),
    },
    {
      label: 'Eliminar',
      callback: () => handleDeleteService(service?.id),
    },
  ];

  useEffect(() => {
    if (service?.description?.length < 200) {
      setShowMoreDescription(false);
    }
    if (service?.experience?.length < 200) {
      setShowMoreExperience(false);
    }
  }, [service]);

  return (
    <>
      {service && (
        <>
          {service?.owner === user?.id && (
            <div className={css.greenHeader}>
              <div className="publication-card__edit">
                <DropdownMenuItem hoverButton="" options={cardActions} />
              </div>
            </div>
          )}

          <Card
            className={service?.owner === user?.id ? css.serviceCardOwner : css.serviceCard}
            onClick={() => setShowPopup(true)}>
            <Title className="project-title-text" color="secondary">
              {service?.title}
            </Title>
            <Text type="strong">{categoriesNames(service?.categories_names)}</Text>
            <div className="row service-card-skill-list-container" style={{ padding: '0 15px' }}>
              <SkillsBeans skills={service?.skills_names} />
            </div>
            {!isPopup && (
              <>
                <div style={{ margin: '2em 0 0 0' }}>
                  <Title className="project-title-text" type="tertiary" style={{ fontWeight: 900 }}>
                    Descripción
                  </Title>
                  <Text>
                    {service?.description?.slice(
                      0,
                      showMoreDescription ? 200 : service?.description?.length
                    )}
                    <span
                      className={css.show_more}
                      onClick={() => setShowMoreDescription((prev) => !prev)}
                      style={{ marginLeft: 5 }}>
                      {showMoreDescription ? 'Ver más...' : 'Ver menos'}
                    </span>
                  </Text>
                </div>
                <div style={{ margin: '1em 0 0 0' }}>
                  <Title className="project-title-text" type="tertiary" style={{ fontWeight: 900 }}>
                    Experiencia
                  </Title>
                  <Text>
                    {service?.experience?.slice(
                      0,
                      showMoreExperience ? 200 : service?.description?.length
                    )}
                    <span
                      className={css.show_more}
                      onClick={() => setShowMoreExperience((prev) => !prev)}
                      style={{ marginLeft: 5 }}>
                      {showMoreExperience ? 'Ver más...' : 'Ver menos'}
                    </span>
                  </Text>
                </div>
              </>
            )}
          </Card>
        </>
      )}
      {isPopup && (
        <StandardModal visible={showPopup} onCancel={() => setShowPopup(false)} width={768}>
          <Title className="project-title-text" color="secondary">
            {service?.title}
          </Title>
          <Text type="strong">{categoriesNames(service?.categories_names)}</Text>
          <div className="row service-card-skill-list-container">
            {service?.skills_names?.map((skill) => (
              <div
                className="service-card-skill-list-element"
                key={skill}
                style={{ marginLeft: 0, marginRight: '5px' }}>
                {skill}
              </div>
            ))}
          </div>
          <div style={{ margin: '2em 0 0 0' }}>
            <Title className="project-title-text" color="secondary" type="tertiary">
              Descripción
            </Title>
            <Text>{service?.description}</Text>
          </div>
          <div style={{ margin: '1em 0 0 0' }}>
            <Title className="project-title-text" color="secondary" type="tertiary">
              Experiencia
            </Title>
            <Text>{service?.experience}</Text>
          </div>
          <div style={{ margin: '1em 0 0 0' }}>
            <Title className="project-title-text" color="secondary" type="tertiary">
              Disponibilidad
            </Title>
            <Text>{service?.availability}</Text>
          </div>
        </StandardModal>
      )}
    </>
  );
};

export default MyServicesCard;
