import { inviteColabToProject_v2 } from 'actions/services';
import { message } from 'antd';
import { useState } from 'react';

const useSuggestions = ({ currentProject }) => {
  const [isInviting, setIsInviting] = useState(false);
  const [inviteds, setInviteds] = useState([]);
  const handleMaxSkills = (skills) => {
    if (skills?.length > 2) {
      return skills.slice(0, 2).concat(`+${skills.length - 2}`);
    } else {
      return skills;
    }
  };
  const handleInvite = async (item) => {
    setIsInviting(true);
    inviteColabToProject_v2({ projectId: currentProject.id, userId: item.owner })
      .then(() => {
        message.success('¡Se le envió una invitación al freelancer correctamente!');
        setInviteds([...inviteds, item.owner_id]);
        setIsInviting(false);
      })
      .catch(() => setIsInviting(false));
  };
  const isAlreadyInvited = (item) => {
    return inviteds.includes(item.owner_id);
  };
  return {
    handleInvite,
    handleMaxSkills,
    isInviting,
    isAlreadyInvited,
  };
};

export default useSuggestions;
