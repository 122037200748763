/* eslint-disable no-unused-vars */
import React from 'react';
import { getMainCategories_v2, getSubCategories_v2 } from '@actions/categories';
import { searchSkills_v2 } from '@actions/skills';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'reducers/auth';
import { registerService_v2, updateService_v2 } from 'actions/services';
import { ModalConfirm } from 'components/common/ModalConfirm';
import { useDispatch } from 'react-redux';
import { createMessage } from 'actions/messages';
import { serviceMessages } from './ServicesForm.constants';
import {
  serviceAdapterFromApiToUi,
  serviceAdapterFromUiToApi,
} from 'common/adapters/service.adapter';
import { FREELANCER_PUBLICATIONS_ROUTE } from 'common/constants/routes.constants';

export const ServicesFormViewModel = ({ afterSubmit, serviceToUpdate }) => {
  const authUser = useSelector(selectAuthUser);
  const dispatch = useDispatch();
  const [mainCategories, setMainCategories] = React.useState(null);
  const [subCategories, setSubCategories] = React.useState(null);
  const [searchedSkills, setSearchedSkills] = React.useState(null);

  const [serviceTitle, setServiceTitle] = React.useState(null);
  const [mainCategorySelectedID, setMainCategorySelectedID] = React.useState(null);
  const [subCategoriesSelectedsID, setSubCategoriesSelectedsID] = React.useState([]);
  const [serviceDescription, setServiceDescription] = React.useState(null);
  const [serviceExperience, setServiceExperience] = React.useState(null);
  const [skillsSelecteds, setSkillsSelecteds] = React.useState([]);
  const [serviceDisponibility, setServiceDisponibility] = React.useState(null);

  const [isLoading, setIsLoading] = React.useState(false);

  const [showVerifyIdentity, setShowVerifyIdentity] = React.useState(false);

  const [serviceId, setServiceId] = React.useState(null);

  const doneVerification = () => {
    setShowVerifyIdentity(false);
  };

  const handlePushSubCategories = (value) => {
    if (value.length > 3) return;
    setSubCategoriesSelectedsID(value);
  };
  const handlePushSkills = (value) => {
    if (value.length > 5) return;
    setSkillsSelecteds(value);
  };
  const fetchSkills = (value) => {
    searchSkills_v2({ variable: value })
      .then(({ data }) => setSearchedSkills(data))
      .catch(() => setSearchedSkills(null));
  };
  const handleSendForm = () => {
    const uiService = {
      title: serviceTitle,
      description: serviceDescription,
      availability: serviceDisponibility,
      experience: serviceExperience,
      skillsRequired: skillsSelecteds,
      mainCategory: mainCategorySelectedID,
      subCategories: subCategoriesSelectedsID,
    };
    const service = serviceAdapterFromUiToApi(uiService);
    if (serviceToUpdate) {
      updateService_v2({ id: serviceId, service })
        .then(() => {
          dispatch(createMessage({ successMessage: serviceMessages.updateService.success }));
          afterSubmit();
        })
        .catch(() => {
          dispatch(
            createMessage({
              errorMessage: serviceMessages.updateService.error,
            })
          );
        });
    } else {
      registerService_v2({ service })
        .then(() => {
          dispatch(createMessage({ successMessage: serviceMessages.createService.success }));
          afterSubmit();
        })
        .catch(() => {
          dispatch(
            createMessage({
              errorMessage: serviceMessages.createService.error,
            })
          );
        });
    }
  };
  const handleSubmit = () => {
    if (authUser?.user.is_identity_verified && authUser?.user.phone_number) {
      ModalConfirm({
        title: serviceMessages.publicationModalConfirm.title,
        content: serviceMessages.publicationModalConfirm.content,
        onOk: handleSendForm,
        onCancel: () => null,
      });
    } else {
      setShowVerifyIdentity(true);
    }
  };
  const setServiceToUpdate = async (service) => {
    try {
      const {
        title,
        description,
        availability,
        experience,
        mainCategory,
        subCategories,
        skillsRequired,
      } = await serviceAdapterFromApiToUi(service);

      setServiceTitle(title);
      setServiceDescription(description);
      setServiceDisponibility(availability);
      setServiceExperience(experience);
      setMainCategorySelectedID(mainCategory);
      setSubCategoriesSelectedsID(subCategories);
      setSkillsSelecteds(skillsRequired);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    setIsLoading(true);
    getMainCategories_v2()
      .then(({ data }) => setMainCategories(data))
      .catch(() => setMainCategories(null))
      .finally(() => setIsLoading(false));
  }, []);
  React.useEffect(() => {
    if (serviceToUpdate) {
      setIsLoading(true);
      setServiceId(serviceToUpdate.id);
      setServiceToUpdate(serviceToUpdate);
    } else {
      setServiceTitle(null);
      setServiceDescription(null);
      setServiceDisponibility(null);
      setServiceExperience(null);
      setMainCategorySelectedID(null);
      setSubCategoriesSelectedsID([]);
      setSkillsSelecteds([]);
    }
  }, [serviceToUpdate]);
  React.useEffect(() => {
    if (mainCategorySelectedID) {
      getSubCategories_v2(mainCategorySelectedID)
        .then(({ data }) => setSubCategories(data))
        .catch(() => {
          setSubCategories(null);
          setSubCategoriesSelectedsID([]);
        });
    }
  }, [mainCategorySelectedID]);
  return {
    mainCategories,
    mainCategorySelectedID,
    setMainCategorySelectedID,
    subCategories,
    searchedSkills,
    serviceTitle,
    setServiceTitle,
    subCategoriesSelectedsID,
    serviceDescription,
    setServiceDescription,
    serviceExperience,
    setServiceExperience,
    skillsSelecteds,
    serviceDisponibility,
    setServiceDisponibility,
    handlePushSubCategories,
    handlePushSkills,
    fetchSkills,
    handleSubmit,
    isLoading,
    authUser,
    doneVerification,
    showVerifyIdentity,
    setShowVerifyIdentity,
  };
};
