import { createMessage } from 'actions/messages';
import { deleteService_v2, getUserServices } from 'actions/services';
import { ModalConfirm } from 'components/common/ModalConfirm';
import { serviceMessages } from 'pages/ServicesForm/ServicesForm.constants';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const useMyServices = ({ user }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [services, setServices] = useState([]);
  const [refetch, setRefetch] = useState(false);
  useEffect(() => {
    getUserServices(user.id).then((res) => {
      setServices(res?.data?.objects);
    });
  }, [refetch]);

  const handleDeleteService = (id) => {
    ModalConfirm({
      title: serviceMessages.deleteModalConfirm.title,
      content: serviceMessages.deleteModalConfirm.content,
      onOk: () => {
        deleteService_v2({ id })
          .then(() => {
            dispatch(createMessage({ successMessage: serviceMessages.deleteService.success }));
            setRefetch((prev) => !prev);
          })
          .catch(() => {
            dispatch(
              createMessage({
                errorMessage: serviceMessages.deleteService.error,
              })
            );
          });
      },
      onCancel: () => null,
    });
  };

  const handleGoToUpdateService = (id) => {
    history.push(`/updateService/${id}`);
  };

  return {
    services,
    setRefetch,
    handleDeleteService,
    handleGoToUpdateService,
  };
};

export default useMyServices;
