/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

import { Send } from 'react-feather';
import { useDispatch, useSelector } from 'react-redux';

import { Tooltip, useMediaQuery } from '@material-ui/core';
import { Videocam } from '@material-ui/icons';

import { createMessage } from 'actions/messages';
import { videoCallSchedule } from 'actions/chat';

import { FileIcon, Text } from '..';
import { LoadingSpin } from '../LoadingSpin';
import RenderIf from '../RenderIf';
import StandardModal from '../StandardModal';
import UploadClip from './UploadClip';
import useNewChat from './useNewChat';
import VideoModalContent from './VideoModalContent';

import './NewChat.scss';
import { selectAuthUserInfo } from 'reducers/auth';
import VideoCallModal from './VideoCallModal';
const NewChat = ({
  chatID,
  archivos,
  handleGetFiles,
  currentProject,
  participants,
  chatHeight = 405,
  noFiles = false,
  disabled = false,
  ...rest
}) => {
  const {
    onChatScroll,
    isNewPage,
    messageHistory,
    isOwnerOfMessage,
    stringMessageToArray,
    message,
    onUpload,
    onSend,
    handleChange,
    handleKeyPress,
    handleClickSendMessage,
  } = useNewChat({ chatID, handleGetFiles });
  const user = useSelector(selectAuthUserInfo);
  const dispatch = useDispatch();
  const hiddeFiles = useMediaQuery('(max-width: 1023px)');
  const [tab, setTab] = useState(0);
  const [videoModal, setVideoModal] = useState(false);
  const toggleChat = () => {
    setTab(0);
  };
  const toggleArchivos = () => {
    setTab(1);
  };
  const handleSubmitVideoCall = (values, resetForm) => {
    const invited = participants.filter((id) => id !== user?.id)[0];

    videoCallSchedule({ ...values, project: currentProject?.id, host: user?.id, invited }).then(
      (res) => {
        resetForm();

        setVideoModal(false);

        const day = values?.start_time?.split('T')[0]?.split('-')[2];
        const month = values?.start_time?.split('T')[0]?.split('-')[1];

        const hour = values?.start_time?.split('T')[1]?.split(':')[0];
        const minutes = values?.start_time?.split('T')[1]?.split(':')[1];

        onSend(
          `${user?.first_name} agendó una videollamada el dia ${day}/${month} a las ${hour}:${minutes}.\nRecibirán un correo electronico con el link de la reunión.`
        );

        dispatch(
          createMessage({
            successMessage:
              'Se agendó una reunión en zoom, los participantes recibirán la invitación por correo electronico',
          })
        );
      }
    );
  };
  return (
    <>
      <VideoCallModal
        handleSubmitVideoCall={handleSubmitVideoCall}
        toggle={videoModal}
        setToggle={setVideoModal}
      />
      <div className="new_chat_section">
        <div className="wrapper" {...rest}>
          <RenderIf isTrue={hiddeFiles && !noFiles}>
            <div className="chat-tabs">
              <p onClick={toggleChat} className={tab === 0 ? 'active' : ''}>
                Chat
              </p>
              <p onClick={toggleArchivos} className={tab === 1 ? 'active' : ''}>
                Archivos
              </p>
            </div>
            <hr />
          </RenderIf>

          <main>
            <RenderIf isTrue={tab === 0}>
              <div className="col">
                <div className="col-content">
                  <section
                    className="message"
                    onScroll={onChatScroll}
                    id="new_chat_messages_container"
                    style={{ height: chatHeight }}>
                    <div className="grid-message">
                      {isNewPage && (
                        <div style={{ textAlign: 'center', width: '100%' }}>
                          <LoadingSpin />
                        </div>
                      )}

                      {messageHistory?.map((msg, idx) => (
                        <div
                          className={`col-message-${isOwnerOfMessage(msg) ? 'sent' : 'received'}`}
                          key={idx}>
                          <div
                            className={`message-${isOwnerOfMessage(msg) ? 'sent' : 'received'}`}
                            style={{ maxWidth: 300, wordBreak: 'break-word' }}>
                            <RenderIf isTrue={msg?.file_id}>
                              <div className="chat_file_container">
                                <FileIcon fileData={msg?.attached_file} fromChat />
                              </div>
                            </RenderIf>
                            <RenderIf isTrue={!msg?.file_id}>
                              {stringMessageToArray(msg?.content).map((message, idx) => (
                                <p key={idx}>{message}</p>
                              ))}
                            </RenderIf>
                          </div>
                        </div>
                      ))}
                    </div>
                  </section>
                </div>
                {!disabled && (
                  <div className="col-foot">
                    <div className="compose">
                      <textarea
                        placeholder="Responder mensaje"
                        value={message}
                        onChange={handleChange}
                        onKeyPress={handleKeyPress}
                      />
                      <div className="compose-dock">
                        <div className="dock">
                          <UploadClip onUpload={onUpload} id="chat-file" />

                          <Tooltip title="Agendar una videollamada" placement="top">
                            <Videocam
                              style={{ fontSize: 30 }}
                              onClick={() => setVideoModal(true)}
                            />
                          </Tooltip>

                          <Send onClick={handleClickSendMessage} />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </RenderIf>
            <RenderIf isTrue={(!hiddeFiles || tab === 1) && !noFiles}>
              <div className="col-right">
                <div className="col-content" style={{ padding: '1em' }}>
                  <p style={{ textAlign: 'center', fontSize: 16 }}>Archivos</p>
                  {archivos}
                  {/* <Tabs>
              <Tabs.TabPane tab="Archivos" key="item-1">
                {archivos}
              </Tabs.TabPane>
              <Tabs.TabPane tab="Documentos" key="item-2">
                Documentos
              </Tabs.TabPane>
              <Tabs.TabPane tab="Enlaces" key="item-3">
                Enlaces
              </Tabs.TabPane>
            </Tabs> */}
                </div>
              </div>
            </RenderIf>
          </main>
        </div>
      </div>
    </>
  );
};

export default NewChat;
