import {
  GET_SERVICES,
  DELETE_SERVICE,
  GET_MY_SERVICE,
  ADD_SERVICE,
  CLEAR_SERVICES,
  MY_SERVICES_PAGINATION,
  MAX_RANGE_SERVICES,
  GET_SERVICES_LOADING,
} from '../actions/types.js';

const initialState = {
  services: [],
  myservice: null,
  my_total_services: 10,
  maxRangePrice: 200000,
  loadingServices: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SERVICES:
      return {
        ...state,
        services: action.payload,
        loadingServices: false,
      };
    case GET_SERVICES_LOADING:
      return {
        ...state,
        loadingServices: true,
      };
    case DELETE_SERVICE:
      return {
        ...state,
        services: state.services.filter((service) => service.id !== action.payload),
      };
    case GET_MY_SERVICE:
      return {
        ...state,
        myservice: action.payload,
      };
    case ADD_SERVICE:
      return {
        ...state,
        services: [...state.services, action.payload],
      };
    case MY_SERVICES_PAGINATION:
      return {
        ...state,
        my_total_services: action.payload,
      };
    case MAX_RANGE_SERVICES:
      return {
        ...state,
        maxRangePrice: action.payload,
      };
    case CLEAR_SERVICES:
      return {
        ...state,
        services: [],
      };
    default:
      return state;
  }
}
