import React from 'react';
import AddImage from 'components/common/AddImage';

const NewImage = ({ id, onUpload, text }) => {
  const onChange = (e) => {
    onUpload(e);
    document.getElementById(id).value = '';
  };
  return (
    <>
      <label htmlFor={id}>
        <input
          type="file"
          accept="image/*"
          id={id}
          style={{ display: 'none' }}
          onChange={onChange}
        />
        <AddImage text={text} />
      </label>
    </>
  );
};

export default NewImage;
