import React, { Fragment, useEffect } from 'react';
import css from './AccountsManagement.module.scss';
import TagManager from 'react-gtm-module';
import config from 'config';
import useAuthButtons from 'hooks/useAuthButtons';

const AccountsManagement = () => {
  const authButtons = useAuthButtons();

  useEffect(() => {
    TagManager.initialize({ gtmId: config.googleTagManagerId });
  }, []);

  const handleRegister = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'conversion',
      },
    });
    authButtons.setShowRegisterWindow(true);
    authButtons.setShowAuthModal(false);
  };

  const handleLogin = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'conversion',
      },
    });
    authButtons.setShowLoginWindow(true);
    authButtons.setShowAuthModal(false);
  };

  return (
    <Fragment>
      <div className={css.container}>
        <button className={css.login} type="button" onClick={handleLogin}>
          Ingresar
        </button>
        <button className={css.register} type="button" onClick={handleRegister}>
          Registrarse
        </button>
      </div>
    </Fragment>
  );
};

export default AccountsManagement;
