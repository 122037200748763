/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import { Text } from 'components/common';
import css from '../Forms.module.scss';
import AddImage from 'components/common/AddImage';
import PreviewImage from 'pages/Managment/MyProfile/PreviousProjects/NewImage/PreviewImage';

const ImagesType = ({ handleSetAnswer, answer }) => {
  const [imgsId, setImgsId] = useState([1, 2, 3, 4, 5]);

  const [images, setImages] = useState({});
  const [showPreview, setShowPreview] = useState({});

  const [uploadedImages, setUploadedImages] = useState([]);

  const handleUpload = (e, i) => {
    setImages({ ...images, [i]: e.target.files[0] });
    setShowPreview({ ...showPreview, [i]: true });
    handleSetAnswer({ ...images, [i]: e.target.files[0] }, 'images');
  };
  const handleDeletePreview = (i) => {
    setShowPreview({ ...showPreview, [i]: false });
    setImages({ ...images, [i]: null });
    handleSetAnswer({ ...images, [i]: null }, 'images');
  };

  useEffect(() => {
    if (answer?.answers?.length > 0) {
      if (answer?.isEdit) {
        setUploadedImages(answer?.answers?.[0]);
        setImgsId(imgsId.slice(0, -answer?.answers?.[0]?.length));
      } else {
        const ans = answer?.answers?.[0];
        setImages(ans);
        const show = {};
        imgsId.forEach((imgId) => {
          show[imgId] = ans[imgId] ? true : false;
        });
        setShowPreview(show);
      }
    }
  }, [answer]);

  return (
    <>
      <div className={css.palettes_container}>
        {uploadedImages?.length > 0 &&
          uploadedImages.map((img, i) => (
            <PreviewImage key={i} myPreview={img} width={240} height={200} withDelete={false} />
          ))}
        {imgsId.map((imgId) => (
          <div style={{ width: 240, height: 200 }} key={imgId}>
            {showPreview[imgId] ? (
              <PreviewImage
                file={images[imgId]}
                width={240}
                height={200}
                onDelete={() => handleDeletePreview(imgId)}
              />
            ) : (
              <label htmlFor={'cms-templateimg' + imgId} style={{ width: '100%', height: '100%' }}>
                <input
                  type="file"
                  accept="image/*"
                  id={'cms-templateimg' + imgId}
                  style={{ display: 'none' }}
                  onChange={(e) => handleUpload(e, imgId)}
                />
                <AddImage
                  text="Agregar imagen"
                  style={{ width: 240, height: '100%', borderRadius: 0 }}
                  iconSize={50}
                />
              </label>
            )}
          </div>
        ))}
      </div>
    </>
  );
};

export default ImagesType;
