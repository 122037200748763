import React from 'react';
import classnames from 'classnames';
import css from './ManagementCardLayout.module.scss';
import { LoadingDots } from '@components/common/';

const ManagementCardLayout = ({ children, isLoading, className = '', ...rest }) => {
  return (
    <div className={classnames(css.container, className)} {...rest}>
      {!isLoading ? children : <LoadingDots />}
    </div>
  );
};

const ManagementCardLayoutLeftSide = ({ children, className, ...rest }) => {
  const classes = classnames(css.left, className);

  return (
    <div className={classes} {...rest}>
      {children}
      <hr />
    </div>
  );
};

const ManagementCardLayoutRightSide = ({ children, className, ...rest }) => {
  const classes = classnames(css.right, className);

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

const ManagementCardLayoutButtons = ({ children, className, ...rest }) => {
  const classes = classnames(css.buttons, className);

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

const ManagementCardLayoutBottom = ({ children, className, ...rest }) => {
  const classes = classnames(css.bottom, className);

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

ManagementCardLayout.LeftSide = ManagementCardLayoutLeftSide;
ManagementCardLayout.RightSide = ManagementCardLayoutRightSide;
ManagementCardLayout.Buttons = ManagementCardLayoutButtons;
ManagementCardLayout.Bottom = ManagementCardLayoutBottom;

export default ManagementCardLayout;
