import React from 'react';
import classNames from 'classnames';

import './BasicContainer.scss';

const BasicContainer = ({ className, children, ...rest }) => {
  const containerClasses = classNames('basic-container-body', className);
  return (
    <div className={containerClasses} {...rest}>
      {children}
    </div>
  );
};

export default BasicContainer;
