import React from 'react';
import { SummaryRequester } from './SummaryRequester/SummaryRequester';
import './OtherRequesters.scss';
import { NO_HIRING_PROJECTS_ILUSTRATION } from '@common/constants/informativeIlustrations.constants';
import { Text } from '@components/common';

export const OtherRequesters = ({
  requestersList,
  handleOnClickApplicant,
  currentRequester,
  onlySelected,
}) => {
  let requesters = onlySelected
    ? requestersList.filter(
        (requester) => requester?.request_status === 'accepted' || requester?.proposal?.accepted
      )
    : requestersList;
  return (
    <div className="other-requesters__container">
      <Text type="detail">
        {onlySelected
          ? 'Freelancer seleccionado:'
          : `Postulantes a tu proyecto (${requestersList?.length}): `}
      </Text>
      <hr />
      {requestersList?.length == 0 ? (
        <img src={NO_HIRING_PROJECTS_ILUSTRATION} />
      ) : (
        <section className="other-requesters__scroll-content">
          {requesters?.map((requester, index) => (
            <SummaryRequester
              style={{
                cursor: 'pointer',
                borderColor: requester.id === currentRequester?.id ? '#3383ff' : 'lightgray',
              }}
              requester={requester}
              key={index}
              handleOnClickApplicant={handleOnClickApplicant}
            />
          ))}
        </section>
      )}
    </div>
  );
};
