const dev = {
  cmsUrl: '',
  backendUrl: 'https://dev-ten-backend-api.herokuapp.com',
  googleTagManagerId: 'UA-228820925-1',
  assetsBucket: 'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/',
  chatURL: 'wss://tn-chat-app.herokuapp.com/ws/chat/v3/DEVEL',
  homeURL: 'https://www.dev.tenecesito.cl/#',
  chatEnv: 'dev',
};

const prod = {
  cmsUrl: '',
  backendUrl: 'https://api-master.tenecesito.cl',
  googleTagManagerId: 'UA-228820925-1',
  assetsBucket: 'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/',
  chatURL: 'wss://tn-chat-app.herokuapp.com/ws/chat/v3/PROD',
  homeURL: 'https://www.tenecesito.cl/#',
  chatEnv: 'PROD',
};

const local = {
  cmsUrl: '',
  backendUrl: 'http://127.0.0.1:8000',
  googleTagManagerId: 'UA-228820925-1',
  assetsBucket: 'https://tenecesito-bucket.s3.us-west-1.amazonaws.com/static/assets/',
  chatURL: 'wss://tn-chat-app.herokuapp.com/ws/chat/v3/DEVEL',
  chatEnv: 'DEV',
  homeURL: 'http://localhost:3000/#',
};

const config =
  process.env.REACT_APP_ENV === 'prod' ? prod : process.env.REACT_APP_ENV === 'dev' ? dev : local;

export default {
  // Add common config values here
  ...config,
};
