import React from 'react';
import { ManagmentViewModel } from '../../../ManagmentViewModel';
import {
  COMPLETED_PROJECTS_LABEL,
  INPROGRESS_PROJECTS_LABEL,
  ALL_PROJECTS,
  FEEDBACK_PROJECT_LABEL,
} from '@common/constants/project.constants';
import projectService from '@services/projectService';
import { MEDIATION_PROJECTS_LABEL } from 'common/constants/project.constants';

export const FreelancerProjectsListViewModel = () => {
  const { authUser } = ManagmentViewModel();
  const [currentFilter, setCurrentFilter] = React.useState(ALL_PROJECTS);

  const [isLoadingWorkingProjectsList, setIsLoadingWorkingProjectsList] = React.useState(false);
  const [workingProjectsList, setWorkingProjectsList] = React.useState([]);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalWorkingProjects, setTotalWorkingProjects] = React.useState(0);

  React.useEffect(() => {
    setIsLoadingWorkingProjectsList(true);
    if (authUser) {
      projectService
        .getUserProjects(authUser.user.id, currentFilter, 'collaborator', currentPage)
        .then((res) => {
          setWorkingProjectsList(res.data.objects);
          setTotalWorkingProjects(res.data.total_objects);
          setIsLoadingWorkingProjectsList(false);
        })
        .catch(() => {
          setWorkingProjectsList([]);
          setTotalWorkingProjects(0);
          setIsLoadingWorkingProjectsList(false);
        });
    }
  }, [currentFilter, currentPage]);

  const project_types = [
    { id: ALL_PROJECTS, name: 'Todos', tag: 1 },
    { id: INPROGRESS_PROJECTS_LABEL, name: 'En progreso', tag: 2 },
    { id: FEEDBACK_PROJECT_LABEL, name: 'Feedback', tag: 3 },
    { id: COMPLETED_PROJECTS_LABEL, name: 'Completados', tag: 4 },
    { id: MEDIATION_PROJECTS_LABEL, name: 'En mediación', tag: 5 },
  ];

  const handleOnClickCategory = async (categoryId) => {
    setCurrentFilter(categoryId);
  };

  const handlePagination = (event) => {
    setCurrentPage(event);
  };
  return {
    isLoadingWorkingProjectsList: isLoadingWorkingProjectsList,
    workingProjectsList,
    project_types,
    handleOnClickCategory,
    currentFilter,
    handlePagination,
    totalWorkingProjects,
    currentPage,
  };
};
