import React, { Fragment } from 'react';
import './SummaryRequest.scss';
import { Text } from '@components/common';

export const SummaryRequest = ({ request, handleSwitchRequest, isCurrent, isLoading }) => {
  const [selected, setSelected] = React.useState(false);
  React.useEffect(() => {
    setSelected(isCurrent(request.id));
  }, [isCurrent]);
  const parsedProjectName = () => {
    if (request.project_name.length > 20) {
      return request.project_name.substring(0, 20) + '...';
    }
    return request.project_name;
  };
  return (
    <Fragment>
      {!isLoading ? (
        <div
          className={`summary-request__container ${selected ? 'summary-request__selected' : ''}`}
          onClick={() => handleSwitchRequest(request.id)}>
          <div className="summary-request__image">
            <img
              src={request.owner_data.profile_image}
              className="summary-request__profile-image"
              alt="Profile image"
            />
          </div>
          <div className="summary-request__title">
            <Text type="strong">{request.owner_data.first_name}</Text>
            <Text type="strongBlue">{parsedProjectName(request.project_name)}</Text>
          </div>
        </div>
      ) : (
        ''
      )}
    </Fragment>
  );
};
