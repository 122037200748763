import { useState, useEffect } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';
import { useDispatch, useSelector } from 'react-redux';
import config from '@config';
import { useRef } from 'react';
import { uploadFile } from 'actions/projects';
import { videoCallSchedule, viewChat } from 'actions/chat';
import chatService from 'services/chatService';
import { createMessage } from 'actions/messages';
import { getProjectRoute } from 'common/utils/ProjectUtils';
import { useHistory } from 'react-router-dom';

const NO_ID_CHAT = {
  messageHistory: [],
  onSend: () => console.log('not passing id to chat'),
  connectionStatus: 'Closed',
  isActive: false,
  isEmpty: true,
};

const useSingleChat = ({ chat, containerId }) => {
  const chatId = chat?.id;
  const mounted = useRef(false);
  if (!chatId) {
    return NO_ID_CHAT;
  }

  const history = useHistory();
  const dispatch = useDispatch();
  const [message, setMessage] = useState('');
  const [videoModal, setVideoModal] = useState(false);
  const [page, setPage] = useState(1);
  const [files, setFiles] = useState([]);
  const [isLoadingFiles, setIsLoadingFiles] = useState(false);

  //Public API that will echo messages sent to it back to the client
  const CHATURL = `${config.chatURL}/${chatId}/`;
  const [messageHistory, setMessageHistory] = useState([]);
  const { user, token } = useSelector((state) => state.auth);
  const [isNewPage, setIsNewPage] = useState(false);
  const [isFullHistory, setIsFullHistory] = useState(false);

  const { sendMessage, lastJsonMessage, readyState } = useWebSocket(CHATURL, {
    onOpen: () => onLoad(),
    shouldReconnect: () => true,
    reconnectInterval: 5000,
  });

  const onLoad = () => {
    sendMessage(chatService.fetchMessages(user?.email, chatId));
  };
  const fetchNewPage = (page) => {
    sendMessage(chatService.fetchMessages(user?.email, chatId, page));
  };

  const onSend = (messageContent, fileData = null) => {
    if (messageContent.trim() === '') return;
    sendMessage(
      chatService.newChatMessage({
        from: user?.email,
        content: messageContent,
        chatId,
        token,
        fileId: fileData ? fileData.id : null,
      })
    );
  };

  useEffect(() => {
    if (lastJsonMessage !== null && mounted) {
      if (isNewPage) {
        setMessageHistory((prev) => [...lastJsonMessage.messages, ...prev]);
        setIsNewPage(false);
      } else {
        if (lastJsonMessage?.messages) {
          setMessageHistory(lastJsonMessage.messages);
        }
        if (lastJsonMessage?.message) {
          setMessageHistory([...messageHistory, lastJsonMessage?.message]);
        }
      }
      if (lastJsonMessage.actual_page === lastJsonMessage.total_pages) {
        setIsFullHistory(true);
      }
    }
  }, [lastJsonMessage]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  const isActive = connectionStatus === 'Open';

  const isEmpty = messageHistory ? true : false;

  const isOwnerOfMessage = (msg) => msg.owner === user?.id;

  const stringMessageToArray = (chatMessage) => {
    return chatMessage?.split('\n');
  };

  const onUpload = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();

    formData.append('file', file);
    formData.append('chat', chatId);
    formData.append('filename', file.name);
    formData.append('type', 'chat');

    const { data } = await uploadFile(formData);

    onSend(file.name, data.data);

    document.getElementById('chat-file').value = '';

    handleGetFiles();
  };

  const handleSubmitVideoCall = (values, resetForm) => {
    const invited = chat?.participants_id?.find((id) => id !== user?.id);

    videoCallSchedule({ ...values, project: chat?.project, host: user?.id, invited }).then(() => {
      resetForm();

      setVideoModal(false);

      const day = values?.start_time?.split('T')[0]?.split('-')[2];
      const month = values?.start_time?.split('T')[0]?.split('-')[1];

      const hour = values?.start_time?.split('T')[1]?.split(':')[0];
      const minutes = values?.start_time?.split('T')[1]?.split(':')[1];

      onSend(
        `${user?.first_name} agendó una videollamada el dia ${day}/${month} a las ${hour}:${minutes}.\nRecibirán un correo electronico con el link de la reunión.`
      );

      dispatch(
        createMessage({
          successMessage:
            'Se agendó una reunión en zoom, los participantes recibirán la invitación por correo electronico',
        })
      );
    });
  };

  const onChatScroll = (e) => {
    if (isNewPage || isFullHistory) return;
    const scroll = e.target.scrollTop;
    const chatContainer = document.getElementById(containerId);
    const height = chatContainer.style.height.toString().slice(0, -2);
    const scrollToFetch = (chatContainer.scrollHeight - height) * -1;
    if (scroll <= scrollToFetch + 10) {
      console.log('fetch');
      e.target.scrollTop = scrollToFetch;
      setIsNewPage(true);
      fetchNewPage(page + 1);
      setPage((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (chatId) {
      viewChat(chatId);
      handleGetFiles();
    }
  }, [chatId]);

  const handleGetFiles = () => {
    setIsLoadingFiles(true);
    chatService
      .getFilesHandler(chatId)
      .then((res) => {
        setFiles(res.data);
        setIsLoadingFiles(false);
      })
      .catch(() => setIsLoadingFiles(false));
  };

  const handleRedirectToProject = () => {
    const isOwner = user?.id === chat?.project_owner;
    const projectId = chat?.project;
    const status = chat?.project_status;
    const requestId = chat?.request;

    const route = getProjectRoute({ isOwner, projectId, status, requestId });

    history.push(route);
  };

  const isLoading = chat?.last_message?.id && messageHistory?.length === 0;

  return {
    messageHistory,
    onSend,
    connectionStatus,
    isActive,
    isEmpty,
    fetchNewPage,
    setIsNewPage,
    isNewPage,
    isFullHistory,
    isOwnerOfMessage,
    stringMessageToArray,
    onUpload,
    handleSubmitVideoCall,
    setVideoModal,
    videoModal,
    onChatScroll,
    message,
    setMessage,
    handleGetFiles,
    files,
    isLoadingFiles,
    handleRedirectToProject,
    isLoading,
  };
};

export default useSingleChat;
