import React, { Fragment } from 'react';
import { BaseLayout, LoadingDots, Text, Suggestions } from '@components/common';
import { PublicServiceViewModel } from './PublicServiceViewModel';
import { PublicServiceMainCard } from '@components/service/PublicServiceMainCard/PublicServiceMainCard';
import { ViewProfileDrawer } from '@components/profile/ViewProfileDrawer/ViewProfileDrawer';
import { QuestionContainer } from '@components/common/QuestionContainer';
import { ContactDrawer } from '@components/service/ContactDrawer/ContactDrawer';
import { ServiceDetails } from 'components/service/ServiceDetails';
import css from './PublicService.module.scss';

export const PublicService = () => {
  const {
    authUser,
    isLoading,
    isLoadingProjects,
    currentService,
    currentUser,
    userDrawer,
    setUserDrawer,
    handleCreateQuestion,
    contactDrawer,
    setContactDrawer,
    redirectToCreateProject,
    authUserProjects,
    handleConfirmInviteProject,
    setProjectSelected,
    projectSelected,
    questionsList,
    projectsSuggestions,
    handleRedirectSuggestion,
  } = PublicServiceViewModel();

  return (
    <BaseLayout>
      {projectsSuggestions.length >= 1 ? (
        <Fragment>
          <BaseLayout.LeftSide>
            <Suggestions
              suggestionsData={projectsSuggestions}
              handleRedirectSuggestion={handleRedirectSuggestion}
              type="service"
            />
          </BaseLayout.LeftSide>
        </Fragment>
      ) : null}
      <BaseLayout.Content>
        <Fragment>
          <ViewProfileDrawer
            currentUser={currentUser}
            type="service"
            userDrawer={userDrawer}
            setUserDrawer={setUserDrawer}
          />
          {!isLoadingProjects && (
            <ContactDrawer
              currentUser={currentUser}
              redirectToCreateProject={redirectToCreateProject}
              authUserProjects={authUserProjects}
              handleConfirmInviteProject={handleConfirmInviteProject}
              setProjectSelected={setProjectSelected}
              projectSelected={projectSelected}
              contactDrawer={contactDrawer}
              setContactDrawer={setContactDrawer}
            />
          )}
          {isLoading ? (
            <LoadingDots />
          ) : (
            <Fragment>
              {' '}
              <PublicServiceMainCard
                currentService={currentService}
                currentUser={currentUser}
                setUserDrawer={setUserDrawer}
                setContactDrawer={setContactDrawer}
                authUser={authUser}
              />
              <ServiceDetails currentService={currentService} />
              {authUser?.isAuthenticated && (
                <div className={css.questionContainer}>
                  <Text type="detail">Preguntas:</Text>
                  <Text type="smallDetail">
                    Aquí puedes hacer tus consultas sobre cosas que no te hayan quedado claras de la
                    descripción.
                  </Text>
                  <QuestionContainer
                    type="service"
                    user={currentUser}
                    handleCreateQuestion={handleCreateQuestion}
                    authUser={authUser}
                    isLoading={isLoading}
                    questionsList={questionsList}
                  />
                </div>
              )}
            </Fragment>
          )}
        </Fragment>
      </BaseLayout.Content>
    </BaseLayout>
  );
};
