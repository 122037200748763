import {
  GET_SKILLS,
  DELETE_SKILL,
  ADD_SKILL,
  GET_CATEGORIES,
  GET_SUB_CATEGORIES,
  ADD_CATEGORY,
  DELETE_CATEGORY,
} from '../actions/types.js';

const initialState = {
  skills: [],
  categories: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_SKILLS:
      return {
        ...state,
        skills: action.payload,
      };
    case DELETE_SKILL:
      return {
        ...state,
        skills: state.skills.filter((skill) => skill.id !== action.payload),
      };
    case ADD_SKILL:
      return {
        ...state,
        skills: [...state.skills, action.payload],
      };
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case GET_SUB_CATEGORIES:
      return {
        ...state,
        categories: state.categories.concat(action.payload),
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        categories: state.categories.filter((category) => category.id !== action.payload),
      };
    case ADD_CATEGORY:
      return {
        ...state,
        categories: [...state.categories, action.payload],
      };
    default:
      return state;
  }
}
