export const SERVICE_FORM_INPUT_CONSTANTS = {
  title: {
    title: 'Nombre de la publicación',
    placeholder: 'Ingresa el título de la publicación',
  },
  category: {
    title: 'Categoría en la que me desempeño',
    placeholder: 'Selecciona la categoría que represente mejor tu servicio',
  },
  subcategory: {
    title: 'Subcategorías del servicio',
    placeholder: 'Ingresa las subcategorías que se relacionan con tu servicio',
  },
  description: {
    title: 'Detalles del/los servicio/s que puedes proveer',
    placeholder: 'Ingresa la descripción de tu servicio aquí',
  },
  experience: {
    title: 'Experiencia',
    placeholder: 'Detalle su experiencia en este servicio en particular',
  },
  skills: {
    title: 'Habilidades',
    placeholder: 'Ingresa los conocimientos técnicos que posee tu servicio',
  },
  disponibility: {
    title: 'Disponibilidad',
    placeholder: 'Indicanos tu horario o disponibilidad',
  },
  cost: {
    title: 'Pago esperado por hora',
  },
};
export const serviceMessages = {
  createService: {
    success: 'El servicio ha sido creado exitosamente',
    error: 'El servicio no ha podido ser creado correctamente. Por favor intente más tarde.',
  },
  updateService: {
    success: 'El servicio ha sido editado exitosamente',
    error: 'El servicio no ha podido ser editado correctamente. Por favor intente más tarde.',
  },
  deleteService: {
    success: 'El servicio ha sido eliminado exitosamente',
    error: 'El servicio no ha podido ser eliminado correctamente. Por favor intente más tarde.',
  },
  publicationModalConfirm: {
    title: '¿Estás seguro que quieres publicar tu Servicio ?',
    content: 'Revisa que hayas añadido toda la información, así podrás trabajar más prontamente!.',
  },
  publicationDeniedMessage:
    'Debes verificar tu perfil y/o agregar un número de teléfono para poder publicar y postular.',
  deleteModalConfirm: {
    title: '¿Estas seguro/a que quieres eliminar este servicio?',
    content: 'Si confirmas, el servicio será eliminado y las propuestas serán rechazadas.',
  },
};
