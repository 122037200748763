import axiosClient from '@config/axios';
import { tokenConfig } from './auth';
import { createMessage, returnErrors } from './messages';
import {
  GET_SERVICES,
  GET_SERVICE,
  GET_MY_SERVICE,
  SERVICES_PAGINATION,
  DELETE_SERVICE,
  ADD_SERVICE,
  UPDATE_SERVICE,
  MAX_RANGE_SERVICES,
  GET_SERVICE_LOADING,
  GET_SERVICES_LOADING,
} from './types';

// GET SERVICES
export const getServices = (page) => (dispatch, getState) => {
  var aux_page = 1;
  if (page) {
    aux_page = page;
  }
  axiosClient
    .get(`/services/?page=${aux_page}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SERVICES,
        payload: res.data.objects,
      });
      dispatch({
        type: SERVICES_PAGINATION,
        payload: res.data.total_objects,
      });
      dispatch({
        type: MAX_RANGE_SERVICES,
        payload: res.data.max_range_price,
      });
    })
    .catch((err) => console.log(err));
};

// GET SEARCH FILTERED SERVICES
export const getSearchFilteredServices = (searchdata, page, setLoading) => (dispatch, getState) => {
  var aux_page = 1;
  if (page) {
    aux_page = page;
  }
  axiosClient
    .get(`/services/?page=${aux_page}&search=${searchdata}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SERVICES,
        payload: res.data.objects,
      });
      dispatch({
        type: SERVICES_PAGINATION,
        payload: res.data.total_objects,
      });
      dispatch({
        type: MAX_RANGE_SERVICES,
        payload: res.data.max_range_price,
      });
      setLoading ? setLoading(false) : null;
    })
    .catch((err) => console.log(err));
};
export const getFilteredServices = (filters, page) => (dispatch, getState) => {
  dispatch({ type: GET_SERVICES_LOADING });
  var aux_page = 1;
  if (page) {
    aux_page = page;
  }
  axiosClient
    .get(`/services/?status=active&${filters}&page=${aux_page}`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_SERVICES,
        payload: res.data.objects,
      });
      dispatch({
        type: SERVICES_PAGINATION,
        payload: res.data.total_objects,
      });
      dispatch({
        type: MAX_RANGE_SERVICES,
        payload: res.data.max_range_price,
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
// GET SERVICES FROM A SPECIFIC USER
export const getUserServices = (userid, page) => {
  var aux_page = 1;
  if (page) {
    aux_page = page;
  }
  return axiosClient.get(`/services/?owner=${userid}&page=${aux_page}`);
};

// GET SERVICE
export const getService = (id) => async (dispatch) => {
  dispatch({ type: GET_SERVICE_LOADING });
  await axiosClient
    .get(`/services/${id}/`)
    .then((res) => {
      dispatch(createMessage({ getService: 'Service gotten' }));
      dispatch({
        type: GET_SERVICE,
        payload: res.data,
      });
    })
    .catch((err) => dispatch(returnErrors(err.response.data, err.response.status)));
};

export const getPrivateService = (id) => axiosClient.get(`/services/${id}/`);

// DELETE SERVICE
export const deleteService = (id) => async (dispatch, getState) => {
  await axiosClient
    .delete(`/services/${id}/`, tokenConfig(getState))
    .then(() => {
      dispatch(createMessage({ deleteService: 'Service Deleted' }));
      dispatch({
        type: DELETE_SERVICE,
        payload: id,
      });
    })
    .catch((err) => console.log(err));
};

// ADD SERVICE
export const registerService = (service) => async (dispatch, getState) => {
  const result = await axiosClient
    .post(`/registerService`, service, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ registerService: res.data }));
      dispatch({
        type: ADD_SERVICE,
        payload: res.data['service'],
      });
      return res;
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      return err.response;
    });
  return result;
};
// INVITE A COLABORATOR TO PROJECT

export const inviteColabToProject_v2 = ({ projectId, userId }) =>
  axiosClient.post(`/inviteService/`, { project: projectId, freelance: userId });
// UPDATE SERVICE
export const updateService = (id, service) => async (dispatch, getState) => {
  const result = await axiosClient
    .patch(`/services/${id}/`, service, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ updateService: 'Servicio Actualizado!' }));
      dispatch({
        type: UPDATE_SERVICE,
        payload: res.data,
      });
      return res;
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      return err.response;
    });
  return result;
};
export const dispatchService = (myservice) => (dispatch) => {
  dispatch({
    type: GET_MY_SERVICE,
    payload: myservice,
  });
};

export const getServiceQuestions = (serviceId) =>
  axiosClient.get(`questions/?service=${serviceId}`);

export const registerService_v2 = ({ service }) => axiosClient.post(`/registerService`, service);
export const updateService_v2 = ({ id, service }) => axiosClient.patch(`/services/${id}/`, service);
export const deleteService_v2 = ({ id }) => axiosClient.delete(`/services/${id}/`);

export const relatedServices = (serviceId) =>
  axiosClient.get(`/related_services/?service=${serviceId}`);

export const relatedServicesForProject = ({ projectId }) =>
  axiosClient.get(`/related_services/?project=${projectId}`);
