import React from 'react';
import { GraphicContainer, Title } from '@components/common';
import { SPONSOR1, SPONSOR2, SPONSOR3, SPONSOR4 } from 'pages/Landing/landing.constants';
import './Section5.scss';
import AnimateOnView from 'components/AnimateOnView';

const Section5 = () => {
  return (
    <div className="section5__section">
      <div className="section5__content">
        <Title type="xl" color="primary">
          Contamos con el apoyo de:
        </Title>
        <div className="section5__images">
          <AnimateOnView className="section5_image">
            <GraphicContainer src={SPONSOR1} maxHeight="50px" />
          </AnimateOnView>
          <AnimateOnView className="section5_image">
            <GraphicContainer src={SPONSOR2} maxHeight="40px" />
          </AnimateOnView>
          <AnimateOnView className="section5_image">
            <GraphicContainer src={SPONSOR3} maxHeight="50px" />
          </AnimateOnView>
          <AnimateOnView className="section5_image">
            <GraphicContainer src={SPONSOR4} maxHeight="40px" />
          </AnimateOnView>
        </div>
      </div>
    </div>
  );
};

export default Section5;
