/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react';
import { Modal } from 'antd';
import { Button } from 'components/common';

import RenderIf from 'components/common/RenderIf';
import RequestStepper from './RequestStepper';
import useRequest from './RequestStepper/useRequest';
import YourRequest from './RequestStepper/YourRequest';

export function RequestModal({ isModalOpen, authUser, setRequestDrawer, lastStep }) {
  const { current, nextStep, canGoNext, TOTAL_STEP } = useRequest({
    authUser,
  });

  return (
    <Fragment>
      <Modal
        visible={isModalOpen}
        width={1280}
        style={{ top: 20 }}
        footer={
          current < TOTAL_STEP && (
            <Button
              text={current === TOTAL_STEP - 1 ? 'Guardar y continuar' : 'Continuar'}
              color={current === TOTAL_STEP - 1 ? 'secondary' : 'primary'}
              onClick={nextStep}
              disabled={current < TOTAL_STEP && !canGoNext[current]}
            />
          )
        }
        onCancel={() => {
          setRequestDrawer(false);
        }}>
        <section
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '80vh',
            marginTop: 24,
            padding: 16,
            overflow: 'auto',
          }}>
          <RenderIf isTrue={current < TOTAL_STEP}>
            <RequestStepper current={current} authUser={authUser} lastStepTitle={lastStep.title} />
          </RenderIf>
          <RenderIf isTrue={current === TOTAL_STEP}>
            <div>{lastStep.component}</div>
          </RenderIf>
        </section>
      </Modal>
    </Fragment>
  );
}
