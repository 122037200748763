import React from 'react';
import { Input, Form, message } from 'antd';
import 'react-image-crop/dist/ReactCrop.css';
import './FormVerifyIdentity.scss';
import { Button, PhoneNumberInput, Text, Title } from '@components/common';
import useRut from '../../../hooks/useRut';
import { addThousandDots, removeNonNumeric } from '@common/utils/Formaters';
import { verifyIdentity } from '@actions/myprofile';
import { useDispatch, useSelector } from 'react-redux';
import { VALIDATE_IDENTITY } from '@actions/types';
import { editUser_v2 } from '@actions/users';
import { EDIT_USER } from '@actions/types';
import { selectAuthUser } from '@reducers/auth';
import { returnErrors } from '@actions/messages';
import { VERIFIED_ACCOUNT_ICON_URL } from 'common/constants/assets.constants';

const FormVerifyIdentity = ({
  doneVerification,
  userData,
  mustVerify,
  withTitle = true,
  style,
}) => {
  const dispatch = useDispatch();
  const authUser = useSelector(selectAuthUser);
  const [currentName, setCurrentName] = React.useState('');
  const [currentSerialNumber, setCurrentSerialNumber] = React.useState('');
  const [currentPhoneNumber, setCurrentPhoneNumber] = React.useState('');

  const [prefix, setPrefix] = React.useState();

  const { currentRut, onChangeRut } = useRut();

  const [isRequired, setIsRequired] = React.useState({ name: true, rut: true, serialNumber: true });

  const handleChangeName = (e) => {
    const name = e.target.value;
    if (name != '') {
      setIsRequired({ ...isRequired, name: false });
    }
    setCurrentName(name);
  };

  const handleChangeRut = (e) => {
    if (e.target.value != '') {
      setIsRequired({ ...isRequired, rut: false });
    }
    onChangeRut(e);
  };

  const handleChangeSerialNumber = (e) => {
    const serialNumber = e.target.value;
    if (serialNumber != '') {
      setIsRequired({ ...isRequired, serialNumber: false });
    }
    setCurrentSerialNumber(serialNumber);
  };

  const handleSubmitIdentity = async () => {
    const queryData = {
      name: currentName,
      rut: currentRut.split('.').join(''),
      serial_number: currentSerialNumber.split('.').join(''),
    };

    await verifyIdentity(queryData)
      .then(() => {
        message.success('¡Identidad verificada con éxito!');
        dispatch({ type: VALIDATE_IDENTITY });
        doneVerification();
      })
      .catch((err) => {
        if (err.response) {
          dispatch(returnErrors(err.response.data, err.response.status));
        }
      });

    if (currentPhoneNumber) {
      editUser_v2(authUser.user.id, {
        phone_number: currentPhoneNumber,
        phone_prefix: prefix,
      }).then((res) => {
        dispatch({
          type: EDIT_USER,
          payload: res.data,
        });
      });
    }
  };

  const serialNumberFormat = (serialNumber) => {
    if (serialNumber.length > 0) {
      return addThousandDots(removeNonNumeric(currentSerialNumber));
    } else {
      return '';
    }
  };
  return (
    <div className="verify-identity__container" style={{ padding: '2em', ...style }}>
      {withTitle && (
        <Title size="28" color="primary">
          Verifica tu identidad
        </Title>
      )}

      {mustVerify ? (
        <div>
          <Text>
            Debes verificar tu identidad para acceder a esa funcionalidad. Puedes validar tu
            identidad rellenando el siguiente formulario.
          </Text>
        </div>
      ) : null}
      <Form requiredMark={false} className="verify-identity__form" onFinish={handleSubmitIdentity}>
        <Form.Item
          name="completeName"
          className="verify-identity__form-item"
          rules={[
            {
              required: isRequired.name,
              message: 'Ingresa tu nombre completo.',
            },
          ]}>
          <Text>Nombre completo: </Text>
          <Input onChange={handleChangeName} style={{ borderRadius: '1em' }} />
        </Form.Item>
        <Form.Item
          name="rut"
          className="verify-identity__form-item"
          rules={[
            {
              required: isRequired.rut,
              message: 'Ingresa tu rut',
            },
          ]}>
          <Text>Rut: </Text>
          <Input value={currentRut} onChange={handleChangeRut} style={{ borderRadius: '1em' }} />
        </Form.Item>
        <Form.Item
          name="serialNumber"
          className="verify-identity__form-item"
          rules={[
            {
              required: isRequired.serialNumber,
              message: 'Ingresa tu número de serie',
            },
          ]}>
          <Text>Número de documento: </Text>
          <Input
            value={serialNumberFormat(currentSerialNumber)}
            onChange={handleChangeSerialNumber}
            style={{ borderRadius: '1em' }}
          />
        </Form.Item>

        {!userData?.phone_number ? (
          <div className="verify-identity__form-item">
            <Text>Número de telefono: </Text>
            <PhoneNumberInput
              prefix={prefix}
              setPrefix={setPrefix}
              setCurrentPhoneNumber={setCurrentPhoneNumber}
              currentPhoneNumber={currentPhoneNumber}
            />
          </div>
        ) : (
          ''
        )}

        {authUser.user.is_identity_verified ? (
          <div style={{ textAlign: 'center' }}>
            <Title type="tertiary" color="primary" style={{ fontSize: 18 }}>
              Identidad Verificada
            </Title>
            <div className="general-information__verify-identity">
              <img
                src={VERIFIED_ACCOUNT_ICON_URL}
                alt="profile"
                style={{ height: 36, width: 36 }}
              />
            </div>
          </div>
        ) : (
          <div className="verify-identity__button">
            <Button text="Verificar" variant="primary" />
          </div>
        )}
      </Form>
    </div>
  );
};

export default FormVerifyIdentity;
