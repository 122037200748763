import React from 'react';
import { PlusCircle } from 'react-feather';
import { Text } from '../Text';
import css from './AddImage.module.scss';
const AddImage = ({ text, onClick, iconSize, ...rest }) => {
  return (
    <div className={css.container} onClick={onClick} {...rest}>
      <PlusCircle
        className={css.plus}
        style={iconSize ? { width: iconSize, height: iconSize } : {}}
      />
      <Text className={css.text}>{text}</Text>
    </div>
  );
};

export default AddImage;
