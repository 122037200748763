import { Button, Text } from 'components/common';
import StandardModal from 'components/common/StandardModal';
import React from 'react';

const RejectProposalModal = ({
  showRejectModal,
  setShowRejectModal,
  handleRejectRequest,
  currentRequest,
}) => {
  return (
    <StandardModal
      visible={showRejectModal}
      onOk={() => setShowRejectModal(false)}
      onCancel={() => setShowRejectModal(false)}
      title="¿Estas seguro/a que quieres rechazar esta propuesta?"
      button={
        <Button
          text="Rechazar propuesta"
          variant="secondary"
          borderColor="error"
          size="sm"
          onClick={handleRejectRequest}
        />
      }>
      <div style={{ marginBottom: '2em' }}>
        <Text size={14}>
          Si confirmas, se rechazará esta propuesta y no podrás negociar más con{' '}
          {currentRequest?.emitter_data.first_name}
        </Text>
      </div>
    </StandardModal>
  );
};

export default RejectProposalModal;
