import React from 'react';
import './SocialMediaLinks.scss';
import { Text } from '@components/common';

const SocialMediaLinks = ({ currentUser }) => {
  return (
    <div className="social-media-links__container">
      {currentUser.github_link && (
        <div className="social-media-links__item">
          <a id="social-media-icon" href={currentUser.github_link} target="_blank" rel="noreferrer">
            <i className="fab fa-github"></i>
            <Text type="strong">Github</Text>
          </a>
        </div>
      )}

      {currentUser.stackoverflow_link && (
        <div className="social-media-links__item">
          {' '}
          <a
            id="social-media-icon"
            href={currentUser.stackoverflow_link}
            target="_blank"
            rel="noreferrer">
            <i className="fab fa-stack-overflow"></i>
            <Text type="strong">Stackoverflow</Text>
          </a>
        </div>
      )}
      {currentUser.briefcase_link && (
        <div className="social-media-links__item">
          <a
            id="social-media-briefcase"
            href={currentUser.briefcase_link}
            target="_blank"
            rel="noreferrer">
            <i className="fa fa-briefcase"></i>
            <Text type="strong">Portafolio</Text>
          </a>
        </div>
      )}
    </div>
  );
};

export default SocialMediaLinks;
