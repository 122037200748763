import React from 'react';
import css from './CommonGeneral.module.scss';
import { Text } from '@components/common';

function CommonGeneral({ currentProject }) {
  const projectAttributes = [
    { label: 'Tipo de página:', name: 'website_type' },
    { label: 'Requerimiento:', name: 'needed_type' },
    { label: 'Plazo:', name: 'time_range' },
    { label: 'Presupuesto:', name: 'budget_range' },
  ];
  return (
    <div className={css.container}>
      {' '}
      <Text type="detail">General: </Text>
      <div className={css.content}>
        {projectAttributes.map((attr, index) => (
          <div className={css.row} key={`attr-${index}`}>
            <Text>{attr.label}</Text>
            <Text type="detail">{currentProject[attr.name]}</Text>
          </div>
        ))}
      </div>
      <div className={css.detail}>
        <Text>Detalles</Text>
        <Text type="detail">{currentProject['description']}</Text>
      </div>
    </div>
  );
}

export default CommonGeneral;
