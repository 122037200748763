import React from 'react';
import { useParams } from 'react-router-dom';
import {
  getBlogCategories,
  getBlogs,
  getBlogId,
  newComment,
  getComments,
  getBlogInfo,
  getRecentBlogs,
} from 'actions/blog';
import { useHistory } from 'react-router';
import { BLOG_URL } from 'common/constants/routes.constants';
import { parseToUrlFormat } from 'common/utils/Formaters';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuthUser } from '@reducers/auth';
import { createMessage } from 'actions/messages';

export const BlogViewModel = () => {
  const { id, title } = useParams();
  const history = useHistory();
  const authUser = useSelector(selectAuthUser);
  const dispatch = useDispatch();

  const [blogCategories, setBlogCategories] = React.useState([]);

  const [postsList, setPostsList] = React.useState([]);
  const [isLoadingPosts, setIsLoadingPosts] = React.useState(false);

  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalObjects, setTotalObjects] = React.useState(1);

  const [categoryFilter, setCategoryFilter] = React.useState('');

  const [currentPost, setCurrentPost] = React.useState();

  const [commentsList, setCommentsList] = React.useState([]);

  const [primaryBlog, setPrimaryBlog] = React.useState();
  const [secondaryBlog, setSecondaryBlog] = React.useState();
  const [tertiaryBlog, setTertiaryBlog] = React.useState();

  const [recentBlogs, setRecentBlogs] = React.useState([]);

  const onChangeCategoryFilter = (category) => {
    setCategoryFilter(category);
  };

  const handlePagination = (event) => {
    setCurrentPage(event);
  };

  const handleClickBlog = async (id, title) => {
    history.push(`${BLOG_URL}/${id}/${parseToUrlFormat(title)}`);
  };

  const handleBackToBlog = () => {
    history.push(`${BLOG_URL}`);
  };

  const handleSendComment = (comment) => {
    let formData = { blog_id: currentPost.id, comment_text: comment, emmiter: authUser.user.id };
    newComment(formData)
      .then((res) => {
        setCommentsList([...commentsList, res.data.Comment]);
        dispatch(createMessage({ successMessage: 'El comentario ha sido creado exitosamente' }));
      })
      .catch(() => {
        setCommentsList(null);
        dispatch(
          createMessage({
            errorMessage:
              'El comentario no ha podido ser creado correctamente. Por favor intente más tarde.',
          })
        );
      });
  };

  React.useEffect(() => {
    if (id) {
      getBlogId(id)
        .then((res) => {
          setCurrentPost(res.data.data);
          getComments(res.data.data.id)
            .then((res) => {
              setCommentsList(res.data.objects);
            })
            .catch(() => {
              setCommentsList([]);
            });
          getBlogInfo(res.data.data.id);
          if (!title) {
            history.push(
              `${BLOG_URL}/${id}/${parseToUrlFormat(res?.data?.data?.attributes?.title)}`
            );
          }
        })
        .catch(() => setCurrentPost(null));
    }
  }, [id]);

  React.useEffect(() => {
    getBlogCategories()
      .then((res) => {
        setBlogCategories(res.data);
      })
      .catch(() => {
        setBlogCategories(null);
      });
  }, []);

  React.useEffect(() => {
    setIsLoadingPosts(true);

    getRecentBlogs()
      .then((res) => {
        setRecentBlogs(res.data.blogs);
        setIsLoadingPosts(false);
      })
      .catch(() => {
        setRecentBlogs([]);
        setIsLoadingPosts(false);
      });
  }, []);

  React.useEffect(() => {
    setIsLoadingPosts(true);
    getBlogs(currentPage, categoryFilter)
      .then((res) => {
        setPostsList(res.data.data);
        setPrimaryBlog(res.data.data[0]);
        setSecondaryBlog(res.data.data[1]);
        setTertiaryBlog(res.data.data[2]);
        setTotalObjects(res.data.meta.pagination.total);
        setIsLoadingPosts(false);
      })
      .catch(() => {
        setPostsList(null);
        setIsLoadingPosts(false);
      });
  }, [currentPage, categoryFilter]);

  return {
    postsList,
    primaryBlog,
    isLoadingPosts,
    secondaryBlog,
    tertiaryBlog,
    handlePagination,
    totalObjects,
    blogCategories,
    categoryFilter,
    onChangeCategoryFilter,
    handleClickBlog,
    id,
    currentPost,
    handleSendComment,
    commentsList,
    authUser,
    handleBackToBlog,
    recentBlogs,
    title,
  };
};
