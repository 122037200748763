import {
  GET_USER_REQUESTS,
  DELETE_REQUEST,
  ADD_REQUEST,
  GET_PROJECT_REQUESTS,
  GET_REQUEST_PROPOSALS,
  ADD_PROPOSAL,
  ACCEPT_REQUEST,
  ACCEPT_PROPOSAL,
} from '../actions/types.js';

const initialState = {
  requests: [],
  proposals: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_USER_REQUESTS:
      return {
        ...state,
        requests: action.payload,
      };
    case DELETE_REQUEST:
      return {
        ...state,
        requests: state.requests.filter((request) => request.id !== action.payload),
      };
    case ADD_REQUEST:
      return {
        ...state,
        requests: [...state.requests, action.payload],
      };
    case GET_PROJECT_REQUESTS:
      return {
        ...state,
        requests: action.payload,
      };
    case GET_REQUEST_PROPOSALS:
      return {
        ...state,
        proposals: action.payload,
      };
    case ADD_PROPOSAL:
      return {
        ...state,
        proposals: [...state.proposals, action.payload],
      };
    case ACCEPT_REQUEST: {
      return Object.assign({}, state, {
        requests: state.requests.map((request) => {
          if (request.id === action.payload.id) {
            return Object.assign({}, request, {
              status: action.payload.status,
            });
          }
          return request;
        }),
      });
    }

    case ACCEPT_PROPOSAL: {
      return Object.assign({}, state, {
        proposals: state.proposals.map((proposal) => {
          if (proposal.id === action.payload.id) {
            return Object.assign({}, proposal, {
              accepted: true,
            });
          }
          return proposal;
        }),
      });
    }
    default:
      return state;
  }
}

export const selectProjectRequests = (state) => state.requests.requests;
