/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react';
import { BaseLayout, CustomBreadcrumb, FileContainer, Text } from '@components/common';
import { MainCard } from './MainCard';
import { OtherRequests } from '@components/requests/OtherRequests/OtherRequests';
import { FreelancerRequestManagmentViewModel } from './FreelancerRequestManagmentViewModel';
import { LoadingDots } from '@components/common/LoadingDots';
import { ViewProfileDrawer } from '@components/profile/ViewProfileDrawer/ViewProfileDrawer';
import { ViewProjectDrawer } from '@components/project/ViewProjectDrawer/ViewProjectDrawer';
import { FreelancerRequestsListViewModel } from '../FreelancerRequestsList/FreelancerRequestsListViewModel';
import './FreelancerRequestManagment.scss';
import NewChat from 'components/common/NewChat';
import { RequestModal } from 'components/requests/EditRequestDrawer/RequestModal';
import { ViewRequestDrawer } from 'components/requests/ViewRequestDrawer/ViewRequestDrawer';
import { PAYMENT_REQUEST } from '@common/constants/request.constants';
import StandardModal from 'components/common/StandardModal';
import { FormAccount } from 'components/bankAccounts/FormAccount';
import FreelancerRequestTabs from './FreelancerRequestTabs';
import YourRequest from 'components/requests/EditRequestDrawer/RequestStepper/YourRequest';
import { PublicationQuestions, QuestionContainer } from 'components/common';
import PublicProjectViewModel from 'pages/Project/PublicProject/PublicProjectViewModel';

const FreelancerRequestManagment = () => {
  const {
    isLoading,
    isLoadingRequest,
    isLoadingChat,
    //isLoadingProject,
    isLoadingFiles,
    files,
    authUser,
    currentRequest,
    currentProposal,
    currentProject,
    currentChat,
    //requestsList,
    userDrawer,
    setUserDrawer,
    requestDrawer,
    setRequestDrawer,
    projectDrawer,
    setProjectDrawer,
    handleSwitchRequest,
    useImageUploadHandler,
    handleGetFiles,
    viewRequestDrawer,
    setViewRequestDrawer,
    handleSubmitBankAccount,
    openBankAccountModal,
    setOpenBankAccountModal,
    handleReplyQuestion,
    questionsList,
  } = FreelancerRequestManagmentViewModel();
  const { requestsList } = FreelancerRequestsListViewModel();
  return (
    <Fragment>
      <StandardModal
        visible={openBankAccountModal}
        onCancel={() => setOpenBankAccountModal(false)}
        width={600}
        title="¡Prepárate para recibir tu pago!">
        <div style={{ maxWidth: 560, margin: '0 auto' }}>
          <Text centered style={{ marginBottom: '3em' }}>
            Si un cliente acepta tu propuesta y comienzas a trabajar, asegúrate de completar tus
            datos para el pago.
          </Text>
          <FormAccount window="create" handleSubmitAccount={handleSubmitBankAccount} />
        </div>
      </StandardModal>

      <CustomBreadcrumb type="freelancer-request" title={currentProject?.title} />
      <BaseLayout>
        <BaseLayout.LeftSide>
          <div className="freelancer-request__other-request-wrapper">
            <Text type="detail">{`Proyectos a los que estas postulando (${requestsList.length}): `}</Text>{' '}
            <hr />
            <OtherRequests
              requestsList={requestsList}
              currentRequest={currentRequest}
              handleSwitchRequest={handleSwitchRequest}
              isLoading={isLoadingRequest}
            />
          </div>
        </BaseLayout.LeftSide>
        <BaseLayout.Content>
          <Fragment>
            <ViewProfileDrawer
              currentUser={currentRequest?.user_data}
              type="project"
              userDrawer={userDrawer}
              setUserDrawer={setUserDrawer}
            />

            {PAYMENT_REQUEST.includes(currentRequest?.status) ? (
              <ViewRequestDrawer
                currentRequest={currentRequest}
                currentProposal={currentProposal}
                viewRequestDrawer={viewRequestDrawer}
                setViewRequestDrawer={setViewRequestDrawer}
              />
            ) : (
              <RequestModal
                isModalOpen={requestDrawer}
                authUser={authUser}
                setRequestDrawer={setRequestDrawer}
                lastStep={{
                  title: 'Realizar Propuesta',
                  component: (
                    <YourRequest
                      currentProject={currentProject}
                      authUser={authUser}
                      currentRequest={currentRequest}
                      currentProposal={currentProposal}
                      isLoading={isLoading}
                    />
                  ),
                }}
              />
            )}

            <ViewProjectDrawer
              currentProject={currentProject}
              projectDrawer={projectDrawer}
              setProjectDrawer={setProjectDrawer}
            />

            {currentRequest && (
              <MainCard
                request={currentRequest}
                key={currentRequest.id}
                setUserDrawer={setUserDrawer}
                setRequestDrawer={
                  PAYMENT_REQUEST.includes(currentRequest?.status)
                    ? setViewRequestDrawer
                    : setRequestDrawer
                }
                setProjectDrawer={setProjectDrawer}
              />
            )}
            {currentRequest?.status != 'rejected' &&
              (currentRequest && currentChat && !isLoadingRequest && !isLoadingChat ? (
                <Fragment>
                  <FreelancerRequestTabs
                    authUser={authUser}
                    currentProject={currentProject}
                    currentRequest={currentRequest}
                    currentProposal={currentProposal}
                    isLoading={isLoading}
                    currentChat={currentChat}
                    handleGetFiles={handleGetFiles}
                    isLoadingFiles={isLoadingFiles}
                    files={files}
                    useImageUploadHandler={useImageUploadHandler}
                  />
                </Fragment>
              ) : (
                <LoadingDots />
              ))}
            <div style={{ marginTop: '1em' }}>
              <PublicationQuestions
                type="project"
                questionsList={questionsList}
                handleReplyQuestion={handleReplyQuestion}
                answerable={false}
              />
            </div>
          </Fragment>
        </BaseLayout.Content>
      </BaseLayout>
    </Fragment>
  );
};
export default FreelancerRequestManagment;
