import React, { Fragment } from 'react';
import Sidepanel from './Sidepanel';
import Profile from './Profile';
import Chat from './Chat';
import { Drawer } from 'antd';
import { Button } from '@components/common';
import 'antd/dist/antd.css';

import { Title, BaseLayout } from '@components/common';
import './liveChat.scss';

export class LiveChatDashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      isEmpty: true,
      visible: false,
    };
  }
  componentDidMount() {
    this.setState({ page_status: this.props.match.params.type });
  }
  handleChatEmpty = (chats) => {
    if (chats.length > 0) {
      this.setState({ isEmpty: false });
    }
  };

  showDrawer = () => {
    this.setState({ visible: true });
  };

  onClose = () => {
    this.setState({ visible: false });
  };
  render() {
    const empty = this.state.isEmpty;
    return (
      <BaseLayout>
        <BaseLayout.LeftSide>
          <Sidepanel handleChats={this.handleChatEmpty} history={this.props.history} />
          <Drawer
            className="contacts__drawer"
            title="Contactos"
            placement="left"
            onClose={this.onClose}
            visible={this.state.visible}>
            <Sidepanel handleChats={this.handleChatEmpty} history={this.props.history} />
          </Drawer>
        </BaseLayout.LeftSide>
        <BaseLayout.Content>
          <div className="tn-chat__content">
            <Button
              className="contacts__chat-button"
              text="Abrir Sidebar"
              variant="secondary"
              color="primary"
              onClick={this.showDrawer}
            />
            {empty ? (
              <div className="chat-is-empty">
                <Title color="main">
                  No tienes mensajes aún porque todavía no tienes proyectos activos
                </Title>
              </div>
            ) : (
              <Fragment>
                <Profile history={this.props.history} />
                <div className="chat-separation"></div>
                {this.props.match.params.chatID !== 'home' ? <Chat /> : null}
              </Fragment>
            )}
          </div>
        </BaseLayout.Content>
      </BaseLayout>
    );
  }
}
