import React, { Fragment } from 'react';
import {
  BaseLayout,
  FilterTypes,
  EmptyListCard,
  LoadingDots,
  InformativeAdvice,
  CustomBreadcrumb,
  Title,
} from '@components/common';
import { ClientPublicationsListViewModel } from './ClientPublicationsListViewModel';
import { PublicationsListMainCard } from '@components/project/ProjectMainCard/PublicationsListMainCard/PublicationsListMainCard';
import { PERSON_3_ILUSTRATION } from '@common/constants/informativeIlustrations.constants';
import { Pagination } from 'antd';
import css from './ClientPublicationsList.module.scss';

const ClientPublicationsList = () => {
  const {
    isLoadingPublications,
    publicationsList,
    project_types,
    handleOnClickCategory,
    currentFilter,
    handlePagination,
    totalPublications,
    currentPage,
  } = ClientPublicationsListViewModel();

  return (
    <Fragment>
      <CustomBreadcrumb type="client-publication" />

      <BaseLayout>
        <BaseLayout.LeftSide>
          <div className={css.layoutContainer}>
            <div className="tn-layout__left-section">
              <Title type="secondary-bold" color="primary">
                Tus proyectos publicados:{' '}
              </Title>
              <hr />
              <FilterTypes
                filter_types={project_types}
                onSelect={handleOnClickCategory}
                selectedState={currentFilter}
              />
            </div>
            <div className="tn-layout__left-section">
              <InformativeAdvice
                image={PERSON_3_ILUSTRATION}
                message="Acá puedes encontrar el listado de los proyectos que tienes publicados. Presiona alguno para
    ver más detalles."
              />
            </div>
          </div>
        </BaseLayout.LeftSide>
        <BaseLayout.Content>
          <div className={css.content}>
            {' '}
            {!isLoadingPublications && publicationsList ? (
              publicationsList.length == 0 ? (
                <EmptyListCard type="no-project-publication" />
              ) : (
                <Fragment>
                  {publicationsList.map((project, index) => {
                    return <PublicationsListMainCard currentProject={project} key={index} />;
                  })}
                  <div className={css.paginationContainer}>
                    {' '}
                    <Pagination
                      onChange={handlePagination}
                      pageSize={10}
                      total={totalPublications}
                      current={currentPage}
                      showSizeChanger={false}
                    />
                  </div>
                </Fragment>
              )
            ) : (
              <LoadingDots />
            )}
          </div>
        </BaseLayout.Content>
      </BaseLayout>
    </Fragment>
  );
};
export default ClientPublicationsList;
