import config from '@config';

const BUCKETASSETS = config.assetsBucket;

export const LANDING_IMAGE__TOP = BUCKETASSETS + 'landing/Recurso+62-8.png';

export const LANDING_IMAGE__TOP1 = BUCKETASSETS + 'landing/Recurso+63.svg';

export const LANDING_ADVANTAGES1 = BUCKETASSETS + 'landing/Recurso+64-8.png';

export const LANDING_ADVANTAGES2 = BUCKETASSETS + 'landing/Recurso+65-8.png';

export const LANDING_WORLDMAP_BACKGROUND = BUCKETASSETS + 'landing/Recurso+67-8.png';

export const PC_BACKGROUND_IMAGE = BUCKETASSETS + 'landing/fondo+del+computador.png';

export const PC_IMAGE = BUCKETASSETS + 'landing/Recurso+83-8.png';

export const PC_IMAGE1 = BUCKETASSETS + 'landing/Recurso+2-8.png';

export const PC_IMAGE2 = BUCKETASSETS + 'landing/Recurso+3-8.png';

export const PC_IMAGE3 = BUCKETASSETS + 'landing/Recurso+4-8.png';

export const PC_IMAGE4 = BUCKETASSETS + 'landing/mercurio.png';

export const PC_LUN = BUCKETASSETS + 'landing/noticia_lun_necesitas_programador.png';

export const LANDING_WHATSAPP_LEFT_IMAGE = BUCKETASSETS + 'landing/Recurso+68-8.png';

export const LANDING_WHATSAPP_BOTTOM_IMAGE1 = BUCKETASSETS + 'landing/Recurso+69-8.png';

export const LANDING_WHATSAPP_BOTTOM_IMAGE2 = BUCKETASSETS + 'landing/documentoRecurso_98-8.png';

export const LANDING_WHATSAPP_ICON = BUCKETASSETS + 'landing/jRecurso+1.svg';

export const LANDING_BOTTOM_LEFT = BUCKETASSETS + 'landing/Recurso+71-8.png';

export const LANDING_BOTTOM_RIGHT = BUCKETASSETS + 'landing/Recurso+72-8.png';

export const LANDING_BOTTOM_BACKGROUND = BUCKETASSETS + 'landing/queestasesperando.png';

export const LANDING_ADVANTAGES_BACKGROUND = BUCKETASSETS + 'landing/porque+contratar+en+tn.png';

export const LANDING_NEWS_BACKGROUND = BUCKETASSETS + 'landing/freelancerx.png';

export const LANDING_NEWS_PC = BUCKETASSETS + 'landing/fondo+del+computador.png';

export const SPONSOR1 = BUCKETASSETS + 'landing/jRecurso+95.svg';

export const SPONSOR2 = BUCKETASSETS + 'landing/TeNecesitoRecurso 114-8.png';

export const SPONSOR3 = BUCKETASSETS + 'landing/jRecurso+93.svg';

export const SPONSOR4 = BUCKETASSETS + 'landing/logo-uofounders.png';

export const WHATSAPP_BACKGROUND_CLOUD = BUCKETASSETS + 'landing/jRecurso+97.svg';

export const CARDS_BACKGROUND = BUCKETASSETS + 'landing/TeNecesitoRecurso+118-8.png';

export const PC_IMAGES_LIST = [PC_IMAGE, PC_IMAGE1, PC_IMAGE2, PC_IMAGE3, PC_LUN];

export const BLUR_IMAGE1 = BUCKETASSETS + 'landing/TeNecesitoRecurso 104-8.png';
export const BLUR_IMAGE2 = BUCKETASSETS + 'landing/TeNecesitoRecurso 106-8.png';
export const BLUR_IMAGE3 = BUCKETASSETS + 'landing/TeNecesitoRecurso 107-8.png';
export const BLUR_IMAGE4 = BUCKETASSETS + 'landing/TeNecesitoRecurso 99-8.png';
export const BLUR_IMAGE5 = BUCKETASSETS + 'HowItWorks/Project/TeNecesitoRecurso+108-8.png';

export const BLUR_IMAGE_ARRAY = [BLUR_IMAGE1, BLUR_IMAGE2, BLUR_IMAGE3, BLUR_IMAGE4];

export const DESKTOP_BREAKPOINT = 5000;
export const TABLET_BREAKPOINT = 1450;
export const MOBILE_BREAKPOINT = 1100;
export const SMALLMOBILE_BREAKPOINT = 930;
export const XS_SMALLMOBILE_BREAKPOINT = 650;
