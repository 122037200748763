import React from 'react';
import { FormItemCard } from '../../FormItemCard';
import { timeRangeOptions } from 'common/constants/publicationForm.constants';
import { Radio, Space } from 'antd';
import { Text } from '@components/common';

const TimeRange = ({
  values,
  handleSelectTimeRange,
  currentFocus,
  handleGoBackQuestion,
  handleGoForwardQuestion,
  displayDistinctQuestion,
}) => {
  return (
    <FormItemCard
      name="time_range"
      title="¿Para cuando necesitas el proyecto listo?"
      subTitle={'Elige una opción'}
      isCurrent={currentFocus == 'time_range'}
      id={'time_range-container'}
      handleGoForwardQuestion={handleGoForwardQuestion}
      handleGoBackQuestion={handleGoBackQuestion}
      displayDistinctQuestion={displayDistinctQuestion}>
      <Radio.Group
        value={values['time_range']?.name}
        disabled={currentFocus == 'time_range' ? false : true}>
        <Space direction="vertical">
          {timeRangeOptions?.map((item) => {
            return (
              <Radio
                onChange={() => handleSelectTimeRange(item)}
                value={item.name}
                key={`mult-opt-${item.id}`}>
                <Text>{item.name}</Text>
              </Radio>
            );
          })}
        </Space>
      </Radio.Group>
    </FormItemCard>
  );
};

export default TimeRange;
