import React from 'react';
import './RequestBreakdown.scss';
import { AnimatedInput, Text, Title } from '@components/common';
import { MoneyFormatter } from '@common/utils/Formaters';

import {
  CalculateServiceCharge,
  CalculateWillReceive,
  CalculateRetention,
} from '@common/utils/Bussiness';
import { Button } from '@components/common/Button';
import { CheckCircle, HelpCircle } from 'react-feather';
import { addThousandDots } from '@common/utils/Formaters';
import { COLLABORATOR_RETENTION } from 'common/constants/payment.constants';
import { Tooltip } from 'antd';

const TEXT_TOOLTIP =
  'Esta retención es obligatoria del Servicio de Impuestos Internos de Chile a las Boletas de Honorarios. En caso de requerir una factura, por favor contáctate con nosotros una vez aceptado tu presupuesto por el cliente.';

export function RequestBreakdown({
  nStages,
  stagesValues,
  handleStageValueChange,
  totalPrice,
  setShowDiscountWindow,
  totalDays,
  currentProposal,
  commission,
  setCommission,
  currentProject,
  discountCode,
}) {
  React.useEffect(() => {
    if (currentProposal) {
      setCommission(currentProposal.collaborator_breakdown.comission);
    }
  }, []);

  const list_stages = Array.from({ length: nStages }, (v, k) => k + 1);
  const serviceCharge = CalculateServiceCharge(totalPrice, commission);
  const collaboratorRetention = CalculateRetention(
    totalPrice - serviceCharge,
    COLLABORATOR_RETENTION
  );

  const willReceive = CalculateWillReceive(totalPrice, serviceCharge, collaboratorRetention);
  const commisionOneDecimal =
    Math.round(
      currentProposal ? currentProposal.collaborator_comission * 1000 : commission * 1000
    ) / 10;
  const retentionTwoDecimal = Math.round(COLLABORATOR_RETENTION * 10000) / 100;

  return (
    <div className="request-breakdown__container">
      <div className="request-breakdown__header">
        <Title type="tertiary" color="primary">
          Propuesta:{' '}
        </Title>
        {!currentProposal?.discount_value && !currentProject.discount_code && !discountCode ? (
          <Button
            text="Aplicar Descuento"
            variant="secondary"
            borderColor="primary"
            onClick={() => setShowDiscountWindow(true)}
            className="tn-btn"
            type="button"
          />
        ) : (
          <div className="request-breackdown__discount-applied">
            <CheckCircle />
            <Text type="smallDetail">Descuento Aplicado</Text>
          </div>
        )}
      </div>

      {list_stages.map((stage) => (
        <div className="request-breakdown__row-input" key={stage}>
          <div className="request-breakdown__title-input">
            {' '}
            <AnimatedInput
              name={`stage-${stage}-title`}
              type="text"
              onChange={handleStageValueChange}
              label={`Etapa ${stage}`}
              animation="right"
              defaultValue={stagesValues[stage - 1]['name']}
            />
          </div>
          <div className="request-breakdown__price-input">
            {' '}
            <AnimatedInput
              name={`stage-${stage}-price`}
              type="text"
              onChange={handleStageValueChange}
              label="Precio"
              animation="left"
              value={
                isNaN(stagesValues[stage - 1]['price'])
                  ? 0
                  : addThousandDots(stagesValues[stage - 1]['price'])
              }
            />
          </div>
          <div className="request-breakdown__days-input">
            {' '}
            <AnimatedInput
              name={`days-${stage}-days`}
              type="number"
              onChange={handleStageValueChange}
              label="Días"
              animation="left"
              value={stagesValues[stage - 1]['stage_duration']}
              defaultValue={stagesValues[stage - 1]['stage_duration']}
            />
          </div>
        </div>
      ))}
      <div>
        <hr />
      </div>
      <div className="request-breakdown__row-show">
        <div className="request-breakdown__title-show">
          <Text type="smallDetail">Total presupuestado :</Text>
        </div>
        <div className="request-breakdown__price-show">
          <Text type="smallDetail">{MoneyFormatter(totalPrice)} </Text>
        </div>
      </div>
      <div className="request-breakdown__row-show">
        <div className="request-breakdown__title-show">
          <Text type="smallDetail">{`Cargo por servicio (${commisionOneDecimal}%) :`}</Text>
        </div>
        <div className="request-breakdown__price-show">
          <Text type="smallDetail">{MoneyFormatter(serviceCharge)} </Text>
        </div>
      </div>
      <div className="request-breakdown__row-show">
        <div className="request-breakdown__title-show">
          <Tooltip title={TEXT_TOOLTIP} color={'#3383ff'}>
            <HelpCircle />
          </Tooltip>
          <Text type="smallDetail">{`Retención Boleta Honorarios (${retentionTwoDecimal}%) :`}</Text>
        </div>
        <div className="request-breakdown__price-show">
          <Text type="smallDetail">{MoneyFormatter(collaboratorRetention)} </Text>
        </div>
      </div>
      <div className="request-breakdown__row-show">
        <div className="request-breakdown__title-show">
          <Text type="smallDetail">Vas a recibir :</Text>
        </div>
        <div className="request-breakdown__price-show">
          <Text type="smallDetail">{MoneyFormatter(willReceive)} </Text>
        </div>
      </div>
      <div className="request-breakdown__row-show">
        <div className="request-breakdown__title-show">
          <Text type="smallDetail">Duración total proyecto :</Text>
        </div>
        <div className="request-breakdown__price-show">
          <Text type="smallDetail">{isNaN(totalDays) ? '0 días' : `${totalDays} días`}</Text>
        </div>
      </div>
    </div>
  );
}
