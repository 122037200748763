import React, { Fragment, useState } from 'react';
import NewCardWindow from '../NewCardWindow/NewCardWindow';
import ClampLines from 'react-clamp-lines';
import { Text, Title } from '@components/common';

const Item = ({ item, moveItem, setDragElement, handleDelete, handleUpdate, disabled = false }) => {
  const [show, setShow] = useState(false);

  const onClose = () => {
    handleUpdate(item);
    setShow(false);
  };

  const onOpen = () => setShow(true);

  const onDragStart = ({ dataTransfer, target }) => {
    dataTransfer.setData('item', JSON.stringify(item));
    setDragElement(item);
    setTimeout(() => {
      target.style.visibility = 'hidden';
    }, 1);
  };

  const onDragOver = (e) => {
    moveItem(e.target.innerText);
    e.preventDefault();
  };

  const onDragEnd = (e) => (e.target.style.visibility = 'visible');

  const handlePriority = (item) => {
    if (item.priority === 'Muy Alta') {
      return 'kanban-priority-very-high';
    } else if (item.priority === 'Alta') {
      return 'kanban-priority-high';
    } else if (item.priority === 'Media') {
      return 'kanban-priority-medium';
    } else if (item.priority === 'Baja') {
      return 'kanban-priority-low';
    }
  };

  return (
    <Fragment>
      <div className="item-wrapper">
        <div
          className="item"
          style={
            disabled
              ? { cursor: 'not-allowed', backgroundColor: 'rgba(200,200,200,0.1)' }
              : { cursor: 'pointer' }
          }
          onClick={!disabled ? onOpen : null}
          draggable={disabled ? 'false' : 'true'}
          onDragStart={!disabled ? onDragStart : null}
          onDragOver={!disabled ? onDragOver : null}
          onDragEnd={!disabled ? onDragEnd : null}>
          <div className="item-wrapper-header">
            <p
              style={{ width: 10, height: 10 }}
              className={`tn-text tn-text--regular item-priority-fullfilled ${handlePriority(
                item
              )}`}></p>
            <Title type="tertiary" color="primary" centered>
              {item.title.length > 10 ? item.title.substring(0, 10) + '...' : item.title}
            </Title>
          </div>
          <div className="item-wrapper-content">
            <ClampLines
              text={item.content}
              lines={1}
              ellipsis="..."
              className="tn-text tn-text--regular"
              innerElement="p"
            />
          </div>
          <div>
            <Text type="item" size={12}>{`${item.estimate} hh`}</Text>
          </div>
        </div>
      </div>
      <NewCardWindow item={item} onClose={onClose} show={show} handleDelete={handleDelete} />
    </Fragment>
  );
};
export default Item;
