import React from 'react';
import css from './Welcome.module.scss';
import { BaseLayout, CurrentFilters, OrderBy, Button } from '@components/common';
import WelcomeViewModel from './WelcomeViewModel';
import HomeSlider from './components/HomeSlider';
import SideBarFilters from './components/SideBarFilters';
import ProjectsFeed from './components/ProjectsFeed';
import FreelancersFeed from './components/FreelancersFeed';
import { PublicationModal } from 'components/layouts';
import LinksContainer from 'components/layouts/Header/components/LinksContainer';

function Welcome() {
  const {
    homeSlider,
    isLoadingSlider,
    latestBlog,
    switchStatus,
    handleSwitch,
    mainCategories,
    loadingMainCategories,
    selectCategory,
    currentFilters,
    orderBy,
    orderByOptions,
    handleOrderBy,
    currentPage,
    handlePagination,
    showPublicationModal,
    setShowPublicationModal,
  } = WelcomeViewModel();

  return (
    <BaseLayout>
      <PublicationModal
        closeModal={() => setShowPublicationModal(false)}
        showModal={showPublicationModal}
      />
      <BaseLayout.LeftSide>
        <div className={css.headerButtons}>
          <Button text="Publicar" variant="primary" onClick={() => setShowPublicationModal(true)} />
          <LinksContainer />
          <hr />
        </div>
        <SideBarFilters
          switchStatus={switchStatus}
          handleSwitch={handleSwitch}
          mainCategories={mainCategories}
          loadingMainCategories={loadingMainCategories}
          selectCategory={selectCategory}
          currentFilters={currentFilters}
        />
      </BaseLayout.LeftSide>
      <BaseLayout.Content>
        {!isLoadingSlider && <HomeSlider homeSlider={homeSlider} latestBlog={latestBlog} />}
        <div className={css.filters}>
          <div className={css.categories}>
            <CurrentFilters
              currentFilters={currentFilters}
              handleOnClickCategory={selectCategory}
            />
          </div>
          <div className={css.orderBy}>
            <OrderBy
              orderBy={orderBy}
              orderByOptions={orderByOptions}
              handleOrderBy={handleOrderBy}
            />
          </div>
        </div>
        {switchStatus == 'Trabajar' ? (
          <ProjectsFeed
            currentFilters={currentFilters}
            orderBy={orderBy}
            currentPage={currentPage}
            handlePagination={handlePagination}
          />
        ) : (
          <FreelancersFeed
            currentFilters={currentFilters}
            orderBy={orderBy}
            currentPage={currentPage}
            handlePagination={handlePagination}
          />
        )}
      </BaseLayout.Content>
    </BaseLayout>
  );
}

export default Welcome;
