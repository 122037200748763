import { ADMIN_WINDOW_STATE } from '../actions/types.js';

const initialState = {
  windowState: 'HOME',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADMIN_WINDOW_STATE:
      return {
        ...state,
        windowState: action.payload,
      };
    default:
      return state;
  }
}
