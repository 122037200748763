import axios from 'axios';
import config from './index';

const axiosClient = axios.create({
  baseURL: config.backendUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default axiosClient;
