import { Modal } from 'antd';
import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

export const ModalConfirm = ({ title, content, onOk, onCancel }) => {
  return Modal.confirm({
    title: title,
    icon: <ExclamationCircleOutlined />,
    content: content,
    okText: 'Confirmar',
    cancelText: 'Cancelar',
    onOk: onOk,
    onCancel: onCancel,
  });
};
