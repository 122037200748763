/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { BLUR_IMAGE_ARRAY, LANDING_BOTTOM_BACKGROUND } from 'pages/Landing/landing.constants';
import { Button, Title } from '@components/common';
import { HOME_ROUTE } from '@common/constants/routes.constants';
import { useHistory } from 'react-router-dom';
import './Section1.scss';
import { PublicationModal } from 'components/layouts';
import AnimateOnView from 'components/AnimateOnView';

const Section1 = () => {
  const history = useHistory();

  const [showModal, setShowModal] = useState(false);

  const [count, setCount] = useState(0);
  const [isBlur, setIsBlur] = useState(true);

  useEffect(() => {
    const timerId = setInterval(() => {
      setCount((count) => count + 1);
    }, 8000);

    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    const removeBlur = setInterval(() => {
      setIsBlur(false);
    }, 500);

    return () => clearInterval(removeBlur);
  }, [count]);

  useEffect(() => {
    const addBlur = setInterval(() => {
      setIsBlur(true);
    }, 7500);

    return () => clearInterval(addBlur);
  }, [count]);

  useEffect(() => {
    setIsBlur(true);
  }, [count]);

  const handleOnClickServices = () => {
    history.push(HOME_ROUTE);
    window.localStorage.setItem('switchStatus', 'Contratar');
  };

  const image = BLUR_IMAGE_ARRAY[count % BLUR_IMAGE_ARRAY.length];

  return (
    <>
      <PublicationModal closeModal={() => setShowModal(false)} showModal={showModal} />

      <div
        className="section1__style"
        style={{
          backgroundImage: `url(${LANDING_BOTTOM_BACKGROUND})`,
        }}>
        <div className="section1__container">
          <AnimateOnView className="section1__left">
            <Title className="landing__title-desktop" type="landing" color="white">
              Tus proyectos de programación con los mejores freelancers
            </Title>
            <Title className="landing__title-mobile" size="35" type="xl" color="white">
              Tus proyectos de programación con los mejores freelancers
            </Title>
            <Title type="primary-nobold" color="white">
              Desarrolla tu sitio web, ecommerce, aplicación a medida, apps móviles o ¡lo que
              necesites!
            </Title>
            <div className="mobile__image">
              <div className={isBlur ? 'blur freelancer__image' : 'freelancer__image'}>
                <img src={image} height={100} className="w-100 img-fluid" alt="freelancers" />
              </div>
            </div>
            <div className="buttons__container">
              <Button
                type="landing-btn"
                text="¡Publica tu Proyecto!"
                variant="primaryProject"
                onClick={() => setShowModal(true)}
              />
              <Button
                type="landing-btn"
                text="¡Busca Programadores!"
                variant="secondaryFreelancer"
                onClick={handleOnClickServices}
              />
            </div>
          </AnimateOnView>
          <AnimateOnView className="section1__right">
            <div className={isBlur ? 'blur freelancer__image' : 'freelancer__image'}>
              <img src={image} height={100} className="w-100 img-fluid" alt="freelancers" />
            </div>
          </AnimateOnView>
        </div>
      </div>
    </>
  );
};

export default Section1;
