/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import './FreelancerProjectManagment.scss';
import { FileContainer, Text } from '@components/common';
import { MyProjectsMainCard } from '@components/project/ProjectMainCard/MyProjectsMainCard/MyProjectsMainCard';
import { LoadingDots } from '@components/common/';
import BoardDashboard from '@components/board/BoardDashboard/BoardDashboard';
import NewChat from 'components/common/NewChat';
import ClientPublicationDetailsAndQuestions from 'pages/Managment/ProjectsCommon/ProjectDetailsAndQuestions';
import ClientPublicationProposal from 'pages/Managment/ProjectsCommon/ProjectProposal';
import ClientProjectFreelancerProfile from 'pages/Managment/ProjectsCommon/ProfileContent';
import CustomTabs from 'components/common/CustomTabs';
import { TABS } from 'pages/Managment/ProjectsCommon/ProjectsTabs.constants';
import { BookOpen, File, Monitor, Star, User } from 'react-feather';
import { useMediaQuery } from '@material-ui/core';
import ProjectFeedback from '../../../ProjectsCommon/ProjectFeedback';

const FreelancerProjectTabs = ({
  currentProject,
  handleGetFiles,
  authUser,
  isLoadingFiles,
  files,
  questionsList,
  handleReplyQuestion,
  isLoadingQuestions,
  currentProposal,
  currentRequest,
  isLoadingProject,
  setRatingModal,
  isFinished,
  setRefetchProject,
}) => {
  const ratingUser = currentProject?.ratings?.find((rating) => rating.rating_type === 'client');
  const isCompleted = currentProject?.status === 'completed';
  const freelancerTabs = [
    {
      name: TABS.FEEDBACK,
      icon: <Star />,
      component: <ProjectFeedback ratingUser={ratingUser} />,
    },
    {
      name: TABS.PROYECT,
      icon: <Monitor />,
      component: (
        <>
          <NewChat
            chatID={currentProject?.chat_id}
            participants={[currentProject?.owner_data?.id, currentProject?.freellance_data?.id]}
            handleGetFiles={handleGetFiles}
            currentProject={currentProject}
            style={{ padding: '1em 0' }}
            disabled={isFinished}
            archivos={
              <FileContainer
                userId={authUser?.user.id}
                ownerImage={currentProject?.freellance_data.profile_image}
                freelanceImage={currentProject?.owner_data.profile_image}
                isLoadingFiles={isLoadingFiles}
                files={files}
                withButton={false}
                style={{ maxHeight: '350px', overflowY: 'auto' }}
              />
            }
          />
          <Text type="detail">Usa el tablero para ordenar tus tareas e informar a tu cliente.</Text>
          <BoardDashboard projectId={currentProject?.id} disabled={isFinished} />
        </>
      ),
    },
    {
      name: TABS.PUBLICATION,
      icon: <BookOpen />,
      component: (
        <ClientPublicationDetailsAndQuestions
          type="service"
          currentProject={currentProject}
          questionsList={questionsList}
          handleReplyQuestion={handleReplyQuestion}
          isLoadingQuestions={isLoadingQuestions}
        />
      ),
    },
    {
      name: TABS.PROPOSAL,
      icon: <File />,
      component: (
        <ClientPublicationProposal
          currentProposal={currentProposal}
          currentRequest={currentRequest}
          isFreelance
        />
      ),
    },
    {
      name: TABS.PROFILE.OWNER,
      icon: <User />,
      component: (
        <ClientProjectFreelancerProfile
          currentUser={currentProject?.owner_data}
          type="collaborator"
        />
      ),
    },
  ];
  const Mobile = useMediaQuery('(max-width:770px)');
  const DeskResponsiveEnd = useMediaQuery('(min-width:1017px)');
  const DeskResponsiveStart = useMediaQuery('(max-width:1160px)');
  const toggleIcons = (DeskResponsiveStart && DeskResponsiveEnd) || Mobile;
  return (
    <>
      {!isLoadingProject && currentProject ? (
        <Fragment>
          <CustomTabs
            tabs={isCompleted ? freelancerTabs : freelancerTabs.slice(1)}
            toggleIcons={toggleIcons}
            commonLayout={
              <MyProjectsMainCard
                style={{ borderTopLeftRadius: 0 }}
                type="detail"
                currentProject={currentProject}
                currentOwner={currentProject.owner_data}
                setRatingModal={!currentProject.collaborator_rating ? setRatingModal : null}
                withActions
                setRefetchProject={setRefetchProject}
              />
            }
          />
        </Fragment>
      ) : (
        <LoadingDots />
      )}
    </>
  );
};

export default FreelancerProjectTabs;
