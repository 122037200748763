import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuthUser } from '@reducers/auth';
import { getMainCategories, getSubCategories_v2 } from 'actions/categories';
import { centerElementById } from 'common/utils/WindowFocus';
import { searchSkills_v2 } from 'actions/skills';
import { createMessage } from 'actions/messages';
import projectService from 'services/projectService';
import { useParams } from 'react-router-dom';
import { variableQuestionsOrder } from 'common/constants/publicationForm.constants';

export const NewProjectFormViewModel = () => {
  const authUser = useSelector(selectAuthUser);
  const dispatch = useDispatch();

  const { type, id } = useParams();

  const [values, setValues] = React.useState({
    actual_state: null,
    category: null,
    sub_category: null,
    website_type: null,
    reference_urls: [],
    website_functionalities: ['Página de inicio.', 'Formulario de contacto.'],
    compatibility: null,
    actual_db_status: null,
    model_goal: [],
    api_integration: [],
    time_range: null,
    budget_range: null,
    description: null,
    skills: [],
    images: null,
    title: null,
  });

  const [branchStatus, setBranchStatus] = React.useState();
  const [currentFocus, setCurrentFocus] = React.useState('category');

  const [questionOrder, setQuestionOrder] = React.useState([]);

  const [mainCategories, setMainCategories] = React.useState([]);
  const [isLoadingMainCategories, setIsLoadingMainCategories] = React.useState(false);

  const [subCategories, setSubCategories] = React.useState([]);
  const [isLoadingSubCategories, setIsLoadingSubCategories] = React.useState(false);

  const [skillsOptions, setSkillsOptions] = React.useState([]);
  const [fetchingSkills, setFetchingSkills] = React.useState(false);

  const [displayedQuestions, setDisplayedQuestions] = React.useState(['category']);

  React.useEffect(() => {
    async function fetchData() {
      let tempQuestionsOrder = ['category', 'actual_state', 'sub_category'];
      if (values['category'] && values['actual_state']) {
        const tempBranchStatus = await projectService.loadBrachStatus(
          values['category'].id,
          values['actual_state'].id
        );
        setBranchStatus(tempBranchStatus);
        const variableList = await variableQuestionsOrder[tempBranchStatus];
        tempQuestionsOrder = tempQuestionsOrder.concat(variableList);
      }
      tempQuestionsOrder.push(
        'api_integration',
        'time_range',
        'budget_range',
        'description',
        'skills',
        'images',
        'title',
        'finalPreview'
      );

      setQuestionOrder(tempQuestionsOrder);

      if (values['category']) {
        searchSkills_v2({ categoryId: values['category']?.id })
          .then((res) => {
            setSkillsOptions(res.data);
            setFetchingSkills(false);
          })
          .catch(() => setFetchingSkills(false));
      }

      if (type == 'EDIT') {
        if (values['category']) {
          setIsLoadingSubCategories(true);
          getSubCategories_v2(values['category'].id)
            .then((res) => {
              setSubCategories(res.data);
              setIsLoadingSubCategories(false);
            })
            .catch(() => setIsLoadingSubCategories(false));
        }

        setDisplayedQuestions(tempQuestionsOrder);
        displayDistinctQuestion('finalPreview');
      }
    }
    fetchData();
  }, [values['actual_state'], values['category']]);

  React.useEffect(() => {
    async function fetchData() {
      if (type == 'EDIT') {
        const tempValues = await projectService.loadProject(id);
        setValues(tempValues);
      }

      setIsLoadingMainCategories(true);
      getMainCategories()
        .then((res) => {
          setMainCategories(res.data);
          setIsLoadingMainCategories(false);
        })
        .catch(() => setIsLoadingMainCategories(false));
    }
    fetchData();
  }, []);

  React.useEffect(() => {
    async function fetchData() {
      if (type == 'EDIT') {
        const tempValues = await projectService.loadProject(id);
        setValues(tempValues);
      }

      setIsLoadingMainCategories(true);
      getMainCategories()
        .then((res) => {
          setMainCategories(res.data);
          setIsLoadingMainCategories(false);
        })
        .catch(() => setIsLoadingMainCategories(false));
    }
    fetchData();
  }, []);

  const handleCurrentFocus = (name) => {
    setCurrentFocus(name);
    centerElementById(`${name}-container`);
  };

  const displayDistinctQuestion = async (name) => {
    if (!displayedQuestions.includes(name)) {
      await setDisplayedQuestions([...displayedQuestions, name]);
    }
    handleCurrentFocus(name);
  };

  const handleGoBackQuestion = (name) => {
    if (questionOrder.indexOf(name) > 0) {
      let position = questionOrder.indexOf(name) - 1;
      displayDistinctQuestion(questionOrder[position]);
    }
  };

  const nexQuestionConditions = (value) => {
    return {
      actual_state: {
        warningText: 'Debes seleccionar una opción antes de seguir avanzando.',
        condition: value != null,
      },
      category: {
        warningText: 'Debes seleccionar una opción antes de seguir avanzando.',
        condition: value != null,
      },
      sub_category: {
        warningText: 'Debes seleccionar una opción antes de seguir avanzando.',
        condition: value != null,
      },
      website_type: {
        warningText: 'Debes seleccionar una opción antes de seguir avanzando.',
        condition: value != null,
      },
      reference_urls: {
        warningText: '',
        condition: true,
      },
      website_functionalities: {
        warningText: 'Debes seleccionar una opción antes de seguir avanzando.',
        condition: value?.length > 0,
      },
      compatibility: {
        warningText: 'Debes seleccionar una opción antes de seguir avanzando.',
        condition: value != null,
      },
      actual_db_status: {
        warningText: 'Debes seleccionar una opción antes de seguir avanzando.',
        condition: value != null,
      },
      model_goal: {
        warningText: '',
        condition: true,
      },
      api_integration: {
        warningText: '',
        condition: true,
      },
      time_range: {
        warningText: 'Debes seleccionar una opción antes de seguir avanzando.',
        condition: value != null,
      },
      budget_range: {
        warningText: 'Debes seleccionar una opción antes de seguir avanzando.',
        condition: value != null,
      },
      description: {
        warningText:
          'Por favor ingrese una descripción más larga (por lo menos 100 caracteres). Una descripción más larga ayudará a los freelancers a encontrar su proyecto y le enviarán propuestas de buena calidad.',
        condition: value?.length > 99,
      },
      skills: {
        warningText: 'Debes seleccionar entre 1 y 5 habilidades',
        condition: value?.length > 0 && value?.length < 6,
      },
      images: {
        warningText: '',
        condition: true,
      },
      title: {
        warningText: 'El nombre del proyecto debe tener al menos 7 caracteres.',
        condition: value?.length > 6,
      },
    };
  };

  const handleGoForwardQuestion = (name, value) => {
    const answer = value ? value : values[name];
    if (questionOrder.indexOf(name) < questionOrder.length) {
      let position = questionOrder.indexOf(name) + 1;
      if (nexQuestionConditions(answer)[name]) {
        const { warningText, condition } = nexQuestionConditions(answer)[name];
        if (condition) {
          displayDistinctQuestion(questionOrder[position]);
        } else {
          dispatch(
            createMessage({
              errorMessage: warningText,
            })
          );
        }
      } else {
        displayDistinctQuestion(questionOrder[position]);
      }
    }
  };

  const hideAllQuestions = () => {
    setDisplayedQuestions(
      displayedQuestions.filter((funct) =>
        ['category', 'actual_state', 'sub_category'].includes(funct)
      )
    );
  };

  const handleSelectCategory = (category) => {
    setValues({
      ...values,
      category: category,
      sub_category: null,
      website_type: null,
      website_functionalities: ['Página de inicio.', 'Formulario de contacto.'],
      actual_db_status: null,
      model_goal: [],
      compatibility: null,
    });
    setIsLoadingSubCategories(true);
    getSubCategories_v2(category.id)
      .then((res) => {
        setSubCategories(res.data);
        setIsLoadingSubCategories(false);
        hideAllQuestions();
        handleGoForwardQuestion('category', category);
      })
      .catch(() => setIsLoadingSubCategories(false));
  };

  const handleSelectActualState = (actual_state) => {
    setValues({
      ...values,
      actual_state: actual_state,
      sub_category: null,
      website_type: null,
      website_functionalities: ['Página de inicio.', 'Formulario de contacto.'],
      actual_db_status: null,
      model_goal: [],
      compatibility: null,
    });
    hideAllQuestions();
    handleGoForwardQuestion('actual_state', actual_state);
  };

  const handleSelectSubCategory = (sub_category) => {
    setValues({
      ...values,
      sub_category: sub_category,
    });
    handleGoForwardQuestion('sub_category', sub_category);
  };

  const handleSelectWebsiteType = (website_type) => {
    setValues({
      ...values,
      website_type: website_type,
    });
    handleGoForwardQuestion('website_type', website_type);
  };

  const handleSelectReferenceUrls = (reference_urls) => {
    setValues({
      ...values,
      reference_urls: reference_urls,
    });
  };

  const handleSelectWebsiteFunctionalities = (website_functionality) => {
    if (values['website_functionalities']?.includes(website_functionality.name)) {
      setValues({
        ...values,
        website_functionalities: values['website_functionalities'].filter(
          (funct) => funct !== website_functionality.name
        ),
      });
    } else {
      setValues({
        ...values,
        website_functionalities: [...values['website_functionalities'], website_functionality.name],
      });
    }
  };

  const handleSelectCompatibility = (compatibility) => {
    setValues({
      ...values,
      compatibility: compatibility,
    });
    handleGoForwardQuestion('compatibility', compatibility);
  };

  const handleSelectActualDBStatus = (actual_db_status) => {
    setValues({
      ...values,
      actual_db_status: actual_db_status,
    });
    handleGoForwardQuestion('actual_db_status', actual_db_status);
  };

  const handleSelectModelGoal = (model_goal) => {
    if (values['model_goal']?.includes(model_goal.name)) {
      setValues({
        ...values,
        model_goal: values['model_goal'].filter((funct) => funct !== model_goal.name),
      });
    } else {
      setValues({
        ...values,
        model_goal: [...values['model_goal'], model_goal.name],
      });
    }
  };

  const handleSelectAPIIntegration = (api_integration) => {
    if (values['api_integration']?.includes(api_integration.name)) {
      setValues({
        ...values,
        api_integration: values['api_integration'].filter(
          (funct) => funct !== api_integration.name
        ),
      });
    } else {
      setValues({
        ...values,
        api_integration: [...values['api_integration'], api_integration.name],
      });
    }
  };

  const handleSelectTimeRange = (time_range) => {
    setValues({
      ...values,
      time_range: time_range,
    });
    handleGoForwardQuestion('time_range', time_range);
  };

  const handleSelectBudgetRange = (budget_range) => {
    setValues({ ...values, budget_range: budget_range });
    handleGoForwardQuestion('budget_range', budget_range);
  };

  const handleOnChangeDescription = (value) => {
    setValues({ ...values, description: value });
  };

  const fetchSkills = async (value) => {
    setFetchingSkills(true);
    searchSkills_v2({ variable: value, categoryId: values['category']?.id })
      .then((res) => {
        setSkillsOptions(res.data);
        setFetchingSkills(false);
      })
      .catch(() => setFetchingSkills(false));
  };

  const handleSelectSkills = (value) => {
    setValues({ ...values, skills: value });
  };

  const handleOnChangeTitle = (value) => {
    setValues({ ...values, title: value });
  };

  const handleChangeImages = (info) => {
    let newFileList = info.fileList;
    newFileList.map((image) => {
      image.status = 'done';
    });
    setValues({ ...values, images: newFileList });
  };

  return {
    authUser,
    values,
    branchStatus,
    mainCategories,
    isLoadingMainCategories,
    subCategories,
    isLoadingSubCategories,
    handleSelectActualState,
    handleSelectCategory,
    handleSelectSubCategory,
    handleSelectWebsiteType,
    handleSelectWebsiteFunctionalities,
    handleSelectCompatibility,
    handleSelectTimeRange,
    handleSelectBudgetRange,
    handleSelectActualDBStatus,
    handleSelectModelGoal,
    handleSelectAPIIntegration,
    handleOnChangeDescription,
    currentFocus,
    handleCurrentFocus,
    questionOrder,
    fetchSkills,
    skillsOptions,
    fetchingSkills,
    handleSelectSkills,
    handleOnChangeTitle,
    handleChangeImages,
    displayedQuestions,
    setDisplayedQuestions,
    displayDistinctQuestion,
    handleGoBackQuestion,
    handleGoForwardQuestion,
    handleSelectReferenceUrls,
    type,
    id,
  };
};
