import React from 'react';
import './SocialMediaInput.scss';
import { Form, Input } from 'antd';
import { Button, Text, Title } from '@components/common';

const { TextArea } = Input;

export const SocialMediaInput = ({ type, user, onSubmitSocialMedia }) => {
  const socialInfo = {
    github: {
      name: 'github_link',
      icon: 'fab fa-github',
      title: 'Github',
      text: 'Porfavor ingresa la url de tu perfil de Github.',
    },
    stackoverflow: {
      name: 'stackoverflow_link',
      icon: 'fab fa-stack-overflow',
      title: 'Stackoverflow',
      text: 'Porfavor ingresa la url de tu perfil de Stackoverflow.',
    },
    briefcase: {
      name: 'briefcase_link',
      icon: 'fa fa-briefcase',
      title: 'Portafolio',
      text: 'En tu portafolio puedes agregar una url que ayude al cliente a conocer más tu trabajo.',
    },
  };

  return (
    <div className="social-media-input__container">
      <div className="social-media-input__icon">
        <i className={socialInfo[type].icon}></i>
      </div>
      <div className="social-media-input__title">
        <Title type="secondary center">{socialInfo[type].title} </Title>
      </div>
      <div className="social-media-input__text">
        <Text>{socialInfo[type].text}</Text>
      </div>
      <div className="social-media-input__form">
        <Form
          name="SocialMediaForm"
          onFinish={onSubmitSocialMedia}
          initialValues={{
            github_link: user.github_link,
            stackoverflow_link: user.stackoverflow_link,
            briefcase_link: user.briefcase_link,
          }}>
          {
            <Form.Item name={socialInfo[type].name}>
              <TextArea
                maxLength={1000}
                autoSize={{ minRows: 5 }}
                placeholder="Pega aquí la url."
              />
            </Form.Item>
          }

          <Button text="Guardar" variant="secondary" borderColor="primary" size="sm" />
        </Form>
      </div>
    </div>
  );
};
