import React, { useEffect, useState } from 'react';
import { Text } from 'components/common';
import css from '../Forms.module.scss';
import { getTemplatesByCategoryName } from 'actions/forms';

const OptionType = ({
  option,
  handleSetAnswer,
  isOptionSelected,
  type,
  setTemplates,
  setSelectedCategory,
  isVertical,
}) => {
  const [checkCategory, setCheckCategory] = useState(false);
  useEffect(() => {
    if (type === 'templateCategory' && isOptionSelected(option)) {
      setSelectedCategory(option?.value);
      getTemplatesByCategoryName({ categoryName: option?.value }).then((res) =>
        setTemplates(
          res?.data?.data?.map((t) => ({
            id: t?.id,
            img: t?.attributes?.img?.data?.attributes?.url,
          }))
        )
      );
    }
  }, [type, checkCategory]);

  const handleAnswer = (option, type) => {
    handleSetAnswer(option, type);
    if (type === 'templateCategory') {
      setCheckCategory(!checkCategory);
    }
  };
  return (
    <>
      {isVertical ? (
        <div style={{ maxWidth: 480, width: '100%', margin: '0 auto' }}>
          <Vertical
            option={option}
            handleAnswer={handleAnswer}
            isOptionSelected={isOptionSelected}
            type={type}
          />
        </div>
      ) : (
        <Horizontal
          option={option}
          handleAnswer={handleAnswer}
          isOptionSelected={isOptionSelected}
          type={type}
        />
      )}
    </>
  );
};

const Horizontal = ({ option, handleAnswer, isOptionSelected, type }) => (
  <div className={css.option} onClick={() => handleAnswer(option, type)}>
    <img
      src={option.img}
      alt="option"
      style={isOptionSelected(option) ? { border: '2px solid #3383ff', opacity: 1 } : {}}
    />
    <Text> {option.label}</Text>
  </div>
);

const Vertical = ({ option, handleAnswer, isOptionSelected, type }) => (
  <div
    className={css.optionVertical}
    style={isOptionSelected(option) ? { border: '2px solid #3383ff' } : {}}
    onClick={() => handleAnswer(option, type)}>
    <Text> {option.label}</Text>
  </div>
);

export default OptionType;
