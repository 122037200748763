import { Carousel } from 'antd';
import { Text, Title } from 'components/common';
import css from '../PreviousProjects.module.scss';
import React from 'react';
import StandardModal from 'components/common/StandardModal';
import { addHttps } from 'common/utils/Formaters';

const InfoPrevProjectModal = ({ onCancel, visible, project }) => {
  return (
    <StandardModal width={800} onCancel={onCancel} visible={visible}>
      <section className={css.infoContainer}>
        <div className={css.infoSkills}>
          {project?.url ? (
            <a
              href={addHttps(project?.url)}
              target="_blank"
              rel="noreferrer"
              className={css.titleUrl}>
              <Title color="primary">{project?.title}</Title>
            </a>
          ) : (
            <Title color="primary">{project?.title}</Title>
          )}

          <Text>{project?.description}</Text>
          <ul className={css.skills}>
            {project?.skills_names?.map((name) => (
              <li key={name}>
                <Text>{name}</Text>
              </li>
            ))}
          </ul>
        </div>
        <div className={css.images}>
          <img
            alt="projects-portada"
            src={project?.front_image?.upload}
            style={{ marginBottom: '2em' }}
          />
          <Carousel autoplay>
            {project?.gallery_images?.map((img, idx) => (
              <img key={idx} alt="pojects-img" src={img?.upload} />
            ))}
          </Carousel>
        </div>
      </section>
    </StandardModal>
  );
};

export default InfoPrevProjectModal;
