import React from 'react';
import { Paperclip } from 'react-feather';

const UploadClip = ({
  onUpload,
  isDisabled,
  fileTypes = '.png, .jpg, .jpeg, .doc, .xls, .pdf, .docx, .xlsx, .PNG',
  id,
  ...rest
}) => {
  return (
    <>
      <input
        style={{
          display: 'none',
        }}
        accept={fileTypes}
        disabled={isDisabled}
        onChange={onUpload}
        id={id}
        type="file"
      />
      <label htmlFor={id}>
        <Paperclip aria-label="file-upload" {...rest} />
      </label>
    </>
  );
};

export default UploadClip;
