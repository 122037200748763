import React from 'react';
import Carousel from 'react-multi-carousel';
import { Title } from '@components/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserCheck,
  faLock,
  faPersonRays,
  faWifi,
  faHandshakeSimple,
} from '@fortawesome/free-solid-svg-icons';
import {
  DESKTOP_BREAKPOINT,
  TABLET_BREAKPOINT,
  MOBILE_BREAKPOINT,
  SMALLMOBILE_BREAKPOINT,
  XS_SMALLMOBILE_BREAKPOINT,
} from 'pages/Landing/landing.constants';
import 'react-multi-carousel/lib/styles.css';
import './Section2.scss';
import AnimateOnView from 'components/AnimateOnView';

const responsive = {
  desktop: {
    breakpoint: { max: DESKTOP_BREAKPOINT, min: TABLET_BREAKPOINT },
    items: 3,
  },
  tablet: {
    breakpoint: { max: TABLET_BREAKPOINT, min: MOBILE_BREAKPOINT },
    items: 3,
  },
  mobile: {
    breakpoint: { max: MOBILE_BREAKPOINT, min: SMALLMOBILE_BREAKPOINT },
    items: 3,
  },
  smallmobile: {
    breakpoint: { max: SMALLMOBILE_BREAKPOINT, min: XS_SMALLMOBILE_BREAKPOINT },
    items: 2,
  },
  xsmallmobile: {
    breakpoint: { max: XS_SMALLMOBILE_BREAKPOINT, min: 0 },
    items: 1,
  },
};

const Section2 = () => {
  return (
    <div className="section2__container">
      <AnimateOnView className="section2__content">
        <Carousel
          infinite
          autoPlay
          autoPlaySpeed={4000}
          arrows
          responsive={responsive}
          transitionDuration={500}
          swipeable={true}>
          <div className="advantages__card">
            <div className="advantages__top">
              <div className="advantages__icon">
                <FontAwesomeIcon icon={faPersonRays} />
              </div>
              <Title type="primary" color="primary">
                ¡Tú decides!
              </Title>
            </div>
            <div className="advantages__bottom">
              <Title strong>
                Maneja tus propios horarios, elige los proyectos que más te gusten, reduce costos y
                trabaja en varios al mismo tiempo. Recuerda que eres tu jefe.
              </Title>
            </div>
          </div>
          <div className="advantages__card">
            <div className="advantages__top">
              <div className="advantages__icon">
                <FontAwesomeIcon icon={faUserCheck} />
              </div>
              <Title type="primary" color="primary">
                Contrata fácil
              </Title>
            </div>
            <div className="advantages__bottom">
              <Title strong>
                Encuentra a los mejores freelancers para pogramar, explora sus perfiles, negocia y
                chatea con ellos y puntéalos cuando termine el proyecto.
              </Title>
            </div>
          </div>
          <div className="advantages__card">
            <div className="advantages__top">
              <div className="advantages__icon">
                <FontAwesomeIcon icon={faLock} />
              </div>
              <Title type="primary" color="primary">
                Seguridad
              </Title>
            </div>
            <div className="advantages__bottom">
              <Title strong>
                Ofrecemos el pago por garantía que da seguridad para ambas partes y se libera cuando
                tú estás satisfecho/a con el trabajo realizado.
              </Title>
            </div>
          </div>
          <div className="advantages__card ">
            <div className="advantages__top">
              <div className="advantages__icon">
                <FontAwesomeIcon icon={faWifi} />
              </div>
              <Title type="primary" color="primary">
                Trabaja conectado
              </Title>
            </div>
            <div className="advantages__bottom">
              <Title strong>
                ¡Conéctate desde donde quieras! Mantente al día con el avance de tu proyecto por
                medio del chat, de los archivos y el tablero kanban que puede ayudarte a ordenar tus
                ideas.
              </Title>
            </div>
          </div>
          <div className="advantages__card">
            <div className="advantages__top">
              <div className="advantages__icon">
                <FontAwesomeIcon icon={faHandshakeSimple} />
              </div>
              <Title type="primary" color="primary">
                ¡No estás solo!
              </Title>
            </div>
            <div className="advantages__bottom">
              <Title strong>
                Recuerda que estamos contigo durante todo el proceso para ayudarte en lo que
                necesites, ya sea buscar al freelancer adecuado, resolver dudas o hacer seguimiento
                de tu proyecto.
              </Title>
            </div>
          </div>
        </Carousel>
      </AnimateOnView>
    </div>
  );
};

export default Section2;
