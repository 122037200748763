import React from 'react';
import WebSocketInstance from './websocket';
import { RenderTimestamp } from '@components/common/index';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 50 }} spin />;

export class Chat extends React.Component {
  state = {
    message: '',
    messages: [],
    loadingChat: true,
  };

  createWebSocketInstance = () => {
    WebSocketInstance.addCallbacks(this.setMessages.bind(this), this.addMessage.bind(this));
  };

  initialiseChat = () => {
    this.waitForSocketConnection(() => {
      WebSocketInstance.fetchMessages(this.props.user.email, this.props.selectedChat.id);
    });
    WebSocketInstance.connect(this.props.selectedChat.id);
  };

  componentWillUnmount() {
    if (this.state.conected) {
      WebSocketInstance.disconnect();
    }
  }

  waitForSocketConnection(callback) {
    const component = this;
    setTimeout(function () {
      if (WebSocketInstance.state() === 1) {
        callback();
        return;
      } else {
        component.waitForSocketConnection(callback);
      }
    }, 100);
  }

  addMessage(message) {
    this.setState({
      messages: [...this.state.messages, message],
    });
  }

  setMessages(messages) {
    this.setState({ messages: messages, loadingChat: false });
  }

  messageChangeHandler = (event) => {
    this.setState({
      message: event.target.value,
    });
  };

  sendMessageHandler = (e) => {
    e.preventDefault();
    const messageObject = {
      from: this.props.user.email,
      content: this.state.message,
      chatId: this.props.selectedChat.id,
      token: this.props.user.oauth_token,
      created_at: Date.now(),
    };
    //this.addMessage(messageObject);
    WebSocketInstance.newChatMessage(messageObject);
    this.setState({ message: '' });
  };

  renderMessages = (messages) => {
    const currentUser = this.props.user.id;
    return messages.map((message, i, arr) => (
      <li
        key={message.id}
        style={{ marginBottom: arr.length - 1 === i ? '20px' : '5px' }}
        className={message.owner !== currentUser ? 'sent' : 'replies'}>
        <img
          src={
            message.owner !== currentUser
              ? this.props.otherUser.profile_image
              : this.props.user.profile_image
          }
          alt={'AltPhoto'}
        />
        <p>
          {message.content}
          <br />
          <small>{RenderTimestamp(message.created_at)}</small>
        </p>
      </li>
    ));
  };

  scrollToBottom = () => {
    var elem = document.getElementById('messagesCtn');
    if (elem) {
      elem.scrollTop = elem.scrollHeight;
    }
  };

  componentDidMount() {
    this.createWebSocketInstance();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedChat !== prevProps.selectedChat ||
      (this.props.selectedChat && !prevProps.selectedChat)
    ) {
      WebSocketInstance.disconnect();
      this.initialiseChat();
      this.setState({ loadingChat: true });
    }
    if (prevState.messages !== this.state.messages) {
      this.scrollToBottom();
    }
  }

  render() {
    const messages = this.state.messages;
    return (
      <div className="messagesCtn">
        <div className="messages" id="messagesCtn">
          {this.state.loadingChat ? (
            <div className="loadingChatSpin">
              <Spin tip="Cargando..." indicator={antIcon} />
            </div>
          ) : (
            <ul id="chat-log">
              {messages.length > 0 && this.renderMessages(messages)}
              <div
                style={{ float: 'left', clear: 'both' }}
                ref={(el) => {
                  this.messagesEnd = el;
                }}></div>
            </ul>
          )}
        </div>
        <div className="chat-separation"></div>
        {!this.state.loadingChat ? (
          <div className="message-input">
            <form className="form-input-chat" onSubmit={this.sendMessageHandler}>
              <div className="wrap">
                <input
                  onChange={this.messageChangeHandler}
                  value={this.state.message}
                  required
                  id="chat-message-input"
                  type="text"
                  placeholder="Escribe aquí..."
                />
                <button id="chat-message-submit" className="submit">
                  <i className="fa fa-paper-plane" aria-hidden="true"></i>
                </button>
              </div>
            </form>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  selectedChat: state.chat.selectedChat,
  otherUser: state.navigation.user,
});

export default connect(mapStateToProps, {})(Chat);
