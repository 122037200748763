/* eslint-disable no-unused-vars */
import React from 'react';
import { Input, Select } from 'antd';
import { PhoneNumberInput, Text } from 'components/common';
import { COUNTRY_PREFIX_LISTS } from 'common/constants/registercountries.constants';
const myInfo = [
  { name: 'first_name', label: 'Nombre:' },
  { name: 'last_name', label: 'Apellidos:' },
];
const MyInformationProposal = ({ user, useEditUser }) => {
  const [isEditing, setIsEditing] = React.useState('');
  const [userForm, setUserForm] = React.useState({
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    phone_number: user?.phone_number || '',
    phone_prefix: user?.phone_prefix || '',
  });
  const handleChange = (e) => {
    setUserForm({ ...userForm, [e.target.name]: e.target.value });
  };
  return (
    <div>
      <Text style={{ marginBottom: '1em' }}>Información personal</Text>
      {myInfo.map((info, idx) => (
        <div key={idx} style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
          <Text style={{ width: 180, color: isEditing === info.name ? '#3383ff' : '' }}>
            {info.label}
          </Text>
          <MyInformationInput
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            value={userForm[info.name]}
            handleChange={handleChange}
            name={info.name}
            useEditUser={useEditUser}
          />
        </div>
      ))}
      <PhoneInput
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        phone_value={userForm.phone_number}
        prefix_value={userForm.phone_prefix}
        handleChange={handleChange}
        phone_name={'phone_number'}
        prefix_name={'phone_prefix'}
        useEditUser={useEditUser}
        setUserForm={setUserForm}
      />
    </div>
  );
};
const MyInformationInput = ({
  isEditing,
  setIsEditing,
  value,
  handleChange,
  name,
  useEditUser,
}) => {
  const onBlur = () => {
    useEditUser({ [name]: value });
    setIsEditing('');
  };
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', gap: '1em', width: '100%', maxWidth: 280 }}>
      <Input
        style={{
          width: '100%',
          borderRadius: '1em',
          margin: '0.5em 0',
          backgroundColor: isEditing === name ? '' : '#cccc',
        }}
        onFocus={() => setIsEditing(name)}
        onBlur={onBlur}
        name={name}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

const PhoneInput = ({
  isEditing,
  setIsEditing,
  handleChange,
  useEditUser,
  phone_value,
  prefix_value,
  phone_name,
  prefix_name,
  setUserForm,
}) => {
  const onBlur = () => {
    useEditUser({ [phone_name]: phone_value });
    setIsEditing('');
  };
  const handleChangePrefix = (value) => {
    setUserForm((prev) => ({ ...prev, [prefix_name]: value }));
    useEditUser({ [prefix_name]: value });
  };
  return (
    <div style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
      <Text style={{ width: 180 }}>Teléfono:</Text>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1em',
          width: '100%',
          maxWidth: 280,
        }}>
        <Select
          placeholder="Prefijo"
          name={prefix_name}
          value={prefix_value}
          onChange={handleChangePrefix}>
          {COUNTRY_PREFIX_LISTS.map((country, key) => (
            <Select.Option value={country.prefix} key={`opt-${key}`}>
              {country.name + ' ' + country.prefix}
            </Select.Option>
          ))}
        </Select>
        <Input
          style={{
            width: '100%',
            borderRadius: '1em',
            margin: '0.5em 0',
            backgroundColor: isEditing === phone_name ? '' : '#cccc',
          }}
          onFocus={() => setIsEditing(phone_name)}
          onBlur={onBlur}
          name={phone_name}
          value={phone_value}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};
export default MyInformationProposal;
