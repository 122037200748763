import React from 'react';
import { useSelector } from 'react-redux';
import { selectAuthUser } from '@reducers/auth';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { openLeftWindow } from '@actions/sideWindows';

export const HeaderViewModel = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const authUser = useSelector(selectAuthUser);

  const [showRightMenu, setShowRightMenu] = React.useState(false);
  const [showPublicationModal, setShowPublicationModal] = React.useState(false);
  const [currentLink, setCurrentLink] = React.useState();

  React.useEffect(() => {
    setCurrentLink(location.pathname);
  }, [location]);

  const handleOpenLeftWindow = () => {
    dispatch(openLeftWindow());
  };

  return {
    history,
    authUser,
    showRightMenu,
    setShowRightMenu,
    handleOpenLeftWindow,
    showPublicationModal,
    setShowPublicationModal,
    currentLink,
  };
};
