import React from 'react';
import { BaseLayout } from '@components/common';
import { MoreConfigurationsViewModel } from './MoreConfigurationsViewModel';
import { Settings } from './Settings';
import { BankAccountDrawer } from '@components/bankAccounts/BankAccountDrawer';
import './MoreConfigurations.scss';
import { BankAccounts } from './BankAccounts';
import { Notifications } from '../Notifications';
import { useHistory } from 'react-router-dom';
import {
  BANK_ACCOUNTS,
  MORECONFIGURATIONS,
  NOTIFICATIONS,
} from 'common/constants/routes.constants';
import { Bell, User, CreditCard } from 'react-feather';
const MoreConfigurations = ({ route }) => {
  const {
    userData,
    bankAccountsList,
    isLoadingBankAccounts,
    editingPersonalData,
    setEditingPersonalData,
    handleInputChange,
    handleSelectChange,
    handleonChangeBirth,
    handleEditUser,
    handleChangePassword,
    bankAccountDrawer,
    setBankAccountDrawer,
    handleSubmitAccount,
    currentBankAccount,
    setCurrentBankAccount,
    handleClickBankAccountCard,
    handleDeleteBankAccount,
    handleSelectBankAccount,
  } = MoreConfigurationsViewModel();
  const history = useHistory();
  return (
    <BaseLayout>
      <BankAccountDrawer
        account={currentBankAccount}
        handleSubmitAccount={handleSubmitAccount}
        bankAccountDrawer={bankAccountDrawer}
        setBankAccountDrawer={setBankAccountDrawer}
      />

      <BaseLayout.LeftSide>
        <div className="tn-layout__left-section">
          <button
            className={`more-configurations__button${
              route === MORECONFIGURATIONS ? '--active' : ''
            }`}
            onClick={() => history.push(MORECONFIGURATIONS)}>
            <User />
            Datos personales
          </button>
          <button
            className={`more-configurations__button${route === NOTIFICATIONS ? '--active' : ''}`}
            onClick={() => history.push(NOTIFICATIONS)}>
            <Bell />
            Notificaciones
          </button>
          <button
            className={`more-configurations__button${route === BANK_ACCOUNTS ? '--active' : ''}`}
            onClick={() => history.push(BANK_ACCOUNTS)}>
            <CreditCard />
            Cuentas Bancarias
          </button>
        </div>
      </BaseLayout.LeftSide>
      <BaseLayout.Content>
        {route === MORECONFIGURATIONS ? (
          <Settings
            userData={userData}
            editingPersonalData={editingPersonalData}
            setEditingPersonalData={setEditingPersonalData}
            handleInputChange={handleInputChange}
            handleSelectChange={handleSelectChange}
            handleonChangeBirth={handleonChangeBirth}
            handleEditUser={handleEditUser}
            handleChangePassword={handleChangePassword}
            setBankAccountDrawer={setBankAccountDrawer}
          />
        ) : route == BANK_ACCOUNTS ? (
          <BankAccounts
            bankAccountsList={bankAccountsList}
            setBankAccountDrawer={setBankAccountDrawer}
            handleClickBankAccountCard={handleClickBankAccountCard}
            setCurrentBankAccount={setCurrentBankAccount}
            handleSelectBankAccount={handleSelectBankAccount}
            handleDeleteBankAccount={handleDeleteBankAccount}
            isLoadingBankAccounts={isLoadingBankAccounts}
          />
        ) : route == NOTIFICATIONS ? (
          <Notifications />
        ) : (
          ''
        )}
      </BaseLayout.Content>
    </BaseLayout>
  );
};

export default MoreConfigurations;
