/* eslint-disable no-unused-vars */
import React from 'react';
import css from './PublicationQuestions.module.scss';
import { QuestionBox } from './QuestionBox';
import { InformativeAdvice, Text, Title } from 'components/common';
import { PERSON_6_ILUSTRATION } from '@common/constants/informativeIlustrations.constants';
import RenderIf from '../RenderIf';
import { ViewProfileDrawer } from '@components/profile/ViewProfileDrawer/ViewProfileDrawer';
import { getUser_v2 } from 'actions/users';
import usePublicationQuestions from './usePublicationQuestions';

const PublicationQuestions = ({
  type,
  questionsList,
  handleReplyQuestion,
  answerable = true,
  isReplying,
  noTitle = false,
  withScroll = true,
}) => {
  const { getColor, currentSelectedUser, viewProfileDrawer, handleDrawer, setViewProfileDrawer } =
    usePublicationQuestions({ type });

  return (
    <>
      <ViewProfileDrawer
        currentUser={currentSelectedUser}
        type="project"
        userDrawer={viewProfileDrawer}
        setUserDrawer={setViewProfileDrawer}
      />
      <div className={css.container} id="publication-questions-container">
        {!noTitle && <Text type="detail">{`Preguntas públicas (${questionsList?.length}):`}</Text>}
        {questionsList?.length == 0 ? (
          <div className={css.adviceContainer}>
            <InformativeAdvice
              image={PERSON_6_ILUSTRATION}
              message="Aún no hay preguntas. En esta sección podrás ver y responder las preguntas de otros usuarios."
            />
          </div>
        ) : (
          <div className={css.card}>
            <div
              className={css.content}
              style={{
                overflowY: withScroll ? 'scroll' : '',
                maxHeight: withScroll ? '80vh' : '',
              }}>
              {questionsList?.map((question, index) => (
                <div
                  className={css.item}
                  key={`question-${index}`}
                  id={`publication-questions-${question.id}`}>
                  <div className={css.question}>
                    {' '}
                    <img src={question.user_profile_picture}></img>
                    <div>
                      <Title
                        type="tertiary"
                        color="primary"
                        className={css.questionTitle}
                        onClick={() => handleDrawer(question.emitter)}>
                        {question.emitter_name}
                      </Title>
                      <Text type="detail">{question.question_text}</Text>
                    </div>
                  </div>
                  <div className={css.answer}>
                    <QuestionBox
                      question={question}
                      handleReplyQuestion={handleReplyQuestion}
                      getColor={getColor}
                      isReplying={isReplying}
                      answerable={answerable}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PublicationQuestions;
