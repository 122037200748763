import React, { Fragment } from 'react';
import css from '../../PostCard.module.scss';

function SecondaryCardContent({ postAttr }) {
  return (
    <Fragment>
      <div className={css.category}>
        <p className="tn-text tn-text--regular primaryColor">{postAttr?.category}</p>
      </div>{' '}
      <title className={css.secondaryTitle}>
        <p className="tn-text tn-text--big">{postAttr?.title}</p>
      </title>
    </Fragment>
  );
}

export default SecondaryCardContent;
