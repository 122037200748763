import React from 'react';
import './ChatInput.scss';
import { Input } from 'antd';
const { TextArea } = Input;

const ChatInput = ({ value, onChange }) => {
  return (
    <TextArea
      autoSize={{ minRows: 1, maxRows: 30 }}
      className="chat__input"
      value={value}
      onChange={onChange}
      required
      type="text"
      placeholder="Escribe aquí..."
      maxLength={1000}
    />
  );
};

export default ChatInput;
