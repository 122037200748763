import { Card, Col, Rate, Row } from 'antd';
import { Text, Title } from 'components/common';
import React from 'react';
import '../../../assets/styles/viewProject/RateWindow.scss';
const ProjectFeedback = ({ ratingUser }) => {
  const type = ratingUser?.rating_type === 'client' ? 'freelancer' : 'client';
  return (
    <Card style={{ borderRadius: '1em' }}>
      <Title type="secondary" color="primary" style={{ padding: '5px 15px' }}>
        Te han dejado la siguiente calificación:
      </Title>
      <Row className="window-container">
        <Col sm={12} xs={24}>
          <Title style={{ margin: 0 }}>Calidad de comunicación.</Title>
          <div className="rate-container">
            <Rate defaultValue={ratingUser?.rating_communication} disabled />
          </div>
          <Title style={{ margin: 0 }}>
            {type === 'client'
              ? 'Claridad de tus instrucciones e información.'
              : 'Calidad del trabajo.'}
          </Title>
          <div className="rate-container">
            <Rate
              defaultValue={
                type === 'client' ? ratingUser?.rating_clarity : ratingUser?.rating_quality
              }
              disabled
            />
          </div>
          <Title style={{ margin: 0 }}>
            {type === 'client'
              ? 'Respeto de los términos acordados en un comienzo.'
              : 'Puntualidad y disponibilidad para responder.'}
          </Title>
          <div className="rate-container">
            <Rate
              defaultValue={
                type === 'client' ? ratingUser?.rating_agreed_terms : ratingUser?.rating_punctuality
              }
              disabled
            />
          </div>
          <Title style={{ margin: 0 }}>Comentario general del trabajo</Title>
          <div className="rate-container" style={{ wordBreak: 'break-word', textAlign: 'justify' }}>
            <Text>{ratingUser?.comment}</Text>
          </div>
        </Col>
        <Col
          sm={12}
          xs={24}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ paddingLeft: '2em' }}>
            <Title type="secondary" color="primary" style={{ margin: 0 }}>
              Puntuación promedio
            </Title>
            <div className="rate-container" style={{ textAlign: 'center' }}>
              <Rate defaultValue={ratingUser?.rating_average} disabled />
            </div>
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default ProjectFeedback;
