import React, { Fragment } from 'react';
import './DescriptiveQuestions.scss';
import { Form, Input } from 'antd';
import { Title } from '@components/common';
const { TextArea } = Input;

export function DescriptiveQuestions() {
  return (
    <Fragment>
      <Title type="tertiary" color="primary">
        Por qué eres el indicado
      </Title>
      <Form.Item
        name="why_you"
        rules={[{ required: true, message: 'Por favor completa este campo.' }]}>
        <TextArea
          autoSize={{ minRows: 3, maxRows: 30 }}
          placeholder="Ejemplo: He realizado este tipo de proyectos de E-commerce con Shopify. Llevo 3 años desarrollando en Liquid, y me motiva poder entregar a mis clientes las herramientas para que puedan desarrollar sus tiendas online para el éxito de su negocio…"
          maxLength={1000}
          showCount
        />
      </Form.Item>
      <Title type="tertiary" color="primary">
        Descripción de la propuesta
      </Title>

      <Form.Item
        name="description"
        rules={[{ required: true, message: 'Por favor completa este campo.' }]}>
        <TextArea
          autoSize={{ minRows: 3, maxRows: 30 }}
          placeholder="Ejemplo: En una primera etapa, se hará la maquetación de la plataforma con foco en las funcionalidades y sin mayor detalle en el diseño. Luego, para una segunda etapa…"
          maxLength={10000}
          showCount
        />
      </Form.Item>
    </Fragment>
  );
}
