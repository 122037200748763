import React from 'react';
import './LinkElement.scss';
import { Text } from '@components/common';

const LinkElement = ({ userData, handleEditSocialMedia, type, icon, title }) => {
  const empty = () => {
    if (type == 'github' && userData.github_link) {
      return false;
    } else if (type == 'github' && !userData.github_link) {
      return true;
    } else if (type == 'stackoverflow' && userData.stackoverflow_link) {
      return false;
    } else if (type == 'stackoverflow' && !userData.stackoverflow_link) {
      return true;
    } else if (type == 'briefcase' && userData.briefcase_link) {
      return false;
    } else if (type == 'briefcase' && !userData.briefcase_link) {
      return true;
    }
  };

  const smaller = type == 'briefcase' ? 'briefcase' : 'submited';

  return (
    <div className="link-element__container">
      {empty() ? (
        <button className="link-element__button" onClick={() => handleEditSocialMedia(type)}>
          <i className="fa fa-plus" aria-hidden="true"></i>
          <Text type="blue">{title}</Text>
        </button>
      ) : (
        <button className={`link-element__${smaller}`} onClick={() => handleEditSocialMedia(type)}>
          <i className={icon} aria-hidden="true"></i>
          <Text type="strong">{title}</Text>
        </button>
      )}
    </div>
  );
};

export default LinkElement;
