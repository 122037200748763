import React, { Fragment } from 'react';
import './ServicesForm.module.scss';
import TextArea from 'antd/lib/input/TextArea';
import { Button, Title, FormVerifyIdentity, PopUpWindow } from '@components/common';
import { Form, Select, Spin } from 'antd';
import { SERVICE_FORM_INPUT_CONSTANTS as formTexts } from './ServicesForm.constants';
import { ServicesFormViewModel } from './ServicesFormViewModel';
import ServicesFormItem from './components/ServicesFormItem';
import { LoadingDots } from 'components/common';

export const ServicesForm = ({ afterSubmit, serviceToUpdate }) => {
  const { title, category, subcategory, description, experience, skills, disponibility } =
    formTexts;
  const {
    mainCategories,
    mainCategorySelectedID,
    setMainCategorySelectedID,
    subCategories,
    searchedSkills,
    serviceTitle,
    setServiceTitle,
    subCategoriesSelectedsID,
    serviceDescription,
    setServiceDescription,
    serviceExperience,
    setServiceExperience,
    skillsSelecteds,
    serviceDisponibility,
    setServiceDisponibility,
    handlePushSubCategories,
    handlePushSkills,
    fetchSkills,
    handleSubmit,
    isLoading,
    authUser,
    doneVerification,
    showVerifyIdentity,
    setShowVerifyIdentity,
  } = ServicesFormViewModel({ afterSubmit, serviceToUpdate });

  if (isLoading) {
    return <LoadingDots />;
  }
  return (
    <Fragment>
      <PopUpWindow open={showVerifyIdentity} onClose={() => setShowVerifyIdentity(false)}>
        <FormVerifyIdentity
          doneVerification={doneVerification}
          userData={authUser.user}
          mustVerify
        />
      </PopUpWindow>
      <div className="custom-container">
        <div className="basic-container create-publication-container">
          <Title color="secondary">PUBLICA TU SERVICIO</Title>
          <hr />
          <Form onFinish={handleSubmit}>
            <div className="form-container">
              <ServicesFormItem title={title.title}>
                <Form.Item
                  className="service-form-input"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresa el título',
                    },
                  ]}>
                  <TextArea
                    name="title"
                    value={serviceTitle}
                    onChange={(e) => setServiceTitle(e.target.value)}
                    maxLength={100}
                    placeholder={title.placeholder}
                    showCount
                    required={true}
                    autoSize={{ minRows: 1 }}
                  />
                </Form.Item>
              </ServicesFormItem>
              <ServicesFormItem title={category.title} id="formrightflexitem-category">
                <Form.Item
                  className="service-form-input"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresa el título',
                    },
                  ]}>
                  <Select
                    name="categories"
                    style={{ width: '100%' }}
                    placeholder={category.placeholder}
                    onChange={(id) => setMainCategorySelectedID(id)}
                    className="form-item-input"
                    value={mainCategorySelectedID}
                    required={true}
                    getPopupContainer={() => document.getElementById('formrightflexitem-category')}>
                    {mainCategories &&
                      mainCategories.map((category) => (
                        <Select.Option value={category.id} key={category.id}>
                          {category.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </ServicesFormItem>
              <ServicesFormItem title={subcategory.title} id="formrightflexitem-subcategory">
                <Form.Item className="service-form-input">
                  <Select
                    name="subcategories"
                    style={{ width: '100%' }}
                    mode="multiple"
                    placeholder={subcategory.placeholder}
                    onChange={handlePushSubCategories}
                    className="form-item-input"
                    value={subCategoriesSelectedsID}
                    required={true}
                    getPopupContainer={() =>
                      document.getElementById('formrightflexitem-subcategory')
                    }>
                    {subCategories &&
                      subCategories.map((category) => (
                        <Select.Option value={category.id} key={category.id}>
                          {category.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </ServicesFormItem>
              <hr />
              <ServicesFormItem title={description.title}>
                <Form.Item
                  className="service-form-input"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresa la descripción de tu servicio',
                    },
                  ]}>
                  <TextArea
                    name="description"
                    placeholder={description.placeholder}
                    autoSize={{ minRows: 15, maxRows: 15 }}
                    maxLength={4000}
                    showCount
                    onChange={(e) => setServiceDescription(e.target.value)}
                    value={serviceDescription}
                    required={true}
                  />
                </Form.Item>
              </ServicesFormItem>
              <ServicesFormItem title={experience.title}>
                <Form.Item
                  className="service-form-input"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresa tu experiencia en el servicio',
                    },
                  ]}>
                  <TextArea
                    name="experience_required"
                    placeholder={experience.placeholder}
                    autoSize={{ minRows: 9, maxRows: 9 }}
                    maxLength={1000}
                    showCount
                    onChange={(e) => setServiceExperience(e.target.value)}
                    value={serviceExperience}
                    required={true}
                  />
                </Form.Item>
              </ServicesFormItem>
              <hr />
              <ServicesFormItem
                title={skills.title}
                detail="Seleccionar un máximo de 5 habilidades de las que tenemos disponibles para ti."
                id="formrightflexitem-skills">
                <Form.Item
                  className="service-form-input"
                  rules={[
                    {
                      required: false,
                      message: 'Agrega Habilidades para que tu publicacion sobresalga.',
                    },
                  ]}>
                  <Select
                    name="skills"
                    mode="multiple"
                    labelInValue
                    value={skillsSelecteds}
                    placeholder={skills.placeholder}
                    notFoundContent={!searchedSkills ? <Spin size="small" /> : null}
                    filterOption={false}
                    onSearch={fetchSkills}
                    onChange={handlePushSkills}
                    style={{ width: '100%' }}
                    className="form-item-input"
                    required={true}
                    getPopupContainer={() => document.getElementById('formrightflexitem-skills')}>
                    {searchedSkills &&
                      searchedSkills.map((skill) => (
                        <Select.Option value={skill.id} key={skill.id}>
                          {skill.name}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </ServicesFormItem>
              <ServicesFormItem title={disponibility.title}>
                <Form.Item
                  className="service-form-input"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor ingresa tu disponibilidad',
                    },
                  ]}>
                  <TextArea
                    name="availability"
                    placeholder={disponibility.placeholder}
                    autoSize={{ minRows: 3, maxRows: 3 }}
                    maxLength={300}
                    showCount
                    onChange={(e) => setServiceDisponibility(e.target.value)}
                    value={serviceDisponibility}
                    required={true}
                  />
                </Form.Item>
              </ServicesFormItem>
              <div className="submit-button text-center">
                <Form.Item>
                  <Button
                    text={serviceToUpdate ? 'Editar servicio' : 'Publicar servicio'}
                    variant="primary"
                    color="secondary"
                  />
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </Fragment>
  );
};
