export function CalculateServiceCharge(totalPrice, commission) {
  return Math.round(totalPrice * commission);
}

export function CalculateRetention(totalPrice, retention) {
  return Math.round(totalPrice * retention);
}

export function CalculateWillReceive(totalPrice, serviceCharge, collaboratorRetention) {
  return totalPrice - serviceCharge - collaboratorRetention;
}

export function CalculateComissionIVAStage(proposal, nStage = 1) {
  const stagePrice = proposal.stages[nStage - 1].price;
  const commision = proposal.client_breakdown.comission;
  const iva = proposal.client_breakdown.iva;
  return stagePrice * (1 + commision) * (1 + iva);
}
