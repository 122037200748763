import React from 'react';
import { FormItemCard } from '../../FormItemCard';
import { Button } from 'components/common';
import { Upload } from 'antd';
import css from './Images.module.scss';
import { deleteFile } from 'actions/projects';

const Images = ({
  values,
  handleChangeImages,
  displayDistinctQuestion,
  currentFocus,
  handleGoBackQuestion,
  handleGoForwardQuestion,
}) => {
  const handleRemove = (e) => {
    if (e.id) {
      deleteFile(e.id);
    }
  };
  return (
    <FormItemCard
      name="images"
      title="Adjunta imágenes para ayudar a entender el proyecto"
      subTitle={'(Opcional) Adjunta hasta un máximo de 5 imagenes'}
      isCurrent={currentFocus == 'images'}
      id={'images-container'}
      handleGoForwardQuestion={handleGoForwardQuestion}
      handleGoBackQuestion={handleGoBackQuestion}
      displayDistinctQuestion={displayDistinctQuestion}>
      <div className={`${currentFocus != 'images' && css.disabled}`}>
        {' '}
        <Upload
          multiple
          name="project_image"
          accept=".png, .jpg, .jpeg"
          maxCount={5}
          onChange={handleChangeImages}
          fileList={values['images']}
          onRemove={handleRemove}>
          <Button
            type="button"
            text="Adjuntar Imagen"
            variant="files"
            disabled={currentFocus != 'images'}
          />
        </Upload>
      </div>
    </FormItemCard>
  );
};

export default Images;
