import React from 'react';
import { useSelector } from 'react-redux';
import { selectAuthUser } from '@reducers/auth';
import ServiceViewModel from '../ServiceViewModel';
import { relatedServices } from '@actions/services';

import { useHistory } from 'react-router';
import { message } from 'antd';
import projectService from '../../../services/projectService';

import { inviteColabToProject_v2 } from 'actions/services';

export const PublicServiceViewModel = () => {
  const history = useHistory();
  const authUser = useSelector(selectAuthUser);

  const [authUserProjects, setAuthUserProjects] = React.useState([]);
  const [isLoadingProjects, setIsLoadingProjects] = React.useState(false);

  const [userDrawer, setUserDrawer] = React.useState(false);
  const [contactDrawer, setContactDrawer] = React.useState(false);

  const [projectSelected, setProjectSelected] = React.useState({});

  const { isLoading, currentService, currentUser } = ServiceViewModel();

  const [projectsSuggestions, setProjectsSuggestions] = React.useState([]);

  React.useEffect(() => {
    if (currentService) {
      relatedServices(currentService.id)
        .then((res) => {
          setProjectsSuggestions(res.data.services);
        })

        .catch(() => {
          setProjectsSuggestions([]);
        });
    }
  }, [currentService]);

  const handleConfirmInviteProject = async () => {
    if (projectSelected) {
      inviteColabToProject_v2({ projectId: projectSelected.id, userId: currentUser.id }).then(
        () => {
          message.success('¡Se le envió una invitación al freelancer correctamente!');
        }
      );
    } else {
      message.error('¡Debes Seleccionar algún Proyecto!');
    }
  };

  React.useEffect(() => {
    async function fetchData() {
      if (authUser?.isAuthenticated) {
        console.log('fetch');
        setIsLoadingProjects(true);
        await projectService.getOwnerProjectsStatus(authUser.user.id, 'published').then((res) => {
          setAuthUserProjects(res.data.objects);
        });
        setIsLoadingProjects(false);
      }
    }
    fetchData();
  }, [authUser?.isAuthenticated]);

  const redirectToCreateProject = () => {
    history.push('/forms');
  };

  const handleRedirectSuggestion = (id) => {
    history.push(`/freelance-profile/${id}/view`);
  };

  return {
    authUser,
    isLoading,
    isLoadingProjects,
    currentService,
    currentUser,
    userDrawer,
    setUserDrawer,
    contactDrawer,
    setContactDrawer,
    redirectToCreateProject,
    authUserProjects,
    handleConfirmInviteProject,
    setProjectSelected,
    projectSelected,
    projectsSuggestions,
    handleRedirectSuggestion,
  };
};
