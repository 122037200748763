import React from 'react';
import './PaymentOption.scss';
import { Text } from '@components/common';

import { ICONO_FACTURA, ICONO_BOLETA } from '@common/constants/assets.constants';

export const PaymentOption = ({ type, selected, setPaymentType }) => {
  const [iconURL, setIconURL] = React.useState(ICONO_BOLETA);

  React.useEffect(() => {
    if (type == 'bill') {
      setIconURL(ICONO_BOLETA);
    } else if (type == 'invoice') {
      setIconURL(ICONO_FACTURA);
    }
  }, []);

  const handleOnClick = () => {
    setPaymentType(type);
  };
  return (
    <div
      className={`payment-option__container ${selected ? 'selected' : ''}`}
      onClick={() => handleOnClick()}>
      {' '}
      <img src={iconURL} className="payment-option__icon" alt="Icono Boleta" />
      <Text type="detail">{type == 'bill' ? 'Boleta' : 'Factura'}</Text>
    </div>
  );
};
