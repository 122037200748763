import { SELECT_CHAT } from '../actions/types.js';
import { LOGIN_SUCCESS, LOGOUT_SUCCESS, UPDATE_CHATS } from '../actions/types';

const initialState = {
  selectedChat: null,
  messages: [],
  chats: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_CHATS:
      return {
        ...state,
        chats: action.payload,
      };
    case SELECT_CHAT:
      return {
        ...state,
        selectedChat: action.payload,
      };
    case LOGIN_SUCCESS:
    case LOGOUT_SUCCESS:
      return {
        ...state,
        selectedChat: null,
      };
    default:
      return state;
  }
}
export const selectUserChats = (state) => state.chat.chats;
export const selectSelectedChat = (state) => state.chat.selectedChat;
