/* eslint-disable no-unused-vars */
import { viewChat } from 'actions/chat';
import useChat from 'hooks/useChat';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
const useNewChat = ({ chatID, handleGetFiles }) => {
  const { user } = useSelector((state) => state.auth);

  const [message, setMessage] = useState('');
  const [page, setPage] = useState(1);

  const {
    messageHistory,
    onSend,
    fetchNewPage,
    setIsNewPage,
    isNewPage,
    isFullHistory,
    onUpload: onUploadChat,
  } = useChat({ chatId: chatID });

  useEffect(() => {
    if (chatID) {
      viewChat(chatID);
    }
  }, [chatID]);

  const handleClickSendMessage = useCallback(() => {
    onSend(message);
    setMessage('');
  }, [message]);

  const handleChange = (event) => {
    setMessage(event.target.value);
  };
  const onUpload = async (e) => {
    onUploadChat(e);
    if (handleGetFiles) {
      await handleGetFiles(chatID);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && e.shiftKey) {
      e.preventDefault();
      return setMessage((prev) => `${prev}\n`);
    }
    if (e.key === 'Enter') {
      e.preventDefault();
      return handleClickSendMessage();
    }
    return;
  };
  const onChatScroll = (e) => {
    if (isNewPage || isFullHistory) return;

    const scroll = e.target.scrollTop;
    const chatContainer = document.getElementById('new_chat_messages_container');
    const height = chatContainer.style.height.toString().slice(0, -2);
    const scrollToFetch = (chatContainer.scrollHeight - height) * -1;
    if (scroll <= scrollToFetch + 10) {
      e.target.scrollTop = scrollToFetch;
      setIsNewPage(true);
      fetchNewPage(page + 1);
      setPage((prev) => prev + 1);
    }
  };

  const stringMessageToArray = (chatMessage) => {
    return chatMessage?.split('\n');
  };

  const isOwnerOfMessage = (msg) => msg.owner === user?.id;

  return {
    onChatScroll,
    messageHistory,
    isOwnerOfMessage,
    stringMessageToArray,
    message,
    handleChange,
    handleKeyPress,
    handleClickSendMessage,
    onUpload,
    page,
    isNewPage,
    onSend,
  };
};

export default useNewChat;
