import axiosClient from '@config/axios';
import { tokenConfig } from './auth';
import { createMessage } from './messages';
import { GET_USERS, DELETE_USER, GET_USER, EDIT_USER, GET_USER_LOADING } from './types';

// GET USERS
export const getUsers = () => (dispatch, getState) => {
  axiosClient
    .get(`/users/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_USERS,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getUsers_v2 = () => axiosClient.get('/users/');

// DELETE USER
export const deleteUser = (id) => (dispatch, getState) => {
  axiosClient
    .delete(`/users/${id}/`, tokenConfig(getState))
    .then(() => {
      dispatch(createMessage({ deleteUser: 'User Deleted' }));
      dispatch({
        type: DELETE_USER,
        payload: id,
      });
    })
    .catch((err) => console.log(err));
};

// GET USER
export const getUser = (id) => async (dispatch) => {
  dispatch({ type: GET_USER_LOADING });
  const result = await axiosClient
    .get(`/users/${id}/`)
    .then((res) => {
      dispatch(createMessage({ getUser: 'User gotten' }));
      dispatch({
        type: GET_USER,
        payload: res.data,
      });
      return res.data;
    })
    .catch((err) => {
      return err.data;
    });
  return result;
};
export const getUser_v2 = (id) => axiosClient.get(`/users/${id}/`);
// EDIT USER
export const editUser = (id, body) => (dispatch) => {
  axiosClient
    .patch(`/users/${id}/`, body)
    .then((res) => {
      dispatch(createMessage({ editUser: 'User edited' }));
      dispatch({
        type: EDIT_USER,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const editUser_v2 = (id, body) => {
  return axiosClient.patch(`/users/${id}/`, body);
};

export const dispatchUser = (user) => (dispatch) => {
  dispatch({
    type: GET_USER,
    payload: user,
  });
};

export const updateProfileImage = (formData) => axiosClient.post('/pictures', formData);

export const getUsersWithServices = (page, filterText, orderBy) => {
  return axiosClient.get(`/users_services?page=${page}&order=${orderBy}${filterText}`);
};

export const relatedUsers = (userId) => axiosClient.get(`/related_users/?user=${userId}`);

export const getUserQuestions = (ownerId) => axiosClient.get(`questions/?owner=${ownerId}`);

export const getUserMedals = (userId) => axiosClient.get(`/user_medal?owner=${userId}`);
