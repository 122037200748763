import React from 'react';
import { ManagmentViewModel } from '../../../ManagmentViewModel';
import {
  ALL_REQUEST,
  ACTIVE_REQUEST,
  PAYMENT_REQUEST,
  REJECTED_REQUEST,
} from '@common/constants/request.constants';
import requestService from '@services/requestService';

export const FreelancerRequestsListViewModel = () => {
  const { authUser } = ManagmentViewModel();
  const [requestsList, setRequestList] = React.useState([]);
  const [currentFilter, setCurrentFilter] = React.useState(ALL_REQUEST);
  const [isLoadingRequests, setIsLoadingRequests] = React.useState(true);

  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalRequests, setTotalRequests] = React.useState(0);

  React.useEffect(() => {
    setIsLoadingRequests(true);
    requestService
      .getUserRequest(authUser.user.id, currentFilter, currentPage)
      .then((res) => {
        setRequestList(res.data.objects);
        setTotalRequests(res.data.total_objects);
        setIsLoadingRequests(false);
      })
      .catch(() => {
        setIsLoadingRequests(false);
        setRequestList([]);
        setTotalRequests(0);
      });
  }, [currentFilter, currentPage]);

  const request_types = [
    { id: ALL_REQUEST, name: 'Todas', tag: 1 },
    { id: PAYMENT_REQUEST, name: 'Validando pago', tag: 2 },
    { id: ACTIVE_REQUEST, name: 'Activas', tag: 3 },
    { id: REJECTED_REQUEST, name: 'Rechazadas', tag: 4 },
  ];

  const handleOnClickCategory = async (categoryId) => {
    setCurrentFilter(categoryId);
  };

  const handlePagination = (event) => {
    setCurrentPage(event);
  };

  return {
    isLoadingRequests,
    requestsList,
    request_types,
    handleOnClickCategory,
    currentFilter,
    handlePagination,
    totalRequests,
    currentPage,
  };
};
