import { getMainCategories_v2 } from 'actions/categories';
import { getSkill } from 'actions/skills';

export const serviceAdapterFromUiToApi = (service) => {
  const {
    title,
    description,
    availability,
    experience,
    skillsRequired,
    mainCategory,
    subCategories,
  } = service;
  const apiService = {
    title,
    description,
    availability,
    experience,
    skills_required: skillsRequired.map((skill) => skill.value),
    categories: [mainCategory, ...subCategories],
  };
  return apiService;
};
export const serviceAdapterFromApiToUi = async (service) => {
  const { title, description, availability, experience, skills_required, categories } = service;
  const { data } = await getMainCategories_v2();
  const mainCatIds = data.map((cat) => cat.id);
  const mainCategory = categories.filter((cat) => mainCatIds.includes(cat))[0];
  const subCategories = categories.filter((cat) => !mainCatIds.includes(cat));
  let skillsRequired = [];
  for (const skill of skills_required) {
    const { data } = await getSkill(skill);
    skillsRequired = [...skillsRequired, { value: data.id, label: data.name, key: data.id }];
  }
  const uiService = {
    title,
    description,
    availability,
    experience,
    mainCategory,
    subCategories,
    skillsRequired,
  };
  return uiService;
};
