import React, { useEffect, useState } from 'react';
import { Select, Form, Input, Checkbox } from 'antd';
import { Button, Text } from '@components/common';
import {
  BANKS_LIST,
  ACCOUNT_TYPES,
  WINDOW_TYPE_EDIT,
} from '@common/constants/bankAccounts.constants';
import './FormAccount.scss';
import { clearRutInput, removeNonNumeric, rutFormater } from 'common/utils/Formaters';

const { Option } = Select;

const FormAccount = ({ handleSubmitAccount, account, window }) => {
  const [termsChecked, setTermsChecked] = useState(true);
  const formRef = React.createRef();
  useEffect(() => {
    if (account) {
      formRef.current.setFieldsValue({
        name: account?.name,
        rut: account?.rut,
        bank: account?.bank,
        account_type: account?.account_type,
        account_number: account?.account_number,
        address: account?.address,
      });
    } else {
      formRef.current.resetFields();
    }
  }, [account]);

  const handleCheckbox = () => {
    if (termsChecked) {
      setTermsChecked(false);
    } else {
      setTermsChecked(true);
    }
  };
  const onRutChange = (event) => {
    const rutInput = clearRutInput(event.target.value);
    if (rutInput === '')
      return formRef.current.setFieldsValue({
        rut: '',
      });
    const formatRut = rutFormater(rutInput);

    formRef.current.setFieldsValue({
      rut: formatRut,
    });
  };
  const onAccountNumberChange = (e) => {
    const value = removeNonNumeric(e.target.value);
    formRef.current.setFieldsValue({
      account_number: value,
    });
  };
  const isEditing = window === WINDOW_TYPE_EDIT;

  return (
    <div className="form-account__container">
      <Form onFinish={handleSubmitAccount} ref={formRef}>
        <Text type="boldDetail" style={{ marginBottom: '2em' }}>
          Datos de facturación:
        </Text>
        <div className="form-account__row">
          <Text type="strongBlue">Dirección:</Text>
          <Form.Item name="address" rules={[{ required: true, message: 'Completa este campo!' }]}>
            <Input maxLength={255} />
          </Form.Item>
        </div>
        <hr />
        <Text type="boldDetail" style={{ marginBottom: '2em' }}>
          Cuenta corriente:
        </Text>
        <div className="form-account__row">
          <Text type="strongBlue">Nombre Destinatario:</Text>
          <Form.Item name="name" rules={[{ required: true, message: 'Completa este campo!' }]}>
            <Input maxLength={50} />
          </Form.Item>
        </div>
        <div className="form-account__row">
          <Text type="strongBlue">Rut:</Text>
          <Form.Item name="rut" rules={[{ required: true, message: 'Completa este campo!' }]}>
            <Input disabled={isEditing} placeholder="ej: 12345678-9" onChange={onRutChange} />
          </Form.Item>
        </div>
        <div className="form-account__row">
          <Text type="strongBlue">Banco:</Text>
          <Form.Item
            name="bank"
            rules={[{ required: true, message: 'Completa este campo!' }]}
            style={{ minWidth: '200px' }}>
            <Select required>
              {BANKS_LIST.map((bank, index) => {
                return (
                  <Option value={bank.name} key={`bopt-${index}`}>
                    {bank.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
        <div className="form-account__row">
          <Text type="strongBlue">Tipo de Cuenta:</Text>
          <Form.Item
            name="account_type"
            rules={[{ required: true, message: 'Completa este campo!' }]}
            style={{ minWidth: '200px' }}>
            <Select required>
              {ACCOUNT_TYPES.map((accType, index) => {
                return (
                  <Option value={accType.name} key={`accopt-${index}`}>
                    {accType.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </div>
        <div className="form-account__row">
          <Text type="strongBlue">Número de cuenta:</Text>
          <Form.Item
            name="account_number"
            rules={[{ required: true, message: 'Completa este campo!' }]}>
            <Input maxLength={25} onChange={onAccountNumberChange} />
          </Form.Item>
        </div>
        {account ? null : (
          <div className="form-account__checkbox">
            <div className="form-account_row">
              <Checkbox className="acc-checkbox" onClick={handleCheckbox}></Checkbox>
              <Text type="strong">¿No eres titular de la cuenta?</Text>
            </div>
            <Text>
              No soy titular de la cuenta, he leido y entendido los{' '}
              <a href="#Terms&Conditions">Términos y Condiciones </a>
              de utilizar una cuenta que no esté a mi nombre.
            </Text>
          </div>
        )}
        <div className="form-account__row" style={{ marginTop: '2em' }}>
          <Button
            text={account ? 'Editar cuenta' : 'Añadir cuenta'}
            variant="primary"
            borderColor="primary"
          />
        </div>
      </Form>
    </div>
  );
};
export default FormAccount;
