/* eslint-disable no-unused-vars */
import './ProjectSteps.scss';
import { Badge, Modal } from 'antd';
import { Text } from '../Text';
import ProjectFeedbackStageModal from './ProjectFeedbackStageModal';
import ProjectSubmitStageModal from './ProjectSubmitStageModal';
import React, { useState } from 'react';
import RenderIf from '../RenderIf';
import useCurrentProjectStages from './useCurrentProjectStages';
const ProjectSteps = ({ currentProject, type, setRefetchProject }) => {
  const {
    formatedStartDate,
    formatedEndDate,
    sortedStages,
    descriptionOfStage,
    calculateStageClass,
    isCurrent,
    isProjectFinished,
    handleAproveCurrentStage,
    showSubmitStageModal,
    handleSendSubmitStageModal,
    handleCancelSubmitStageModal,
    isSubmitStageModalOpen,
    onUploadFeedbackFile,
    handleChangeFeedback,
    feedback,
    file,
    handleClearFile,
    currentStage,
    showFeedbackStageModal,
    handleSendFeedbackStageModal,
    handleCancelFeedbackStageModal,
    isFeedbackStageModalOpen,
  } = useCurrentProjectStages({ currentProject, setRefetchProject });
  const buttonTextByType = type === 'client' ? 'Aprobar etapa' : 'Entregar etapa';
  const handleClickByType = type === 'client' ? handleAproveCurrentStage : showSubmitStageModal;
  const showFeedbackBadge = (stage, idx) => {
    return isCurrent(idx) && type === 'client' && stage.stage_feedbacks?.length > 0;
  };
  const hasFeedback = (stage, idx) => {
    return !isCurrent(idx) && stage.stage_feedbacks?.length > 0;
  };
  const handleOpenModalStageFeedback = (stage) => {
    setClickedStage(stage);
    showFeedbackStageModal();
  };
  const handleClickByBadge = (stage, idx) => {
    if (showFeedbackBadge(stage, idx)) {
      handleOpenModalStageFeedback(stage);
    } else {
      handleClickByType();
    }
  };
  const [clickedStage, setClickedStage] = useState(null);
  return (
    <div className="project_steps_main">
      {sortedStages.length > 0 && (
        <div>
          <div className="wrapper">
            <ul className="StepProgress">
              <div className="StepProgress-item project-start">
                <Text>Inicio del proyecto</Text>
                <Text className="details">{formatedStartDate}</Text>
              </div>
              {sortedStages?.map((stage, idx) => (
                <div className={'StepProgress-item ' + calculateStageClass(idx)} key={idx}>
                  <div style={{ display: 'flex' }}>
                    <Text>{stage.name}</Text>
                  </div>
                  <RenderIf isTrue={hasFeedback(stage, idx)}>
                    <p
                      className="show_feedback"
                      onClick={() => handleOpenModalStageFeedback(stage)}>
                      Ver entrega
                    </p>
                  </RenderIf>

                  <Text className="details">{descriptionOfStage(idx)}</Text>

                  <RenderIf isTrue={isCurrent(idx)}>
                    <div style={{ display: 'flex' }}>
                      <button onClick={() => handleClickByBadge(stage, idx)}>
                        {buttonTextByType}
                      </button>
                      <RenderIf isTrue={showFeedbackBadge(stage, idx)}>
                        <Badge
                          count={1}
                          title="El freelancer ha dejado feedback"
                          style={{
                            marginTop: -3,
                            marginLeft: -10,
                            backgroundColor: '#00D5A1',
                            cursor: 'pointer',
                          }}
                        />
                      </RenderIf>
                    </div>

                    <Modal
                      title={<Text>Vas a entregar: {stage.name}</Text>}
                      visible={isSubmitStageModalOpen}
                      onOk={handleSendSubmitStageModal}
                      onCancel={handleCancelSubmitStageModal}>
                      <ProjectSubmitStageModal
                        onUploadFeedbackFile={onUploadFeedbackFile}
                        handleChangeFeedback={handleChangeFeedback}
                        feedback={feedback}
                        file={file}
                        handleClearFile={handleClearFile}
                        currentStage={currentStage}
                      />
                    </Modal>
                  </RenderIf>
                </div>
              ))}

              <div
                className={`${
                  !isProjectFinished() && 'not-started'
                } StepProgress-item project-end`}>
                <Text>Fin del proyecto</Text>
                <Text className="details">{formatedEndDate}</Text>
              </div>
            </ul>
          </div>
        </div>
      )}
      <RenderIf isTrue={clickedStage}>
        <Modal
          title={<Text>Feedback para: {clickedStage?.name}</Text>}
          visible={isFeedbackStageModalOpen}
          onOk={() => handleSendFeedbackStageModal(clickedStage)}
          okText={currentStage?.id === clickedStage?.id ? 'Aprobar' : 'Aceptar'}
          onCancel={handleCancelFeedbackStageModal}>
          <ProjectFeedbackStageModal
            stage={clickedStage}
            type={type}
            owner={currentProject?.owner_name}
          />
        </Modal>
      </RenderIf>
    </div>
  );
};

export default ProjectSteps;
