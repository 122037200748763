/* eslint-disable no-unused-vars */
import React from 'react';
import { Button, Text, Title } from 'components/common';
import { Card } from 'antd';
import { selectAuthUser } from 'reducers/auth';
import { useSelector } from 'react-redux';
import css from './PreviousProjects.module.scss';
import DeletePrevProjectModal from './Modals/DeletePrevProjectModal';
import InfoPrevProjectModal from './Modals/InfoPrevProjectModal';
import NewPrevProjectModal from './Modals/NewPrevProjectModal';
import PrevProjectCard from './PrevProjectCard';
import usePrevProjects from './hooks/usePrevProjects';
import PreviousProjectsCarousel from './PrevProjectCarousel';
import AddImage from 'components/common/AddImage';

const PreviousProjects = ({ user, fromProfile, withAdd, carousel, type = 'project' }) => {
  const {
    showModal,
    hiddeModal,
    modal,
    handleEdit,
    handleCreate,
    selectedProject,
    handleDelete,
    projects,
    isLoading,
  } = usePrevProjects({ user });
  const authUser = useSelector(selectAuthUser);
  const isOwner = authUser && authUser?.user?.id === user?.id;
  return (
    <>
      <InfoPrevProjectModal onCancel={hiddeModal} visible={modal.info} project={selectedProject} />
      {isOwner && (
        <>
          <NewPrevProjectModal
            onCancel={hiddeModal}
            visible={modal.edit}
            project={selectedProject}
            handleEdit={handleEdit}
            handleCreate={handleCreate}
            isLoading={isLoading}
          />
          <DeletePrevProjectModal
            onCancel={hiddeModal}
            visible={modal.delete}
            handleDelete={handleDelete}
            isLoading={isLoading}
          />
        </>
      )}
      {fromProfile ? (
        <div className={css.container}>
          <Title color="primary">Proyectos Destacados</Title>
          <Text>En esta sección podrás incluir los proyectos en los que has trabajado</Text>
          <PreviousProjectsContainer
            projects={projects}
            isOwner={isOwner}
            showModal={showModal}
            withAdd={fromProfile && isOwner}
          />
        </div>
      ) : (
        <>
          <Title type="tertiary" color={type == 'project' ? 'primary' : 'secondary'}>
            Proyectos Destacados ({projects?.length})
          </Title>
          {carousel ? (
            <PreviousProjectsCarousel projects={projects} isOwner={isOwner} showModal={showModal} />
          ) : (
            <PreviousProjectsContainer
              projects={projects?.slice(0, 3)}
              isOwner={isOwner}
              showModal={showModal}
              withAdd={withAdd && isOwner}
            />
          )}
        </>
      )}
    </>
  );
};
const PreviousProjectsContainer = ({ projects, isOwner, showModal, withAdd }) => (
  <div className={css.projects}>
    {projects?.map((project, index) => (
      <PrevProjectCard
        isOwner={isOwner}
        key={index}
        project={project}
        showCreateEditModal={() => showModal(project, 'edit')}
        showDeleteModal={() => showModal(project, 'delete')}
        showInfoModal={() => showModal(project, 'info')}
      />
    ))}
    {withAdd && <AddButton showModal={showModal} />}
  </div>
);
const AddButton = ({ showModal }) => (
  <AddImage
    text="Agregar proyecto"
    style={{ width: 200, height: 300 }}
    iconSize={50}
    onClick={() => showModal(null, 'edit')}
  />
  // <Button text="Agregar proyecto" onClick={() => showModal(null, 'edit')} />
);

export default PreviousProjects;
