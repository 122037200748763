import React, { Fragment } from 'react';
import { Button, SkillsBeans, DisplayList, Text, Title } from 'components/common';
import { questionTitle } from 'common/constants/publicationForm.constants';
import css from './FinalPreview.module.scss';
import projectService from 'services/projectService';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormItemCard } from 'components/project/NewProjectForm/FormItemCard';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { CLIENT_PUBLICATIONS_ROUTE } from '@common/constants/routes.constants';
import { createMessage } from 'actions/messages';
import useAuthButtons from 'hooks/useAuthButtons';

const FinalPreview = ({
  values,
  currentFocus,
  questionOrder,
  displayDistinctQuestion,
  type,
  id,
  authUser,
}) => {
  const { confirm } = Modal;
  const history = useHistory();
  const dispatch = useDispatch();
  const { setShowAuthModal } = useAuthButtons();
  const handleSendForm = () => {
    if (authUser?.user) {
      confirm({
        title: `¿Estás seguro que quieres enviar tu Proyecto?`,
        icon: <ExclamationCircleOutlined />,
        content:
          '¡Revisa que hayas añadido toda la información, así podrás encontrar más rápido a tu trabajador!.',
        okText: 'Confirmar',
        cancelText: 'Cancelar',
        onOk: async () => {
          await projectService
            .handleOnSubmitForm(type, id, values)
            .then((res) => {
              dispatch(createMessage({ successMessage: res.message }));
              history.push(`${CLIENT_PUBLICATIONS_ROUTE}/${res.id}?invites=true`);
            })
            .catch((err) => {
              dispatch(createMessage({ errorMessage: err.message }));
            });
        },
        onCancel() {
          console.log('Cancel');
        },
      });
    } else {
      setShowAuthModal(true);
    }
  };

  const getText = (name) => {
    if (
      [
        'api_integration',
        'skills',
        'images',
        'model_goal',
        'website_functionalities',
        'reference_urls',
      ].includes(name)
    ) {
      return (
        values[name]?.length > 0 && (
          <Fragment>
            <Text type="blue">{questionTitle[name]}</Text>
            <DisplayList list={values[name]} />
          </Fragment>
        )
      );
    } else if (
      [
        'category',
        'sub_category',
        'actual_state',
        'website_type',
        'time_range',
        'budget_range',
        'actual_db_status',
        'compatibility',
      ].includes(name)
    ) {
      return (
        <Fragment>
          <Text type="blue">{questionTitle[name]}</Text>
          <Text type="detail">{values[name] ? values[name].name : ''} </Text>
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <Text type="blue">{questionTitle[name]}</Text>
          <Text type="detail">{values[name]}</Text>
        </Fragment>
      );
    }
  };

  return (
    <FormItemCard
      name="finalPreview"
      className={css.container}
      title="Vista Previa"
      isCurrent={currentFocus == 'finalPreview'}
      id={'finalPreview-container'}
      displayDistinctQuestion={displayDistinctQuestion}>
      <Fragment>
        <div className={css.content}>
          <div className={css.title}>
            <Title type="primary" color="primary">
              {values['title'] ? values['title'] : ''}
            </Title>
          </div>
          <div className={css.categories}>
            <Text type="normalBoldDetail">{values['category']?.name}</Text>
          </div>
          <div className={css.subCategories}>
            <Text type="normalBoldDetail">{values['sub_category']?.name}</Text>
          </div>
          <SkillsBeans skills={values['skills']?.map((skill) => skill.label)} />
          <hr />
          <div className={css.description}>
            <Text type="blue">{questionTitle['description']}</Text>
            <Text type="normalBoldDetail">{values['description']}</Text>
          </div>
          {questionOrder?.map((name, index) => {
            return (
              !['description', 'title', 'finalPreview', 'skills'].includes(name) && (
                <div className={css.item} key={`preview-item-${index}`}>
                  {getText(name)}
                </div>
              )
            );
          })}
        </div>

        <div className={css.buttonContainer}>
          {' '}
          <Button
            text={type == 'EDIT' ? 'Editar proyecto' : 'Publicar proyecto'}
            variant="primary"
            color="primary"
            onClick={() => handleSendForm()}
          />
        </div>
      </Fragment>
    </FormItemCard>
  );
};

export default FinalPreview;
