import React from 'react';
import { Button, Title } from '@components/common';
import { LANDING_WORLDMAP_BACKGROUND } from 'pages/Landing/landing.constants';
import { HOME_ROUTE } from '@common/constants/routes.constants';
import { useHistory } from 'react-router-dom';
import './Section6.scss';
import AnimateOnView from 'components/AnimateOnView';

const Section6 = () => {
  const history = useHistory();
  const handleOnClickProjects = () => {
    history.push(HOME_ROUTE);
    window.localStorage.setItem('switchStatus', 'Trabajar');
  };

  const handleOnClickServices = () => {
    history.push(HOME_ROUTE);
    window.localStorage.setItem('switchStatus', 'Contratar');
  };
  return (
    <div className="section6__container">
      <div className="section6__content">
        <AnimateOnView className="section6__left">
          <img className="section6__image" src={LANDING_WORLDMAP_BACKGROUND} />
        </AnimateOnView>
        <AnimateOnView className="section6__right">
          <Title type="xl" color="black">
            TeNecesito es para todos,
          </Title>
          <Title type="primary" color="detailColor">
            contrata y trabaja a tú modo
          </Title>
          <div className="section6__buttons">
            <Button
              type="landing-btn"
              text="¡Necesito trabajar!"
              variant="primary --primaryColor"
              onClick={handleOnClickProjects}
            />
            <Button
              type="landing-btn"
              text="¡Necesito contratar!"
              variant="secondary"
              onClick={handleOnClickServices}
            />
          </div>
        </AnimateOnView>
      </div>
    </div>
  );
};

export default Section6;
