import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_FAIL,
  EDIT_USER,
  GET_USER_NOTIFICATIONS,
  NOTIFICATIONS_PAGINATION,
  VALIDATE_IDENTITY,
  SET_LOADING_AUTH,
} from '../actions/types';

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: null,
  isLoading: true,
  user: null,
  notifications: [],
  total_notifications: 10,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case USER_LOADED:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload.user,
      };
    case LOGIN_SUCCESS:
      localStorage.setItem('token', action.payload.user['oauth_token']);
      return {
        ...state,
        token: action.payload.user['oauth_token'],
        isAuthenticated: true,
        isLoading: false,
        user: action.payload.user,
      };
    case AUTH_ERROR:
    case LOGIN_FAIL:
    case LOGOUT_SUCCESS:
    case REGISTER_FAIL:
      localStorage.removeItem('token');
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
      };
    case EDIT_USER:
      return {
        ...state,
        user: action.payload,
      };
    case GET_USER_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case NOTIFICATIONS_PAGINATION:
      return {
        ...state,
        total_notifications: action.payload,
      };
    case VALIDATE_IDENTITY:
      return Object.assign({}, state, {
        user: Object.assign({}, state.user, { is_identity_verified: true }),
      });
    case SET_LOADING_AUTH:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
}

export const selectAuthUserInfo = (state) => state.auth.user;
export const selectToken = (state) => state.auth.token;
export const selectAuthUser = (state) => state.auth;
export const selectNotifications = (state) => state.auth.notifications;
