import React from 'react';
import './PaymentVoucher.scss';
import { BaseLayout, Button, Title, BasicContainer, Row, Text } from '@components/common';
import queryString from 'query-string';
import { useEffect, useState } from 'react';

const PaymentVoucherPage = ({ location }) => {
  const [paymentData, setPaymentData] = useState([]);

  useEffect(() => {
    const values = queryString.parse(location);
    if (values) {
      setPaymentData(values);
      console.log(paymentData);
    }
  }, []);

  return (
    <BaseLayout>
      <BaseLayout.Content>
        <Title color="primary">Información de tú pago</Title>
        <BasicContainer style={{ maxWidth: '500px' }}>
          <Row>
            <Row.Leading>
              <Text strong>Monto</Text>
            </Row.Leading>
            <Row.Trailing>$20.000</Row.Trailing>
          </Row>
          <Row>
            <Row.Leading>
              <Text strong>Cargo</Text>
            </Row.Leading>
            <Row.Trailing>$20.000</Row.Trailing>
          </Row>
          <Row>
            <Row.Leading>
              <Text strong>Cargo</Text>
            </Row.Leading>
            <Row.Trailing>$20.000</Row.Trailing>
          </Row>
          <Row>
            <Row.Leading>
              <Text strong>Cargo</Text>
            </Row.Leading>
            <Row.Trailing>$20.000</Row.Trailing>
          </Row>
          <Row>
            <Row.Leading>
              <Text strong>Cargo</Text>
            </Row.Leading>
            <Row.Trailing>$20.000</Row.Trailing>
          </Row>
          <Row>
            <Row.Leading>
              <Text strong>Cargo</Text>
            </Row.Leading>
            <Row.Trailing>$20.000</Row.Trailing>
          </Row>
        </BasicContainer>
        <Button
          text="Continuar al proyecto"
          variant="primary"
          color="evaluation"
          type="submit"
          style={{ marginTop: '20px' }}
          href="/"
        />
      </BaseLayout.Content>
    </BaseLayout>
  );
};

export default PaymentVoucherPage;
