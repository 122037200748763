import React from 'react';
import { Text } from 'components/common';
import css from './ProjectTags.module.scss';

const PROJECT_TAGS_DICTIONARY = {
  'Tengo un error o problema en mi sitio web': {
    label: 'Error o problema',
  },
  'Necesito una integración': {
    label: 'Integración',
  },
  'Necesito modificar o mejorar mi sitio web': {
    label: 'Modificación o Mejora',
  },
  'Necesito migrar mi sitio': {
    label: 'Migración',
  },
  'Aplicación a medida o software propio': {
    label: 'Aplicación a medida',
  },
  'Página web corporativa o informativa': {
    label: 'Corporativa o Informativa',
  },
  'Sitio web corporativo': {
    label: 'Corporativa o Informativa',
  },
  Marketplace: {
    label: 'Marketplace',
  },
  'Tienda en línea': {
    label: 'Tienda en línea',
  },
  'Tienda Online': {
    label: 'Tienda en línea',
  },
  'Blog o foro de discusión': {
    label: 'Blog',
  },
  Blog: {
    label: 'Blog',
  },
  'Foros de debate u opinión': {
    label: 'Blog',
  },
  'Portafolio o personal': {
    label: 'Portafolio o Personal',
  },
  Portafolio: {
    label: 'Portafolio o Personal',
  },

  'Red social': {
    label: 'Red social',
  },
  'Plataforma Saas': {
    label: 'Plataforma Saas',
  },
  'Control de gestión': {
    label: 'Control de gestión',
  },

  'Difusión de material multimedia, noticias o educativo': {
    label: 'Difusión de material multimedia',
  },
};

const getProjectTags = (projectType, neededType, websiteType) => {
  if (projectType == 'cms') {
    return [websiteType];
  } else if (projectType == 'integration') {
    return [websiteType, neededType];
  } else if (projectType == 'normal') {
    return [websiteType];
  }
};

function ProjectTags({ projectType, neededType, websiteType }) {
  return (
    <div className={css.container}>
      {getProjectTags(projectType, neededType, websiteType)?.map((tag, index) => {
        return (
          PROJECT_TAGS_DICTIONARY[tag] && (
            <div className={css.item} key={`tag-${index}`}>
              <Text>{PROJECT_TAGS_DICTIONARY[tag].label}</Text>
            </div>
          )
        );
      })}
    </div>
  );
}

export default ProjectTags;
