import React from 'react';
import { LoadingDots, Title } from '@components/common';
import { NotificationsViewModel } from './NotificationsViewModel';
import './Notifications.scss';
import { DropdownItem } from '@components/layouts/Header/components/NotificationDropdown/DropdownItem';
import { Pagination } from 'antd';

const Notifications = () => {
  const {
    authUser,
    currentNotifications,
    isLoadingNotifications,
    handleNotificationRedirection,
    handlePagination,
  } = NotificationsViewModel();
  return !isLoadingNotifications ? (
    <div className="notifications__container">
      <div className="notifications__header">
        {' '}
        <Title type="tertiary" color="primary">
          Notificaciones:{' '}
        </Title>
        <hr />
      </div>
      <div className="notifications__content">
        {currentNotifications.map((notification, index) => {
          return (
            <div className="notification__row" key={notification.id}>
              <DropdownItem
                key={index}
                notification={notification}
                handleNotificationRedirection={handleNotificationRedirection}
              />
            </div>
          );
        })}
      </div>
      <div className="notifications__pagination">
        <Pagination
          onChange={handlePagination}
          defaultPageSize={10}
          total={authUser.total_notifications}
          showSizeChanger={false}
        />
      </div>
    </div>
  ) : (
    <LoadingDots />
  );
};

export default Notifications;
