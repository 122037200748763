import axiosClient from '@config/axios';
import { returnErrors, createMessage } from './messages';

import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  SET_LOADING_AUTH,
  LOGIN_FAIL,
} from './types';

// CHECK TOKEN & LOAD USER
export const loadUser = () => (dispatch, getState) => {
  // User Loading
  dispatch({ type: USER_LOADING });

  axiosClient
    .get(`/loadUser`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response?.data, err.response?.status));
      dispatch({
        type: AUTH_ERROR,
      });
    });
};

// LOGIN USER
export const logInUser = (email, password) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({ email, password });
  return axiosClient.post(`/login`, body, config);
};

// LOGIN USER
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  const body = JSON.stringify({ email, password });
  await axiosClient
    .post(`/login`, body, config)
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response?.data, err.response?.status));
      dispatch({
        type: LOGIN_FAIL,
      });
    });
};

// REGISTER USER
export const registerUser = (newUser) => {
  // Headers
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return axiosClient.post(`/register`, newUser, config);
};

export const register =
  ({ username, password, email, first_name, last_name, phone_number }) =>
  (dispatch) => {
    // Headers
    const config = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    // Request Body
    const body = JSON.stringify({
      username,
      email,
      password,
      first_name,
      last_name,
      phone_number,
    });

    axiosClient
      .post(`/register`, body, config)
      .then((res) => {
        dispatch({
          type: REGISTER_SUCCESS,
          payload: res.data,
        });
        dispatch(createMessage({ registerUser: 'User Created' }));
      })
      .catch((err) => {
        dispatch(returnErrors(err.response?.data, err.response?.status));
        dispatch({
          type: REGISTER_FAIL,
        });
      });
  };

// LOGOUT USER
export const logout = () => (dispatch, getState) => {
  axiosClient
    .post(`/logout/`, null, tokenConfig(getState))
    .then(() => {
      dispatch({ type: 'LOGOUT_SUCCESS' });
      dispatch({
        type: LOGOUT_SUCCESS,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response?.data, err.response?.status));
    });
};

export const registerConfirmation = (registertoken) => async (dispatch, getState) => {
  await axiosClient
    .post(`/registerConfirmation`, registertoken, tokenConfig(getState))
    .then((res) => {
      dispatch(createMessage({ registerConfirmation: 'Registro Completado' }));
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response?.data, err.response?.status));
    });
};

export const registerSendConfirmation = (email) => async (dispatch, getState) => {
  await axiosClient
    .post(`/registerSendConfirmation`, { email: email }, tokenConfig(getState))
    .then(() => {
      dispatch(createMessage({ registerSendConfirmation: 'Reinicio realizado' }));
    })
    .catch((err) => {
      dispatch(returnErrors(err.response?.data, err.response?.status));
    });
};

export const changePasswordRecovery = (data) => async (dispatch, getState) => {
  await axiosClient
    .post(`/changePasswordRecovery`, data, tokenConfig(getState))
    .then(() => {
      dispatch(
        createMessage({
          successMessageRedirect: 'Contraseña actualizada!',
          redirectUri: '/home',
        })
      );
    })
    .catch((err) => {
      dispatch(returnErrors(err.response?.data, err.response?.status));
    });
};

export const changePassword = (data) => async (dispatch, getState) => {
  // Request Body
  const body = JSON.stringify(data);

  await axiosClient
    .post(`/changePassword`, body, tokenConfig(getState))
    .then(() => {
      dispatch(
        createMessage({
          successMessageRedirect: 'Contraseña Nueva Actualizada',
          redirectUri: '/login',
        })
      );
    })
    .catch((err) => {
      dispatch(returnErrors(err.response?.data, err.response?.status));
    });
};

export const resetPassword = (email) => async (dispatch) => {
  await axiosClient
    .post(`/resetPassword`, { email: email })
    .then(() => {
      dispatch(createMessage({ resetPassword: 'Reinicio realizado' }));
    })
    .catch((err) => {
      dispatch(returnErrors(err.response?.data, err.response?.status));
    });
};
//Google Login
export const googleLogin = (access_token) => async (dispatch) => {
  await axiosClient
    .post(`/loginGoogle`, { access_token: access_token })
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch(returnErrors(err.response?.data, err.response?.status));
    });
};

export const setLoading = (state) => (dispatch) => {
  return dispatch({ type: SET_LOADING_AUTH, payload: state });
};

// Setup config with token - helper function
export const tokenConfig = (getState) => {
  // Get token from state
  const token = getState().auth.token;

  // HeadersW
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // If token, add to headers config
  if (token) {
    config.headers['Authorization'] = `Token ${token}`;
  }
  return config;
};

export const refreshLastLogin = () => {
  return axiosClient.post('/update_user_last');
};
