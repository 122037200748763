import React from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { closeLeftWindow } from '@actions/sideWindows';
import { Drawer } from 'antd';
import './BaseLayout.scss';

const BaseLayout = ({ children, className = '', ...rest }) => {
  return (
    <div className={classnames('tn-layout', className)} {...rest}>
      {children}
    </div>
  );
};

const BaseLayoutLeftSide = ({ children, className, background, ...rest }) => {
  const classes = classnames(
    background ? 'tn-layout__left-nobackground' : 'tn-layout__left',
    className
  );
  const isOpen = useSelector((state) => state.sideWindows?.leftWindowOpened);
  const dispatch = useDispatch();
  const closeWindow = () => dispatch(closeLeftWindow());

  return (
    <div className={classes} {...rest}>
      <Drawer
        bodyStyle={{ padding: 0 }}
        width={350}
        open={isOpen}
        onClose={closeWindow}
        visible={isOpen}
        placement="left">
        {children}
      </Drawer>
      {children}
    </div>
  );
};

const BaseLayoutContent = ({ children, fullwidth, className, ...rest }) => {
  const classes = classnames('tn-layout__content', fullwidth && 'full-width', className);

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

const BaseLayoutRightSide = ({ children, className, guideSide, ...rest }) => {
  const classes = classnames('tn-layout__right', guideSide && 'with-guide', className);

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

const BaseLayoutMargins = ({ children, className, ...rest }) => {
  const classes = classnames('tn-layout__margins', className);

  return (
    <div className={classes} {...rest}>
      {children}
    </div>
  );
};

BaseLayout.LeftSide = BaseLayoutLeftSide;
BaseLayout.Content = BaseLayoutContent;
BaseLayout.RightSide = BaseLayoutRightSide;
BaseLayout.Margins = BaseLayoutMargins;

export default BaseLayout;
