import { Tooltip } from 'antd';
import { FormVerifyIdentity, Text } from 'components/common';
import React from 'react';

const VerifyIdentity = () => (
  <div style={{ maxWidth: 580, margin: '0 auto' }}>
    <Text>
      Antes de hacer tu propuesta nos gustaría hacerte unas preguntas sobre ti para completar tu
      perfil
    </Text>
    <Text style={{ margin: '1em 0' }}>
      Esto se preguntará una vez, y quedará disponible para modificar en tu perfil.
    </Text>
    <Text style={{ margin: '1em 0' }}>No tardará más de 5 minutos.</Text>
    <Tooltip
      placement="right"
      title={
        <>
          <Text style={{ fontSize: 10, color: 'white' }}>
            Verificamos la identidad de nuestros usuarios para seguridad de ustedes mismos.
          </Text>
          <Text style={{ fontSize: 10, color: 'white' }}>
            De esta forma nos aseguramos que detrás de cada usuario exista una persona real, y así
            garantizamos que puedan generar conexiones fructíferas para una experiencia increíble.
          </Text>
        </>
      }>
      <Text style={{ margin: '1em 0', color: '#3383ff', maxWidth: 240, cursor: 'pointer' }}>
        ¿Por qué verificamos identidad?
      </Text>
    </Tooltip>
    <FormVerifyIdentity withTitle={false} style={{ padding: 0 }} />
  </div>
);

export default VerifyIdentity;
