import React from 'react';
import css from './IntegrationType.module.scss';
import { Text } from '@components/common';
import {
  INTEGRATIONS_LOGOS_DICTIONARY,
  ilustrationsFolder,
} from '../../IntegrationsDetails.constants';
import { addHttps } from 'common/utils/Formaters';

function IntegrationType({ currentProject }) {
  return (
    <div className={css.container}>
      <Text type="detail">Integraciones requeridas: </Text>
      <div className={css.content}>
        <div className={css.integrations}>
          {currentProject?.future_integration?.map((int, index) => {
            return (
              <div className={css.item} key={`int-${index}`}>
                <img
                  src={
                    INTEGRATIONS_LOGOS_DICTIONARY[int]
                      ? ilustrationsFolder + INTEGRATIONS_LOGOS_DICTIONARY[int]
                      : ilustrationsFolder + 'dreamer_e856445593.svg'
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
      {currentProject?.documentation_link && (
        <div>
          {' '}
          <Text type="detail">Documentación: </Text>
          <div className={css.documentation}>
            {' '}
            <a href={addHttps(currentProject.actual_link)} target="_blank" rel="noreferrer">
              {currentProject.actual_link}
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default IntegrationType;
