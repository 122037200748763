import React, { Fragment } from 'react';
import './ViewRequestDrawer.scss';
import { ViewRequestBreakdown } from './ViewRequestBreakdown/ViewRequestBreakdown';
import { Button, Text, Title, CustomDrawer } from '@components/common';
import { Checkbox } from 'antd';
import { NEGOTIATION_INTELLECTUAL_PROP_TEXT } from '@common/constants/negotiation.constants';

export function ViewRequestDrawer({
  currentRequest,
  currentProposal,
  handleAcceptProposal,
  viewRequestDrawer,
  setViewRequestDrawer,
}) {
  const [intellectualProp, setIntellectualProp] = React.useState(false);

  return (
    <CustomDrawer
      type="request"
      open={viewRequestDrawer}
      onClose={() => setViewRequestDrawer(false)}
      visible={viewRequestDrawer}>
      <div className="view-request-drawer__container">
        <div className="view-request-drawer__content">
          <Text type="boldDetail">Propuesta: </Text>
          <Title type="tertiary" color="primary">
            ¿Por qué eres la persona indicada para este trabajo?
          </Title>
          <Text>{currentRequest?.why_you}</Text>
          <Title type="tertiary" color="primary">
            Descripción de la propuesta
          </Title>
          <Text>{currentProposal?.text}</Text>
          <Title type="tertiary" color="primary">
            Desglose de la propuesta
          </Title>
          <div className="view-request-drawer__request-breakdown">
            <ViewRequestBreakdown currentProposal={currentProposal} />
          </div>
          {handleAcceptProposal && (
            <Fragment>
              <Title type="tertiary" color="primary">
                Condiciones de propiedad intelectual
              </Title>
              <div className="view-request-drawer__intellectual-prop">
                <Checkbox
                  checked={intellectualProp}
                  onChange={(event) => setIntellectualProp(event.target.checked)}></Checkbox>
                <Text type="smallDetail"> {NEGOTIATION_INTELLECTUAL_PROP_TEXT}</Text>
              </div>
              <div className="view-request-drawer__accept-button">
                <Button
                  text="Aceptar propuesta"
                  variant="primary"
                  color="primary"
                  onClick={() => handleAcceptProposal(currentProposal, intellectualProp)}
                />
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </CustomDrawer>
  );
}
