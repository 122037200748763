import React from 'react';
import './MyLinks.scss';
import { LinkElement } from './LinkElement';
import { Title } from '@components/common';

const MyLinks = ({ user, handleEditSocialMedia, withHeader = true }) => {
  return (
    <div className="my-links__container">
      {withHeader && <div className="my-links__header"></div>}

      <div className="my-links__content">
        <Title type="tertiary" color="primary">
          Cuentas y portafolio{' '}
        </Title>
        <div className="my-links__row">
          <LinkElement
            userData={user}
            handleEditSocialMedia={handleEditSocialMedia}
            type="github"
            icon="fab fa-github"
            title="Github"
          />

          <LinkElement
            userData={user}
            handleEditSocialMedia={handleEditSocialMedia}
            type="stackoverflow"
            icon="fab fa-stack-overflow"
            title="Stackoverflow"
          />
          <LinkElement
            userData={user}
            handleEditSocialMedia={handleEditSocialMedia}
            type="briefcase"
            icon="fa fa-briefcase"
            title="Portafolio"
          />
        </div>
      </div>
    </div>
  );
};

export default MyLinks;
