import React from 'react';
import css from './Design.module.scss';
import { Text } from '@components/common';
import { Image } from 'antd';
import { addHttps } from 'common/utils/Formaters';
import { ilustrationsFolder } from '../CMSDetails.constants';

function Design({ currentProject }) {
  return (
    <div className={css.container}>
      <div className={css.template}>
        <Text type="detail">Plantilla de referencia: </Text>
        <div>
          <Image.PreviewGroup>
            <Image src={currentProject.template_url} />
          </Image.PreviewGroup>
        </div>
      </div>
      <div className={css.pallete}>
        <Text type="detail">Paleta de colores: </Text>
        <div>
          <Image.PreviewGroup>
            <Image src={currentProject.pallette_url} />
          </Image.PreviewGroup>
        </div>
      </div>
      <div className={css.urls}>
        <Text type="detail">Sitios de referencia: </Text>
        <div className={css.content}>
          {currentProject.reference_urls.length > 0 ? (
            currentProject.reference_urls.map((url, index) => {
              return (
                <a key={`url-${index}`} href={addHttps(url)} target="_blank" rel="noreferrer">
                  {url}
                </a>
              );
            })
          ) : (
            <div className={css.empty}>
              <img src={ilustrationsFolder + 'dreamer.svg'} />
              <Text type="smallDetail">Sin sitios de referencia</Text>
            </div>
          )}
        </div>
      </div>
      <hr />
    </div>
  );
}

export default Design;
